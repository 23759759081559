import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HeaderModule } from '../header/header.module';


import { CalendarComponent } from './calendar.component';
import { ViewCalendarComponent } from './view-calendar/view-calendar.component';
import { EditCalendarComponent } from './edit-calendar/edit-calendar.component';
import { AssociateLockComponent } from './associate-lock/associate-lock.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    CalendarComponent,
    EditCalendarComponent,
    ViewCalendarComponent,
    AssociateLockComponent,
  ],
  imports: [
    FormsModule,
    HeaderModule,
    CommonModule,
    SharedModule
  ],
  exports: [
    CalendarComponent
  ]
})
export class CalendarModule { }
