import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';


import { InputNumberModule } from '../input-number/input-number.module';
import { PeriodoSelectModule } from '../periodo-select/periodo-select.module';
import { LabelOverLenghtModule } from '../label-over-lenght/label-over-lenght.module';

import { ElementsComponent } from './elements.component';
import { ElementsEditComponent } from './elements-edit/elements-edit.component';
import { ElementsCercaComponent } from './elements-cerca/elements-cerca.component';
import { ElementsDetailComponent } from './elements-detail/elements-detail.component';
import { ElementsSelectComponent } from './elements-select/elements-select.component';
import { ElementsCatalogComponent } from './elements-catalog/elements-catalog.component';
import { ElementsEditGestionComponent } from './elements-edit/elements-edit-gestion/elements-edit-gestion.component';
import { ElementsEditDetalleComponent } from './elements-edit/elements-edit-detalle/elements-edit-detalle.component';
import { ElementsEditInformacionComponent } from './elements-edit/elements-edit-informacion/elements-edit-informacion.component';
import { ElementsEditPerifericosComponent } from './elements-edit/elements-edit-perifericos/elements-edit-perifericos.component';
import { ElementsEditInstalacionesComponent } from './elements-edit/elements-edit-instalaciones/elements-edit-instalaciones.component';
import { ElementsEditHistoricoCoordenadasComponent } from './elements-edit/elements-edit-historico-coordenadas/elements-edit-historico-coordenadas.component';
import { HeaderModule } from '../header/header.module';
import { ImagesModule } from '../images/images.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NzModalModule } from 'ng-zorro-antd/modal';


@NgModule({
  declarations: [
    ElementsComponent,
    ElementsEditComponent,
    ElementsCercaComponent,
    ElementsDetailComponent,
    ElementsSelectComponent,
    ElementsCatalogComponent,
    ElementsEditGestionComponent,
    ElementsEditDetalleComponent,
    ElementsEditInformacionComponent,
    ElementsEditPerifericosComponent,
    ElementsEditInstalacionesComponent,
    ElementsEditHistoricoCoordenadasComponent,
  ],
  imports: [
    FormsModule,
    ImagesModule,
    CommonModule,
    HeaderModule,
    BrowserModule,
    SharedModule,
    NzModalModule,
    InputNumberModule,
    PeriodoSelectModule,
    LabelOverLenghtModule,
  ],
  exports: [
    ElementsComponent
  ]
})
export class ElementsModule { }
