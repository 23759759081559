Date.prototype.setHourMinuteSecondFromString = function(value: string) {
  let hours: Number = Number.parseInt(value.split(':')[0]);
  this.setHours(hours);
  let minutes: Number = Number.parseInt(value.split(':')[1]);
  this.setMinutes(minutes);
  let seconds: Number = Number.parseInt(value.split(':')[2]);
  this.setSeconds(seconds)

  return this;
}
