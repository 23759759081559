<jqxWindow #window [jqxWindowAutoResize] [theme]="tema" [position]="getFormPos(window, 24)" [zIndex]="900"
  [width]="mapWidth" [height]="mapHeight - 24" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="true" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Cluster_zoom')}}
  </div>
  <div class="form" style="float:left;">
    <div style="display: flex;height: 100%;">
      <div style="display: flex;flex-direction: column;justify-content: space-between;">
        <div style="margin-top: 5px;">
          <jqxSlider #slider height="60" [min]="15" [max]="20" [ticksFrequency]="1" [value]="clusterZoom" showTicks="1"
            (onSlide)="onSlider($event)" showTickLabels="true" [step]="1" [theme]="tema" [tickSize]="5"
            [mode]="'fixed'">
          </jqxSlider>
        </div>

        <div style="margin-top: 5px; clear: both; display: flex; gap: 5px;">
          <jqxButton style="float: left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
            [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onSaveConfigZoom()"
            [value]="translate('Guardar')">
          </jqxButton>
          <jqxButton style="float: left; margin-left: 5px;" [width]="100" [height]="26"
            [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="window.close()"
            [value]="translate('Salir')">
          </jqxButton>
        </div>
      </div>

      <div style="width: 100%; height: 100%">
        <movisat-map [provider]="mapProvider" (mapReady)="onMapReady($event)" [lang]="lang"
          [searchCountry]="searchCountry" (boundsChange)="onBoundsChange($event)" [noSearch]="true" [zoom]="6">
        </movisat-map>
      </div>
    </div>
  </div>
</jqxWindow>
