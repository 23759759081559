import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { debounce } from 'lodash';
import {
  CamposCiudadanosVisibleModel,
  LopdCiudadanoModel,
  RolCamposCiudadanoModel
} from 'src/app/services/ciudadanos/models/lopdCiudadano.model';
import { CiudadanosService } from 'src/app/services/ciudadanos/ciudadanos.service';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { HeaderComponent } from '../../header/header.component';
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { MainComponent } from '../../main/main.component';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { SsoService } from 'src/app/services/sso/sso.service';
import { CustomForms } from '../../forms/custom-forms';
import { RolModel } from 'src/app/services/sso/models/rol.model';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { BehaviorSubject } from 'rxjs';
import { AplicacionModel } from 'src/app/services/sso/models/aplicacion.model';

@Component({
  selector: 'app-lopd-ciudadanos',
  templateUrl: './lopd-ciudadanos.component.html',
  styleUrls: ['./lopd-ciudadanos.component.css']
})
export class LopdCiudadanosComponent extends CustomForms implements OnInit, AfterViewInit, OnDestroy {

  static _this: any;
  @ViewChild('window') window: jqxWindowComponent;
  @ViewChild('myGrid') grid: jqxGridComponent;
  @ViewChild('myGridConf') gridConf: jqxGridComponent;
  @ViewChild('tabs') tabs: jqxTabsComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('listRoles') listRoles: jqxDropDownListComponent;

  lpodsCiudadanosLength$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  camposCiudadanosVisibleLength$: BehaviorSubject<number> = new BehaviorSubject<number>(0);


  public showLoader = true;
  public tema = environment.tema;
  public langGrid: any;

  lpodsCiudadanos: LopdCiudadanoModel[] = [];
  camposCiudadanosVisible: CamposCiudadanosVisibleModel[] = [];

  mapWidth: number;
  mapHeight: number;
  dataAdapter: any;
  adapterRoles: any;

  idsRolFilter: any[] = [];
  idsRolSelect: any[] = [];
  idsAppSelect: any[] = [];
  roles: RolModel[];
  datosLOPD: RolCamposCiudadanoModel[] = [];
  showCopy: boolean = false;
  esDesactivado: boolean = false;
  filterTablaHeader: jqxDropDownListComponent;

  createTooltip = (content: string) => {
    return (columnHeaderElement?: any) => {
      const jqElement = columnHeaderElement[0].parentElement;
      const options = {
        content: content,
        theme: environment.tema,
        width: 'auto',
        height: 'auto',
        position: 'mouse',
        autoHide: true,
      };
      jqwidgets.createInstance(jqElement, 'jqxTooltip', options);
    };
  };

  columns: any = [];
  idsTablaSelect: any = [];
  resetFilter: boolean = false;
  dataSourceConf: { datatype: string; datafields: ({ name: string; type: string; map?: undefined; } | { name: string; type: string; map: string; })[]; localdata: { id: number; nombre: string; nombreTabla: string; selec: string; }[]; sortcolumn: string; sortdirection: string; };

  rendexTextGeneric(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {

    if (typeof value === 'string') {
      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;

    } else if (typeof value === 'number') {
      return `<div style="margin-right: 4px; margin-top: 4px;  text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';  this.style.position='fixed'" onmouseout="this.style.backgroundColor=''; this.style.color='';this.style.position=''"> ${value}</span></div>`;
    } else if (value instanceof Date) {

      if (columnfield === 'Fecha') {
        return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';  this.style.position='fixed'" onmouseout="this.style.backgroundColor=''; this.style.color='';this.style.position=''"> ${DateUtils.formatDate(value, true)}</span></div>`;
      } else if (columnfield === 'Hora') {
        return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';  this.style.position='fixed'" onmouseout="this.style.backgroundColor=''; this.style.color='';this.style.position=''"> ${DateUtils.formatTime(value)}</span></div>`;
      }
    }
  }

  dataFilterGrid: any;
  dataFilterGridConf: any;


  columngroupsGeneral: any[];
  columnsConf: any[] = [];
  apps: AplicacionModel[] = [];
  columnGroupsConf: any[] = [];


  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    if (group === undefined) {
      return;
    }

    let num = data?.subItems.length;
    let name = group;
    let header = '';

    if (name === true) {
      header = '(S)';
    } else if (name === false) {
      header = '(N)';
    } else {
      header = name;
    }

    return '<div style="margin-left: 4px;">' + header + ': ' + num + '</div>';
  }

  dataAdapterConf: any;
  componentRef: any;

  constructor(private ciudadanosService: CiudadanosService, private ssoService: SsoService, private cdr: ChangeDetectorRef) {
    super();
    LopdCiudadanosComponent._this = this;
  }

  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  ngOnInit() {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.loadRoles();
  }

  async ngAfterViewInit() {
    this.addCustomForm(this.window);
    this.filterTablaHeader = HeaderComponent._this.listTabla;
    try {
      await this.loadCiudadanosData();
    } catch (error) {
      console.error(error);
    }
    this.loadWindowTabs();
  }

  async loadCiudadanosData() {
    if (!this.filterTablaHeader.getItems()) {
      this.lpodsCiudadanos = await this.ciudadanosService.getLopdsListCiudadanos();

      const source = this.lpodsCiudadanos.map(item => ({
        Id: item.nombreTabla,
        Nombre: item.nombreTabla,
        group: item.nombre
      }));

      source.sort((a, b) => a.Nombre < b.Nombre ? -1 : 1);

      HeaderComponent._this.dataAdapterTabla = new jqx.dataAdapter({
        datatype: 'json',
        localdata: source,
        hierarchy: {
          groupDataFeild: 'label',
          childDataField: 'items'
        },
        autoBind: true
      });

      HeaderComponent._this.listTabla.source(HeaderComponent._this.dataAdapterTabla);
    }
    this.loadColumnGeneral(this.lpodsCiudadanos[0]?.opcionesAplicaciones);
  }


  loadColumnGeneral(appsFilter: any[]) {
    this.resetColumnsAndGroups();

    this.columns = [
      this.createStaticColumn('Tabla', 'nombreTabla', 'APLICACIONES'),
      this.createStaticColumn('Campos', 'nombre', 'APLICACIONES'),
      this.createCheckboxColumn('D_personal', 'datoPersonal', 'APLICACIONES', ['(S)', '(N)']),
      {
        text: 'Selec',
        datafield: 'selec',
        columntype: 'textbox',
        filtertype: 'textbox',
        hidden: true,
      }
    ];

    this.columngroupsGeneral = [
      {
        text: this.translate('APLICACIONES'),
        align: 'center',
        name: this.translate('APLICACIONES')
      }
    ];
    this.addDynamicColumnsAndGroups(appsFilter);
    this.loadGridGeneral();
  }

  createStaticColumn(text: string, datafield: string, columngroup: string) {
    return {
      text: this.translate(text),
      datafield,
      width: '10%',
      editable: true,
      columngroup: this.translate(columngroup),
      rendered: this.createTooltip(this.translate(text)),
      cellsrenderer: this.rendexTextGeneric
    };
  }


  addDynamicColumnsAndGroups(aplicaciones: any[]) {

    if (!aplicaciones || aplicaciones.length === 0) {
      return;
    }

    aplicaciones.forEach(aplicacion => {
      // Crear columnas dinámicas para cada aplicación
      this.columns.push(
        this.createCheckboxColumn('Oblig', `${aplicacion.nombreAplicacion}_obligatorio`, aplicacion.nombreAplicacion, ['(S)', '(N)']),
        this.createCheckboxColumn('Busq', `${aplicacion.nombreAplicacion}_busqueda`, aplicacion.nombreAplicacion, ['(S)', '(N)'])
      );
      // Crear grupo de columnas para cada aplicación
      this.columngroupsGeneral.push({
        text: aplicacion.nombreAplicacion,
        align: 'center',
        name: aplicacion.nombreAplicacion,
        rendered: this.createTooltip(aplicacion.nombreAplicacion),
      });
    });
  }

  createCheckboxColumn(text: string, datafield: string, columngroup: string, filteritems: string[]) {
    return {
      text: this.translate(text),
      datafield,
      columntype: 'checkbox',
      filtertype: 'list',
      columngroup,
      filteritems,
      editable: true,
      rendered: this.createTooltip(this.translate(text)),
      aggregates: [{
        'Total': function (aggregatedValue: number, currentValue: boolean) {
          return currentValue === true ? aggregatedValue + 1 : aggregatedValue;
        }
      }],
      aggregatesrenderer: function (aggregates: any) {
        const total = aggregates['Total'] !== undefined ? aggregates['Total'] : 0;
        return `<div style="margin-left: 4px;">${total}</div>`;  // Mostrar el total en la columna
      }
    };
  }


  resetColumnsAndGroups() {
    if (this.columns.length > 0 && this.columngroupsGeneral.length > 0) {
      this.columns = [];
      this.columngroupsGeneral = [];
      this.grid.columns(this.columns);
      this.grid.columngroups(this.columngroupsGeneral);
      this.grid.refresh();
      this.grid.updatebounddata();
    }
  }

  async loadGridGeneral() {
    try {
      this.camposCiudadanosVisible = await this.ciudadanosService.getLopdCamposCiudadanos();
      this.updateTabGeneral(this.camposCiudadanosVisible);
      this.updateTabConf(this.camposCiudadanosVisible);

      this.preprocessCiudadanosData();

      const dataSource = this.createDataSource();
      this.dataAdapter = new jqx.dataAdapter(dataSource);
      this.grid.source(this.dataAdapter);
      this.grid.clearfilters();
      setTimeout(() => {
        this.grid.sortby('nombre', 'asc');
      }, 200);
    } catch (error) {
      console.error('Error loading grid data:', error);
    }

    this.updateTabGeneral(this.grid.getrows());
    this.showLoader = false;
  }


  preprocessCiudadanosData() {
    // Asegurarse de que los datos de obligatorio y búsqueda están correctamente añadidos
    this.lpodsCiudadanos.forEach(item => {
      item.opcionesAplicaciones.forEach(aplicacion => {
        // Asegurarse de que los campos de obligatorio y búsqueda están definidos en el item
        item[`${aplicacion.nombreAplicacion}_obligatorio`] = aplicacion.obligatorio || false;
        item[`${aplicacion.nombreAplicacion}_busqueda`] = aplicacion.busqueda || false;
        item[`${aplicacion.nombreAplicacion}_idAplicacion`] = aplicacion.idAplicacion || null;
      });
    });
  }


  processCamposVisiblesData() {
    // Asegurarse de que los datos de visibilidad están correctamente añadidos
    this.camposCiudadanosVisible.forEach(item => {
      item.aplicaciones.forEach(aplicacion => {
        aplicacion.roles.forEach(role => {
          // Asegurarse de que los campos de visibilidad están definidos en el item
          item[`visible_${aplicacion.idAplicacion}_${role.idRol}`] = role.visible || false;
        });
      });
    });
  }

  createDataSource() {
    const dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'nombreTabla', type: 'string', map: 'nombreTabla' },
        { name: 'nombre', type: 'string', map: 'nombre' },
        { name: 'empresa', type: 'number', map: 'empresa' },
        { name: 'datoPersonal', type: 'boolean', map: 'datoPersonal' },
        { name: 'selec', map: 'selec', type: 'string' },
      ],
      localdata: this.lpodsCiudadanos,
      sortcolumn: 'nombre',
      sortdirection: 'asc'
    };

    // Añadir dinámicamente los campos de obligatorio y búsqueda para cada aplicación
    this.lpodsCiudadanos[0]?.opcionesAplicaciones.forEach(aplicacion => {
      dataSource.datafields.push(
        {
          name: `${aplicacion.nombreAplicacion}_obligatorio`,
          type: 'boolean',
          map: `${aplicacion.nombreAplicacion}_obligatorio`
        },
        {
          name: `${aplicacion.nombreAplicacion}_busqueda`,
          type: 'boolean',
          map: `${aplicacion.nombreAplicacion}_busqueda`
        },
        {
          name: `${aplicacion.nombreAplicacion}_idAplicacion`,
          type: 'number',
          map: `${aplicacion.nombreAplicacion}_idAplicacion`
        }
      );
    });

    return dataSource;
  }

  createDataSourceConf() {
    const datafields = [
      { name: 'id', type: 'number' },
      { name: 'nombre', type: 'string', map: 'nombre' },
      { name: 'nombreTabla', type: 'string', map: 'nombreTabla' },
      { name: 'selec', type: 'string', map: 'selec' }
    ];

    if (this.camposCiudadanosVisible.length > 0) {
      this.camposCiudadanosVisible.forEach(item => {
        item.aplicaciones.forEach(aplicacion => {
          aplicacion.roles.forEach(role => {
            const fieldName = `visible_${aplicacion.idAplicacion}_${role.idRol}`;
            datafields.push({
              name: fieldName,
              type: 'boolean'
            });
          });
        });
      });
    }

    // Crear los datos locales basados en los campos procesados
    const gridData = this.camposCiudadanosVisible.map(item => {
      const rowData = {
        id: item.id,
        nombre: item.nombre,
        nombreTabla: item.nombreTabla,
        selec: item.selec
      };

      // Añadir los campos de visibilidad de roles
      item.aplicaciones.forEach(aplicacion => {
        aplicacion.roles.forEach(role => {
          const fieldName = `visible_${aplicacion.idAplicacion}_${role.idRol}`;
          rowData[fieldName] = role.visible || false;
        });
      });

      return rowData;
    });

    // Retornar la fuente de datos
    return {
      datatype: 'json',
      datafields: datafields,
      localdata: gridData,
      sortcolumn: 'nombre',
      sortdirection: 'asc'
    };
  }

  loadColumnConfGeneric(appsData: any[]) {
    // Reiniciar columnas y grupos de columnas
    this.resetColumnsAndGroupsConf();

    // Añadir las columnas estáticas
    this.columnsConf = [
      this.createStaticColumnConf('Tabla', 'nombreTabla', 'Tabla'),
      this.createStaticColumnConf('Campos', 'nombre', 'Campos'),
      {
        text: 'Selec',
        datafield: 'selec',
        columntype: 'textbox',
        filtertype: 'textbox',
        hidden: true,
      }
    ];

    // Añadir las columnas dinámicas de aplicaciones y roles
    this.addDynamicColumnsAndGroupsConf(appsData);

    // Procesar los datos visibles antes de crear la fuente de datos
    this.processCamposVisiblesData();

    // Crear la fuente de datos usando `createDataSourceConf`
    this.dataSourceConf = this.createDataSourceConf();

    // Crear el adaptador de datos y asignar la fuente al grid
    this.dataAdapterConf = new jqx.dataAdapter(this.dataSourceConf);

    // Asignar el adaptador como la fuente del grid y recargar el grid
    this.gridConf.columns(this.columnsConf);
    this.gridConf.columngroups(this.columnGroupsConf);
    setTimeout(() => {
      this.gridConf.source(this.dataAdapterConf);
      this.gridConf.refresh();
      this.loader.close();
    }, 50);
  }

  createStaticColumnConf(text: string, datafield: string, columngroup: string) {
    return {
      text: this.translate(text),
      datafield,
      width: '10%',
      editable: false,
      columngroup: this.translate(columngroup),
      rendered: this.createTooltip(this.translate(text)),
      cellsrenderer: this.rendexTextGeneric
    };
  }
  createCheckboxColumnConf(text: string, datafield: string, columngroup: string) {
    return {
      text: this.translate(text),
      datafield,
      columntype: 'checkbox',
      columngroup,
      filtertype: 'list',
      filteritems: ['(S)', '(N)'],
      width: '5%',
      editable: true,
      rendered: this.createTooltip(this.translate(text)),
      aggregates: [{
        'Total': function (aggregatedValue: number, currentValue: boolean) {
          return currentValue === true ? aggregatedValue + 1 : aggregatedValue;
        }
      }],
      aggregatesrenderer: function (aggregates: any) {
        const total = aggregates['Total'] !== undefined ? aggregates['Total'] : 0;
        return `<div style="margin-left: 4px;">${total}</div>`;
      }
    };
  }

  addDynamicColumnsAndGroupsConf(appsData: any[]) {
    if (appsData.length > 0) {
      let firstData = appsData[0];

      if (firstData && Array.isArray(firstData.aplicaciones)) {
        firstData.aplicaciones.forEach(app => {
          if (app && Array.isArray(app.roles)) {

            // Crear el grupo de columnas para la aplicación
            const appGroup = {
              text: app.nombreAplicacion,
              align: 'center',
              name: app.nombreAplicacion,
              rendered: this.createTooltip(app.nombreAplicacion)
            };
            this.columnGroupsConf.push(appGroup);

            // Iterar sobre los roles de la aplicación
            app.roles.forEach(role => {
              // Crear un grupo para cada rol
              const roleGroup = {
                text: role.nombreRol,
                align: 'center',
                parentgroup: app.nombreAplicacion,
                name: `${app.nombreAplicacion}_${role.nombreRol}`,
                rendered: this.createTooltip(role.nombreRol)
              };
              this.columnGroupsConf.push(roleGroup);

              // Crear la columna para la visibilidad del rol
              this.columnsConf.push(
                this.createCheckboxColumnConf(
                  'Visible',
                  `visible_${app.idAplicacion}_${role.idRol}`, // Debe coincidir con el nombre del campo en `createDataSourceConf`
                  `${app.nombreAplicacion}_${role.nombreRol}`
                )
              );
            });
          }
        });
      }
    }
  }

  resetColumnsAndGroupsConf() {
    // Solo reiniciar si ya existen columnas
    if (this.columnsConf.length > 0 || this.columnGroupsConf.length > 0) {
      // Limpiar las columnas y los grupos de columnas
      this.columnsConf = [];
      this.columnGroupsConf = [];

      // Asignar las columnas y grupos vacíos al grid
      this.gridConf.columns(this.columnsConf);
      this.gridConf.columngroups(this.columnGroupsConf);

      // Refrescar el grid
      this.gridConf.refresh();
      this.gridConf.updatebounddata();
    }
  }

  loadColumnConf() {
    if (this.camposCiudadanosVisible.length > 0) {
      // Aquí cargamos todas las aplicaciones y sus roles desde `camposCiudadanosVisible`
      if (this.columnsConf.length > 0 && this.columnGroupsConf.length > 0) {
        return;
      } else {
        this.loader.open();
        let allApps = this.camposCiudadanosVisible;
        this.loadColumnConfGeneric(allApps);
      }
    }
  }


  loadWindowTabs() {
    setTimeout(() => {
      if (this.tabs.selectedItem() === 0) {
        HeaderComponent._this.listRoles.disabled(true);
      } else {
        HeaderComponent._this.listRoles.disabled(false);
      }
    }, 500);
  }

  updateTabGeneral(lopdCiudadanos) {
    this.lpodsCiudadanosLength$.next(lopdCiudadanos.length);
    // Actualiza los títulos de las pestañas
    this.lpodsCiudadanosLength$.subscribe((length) => {
      // Cambiar el título de la primera pestaña
      this.tabs.setTitleAt(0, this.translate('General') + ' (' + length + ')');
    });
  }

  updateTabConf(camposCiudadanosVisible) {
    this.camposCiudadanosVisibleLength$.next(camposCiudadanosVisible.length);
    // Actualiza los títulos de las pestañas
    this.camposCiudadanosVisibleLength$.subscribe((length) => {
      // Cambiar el título de la segunda pestaña
      this.tabs.setTitleAt(1, this.translate('Configuracion_por_rol') + ' (' + length + ')');
    });
  }

  async loadRoles() {
    this.roles = await this.ssoService.getRoles();
    if (this.roles) {
      let dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'Id', type: 'string', map: 'Id' },
          { name: 'Nombre', type: 'string', map: 'Nombre' },
        ],
        localdata: this.roles,
      };

      dataSource.localdata.sort((a, b) => (a.Nombre < b.Nombre ? -1 : 1));
      this.adapterRoles = new jqx.dataAdapter(dataSource,
        {
          beforeLoadComplete: (records: any[]): void => {
            records.sort((a, b) => (a.Nombre < b.Nombre ? -1 : 1));
          },
        }
      );
    }
  }

  filter = (cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean => {
    const fieldType = dataField?.split('_').pop();  // 'obligatorio' o 'busqueda'
    if (fieldType === 'obligatorio' || fieldType === 'busqueda' || dataField === 'datoPersonal') {
      const filterValue = filterGroup.getfilters()[0].value;
      switch (filterValue) {
        case '(S)':
          return !!cellValue;

        case '(N)':
          return !cellValue;

        default:
          return defaultFilterResult;
      }
    }

    if (dataField === 'nombreTabla') {
      let filterValue = filterGroup.getfilters()[0].value;

      // Comprobar si el valor de la celda contiene el valor del filtro
      const result = cellValue.includes(filterValue);

      // Actualizar el contador de campos visibles filtrados
      setTimeout(() => {
        this.lpodsCiudadanosLength$.next(this.grid.getrows().length);
      }, 0);

      return result;
    }

    if (dataField === 'nombre') {
      let filterValue = filterGroup.getfilters()[0].value;

      // Comprobar si el valor de la celda contiene el valor del filtro
      const result = cellValue.includes(filterValue);

      // Actualizar el contador de campos visibles filtrados
      setTimeout(() => {
        this.lpodsCiudadanosLength$.next(this.grid.getrows().length);
      }, 0);

      return result;
    }

    // En caso de no hacer match con ningún campo
    return defaultFilterResult;
  };


  filterConf = (cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean => {
    // Filtrar por el campo 'visible'
    // Extraer correctamente el tipo de campo desde `dataField`
    const fieldType = dataField?.split('_')[0]; // Esto te dará "visible" si el campo es "visible_2_3"

    // Filtrado basado en `fieldType`
    if (fieldType === 'visible') {
      const filterValue = filterGroup.getfilters()[0].value;
      switch (filterValue) {
        case '(S)':
          return !!cellValue;  // Convertir a booleano
        case '(N)':
          return !cellValue;   // Negar el valor booleano
        default:
          return defaultFilterResult;
      }
    }

    // Filtrar por el campo 'nombreTabla' usando 'contiene'
    if (dataField === 'nombreTabla') {
      let filterValue = filterGroup.getfilters()[0].value;

      // Comprobar si el valor de la celda contiene el valor del filtro
      const result = cellValue.includes(filterValue);

      // Actualizar el contador de campos visibles filtrados
      setTimeout(() => {
        this.camposCiudadanosVisibleLength$.next(this.gridConf.getrows().length);
      }, 0);

      return result;
    }

    if (dataField === 'nombre') {
      let filterValue = filterGroup.getfilters()[0].value;

      // Comprobar si el valor de la celda contiene el valor del filtro
      const result = cellValue.includes(filterValue);

      // Actualizar el contador de campos visibles filtrados
      setTimeout(() => {
        this.camposCiudadanosVisibleLength$.next(this.gridConf.getrows().length);
      }, 0);

      return result;
    }

    // En caso de no hacer match con ningún campo
    return defaultFilterResult;
  };

  onGuardar = async () => {
    this.loader.open();
    if (this.tabs.selectedItem() === 0) {
      this.loader.open();
      if (this.tabs.selectedItem() === 0) {
        let data = this.grid.getrows(); // Obtiene los datos del grid

        // Procesar los datos para reconstruir 'opcionesAplicaciones'
        let dataToSend: any = data.map(row => {
          let opcionesAplicaciones = [];

          // Iterar sobre las claves del objeto (las columnas del grid)
          Object.keys(row).forEach(key => {
            // Verificar si la clave pertenece a 'obligatorio' o 'busqueda'
            if (key.includes('_obligatorio') || key.includes('_busqueda')) {
              // Obtener el nombre de la aplicación eliminando la parte de '_obligatorio' o '_busqueda'
              let nombreAplicacion = key.split('_')[0];

              // Verificar si ya existe la aplicación en 'opcionesAplicaciones'
              let aplicacion = opcionesAplicaciones.find(app => app.nombreAplicacion === nombreAplicacion);

              if (!aplicacion) {
                // Crear un nuevo objeto para la aplicación si no existe
                aplicacion = {
                  nombreAplicacion: nombreAplicacion,
                  idAplicacion: row[`${nombreAplicacion}_idAplicacion`] || 0, // Obtener idAplicacion
                  obligatorio: false,
                  busqueda: false
                };
                opcionesAplicaciones.push(aplicacion);
              }

              // Asignar el valor correspondiente
              if (key.includes('_obligatorio')) {
                aplicacion.obligatorio = row[key];
              } else if (key.includes('_busqueda')) {
                aplicacion.busqueda = row[key];
              }
            }
          });

          // Devolver el objeto correctamente estructurado
          return {
            id: row.id,                     // ID
            empresa: row.empresa || 0,       // Empresa (por defecto 0)
            datoPersonal: row.datoPersonal,  // Dato personal
            opcionesAplicaciones: opcionesAplicaciones // Array de aplicaciones
          };
        });

        let responde = await this.ciudadanosService.postLopdListCiudadanos(dataToSend);
        if (responde) {
          MainComponent.showSuccess('ATENCION', 'Cambios_guardados', 2000);
        } else {
          MainComponent.showError('ATENCION', 'Ha_ocurrido_un_error', 2000);
        }
      }

    } else {
      if (this.gridConf.getrows().length > 0) {
        let dataFromGrid = this.gridConf.getrows();  // Obtenemos los datos del grid
        let appsData = await this.ciudadanosService.getLopdCamposCiudadanos();

        // Obtener los campos originales y aplicar los cambios del grid
        let updatedData = appsData.map(data => {
          // Buscar la fila correspondiente en el grid para este objeto 'data'
          let gridRow = dataFromGrid.find(row => row.id === data.id);

          // Si encontramos la fila correspondiente, aplicamos las actualizaciones
          if (gridRow) {
            // Iteramos sobre las aplicaciones
            data.aplicaciones.forEach(app => {
              app.roles.forEach(role => {
                // Generamos la clave 'visible_X_Y' para acceder a los datos del grid
                let campoVisible = `visible_${app.idAplicacion}_${role.idRol}`;

                // Verificamos si ese campo existe en el objeto gridRow y que no sea undefined o null
                if (gridRow.hasOwnProperty(campoVisible) && gridRow[campoVisible] !== undefined) {
                  // Actualizamos el valor 'visible' usando los datos del grid
                  role.visible = gridRow[campoVisible];
                } else {
                  // Si el campo no existe, asumimos que no hay cambios y mantenemos el valor actual
                  console.warn(`Campo ${campoVisible} no encontrado en gridRow para id: ${data.id}`);
                }
              });
            });
          } else {
            console.warn(`No se encontró una fila correspondiente para el id: ${data.id} en el grid`);
          }

          return data;  // Devolvemos el objeto con los cambios aplicados
        });
        // quito el campo nombreTabla
        updatedData.forEach(x => {
          delete x.nombreTabla;
          delete x.nombre;
          delete x.nombreJson;
          delete x.nombreColumna;
          x.aplicaciones.forEach(app => {
            delete app.nombreAplicacion;
            app.roles.forEach(role => {
              delete role.nombreRol;
            });
          });
        });
        let response = await this.ciudadanosService.postLopdCamposCiudadanos(updatedData);
        if (response) {
          this.ciudadanosService.notifyConfigChange();
          MainComponent.showSuccess('ATENCION', 'Cambios_guardados', 2000);
        } else {
          MainComponent.showError('ATENCION', 'Ha_ocurrido_un_error', 2000);
        }
      }
    }
    this.loader.close();
  }

  onTabClick(event: any) {
    if (event.args.item === 1) {
      this.showCopy = true;
      HeaderComponent._this.listApps.disabled(false);
      HeaderComponent._this.listRoles.disabled(false);
      if (this.columnsConf.length === 0 && this.columnGroupsConf.length === 0) {
        this.loadColumnConf();
      } else {
        return;
      }

    } else {
      this.showCopy = false;
      HeaderComponent._this.listRoles.disabled(true);
      HeaderComponent._this.listApps.disabled(false);
      this.gridConf.clearfilters();
      this.gridConf.refresh();
    }
  }

  onSelectRol(event: any) {
    this.idsRolFilter = event;
  }

  onCheckApps(event: any) {
    this.idsAppSelect = event;
    HeaderComponent._this.getrolesWithApp(this.idsAppSelect);
  }

  onUnChecked(event: any) {
    // quito de la lista de campos visibles el campo que se ha deseleccionado
    let dataLOPD = event.args.row;
    this.datosLOPD = this.datosLOPD.filter(x => x.idRol !== dataLOPD.id);
  }

  onCheckTabla(event: any) {
    this.idsTablaSelect = event;
  }



  onChecked(event: any) {

    let dataLOPD = event.args.row;
    if (typeof dataLOPD === 'object' && dataLOPD !== null) {
      // cojo los dos campos que me interesan
      let rowSelect = {
        idRol: dataLOPD.idRol,
        nombreRol: dataLOPD.nombreRol,
        visible: dataLOPD.visible
      };
      this.datosLOPD.push(rowSelect);
    }
  }

  onCheckRol(event: any) {
    this.idsRolSelect = this.listRoles.getCheckedItems().map(x => x.value);
  }

  async onClickCopyConfRol(event) {

    if (this.datosLOPD.length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('Debe_seleccionar_campos'), 2000);
    }

    if (this.idsRolSelect === undefined || this.idsRolSelect.length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('Debe_seleccionar_un_rol'), 2000);
    }

    this.loader.open();
    let dataToSend: any = this.datosLOPD.map(x => {
      return {
        id: x.idRol,
        nombreRol: x.nombreRol,
        visible: x.visible
      };
    });

    let successCount = 0;

    // for (let idRol of this.idsRolSelect) {
    //   try {
    //     let response = await this.ciudadanosService.postCamposVisiblesCiudadanos(dataToSend, idRol);
    //     if (response) {
    //       successCount++;
    //     } else {
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }

    if (successCount > 0) {
      MainComponent.showSuccess('ATENCION', 'Cambios_guardados', 2000);
    }

    this.loader.close();
  }


  onPrint($event: any) {

    if (this.grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      let gridContent = this.grid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }

    if (this.gridConf.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      let gridContent = this.gridConf.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets GridConfig</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }

  async eventResetFilter() {

    this.loader.open();
    this.header.searchInput['nativeElement'].value = '';
    if (HeaderComponent._this.listApps.getItems()) {
      HeaderComponent._this.listApps.uncheckAll();
    }
    this.filterTablaHeader.uncheckAll();

    if (this.tabs.selectedItem() === 0) {
      this.resetFilter = true;

      //this.loadColumnGeneral();
      const aplicaciones = await this.ciudadanosService.getLopdsListCiudadanos();
      this.loadColumnGeneral(aplicaciones[0].opcionesAplicaciones);
      this.grid.clearfilters();
      this.grid.sortby('nombre', 'asc');
      this.loader.close();


    } else {
      this.gridConf.clearfilters();

      HeaderComponent._this.loadListRoles();
      setTimeout(() => {
        this.loadColumnConfGeneric(this.camposCiudadanosVisible);
        this.gridConf.sortby('nombre', 'asc');
        this.loader.close();
      }, 50);
    }
  }

  eliminarDuplicados(array: { nombre: string, id: number, obligatorio: boolean, datoPersonal: boolean }[]) {
    const mapa = new Map<string, { nombre: string, id: number, obligatorio: boolean, datoPersonal: boolean }[]>();

    array.forEach(item => {
      const key = item.nombre;
      if (!mapa.has(key)) {
        mapa.set(key, [item]);
      } else {
        const items = mapa.get(key);
        if (items && !items.some(i => i.obligatorio === item.obligatorio && i.datoPersonal === item.datoPersonal)) {
          items.push(item);
        }
      }
    });

    const resultado: { nombre: string, id: number, obligatorio: boolean, datoPersonal: boolean }[] = [];
    mapa.forEach(items => {
      items.forEach(item => resultado.push(item));
    });

    return resultado;
  }


  eliminarDuplicadosPorRol(array: { nombre: string, id: number, visible: boolean }[]) {
    const mapa = new Map<string, { nombre: string, id: number, visible: boolean }[]>();

    array.forEach(item => {
      const key = item.nombre;
      if (!mapa.has(key)) {
        mapa.set(key, [item]);
      } else {
        const items = mapa.get(key);
        if (items && !items.some(i => i.visible === item.visible)) {
          items.push(item);
        }
      }
    });

    const resultado: { nombre: string, id: number, visible: boolean }[] = [];
    mapa.forEach(items => {
      items.forEach(item => resultado.push(item));
    });

    return resultado;
  }


  private applyRoleAndAppFilter = async () => {
    this.camposCiudadanosVisible = await this.ciudadanosService.getLopdCamposCiudadanos();
    let dataCampos = this.camposCiudadanosVisible.map(campo => {
      let aplicacionesFiltradas = campo.aplicaciones
        .map(aplicacion => {
          let isAppSelected = this.idsAppSelect.some(x => x.value === aplicacion.idAplicacion);
          if (!isAppSelected) {
            return null;
          }
          let rolesFiltrados = aplicacion.roles.filter(rol =>
            this.idsRolFilter.some(idRolFilter => idRolFilter.value === rol.idRol)
          );
          if (rolesFiltrados.length === 0) {
            return null;
          }
          return { ...aplicacion, roles: rolesFiltrados };
        })
        .filter(aplicacion => aplicacion !== null);

      if (aplicacionesFiltradas.length > 0) {
        return { ...campo, aplicaciones: aplicacionesFiltradas };
      }

      return null;
    }).filter(campo => campo !== null);

    this.loadColumnConfGeneric(dataCampos);
  };

  // Método para aplicar el filtro de tablas en el grid
  private applyTableFilter() {
    this.dataFilterGrid = this.grid.getfilterinformation();

    let filterGroup = new jqx.filter();
    let filter_or_operator = 1;
    let filtercondition = 'contains';

    // Crear un filtro para cada elemento en idsTablaSelect
    this.idsTablaSelect.forEach((item) => {
      let filter = filterGroup.createfilter('stringfilter', item.value, filtercondition);
      filterGroup.addfilter(filter_or_operator, filter);
    });

    this.gridConf.addfilter('nombreTabla', filterGroup);
    this.gridConf.applyfilters();
    this.updateTabConf(this.gridConf.getrows());
  }


  async onFilter(event: any) {
    this.loader.open();
    if (this.tabs.selectedItem() == 0) {

      if (this.idsAppSelect.length > 0) {
        this.filterColumnsByApplication();
      } else {

      }

      if (this.idsTablaSelect.length > 0) {
        this.dataFilterGrid = this.grid.getfilterinformation();
        // creo un filtro mostrando solo los campos que pertenecen a las tablas seleccionadas
        let filterGroup = new jqx.filter();
        let filter_or_operator = 1; // Operador OR para combinar filtros
        let filtercondition1 = 'contains';

        // Recorrer los elementos de idsTablaSelect y crear un filtro para cada uno
        this.idsTablaSelect.forEach((item) => {
          // Crear un filtro con cada nombreTabla
          let filter1 = filterGroup.createfilter('stringfilter', item.value, filtercondition1);
          // Agregar el filtro al grupo de filtros con el operador OR
          filterGroup.addfilter(filter_or_operator, filter1);
        });

        // Aplicar el grupo de filtros al grid
        this.grid.addfilter('nombreTabla', filterGroup);
        this.grid.applyfilters();

        this.updateTabGeneral(this.grid.getrows());

      }

    } else {

      const hasRoleFilter = this.idsRolFilter?.length > 0;
      const hasAppFilter = this.idsAppSelect?.length > 0;
      const hasTableFilter = this.idsTablaSelect?.length > 0;

      if (!hasRoleFilter && !hasAppFilter && !hasTableFilter) {
        this.loader.close();
        return; // No hacer nada si no hay filtros aplicables
      }

      // Aplicar el filtro de roles y aplicaciones si ambos filtros están presentes
      if (hasRoleFilter && hasAppFilter) {
        await this.applyRoleAndAppFilter();
      }

      if (hasRoleFilter && !hasAppFilter) {

        // solo filro por rol
        let dataCampos = this.camposCiudadanosVisible.map(campo => {
          let aplicacionesFiltradas = campo.aplicaciones.map(aplicacion => {
            let rolesFiltrados = aplicacion.roles.filter(rol =>
              this.idsRolFilter.some(idRolFilter => idRolFilter.value === rol.idRol)
            );
            return { ...aplicacion, roles: rolesFiltrados };
          }).filter(aplicacion => aplicacion.roles.length > 0);

          return { ...campo, aplicaciones: aplicacionesFiltradas };
        }).filter(campo => campo.aplicaciones.length > 0);

        this.loadColumnConfGeneric(dataCampos);

      }

      // Aplicar el filtro de tablas si existen tablas seleccionadas
      if (hasTableFilter) {
        setTimeout(() => {
          this.applyTableFilter();
        }, 50);
      }
    }
    this.loader.close();
  }

  filterColumnsByApplication() {
    // Obtener las aplicaciones filtradas según el filtro actual (this.idsAppSelect)
    const aplicacionesFiltradas = this.lpodsCiudadanos[0].opcionesAplicaciones.filter(app =>
      this.idsAppSelect.some(x => x.value === app.idAplicacion)
    );

    // Nombres de las columnas estáticas que no cambian con las aplicaciones
    const staticColumns = ['nombreTabla', 'nombre', 'datoPersonal'];

    // Primero ocultar todas las columnas dinámicas y estáticas
    this.columns.forEach(column => {
      const isStaticColumn = staticColumns.includes(column.datafield);  // Verificar si es una columna estática
      if (isStaticColumn) {
        column.hidden = true;  // Ocultar las columnas estáticas
      } else {
        column.hidden = true;  // Ocultar las columnas dinámicas inicialmente
      }
    });

    // Mostrar solo las columnas que pertenecen a las aplicaciones filtradas
    aplicacionesFiltradas.forEach(aplicacion => {
      const obligColumn = this.columns.find(col => col.datafield === `${aplicacion.nombreAplicacion}_obligatorio`);
      const busqColumn = this.columns.find(col => col.datafield === `${aplicacion.nombreAplicacion}_busqueda`);

      if (obligColumn) obligColumn.hidden = false;
      if (busqColumn) busqColumn.hidden = false;
      if (obligColumn) obligColumn.width = '5%';
      if (busqColumn) busqColumn.width = '5%';

    });

    // Opción: Mostrar columnas estáticas si quieres que siempre se vean
    staticColumns.forEach(staticColumn => {
      const column = this.columns.find(col => col.datafield === staticColumn);
      if (column) column.hidden = false;  // Mostrar las columnas estáticas si así lo deseas
      // 8% de ancho para la columna 'nombreTabla'
      if (column.datafield === 'nombreTabla') column.width = '8%';
      // 10% de ancho para la columna 'nombre'
      if (column.datafield === 'nombre') column.width = '10%';
      // 5% de ancho para la columna 'datoPersonal'
      if (column.datafield === 'datoPersonal') column.width = '5%';
    });

    this.grid.updatebounddata();
  }

  applyGridFilter(filterValue: string) {
    // paso a minusculas
    //this.dataFilterGrid = this.gridConf.getfilterinformation();

    let filterValueLower = filterValue.toLowerCase();

    // Actualizar el grid con los datos filtrados
    let auxdata = this.camposCiudadanosVisible;

    let dataFiltered = auxdata.filter(x => {
      return x.nombre.toLowerCase().includes(filterValueLower) || x.nombreTabla.toLowerCase().includes(filterValueLower);
    });

    setTimeout(() => {
      this.dataSourceConf.localdata = dataFiltered;
      this.gridConf.updatebounddata('data');
      this.updateTabConf(dataFiltered);
    }, 0);


  }



  onBuscar = debounce((searchValue: string) => {
    let filterValue = '';

    // Obtener el valor del input de búsqueda
    const inputValue = this.header.searchInput['nativeElement'].value;

    // Si el valor es mayor o igual a 3 caracteres, convertir a mayúsculas y quitar acentos
    if (inputValue.length >= 3) {
      filterValue = this.removeAccents(inputValue.toUpperCase());
    } else {
      filterValue = '';
    }

    // Aplicar o limpiar los filtros basados en el valor del filtro
    if (filterValue.length >= 3) {
      if (this.tabs.selectedItem() === 0) {
        this.applyFilter(filterValue);
      } else {
        this.applyGridFilter(filterValue);
      }

    } else {

      if (this.tabs.selectedItem() === 1) {

        this.dataSourceConf.localdata = this.camposCiudadanosVisible;
        this.gridConf.updatebounddata('data');
        this.updateTabConf(this.camposCiudadanosVisible);

      }

      this.clearFilters(this.tabs.selectedItem() === 0 ? 'grid' : 'gridConf');
    }

    // Guardar el estado del grid y actualizar la pestaña correspondiente
    //this.saveStateAndRefresh(this.tabs.selectedItem() === 0 ? 'grid' : 'gridConf');
  }, 100);

  // Función para aplicar el filtro
  applyFilter(filterValue: string) {
    // Seleccionar los items y grid correspondientes según la pestaña seleccionada
    const grid = this.tabs.selectedItem() === 0 ? this.grid : this.gridConf;
    const items = this.tabs.selectedItem() === 0 ? this.lpodsCiudadanos : this.camposCiudadanosVisible;

    // Actualizar el campo 'selec' basado en la coincidencia con nombre o nombreTabla
    items.forEach(item => {
      if (
        (item?.nombre + '').toUpperCase().indexOf(filterValue.toUpperCase()) > -1 ||
        (item?.nombreTabla + '').toUpperCase().indexOf(filterValue.toUpperCase()) > -1
      ) {
        item['selec'] = 'selec';
      } else {
        item['selec'] = '';
      }
    });

    // Verificar si ya existe un filtro para 'selec'
    const filters = grid.getfilterinformation();
    const existingFilter = filters.find(s => s.datafield === 'selec');

    if (!existingFilter) {
      // Crear un filtro para el campo 'selec'
      const filterGroup = new jqx.filter();
      filterGroup.operator = 'and';
      const filterCondition = filterGroup.createfilter('stringfilter', 'selec', 'contains');
      filterGroup.addfilter(0, filterCondition);


      // Agregar y aplicar el filtro
      grid.addfilter('selec', filterGroup);
    }

    try {
      grid.applyfilters();
      grid.refresh();  // Asegura que el grid se refresque correctamente
      grid.updatebounddata('filter');  // Indica que la fuente de datos se ha filtrado
    } catch (error) {
      console.error('Error applying filters:', error);
    }
  }

  // Función para limpiar los filtros
  clearFilters(gridType: 'grid' | 'gridConf') {
    const grid = gridType === 'grid' ? this.grid : this.gridConf;
    grid.clearfilters();
  }

  // Función para guardar el estado y actualizar la pestaña
  saveStateAndRefresh(gridType: 'grid' | 'gridConf') {
    const grid = gridType === 'grid' ? this.grid : this.gridConf;

    if (gridType === 'grid') {
      this.dataFilterGrid = grid.getfilterinformation();
      this.updateTabGeneral(grid.getrows());
    } else {
      this.dataFilterGridConf = grid.getfilterinformation();
      this.updateTabConf(grid.getrows());
    }
  }


  // Función para eliminar los acentos
  removeAccents(value: string): string {
    return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  onExportar() {
    const wb = xlsx.utils.book_new();

    this.exportSheetGeneral(wb);
    this.exportConfigurationSheetByRole(wb);

    this.downloadExcelFile(wb);
  }

  private exportSheetGeneral(wb: xlsx.WorkBook) {
    const rows = this.grid.getrows();
    if (rows.length === 0) return;

    const json = JSON.parse(JSON.stringify(rows));
    const tabla = this.translate("Tabla");
    const campos = this.translate("Campos");
    const datoPersonal = this.translate("D_personal");

    const columnasDinamicas = this.getColumnasDinamicas(json);
    const exportData = this.mapDataSheetGeneral(json, tabla, campos, datoPersonal, columnasDinamicas);
    
    const ws = xlsx.utils.aoa_to_sheet([]);

    this.addHeadersGeneralSheet(ws, tabla, campos, datoPersonal, columnasDinamicas);

    xlsx.utils.sheet_add_json(ws, exportData, { skipHeader: true, origin: "A3" });

    const totalColumns = 3 + Object.keys(columnasDinamicas).length * 2;
    const lastColumnLetter = this.getColumnLetter(totalColumns - 1);

    const filterRange = `A2:${lastColumnLetter}2`;
    ws["!autofilter"] = { ref: filterRange };
    xlsx.utils.book_append_sheet(wb, ws, this.translate("General"));
}


  private getColumnLetter(colIndex: number): string {
    let letter = '';
    while (colIndex > 0) {
      const remainder = (colIndex - 1) % 26;
      letter = String.fromCharCode(65 + remainder) + letter;
      colIndex = Math.floor((colIndex - 1) / 26);
    }
    return letter;
  }

  private getColumnasDinamicas(json: any[]): Record<string, { obligatorio: string; busqueda: string }> {
    if (!json || json.length === 0) return {};

    const aplicaciones = Object.keys(json[0]).filter(
      (key) => key.includes("_obligatorio") || key.includes("_busqueda")
    );

    return aplicaciones.reduce((acc, key) => {
      const baseName = key.split("_")[0];
      if (!acc[baseName]) acc[baseName] = { obligatorio: "", busqueda: "" };
      if (key.includes("_obligatorio"))
        acc[baseName].obligatorio = this.translate("Obligatorio");
      if (key.includes("_busqueda"))
        acc[baseName].busqueda = this.translate("Busqueda");
      return acc;
    }, {});
  }

  private mapDataSheetGeneral(
    json: any[],
    tabla: string,
    campos: string,
    datoPersonal: string,
    columnasDinamicas: Record<string, { obligatorio: string; busqueda: string }>
  ): any[] {
    return json.map((element) => {
      const row: any = {
        [tabla]: element.nombreTabla,
        [campos]: element.nombre,
        [datoPersonal]: element.datoPersonal ? this.translate("SI") : this.translate("NO"),
      };

      Object.keys(columnasDinamicas).forEach((appKey) => {
        row[`${appKey}_obligatorio`] = element[`${appKey}_obligatorio`] ? this.translate("SI") : this.translate("NO");
        row[`${appKey}_busqueda`] = element[`${appKey}_busqueda`] ? this.translate("SI") : this.translate("NO");
      });

      return row;
    });
  }

  private addHeadersGeneralSheet(
    ws: xlsx.WorkSheet,
    tabla: string,
    campos: string,
    datoPersonal: string,
    columnasDinamicas: Record<string, { obligatorio: string; busqueda: string }>
  ) {
    const headers = [
      [
        this.translate("APLICACIONES"),
        null,
        datoPersonal,
        ...Object.keys(columnasDinamicas).flatMap((app) => [app, null]),
      ],
      [
        tabla,
        campos,
        null,
        ...Object.values(columnasDinamicas).flatMap((app) => [app.obligatorio, app.busqueda]),
      ],
    ];

    xlsx.utils.sheet_add_aoa(ws, headers, { origin: "A1" });

    ws["!merges"] = ws["!merges"] || [];

    ws["!merges"].push({
      s: { r: 0, c: 0 },
      e: { r: 0, c: 1 },
    });

    ws["!merges"].push({
      s: { r: 0, c: 2 },
      e: { r: 1, c: 2 },
    });


    let colIndex = 3;
    Object.keys(columnasDinamicas).forEach((appKey) => {
      ws["!merges"].push({
        s: { r: 0, c: colIndex },
        e: { r: 0, c: colIndex + 1 },
      });
      colIndex += 2;
    });
  }

  private exportConfigurationSheetByRole(wb: xlsx.WorkBook) {
    const rows = this.gridConf.getrows();
    if (rows.length === 0) return;

    const appRoleMapping = this.generateApplicationRolesMapping();
    const appNameMapping = this.generateApplicationNameMapping();

    const roleHeaders = this.generateHeadersRoles(rows);

    const headers = this.createHeadersSheetConfiguration(roleHeaders, appNameMapping, appRoleMapping);
    const data = this.mapDataSheetConfiguration(rows, roleHeaders, appNameMapping, appRoleMapping);

    const ws = xlsx.utils.json_to_sheet(data);
    xlsx.utils.sheet_add_aoa(ws, headers, { origin: "A1" });

    this.addMergesSheetConfiguration(ws, roleHeaders);

    const totalColumns = 2 + roleHeaders.length;
    const lastColumn = String.fromCharCode(64 + (totalColumns > 26 ? Math.floor((totalColumns - 1) / 26) + 64 : 0)) +
      String.fromCharCode(64 + ((totalColumns - 1) % 26) + 1);
    const filterRange = `A3:${lastColumn}3`;
    ws["!autofilter"] = { ref: filterRange };

    xlsx.utils.book_append_sheet(wb, ws, this.translate('Configuracion_por_rol'));
  }

  private generateApplicationRolesMapping(): Record<string, Record<string, string>> {
    const mapping: Record<string, Record<string, string>> = {};
    if (this.camposCiudadanosVisible.length === 0) return mapping;

    this.camposCiudadanosVisible.forEach((item) => {
      item.aplicaciones.forEach((aplicacion) => {
        mapping[aplicacion.idAplicacion] = mapping[aplicacion.idAplicacion] || {};
        aplicacion.roles.forEach((role) => {
          mapping[aplicacion.idAplicacion][role.idRol] = role.nombreRol;
        });
      });
    });

    return mapping;
  }

  private generateApplicationNameMapping(): Record<string, string> {
    const mapping: Record<string, string> = {};
    if (this.camposCiudadanosVisible.length === 0) return mapping;

    this.camposCiudadanosVisible.forEach((item) => {
      item.aplicaciones.forEach((aplicacion) => {
        mapping[aplicacion.idAplicacion] = aplicacion.nombreAplicacion;
      });
    });

    return mapping;
  }

  private generateHeadersRoles(data: any[]): any[] {
    return Object.keys(data[0])
      .filter((key) => key.includes("visible_"))
      .map((key) => {
        const [, idAplicacion, idRol] = key.split("_");
        return { idAplicacion, idRol, fieldName: key };
      });
  }

  private createHeadersSheetConfiguration(roleHeaders: any[], appNameMapping: any, appRoleMapping: any) {
    return [
      ["Tabla", "Campos", ...roleHeaders.map((header) => appNameMapping[header.idAplicacion])],
      [null, null, ...roleHeaders.map((header) => appRoleMapping[header.idAplicacion][header.idRol])],
      [null, null, ...roleHeaders.map(() => "Visible")],
    ];
  }

  private mapDataSheetConfiguration(
    rows: any[],
    roleHeaders: any[],
    appNameMapping: any,
    appRoleMapping: any
  ): any[] {
    return rows.map((item) => {
      const row: any = {
        Tabla: item.nombreTabla,
        Campos: item.nombre,
      };

      roleHeaders.forEach((header) => {
        const key = header.fieldName;
        row[`${appNameMapping[header.idAplicacion]}_${appRoleMapping[header.idAplicacion][header.idRol]}_Visible`] =
          item[key] ? this.translate("SI") : this.translate("NO");
      });

      return row;
    });
  }

  private addMergesSheetConfiguration(ws: xlsx.WorkSheet, roleHeaders: any[]) {
    const merges = [];

    merges.push({ s: { r: 0, c: 0 }, e: { r: 2, c: 0 } });
    merges.push({ s: { r: 0, c: 1 }, e: { r: 2, c: 1 } });

    let colIndex = 2;
    roleHeaders.forEach((header, index) => {
      if (index === 0 || header.idAplicacion !== roleHeaders[index - 1].idAplicacion) {
        merges.push({
          s: { r: 0, c: colIndex },
          e: { r: 0, c: colIndex + roleHeaders.filter((h) => h.idAplicacion === header.idAplicacion).length - 1 },
        });
      }
      merges.push({
        s: { r: 1, c: colIndex },
        e: { r: 1, c: colIndex },
      });
      colIndex++;
    });

    ws["!merges"] = merges;
  }

  private downloadExcelFile(wb: xlsx.WorkBook) {
    const fileName =
      DateUtils.formatDateAMDhms(new Date()) + "_" + this.translate("Lopd_ciudadanos") + ".xlsx";
    const wbout = xlsx.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([wbout], { type: "application/octet-stream" });
    const url = URL

      .createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = fileName;
    anchor.click();
    URL.revokeObjectURL(url);
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  selectionRolRenderer = (): string => {
    if (this.listRoles) {
      let selectedItems = this.listRoles.getCheckedItems();
      if (selectedItems !== undefined && selectedItems.length > 0) {
        let itemValue = selectedItems.map(x => x.label).join(', ');
        return `<div style="position: relative; margin-left: 3px; margin-top: 5px;">${itemValue}</div>`;
      } else {
        return '<div style="position: relative; margin-left: 5px; margin-top: 5px;"> Selecciona Rol  </div>';
      }
    }
  };

  ngOnDestroy(): void {
    this.window.destroy();
    this.componentRef = null;
    LopdCiudadanosComponent._this = null;
  }

  onClose() {
    this.componentRef.destroy();
    LopdCiudadanosComponent._this = null;
  }

}
