<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #windowPersonas [jqxWindowAutoResize] [theme]="environment.tema" [width]="mapWidth" [height]="mapHeight - 24"
  [position]="getFormPos(windowPersonas, 24)" [zIndex]="900" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
  [title]="translate('Listado_personas') + ' (' + gridPersonas?.getrows().length + ')'" (onClose)="onClose()">

  <div class="formHeader" style="overflow-x: hidden;">
  </div>

  <div class="form" style="float:left; overflow: hidden; height: 100%; width: 100%;">
    <div>
      <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
        (eventImprimir)="onPrint()" (eventExportar)="onExportar()" (eventBuscador)="onBuscar()"
        (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()">
      </app-header>
    </div>

    <div style="float: left; width: 100%; height: 100%;">
      <jqxGrid #gridPeople [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 80px)'"
        [appCellClick] [source]="sourceGridPersonasAdapter" [localization]="langGrid" [filterable]="true"
        [columns]="columnsGridPersonas" [showrowlines]="false" [columnsresize]="true" [sortable]="true"
        [enablebrowserselection]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20"
        [showaggregates]="true" [rowsheight]="25" [columnsheight]="20" [groupable]="true" [editable]="false"
        [updatefilterconditions]="updatefilterconditions" [columngroups]="columnsGroupPersonas">
      </jqxGrid>

      <div style="float: left; margin-top: 10px">
        <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [value]="translate('Salir')"
          (onClick)="closeWindow()">
        </jqxButton>
      </div>
    </div>
  </div>
</jqxWindow>
