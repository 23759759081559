import { Component, signal, TemplateRef, ViewChild, ViewContainerRef } from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ConjuntoVehiculoService } from 'src/app/services/conjuntoVehiculo/conjunto-vehiculo.service';
import { ConjuntoVehiculo } from 'src/app/services/conjuntoVehiculo/models/conjunto-vehiculo.model';
import { VehicleView } from 'src/app/interfaces/vehicle-view';
import { HeaderComponent } from 'src/app/components/header/header.component';

@Component({
  selector: 'app-vehiculos-maquinaria-resumen',
  templateUrl: './vehiculos-maquinaria-resumen.component.html',
  styleUrl: './vehiculos-maquinaria-resumen.component.css'
})
export class VehiculosMaquinariaResumenComponent {
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('gridVehiculos') gridVehiculos: jqxGridComponent;
  @ViewChild('renderColumnIcon', { static: true }) renderColumnIcon!: TemplateRef<any>;

  isLoading: boolean = false;

  public totalRegistros = signal<string>('(0)');

  protected utils = Utils;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  protected vehiculos: VehicleView[] = [];
  protected vehiculosMaquinaria: ConjuntoVehiculo[] = [];

  cellClass = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'cellTooltip';
    }
    return '';
  }

  public columns: any[] = [
    { datafield: 'id', hidden: true },
    { datafield: 'selec', hidden: true },
    {
      text: this.translate('Icono'), columntype: 'text', datafield: 'Icono',
      cellsrenderer: this.imagerenderer.bind(this),
    },
    {
      text: this.translate('CODIGO'), columntype: 'text', datafield: 'Codigo', cellsalign: 'right', cellclassname: this.cellClass,
    },
    {
      text: this.translate('Matricula'), columntype: 'text', datafield: 'Matricula', cellclassname: this.cellClass,
    },
    {
      text: this.translate('Nombre'), columntype: 'text', datafield: 'Nombre', cellclassname: this.cellClass,
    },
    {
      text: this.translate('Subflota'), columntype: 'text', datafield: 'Subflota', cellclassname: this.cellClass,
    },
    {
      text: this.translate('Recurso'), columntype: 'text', datafield: 'Recurso', cellclassname: this.cellClass,
    },
    {
      text: this.translate('Clase'), columntype: 'text', datafield: 'Clase', cellclassname: this.cellClass,
    },
    {
      text: this.translate('Tipo'), columntype: 'text', datafield: 'Tipo', cellclassname: this.cellClass,
    },
    {
      text: this.translate('Base'), columntype: 'text', datafield: 'Base', cellclassname: this.cellClass,
    },
    {
      text: 'GLS', columntype: 'text', datafield: 'GLS', cellclassname: this.cellClass,
    },
    {
      text: this.translate('Perifericos'), columntype: 'text', datafield: 'Perifericos', cellclassname: this.cellClass,
    },
    {
      text: this.translate('Sensores'), columntype: 'text', datafield: 'Sensores', cellclassname: this.cellClass,
    },
  ];

  public source = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'int', map: 'id' },
      { name: 'selec', type: 'string', map: 'selec' },
      { name: 'Icono', type: 'string', map: 'icono' },
      { name: 'Codigo', type: 'int', map: 'codigo' },
      { name: 'Matricula', type: 'string', map: 'matricula' },
      { name: 'Nombre', type: 'string', map: 'nombre' },
      { name: 'Subflota', type: 'string', map: 'subFlota' },
      { name: 'Recurso', type: 'string', map: 'recurso' },
      { name: 'Clase', type: 'string', map: 'clase' },
      { name: 'Tipo', type: 'string', map: 'tipo' },
      { name: 'Base', type: 'string', map: 'base' },
      { name: 'GLS', type: 'string', map: 'gls' },
      { name: 'Perifericos', type: 'string', map: 'perifericos' },
      { name: 'Sensores', type: 'string', map: 'sensores' },
    ],
    localdata: this.vehiculos
  }
  public dataAdapter = new jqx.dataAdapter(this.source);

  constructor(
    private ssoService: SsoService,
    private conjuntoVehiculoService: ConjuntoVehiculoService,
  ) {
  }

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  ngOnInit() {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  ngAfterContentInit() {
    this.getMoviles();
  }

  async getMoviles() {
    this.isLoading = true;
    this.vehiculosMaquinaria = await this.conjuntoVehiculoService.getConjuntosVehiculo();

    if (this.vehiculosMaquinaria) {
      this.vehiculosMaquinaria.forEach(conjVeh => {
        if (conjVeh.clases && conjVeh.clases.length != 0) {
          conjVeh.clase = conjVeh.clases[0];
        }

        if (conjVeh.carrozados && conjVeh.carrozados.length != 0) {
          conjVeh.carrozado = conjVeh.carrozados[0];
        }

        if (conjVeh.vehiculos && conjVeh.vehiculos.length != 0) {
          conjVeh['visible'] = conjVeh.vehiculos[0].idLicencia;
        }

        conjVeh.vehiculos.forEach(veh => {
          this.vehiculos.push(this.createVehiculo(conjVeh, veh));
        })
      });

      this.refrescarGrid();
    }
    this.isLoading = false;
  }

  // Transformador vehiculo
  createVehiculo(conjuntoVehiculo, vehiculo) {
    return {
      'icono': conjuntoVehiculo.icono || '',
      'codigo': vehiculo.idLicencia || '',
      'matricula': vehiculo.matricula || '',
      'nombre': vehiculo.descripcionVehiculo || '',
      'subflota': vehiculo.subFlota,
      'recurso': conjuntoVehiculo.recurso?.nombre || '',
      'clase': conjuntoVehiculo.clase?.name || '',
      'tipo': vehiculo.tipoMovil || '',
      'base': '',
      'gls': '',
      'perifericos': '',
      'sensores': ''
    }
  }

  refrescarGrid() {
    this.source.localdata = this.vehiculos;
    this.dataAdapter = new jqx.dataAdapter(this.source);
    this.gridVehiculos.updatebounddata('data');
    this.__updateWindowTitle();

    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
  }

  onFilter() {
    this.__updateWindowTitle();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.vehiculos.forEach(veh => {
      if (
        (veh?.codigo + '').compareWith(filtervalue) ||
        (veh?.matricula + '').compareWith(filtervalue) ||
        (veh?.nombre + '').compareWith(filtervalue) ||
        (veh?.subflota + '').compareWith(filtervalue) ||
        (veh?.recurso + '').compareWith(filtervalue) ||
        (veh?.clase + '').compareWith(filtervalue) ||
        (veh?.tipo + '').compareWith(filtervalue) ||
        (veh?.base + '').compareWith(filtervalue) ||
        (veh?.gls + '').compareWith(filtervalue) ||
        (veh?.perifericos + '').compareWith(filtervalue) ||
        (veh?.sensores + '').compareWith(filtervalue)
      ) {
        veh['selec'] = 'selec';
      } else {
        veh['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.gridVehiculos.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.gridVehiculos.addfilter('selec', filtergroup);
    }
    this.gridVehiculos.applyfilters();
    this.gridVehiculos.updatebounddata('data');
  }

  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getMoviles();
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  imagerenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    const imgElement = document.createElement('img');

    imgElement.src = value ? `data:image/jpg;base64,${value}` : `assets/images/car.png`;
    imgElement.style.setProperty('margin-left', '4px');
    imgElement.style.setProperty('margin-top', '6.5px');
    imgElement.style.setProperty('width', '16px');
    imgElement.style.setProperty('height', '16px');

    return imgElement.outerHTML;
  }

  private __updateWindowTitle(): void {
    this.totalRegistros.set(`(${this.gridVehiculos.getrows().length})`)
  }
}