import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { SsoService } from 'src/app/services/sso/sso.service';

import { TarjetaModel } from 'src/app/services/ciudadanos/models/tarjeta.model';
import { CustomForms } from '../../forms/custom-forms';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';
import { NumberUtils } from 'src/app/utils/number-utils';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-ciudadanos-listado-tarjetas',
  templateUrl: './ciudadanos-listado-tarjetas.component.html',
  styleUrls: ['./ciudadanos-listado-tarjetas.component.css']
})
export class CiudadanosListadoTarjetasComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('gridTarjetas') gridTarjetas: jqxGridComponent;

  private componentRef = null;
  public theme = environment.tema;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  tarjetas: TarjetaModel[] = [];
  ciudadano;

  renderMasterColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (value) {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">'+this.translate('Master')+'</div>';
    }
    else{
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">'+this.translate('Blanca')+'</div>';
    }
  };

  renderDireccionTarjeta(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any): string
  {
    let showText = '';

    if(value) {
      showText =
      (`<div class="jqx-grid-cell-left-align" style="margin-top: 8px;">` +
        (value.direccion ? value.direccion : '') +
        (value.numero ?  ', ' + value.numero : '') +
        (value.poblacion ?  ', ' + value.poblacion : '') +
        (value.provincia ?  ', ' + value.provincia : '') +
      `</div>`);
    }

    return showText;
  }

  renderTipo(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return '<div class="jqx-grid-cell-middle-align" style="margin-top: 8px; cursor: pointer">V</div>';
    } else {
      return '<div class="jqx-grid-cell-middle-align" style="margin-top: 8px; cursor: pointer">F</div>';
    }
  }

  public columnsTarjetas = [
    { text: this.translate('Modo'), columntype: 'textbox', datafield: 'virtual', width: 80, cellsrenderer: this.renderTipo,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      },
    },
    { text: this.translate('Ns_movisat'), columntype: 'textbox', datafield: 'nsMovisat', editable: false, width: 140 },
    { text: this.translate('Descripcion'), columntype: 'textbox', datafield: 'descripcion', editable: false, width: 100 },
    { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'master', cellsrenderer: this.renderMasterColumn, width: 90 },
    { text: this.translate('Direccion'), columntype: 'textbox', datafield: 'direccion', editable: false, cellsrenderer: this.renderDireccionTarjeta },
  ];

  public sourceTarjetas = {
    datatype: 'json',
    datafields: [
      { name: 'virtual', type: 'string', map: 'virtual' },
      { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
      { name: 'descripcion', type: 'string', map: 'descripcion' },
      { name: 'master', type: 'string', map: 'master' },
      { name: 'direccion', type: 'object', map: 'direccion'}
    ],
    localdata: this.tarjetas
  };

  public dataAdapterTarjetas = new jqx.dataAdapter(this.sourceTarjetas);

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(private ssoService: SsoService) {
    super();
  }

  public init(componentRef: any, ciudadano: CiudadanoModel): void {
    this.componentRef = componentRef;
    this.ciudadano = ciudadano;
    this.tarjetas = ciudadano.tarjetas;
    this.sourceTarjetas.localdata = this.tarjetas;
    this.dataAdapterTarjetas.dataBind();
  }

  async ngAfterViewInit(): Promise<void> {
    this.form.setTitle(this.translate('Tarjetas') + ' ' + this.ciudadano.nombre + ' ' + this.ciudadano.apellidos);
    this.addCustomForm(this.form);
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  closeWindow(){
    this.form.close();
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'nsMovisat',
      'descripcion',
      'direccion',
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }
}
