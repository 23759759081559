<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>

<jqxWindow #form [theme]="environment.tema" [position]="getFormPos(form, 24)" [zIndex]="900" [width]="950" [height]="482"
    [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Capas_kml')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <div style="float:left; margin-top: 6px; width: 100%;">
            <jqxGrid #gridKml [theme]="environment.tema" [width]="'100%'" [source]="dataAdapter" [columnsresize]="true"
                [localization]="langGrid" [filterable]="true" [showfilterrow]="true" [columns]="columns"
                [enablebrowserselection]="true" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false"
                [appCellClick] [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20"
                [showaggregates]="true" [rowsheight]="20" [columnsheight]="20" [enabletooltips]="true"
                [editable]="false" [filter]="filter" [updatefilterconditions]="updatefilterconditions"
                (onRowclick)="onRowSelect($event)" (onRowdoubleclick)="onRowdoubleclick($event)">
            </jqxGrid>
        </div>
        <div style="float: left; margin-top: 6px;">
            <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgSrc]='"/assets/images/ver.png"' [value]="translate('Ver')"
                (onClick)="onVer($event)">
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"'
                [value]="translate('Crear')" (onClick)="onCrearKml($event)" [hidden]="!canEdit">
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"'
                [value]="translate('Editar')" (onClick)="onEditarKml($event)" [hidden]="!canEdit">
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/borrar.png"'
                [value]="translate('Borrar')" (onClick)="onBorrarKml($event)" [hidden]="!canEdit">
            </jqxButton>
            <jqxButton style="float: left; margin-left: 4px;" [width]="100" [height]="26"
              [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
              [value]="translate('Salir')">
            </jqxButton>
        </div>
    </div>
</jqxWindow>

<div *ngIf="mostrarEditKml">
    <jqxWindow #formEditKml [position]="getFormPos(formEditKml)" [width]="640" [height]="220" [zIndex]="100"
        [autoOpen]="true" [isModal]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
        (onClose)="onCloseEditKml()" (onOpen)="onOpenEditKml()" (onExpand)="onExpandKml()">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Edicion_kml')}}
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div style="margin-top: 5px; float:left; clear:both;">
                {{translate('Nombre')}}
            </div>
            <div style="float:left; clear:both;">
                <jqxInput [(ngModel)]="kmlEdit.Nombre" [width]="630" [height]="25" [disabled]="!canEdit">
                </jqxInput>
            </div>
            <div style="margin-top: 5px; float:left; clear:both;">
                Url
            </div>
            <div style="float:left; clear:both;">
                <jqxInput [(ngModel)]="kmlEdit.Url" [width]="630" [height]="25" [disabled]="!canEdit">
                </jqxInput>
            </div>
            <div style="margin-top: 5px; float:left; clear:both;">
                {{translate('Observaciones')}}
            </div>
            <div style="float:left; clear: both;">
                <jqxInput [(ngModel)]="kmlEdit.Observaciones" [width]="630" [height]="25" [disabled]="!canEdit">
                </jqxInput>
            </div>
            <div style="position:absolute; bottom:5px; left:5px; margin-top: 23px; clear: both; ">
                <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                    [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' [value]="translate('Guardar')"
                    (onClick)="onGuardarKml($event)" [disabled]="!canEdit">
                </jqxButton>
            </div>
        </div>
    </jqxWindow>
</div>
