import {
  OnInit,
  Component,
  ViewChild,
  AfterViewInit,
  ViewContainerRef,
  ChangeDetectorRef
} from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';
import {
  MapComponent,
  MapLatLng,
  MapMarker,
  MapCircle,
  MapPolygonPoint,
} from 'movisat-maps';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { PuService } from 'src/app/services/pu/pu.service';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { ElementsService } from 'src/app/services/elements/elements.service';
import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';

import { PuModel } from 'src/app/services/pu/models/pu.model';
import { TagModel } from 'src/app/services/elements/models/tag.model';
import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { BdtCatalogoEquipamientoModel } from 'src/app/services/bdt/models/bdt-catalogo-equipamiento.model';
import { ElemInstalacionModel } from 'src/app/services/elements/models/instalacion/elem-instalacion.model';

import { ElementsComponent } from '../elements.component';
import { ElementsCatalogComponent } from '../elements-catalog/elements-catalog.component';
import { ElementsEditDetalleComponent } from './elements-edit-detalle/elements-edit-detalle.component';
import { ElementsEditGestionComponent } from './elements-edit-gestion/elements-edit-gestion.component';
import { ElementsEditInformacionComponent } from './elements-edit-informacion/elements-edit-informacion.component';
import { ElementsEditPerifericosComponent } from './elements-edit-perifericos/elements-edit-perifericos.component';
import { ElementsEditInstalacionesComponent } from './elements-edit-instalaciones/elements-edit-instalaciones.component';
import { ElementsEditHistoricoCoordenadasComponent } from './elements-edit-historico-coordenadas/elements-edit-historico-coordenadas.component';
import { InstalacionService } from 'src/app/services/elements/instalacion/instalacion.service';
import { VolumService } from 'src/app/services/volumetricos/volum.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-elements-edit',
  templateUrl: './elements-edit.component.html',
  styleUrls: ['./elements-edit.component.css'],
})
export class ElementsEditComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('elemRibbon') elemRibbon;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('component', { read: ViewContainerRef }) component;

  @ViewChild('tabDetalle') tabDetalle: ElementsEditDetalleComponent;
  @ViewChild('tabGestion') tabGestion: ElementsEditGestionComponent;
  @ViewChild('tabPeriferico') tabPeriferico: ElementsEditPerifericosComponent;
  @ViewChild('tabInformacion') tabInformacion: ElementsEditInformacionComponent;
  @ViewChild('tabInstalacion') tabInstalacion: ElementsEditInstalacionesComponent;
  @ViewChild('tabHistoricoCoordenadas') tabHistoricoCoordenadas: ElementsEditHistoricoCoordenadasComponent;

  public closed = false;
  public expanded = true;

  public canEdit = true;
  ejecutado: boolean = false;

  environment = environment;

  crearMultiples = false;
  elemEdit: ElementoModel = new ElementoModel();

  map: MapComponent;
  markerHistorico: MapMarker;

  // Recoge las medidas de la parte equivalente al mapa
  mapWidth;
  mapHeight;

  private componentRef = null;

  public static _this: ElementsEditComponent;
  public catEquipSelec: BdtCatalogoEquipamientoModel;

  private save = false;
  private oldIcon: any = null;
  private oldImeiVolumetrico: string = null;

  // Variables de suscripción
  private subscriptionMapClick: Subscription;
  private subscriptionCircleClick: Subscription;
  private subscriptionPolygonClick: Subscription;
  private subscriptionMarkerClick: Subscription;
  private subscriptionOnMarkerDragEnd: Subscription;
  showLoader: boolean = true;

  private subscriptions: Subscription[] = [];

  public isSaving: boolean = false;

  // Para traducir los textos del template
  translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private puService: PuService,
    private ssoService: SsoService,
    private elemService: ElementsService,
    private instalacionService: InstalacionService,
    private cerraduraService: CerraduraService,
    private volumService: VolumService,
    private zonesService: ZonesService,
    private cdRef: ChangeDetectorRef,
  ) {
    super();
    ElementsEditComponent._this = this;
  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    setTimeout(() => {
      this.map = MainComponent.getInstance().getMap();
      this.showLoader = false;
    }, 1000);
  }

  // Este método es llamado por el creador del componente
  async init(componentRef: any, elemento: ElementoModel) {
    this.componentRef = componentRef;

    if (elemento) {
      try {
        this.elemEdit = elemento;
        this.elemEdit = await this.elemService.getElement(elemento);

        if (this.elemEdit) {
          this.elemEdit.Instalacion = await this.instalacionService.getInstalacionByElement(this.elemEdit.Id);
          this.elemEdit.Marker = elemento.Marker;
          this.elemEdit.FechaInstalacionVolum = new Date(this.elemEdit.FechaInstalacionVolum);

          this.elemEdit.Instalacion = this.elemEdit.Instalacion || new ElemInstalacionModel();
          this.elemEdit.Instalacion.Detalle = this.elemEdit.Instalacion.Detalle || [];

          if (this.elemEdit.Volumetrico) {
            this.oldImeiVolumetrico = this.elemEdit.Volumetrico.imei || this.elemEdit.Volumetrico.Imei;
          }

          if (this.elemEdit.Marker)

            if (this.map && this.elemEdit.Marker) {
              this.markerHistorico = this.map.addMarker({
                ...this.elemEdit.Marker,
                visible: true
              });
            }
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      this.elemEdit = new ElementoModel();
      this.elemEdit.Imagenes = [];
    }

    if (this.elemEdit?.Id > 0 && this.elemEdit.Marker) {
      this.oldIcon = this.elemEdit.Marker.icon;
      this.crearMultiples = false;
    }
  }


  changeTab(event: any) {
    const index = event.args.selectedIndex;
    const initFunctions = [
      () => this.tabInformacion.initGridHistorico(),
      () => this.tabDetalle.initGridClasesAsociadas(),
      () => {
        this.tabPeriferico.inputNumbers.forEach(inputNumber => {
          inputNumber.setValue(inputNumber.value);
        });
        this.tabPeriferico.getCatalogoCerradurasWithoutElement();
      },
      () => {
        this.tabInstalacion.initGridIncidenciasVolum();
        this.tabInstalacion.initGridIncidenciasTag();
        this.tabInstalacion.initGridIncidenciasEcolock();
      },
      () => {
        this.tabGestion.initGridOperRecientes();
        this.tabGestion.initGridHistIncidencias();
      },
      () => this.tabHistoricoCoordenadas.initGridHistCoordenadas()
    ];

    if (initFunctions[index]) {
      initFunctions[index]();
    }
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
    this.form.setTitle(`<b>${this.translate('Edicion_elementos')}</b>`);

    const ribbonDiv = this.elemRibbon.elementRef.nativeElement.firstChild as HTMLElement;
    if (ribbonDiv) {
      ribbonDiv.style.height = '100%';
    }
    this.showLoader = false;
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribeAll();
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  unsubscribeAll() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }


  closeWindow() {
    this.form?.close();
    // borro el marker
    if (this.elemEdit?.Marker) {
      this.map.removeMarker(this.elemEdit?.Marker);
    }
  }

  setPunteroPosicionar(posicionando: boolean) {
    const mapElement = document.querySelector('.gm-style > div') as HTMLElement;
    if (mapElement) {
      mapElement.classList.toggle('setPosition', posicionando);
    }
  }

  // Cuando se visualiza el formulario
  async onOpen(): Promise<void> {
    // TODO: ver por que se hace esta llamada si ya esta realizanod en la linea 140
    this.elemEdit = await this.elemService.getElement(this.elemEdit);
    if (this.elemEdit) {
      this.form?.setTitle(`<b>${this.translate('Edicion_elementos')}: ${this.elemEdit?.Nombre ?? ''}</b>`);

      this.elemEdit.Tag = this.elemEdit.Tag || new TagModel();
      this.elemEdit.IdCircuito = this.elemEdit.IdCircuito || '';

      const estadoTextos = {
        0: 'Sin instalar',
        1: 'Instalado pendiente lectura',
        2: 'Instalado correctamente'
      };
      this.elemEdit.estadoInstallTexto = estadoTextos[this.elemEdit.EstadoInstalVolum] || '';

      if (this.elemEdit.Id > 0 && this.elemEdit.Marker) {
        this.onPosicionar();
      }
    } else {
      this.elemEdit = new ElementoModel();
    }
  }

  // Esto es para que no se quede el desplegable abierto cuando se minimiza el formulario
  onCollapse() {
    this.expanded = false;
  }

  // al expandir el formulario
  onExpand() {
    if (this.subscriptionMapClick) {
      this.expanded = true;
      this.subscriptionMapClick.unsubscribe();
      this.subscriptionMapClick = null;
      this.setPunteroPosicionar(false);
    }
  }

  // Cierro el formulario y destruyo el componente
  async onClose() {
    this.closed = true;
    if (this.markerHistorico) {
      this.map.removeMarker(this.markerHistorico);
    }

    if (this.elemEdit?.Marker) {
      if (this.elemEdit.Id < 1) {
        this.map.removeMarker(this.elemEdit.Marker);
      } else if (!this.save && this.oldIcon) {
        this.elemEdit.Marker.setIcon(this.oldIcon);
      }
    }

    if (ElementsEditHistoricoCoordenadasComponent._this?.coordinateMarker) {
      ElementsEditHistoricoCoordenadasComponent._this.coordinateMarker.setVisible(false);
    }

    this.unsubscribeAll();
    if (ElementsCatalogComponent._this) {
      ElementsCatalogComponent._this.form.expand();
    }
    this.componentRef?.destroy();
  }

  onCreacionMultiple(event: any) {
    this.crearMultiples = event.target.checked;
    if (!event.target.checked) {
      setTimeout(() => {
        this.elemEdit.Lat = this.elemEdit.Lng = 0;
      }, 500);
    }
  }

  onGuardar = async () => {
    if (this.showLoader) {
      return;
    }

    // Validaciones previas al guardado
    const numReg = ElementsComponent.getInstance().elementList.filter(
      elem => elem.Equipamiento.Id === this.catEquipSelec.Equipamiento.Id && !elem.FechaBaja
    ).length;

    if (this.catEquipSelec.Unidades > 0 && this.elemEdit.Id < 1 && numReg >= this.catEquipSelec.Unidades) {
      MainComponent.getInstance().showError('ATENCION', 'Superado_max_elementos', 2000);
      return;
    }

    if (!this.elemEdit.Equipamiento) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_equipamiento', 2000);
      return;
    }

    if (!this.elemEdit.Nombre && !this.crearMultiples) {
      MainComponent.getInstance().showWarning('ATENCION', 'Introduzca_nombre', 2000);
      return;
    }

    if (this.elemEdit.Lat === 0 && this.elemEdit.Lng === 0) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_punto_cartografia', 2000);
      return;
    }

    if (this.elemEdit.Id > 0) {
      const pu = await this.puService.getPuntoUbicacionByElemento(this.elemEdit.Id);
      if (pu?.Equipamiento?.IdModelo !== this.elemEdit.Equipamiento.IdModelo) {
        MainComponent.getInstance().showWarning('ATENCION', 'PU_distinto_modelo', 2000);
        this.form.close();
        return;
      }
    }

    this.showLoader = true;

    try {
      if (!this.elemEdit.Guid) {
        this.elemEdit.Guid = crypto.randomUUID();
      }

      this.elemEdit.Empresa = this.ssoService.getTicket().Empresa.IdGestion;
      this.elemEdit.IdEquipamiento = this.elemEdit.Equipamiento.Id;

      // Filtramos el detalle de instalación
      this.elemEdit.Instalacion.Detalle =
        (this.elemEdit.Instalacion.Detalle || []).filter(det => det.id !== 0);

      const saveResult = await this.elemService.saveElemento(this.elemEdit);

      if (saveResult !== null) {
        MainComponent.getInstance().showInfo('ATENCION', 'Registro_almacenado', 2000);

        if (this.elemEdit.cerradura) {
          const asociacion = {
            idCerradura: this.elemEdit.cerradura.id,
            idElemento: saveResult.Id,
            fecha: new Date()
          };
          await this.cerraduraService.associateCerraduraToElement(asociacion);
        }

        if (this.oldImeiVolumetrico) {
          await this.volumService.updateVolumetrico(this.oldImeiVolumetrico, null);
        }

        const imei = this.elemEdit.Volumetrico?.Imei || this.elemEdit.Volumetrico?.imei;
        if (imei) {
          await this.volumService.updateVolumetrico(imei, this.elemEdit.Id);
        }

        this.save = true;

        if (!this.crearMultiples) {
          this.form.close();
        }
      } else {
        MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.showLoader = false;
    }
  }

  // Posicionar el componente sobre la cartografía
  private onPosicionar(): void {
    if (this.elemEdit.Id < 1) {
      this.form.collapse();
      this.setPunteroPosicionar(true);

      if (!this.subscriptionMapClick) {
        this.subscriptionMapClick = this.subscribeOnMapClick();
        this.subscriptions.push(this.subscriptionMapClick);
      }

      if (!this.subscriptionMarkerClick) {
        this.subscriptionMarkerClick = this.subscribeOnMarkerClick();
        this.subscriptions.push(this.subscriptionMarkerClick);
      }

      if (!this.subscriptionCircleClick) {
        this.subscriptionCircleClick = this.map.subscribeOnCircleClick(
          this,
          (_this: any, circle: MapCircle) => {
            this.map.onMapClick(circle.clickPoint);
          }
        ) as unknown as Subscription;
        this.subscriptions.push(this.subscriptionCircleClick);
      }

      if (!this.subscriptionPolygonClick) {
        this.subscriptionPolygonClick = this.map.subscribeOnPolygonClick(
          this,
          (_this: any, polygon: MapPolygonPoint) => {
            this.map.onMapClick(polygon.clickPoint);
          }
        ) as unknown as Subscription;
        this.subscriptions.push(this.subscriptionPolygonClick);
      }

      if (!this.subscriptionOnMarkerDragEnd) {
        this.subscriptionOnMarkerDragEnd = this.map.subscribeOnMarkerDragEnd(
          this,
          (_this: any, marker: MapMarker) => {
            if (MainComponent.getInstance().controlAmbitoActividad(marker.position)) {
              this.elemEdit.Lat = marker.position.lat;
              this.elemEdit.Lng = marker.position.lng;
            } else {
              if (this.elemEdit.Lat && this.elemEdit.Lng) {
                MainComponent.getInstance().showError('ATENCION', 'Fuera_ambito', 2000);
                marker.setPosition(new MapLatLng(this.elemEdit.Lat, this.elemEdit.Lng));
              }
            }
          }
        ) as unknown as Subscription;
        this.subscriptions.push(this.subscriptionOnMarkerDragEnd);
      }
    } else {
      this.map.setCenter(this.elemEdit.Marker.position);
      this.elemEdit.Marker.setZIndex(999);
      if (this.map.zoom < 18) {
        this.map.setZoom(18);
      }
      this.form.collapse();
      this.elemEdit.Marker.animate(2850);
    }
  }


  subscribeOnMapClick(): Subscription {
    return this.map.subscribeOnMapClick(this, async (_this: ElementsEditComponent, position: MapLatLng) => {
      if (_this.elemEdit.Id < 1) {
        if (!MainComponent.getInstance().controlAmbitoActividad(position)) {
          MainComponent.getInstance().showError('ATENCION', 'Fuera_ambito', 2000);
          return;
        }

        const result = await _this.zonesService.getDireccion(position.lat, position.lng);
        Object.assign(this.elemEdit, {
          Calle: result.Street,
          Poblacion: result.District,
          CodigoPostal: result.PostalCode,
          Provincia: result.AdministrativeArea2,
          Pais: result.Country,
          NumeroCalle: result.StreetNumber,
          Municipio: result.Locality,
          ComunidadAutonoma: result.AdministrativeArea1
        });

        if (_this.elemEdit.Marker) {
          _this.map.removeMarker(_this.elemEdit.Marker);
        }

        _this.elemEdit.Lat = position.lat;
        _this.elemEdit.Lng = position.lng;
        _this.elemEdit.IdPU = 0;
        _this.elemEdit.Marker = _this.map.addMarker({
          dataModel: _this.elemEdit,
          title: _this.elemEdit.Nombre,
          content: `<b>${_this.elemEdit.Nombre}</b><hr>${_this.elemEdit.Equipamiento.Elemento.Nombre}<br>${_this.elemEdit.Equipamiento.Marca.Nombre}<br>${_this.elemEdit.Equipamiento.Modelo.Nombre}`,
          position: position,
          icon: _this.getIcon(),
          zIndex: 999,
          drag: true,
          visible: true
        });
      }
      if (!this.crearMultiples) {
        _this.form.expand();
      } else {
        _this.resetElementForMultipleCreation();
        await _this.onGuardar();
      }
    }) as unknown as Subscription;
  }

  subscribeOnMarkerClick(): Subscription {
    return this.map.subscribeOnMarkerClick(this, (_this: ElementsEditComponent, marker: MapMarker) => {
      if (marker.dataModel.hasOwnProperty('IdElemento')) {
        if (_this.elemEdit.Id < 1) {
          if (_this.elemEdit.Marker) {
            _this.map.removeMarker(this.elemEdit.Marker);
          }
          const pu: PuModel = marker.dataModel;
          if (pu?.Equipamiento?.IdModelo !== _this.elemEdit.Equipamiento.IdModelo) {
            MainComponent.getInstance().showWarning('ATENCION', 'PU_distinto_modelo', 2000);
            return;
          }
          if (pu.IdElemento > 0) {
            MainComponent.getInstance().showWarning('ATENCION', 'PU_ya_tiene_elemento', 2000);
            return;
          }
          _this.elemEdit.IdPU = pu.Id;
          _this.elemEdit.Lat = marker.position.lat;
          _this.elemEdit.Lng = marker.position.lng;

          _this.elemEdit.Marker = _this.map.addMarker({
            dataModel: _this.elemEdit,
            title: _this.elemEdit.Nombre,
            content: `<b>${_this.elemEdit.Nombre}</b><hr>${_this.elemEdit.Equipamiento.Elemento.Nombre}<br>${_this.elemEdit.Equipamiento.Marca.Nombre}<br>${_this.elemEdit.Equipamiento.Modelo.Nombre}`,
            position: marker.position,
            icon: _this.getIcon(),
            zIndex: 999,
            drag: true,
            visible: true
          });

        }
        _this.subscriptionMarkerClick.unsubscribe();
        _this.subscriptionMarkerClick = null;
        _this.setPunteroPosicionar(false);
        _this.form.expand();
      }
    }) as unknown as Subscription;
  }

  private getIcon(): string {
    const icono = this.elemEdit.Equipamiento.Icono;
    return !this.elemService.elemGenericos || (icono && icono.length > 50) ? `data:image/png;base64,${icono}` : 'assets/images/elemento.png';
  }

  private resetElementForMultipleCreation() {
    this.elemEdit.Id = 0;
    this.elemEdit.Guid = '';
    this.elemEdit.Nombre = '';
    if (this.elemEdit.Marker) {
      this.map.removeMarker(this.elemEdit.Marker);
    }
    this.elemEdit.Marker = null;
  }

  expandForm() {
    this.form.expand();
    this.form.bringToFront();
  }
}
