<jqxWindow id="windowForm" #windowForm [jqxWindowAutoResize] [width]="mapWidth" [height]="mapHeight - 24" [zIndex]="900"
  [theme]="environment.tema" [isModal]="false" [position]="getFormPos(windowForm, 24)" [showCloseButton]="true"
  [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()" (onResizing)="onResizing($event)"
  [title]="nsMovisatCerradura ? translate('Configuracion_cerraduras') + ': ' + configuracionCerradura.nombre + ' (' + nsMovisatCerradura +')'
    : translate('Configuracion_cerraduras') + ': ' + configuracionCerradura.nombre">

  <div class="formHeader" style="overflow-x: hidden">
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <jqxRibbon #ciudRibbon [theme]="environment.tema" [position]="'top'" [height]="'calc(100% - 30px)'">
      <ul style="width: 100%; text-align: left; z-index: 99; background-color: rgb(225, 229, 231);">
        <li style="--apartado: GRAL;">
          {{ translate('Datos_generales') }}
        </li>
        <li style="--apartado: CONF;">
          {{ translate('Configuracion_e_interacciones') }}
        </li>
      </ul>

      <div>
        <!-- PESTAÑA GENERAL -->
        <div style="--apartado: GRAL; background-color: transparent; padding: 0%; overflow-y: auto;">
          <div style="display: inline-table !important; width: 100%; height: 100%;">
            <div id="fieldGeneral" class="fieldGeneralLg">
              <fieldset>
                <legend>
                  {{ translate('Generales') }}
                </legend>

                <div class="row" style="display: flex; justify-content: space-between;">
                  <span style="width: 15%;">
                    <div style="clear: both">
                      {{ translate("Nombre") }}
                    </div>
                    <jqxInput [(ngModel)]="configuracionCerradura.nombre" [width]="'100%'"
                      [disabled]="!adminUser || desdeListadoCerraduras ">
                    </jqxInput>
                  </span>
                  <span style="width: 15%;">
                    <div style="clear: both;">
                      {{ translate("Servidor") }}
                    </div>
                    <jqxInput [(ngModel)]="configuracionCerradura.host" [width]="'100%'"
                      [disabled]="!adminUser || desdeListadoCerraduras ">
                    </jqxInput>
                  </span>
                  <span style="width: 5%;">
                    <div style="clear: both;">
                      {{ translate("Puerto") }}
                    </div>
                    <app-input-number [value]="configuracionCerradura.puerto"
                      (onChange)="configuracionCerradura.puerto = $event" [render]="renderer()" [width]="'100%'"
                      [renderizar]="false" [disabled]="!adminUser || desdeListadoCerraduras ">
                    </app-input-number>
                  </span>

                  <span style="width: 8%; float: left;">
                    <div style="clear: both;">
                      {{ translate("Orientacion") }}
                    </div>
                    <jqxDropDownButton #dropDownOrientacion [width]="'100%'" (onClose)="onCloseDropOrientacion()"
                      (onOpen)="onOpenDropOrientacion()" [disabled]="!adminUser || desdeListadoCerraduras "
                      (focusout)="dropDownOrientacion.close()">
                      <div [hidden]="gridDropOrientacion" style="width: 100%;">
                        <jqxGrid #gridOrientacion [height]="arrayGridOrientacion.length*20"
                          [source]="adapterGridOrientacion" [enablebrowserselection]="true" [columnsresize]="true"
                          [selectionmode]="'singlerow'" [columns]="columnsGridOrientacion" [sortable]="true"
                          [altrows]="true" [showrowlines]="true" [rowsheight]="20" [columnsheight]="20"
                          [enabletooltips]="true" [localization]="langGrid" (onRowclick)="onRowOrientacionClick($event)"
                          [showheader]="false">
                        </jqxGrid>
                      </div>
                    </jqxDropDownButton>
                  </span>

                  <span style="width: 30%; float: left;">
                    <div style="clear: both">
                      {{ translate("Tipo_contenedor") }}
                    </div>
                    <jqxDropDownButton #dropDownContenedor [width]="'100%'" (onClose)="onCloseDropContenedor()"
                      (onOpen)="onOpenDropContenedor()" [disabled]="!adminUser || desdeListadoCerraduras "
                      (focusout)="dropDownContenedor.close()">
                      <div [hidden]="gridDropContenedor" style="width: 100%;">
                        <jqxGrid #gridContenedor [height]="arrayGridContenedor.length*20" [appCellClick]
                          [source]="adapterGridContenedor" [columnsresize]="true" [selectionmode]="'singlerow'"
                          [enablebrowserselection]="true" [columns]="columnsGridContenedor" [sortable]="true"
                          [altrows]="true" [showrowlines]="true" [rowsheight]="20" [columnsheight]="20"
                          [enabletooltips]="true" [localization]="langGrid" (onRowclick)="onRowContenedorClick($event)"
                          [showheader]="false">
                        </jqxGrid>
                      </div>
                    </jqxDropDownButton>
                  </span>

                  <span style="width: 10%; float: left;">
                    <div style="clear: both">
                      {{ translate("Tipo_cerradura") }}
                    </div>
                    <jqxDropDownButton #dropDownCerradura [width]="'100%'" (onClose)="onCloseDropCerradura()"
                      (onOpen)="onOpenDropCerradura()" [disabled]="!adminUser || desdeListadoCerraduras "
                      (focusout)="dropDownCerradura.close()">
                      <div [hidden]="gridDropCerradura" style="width: 100%;">
                        <jqxGrid #gridCerradura [height]="arrayGridCerradura.length*20" [source]="adapterGridCerradura"
                          [appCellClick] [columnsresize]="true" [selectionmode]="'singlerow'"
                          [columns]="columnsGridCerradura" [sortable]="true" [altrows]="true" [showrowlines]="true"
                          [rowsheight]="20" [enablebrowserselection]="true" [columnsheight]="20" [enabletooltips]="true"
                          [localization]="langGrid" (onRowclick)="onRowCerraduraClick($event)" [showheader]="false">
                        </jqxGrid>
                      </div>
                    </jqxDropDownButton>
                  </span>

                  <span style="width: 10%; float: left; margin-top: 1.4em;">
                    <jqxCheckBox [checked]="configuracionCerradura ? configuracionCerradura?.siempreAbierto : false"
                      (onChange)="changeSiempreAbierto($event)">
                      {{translate('Siempre_abierto')}}
                    </jqxCheckBox>
                  </span>
                </div>
              </fieldset>
            </div>

            <div id="fieldTipoCerradura" class="fieldTipoCerraduraLg"
              [hidden]="configuracionCerradura.tipoCerradura === 0">
              <fieldset>
                <legend>
                  {{ translate("Tipo_cerradura") }}
                </legend>

                <div class="row">
                  <span class="inlineField" style="width: 20%;">
                    <div style="width: 100%; margin-left: 10px;">
                      {{ translate("Pend_ecuac_apert") }}
                    </div>
                    <div class="inputNumber">
                      <app-input-number [value]="configuracionCerradura.pendienteEcuacionApertura" [render]="renderer()"
                        [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                      </app-input-number>
                    </div>
                  </span>
                  <span class="inlineField" style="width: 20%;">
                    <div style="width: 100%; margin-left: 10px;">
                      {{ translate("Pend_ecuac_cierre") }}
                    </div>
                    <div class="inputNumber">
                      <app-input-number [value]="configuracionCerradura.pendienteEcuacionCierre" [render]="renderer()"
                        [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                      </app-input-number>
                    </div>
                  </span>
                  <span class="inlineField" style="width: 20%;">
                    <div style="width: 100%; margin-left: 10px;">
                      {{ translate("Ord_orig_ecu_apert") }}
                    </div>
                    <div class="inputNumber">
                      <app-input-number [value]="configuracionCerradura.ordenOrigenEcuApertura" [render]="renderer()"
                        [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                      </app-input-number>
                    </div>
                  </span>
                  <span class="inlineField" style="width: 20%;">
                    <div style="width: 100%; margin-left: 10px;">
                      {{ translate("Ord_orig_ecu_cierre") }}
                    </div>
                    <div class="inputNumber">
                      <app-input-number [value]="configuracionCerradura.ordenOrigenEcuCierre" [render]="renderer()"
                        [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                      </app-input-number>
                    </div>
                  </span>
                  <span class="inlineField" style="width: 20%;">
                    <div style="width: 100%; margin-left: 10px;">
                      {{ translate("Tiem_esp_act_fin_apert") }}
                    </div>
                    <div class="inputNumber">
                      <app-input-number [value]="configuracionCerradura.esperaEcuApertura" [render]="renderer()"
                        [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                      </app-input-number>
                    </div>
                  </span>
                </div>

                <div class="row">
                  <span class="inlineField" style="width: 20%;">
                    <div style="width: 100%; margin-left: 10px;">
                      {{ translate("Tiem_esp_act_fin_cierre") }}
                    </div>
                    <div class="inputNumber">
                      <app-input-number [value]="configuracionCerradura.esperaEcuCierre" [render]="renderer()"
                        [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                      </app-input-number>
                    </div>
                  </span>
                  <span class="inlineField" style="width: 20%;">
                    <div style="width: 100%; margin-left: 10px;">
                      {{ translate("Tiem_act_cortas_fin_apert") }}
                    </div>
                    <div class="inputNumber">
                      <app-input-number [value]="configuracionCerradura.tiempoActCortasApertura" [render]="renderer()"
                        [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                      </app-input-number>
                    </div>
                  </span>
                  <span class="inlineField" style="width: 20%;">
                    <div style="width: 100%; margin-left: 10px;">
                      {{ translate("Tiem_act_cortas_fin_cierre") }}
                    </div>
                    <div class="inputNumber">
                      <app-input-number [value]="configuracionCerradura.tiempoActCortasCierre" [render]="renderer()"
                        [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                      </app-input-number>
                    </div>
                  </span>
                  <span class="inlineField" style="width: 20%;">
                    <div style="width: 100%; margin-left: 10px;">
                      {{ translate("Umbral_consumo_bajo") }}
                    </div>
                    <div class="inputNumber">
                      <app-input-number [value]="configuracionCerradura.umbralConsumoBajo" [render]="renderer()"
                        [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                      </app-input-number>
                    </div>
                  </span>
                  <span class="inlineField" style="width: 20%;">
                    <div style="width: 100%; margin-left: 10px;">
                      {{ translate("Umbral_consumo_elevado") }}
                    </div>
                    <div class="inputNumber">
                      <app-input-number [value]="configuracionCerradura.umbralConsumoAlto" [render]="renderer()"
                        [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                      </app-input-number>
                    </div>
                  </span>
                </div>
              </fieldset>
            </div>

            <div id="fieldEquipamiento" class="fieldEquipamientoLg">
              <fieldset>
                <legend>
                  {{ translate('Equipamiento') }}
                </legend>

                <div class="row">
                  <span style="width: 25%;">
                    <div style="clear: both">
                      {{ translate('Marca') }}
                    </div>
                    <jqxInput [value]="configuracionElemento.equipamiento?.marca?.nombre" [width]="'100%'"
                      [disabled]="!adminUser || desdeListadoCerraduras ">
                    </jqxInput>
                  </span>
                  <span style="width: 25%;">
                    <div style="clear: both">
                      {{ translate('Modelo') }}
                    </div>
                    <jqxInput [value]="configuracionElemento.equipamiento?.modelo?.nombre" [width]="'100%'"
                      [disabled]="!adminUser || desdeListadoCerraduras ">
                    </jqxInput>
                  </span>
                  <span style="width: 30%;">
                    <div style="clear: both">
                      {{ translate('Residuo') }}
                    </div>
                    <jqxInput [value]="configuracionElemento?.equipamiento?.residuos ? configuracionElemento?.equipamiento?.residuos[0]?.nombre : ''" [width]="'100%'"
                      [disabled]="!adminUser || desdeListadoCerraduras ">
                    </jqxInput>
                  </span>
                  <span style="width: 20%;">
                    <div style="clear: both">
                      {{ translate('Capacidad_en_litros') }}
                    </div>
                    <div class="inputNumber" style="width: 40%;">
                      <app-input-number [value]="configuracionCerradura.equipamiento?.capacidad_litros"
                        [disabled]="!adminUser || desdeListadoCerraduras "
                        (onChange)="configuracionCerradura.intervaloEnvio = $event" [render]="renderer()"
                        [width]="'100%'">
                      </app-input-number>
                    </div>
                  </span>
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <!-- PESTAÑA CONFIGURACION E INTERACCIONES -->
        <div style="--apartado: CONF; background-color: transparent; padding: 0%; overflow-y: auto;">
          <div style="display: inline-table !important; width: 100%; height: 100%;">
            <div class="row">
              <div class="column" style="height: 100%; width: 70%;">
                <div id="fieldConfModem" class="fieldConfModemLg">
                  <fieldset>
                    <legend>
                      {{ translate("Configuracion_modem") }}
                    </legend>
                    <div class="row">
                      <span style="width: 35%;">
                        <div style="clear: both">
                          APN
                        </div>
                        <jqxInput [(ngModel)]="configuracionCerradura.apn" [width]="'100%'"
                          [placeHolder]="translate('Input_vacio')" [disabled]="!adminUser || desdeListadoCerraduras ">
                        </jqxInput>
                      </span>
                      <span style="width: 25%;">
                        <div style="clear: both">
                          {{ translate("USUARIO") }}
                        </div>
                        <jqxInput [(ngModel)]="configuracionCerradura.apnUsuario" [width]="'100%'"
                          [disabled]="!adminUser || desdeListadoCerraduras ">
                        </jqxInput>
                      </span>
                      <span style="width: 25%;">
                        <div style="clear: both">
                          {{ translate("PASSWORD") }}
                        </div>
                        <jqxInput [(ngModel)]="configuracionCerradura.apnPassw" [width]="'100%'"
                          [disabled]="!adminUser || desdeListadoCerraduras ">
                        </jqxInput>
                      </span>
                      <span style="width: 15%;">
                        <div style="clear: both">
                          PIN
                        </div>
                        <jqxInput [(ngModel)]="configuracionCerradura.pin" [width]="'100%'" [maxLength]="4"
                          [disabled]="!adminUser || desdeListadoCerraduras ">
                        </jqxInput>
                      </span>
                    </div>
                  </fieldset>
                </div>

                <div class="row">
                  <div id="fieldInterUsuarios" class="fieldInterUsuariosLg">
                    <fieldset>
                      <legend>
                        {{ translate("Interacciones_usuarios") }}
                      </legend>

                      <div class="row">
                        <span style="width: 33%;">
                          <div style="width: 100%;">
                            {{ translate("Tiempo_apertura") }} (sg)
                          </div>
                          <div class="inputNumber">
                            <app-input-number [value]="configuracionCerradura.tiempoAbrir"
                              (onChange)="configuracionCerradura.tiempoAbrir = $event" [render]="renderer()"
                              [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                            </app-input-number>
                          </div>
                        </span>
                        <span style="width: 33%;">
                          <div style="width: 100%;">
                            {{ translate("Tiempo_aporte") }} (sg)
                          </div>
                          <div class="inputNumber">
                            <app-input-number [value]="configuracionCerradura.tiempoAporte"
                              (onChange)="configuracionCerradura.tiempoAporte = $event" [render]="renderer()"
                              [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                            </app-input-number>
                          </div>
                        </span>
                        <span style="width: 33%;">
                          <div style="width: 100%;">
                            {{ translate("Tiempo_cierre") }} (sg)
                          </div>
                          <div class="inputNumber">
                            <app-input-number [value]="configuracionCerradura.tiempoCerrar"
                              (onChange)="configuracionCerradura.tiempoCerrar = $event" [render]="renderer()"
                              [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                            </app-input-number>
                          </div>
                        </span>
                      </div>
                    </fieldset>
                  </div>

                  <div id="fieldConfComunic" class="fieldConfComunicLg">
                    <fieldset>
                      <legend>
                        {{ translate("Configuracion_comunicaciones") }}
                      </legend>

                      <div class="row">
                        <span style="width: 35%;">
                          <div style="clear: both">
                            {{ translate("Comunicaciones") }}
                          </div>
                          <jqxDropDownButton #dropDownComunicacion style="float: left" [width]="'100%'"
                            (focusout)="dropDownComunicacion.close()" (onClose)="onCloseDropComunicacion()"
                            (onOpen)="onOpenDropComunicacion()" [disabled]="!adminUser || desdeListadoCerraduras ">
                            <div [hidden]="gridDropComunicacion" style="width: 100%;">
                              <jqxGrid #gridComunicacion [theme]="environment.tema" [enablebrowserselection]="true"
                                [appCellClick] [width]="dropDownComunicacion?.scrollWidth"
                                [height]="arrayGridComunicacion.length*33" [source]="adapterGridComunicacion"
                                [columnsresize]="true" [selectionmode]="'singlerow'" [columns]="columnsGridComunicacion"
                                [sortable]="true" [altrows]="true" [showrowlines]="true" [rowsheight]="20"
                                [columnsheight]="20" [enabletooltips]="true" [localization]="langGrid"
                                (onRowclick)="onRowComunicacionClick($event)" [showheader]="false">
                              </jqxGrid>
                            </div>
                          </jqxDropDownButton>
                        </span>
                        <span style="width: 35%;">
                          <div style="clear: both">
                            {{ translate("Hora_envio") }}
                          </div>
                          <jqxDateTimeInput [value]="horaEnvio" (onChange)="setHoraEnvio($event.target.value)"
                            [width]="'100%'" [showTimeButton]="true" [showCalendarButton]="false"
                            [formatString]="'HH:mm'" [disabled]="!adminUser || desdeListadoCerraduras ">
                          </jqxDateTimeInput>
                        </span>
                        <span style="width: 30%;">
                          <div style="clear: both">
                            {{ translate("Intervalo_envio") }} (m)
                          </div>
                          <app-input-number [value]="configuracionCerradura.intervaloEnvio"
                            (onChange)="configuracionCerradura.intervaloEnvio = $event" [render]="renderer()"
                            [width]="'100%'" [renderizar]="false" [disabled]="!adminUser || desdeListadoCerraduras ">
                          </app-input-number>
                        </span>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div id="fieldInterIdentif" class="fieldInterIdentifLg">
                  <fieldset>
                    <legend>
                      {{ translate("Interacciones_identifiacion") }}
                    </legend>

                    <div class="row">
                      <span style="width: 33%;">
                        <div style="width: 100%;">
                          {{ translate("Tiempo_max_apertura") }} (sg)
                        </div>
                        <div class="inputNumber" style="width: 20%;">
                          <app-input-number [value]="configuracionCerradura.tiempoMaxApertura"
                            (onChange)="configuracionCerradura.tiempoMaxApertura = $event" [render]="renderer()"
                            [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                          </app-input-number>
                        </div>
                      </span>

                      <span style="width: 33%;">
                        <div style="width: 100%;">
                          {{ translate("Tiempo_max_cierre") }} (sg)
                        </div>
                        <div class="inputNumber" style="width: 20%;">
                          <app-input-number [value]="configuracionCerradura.tiempoMaxCierre"
                            (onChange)="configuracionCerradura.tiempoMaxCierre = $event" [render]="renderer()"
                            [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                          </app-input-number>
                        </div>
                      </span>

                      <span style="width: 33%; float: left;">
                        <div style="width: 100%;">
                          {{ translate("Deteccion_vaciado") }}
                        </div>
                        <jqxDropDownButton #dropDownVaciado [width]="'40%'" (onClose)="onCloseDropVaciado()"
                          (onOpen)="onOpenDropVaciado()" [disabled]="!adminUser || desdeListadoCerraduras "
                          (focusout)="dropDownVaciado.close()">
                          <div [hidden]="gridDropVaciado" style="width: 100%;">
                            <jqxGrid #gridVaciado [height]="arrayGridVaciado.length*20" [source]="adapterGridVaciado"
                              [enablebrowserselection]="true" [columnsresize]="true" [selectionmode]="'singlerow'"
                              [columns]="columnsGridVaciado" [sortable]="true" [altrows]="true" [showrowlines]="true"
                              [rowsheight]="20" [columnsheight]="20" [enabletooltips]="true" [localization]="langGrid"
                              (onRowclick)="onRowVaciadoClick($event)" [showheader]="false">
                            </jqxGrid>
                          </div>
                        </jqxDropDownButton>
                      </span>
                    </div>

                    <div class="row">
                      <span style="width: 33%;">
                        <div style="width: 100%;">
                          {{ translate("Tiempo_max_cierre_bateria_baja") }} (sg)
                        </div>
                        <div class="inputNumber" style="width: 20%;">
                          <app-input-number [value]="configuracionCerradura.tiempoMaxCierreBatBaja"
                            (onChange)="configuracionCerradura.tiempoMaxCierreBatBaja = $event" [render]="renderer()"
                            [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                          </app-input-number>
                        </div>
                      </span>

                      <span style="width: 33%;">
                        <div style="width: 100%;">
                          {{ translate("Tiempo_max_apertura_bateria_baja") }} (sg)
                        </div>
                        <div class="inputNumber" style="width: 20%;">
                          <app-input-number [value]="configuracionCerradura.tiempoMaxAperturaBatBaja"
                            (onChange)="configuracionCerradura.tiempoMaxAperturaBatBaja = $event" [render]="renderer()"
                            [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                          </app-input-number>
                        </div>
                      </span>

                      <span style="width: 33%;">
                        <div style="width: 100%;">
                          {{ translate("Anunc_bt") }}
                        </div>
                        <jqxDropDownButton #dropDownAnuncBT [width]="'40%'" (onClose)="onCloseDropAnuncBT()"
                          (onOpen)="onOpenDropAnuncBT()" [disabled]="!adminUser || desdeListadoCerraduras "
                          (focusout)="dropDownAnuncBT.close()">
                          <div [hidden]="gridDropAnuncBT" style="width: 100%;">
                            <jqxGrid #gridAnuncBT [height]="arrayGridAnuncBT.length*20" [source]="adapterGridAnuncBT"
                              [enablebrowserselection]="true" [columnsresize]="true" [selectionmode]="'singlerow'"
                              [columns]="columnsGridAnuncBT" [sortable]="true" [altrows]="true" [showrowlines]="true"
                              [rowsheight]="20" [columnsheight]="20" [enabletooltips]="true" [localization]="langGrid"
                              (onRowclick)="onRowAnuncBTClick($event)" [showheader]="false">
                            </jqxGrid>
                          </div>
                        </jqxDropDownButton>
                      </span>
                    </div>
                  </fieldset>
                </div>
              </div>

              <div style="height: 100%; width: 30%;">
                <div id="fieldConfAlarma" class="fieldConfAlarmaLg">
                  <fieldset>
                    <legend>
                      {{ translate("Configuracion_alarma") }}
                    </legend>

                    <div class="row">
                      <div style="width: 70%;">
                        <span style="width: 100%;">
                          <div style="clear: both">
                            {{ translate("Alarmas") }}
                          </div>
                          <div class="column">
                            <div *ngFor="let alarm of arrayGridAlarmas">
                              <jqxCheckBox [attr.id]="'check' + alarm.tipo" style="float: left;" [height]='25'
                                [disabled]="!adminUser || desdeListadoCerraduras"
                                (onChange)="onChangeCheck($event, alarm.value)" [checked]="checkAlarm(alarm.value)">
                                <span>{{ translate(alarm.tipo) }}</span>
                              </jqxCheckBox>
                            </div>
                          </div>
                        </span>
                      </div>
                      <div style="width: 30%;">
                        <span style="width: 100%;">
                          <div style="width: 100%;">
                            {{ translate("Alarma_bateria_baja") }} (v)
                          </div>
                          <div class="inputNumber" style="width: 40%;">
                            <app-input-number [value]="configuracionCerradura.bateriaBaja"
                              (onChange)="configuracionCerradura.bateriaBaja = $event" [render]="renderer()"
                              [decimal]="2" [disabled]="!adminUser || desdeListadoCerraduras " [width]="'100%'">
                            </app-input-number>
                          </div>
                        </span>
                        <span style="width: 100%;">
                          <div style="width: 100%;">
                            {{ translate("Alarma_bateria_critica") }} (v)
                          </div>
                          <div class="inputNumber" style="width: 40%;">
                            <app-input-number [value]="configuracionCerradura.bateriaCritica"
                              (onChange)="configuracionCerradura.bateriaCritica = $event" [render]="renderer()"
                              [decimal]="2" [disabled]="!adminUser || desdeListadoCerraduras " [width]="'100%'">
                            </app-input-number>
                          </div>
                        </span>
                        <span style="width: 100%;">
                          <div style="width: 100%;">
                            {{ translate("Alarma_temperatura") }} (ºC)
                          </div>
                          <div class="inputNumber" style="width: 40%;">
                            <app-input-number [value]="configuracionCerradura.temperatura"
                              (onChange)="configuracionCerradura.temperatura = $event" [render]="renderer()"
                              [decimal]="2" [disabled]="!adminUser || desdeListadoCerraduras " [width]="'100%'">
                            </app-input-number>
                          </div>
                        </span>
                      </div>
                    </div>
                  </fieldset>
                </div>

                <div id="fieldConfRadar" class="fieldConfRadarLg">
                  <fieldset>
                    <legend>
                      {{ translate("Configuracion_radar") }}
                    </legend>

                    <div class="row">
                      <span style="width: 100%;">
                        <div style="width: 100%;">
                          {{ translate("Distancia_pared_opuesta") }}
                        </div>
                        <div class="inputNumber" style="width: 20%;">
                          <app-input-number [value]="configuracionCerradura.distanciaPared / 10"
                            (onChange)="configuracionCerradura.distanciaPared = $event" [render]="renderer()"
                            [width]="'100%'" [disabled]="!adminUser || desdeListadoCerraduras ">
                          </app-input-number>
                        </div>
                      </span>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </jqxRibbon>

    <jqxButton [hidden]="!adminUser || desdeListadoCerraduras" style="float: left; margin-left: 5px; margin-top: 5px;"
      [width]="100" [height]="26" [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
      [imgSrc]="'/assets/images/save.png'" (onClick)="onGuardar()" [value]="translate('Guardar')"
      [disabled]="!adminUser || desdeListadoCerraduras ">
    </jqxButton>

    <jqxButton style="margin-left: 5px; margin-top: 5px;" [width]="100" [height]="26"
      [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
      [value]="translate('CANCELAR')">
    </jqxButton>
  </div>
</jqxWindow>
