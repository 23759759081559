import { Component, computed, ElementRef, OnInit, signal, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { AuditoriaModel } from 'src/app/services/auditoria/models/auditoria.model';
import { DateAuditoriaModel } from 'src/app/services/auditoria/models/date.auditoria.model';

import { Utils } from 'src/app/utils/utils';
import { HeaderComponent } from '../../header/header.component';
import * as xlsx from 'xlsx';
import { MainComponent } from '../../main/main.component';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { UserLangService } from 'src/app/services/common/user-lang/user-lang.service';

@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.css']
})
export class InformesComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('myGrid') myGrid: jqxGridComponent;

  public static _this: InformesComponent;

  private componentRef = null;
  public theme = environment.tema;
  public langGrid = JqWidgets.getLocalization('es');
  countPartialResults = signal<number>(0);
  titleWindow = this.translate('Informes');

  titleWindowSignal = computed(() => `${this.titleWindow} (${this.countPartialResults()})`);


  auditorias: AuditoriaModel[] = [];
  dateFin = new Date();
  dateInicio = new Date();
  dateAuditoria: DateAuditoriaModel = new DateAuditoriaModel();
  textSearchAccion = '';
  userList = [];

  createTooltip = (content: string) => {
    return (columnHeaderElement?: any) => {
      const jqElement = columnHeaderElement[0].parentElement;
      const options = {
        content: content,
        theme: environment.tema,
        width: 'auto',
        height: 'auto',
        position: 'mouse',
        autoHide: true,
      };
      jqwidgets.createInstance(jqElement, 'jqxTooltip', options);
    };
  };

  apartadoMenurenderer = (row: number, column: any, value: any, rowData: any): string => {
    if (value) {
      let opcion = value.TextoId;
      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${AppComponent.translate(opcion)}</div>`;
    } else {
      return '';
    }
  };

  rendexTextGeneric(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {

    if (typeof value === 'string') {
      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;

    } else if (typeof value === 'number') {
      return `<div style="margin-right: 4px; margin-top: 4px;  text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';  this.style.position='fixed'" onmouseout="this.style.backgroundColor=''; this.style.color='';this.style.position=''"> ${value}</span></div>`;
    } else if (value instanceof Date) {

      if (columnfield === 'Fecha') {
        return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';  this.style.position='fixed'" onmouseout="this.style.backgroundColor=''; this.style.color='';this.style.position=''"> ${DateUtils.formatDate(value, true)}</span></div>`;
      } else if (columnfield === 'Hora') {
        return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';  this.style.position='fixed'" onmouseout="this.style.backgroundColor=''; this.style.color='';this.style.position=''"> ${DateUtils.formatTime(value)}</span></div>`;
      }
    }
  }

  columns = [
    { text: 'Fecha', datafield: 'Fecha', width: 100, filtertype: 'date', columntype: 'datetimeinput', cellsformat: 'dd/MM/yyyy', rendered: this.createTooltip('Fecha'), cellsrenderer: this.rendexTextGeneric, },
    { text: 'Hora', datafield: 'Hora', columntype: 'datetimeinput', filtertype: 'date', width: 80, cellsformat: 'HH:mm:ss', rendered: this.createTooltip('Hora'), cellsrenderer: this.rendexTextGeneric },
    { text: 'Usuario', datafield: 'Usuario', width: 200, rendered: this.createTooltip('Usuario'), filtertype: 'checkedlist', filteritems: this.userList, cellsrenderer: this.rendexTextGeneric },
    { text: 'Apartado Menu', datafield: 'ApartadoMenu', width: 200, rendered: this.createTooltip('Apartado Menu'), cellsrenderer: this.apartadoMenurenderer, },
    { text: 'Accion', datafield: 'AccionNombre', width: 200, rendered: this.createTooltip('Accion'), cellsrenderer: this.rendexTextGeneric },
    { text: 'Informacion', datafield: 'Informacion', width: 200, rendered: this.createTooltip('Informacion'), cellsrenderer: this.rendexTextGeneric },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }

  ];

  dataSource = {
    datatype: 'json',
    datafields: [
      { name: 'Id', type: 'number' },
      { name: 'AccionId', type: 'number', map: 'AccionId' },
      { name: 'Fecha', type: 'date', map: 'Fecha' },
      { name: 'Hora', type: 'date', map: 'Hora' },
      { name: 'Usuario', type: 'string', map: 'Usuario' },
      { name: 'ApartadoMenu', type: 'object', map: 'ApartadoMenu' },
      { name: 'AccionNombre', type: 'string', map: 'Accion>Nombre' },
      { name: 'Informacion', type: 'string', map: 'Info' },
      { name: 'selec', type: 'string' }
    ],
    localdata: [],
    sortcolumn: 'Fecha',
    sortdirection: 'desc'
  };
  dataAdapter = new jqx.dataAdapter(this.dataSource);
  mapWidth: number;

  public columnmenuopening(menu?: any, datafield?: any, height?: number | string): boolean | void {
    if (menu.length === 1) {
      if (datafield === 'Fecha' || datafield === 'Hora') {
        const configureDateTimeInput = (menuElement: HTMLElement, options: { showTimeButton: boolean; showCalendarButton: boolean, formatString: string }) => {
          const dateTimeInputs = menuElement.querySelectorAll('.jqx-datetimeinput');
          if (dateTimeInputs && dateTimeInputs.length > 0) {
            dateTimeInputs.forEach((input: HTMLElement) => {
              const elementRef = new ElementRef(input);
              const jqxDateTimeInput = new jqxDateTimeInputComponent(elementRef);
              const inputDate = jqwidgets.createInstance('#' + jqxDateTimeInput.elementRef.nativeElement.id, 'jqxDateTimeInput', { width: '100%', height: 25 });
              inputDate.setOptions(options);
            });
          }
        };

        const divElement: HTMLElement = menu[0];
        if (datafield === 'Fecha') {
          configureDateTimeInput(divElement, { showTimeButton: false, showCalendarButton: true, formatString: 'dd/MM/yyyy' });
        } else if (datafield === 'Hora') {
          configureDateTimeInput(divElement, { showTimeButton: true, showCalendarButton: false, formatString: 'HH:mm:ss' });
        }
      }
    }
  }

  constructor(private auditoriaService: AuditoriaService, private userLangService: UserLangService) {
    super();
    InformesComponent._this = this;
  }
  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.userLangService.getUserLangShort());
  }
  /**Inicializa el componente
  */

  ngAfterViewInit() {
    this.addCustomForm(this.form);
  }

  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  closeWindow() {
    this.form.close();
  }


  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'Usuario',
      'AccionNombre',
      'Informacion'
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }


  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    let showText = `
      <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
    `;

    if (data && data.group) {
      if (data?.groupcolumn?.datafield === 'Fecha') {
        try {
          let dateUTC = new Date(data?.group);
          let date = new Date(data?.group);
          dateUTC.setFullYear(date.getUTCFullYear());
          dateUTC.setMonth(date.getUTCMonth());
          dateUTC.setDate(date.getUTCDate());
          dateUTC.setHours(date.getUTCHours());

          showText += `<b>` + data.groupcolumn.text + `:</b> <span class="jqx-grid-groups-row-details jqx-grid-groups-row-details-material"/>` +
            DateUtils.formatDateTimeShort(dateUTC, true) +
            `<span/>`

          if (data?.subGroups.length == 0) {
            showText += '(' + data?.subItems.length + ')';
          } else {
            showText += '(' + data?.subGroups.length + ')';
          }
          return showText;
        } catch (error) {

        }
      }
    } else {
      return showText;
    }
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.auditorias.forEach(aud => {
      if (
        (aud?.Usuario + '').compareWith(filtervalue) ||
        (aud?.Accion.Nombre + '').compareWith(filtervalue) ||
        (aud?.Fecha + '').compareWith(filtervalue)
      ) {
        aud['selec'] = 'selec';
      } else {
        aud['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.myGrid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.myGrid.addfilter('selec', filtergroup);
    }
    this.myGrid.applyfilters();
    this.myGrid.updatebounddata('data');
  }

  onSearchAccion(event) {
    if (this.header.searchInput['nativeElement'].value.length) {
      this.header.searchInput['nativeElement'].value = '';
    }
    this.textSearchAccion = event;
  }

  onGridDataUpdate() {
    this.countPartialResults.set(this.myGrid.getrows().length);
  }

  // Si las fechas son correctas, obtenemos los datos del grid
  async onFilter() {


    if (this.textSearchAccion.length) {

      this.auditorias.forEach(aud => {
        let matches = false;

        // Verificar en la columna 'Usuario'
        if ((aud?.Usuario + '').includes(this.textSearchAccion)) {
          matches = true;
        }

        // Verificar en la columna 'Accion.Nombre'
        if ((aud?.Accion.Nombre + '').includes(this.textSearchAccion)) {
          matches = true;
        }

        // Verificar en la columna 'Fecha'
        if ((aud?.Fecha + '').includes(this.textSearchAccion)) {
          matches = true;
        }

        // Verificar en la columna 'Informacion', buscando cualquier dato específico
        if ((aud?.Info + '').includes(this.textSearchAccion)) {
          matches = true;
        }

        // Marcar o desmarcar el elemento basado en los resultados anteriores
        aud['selec'] = matches ? 'selec' : '';
      });

      // Compruebo si ya he creado el filtro "selec" anteriormente
      const filters = this.myGrid.getfilterinformation();
      if (filters.find(s => s.datafield === 'selec') === undefined) {
        const filtergroup = new jqx.filter();
        filtergroup.operator = 'and';
        filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
        this.myGrid.addfilter('selec', filtergroup);
      }
      this.myGrid.applyfilters();
      this.myGrid.updatebounddata('data');

      return;
    }

    let dataFilter = this.myGrid.getfilterinformation();
    this.myGrid.savestate();
    if (this.header.periodoSelect.desde != null && this.header.periodoSelect.hasta != null) {
      let dateAuditoria = new DateAuditoriaModel();
      dateAuditoria.Desde = this.header.periodoSelect.desde;
      dateAuditoria.Hasta = this.header.periodoSelect.hasta;
      this.auditorias = await this.auditoriaService.getAuditoria(dateAuditoria);
      this.userList = this.auditorias.map(aud => aud.Usuario).filter((value, index, self) => self.indexOf(value) === index);


      this.auditorias.forEach((auditoria) => {
        let fullDate = new Date(auditoria.Fecha);
        let dateOnly = new Date(fullDate);
        auditoria.Fecha = dateOnly;
        let timeOnly = new Date(fullDate);
        timeOnly.setFullYear(2000, 0, 1);
        auditoria.Hora = timeOnly;
      });
    } else {
      this.auditorias = await this.auditoriaService.getAuditoria(new DateAuditoriaModel());
    }

    this.dataSource.localdata = this.auditorias;
    this.myGrid.updatebounddata();
    this.myGrid.sortby('Fecha', 'desc');
    this.myGrid.loadstate(dataFilter);
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.textSearchAccion = '';
    this.header.periodoSelect.setPeriodo(0);

    this.header.periodoSelect.dateForm.get('desde').setValue(new Date());
    this.header.periodoSelect.dateForm.get('hasta').setValue(new Date());
    this.header.periodoSelect.resetForm();

    this.dateAuditoria.Desde = new Date();
    this.dateAuditoria.Desde.setHours(0, 0, 0);
    this.dateAuditoria.Hasta = new Date();
    this.dateAuditoria.Hasta.setHours(23, 59, 0, 0)

    if (this.dataSource) {
      this.dataSource.localdata = [];
      this.myGrid.updatebounddata();
    }
  }

  onExportar() {

    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {

      const wb = xlsx.utils.book_new();
      const json = this.myGrid.getrows().map(row => ({
        Fecha: row.Fecha,
        Hora: row.Hora,
        Usuario: row.Usuario,
        ApartadoMenu: row.ApartadoMenu,
        AccionNombre: row.AccionNombre,
        Informacion: row.Informacion
      }));

      // muestro la fecha solo fecha y el campo hora solo hora
      json.forEach(row => {
        row.Fecha = DateUtils.formatDate(row.Fecha, true);
        row.Hora = DateUtils.formatTime(row.Hora);
      });

      // Traducir encabezados
      const headers = [
        { key: 'Fecha', title: this.translate('Fecha') },
        { key: 'Hora', title: this.translate('Hora') },
        { key: 'Usuario', title: this.translate('Usuario') },
        { key: 'ApartadoMenu', title: this.translate('ApartadoMenu') },
        { key: 'AccionNombre', title: this.translate('Accion_nombre') },
        { key: 'Informacion', title: this.translate('Informacion') }
      ]

      const headerMap = headers.reduce((acc, header) => {
        if (!acc[header.title]) {
          acc[header.title] = header.key;
        }
        return acc;
      }, {});

      const uniqueHeaders = Object.keys(headerMap);
      const wsData = [uniqueHeaders];
      json.forEach(row => {
        const rowData = uniqueHeaders.map(header => row[headerMap[header]]);
        wsData.push(rowData);
      });

      const ws = xlsx.utils.aoa_to_sheet(wsData);

      // Añadir filtros a las columnas
      ws['!autofilter'] = { ref: `A1:${String.fromCharCode(65 + uniqueHeaders.length - 1)}1` };

      // Agregar la hoja al libro
      xlsx.utils.book_append_sheet(wb, ws, 'Informe');

      // Escribir el archivo
      const fileName = DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Informes') + `.xlsx`;
      xlsx.writeFile(wb, fileName);

    }
  }

  onPrint($event: any) {
    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      let gridContent = this.myGrid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    InformesComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}
