import { AppComponent } from "../app.component";

export class NumberUtils {

    // Formatea un número con los separadores de miles y con el número de decimales dado
    public static format(num: any, decimals: number): string {
      let res = '0';
        if (num && num !== '') {
            let sepMiles = null;
            let sepDec = null;
            if (num < 10000) { // Esto es porque los números con menos de 5 cifras en español no les pone separador de miles
                let res = new Intl.NumberFormat(AppComponent.getInstance().idioma, {
                    minimumFractionDigits: 2
                }).format(num + 10000);
                sepMiles = (res.indexOf('.') < res.indexOf(',') ? '.' : ',');
                sepDec = (sepMiles === '.' ? ',' : '.');
            }
            res = new Intl.NumberFormat(AppComponent.getInstance().idioma, {
                minimumFractionDigits: decimals
            }).format(Number.parseFloat(num.toFixed(decimals)));
            if (!sepMiles && !sepDec) {
                sepMiles = (res.indexOf('.') < res.indexOf(',') ? '.' : ',');
                sepDec = (sepMiles === '.' ? ',' : '.');
            }
            if ((num < 10000 && num > 999) && sepMiles === '.') {
                res = res.substring(0, 1) + sepMiles + res.substring(1);
            }
            if (decimals > 0) {
                if (res.substring(res.indexOf(sepDec) + 1).length < decimals) {
                    for (let i = res.substring(res.indexOf(sepDec) + 1).length; i < decimals; i++) {
                        res += '0';
                    }
                }
            }
        }
        return res;
    }

    /* Formatea un número con los separadores de miles y con el número de decimales dado en los campos de input */
    public static formatInputs(num: any, decimals: number): string {
      let res = '0';
      if (num && num !== '') {
        /*
          Quitamos el separador de miles manteniendo el numero como number
          si se cumple que el numero es mayor o igual a 1000
        */
        let tempNum = num;
        if(num >= 1000 ){
          num = Number.parseFloat(num.toString().replace(/\./g, ''));
        }else {
          num = Number.parseFloat(num.toString().replace(/\./g, ','));
        }

        let sepMiles = null;
        let sepDec = null;
        if (num < 10000) { // Esto es porque los números con menos de 5 cifras en español no les pone separador de miles
          let res = new Intl.NumberFormat(AppComponent.getInstance().idioma, {
            minimumFractionDigits: 2
          }).format(num + 10000);
          sepMiles = (res.indexOf('.') < res.indexOf(',') ? '.' : ',');
          sepDec = (sepMiles === '.' ? ',' : '.');
        }else if(num >= 10000){
          let res = new Intl.NumberFormat(AppComponent.getInstance().idioma, {
            minimumFractionDigits: 2
          }).format(num);
          sepMiles = (res.indexOf('.') < res.indexOf(',') ? '.' : ',');
          sepDec = (sepMiles === '.' ? ',' : '.');
        }

        let parteDecimal = tempNum.toString().split(sepDec)[1];

        if(!parteDecimal){
          parteDecimal = tempNum.toString().split(sepMiles)[1];
        }

        res = new Intl.NumberFormat(AppComponent.getInstance().idioma).format(Number.parseInt(num));
        if (!sepMiles && !sepDec) {
          sepMiles = (res.indexOf('.') < res.indexOf(',') ? '.' : ',');
          sepDec = (sepMiles === '.' ? ',' : '.');
        }
        if ((num < 10000 && num > 999) && sepMiles === '.') {
          res = res.substring(0, 1) + sepMiles + res.substring(1);
        }
        if (decimals > 0) {
          let lengthDecimal = parteDecimal ? parteDecimal.length : 0;
          if (lengthDecimal <= decimals) {
            res += sepDec;
            for (let i = 0; i < decimals; i++) {
              res += parteDecimal && parteDecimal[i] ? parteDecimal[i] : '0';
            }
          }
        }
      }
      return res;
    }

    // El único valor que no es igual a si mismo es NaN
    static isNaN(num: number): boolean {
        return num !== num;
    }

}
