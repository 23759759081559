import {
  Component, ElementRef, EmbeddedViewRef,
  Injector, OnInit, signal, ViewChild,
  ViewContainerRef
} from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';

import { SsoService } from 'src/app/services/sso/sso.service';
import { CalendarService } from 'src/app/services/calendar/calendar.service';

import { CalendarModel } from 'src/app/services/calendar/models/calendar.model';
import { SpecialDaysModel } from 'src/app/services/calendar/models/special-days.model';
import { CalendarComponent } from '../calendar.component';

@Component({
  selector: 'app-edit-calendar',
  templateUrl: './edit-calendar.component.html',
  styleUrls: ['./edit-calendar.component.css']
})
export class EditCalendarComponent extends CustomForms implements OnInit {
  @ViewChild('formEdit') formEdit: jqxWindowComponent;
  @ViewChild('myTabs') myTabs: jqxTabsComponent;
  @ViewChild('horaInicio') horaInicio: jqxDateTimeInputComponent;
  @ViewChild('horaFin') horaFin: jqxDateTimeInputComponent;
  @ViewChild('fecha1') desde: jqxDateTimeInputComponent;
  @ViewChild('fecha2') hasta: jqxDateTimeInputComponent;
  @ViewChild('todosDias') todosDias: ElementRef;
  @ViewChild('jqxDropDownList') jqxDropDownList: jqxDropDownListComponent;
  @ViewChild('wridDiasSi') wridDiasSi: jqxGridComponent;
  @ViewChild('wridDiasNo') wridDiasNo: jqxGridComponent;

  totalEspecialServicio = signal<string>('(0)');
  totalEspecialSinServicio = signal<string>('(0)');

  private componentRef = null;
  public theme = environment.tema;
  value: Date = new Date();
  editCalendario: boolean = false;
  row: number;
  public calendario: CalendarModel = new CalendarModel();
  todosLosDias: boolean = true;
  calendarios: CalendarModel[] = [];
  fechaDesde: Date;
  diasSemana: String[] = [AppComponent.translate('Lunes'), AppComponent.translate('Martes'),
  AppComponent.translate('Miercoles'), AppComponent.translate('Jueves'), AppComponent.translate('Viernes'),
  AppComponent.translate('Sabado'), AppComponent.translate('Domingo')];
  diasExceptos: string;
  fechaHasta: Date;
  elementIds: any[] = [];
  checkTabs: any[] = [];
  dateTimeComponents: any[] = [];
  componentDateTime: any;
  componentDateTimeDos: any;
  checkTabsDias: any[] = [];

  // Dias especiales con servicio
  public dataAdapterDiasSi: any;
  public sourceDiasSi: any = [];
  public dataSourceDiasSi = new jqx.dataAdapter(this.sourceDiasSi);
  private rowSelecSi = -1;
  // Días especiales sin servicio
  public dataAdapterDiasNo: any;
  public sourceDiasNo: any = [];
  public dataSourceDiasNo = new jqx.dataAdapter(this.sourceDiasNo);
  private rowSelecNo = -1;

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  // Pongo por defecto los textos en español
  public localization = 'es-ES';

  public columns = [
    {
      text: AppComponent.translate('Fecha'), columntype: 'datetimeinput', filtertype: 'datetimeinput',
      datafield: 'fecha', cellsformat: 'dd/MM',
    },
    {
      text: AppComponent.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox',
      datafield: 'observaciones'
    },
  ];

  constructor(
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
    private calendarService: CalendarService,
    private ssoService: SsoService) {
    super();
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.value.setHours(23, 59, 59, 999);
  }

  /**Inicializa el componente
   */
  init(componentRef: any, editCalendario: boolean, row: number, caledario: CalendarModel) {
    this.componentRef = componentRef;
    this.editCalendario = editCalendario;
    this.row = row;
    this.calendario = caledario;
  }

  closeWindow() {
    this.formEdit.close();
  }

  resizeColumns(grid: jqxGridComponent) {
    Utils.renderSizeGrid(grid, 500);
  }

  // Cuando se carga el componente
  ngAfterViewInit(): void {
    this.formEdit.setTitle(AppComponent.translate('Edicion_calendarios'));
    this.addCustomForm(this.formEdit);
    this.tabsContent();
    this.disableTabs();
    this.resizeColumns(this.wridDiasSi);
    this.resizeColumns(this.wridDiasNo);
    setTimeout(() => {
      this.editCalendarioForm();
    }, 100);
  }

  // añado los dias de semana al componente DROPDOWNLIST
  addItemDropSelect() {
    this.diasSemana.forEach((dia: string, index: number) => {
      this.jqxDropDownList.addItem({ label: dia, value: index + 1 });
    });
  };

  disableTabs() {
    if (!this.editCalendario) {
      this.todosDias.nativeElement.checked = true;
      this.myTabs.disabled(true);
      setTimeout(() => {
        this.dateTimeComponents[0]?.instance.disabled(true);
        this.dateTimeComponents[1]?.instance.disabled(true);
      }, 200);
    }
    //si el checkbox de todos los dias esta seleccionado deshabilito las pestañas
    else {
      this.myTabs.disabled(false);
      if (this.calendario.todosLosDias) {
        this.todosDias.nativeElement.checked = true;
        setTimeout(() => {
          this.dateTimeComponents[0]?.instance.disabled(true);
          this.dateTimeComponents[1]?.instance.disabled(true);
          this.horaInicio.disabled(false);
          this.horaFin.disabled(false);
          this.myTabs.disabled(true);
          this.jqxDropDownList.disabled(false);
          this.dehabilitarCheckTabs(this.todosDias.nativeElement.checked);
          this.calendario.diasSemana = null;
        }, 200);
      } else {
        this.todosDias.nativeElement.checked = false;
        setTimeout(() => {
          this.dehabilitarCheckTabs(this.todosDias.nativeElement.checked);
          this.dateTimeComponents[0]?.instance.disabled(false);
          this.dateTimeComponents[1]?.instance.disabled(false);
          this.horaInicio.disabled(true);
          this.horaFin.disabled(true);
          this.myTabs.disabled(false);
          this.jqxDropDownList.disabled(true);
          this.jqxDropDownList.clearSelection();
          this.calendario.excepto = null;
        }, 200);
      }
    }
  }

  //deshabilito los checkbox de las pestañas
  dehabilitarCheckTabs(select: boolean) {
    if (select) {
      // dehabilito los checkbox de las pestañas
      this.elementIds.forEach((tab: string, index: number) => {
        const checkbox = document.querySelector('#checkbox' + index) as HTMLInputElement;
        if (checkbox) {
          checkbox.disabled = true;
        }
      });
    } else {
      // habilito los checkbox de las pestañas
      this.elementIds.forEach((tab: string, index: number) => {
        const checkbox = document.querySelector('#checkbox' + index) as HTMLInputElement;
        if (checkbox) {
          checkbox.disabled = false;
        }
      });
    }
  }

  tabsContent() {
    this.elementIds = Array.from(document.querySelectorAll('[id^="checkbox"]')) as HTMLElement[];

    this.dateTimeComponents.forEach((componentRef) => {
      componentRef.destroy();
    });
    this.dateTimeComponents = [];

    this.elementIds.forEach((tab: HTMLElement, index: number) => {
      const dateTimeElement = document.getElementById('dateTime' + index);
      const dateTimeDosElement = document.getElementById('dateTimeDos' + index);

      if (dateTimeElement) {
        this.componentDateTime = this.loadComponent(jqxDateTimeInputComponent, dateTimeElement);
        this.dateTimeComponents.push(this.componentDateTime.componentRef);
      }

      if (dateTimeDosElement) {
        this.componentDateTimeDos = this.loadComponent(jqxDateTimeInputComponent, dateTimeDosElement);
        this.dateTimeComponents.push(this.componentDateTimeDos.componentRef);
      }

      this.setTimeDate(this.dateTimeComponents);
      this.eventCheckTab(index);
    });

    this.recoverCheckTabs();
  }

  // recupero los check del compoenente jqxDropDownList
  getCheckDropList() {
    if (this.calendario.excepto) {
      this.diasExceptos = this.calendario.excepto;
      // reemplazo del array de dias de la semana el miercoles por X
      this.diasSemana.splice(2, 1, 'X');
      // elimino los caracteres del string repetidos
      this.diasExceptos = this.diasExceptos.split('').filter((item, index, array) => array.indexOf(item) === index).join('');
      // recorro el array de dias de la semana y los comparo cada caracter con el string de dias exceptos
      this.diasSemana.forEach((dia: string, index: number) => {
        if (this.diasExceptos.indexOf(dia.charAt(0)) !== -1) {
          this.jqxDropDownList.checkIndex(index);
        }
      });
    }
  }

  editCalendarioForm() {
    if (this.editCalendario && this.row && this.calendario.id !== undefined) {
      //busco el calendario que se va a editar
      //this.calendario = this.calendarios.find((item: CalendarModel) => item.id === this.row);
      this.getCheckDropList();
      //paso la fecha a formato dd/mm/yyyy
      this.calendario.fechaInicio = DateUtils.formatDateTypeFecha(this.calendario.fechaInicio);
      this.calendario.fechaFin = DateUtils.formatDateTypeFecha(this.calendario.fechaFin);
      //paso la hora a formato hh:mm:ss
      this.calendario.horaInicio1 = new Date(this.calendario.horaInicio1);
      this.calendario.horaFin1 = new Date(this.calendario.horaFin1);
      //seteo los valores de los controles
      this.todosLosDias = this.calendario.todosLosDias;
      this.disableTabs();
      this.hasta.val(this.calendario.fechaFin);
      this.desde.val(this.calendario.fechaInicio);
      if (this.calendario.todosLosDias) {
        this.horaInicio.val(this.calendario.horaInicio1);
        this.horaFin.val(this.calendario.horaFin1);
      } else {
        this.dateTimeComponents[0]?.instance?.val(DateUtils.formatDateTypeHours(this.calendario.horaInicio1));
        this.dateTimeComponents[1]?.instance?.val(DateUtils.formatDateTypeHours(this.calendario.horaFin1));
      }

      //asigno el valor de la horas de calendario a los componentes DateTime de las pestañas
      this.dateTimeComponents[2]?.instance.val(DateUtils.formatDateTypeHours(this.calendario.horaInicio2));
      this.dateTimeComponents[3]?.instance.val(DateUtils.formatDateTypeHours(this.calendario.horaFin2));
      this.dateTimeComponents[4]?.instance.val(DateUtils.formatDateTypeHours(this.calendario.horaInicio3));
      this.dateTimeComponents[5]?.instance.val(DateUtils.formatDateTypeHours(this.calendario.horaFin3));
      this.dateTimeComponents[6]?.instance.val(DateUtils.formatDateTypeHours(this.calendario.horaInicio4));
      this.dateTimeComponents[7]?.instance.val(DateUtils.formatDateTypeHours(this.calendario.horaFin4));
      this.dateTimeComponents[8]?.instance.val(DateUtils.formatDateTypeHours(this.calendario.horaInicio5));
      this.dateTimeComponents[9]?.instance.val(DateUtils.formatDateTypeHours(this.calendario.horaFin5));
      this.dateTimeComponents[10]?.instance.val(DateUtils.formatDateTypeHours(this.calendario.horaInicio6));
      this.dateTimeComponents[11]?.instance.val(DateUtils.formatDateTypeHours(this.calendario.horaFin6));
      this.dateTimeComponents[12]?.instance.val(DateUtils.formatDateTypeHours(this.calendario.horaInicio7));
      this.dateTimeComponents[13]?.instance.val(DateUtils.formatDateTypeHours(this.calendario.horaFin7));

      // Cargo los días especiales
      this.loadSpecialDays();
    }
  }

  loadSpecialDays() {
    if (this.calendario.diasEspecialesConServicio) {
      this.sourceDiasSi = {
        datatype: 'json',
        datafields: [
          { name: 'fecha', type: 'datetime', map: 'fecha' },
          { name: 'observaciones', type: 'string', map: 'observaciones' }
        ],
        localdata: this.calendario.diasEspecialesConServicio,
        sortcolumn: 'fecha',
        sortdirection: 'asc'
      };
      this.dataAdapterDiasSi = new jqx.dataAdapter(this.sourceDiasSi);

      this.resizeColumns(this.wridDiasSi);
    }
    if (this.calendario.diasEspecialesSinServicio) {
      this.sourceDiasNo = {
        datatype: 'json',
        datafields: [
          { name: 'fecha', type: 'datetime', map: 'fecha' },
          { name: 'observaciones', type: 'string', map: 'observaciones' }
        ],
        localdata: this.calendario.diasEspecialesSinServicio,
        sortcolumn: 'fecha',
        sortdirection: 'asc'
      };
      this.dataAdapterDiasNo = new jqx.dataAdapter(this.sourceDiasNo);

      this.resizeColumns(this.wridDiasNo);
    }
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'observaciones',
    ];

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  recoverCheckTabs() {
    if (this.calendario.todosLosDias) {
      for (let i = 0; i < 14; i++) {
        this.dateTimeComponents[i]?.instance.val('00:00:00');
        this.checkTabs.push(i);
      }
    } else {
      //recupero los valores de los checkbox de las pestañas
      let horaUno = new Date(this.calendario.horaInicio1).toTimeString().substring(0, 8);
      let horaUnoFin = new Date(this.calendario.horaFin1).toTimeString().substring(0, 8);
      let horaDos = new Date(this.calendario.horaInicio2).toTimeString().substring(0, 8);
      let horaDosFin = new Date(this.calendario.horaFin2).toTimeString().substring(0, 8);
      let horaTres = new Date(this.calendario.horaInicio3).toTimeString().substring(0, 8);
      let horaTresFin = new Date(this.calendario.horaFin3).toTimeString().substring(0, 8);
      let horaCuatro = new Date(this.calendario.horaInicio4).toTimeString().substring(0, 8);
      let horaCuatroFin = new Date(this.calendario.horaFin4).toTimeString().substring(0, 8);
      let horaCinco = new Date(this.calendario.horaInicio5).toTimeString().substring(0, 8);
      let horaCincoFin = new Date(this.calendario.horaFin5).toTimeString().substring(0, 8);
      let horaSeis = new Date(this.calendario.horaInicio6).toTimeString().substring(0, 8);
      let horaSeisFin = new Date(this.calendario.horaFin6).toTimeString().substring(0, 8);
      let horaSiete = new Date(this.calendario.horaInicio7).toTimeString().substring(0, 8);
      let horaSieteFin = new Date(this.calendario.horaFin7).toTimeString().substring(0, 8);

      if ((horaUno == '00:00:00' || horaUno !== '00:00:00') && horaUnoFin !== '00:00:00') {
        this.checkTabs.push(0);
      }
      if ((horaDos == '00:00:00' || horaDos !== '00:00:00') && horaDosFin !== '00:00:00') {
        this.checkTabs.push(1);
      }

      if ((horaTres == '00:00:00' || horaTres !== '00:00:00') && horaTresFin !== '00:00:00') {
        this.checkTabs.push(2);
      }

      if ((horaCuatro !== '00:00:00' || horaCuatro == '00:00:00') && horaCuatroFin !== '00:00:00') {
        this.checkTabs.push(3);
      }
      if ((horaCinco !== '00:00:00' || horaCinco == '00:00:00') && horaCincoFin !== '00:00:00') {
        this.checkTabs.push(4);
      }

      if ((horaSeis !== '00:00:00' || horaSeis == '00:00:00') && horaSeisFin !== '00:00:00') {
        this.checkTabs.push(5);
      }

      if ((horaSiete !== '00:00:00' || horaSiete == '00:00:00') && horaSieteFin !== '00:00:00') {
        this.checkTabs.push(6);
      }


      // recorro el array de pestañas y checkeo los checkbox de las pestañas que estan seleccionadas
      this.elementIds.forEach((tab: string, index: number) => {
        const checkbox = document.querySelector('#checkbox' + index) as HTMLInputElement;
        // si la hora inicio no es 00:00:00, entonces la pestaña esta seleccionada
        if (this.checkTabs.includes(index)) {
          checkbox.checked = true;
        } else {
          checkbox.checked = false;
        }
      });
    }
  }

  selectDiaSemana(event: any) {
    let diaSelect = event.args.item;
    // si es Miercoles lo reemplazo por X
    if (diaSelect.value === 'Miércoles') {
      diaSelect.value = 'X';
    }
    //quito los caracteres repetidos del string
    if (this.diasExceptos !== undefined) {
      for (let i = 0; i < this.diasExceptos.length; i++) {
        if (this.diasExceptos[i] === diaSelect.value.substring(0, 1)) {
          this.diasExceptos = this.diasExceptos.replace(diaSelect.value.substring(0, 1), '');
        }
      }
    }
    // concateno el dia seleccionado al array de dias exceptuados por comas
    this.diasExceptos += diaSelect.value.substring(0, 1);
    // quito el undefained del array
    this.diasExceptos = this.diasExceptos.replace('undefined', '');

    if (diaSelect.checked === false) {
      // si el dia esta deseleccionado, lo elimino del array de dias exceptuados
      for (let i = 0; i < this.diasExceptos.length; i++) {
        if (this.diasExceptos[i] === diaSelect.value.substring(0, 1)) {
          this.diasExceptos = this.diasExceptos.replace(diaSelect.value.substring(0, 1), '');
        }
      }
    }

    this.exceptoTodosLosDias(this.diasExceptos);
  }


  // si  Todos los dias esta seleccionado, elimino los dias exceptuados
  exceptoTodosLosDias(exceptos: string) {
    if (!this.calendario.todosLosDias) {
      return;
    }
    let excepto = exceptos.split(',');
    const dias = { 'Lu': [0, 1], 'Ma': [2, 3], 'X': [4, 5], 'Ju': [6, 7], 'Vi': [8, 9], 'Sa': [10, 11], 'Do': [12, 13] };
    excepto.forEach(dia => dias[dia].forEach(i => this.dateTimeComponents[i]?.instance.val('00:00:00')));
  }


  // creo el evento para los checkbox de cada pestaña
  eventCheckTab(index) {
    const checkbox = document.querySelector('#checkbox' + index) as HTMLInputElement;

    checkbox.addEventListener('change', () => {
      if (checkbox.checked) {
        this.checkTabs.push(index);
        this.controlTime(index);
      } else {
        const indexToRemove = this.checkTabs.findIndex(item => item === index);
        if (indexToRemove !== -1) {
          this.checkTabs.splice(indexToRemove, 1);
          this.resetDateTimeComponents(index);
        }
      }
    });
  }

  resetDateTimeComponents(index) {
    const startIdx = index * 2;
    const endIdx = startIdx + 1;
    for (let i = startIdx; i <= endIdx; i++) {
      this.dateTimeComponents[i]?.instance.val('00:00:00');
    }
  }


  // creo los componentes DateTime y los agrego al DOM
  loadComponent(component: any, ownerElement: HTMLElement) {
    const viewContainerRef = this.viewContainerRef;
    const componentRef = viewContainerRef.createComponent(component, {
      injector: this.injector,
    });

    const domElement = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    if (ownerElement) {
      ownerElement.appendChild(domElement);
    }

    this.componentRef = componentRef;
    return { componentRef, domElement };
  }

  //modifico el componente DateTime
  setTimeDate(dateTimeComponents: any[]) {
    dateTimeComponents.forEach((dateTimeComponent: any) => {
      setTimeout(() => {
        dateTimeComponent.instance.width(95);
        dateTimeComponent.instance.height(22);
        dateTimeComponent.instance.formatString('HH:mm:ss');
        dateTimeComponent.instance.showTimeButton(true);
        dateTimeComponent.instance.showCalendarButton(false);
      });
    }, 100);
  }

  async onGuardar(event: any) {
    let fechaInicio = this.desde.value();
    let fechaFin = this.hasta.value();
    if (!this.controlForm()) {
      return;

    } else {
      if (this.row) {
        // this.calendario = this.calendarios.find(calendario => calendario.id === this.row);
        // this.calendarios = this.calendarios.filter(calendario => (calendario.id !== this.calendario.id));
        this.calendario.nombre = this.calendario.nombre;
        this.calendario.observaciones = this.calendario.observaciones;
        this.calendario.todosLosDias = this.todosLosDias;
        this.calendario.excepto = this.diasExceptos;
        this.calendario.fechaInicio = fechaInicio;
        this.calendario.fechaFin = fechaFin;
        this.calendario.horaInicio1 = this.todosLosDias ? this.horaInicio.value() : this.dateTimeComponents[0]?.instance.value();
        this.calendario.horaFin1 = this.todosLosDias ? this.horaFin.value() : this.dateTimeComponents[1]?.instance.value();
        this.calendario.horaInicio2 = this.dateTimeComponents[2]?.instance.value();
        this.calendario.horaFin2 = this.dateTimeComponents[3]?.instance.value();
        this.calendario.horaInicio3 = this.dateTimeComponents[4]?.instance.value();
        this.calendario.horaFin3 = this.dateTimeComponents[5]?.instance.value();
        this.calendario.horaInicio4 = this.dateTimeComponents[6]?.instance.value();
        this.calendario.horaFin4 = this.dateTimeComponents[7]?.instance.value();
        this.calendario.horaInicio5 = this.dateTimeComponents[8]?.instance.value();
        this.calendario.horaFin5 = this.dateTimeComponents[9]?.instance.value();
        this.calendario.horaInicio6 = this.dateTimeComponents[10]?.instance.value();
        this.calendario.horaFin6 = this.dateTimeComponents[11]?.instance.value();
        this.calendario.horaInicio7 = this.dateTimeComponents[12]?.instance.value();
        this.calendario.horaFin7 = this.dateTimeComponents[13]?.instance.value();

        this.calendario = await this.calendarService.createCalendar(this.calendario);
      } else {

        let calendario: any = {
          EmpresaId: this.ssoService.getTicket().Empresa.IdGestion,
          Nombre: this.calendario.nombre,
          Observaciones: this.calendario.observaciones,
          FechaInicio: fechaInicio,
          FechaFin: fechaFin,
          TodosLosDias: this.todosLosDias,
          Excepto: this.diasExceptos,
          HoraInicio1: this.todosLosDias ? this.horaInicio.value() : this.dateTimeComponents[0]?.instance.value(),
          HoraFin1: this.todosLosDias ? this.horaFin.value() : this.dateTimeComponents[1]?.instance.value(),
          HoraInicio2: this.dateTimeComponents[2]?.instance.value(),
          HoraFin2: this.dateTimeComponents[3]?.instance.value(),
          HoraInicio3: this.dateTimeComponents[4]?.instance.value(),
          HoraFin3: this.dateTimeComponents[5]?.instance.value(),
          HoraInicio4: this.dateTimeComponents[6]?.instance.value(),
          HoraFin4: this.dateTimeComponents[7]?.instance.value(),
          HoraInicio5: this.dateTimeComponents[8]?.instance.value(),
          HoraFin5: this.dateTimeComponents[9]?.instance.value(),
          HoraInicio6: this.dateTimeComponents[10]?.instance.value(),
          HoraFin6: this.dateTimeComponents[11]?.instance.value(),
          HoraInicio7: this.dateTimeComponents[12]?.instance.value(),
          HoraFin7: this.dateTimeComponents[13]?.instance.value(),
          DiasEspecialesConServicio: this.calendario.diasEspecialesConServicio,
          DiasEspecialesSinServicio: this.calendario.diasEspecialesSinServicio
        };

        this.calendario = await this.calendarService.createCalendar(calendario);

      }
      if (this.calendario) {
        this.calendarios.push(this.calendario);
        CalendarComponent._this.updateGridLocal(this.calendarios);
        MainComponent.getInstance().showInfo(
          'ATENCION',
          'Registro_almacenado',
          2000
        );
        this.formEdit.close();

      } else {
        MainComponent.getInstance().showError(
          'ATENCION',
          'Fallo_almacenar_info',
          2000
        );
      }
    }
  }

  longDias(): number {
    let fechaInicio = new Date(this.desde.value());
    let fechaFin = new Date(this.hasta.value());
    let resta = fechaFin.getTime() - fechaInicio.getTime();
    let dias = Math.round(resta / (1000 * 60 * 60 * 24));
    return dias;
  }

  controlForm(): boolean {
    if (this.calendario.nombre === undefined || this.calendario.nombre === '') {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return false;
      // comprobar que la fecha de inicio es mayor a 7 dias y si es menor sumar 7 dias a la fecha de fin
    } else if (this.longDias() < 7) {
      MainComponent.getInstance().showError('ATENCION', 'Error_fecha_longitud', 2000);
      return false;
      // comprobar que la fecha de inicio es menor que la fecha de fin
    } else if (this.calendario.fechaInicio > this.calendario.fechaFin) {
      MainComponent.getInstance().showError('ATENCION', 'Error_fecha_inicio_fin', 2000);
    } else {
      return true;
    }
  }

  onChangeTodosDias(event: any): Boolean {
    if (event.currentTarget.checked === true) {
      let todosDias = event.currentTarget.checked;
      this.todosLosDias = todosDias;
      if (this.calendario.id) {
        this.dateTimeComponents.forEach((item: any) => {
          item.instance.val('00:00:00');
        });
      }
      this.myTabs.disable();
      this.dateTimeComponents[0]?.instance.disabled(true);
      this.dateTimeComponents[1]?.instance.disabled(true);
      this.horaInicio.disabled(false);
      this.horaFin.disabled(false);
      this.jqxDropDownList.disabled(false);
      this.dehabilitarCheckTabs(this.todosDias.nativeElement.checked);
      return this.todosLosDias;
    } else {
      this.myTabs.enable();
      this.dateTimeComponents[0]?.instance.disabled(false);
      this.dateTimeComponents[1]?.instance.disabled(false);
      this.horaInicio.disabled(true);
      this.horaFin.disabled(true);
      this.todosLosDias = false;
      this.jqxDropDownList.disabled(true);
      this.jqxDropDownList.uncheckAll();
      this.diasExceptos = null;
      this.dehabilitarCheckTabs(this.todosDias.nativeElement.checked);

      // pongo los valores de los componentes DateTime a 00:00:00
      return this.todosLosDias;
    }
  }

  controlTime(item: number): void {
    if (item == 0) {
      this.dateTimeComponents[1]?.instance.val(this.value);
    } else if (item == 1) {
      this.dateTimeComponents[3]?.instance.val(this.value);
    } else if (item == 2) {
      this.dateTimeComponents[5]?.instance.val(this.value);
    } else if (item == 3) {
      this.dateTimeComponents[7]?.instance.val(this.value);
    } else if (item == 4) {
      this.dateTimeComponents[9]?.instance.val(this.value);
    } else if (item == 5) {
      this.dateTimeComponents[11]?.instance.val(this.value);
    } else if (item == 6) {
      this.dateTimeComponents[13]?.instance.val(this.value);
    }

  }

  onChangeFechaDesde(event: any): Date {
    if (event.args.date) {
      let fechaInicio = event.args.date;

      // si la fecha de iniciio es menor que 7 dias sumar 7 dias a la fecha de fin
      if (this.longDias() < 7) {
        let fechaFin = new Date(fechaInicio);
        fechaFin.setDate(fechaFin.getDate() + 7);
        this.calendario.fechaFin = fechaFin;
        this.hasta.val(fechaFin);
      }
      return fechaInicio;
    }
  }

  onChangeFechaHasta(event: any): Date {
    if (event.args.date) {
      let fechaFin = event.args.date;
      return fechaFin;
    }
  }

  onChangeHoraInicio(event: any): Date {
    let horaInicio = event.args.date;
    return horaInicio;
  }

  onChangeHoraFin(event: any): Date {
    let horaFin = event.args.date;
    return horaFin;
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.dateTimeComponents = [];
      CalendarComponent._this.form.expand();
      this.myTabs.destroy();
    }
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  onMasSi(event: any) {
    if (this.calendario.diasEspecialesConServicio.length < 12) {
      this.calendario.diasEspecialesConServicio.push(
        new SpecialDaysModel(new Date(), '')
      );
      this.loadSpecialDays();
    } else {
      MainComponent.showWarning('ATENCION', 'Maximo_12_dias', 2000);
    }
  }

  onMenosSi(event: any) {
    if (this.rowSelecSi > -1) {
      this.calendario.diasEspecialesConServicio.splice(this.rowSelecSi, 1);
      this.loadSpecialDays();
    }
  }

  onMasNo(event: any) {
    if (this.calendario.diasEspecialesSinServicio.length < 12) {
      this.calendario.diasEspecialesSinServicio.push(
        new SpecialDaysModel(new Date(), '')
      );
      this.loadSpecialDays();
    } else {
      MainComponent.showWarning('ATENCION', 'Maximo_12_dias', 2000);
    }
  }

  onMenosNo(event: any) {
    if (this.rowSelecNo > -1) {
      this.calendario.diasEspecialesSinServicio.splice(this.rowSelecNo, 1);
      this.loadSpecialDays();
    }
  }

  onCellEditSi(event: any) {
    if (event && event.args) {
      switch (event.args.datafield) {
        case 'fecha':
          this.calendario.diasEspecialesConServicio[event.args.rowindex].fecha = event.args.value;
          break;
        case 'observaciones':
          this.calendario.diasEspecialesConServicio[event.args.rowindex].observaciones = event.args.value;
      }
    }
  }

  onCellEditNo(event: any) {
    if (event && event.args) {
      switch (event.args.datafield) {
        case 'fecha':
          this.calendario.diasEspecialesSinServicio[event.args.rowindex].fecha = event.args.value;
          break;
        case 'observaciones':
          this.calendario.diasEspecialesSinServicio[event.args.rowindex].observaciones = event.args.value;
      }
    }
  }

  onRowClickSi(event: any) {
    this.rowSelecSi = event.args.rowindex;
  }

  onRowClickNo(event: any) {
    this.rowSelecNo = event.args.rowindex;
  }

  onClickDiasNo(event: any) {
    if (!event.args.date) {
      this.loadSpecialDays();
    }
  }

  diasSiBinded() {
    this.__updateDiasServicioTitle();
  }

  diasNoBinded() {
    this.__updateDiasSinServicioTitle();
  }

  private __updateDiasServicioTitle(): void {
    this.totalEspecialServicio.set(`(${this.wridDiasSi.getrows().length})`);
  }

  private __updateDiasSinServicioTitle(): void {
    this.totalEspecialSinServicio.set(`(${this.wridDiasNo.getrows().length})`);
  }

}

