import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';
import { MapComponent, MapLatLng, MapMarker } from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxRibbonComponent } from 'jqwidgets-ng/jqxribbon';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { CatastroService } from 'src/app/services/direccion/catastro.service';
import { CiudadanosService } from 'src/app/services/ciudadanos/ciudadanos.service';

import { TicketModel } from 'src/app/services/sso/models/ticket.model';
import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';
import { DireccionModel } from 'src/app/services/ciudadanos/models/direccion.model';

import { CiudadanosTarjetasComponent } from '../ciudadanos-tarjetas/ciudadanos-tarjetas.component';
import { SelectorCatastroComponent } from '../direccion/selector-catastro/selector-catastro.component';
import { DireccionListadoComponent } from '../direccion/direccion-listado/direccion-listado.component';

@Component({
  selector: 'app-ciudadanos-edit',
  templateUrl: './ciudadanos-edit.component.html',
  styleUrls: ['./ciudadanos-edit.component.css']
})

export class CiudadanosEditComponent extends CustomForms implements OnInit {
  @Output() createCiudadano: EventEmitter<any>=new EventEmitter<any>();

  // Componentes del html
  @ViewChild('windowCiudadanosForm') windowCiudadanosForm: jqxWindowComponent;
  @ViewChild('ciudRibbon') ciudRibbon: jqxRibbonComponent;
  @ViewChild('gridDirecciones') gridDirecciones: jqxGridComponent;
  @ViewChild('mapSearch') mapSearch: any;
  @ViewChild('dropValidado') dropValidado: jqxDropDownListComponent;
  @ViewChild('selectorCatastro') selectorCatastro: SelectorCatastroComponent;

  @ViewChild('ciudadanoTarjetas') ciudadanoTarjetas: CiudadanosTarjetasComponent;
  @ViewChild('ciudadanoDirecciones') ciudadanoDirecciones: DireccionListadoComponent;
  inputMapSearch: HTMLInputElement;

  // Funcionalidad
  public static _this: CiudadanosEditComponent;
  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  //Comprobaciones
  showLoader: boolean = false;
  public guardar = false;
  ciudadanoEmailExist = false;
  public ecoIdentificadores = false;
  public ecoCompostaje = false;

  // Centrar mapa y añadir marca
  public map: MapComponent;
  public marker: MapMarker;

  // Tamaño de la pantalla sin contar menu gestion y lateral
  mapHeight;
  mapWidth;

  // Ciudadano editandose
  public ciudadano: CiudadanoModel;

  // Generales
  protected direccion: DireccionModel;
  public rol;
  public source: string[] =
    [
      AppComponent.translate('Pendiente'),
      AppComponent.translate('Validado'),
      AppComponent.translate('Rechazado')
    ]
  lpodsCiudadanos: any[] = [];
  camposCiudadanosVisible: any = {};

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    protected ciudadanoService: CiudadanosService,
    private ssoService: SsoService,
    private catastroService: CatastroService,
    private zonesService: ZonesService,
  ) {
    super();
    CiudadanosEditComponent._this = this;
  }

  async ngOnInit(): Promise<void> {
    let ticketSso: TicketModel = JSON.parse(sessionStorage.getItem('ticketSSO'));

    // Comprueba si tiene el modulo ecoidentificadores
    if (ticketSso && ticketSso.Modulos) {
      ticketSso.Modulos.forEach(element => {
        if (element.Id === 4) {
          this.ecoIdentificadores = true;
        }
      });
    }

    // Comprueba si tiene la aplicacion de ecoCompostaje
    if (ticketSso && ticketSso.Aplicacion) {
      if (ticketSso.Aplicacion.Id == 4) {
        this.ecoCompostaje = true;
      }
    }

    // Instancia el mapa
    this.map = MainComponent.getInstance().getMap();

    // Obtiene alto y ancho de pantalla sin contar menu inferior y lateral
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;

    // Obtiene idioma
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));



    // obtengo los campos iguales que en la variabke camposCiudadanosVisible



    this.guardar = false;
  }

  public async init(componentRef: any, ciudadano: CiudadanoModel) {
    // Inicializa el componente
    this.componentRef = componentRef;

    // Obtiene el rol del usuario logueado
    this.rol = this.ssoService.getTicket().Rol;

    // Obtiene el ciudadano para modificar
    this.ciudadano = ciudadano ? await this.ciudadanoService.getCiudadano(ciudadano.id) : new CiudadanoModel;
    this.lpodsCiudadanos = await this.ciudadanoService.getLopdCiudadanos();
    this.camposCiudadanosVisible = await this.ciudadanoService.getCamposVisiblesCiudadanos();
    this.procesarCamposVisibles(this.camposCiudadanosVisible, this.lpodsCiudadanos);

    // Si tiene ciudadano establece el valor para el campo de validacion
    if (ciudadano) {
      switch (ciudadano.estadoValidacion) {
        case 15:
          ciudadano.validadoTexto = this.translate('Validado');
          break;
        case 16:
          ciudadano.validadoTexto = this.translate('Rechazado');
          break;
        default:
          ciudadano.validadoTexto = this.translate('Pendiente');
          break;
      }

      // Inicia el array de tarjetas, si no existe
      if (!this.ciudadano.tarjetas) {
        this.ciudadano.tarjetas = [];
      }
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.windowCiudadanosForm);

    this.disableTabs(true);

    setTimeout(() => {
      this.inputMapSearch = this.mapSearch?.nativeElement.children[0].children[0].children[0].children[0];

      if (this.dropValidado) {
        if (this.ciudadano.id !== 0) {
          switch (this.ciudadano.estadoValidacion) {
            case 15:
              this.dropValidado.selectIndex(1);
              break;
            case 16:
              this.dropValidado.selectIndex(2);
              break;
            default:
              this.dropValidado.selectIndex(0);
              break;
          }
        } else {
          this.dropValidado.selectIndex(0);
        }
      }
    }, 500)
  }

  procesarCamposVisibles(camposVisibles: any[], lpodsCiudadanos: any[]) {
    const lpodsMap = new Map();

    // Crear un mapa con la información de los campos de lpodsCiudadanos
    lpodsCiudadanos.forEach(item => {
      const nombreNormalizado = item.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '');
      lpodsMap.set(nombreNormalizado, item);
    });

    // Inicializar this.camposCiudadanosVisible como un objeto vacío
    this.camposCiudadanosVisible = {};

    camposVisibles.forEach(campo => {
      const nombreNormalizado = campo.nombre.normalize('NFD').replace(/[\u0300-\u036f]/g, "").replace(/\s/g, '');
      if (campo.visible) {
        const infoCampo = lpodsMap.get(nombreNormalizado);
        this.camposCiudadanosVisible[nombreNormalizado] = {
          visible: campo.visible,
          obligatorio: infoCampo ? infoCampo.obligatorio : false,
          personal: infoCampo ? infoCampo.datoPersonal : false
        };
      }
    });
  }

  disableTabs(selectAt: boolean = false) {
    if (this.ciudadano && this.ciudadano.id === 0) {
      this.ciudRibbon.disableAt(1);
      this.ciudRibbon.disableAt(2);
    } else if (this.ciudadano && ((this.ciudadano.estadoValidacion !== 15 && this.ciudadano.estadoValidacion !== 1) || this.ciudadano?.direcciones?.length === 0)) {
      this.ciudRibbon.disableAt(2);
    } else if (selectAt) {
      this.ciudRibbon.selectAt(1);
      this.ciudRibbon.selectAt(2);
      this.ciudRibbon.selectAt(0);
    }
  }

  enableTabs() {
    if (this.ciudadano && ((this.ciudadano.estadoValidacion === 15 || this.ciudadano.estadoValidacion === 1) && this.ciudadano?.direcciones?.length > 0)) {
      this.ciudRibbon.enableAt(2);
    }
  }

  tabSelected(event: any) {
    if (event?.args?.selectedIndex === 1) {
      this.ciudadanoDirecciones.refreshGrid();
    }

    if (event?.args?.selectedIndex === 2) {
      this.ciudadanoTarjetas.cargarGrids();
    }
  }

  // Elimina el marcador del mapa
  removeMarker() {
    if (this.marker) {
      this.map.removeMarker(this.marker);
    }
  }

  closeWindow() {
    if (this.subscriptionMapClick) {
      this.subscriptionMapClick.unsubscribe();
      this.subscriptionMapClick = null;
      this.setPunteroPosicionar(false);
    }
    this.windowCiudadanosForm.close()
  }

  // al expandir el formulario
  onExpand(event: any) {
    if (this.subscriptionMapClick) {
      this.subscriptionMapClick.unsubscribe();
      this.subscriptionMapClick = null;
      this.setPunteroPosicionar(false);
    }
  }

  public onClose() {
    this.removeMarker();
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  /*
    Comprueba el email del ciudadano,
    si existe se muestra un mensaje de error
  */
  async checkCiudadano(event: any) {
    if (this.ciudadano && this.ciudadano.id === 0) {
      if (await this.ciudadanoService.checkCiudadanoExist(event.target.value)) {
        this.ciudadanoEmailExist = true;
      } else {
        this.ciudadanoEmailExist = false;
      }
    } else {
      this.ciudadanoEmailExist = false;
    }
  }

  onMapSearch(event: any) {
    this.ciudadano.direccion = event.place.Street;
    this.ciudadano.codigoPostal = event.place.PostalCode;
    this.ciudadano.poblacion = event.place.District;
    this.ciudadano.municipio = event.place.Locality;
    this.ciudadano.provincia = event.place.AdministrativeArea2;
    this.ciudadano.comunidadAutonoma = event.place.AdministrativeArea1;
    this.ciudadano.numero = event.place.StreetNumber;

    this.inputMapSearch.value = '';
  }

  setPunteroPosicionar(posicionando: boolean) {
    let mp = <HTMLElement>document.getElementsByClassName('gm-style')[0].children[0];
    if (posicionando) {
      mp.classList.add('setPosition');
    } else {
      mp.classList.remove('setPosition');
    }
  }

  private subscriptionMapClick: any = null;

  onPosicionar() {
    this.windowCiudadanosForm.collapse();
    // Cambio el puntero del ratón sobre el mapa
    this.setPunteroPosicionar(true);
    if (!this.subscriptionMapClick) {
      this.subscriptionMapClick = this.subscribeOnMapClick();
    }
  }

  // Cada vez que se pincha sobre la cartogafía
  subscribeOnMapClick(): any {
    return this.map.subscribeOnMapClick(
      this,
      async (_this: any, position: MapLatLng) => {
        let direccion = await this.zonesService.getDireccion(position.lat, position.lng);
        this.ciudadano.direccion = direccion.Street;
        this.ciudadano.numero = direccion.StreetNumber;
        this.ciudadano.codigoPostal = direccion.PostalCode;
        this.ciudadano.poblacion = direccion.District;
        this.ciudadano.municipio = direccion.Locality;
        this.ciudadano.provincia = direccion.AdministrativeArea2;
        this.ciudadano.comunidadAutonoma = direccion.AdministrativeArea1;
        this.subscriptionMapClick.unsubscribe();
        this.subscriptionMapClick = null;
        this.setPunteroPosicionar(false);
        try {
          this.windowCiudadanosForm?.expand();
        } catch (error) {
        }
      }
    );
  }

  changeValidado(event: any) {
    let newValue = event.args.item.value;

    if (newValue === AppComponent.translate('Pendiente')) {
      this.ciudadano.estadoValidacion = 14;
    } else if (newValue === AppComponent.translate('Validado')) {
      this.ciudadano.estadoValidacion = 15;
    } else if (newValue === AppComponent.translate('Rechazado')) {
      this.ciudadano.estadoValidacion = 16;
    }

    this.disableTabs();
    this.enableTabs();
  }

  asignarDireccion(event: DireccionModel) {
    this.direccion = null;
    this.direccion = event;

    this.ciudRibbon.selectAt(2);
  }

  async onGuardar(): Promise<void> {
    this.showLoader = true;
    if (!this.ciudadanoEmailExist) {
      if (this.selectorCatastro && this.selectorCatastro.catastro &&
        (this.selectorCatastro.catastro.titular !== '' ||
          this.selectorCatastro.catastro.referenciaCatastral !== '')
      ) {
        this.saveCatastro();
      }

      this.saveCiudadano();
    } else {
      this.showLoader = false;
      MainComponent.getInstance().showError(
        'ATENCION',
        'Revisa_campos_formulario',
        2000
      );
    }
  }

  async saveCatastro() {
    if (this.selectorCatastro.catastro.referenciaCatastral !== '') {
      try {
        await this.catastroService.saveCatastro(this.selectorCatastro.catastro);
      } catch (error) {
      }
    } else {
      MainComponent.getInstance().showWarning(
        'ATENCION',
        'No_se_ha_indicado_un_numero_de_catastro',
        2000
      );
    }
  }

  validateRequiredFields(): boolean {
    const nombresCamposObligatorios = Object.entries(this.camposCiudadanosVisible)
      .filter(([_, configuracionCampo]: [string, any]) => configuracionCampo.obligatorio)
      .map(([nombreCampo, _]) => nombreCampo.toLocaleLowerCase());

    const cidadanoPropertiesMap = Object.fromEntries(Object.keys(this.ciudadano).map(key => [key.toLocaleLowerCase(), key]));

    const invalidValues = nombresCamposObligatorios.filter(campoObligatrio => {
      const propertyName = cidadanoPropertiesMap[campoObligatrio];

      return !this.ciudadano.hasOwnProperty(propertyName) || !this._hasValue(this.ciudadano[propertyName])
    });


    return !invalidValues.length;
  }

  async saveCiudadano() {
    // Validar campos obligatorios
    if (!this.validateRequiredFields()) {
      this.showLoader = false;
      MainComponent.getInstance().showError(
        'ATENCION',
        'Revisa_campos_formulario_obligatorios',
        2000
      );
      return;
    }

    let ciudadanoOriginal = { ...this.ciudadano };

    const savedCiudadano = await this.ciudadanoService.saveCiudadano(this.ciudadano);

    if(!savedCiudadano) {
      this.showLoader = false;
    }

    if (ciudadanoOriginal.id !== 0) {
      this.guardar = true;
      this.removeMarker();
      await this.ciudadanoTarjetas.onGuardar();
    }

    const ciudadanoGetted = await this.ciudadanoService.getCiudadano(savedCiudadano.id);

    if(!ciudadanoGetted) {
      this.showLoader = false;
      return;
    }

    this.ciudadano = ciudadanoGetted;

    if (ciudadanoOriginal.id === 0) {
      if (!this.ciudadano.direcciones) {
        this.ciudadano.direcciones = [];
      }

      switch (this.ciudadano.estadoValidacion) {
        case 15:
          this.ciudadano.validadoTexto = this.translate('Validado');
          break;
        case 16:
          this.ciudadano.validadoTexto = this.translate('Rechazado');
          break;
        default:
          this.ciudadano.validadoTexto = this.translate('Pendiente');
          break;
      }

      this.ciudRibbon.enableAt(1);
      this.enableTabs();
    } else {
      this.windowCiudadanosForm.close();
    }

    MainComponent.getInstance().showInfo(
      'ATENCION',
      'Registro_almacenado',
      2000
    );
    this.createCiudadano.emit(this.ciudadano);
    this.showLoader = false;
  }

  expandForm(){
    this.windowCiudadanosForm.expand();
    this.windowCiudadanosForm.bringToFront();
  }

  private _hasValue(value) {
    return !!value && !!value.trim();
  }
}
