
import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { GeoUtils } from 'src/app/utils/geo-utils';
import { CustomForms } from '../forms/custom-forms';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../main/main.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';

import { MapBounds, MapCircle, MapComponent, MapLatLng, MapPolygon, MapMarker, MapPolygonPoint } from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { CartoService } from 'src/app/services/carto/carto.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { BdtService, BDT_RECURSOS } from 'src/app/services/bdt/bdt.service';

import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { BdtRecursoModel } from 'src/app/services/bdt/models/bdt-recurso.model';
import { EntradaModel } from 'src/app/services/zones/models/zona-entrada.model';
import { AreaDetail, } from 'src/app/services/carto/models/area-detail.model';

import { EntradaSalidaComponent } from './entrada-salida/entrada-salida.component';
import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { HeaderComponent } from '../header/header.component';
import { jqxTooltipComponent } from 'jqwidgets-ng/jqxtooltip';
import { FormControl } from '@angular/forms';
import { MatAutocompleteActivatedEvent, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, Subscription } from 'rxjs';
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import { LangService } from '../../services/lang/lang.service';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { AmbitoActividadModel } from 'src/app/services/geographics/ambito-actividad.model';
import { MarcoGeograficoModel } from 'src/app/services/geographics/marco-geografico.model';
import { AlarmsService } from 'src/app/services/alarms/alarms.service';
import { AlarmModel } from 'src/app/services/alarms/models/alarm.model';

export enum TiposMaster {
  ZONAADMIN = 1,
  MANUAL = 2,
  ELEMENTO = 3,
  EDIFICIOS = 4,
}

export enum Aplicacion {
  ECOSATLITE = 11,
  GESCONSAT = 14
}

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.css']
})

export class ZonesComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;

  @ViewChild('nombre') nombre: jqxInputComponent;
  @ViewChild('observaciones') observaciones: jqxInputComponent;
  @ViewChild('gridZonas') gridZonas: jqxGridComponent;
  @ViewChild('formEditZona') formEditZona: jqxWindowComponent;
  @ViewChild('tabZonasEdit') tabZonasEdit: jqxTabsComponent;
  @ViewChild('cbTiposZonas') cbTiposZonas: jqxDropDownListComponent;
  @ViewChild('cbTiposGeo') cbTiposGeo: jqxDropDownListComponent;
  @ViewChild('cbTiposMaster') cbTiposMaster: jqxDropDownListComponent;
  @ViewChild('header') header: HeaderComponent;

  theme = environment.tema;
  titleWindow: string = '';
  titleEditWindow: string = '';
  selectTipoMaster: number = 1;
  hiddenTipoZonas: boolean = false;
  hiddenTipoMaster: boolean = false;
  hiddenGeometria: boolean = false;
  hiddenRadius: boolean = false;
  hiddenInputSearch: boolean = true;
  hiddenDrawButton: boolean = false;
  hiddenELementContainer: boolean = false;
  infoMarker: any;
  infoNombre: any;
  infoElement: any;
  hideBdtCombo: any;
  inputSearch: any;
  timer: any;
  superficie: any;
  stringSearch = '';
  public areas = new Map<number, string>();
  adminAreas;
  areaDetail: AreaDetail;
  polygonPoints: MapLatLng[] = [];
  public static _this: ZonesComponent;
  private map: MapComponent;
  public zonaSelec: ZonaModel;
  public zonaEdit: ZonaModel = new ZonaModel();
  zonas: ZonaModel[] = [];
  public dataSource: any;

  dataAdapter: any;

  mapZonaEdit: MapComponent;
  private componentRef = null;
  public environment = environment;
  public canEdit = true;

  alarmas: AlarmModel[] = [];

  zonasConAmbito: ZonaModel[] = [];
  public circle: MapCircle;
  polygon: MapPolygon = null;

  polygonsMapEdit: any[] = [];
  polygonsMap: any[] = [];

  circlesMapEdit: MapCircle[] = [];
  circlesMap: MapCircle[] = [];


  public polygonGeoJson = null;
  public polygonGeoJsonMapEdit = null;
  public subscriptionOnMapClick = null;
  private subscriptionOnCircleDragend = null;
  private subscriptionOnRadiusChange = null;
  private subscriptionOnMarkerClick = null;
  private subscriptionOnPolygonDragEnd = null;
  private tipos: BdtRecursoModel[] = [];
  private rowIndex = -1;
  private select: any = [];
  public modoManual = false;
  private editAlarm: any;
  private formZones: boolean;
  public change: boolean = false;
  public viewGridEntradasSalidas: boolean;
  editEntradas: boolean;
  public idZonaEntrada: number;
  public entradas: EntradaModel[] = [];
  private marker: MapMarker;
  public markersZone: MapMarker[] = [];
  private entrada: EntradaModel = new EntradaModel();
  rows: any[] = [];
  row: any;
  indexRow: number[] = [];
  mapWidth: number;
  mapHeight: number;
  verTtitulo: boolean = false;
  jqxTooltip: jqxTooltipComponent;
  conTexto: boolean = false;
  showLoader: boolean = false;
  selectedRowIndexes: number[] = [];
  fijandoZonas: boolean = false;
  isVisible = false;
  isOkLoading = false;
  public mostrarEditZona = false;

  // Preparo las columnas del grid
  public columns: any[] = [];
  //filtro para el grid
  addfilter = (): void => {
    let filtergroup = new jqx.filter();
    let filtervalue = 'Manual';
    let filtercondition = 'contains';
    let filter1 = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);
    filtergroup.addfilter(1, filter1);
    // add the filters
    this.gridZonas.addfilter('tipo', filtergroup);
    // apply the filters
    this.gridZonas.applyfilters();
  };
  creandoZonaMasEntradas: boolean = false;
  checkSelect: ZonaModel[] = [];
  verDesdeColumn: boolean;

  private subscription: Subscription = new Subscription();
  private checkNumerosSubject = new BehaviorSubject<number>(0);
  public checkNumeros$ = this.checkNumerosSubject.asObservable();
  public checkNumerosValue: number = 0;
  entraUnaVez = true;
  copyTextClipboard: string = '';

  public mapProvider = 'Google';
  public cartoType = 'raster';
  public lang = 'es-ES'; // Idioma por defecto
  public searchCountry = 'ES'; // Pais por defecto (para las búsquedas en el mapa)
  public zoom = 6;
  rowSelectEntradaSalida: EntradaModel;
  zonasFijas: any = [];
  onVerZonas: boolean = false;
  modificandoPolygon: boolean = false;

  // Incializa la columna de botones
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    let rowdata: any;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.gridZonas.getrowdata(row);
    }

    htmlElement.innerHTML = '';
    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'flex-start';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
      <button class="button" style="height: 23px; width: 30px; cursor: pointer;" title="` + AppComponent.translate('Editar') + `">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;
    btnEdit.id = `buttonEdit`;
    btnContainer.appendChild(btnEdit);

    btnEdit.addEventListener('click', (event: any) => {
      this.row = row;
      this.zonaSelec = this.zonas.find(elem => elem.Id == rowdata.id);
      this.onEditarZona();
    });

    // onVer
    const btnVer = document.createElement('div');
    btnVer.innerHTML = `
      <button class="button" style="height: 23px; width: 30px; cursor: pointer;" title="` + AppComponent.translate('Ver') + `">
        <i class="fa-solid fa-eye"></i>
      </button>
    `;
    btnVer.id = `buttonVer`;
    btnContainer.appendChild(btnVer);

    btnVer.addEventListener('click', async (event: any) => {
      this.verDesdeColumn = true;
      this.zonaSelec = this.zonas.find(elem => elem.Id == rowdata.id);
      this.onVer();
    });

    const btnDelete = document.createElement('div');
    btnDelete.innerHTML = `
        <button class="button" style="height: 23px; width: 30px; cursor: pointer;" title="` + AppComponent.translate('Borrar') + `">
          <i class="fa-solid fa-trash"></i>
        </button>
      `;
    btnDelete.id = `buttonDelete`;
    btnContainer.appendChild(btnDelete);
    btnDelete.addEventListener('click', async (event: any) => {
      this.zonaSelec = this.zonas.find(elem => elem.Id == rowdata.id);
      // borro la zona si no esta dentro de una alarma
      if (this.alarmas.some(alarm => alarm.Zona.Id === this.zonaSelec.Id)) {
        this.modal.error({
          nzTitle: 'Error',
          nzContent: 'No se puede borrar la zona porque está asociada a una alarma',
        });
      } else {
        this.onBorrarZona();
      }
    });
    htmlElement.appendChild(btnContainer);
  }

  rendexTextGeneric = (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    const isSelected = Array.isArray(ZonesComponent._this.zonasFijas)
      ? ZonesComponent._this.zonasFijas.find(s => s.id === rowdata.id) : ZonesComponent._this.zonasFijas.id === rowdata.id;
    const dataAttribute = isSelected ? `data-id="${rowdata.id}"` : '';

    if (isSelected) {
      switch (columnfield) {
        case 'area':
        case 'radio':
        case 'entradas':
        case 'salidas':
          return `<div ${dataAttribute} style="margin-right: 4px; margin-top: 4px; text-align: right; color:white"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';">${NumberUtils.format(value, 0)}</span></div>`;
        default:
          return `<div ${dataAttribute} style="margin-left: 4px; margin-top: 5px; text-align: left; color:white;" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';" onmouseout="this.style.backgroundColor=''; this.style.color='white'; this.style.position='';">${value}</div>`;
      }
    } else {
      switch (columnfield) {
        case 'area':
        case 'radio':
        case 'entradas':
        case 'salidas':
          return `<div style="margin-right: 4px; margin-top: 4px; text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';">${NumberUtils.format(value, 0)}</span></div>`;
        default:
          return `<div style="margin-left: 4px; margin-top: 5px; text-align: left;" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';" onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;
      }
    }
  };

  cellclass(row, columnfield, value, rowdata) {
    // Verifica si ZonesComponent._this.select no es null o undefined y si ZonesComponent._this.select es un array
    if (Array.isArray(ZonesComponent._this.zonasFijas) && ZonesComponent._this.zonasFijas.some(sel => sel.id === rowdata.id) || ZonesComponent._this.fijandoZonas) {
      ZonesComponent._this.fijandoZonas = false;
      return 'blue';
    }
  }

  initGrid() {
    this.columns = [
      {
        text: '',
        width: 100,
        columntype: 'text',
        sortable: false,
        editable: false,
        groupable: false,
        menu: false,
        rendered: (columnHeaderElement) => {
          const buttonContainer1 = document.createElement('div');
          buttonContainer1.style.width = '100%';
          buttonContainer1.style.display = 'flex';
          buttonContainer1.style.justifyContent = 'center';

          buttonContainer1.id = `buttonContainerColumn_jqxButton`;
          columnHeaderElement[0].appendChild(buttonContainer1);
          const btnCrear = document.createElement('div');
          btnCrear.innerHTML =
            `<button class="button" style="height: 20px; width: 100%; padding: 0; margin: 0; cursor: pointer !important ; title="` +
            AppComponent.translate('Crear') + `"> <i style="font-size: 18px;" class="fa-solid fa-plus"></i></button>`;
          btnCrear.id = `buttonCrear_jqxButton`;
          buttonContainer1.appendChild(btnCrear);
          btnCrear.style.width = '50%';
          btnCrear.addEventListener('click', (event: any) => {
            this.zonaEdit = new ZonaModel();
            this.zonaSelec = this.zonaEdit;
            this.onCrearZona();
          });
          return columnHeaderElement[0];
        },
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          },
        }],
        aggregatesrenderer: (aggregates) => this.getRenderString(aggregates),

        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
      },
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true, },
      {
        text: AppComponent.translate('Texto'),
        cellclassname: this.cellclass,
        columntype: 'checkbox',
        filtertype: 'list',
        width: '3%',
        datafield: 'textoVisible',
        filteritems: ['Si', 'No'],
        cellvaluechanging: (row, column, columntype, oldvalue, newvalue) => {
          if (row.boundindex) {
            this.row = row.boundindex;
          } else {
            this.row = row;
          }
          //busco en el array de columnas el que coincida con el id
          let data = this.gridZonas.getrowdata(this.row);
          let found = this.zonas.find((zona) => zona.Id == data.id);
          this.conTexto = newvalue;

          switch (found.TipoGeo) {
            case 0:
              // modifico el circulo en el mapa
              if (this.circlesMap.length) {
                this.circlesMap.forEach(circle => {
                  if (circle.dataModel.Id == found.Id) {
                    circle.setContent(newvalue ? found.Nombre : '');
                  }
                });
              }
              ZonesComponent._this.saveFilter();
              break;
            case 1:
              if (this.polygonsMap.length) {
                this.polygonsMap.forEach((polygon) => {
                  // Asegurándonos de que dataModel existe y tiene al menos un elemento
                  if (polygon.dataModel) {
                    const objeto = polygon.dataModel;
                    Object.keys(objeto).forEach(key => {
                      if ('Id' in objeto[key] && objeto[key].Id === found.Id) {
                        polygon.setContent(newvalue ? found.Nombre : '');
                      }
                    });
                  } else {
                    const objeto = polygon.polygons[0];
                    if (polygon.Id == found.Id) {
                      objeto.setContent(newvalue ? polygon.Nombre : '');
                    }
                  }
                });
              } else {
                ZonesComponent._this.saveFilter();
              }
              break;
          }
          return newvalue;
        }
      },
      {
        text: AppComponent.translate('Nombre'),
        width: 100,
        columntype: 'textbox',
        cellsalign: 'left',
        filtertype: 'textbox',
        datafield: 'nombre',
        editable: false,
        cellsrenderer: this.rendexTextGeneric,
        cellclassname: this.cellclass
      },
      {
        text: AppComponent.translate('Tipo'),
        columntype: 'textbox',
        width: 100,
        filtertype: 'checkedlist',
        datafield: 'tipo',
        hidden: this.modoManual,
        editable: false,
        cellsrenderer: this.rendexTextGeneric,
        cellclassname: this.cellclass
      },
      {
        text: AppComponent.translate('Geometria'),
        columntype: 'textbox',
        width: 100,
        filtertype: 'checkedlist',
        datafield: 'tipo_geo',
        editable: false,
        cellsrenderer: this.rendexTextGeneric,
        cellclassname: this.cellclass
      },
      {
        text: AppComponent.translate('Radio') + ' (m)',
        columntype: 'textbox',
        width: 60,
        filtertype: 'number',
        datafield: 'radio',
        editable: false,
        cellsrenderer: this.rendexTextGeneric,
        cellclassname: this.cellclass
      },
      {
        text: AppComponent.translate('Superficie') + ' (m²)',
        columntype: 'textbox',
        width: 100,
        filtertype: 'number',
        datafield: 'area',
        editable: false,
        cellsrenderer: this.rendexTextGeneric,
        cellclassname: this.cellclass
      },
      {
        text: AppComponent.translate('Entradas'),
        columntype: 'textbox',
        filtertype: 'number',
        width: 70,
        cellsalign: 'right',
        datafield: 'entradas',
        editable: false,
        cellsrenderer: this.rendexTextGeneric,
        cellclassname: this.cellclass
      },
      {
        text: AppComponent.translate('Salidas'),
        columntype: 'textbox',
        filtertype: 'number',
        width: 70,
        datafield: 'salidas',
        cellsalign: 'right',
        editable: false,
        menu: false,
        sortable: false,
        cellsrenderer: this.rendexTextGeneric,
        cellclassname: this.cellclass
      },
      {
        text: AppComponent.translate('Observaciones'),
        columntype: 'textbox',
        filtertype: 'input',
        width: 150,
        datafield: 'observ',
        editable: false,
        menu: false,
        sortable: false,
        cellsrenderer: this.rendexTextGeneric,
        cellclassname: this.cellclass
      },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
    ];
    let isFirstIteration = true;
    this.columns.forEach(column => {
      if (!isFirstIteration) {
        column.rendered = (element) => {
          if (column.text === AppComponent.translate('Texto')) {
            Utils.tooltiprenderer(element, AppComponent.translate('Check_texto'));
          } else {
            Utils.tooltiprenderer(element, column.title);
          }
        };
      } else {
        isFirstIteration = false;
      }
    });
  }

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(
    private ssoService: SsoService,
    private zonesService: ZonesService,
    private modal: NzModalService,
    private bdtService: BdtService,
    private configService: ConfigService,
    private cartoService: CartoService,
    public langService: LangService,
    private alarmsService: AlarmsService,
  ) {
    super();
    ZonesComponent._this = this;
    this.subscription.add(
      this.checkNumeros$.subscribe(valorActualizado => {
        this.checkNumerosValue = valorActualizado;
        this.updateRenderString();
      })
    );
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, modoManual: boolean, editAlarm: any, formZones: boolean) {
    this.componentRef = componentRef;
    this.modoManual = modoManual;
    this.editAlarm = editAlarm;
    this.formZones = formZones;
  }

  async ngOnInit(): Promise<void> {
    this.showLoader = true;
    delete this.zonaEdit.nombre;
    delete this.zonaEdit.lat;
    delete this.zonaEdit.lng;
    delete this.zonaEdit.id;
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.initGrid();
    this.canEdit = true;
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.map = MainComponent.getInstance().getActiveMap();
    if (this.modoManual) {
      this.titleWindow = this.translate('Poligonos');
      this.titleEditWindow = this.translate('Edicion_poligonos');
    } else {
      // Si la aplicación es gesconsat, se cambia el nombre de las dos ventanas y se oculta la opción de edificios/instalaciones
      if (MainComponent.getInstance().ssoTicket.Aplicacion.Id == Aplicacion.GESCONSAT) {
        this.hideBdtCombo = true;
        this.titleWindow = this.translate('Zonas_cartograficas');
        this.titleEditWindow = this.translate('Edicion_zonas_cartograficas');
      } else {
        this.hideBdtCombo = false;
        this.titleWindow = this.translate('Edificios_instalaciones');
        this.titleEditWindow = this.translate('Edicion_edificios_instalaciones');
      }
    }

    this.zonasFijas = JSON.parse(await this.configService.getUsuEmpApp('zones-fixed', null));
    if (!this.zonasFijas) {
      this.zonasFijas = [];
    }
    if (this.zonasFijas && Array.isArray(this.zonasFijas)) {
      this.zonasFijas = this.zonasFijas.filter((zona, index, self) =>
        index === self.findIndex((t) => (
          t.id === zona.id
        ))
      );
    }

    this.alarmas = await this.alarmsService.getAlarmas();
    this.alarmas = this.alarmas.filter(alarm => alarm.Zona !== undefined);
  }

  onMapReady(map: MapComponent) {
    if (this.mapZonaEdit) {
      this.mapZonaEdit = null;
    }

    this.mapZonaEdit = map;
    this.marcoGeografico = MainComponent.getInstance().marcoGeografico;
    this.ambitoActividad = MainComponent.getInstance().ambitoActividad;
    if (!this.marcoGeografico.marco.contains(this.mapZonaEdit.center)) {
      this.mapZonaEdit.onBoundsChange(this.mapZonaEdit.getBounds());
    } else {
      this.minZoom = this.mapZonaEdit.zoom;
      try {
        setTimeout(() => {
          this.lastBounds = this.mapZonaEdit.getBounds();
        }, 500);
      } catch (error) {
      }
    }
  }

  private lastZoom;
  private lastBounds: MapBounds;
  private minZoom;

  onBoundsChange(bounds: MapBounds) {
    this.marcoGeografico = MainComponent.getInstance().marcoGeografico;
    this.ambitoActividad = MainComponent.getInstance().ambitoActividad;
    if (this.marcoGeografico) {
      if (!this.lastBounds) {
        this.mapZonaEdit.fitTo(this.marcoGeografico.marco);
        this.lastZoom = this.mapZonaEdit.zoom;
        this.minZoom = this.mapZonaEdit.zoom;
      } else {
        if (!this.lastBounds && (bounds.contains(this.marcoGeografico.marco.swCorner) ||
          bounds.contains(this.marcoGeografico.marco.neCorner))) {
          this.mapZonaEdit.fitTo(this.marcoGeografico.marco);
          this.minZoom = this.mapZonaEdit.zoom;
        } else {
          if (!this.marcoGeografico.marco.contains(this.mapZonaEdit.center)) {
            if (this.lastBounds.contains(this.marcoGeografico.marco.swCorner) &&
              this.lastBounds.contains(this.marcoGeografico.marco.neCorner)) {
              this.mapZonaEdit.setZoom(this.lastZoom + 1);
              this.mapZonaEdit.fitTo(this.marcoGeografico.marco);
            } else {
              this.mapZonaEdit.fitTo(this.lastBounds);
            }
            return;
          } else {
            if (bounds.contains(this.marcoGeografico.marco.swCorner) &&
              bounds.contains(this.marcoGeografico.marco.neCorner) && this.mapZonaEdit.zoom < this.lastZoom) {
              this.mapZonaEdit.setZoom(Math.max(this.lastZoom, this.minZoom));
              this.mapZonaEdit.fitTo(this.marcoGeografico.marco);
              const center = {
                lat: this.marcoGeografico.marco.swCorner.lat +
                  (this.marcoGeografico.marco.neCorner.lat - this.marcoGeografico.marco.swCorner.lat) / 2,
                lng: this.marcoGeografico.marco.swCorner.lng +
                  (this.marcoGeografico.marco.neCorner.lng - this.marcoGeografico.marco.swCorner.lng) / 2
              };
              return;
            }
          }
        }
      }
    }
  }

  async onCheckTextoChange(event: any) {
    // muestro el texto en el circulo o poligono si esta seleccionado el check
    this.zonaEdit.TextoVisible = event.args.checked;


    if (this.zonaEdit.TipoGeo === 0 && this.circlesMapEdit.length > 0) {
      this.circlesMapEdit.forEach(circle => {
        if (circle.dataModel.Id === this.zonaEdit.Id) {
          circle.setContent(event.args.checked ? this.zonaEdit.Nombre : '');
        }
      });
    } else if (this.zonaEdit.TipoGeo === 1 && this.polygonsMapEdit.length > 0) {
      this.polygonsMapEdit.forEach(polygon => {
        if (this.zonaEdit.Id) {
          if (polygon.dataModel) {
            const objeto = polygon.dataModel;
            Object.keys(objeto).forEach(key => {
              if ('Id' in objeto[key] && objeto[key].Id === this.zonaEdit.Id) {
                polygon.setContent(event.args.checked ? this.zonaEdit.Nombre : '');
              }
            });
          } else {
            const objeto = polygon.polygons[0];
            if (polygon.Id == this.zonaEdit.Id) {
              objeto.setContent(event.args.checked ? polygon.Nombre : '');
            }
          }
        }
      });
    }
  }

  closeWindow() {
    this.form.close();
  }

  onTabclick(event: any) {
    if (event.args.item === 1 && this.zonaSelec.Id > 0) {
      EntradaSalidaComponent._this.getEntradas(true);
    } else if (event.args.item === 1 && this.zonaSelec.Id < 1) {
      EntradaSalidaComponent._this.getEntradas(false);
    } else {
      return;
    }
  }

  updateRenderString() {
    this.columns.forEach(column => {
      if (column.aggregatesrenderer) {
        column.aggregatesrenderer = (aggregates) => this.getRenderString(aggregates);
      }
      this.gridZonas.refreshaggregates();
    });
  }

  getRenderString(aggregates): string {
    let renderString = '';
    renderString = '<div class="cellTotal" style="margin-left: 4px; position:fixed;">' +
      aggregates['Total'] + ', ' + this.checkNumerosValue + '<b> selecc </b>' + ', ' + ZonesComponent._this.zonasFijas.length +
      '<b> selecc mapa </b>' + '<span style="display: inline-block; width: 10px; height: 10px; background-color: rgb(54, 144, 204); margin-left: 5px;"></span>' + '</div>';
    return renderString;
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    // Cambia el título de la ventana
    this.form.setTitle(this.titleWindow);
    this.tipos = await this.bdtService.getRecursos(BDT_RECURSOS.Edificios);
    // Recupero los filtros
    this.select = JSON.parse(await this.configService.getUsuEmpApp('zones-filter', null));
    await this.getZones();
    this.onCloseForm();
    this.gridZonas.setOptions({
      statusbarheight: 20,
      rowsheight: 25,
      columnsheight: 20,
    });
    this.showLoader = false;
  }

  async getCheckConTexto() {
    if (this.gridZonas) {
      let rows: any = this.gridZonas.getrows();
      let rowsConTexto: any = [];
      if (rows) {
        // recorro las filas del grid que tienen la propiedad textoVisible a true
        rows.forEach(row => {
          if (row.textoVisible) {
            // devulvo el objeto con el id y el textoVisible
            rowsConTexto.push({ Id: row.id, TextoVisible: row.textoVisible });
          }
        });
      }
      return rowsConTexto;
    }
  }
  public marcoGeografico: MarcoGeograficoModel;
  public ambitoActividad: AmbitoActividadModel;
  public searchBounds: string = '';


  onCloseForm() {
    if (this.formZones) {
      this.form.destroy();
      this.zonaEdit = new ZonaModel();
      this.zonaSelec = this.zonaEdit;
      //this.onEditarZona();
      this.onCrearZona();
      //this.mostrarEditZona = true;
    }
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.circle) {
      this.map.removeCircle(this.circle);
      if (this.mapZonaEdit) {
        this.mapZonaEdit.removeCircles();
      }
      this.circle = null;
    }
    if (this.circlesMap.length > 0) {
      this.circlesMap.forEach(circle => {
        this.map.removeCircle(circle);
      });
      this.circlesMap = [];
    }

    this.deleteZonesMap();
    this.deleteZonesMapEdit();

    if (EntradaSalidaComponent._this) {
      EntradaSalidaComponent._this.removeMarker();
    }

    this.checkNumerosSubject?.unsubscribe();

    // Guardo el filtro de zonas visibles
    this.saveFilter();
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.editAlarm) {
      this.editAlarm.expand();
    }

    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
    }
    if (this.subscriptionOnMarkerClick) {
      this.subscriptionOnMarkerClick.unsubscribe();
    }

    ZonesComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  async handleOk() {
    this.isOkLoading = true;
    await this.zonesService.deleteEntrada(this.rowSelectEntradaSalida.Id);
    this.zonaEdit.Entradas = await this.zonesService.getEntradas(this.zonaEdit.Id);
    EntradaSalidaComponent._this.dataSource.localdata = this.zonaEdit.Entradas;
    EntradaSalidaComponent._this.grid.updatebounddata('data');
    this.removeMarker(this.rowSelectEntradaSalida.Id);
    this.tabZonasEdit.setTitleAt(1, this.translate('Entradas_salidas') + ' (' + this.zonaEdit.Entradas.length + ')');
    this.isOkLoading = false;
    this.isVisible = false;
  }

  // elimino marcador de la zona
  removeMarker(id: number) {
    if (this.markersZone.length > 0) {
      this.markersZone.forEach(marker => {
        if (marker.dataModel.Id === id) {
          this.map.removeMarker(marker);
        }
      });
    }

    if (EntradaSalidaComponent._this.markers.length > 0) {
      EntradaSalidaComponent._this.markers.forEach(marker => {
        if (marker.dataModel.Id === id) {
          this.mapZonaEdit.removeMarker(marker);
        }
      });
    }
  }

  // Obtengo las zonas de la empresa
  async getZones() {
    this.zonas = await this.zonesService.getZonas();
    if (this.zonas) {
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'int', map: 'Id' },
          { name: 'textoVisible', type: 'bool', map: 'TextoVisible' },
          { name: 'nombre', type: 'string', map: 'Nombre' },
          { name: 'tipo', type: 'string', map: 'TipoNombre' },
          { name: 'tipo_geo', type: 'string', map: 'TipoGeoNombre' },
          { name: 'radio', type: 'number', map: 'Radio' },
          { name: 'area', type: 'number', map: 'Area' },
          { name: 'entradas', type: 'number', map: 'EntradasCount' },
          { name: 'salidas', type: 'number', map: 'SalidasCount' },
          { name: 'entradas_salidas', type: 'number', map: 'EntradasSalidasCount' },
          { name: 'observ', type: 'string', map: 'Observaciones' },
          { name: 'selec', map: 'selec', type: 'string' }
        ],
        localdata: this.zonas,
      };
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);
    } else {
      this.zonas = [];
    }
  }

  // Cuando se ha cargado la lista
  onBindingComplete() {
    // solo entra una vez
    if (this.gridZonas) {
      this.gridZonas.sortby('nombre', 'asc');
      const rows = this.gridZonas.getrows();

      // si solo se pueden crear polígonos, mostrar solo zonas manuales
      if (this.modoManual) {
        this.addfilter();
      }
      if (rows.length) {
        rows.forEach(row => {
          // Verificar si la zona está fija o seleccionada
          if (this.zonasFijas.length) {
            const zonaFija = this.zonasFijas.find(z => z.id === row.id);
            if (zonaFija) {
              this.gridZonas.selectrow(row.boundindex);
            }
          }
          if (this.zonasFijas.length) {
            const zonaSeleccionada = this.zonasFijas.find(s => s.id === row.id);
            // Si la zona tiene el texto visible
            if (zonaSeleccionada && zonaSeleccionada.TextoVisible) {
              this.gridZonas.setcellvalue(row.boundindex, 'textoVisible', true);
            }
          }
        });
      }
    }
  }

  // Guardo el filtro de zonas visibles
  public async saveFilter() {
    let zonas: any[] = [];
    // Si se trata del modo de "sólo polígonos" guardamos todas las zonas de otros
    // tipos que esten seleccionadas en el filtro
    if (this.modoManual) {
      this.zonas.forEach(zona => {
        if (zona.Tipo !== TiposMaster.MANUAL && this.select.find(s => s.id === zona.Id) !== undefined) {
          zonas.push({ id: zona.Id });
        }
      });
    }
    let rowsSelec: any = this.gridZonas.getselectedrowindexes();
    // obtengo las filas del grid que tienen la propiedad textoVisible a true
    setTimeout(async () => {
      let rowsConTexto = await this.getCheckConTexto();
      if (rowsSelec.length !== 0) {

        rowsSelec.forEach(i => {
          zonas.push({ id: this.zonas[i].Id });
        });

        rowsConTexto.forEach(row => {
          if (zonas.find(zona => zona.id == row.Id) !== undefined) {
            return zonas.find(zona => zona.id == row.Id).TextoVisible = row.TextoVisible;
          }
        });
      }

      this.zonesService.loadZones();
      this.zonesService.setFilterVisible();
    }, 200);
  }

  onBorrarZona() {
    if (this.zonaSelec.Id) {
      let zonasToDelete = [];

      if (this.zonaSelec) {
        zonasToDelete.push(this.zonaSelec);
      }

      // si hay varias zonas seleccionadas, se borran las zonas seleccionadas
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quieres_borrar_zonas') + ':' + zonasToDelete.map(zona => zona.Nombre).join(', ') + ' ?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzZIndex: 999,
        nzOnOk: async () => {
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
          //creamos una lista de IDs que queremos eliminar.
          const idsParaEliminar = zonasToDelete.map(zona => zona.Id);
          // filtramos la lista de zonas para quitar las zonas que se van a eliminar.
          this.zonas = this.zonas.filter(z => !idsParaEliminar.includes(z.Id));
          await Promise.all(zonasToDelete.map(zona => {
            return this.zonesService.deleteZona(zona.Id);
          }));

          this.deleteZonesMap();
          this.deleteZonesMapEdit();
          this.checkSelect = [];
          this.zonaSelec = new ZonaModel();
          this.dataSource.localdata = this.zonas;
          this.gridZonas.updatebounddata();
          this.gridZonas.unselectrow(this.gridZonas.getselectedrowindex());
        }
      });
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  // Centrar en una zona
  onVer() {
    this.onVerZonas = true;
    this.deleteZonesMap();
    if (this.verDesdeColumn) {
      let zonaArray = [];
      zonaArray[0] = this.zonaSelec;
      this.encuadrarZonasSelec(zonaArray, this.map);
      if (this.zonaSelec) {
        this.zonaEdit = this.zonaSelec;
        if (this.zonaSelec.Tipo == TiposMaster.ZONAADMIN) {
          this.drawPolygon(this.zonaSelec.Geometria);
        } else {
          this.drawZone();
        }
      }
      this.verDesdeColumn = false;
    } else {
      if (this.checkSelect.length > 0) {
        this.encuadrarZonasSelec(this.checkSelect, this.map);
        // pinto las zonas seleccionadas
        this.checkSelect.forEach(zona => {
          if (zona.Tipo == TiposMaster.ZONAADMIN) {
            this.drawPolygon(zona.Geometria);
          } else {
            this.zonaEdit = zona;
            this.drawZone();
          }
        });

        //this.saveFilter();
      } else {
        return MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      }
    }
    this.form.collapse();
    this.onVerZonas = false;
    this.zonaEdit = new ZonaModel();
  }

  async onFijarZonas() {
    this.select = [];
    if (this.checkSelect.length > 0) {
      this.checkSelect.forEach(zona => {
        this.zonasFijas.push({ id: zona.Id });
      });

      this.zonasFijas = this.zonasFijas.filter((zona, index, self) =>
        index === self.findIndex((t) => (
          t.id === zona.id
        ))
      );

      await this.configService.setUsuEmpApp('zones-fixed', JSON.stringify(this.zonasFijas));
      this.fijandoZonas = true;
      this.gridZonas.refresh();
      this.gridZonas.refreshaggregates();

    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  encuadrarZonasSelec(zonas, map) {
    let globalSWPoint = new MapLatLng(180, 90);
    let globalNEPoint = new MapLatLng(-180, -90);

    zonas.forEach(zona => {
      let swPoint, nePoint;

      if (zona.TipoGeo === 0) { // Si es un círculo
        let center = new MapLatLng(zona.Lat, zona.Lng);
        swPoint = GeoUtils.getNewLatLng(center, -zona.Radio, -zona.Radio);
        nePoint = GeoUtils.getNewLatLng(center, zona.Radio, zona.Radio);
      } else { // Si es un polígono
        let bounds = this.calculatePolygonBounds(zona.Geometria); // Obtener límites
        swPoint = bounds.swPoint;
        nePoint = bounds.nePoint;
      }

      globalSWPoint.lat = Math.min(globalSWPoint.lat, swPoint.lat);
      globalSWPoint.lng = Math.min(globalSWPoint.lng, swPoint.lng);
      globalNEPoint.lat = Math.max(globalNEPoint.lat, nePoint.lat);
      globalNEPoint.lng = Math.max(globalNEPoint.lng, nePoint.lng);
    });

    map.fitTo(new MapBounds(globalSWPoint, globalNEPoint));
  }

  calculatePolygonBounds(geoJson) {
    let arrLat = [];
    let arrLng = [];
    let arrayCoordenadas = geoJson.geometry.coordinates;
    arrayCoordenadas.forEach(polygon => {
      polygon[0].forEach(coord => {
        arrLat.push(coord[1]);
        arrLng.push(coord[0]);
      });
    });
    let swPoint = new MapLatLng(Math.min(...arrLat), Math.min(...arrLng));
    let nePoint = new MapLatLng(Math.max(...arrLat), Math.max(...arrLng));
    return { swPoint, nePoint };
  }

  onCrearZona() {
    if (!this.canEdit) {
      return;
    }
    this.viewGridEntradasSalidas = false;
    this.editEntradas = false;
    this.creandoZonaMasEntradas = true;
    this.openEditZonaForm();
    this.disabledBotton(false);
  }

  onEditarZona() {
    if (!this.canEdit) {
      return;
    }
    if (this.zonaSelec?.Id) {
      this.viewGridEntradasSalidas = true;
      this.editEntradas = true;
      // Creo una copia para preservar el contenido original
      delete this.zonaSelec.nombre;
      delete this.zonaSelec.lat;
      delete this.zonaSelec.lng;
      delete this.zonaSelec.id;

      delete this.zonaEdit.nombre;
      delete this.zonaEdit.lat;
      delete this.zonaEdit.lng;
      delete this.zonaEdit.id;

      this.zonaEdit = Utils.clone(this.zonaSelec);
      this.zonaEdit.Radio = Number(this.zonaEdit.Radio);
      this.openEditZonaForm();
      this.disabledBotton(true);
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onOpenEditZona() {
    setTimeout(async () => {
      this.addCustomForm(this.formEditZona);
      this.populateTipoMasterCombo();
      if (!this.hideBdtCombo) {
        this.addEdificiosToCombo();
      }
      if (this.modoManual) {
        this.setModoManual();
      }
      await this.populateTiposZonasCombo();
      this.populateTiposGeoCombo();

      if (this.zonaEdit.Id > 0) {
        this.cbTiposGeo.disabled(true);
      }

      this.selectDefaultComboValues();
      //this.tabZonasEdit.select(0);
    }, 0);
  }

  populateTipoMasterCombo() {
    this.cbTiposMaster.addItem({ label: this.translate('Zonas_administrativas'), value: TiposMaster.ZONAADMIN });
    this.cbTiposMaster.addItem({ label: this.translate('Manual'), value: TiposMaster.MANUAL });
    this.cbTiposMaster.addItem({ label: this.translate('Asociada_elemento'), value: TiposMaster.ELEMENTO });
  }

  addEdificiosToCombo() {
    this.cbTiposMaster.addItem({ label: this.translate('Edificios_instalaciones'), value: TiposMaster.EDIFICIOS });
  }

  setModoManual() {
    this.hiddenTipoMaster = true;
    this.cbTiposMaster.selectIndex(1);
  }

  async populateTiposZonasCombo() {
    this.tipos = await this.bdtService.getRecursos(BDT_RECURSOS.Edificios);
    this.tipos.sort((a, b) => a.Nombre.localeCompare(b.Nombre));

    this.cbTiposZonas.clear();
    this.tipos.forEach((tipo, i) => {
      this.cbTiposZonas.addItem({ label: tipo.Nombre, value: tipo.Id });
      if (this.zonaEdit.Id > 0 && this.zonaEdit.TipoBDT === tipo.Id) {
        this.cbTiposZonas.selectIndex(i);
      }
    });

    if (this.zonaEdit.Id < 1) {
      this.cbTiposZonas.selectIndex(0);
    }
  }

  populateTiposGeoCombo() {
    this.cbTiposGeo.clear();
    this.cbTiposGeo.addItem({ label: this.translate('Circulo'), value: 0 });
    this.cbTiposGeo.addItem({ label: this.translate('Poligono'), value: 1 });
    this.cbTiposGeo.selectIndex(this.zonaEdit.TipoGeo);
  }

  selectDefaultComboValues() {
    if (this.zonaEdit.Tipo !== 0) {
      this.cbTiposMaster.selectIndex(this.zonaEdit.Tipo - 1);
      this.cbTiposMaster.disableItem(3);
    } else {
      this.cbTiposMaster.selectIndex(0);
    }
  }

  onKey(nombre: any) {
    if (nombre.target.value.length > 0) {
      //EntradaSalidaComponent._this.disabledBotton(false);
      this.disabledBotton(true);
    } else {
      //EntradaSalidaComponent._this.disabledBotton(true);
      this.disabledBotton(false);
    }
  }

  disabledBotton(value: boolean) {
    setTimeout(() => {
      if (this.zonaSelec?.Tipo === 3) {
        this.tabZonasEdit.disableAt(1);
      }
      this.activarDesactivarTab(value);
    }, 200);
  }

  activarDesactivarTab(value: boolean) {
    if (value) {
      this.tabZonasEdit.enableAt(1);
    } else {
      this.tabZonasEdit.disableAt(1);
    }
  }

  ngOnDestroy() {
    // Cancelar todas las suscripciones
    this.subscription?.unsubscribe();
  }

  deleteElementsMaps() {
    if (!this.creandoZonaMasEntradas) {
      EntradaSalidaComponent._this.removeMarker();
    }
    this.removeMarker(this.zonaEdit.Id);
    this.deleteZonesMap();
    this.deleteZonesMapEdit();
  }

  unsubscribeMapEvents() {
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
      this.subscriptionOnMapClick = null;
    }
    if (this.subscriptionOnRadiusChange) {
      this.subscriptionOnRadiusChange.unsubscribe();
      this.subscriptionOnRadiusChange = null;
    }

    if (this.subscriptionOnPolygonDragEnd) {
      this.subscriptionOnPolygonDragEnd.unsubscribe();
      this.subscriptionOnPolygonDragEnd = null;
    }

    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
    }
    if (this.subscriptionOnMarkerClick) {
      this.subscriptionOnMarkerClick.unsubscribe();
    }

    EntradaSalidaComponent._this.unsubscribeMapEvents();
  }

  async onCloseEditZona() {
    this.map.setMousePointer('');
    this.unsubscribeMapEvents();
    this.deleteElementsMaps();
    this.removeMarker(this.zonaEdit.Id);
    this.mostrarEditZona = false;
    this.zonas = await this.zonesService.getZonas();
    this.form.enable();
    this.dataSource.localdata = this.zonas;
    this.gridZonas.updatebounddata('data');
    this.form.expand();
    if (this.editAlarm) {
      this.zonesService.onSendZona(this.zonaEdit);
      this.editAlarm.expand();
    }
  }

  // Muestra el formulario de edición de zonas
  openEditZonaForm() {
    this.superficie = NumberUtils.format(this.zonaEdit.Area, 0);
    this.mostrarEditZona = true;
    this.areas.clear();
    if (this.form) {
      this.form.collapse();
      this.form.disable();
    }
    this.polygonPoints = [];
    // Si se está modificando una zona la pinto en el mapa
    setTimeout(() => {
      if (this.zonaEdit.Id > 0) {
        this.titleEditWindow = this.translate('Edicion_zonas_cartograficas') + ' ' + '<b>' + this.zonaEdit.Nombre + '</b>';
        this.formEditZona.setTitle(this.titleEditWindow);
        this.zonesService.hideZone(this.zonaEdit.Id);

        //comprobar si es zona administrativa
        if (this.zonaEdit.Tipo == TiposMaster.ZONAADMIN) {
          this.drawPolygon(this.zonaEdit.Geometria);
        } else {
          this.drawZone();
          this.drawZoneMapEdit();
        }
        this.zonaEdit.Entradas.forEach(entrada => {
          EntradaSalidaComponent._this.paintMarkers(entrada, new MapLatLng(entrada.Lat, entrada.Lng), this.mapZonaEdit);
        });
      } else {
        this.titleEditWindow = this.translate('Edicion_zonas_cartograficas');
        this.formEditZona.setTitle(this.titleEditWindow);
      }

      this.tabZonasEdit.setTitleAt(0, this.translate('Informacion'));
      this.tabZonasEdit.setTitleAt(1, this.translate('Entradas_salidas') + ' (' + this.zonaEdit.Entradas.length + ')');

      // Me subscribo a los cambios de radio en los círculos
      if (this.subscriptionOnRadiusChange) {
        this.subscriptionOnRadiusChange.unsubscribe();
      }
      this.subscriptionOnRadiusChange = this.map.subscribeOnCircleRadiusChange(this, this.onChangeRadiusOnMap);
      // Me subscribo a los cambios en los polígonos
      if (this.subscriptionOnPolygonDragEnd) {
        this.subscriptionOnPolygonDragEnd.unsubscribe();
      }
      this.subscriptionOnPolygonDragEnd = this.map.subscribeOnPolygonDragEnd(this, this.onChangePolygonOnMap);
    }, 1000);
  }

  async onExpandEditZonas(event: any) {
    // actualizo el titulo del tab de entradas y salidas
    if (this.zonaEdit.Id > 0) {
      this.zonaEdit.Entradas = await this.zonesService.getEntradas(this.zonaEdit.Id);
      this.tabZonasEdit.setTitleAt(1, this.translate('Entradas_salidas') + ' (' + this.zonaEdit.Entradas.length + ')');
      this.formEditZona.setTitle(this.translate('Edicion_zonas_cartograficas') + ' ' + '<b>' + this.zonaEdit.Nombre + '</b>');
      this.disabledBotton(false);
      this.removeMarker(this.zonaEdit.Id);
      this.zonaEdit.Entradas.forEach(entrada => {
        EntradaSalidaComponent._this.paintMarkers(entrada, new MapLatLng(entrada.Lat, entrada.Lng), this.mapZonaEdit);
      });
    }

    if (this.zonaEdit.Id < 1 && this.circle || this.polygon || this.polygonGeoJson && this.zonaEdit.Nombre !== '') {
      this.disabledBotton(true);
    }

    setTimeout(() => {
      this.drawZoneMapEdit();
    }, 200);
  }

  // Cuando se cambia el tipo de zona
  onChangeTipoZona(event: any) {
    this.zonaEdit.TipoBDT = event.args.item.value;
  }

  //Cuando se cambia el tipo de creación de zona
  onChangeTipoMaster(event) {
    this.zonaEdit.Tipo = event.args.item.value;
    this.selectTipoMaster = event.args.item.value;
    if (this.zonaEdit.Id < 1) {
      this.resetParams();
    }

    switch (event.args.item.value) {
      //ocultamos opciones para seleccion "zonas admin"
      case TiposMaster.ZONAADMIN:
        this.hiddenTipoZonas = true;
        this.hiddenGeometria = true;
        this.hiddenInputSearch = false;
        this.hiddenELementContainer = true;
        this.hiddenRadius = true;
        this.hiddenDrawButton = true;
        this.zonaEdit.TipoGeo = 1; // Polígono
        this.zonaEdit.TipoBDT = 0;
        //this.disabledBotton(true);
        break;
      //ocultamos opciones para seleccion "manual"
      case TiposMaster.MANUAL:
        this.hiddenTipoZonas = true;
        this.hiddenGeometria = false;
        this.hiddenInputSearch = true;
        this.hiddenELementContainer = true;
        this.hiddenRadius = false;
        this.hiddenDrawButton = false;
        this.disabledBotton(true);
        this.zonaEdit.TipoBDT = 0;
        if (this.zonaEdit.Id < 1) {
          this.zonaEdit.TipoGeo = 0; // Círculo
          this.cbTiposGeo.selectIndex(0)
        } else {
          if (this.zonaEdit.TipoGeo === 0) {
            this.cbTiposGeo.selectIndex(0)
          } else {
            this.cbTiposGeo.selectIndex(1)
          }
        }
        break;
      //ocultamos opciones para seleccion "Asociada a elem"
      case TiposMaster.ELEMENTO:
        this.hiddenTipoZonas = true;
        this.hiddenGeometria = true;
        this.hiddenInputSearch = true;
        this.hiddenELementContainer = false;
        this.hiddenRadius = true;
        this.hiddenDrawButton = true;
        this.subscriptionOnMarkerClick = this.map.subscribeOnMarkerClick(this, this.onMarkerClick);
        this.zonaEdit.TipoGeo = 0; // Círculo
        this.zonaEdit.TipoBDT = 0;
        this.disabledBotton(false);
        break;
      //ocultamos opciones para seleccion "edificios e instal"
      case TiposMaster.EDIFICIOS:
        if (this.zonaEdit.Id > 1 && this.zonaEdit.TipoBDT > 0) {
          this.zonaEdit.TipoBDT = this.cbTiposZonas.getSelectedItem().value;
        } else {
          this.zonaEdit.TipoBDT = this.cbTiposZonas.getItem(0).value;
          this.cbTiposZonas.selectIndex(0);
        }
        this.hiddenTipoZonas = false;
        this.hiddenGeometria = false;
        this.hiddenInputSearch = true;
        this.hiddenELementContainer = true;
        this.hiddenRadius = false;
        this.hiddenDrawButton = false;
        this.disabledBotton(true);
        if (this.zonaEdit.Id < 1) {
          this.zonaEdit.TipoGeo = 0; // Círculo
          this.cbTiposGeo.selectIndex(0)
        } else {
          if (this.zonaEdit.TipoGeo === 0) {
            this.cbTiposGeo.selectIndex(0)
          } else {
            this.cbTiposGeo.selectIndex(1)
          }
        }
        break;
    }
  }

  myControl = new FormControl('');

  filtrarAreas(val: string) {
    if (val && this.areas.size > 0) {
      return [...this.areas.values()].filter(option =>
        option.toLowerCase().includes(val.toLowerCase())
      );
    } else {
      return [...this.areas.values()];
    }
  }

  async getAdminAreas(searchString: string = '') {
    try {
      let adminAreas = await this.cartoService.searchAdminAreas(searchString);
      adminAreas.forEach(area => {
        this.areas.set(area.id, `${area.name}, ${area.prov_name}`);
      });
    } catch (error) {
      console.error(error);
    }
  }

  async onChangeInput(event) {
    let input = event.target.value;
    this.areas.clear();
    this.zonaEdit.Nombre = null;
    //borrar polígono del mapa
    if (this.polygonGeoJson) {
      this.map.removeGeoJson(this.polygonGeoJson);
      this.mapZonaEdit.removePolygon(this.polygonGeoJsonMapEdit);
    }
    //si key está indefinido, significa que se ha seleccionado un elemento de la lista
    if (event.data && input != '') {
      clearTimeout(this.timer);
      await this.getPromiseTimeOut();
      this.getAdminAreas(input);
      setTimeout(() => {
        this.filtrarAreas(input);
      }, 200);
    }
  }

  seleccionarArea(event: MatAutocompleteSelectedEvent) {
    let areaClick = event.option?.value;
    this.handleAreaSelection(areaClick);
  }

  onOptionActivated(event: MatAutocompleteActivatedEvent) {
    let area = event.option?.value;
    this.handleAreaSelection(area);
  }

  private async handleAreaSelection(areaValue: any) {
    if (areaValue) {
      const id = this.getKey(areaValue);
      //EntradaSalidaComponent._this.disabledBotton(false);
      this.disabledBotton(true);
      this.areaDetail = await this.cartoService.getAreaDetail(id);
      this.drawPolygon(this.areaDetail);
      this.zonaEdit.Area = Math.round(Number(this.areaDetail.properties.surface));
      this.superficie = NumberUtils.format(this.zonaEdit.Area, 0);
      this.zonaEdit.Nombre = this.areaDetail.properties.name;
    }
  }

  async getPromiseTimeOut() {
    return new Promise(resolve => {
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        resolve(1);
      }, 500);
    });
  }

  //buscar key a partir de un value
  getKey(value) {
    return [...this.areas].find(([key, val]) => val == value)[0];
  }

  drawPolygon(area: AreaDetail) {
    if (this.polygon) {
      this.map.removePolygon(this.polygon);
      if (this.mapZonaEdit) {
        this.mapZonaEdit.removePolygons();

      }
    }
    if (this.polygonGeoJson) {
      this.map.removeGeoJson(this.polygonGeoJson);
      this.mapZonaEdit.removeGeoJson(this.polygonGeoJsonMapEdit);
      this.polygonGeoJson = null;
    }

    this.polygonGeoJson = this.map.addGeoJson({
      geometry: area.geometry,
      type: 'Feature'
    }, {
      dataModel: this.zonaEdit,
      content: this.conTexto ? this.zonaEdit.Nombre : '',
      strokeColor: '#ff0000',
      strokeOpacity: 0.3,
      strokeWeight: 1,
      fillColor: '#0000ff',
      fillOpacity: 0.1,
      editable: this.onVerZonas ? false : true
    });
    if (!this.onVerZonas) {
      this.polygonGeoJsonMapEdit = this.mapZonaEdit.addGeoJson({
        geometry: area.geometry,
        type: 'Feature'
      }, {
        dataModel: this.zonaEdit,
        content: this.conTexto ? this.zonaEdit.Nombre : '',
        strokeColor: '#ff0000',
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: '#0000ff',
        fillOpacity: 0.1,
        editable: this.onVerZonas ? false : true
      });
    }

    if (this.zonaEdit) {
      this.zonaEdit.TipoGeo = 1;
      this.zonaEdit.Geometria = area;
    }
    this.centeredPolygons(area);
  }

  centeredPolygons(geoJson) {
    let arrLat = [];
    let arrLng = [];
    let arrayCoordenadas = geoJson.geometry.coordinates;
    arrayCoordenadas.forEach(polygon => {
      polygon[0].forEach(coord => {
        arrLat.push(coord[1]);
        arrLng.push(coord[0]);
      });
    });
    //calcular máximos y minimos para centar ppolígono en el mapa
    let arrLatMax = Math.max(...arrLat);
    let arrLatMin = Math.min(...arrLat);
    let arrLngmax = Math.max(...arrLng);
    let arrLngMin = Math.min(...arrLng);
    let coordMax = new MapLatLng(arrLatMax, arrLngmax);
    let coordMin = new MapLatLng(arrLatMin, arrLngMin);
    let mapBounds = new MapBounds(coordMin, coordMax);
    //funcion que centra el mapa a un area
    if (!this.onVerZonas) {
      this.mapZonaEdit.fitTo(mapBounds);
    }

    this.map.fitTo(mapBounds);
  }

  //al cambiar de selección,reiniciar datos que hayan podido cambiar
  resetParams() {
    this.map.setMousePointer('');
    this.superficie = 0;
    this.stringSearch = '';
    this.infoElement = null;
    this.infoMarker = null;
    this.infoNombre = null;
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
    }
    if (this.subscriptionOnMarkerClick) {
      this.subscriptionOnMarkerClick.unsubscribe();
    }
    if (this.circle) {
      this.map.removeCircle(this.circle);
      this.mapZonaEdit.removeCircles();
      this.circle = null;
    }
    if (this.polygon) {
      this.map.removePolygon(this.polygon);
      this.mapZonaEdit.removePolygons();
    }
    if (this.polygonGeoJson) {
      this.map.removeGeoJson(this.polygonGeoJson);
      this.mapZonaEdit.removeGeoJson(this.polygonGeoJson);
    }
  }

  //ordenar la lista de resulados de búsqueda en el orden original
  public keepOriginalOrder(a, b) {
    return a.key;
  }

  // Cuando se cambia el tipo de geometría
  onChangeTipoGeo(event: any) {
    this.zonaEdit.TipoGeo = event.args.item.value;
    if (this.zonaEdit.Id < 1) {
      if (this.circle) {
        this.map.removeCircle(this.circle);
        this.mapZonaEdit.removeCircles();
        this.circle = null;
      }
      if (this.polygon) {
        this.map.removePolygon(this.polygon);
        this.mapZonaEdit.removePolygons();
        this.polygon = null;
      }
    }
  }

  // Cuando se modifica el radio del círculo sobre la cartografía
  onChangeRadiusOnMap(_this: ZonesComponent, circle: MapCircle) {
    _this.zonaEdit.Radio = circle.radius;
  }

  // Cuando se modifica el el polígono sobre la cartografía
  onChangePolygonOnMap(_this: ZonesComponent, point: MapPolygonPoint) {
    _this.modificandoPolygon = true;
    _this.calcArea();
    _this.superficie = NumberUtils.format(_this.zonaEdit.Area, 0);
  }

  // Cuando se modifica el radio del círculo en el formulario
  onChangeRadius() {
    if (this.circle) {
      this.circle.setRadius(Number.parseInt('' + this.zonaEdit.Radio));
    }
  }

  // Cuando se pulsa el botón para dibujar una zona nueva o para modificarla
  onDibujarZona(event: any) {
    if (!this.canEdit) {
      return;
    }
    this.zonaEdit.Radio = Number.parseInt('' + this.zonaEdit.Radio);
    if (this.zonaEdit.TipoGeo === 0 && this.zonaEdit.Radio < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_radio', 2000);
      return;
    }
    if (this.zonaEdit.TipoGeo === 0) {
      MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_punto_cartografia', 2000);
    } else {
      MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_puntos_poligono_cartografia', 2000);
    }

    /*
      Subscripcion para que el usuario pueda arrastra el circulo
      y se actualicen las coordenadas
    */
    this.subscriptionOnCircleDragend = this.map.subscribeOnCircleDragEnd(this, (_this: ZonesComponent, circle: MapCircle) => {
      if (circle.id === this.circle.id) {
        this.zonaEdit.Lat = circle.center.lat;
        this.zonaEdit.Lng = circle.center.lng;
      }
    });

    // Me subscribo al evento click sobre el mapa sólo si es una zona nueva
    if (this.zonaEdit.Id < 1) {
      // Cambio el puntero del ratón sobre el mapa
      // this.map.setMousePointer('assets/images/center.png');
      if (this.subscriptionOnMapClick) {
        this.subscriptionOnMapClick.unsubscribe();
      }
      this.subscriptionOnMapClick = this.map.subscribeOnMapClick(this, this.onMapClick);
    } else {
      // Dibujo las zonas sobre el mapa
      this.zonesService.hideZone(this.zonaEdit.Id);
      this.drawZone();
    }
    if (this.circle) {
      this.circle.setRadius(this.zonaEdit.Radio);
    }
    this.formEditZona.collapse();
    // if (this.map.zoom < 15) {
    //   this.map.setZoom(15);
    // }
  }

  drawZoneMapEdit() {
    // Pinto sobre el mapa la zona
    this.deleteZonesMapEdit();

    if (this.zonaEdit.TipoGeo === 0) { // Círculo
      if (this.zonaEdit.Lat > 0) {
        this.zonaEdit.Geometria = {};
        let circle = this.mapZonaEdit.addCircle({
          dataModel: this.zonaEdit,
          content: this.conTexto ? this.zonaEdit.Nombre : '',
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          position: new MapLatLng(this.zonaEdit.Lat, this.zonaEdit.Lng),
          radius: Number(this.zonaEdit.Radio),
          draggable: false,
          editable: false
        });
        this.circlesMapEdit.push(circle);
        this.mapZonaEdit.setCenter(new MapLatLng(this.zonaEdit.Lat, this.zonaEdit.Lng));
        let zonaArray = [];
        zonaArray[0] = this.zonaEdit;
        this.encuadrarZonasSelec(zonaArray, this.mapZonaEdit);
      }
    } else { // Polígono
      this.superficie = NumberUtils.format(this.zonaEdit.Area, 0);
      //cargar polígono en el mapa a partir de un geojson
      let polygon
      if (this.zonaEdit.Geometria && !this.modificandoPolygon) {

        polygon = this.mapZonaEdit.addPolygonsFromGeoJson(this.zonaEdit.Geometria, false, {
          dataModel: this.zonaEdit,
          content: this.conTexto ? this.zonaEdit.Nombre : '',
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          zIndex: 100,
        })[0];
        this.centeredPolygons(this.zonaEdit.Geometria);
      } else {
        // pinto el polígono en el mapa
        polygon = this.mapZonaEdit.addPolygon({
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          content: this.zonaEdit.Nombre,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          editable: false,
          zIndex: 100,
        });

        this.polygon.points.forEach(punto => {
          this.mapZonaEdit.addPolygonPoint(polygon, {
            content: this.zonaEdit.Nombre,
            position: punto.point
          });
          this.polygonPoints.push(punto.point);
        });

        // centro el polígono en el mapa
        this.centerPolygon(this.polygonPoints);
        this.modificandoPolygon = false;
      }

      this.polygonsMapEdit.push(polygon);
    }
    if (!this.onVerZonas) {
      this.activarDesactivarTab(true);
    }
  }

  centerPolygon(polygon) {
    let arrLat = [];
    let arrLng = [];
    polygon.forEach(coord => {
      arrLat.push(coord.lat);
      arrLng.push(coord.lng);
    });
    let swPoint = new MapLatLng(Math.min(...arrLat), Math.min(...arrLng));
    let nePoint = new MapLatLng(Math.max(...arrLat), Math.max(...arrLng));
    let bounds = new MapBounds(swPoint, nePoint);
    this.mapZonaEdit.fitTo(bounds);
  }

  drawZone() {
    // Pinto sobre el mapa la zona
    if (!this.onVerZonas) {
      this.deleteZonesMap();
    }

    if (this.zonaEdit.TipoGeo === 0) { // Círculo
      if (this.zonaEdit.Lat > 0) {
        this.zonaEdit.Geometria = {};
        this.circle = this.map.addCircle({
          dataModel: this.zonaEdit,
          content: this.conTexto ? this.zonaEdit.Nombre : '',
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          position: new MapLatLng(this.zonaEdit.Lat, this.zonaEdit.Lng),
          radius: Number(this.zonaEdit.Radio),
          draggable: this.onVerZonas ? false : true,
          editable: this.onVerZonas ? false : true
        });
        this.circlesMap.push(this.circle);

        if (!this.onVerZonas) {
          this.map.setCenter(new MapLatLng(this.zonaEdit.Lat, this.zonaEdit.Lng));
          this.encuadrarZonasSelec([this.zonaEdit], this.map);
        }
      }
    } else { // Polígono
      this.superficie = NumberUtils.format(this.zonaEdit.Area, 0);
      let editable = this.onVerZonas ? false : true;
      //cargar polígono en el mapa a partir de un geojson
      this.polygon = this.map.addPolygonsFromGeoJson(this.zonaEdit.Geometria, editable, {
        dataModel: this.zonaEdit,
        content: this.conTexto ? this.zonaEdit.Nombre : '',
        strokeColor: '#ff0000',
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: '#0000ff',
        fillOpacity: 0.1,
        zIndex: 100,
      })[0];
      if (!this.onVerZonas) {
        this.centeredPolygons(this.zonaEdit.Geometria);
      }
      this.polygonsMap.push(this.polygon);
    }
    if (!this.onVerZonas) {
      this.activarDesactivarTab(true);
    }
  }

  deleteZonesMap() {
    const removeMapElement = (element, removeFunction) => {
      if (element) {
        removeFunction(element);
      }
    };

    // Crear una copia de circlesMap y iterar sobre la copia
    const circlesCopy = this.circlesMap.slice();
    circlesCopy.forEach(circle => {
      removeMapElement(circle, elem => this.map.removeCircle(elem));
    });
    this.circlesMap = [];  // Limpia el arreglo original después de remover los elementos

    // Similar para polygonsMap
    const polygonsCopy = this.polygonsMap.slice();
    polygonsCopy.forEach(polygon => {
      removeMapElement(polygon, elem => this.map.removePolygon(elem));
    });
    this.polygonsMap = [];  // Limpia el arreglo original después de remover los elementos

    removeMapElement(this.polygonGeoJson, elem => this.map.removeGeoJson(elem));
  }

  deleteZonesMapEdit() {
    const removeMapElement = (element: any, removeFunction: (element: any) => void) => {
      if (element) {
        removeFunction(element);
      }
    };
    if (this.circlesMapEdit.length) {
      this.circlesMapEdit.forEach(circle => {
        removeMapElement(circle, (elem) => this.mapZonaEdit.removeCircle(elem));
        this.circlesMapEdit = this.circlesMapEdit.filter(c => c !== circle);
      });
    }

    if (this.polygonsMapEdit.length) {
      this.polygonsMapEdit.forEach(polygon => {
        removeMapElement(polygon, (elem) => this.mapZonaEdit.removePolygon(elem));
        this.polygonsMapEdit = this.polygonsMapEdit.filter(p => p !== polygon);
      });
    }

    removeMapElement(this.polygonGeoJsonMapEdit, (elem) => this.mapZonaEdit.removeGeoJson(elem));
  }

  // Cuando se pincha sobre el mapa, este método sólo se llama cuando es una zona nueva
  async onMapClick(_this: ZonesComponent, position: MapLatLng) {
    // Calculo la posición que corresponde con el centro del icono que he puesto como puntero del ratón en ambos mapas
    const pointMap = _this.map.latLngToScreenPoint(position);
    const newPositionMap = _this.map.screenPointToLatLng(pointMap);

    const pointMapEditZone = _this.mapZonaEdit.latLngToScreenPoint(position);
    const newPositionMapEditZone = _this.mapZonaEdit.screenPointToLatLng(pointMapEditZone);

    switch (_this.zonaEdit.TipoGeo) {
      case 0: // Círculo
        _this.zonaEdit.Radio = Number.parseInt('' + _this.zonaEdit.Radio);
        _this.zonaEdit.Lat = newPositionMap.lat;
        _this.zonaEdit.Lng = newPositionMap.lng;

        _this.deleteZonesMap();
        _this.deleteZonesMapEdit();

        _this.circle = _this.map.addCircle({
          id: 0,
          dataModel: _this.zonaEdit,
          content: _this.zonaEdit.Nombre,
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          position: new MapLatLng(newPositionMap.lat, newPositionMap.lng),
          radius: _this.zonaEdit.Radio,
          draggable: true,
          editable: true
        });

        _this.circlesMap.push(_this.circle);

        // Agregar círculo también en mapEditZone
        const circleEdit = _this.mapZonaEdit.addCircle({
          id: 0,
          dataModel: _this.zonaEdit,
          content: _this.zonaEdit.Nombre,
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          position: new MapLatLng(newPositionMapEditZone.lat, newPositionMapEditZone.lng),
          radius: _this.zonaEdit.Radio,
          draggable: false,
          editable: false
        });
        _this.circlesMapEdit.push(circleEdit);
        break;
      case 1: // Polígono
        if (!_this.polygon) {
          _this.polygon = _this.map.addPolygon({
            dataModel: _this.zonaEdit,
            content: _this.zonaEdit.Nombre,
            position: new MapLatLng(newPositionMap.lat, newPositionMap.lng),
            strokeColor: '#ff0000',
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: '#0000ff',
            fillOpacity: 0.1,
            editable: true,
            zIndex: 100,
          });

          _this.polygonsMap.push(_this.polygon);
        }

        _this.map.addPolygonPoint(_this.polygon, {
          position: new MapLatLng(newPositionMap.lat, newPositionMap.lng)
        });
        _this.polygonPoints.push(new MapLatLng(newPositionMap.lat, newPositionMap.lng));
        _this.calcArea();
        _this.superficie = NumberUtils.format(_this.zonaEdit.Area, 0);
        break;
    }
  }

  onMarkerClick(_this: any, marker: MapMarker) {
    // Compruebo que se trata de un elemento
    if (marker.dataModel.hasOwnProperty('RequiereVolum')) {
      _this.zonaEdit.ElementoId = marker.dataModel.Id;
      _this.infoMarker = marker.icon;
      _this.infoElement = marker.dataModel.Nombre;
      _this.infoNombre = marker.dataModel.Equipamiento.Elemento.Nombre;
      _this.zonaEdit.Radio = marker.dataModel.Equipamiento.AreaInfluencia;
      _this.zonaEdit.Nombre = marker.dataModel.Nombre;
      if (_this.circle) {
        _this.map.removeCircle(_this.circle);
      }
      _this.circle = _this.map.addCircle({
        dataModel: _this.zonaEdit,
        content: _this.zonaEdit.Nombre,
        strokeColor: '#ff0000',
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: '#0000ff',
        fillOpacity: 0.1,
        position: new MapLatLng(marker.position.lat, marker.position.lng),
        radius: marker.dataModel.Equipamiento.AreaInfluencia,
        draggable: false,
        editable: false
      });
    }
  }

  async onGuardarZona(): Promise<void> {
    this.showLoader = true;
    if (!this.canEdit) {
      return;
    }

    // elimino la propiedad nombre del modelo, lt y lng, id
    delete this.zonaEdit.nombre;
    delete this.zonaEdit.lat;
    delete this.zonaEdit.lng;
    delete this.zonaEdit.id;



    if (this.zonaEdit.Nombre === '') {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return;
    }
    if (this.zonaEdit.TipoGeo === 0) {
      if (!this.circle) {
        MainComponent.getInstance().showError('ATENCION', 'Seleccione_punto_cartografia', 2000);
        return;
      }
      this.zonaEdit.Lat = this.circle.center.lat;
      this.zonaEdit.Lng = this.circle.center.lng;
      this.zonaEdit.Puntos = null;
      this.zonaEdit.Area = 0;

      if (this.zonaEdit.Lat === 0 && this.zonaEdit.Lng === 0) {
        MainComponent.getInstance().showError('ATENCION', 'Seleccione_punto_cartografia', 2000);
        return;
      }
      if (this.zonaEdit.Radio < 1) {
        MainComponent.getInstance().showError('ATENCION', 'Introduzca_radio', 2000);
        return;
      }
      this.zonaEdit.Geometria = null;
    } else {
      this.zonaEdit.Radio = 0;
      this.zonaEdit.Lat = 0;
      this.zonaEdit.Lng = 0;
      if (this.selectTipoMaster !== TiposMaster.ZONAADMIN) {
        if (!this.polygon) {
          MainComponent.getInstance().showError('ATENCION', 'Defina_poligono', 2000);
          return;
        }
        this.zonaEdit.Geometria = this.polygon2Geojson([this.polygon]);
        this.calcArea();
      } else {
        if (!this.zonaEdit.Geometria) {
          MainComponent.getInstance().showError('ATENCION', 'Defina_poligono', 2000);
          return;
        }
      }
    }
    //this.deleteZones();

    return await this.saveZona();
  }

  //Crear multipolígono geojson a partir de polígonos
  polygon2Geojson(polygons: MapPolygon[]) {
    let geometry = { 'type': 'MultiPolygon', 'coordinates': [] };
    let geojson = { 'geometry': geometry };
    polygons.forEach(polygon => {
      let arrPoints = [];
      polygon.points.forEach(polygonPoint => {
        arrPoints.push([polygonPoint.point.lng, polygonPoint.point.lat]);
      });
      if (this.polygonPoints.length === 0) {
        this.polygonPoints = [];
        const coordenadas = this.zonaEdit.Geometria.geometry.coordinates[0][0];
        coordenadas.forEach((element: any, i) => {
          if (i < coordenadas.length - 1) {
            this.polygonPoints.push(new MapLatLng(element[1], element[0]));
          }
        });
      }
      //la primera y la última coordenada han de ser la misma, eso significa que el poligono está cerrado
      if (this.polygonPoints[0].lat !== this.polygonPoints[this.polygonPoints.length - 1].lat ||
        this.polygonPoints[0].lng !== this.polygonPoints[this.polygonPoints.length - 1].lng) {
        arrPoints.push(arrPoints[0]);
      }
      geometry.coordinates.push([arrPoints]);
    });
    geojson.geometry = geometry;
    geometry.type = 'MultiPolygon';
    return geojson;
  }

  // Calcula el área del polígono
  calcArea() {
    this.zonaEdit.Area = 0;
    for (let i = 0; i < this.polygon.points.length - 1; i++) {
      const p1 = this.polygon.points[i].point;
      const p2 = this.polygon.points[i + 1].point;
      this.zonaEdit.Area += this.degToRad(p2.lng - p1.lng) *
        (2 + Math.sin(this.degToRad(p1.lat)) + Math.sin(this.degToRad(p2.lat)));
    }
    // Invento un último punto igual al primero
    const p1 = this.polygon.points[this.polygon.points.length - 1].point;
    const p2 = this.polygon.points[0].point;
    this.zonaEdit.Area += this.degToRad(p2.lng - p1.lng) *
      (2 + Math.sin(this.degToRad(p1.lat)) + Math.sin(this.degToRad(p2.lat)));
    this.zonaEdit.Area = Math.abs(this.zonaEdit.Area * 6378137 * 6378137 / 2.0);
    this.zonaEdit.Area = Number.parseInt(this.zonaEdit.Area.toFixed(2));
  }

  // Pasa grados a radianes
  degToRad(deg: number): number {
    return deg * Math.PI / 180.0;
  }

  // Guardo la zona
  async saveZona() {
    // elimino la propiedad nombre del modelo
    delete this.zonaEdit.nombre;
    if (!this.canEdit) {
      return;
    }
    const response = await this.zonesService.saveZona(this.zonaEdit);

    if (response) {
      this.idZonaEntrada = response.Id;
      // Elimino los agregados por que dan problemas de referencia circular
      delete response.map;
      delete response.circle;
      delete response.polygons;
      if (this.zonaEdit.Id === 0) {
        this.zonas.push(response);
        // Selecciono la nueva zona como visible
        this.select = [];
        this.select.push({ id: response.Id });
      } else {
        for (let i = 0; i < this.zonas.length; i++) {
          if (this.zonas[i].Id === this.zonaEdit.Id) {
            this.zonas[i] = Utils.clone(response);
            break;
          }
        }
      }
      this.zonaEdit = Utils.clone(response);
      this.zonaSelec = Utils.clone(this.zonaEdit);
      this.dataSource.localdata = this.zonas;
      this.gridZonas.updatebounddata('data');
      this.titleEditWindow = this.translate('Edicion_zonas_cartograficas') + ' ' + '<b>' + this.zonaEdit.Nombre + '</b>';
      this.formEditZona.setTitle(this.titleEditWindow);
      MainComponent.getInstance().showInfo('ATENCION', 'Registro_almacenado', 2000);
      let rowsdata = this.gridZonas.getrows();
      rowsdata.forEach((element, index) => {
        if (element.id === this.zonaEdit.Id) {
          this.gridZonas.selectrow(index);
        }
      });
      setTimeout(() => {
        this.onOpenEditZona();
        this.drawZoneMapEdit();
        this.showLoader = false;
      }, 50);
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
    }
  }

  // Capturo las pulsaciones de teclado
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.formEditZona.isOpen()) {
      // En el caso de los polígonos con ESC borramos el último punto añadido
      if (this.zonaEdit.Id > 0 && this.polygonPoints.length < 1) {
        this.polygonPoints = [];
        const coordenadas = this.zonaEdit.Geometria.geometry.coordinates[0][0];
        coordenadas.forEach((element: any, i) => {
          if (i < coordenadas.length - 1) {
            this.polygonPoints.push(new MapLatLng(element[1], element[0]));
          }
        });
      }
      if (this.polygonPoints && this.polygonPoints.length > 0) {
        this.polygonPoints.pop();
        this.map.removePolygon(this.polygon);
        this.mapZonaEdit.removePolygons();
        this.polygon = this.map.addPolygon({
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          content: this.zonaEdit.Nombre,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          editable: true,
          zIndex: 100,
        });

        this.polygonPoints.forEach(punto => {
          this.map.addPolygonPoint(this.polygon, {
            content: this.zonaEdit.Nombre,
            position: punto
          });
        });
      }
    } else if (event.key === 'Enter' && this.formEditZona.isOpen()) {
      if (this.circle) {
        this.circle.setRadius(this.zonaEdit.Radio);
      }
    } else if (event.key === 'Control') {
      return;
    }
  }

  // obtener zonas checkeadas
  onChecked(event: any) {
    let idRow: number[] = this.gridZonas.getselectedrowindexes();
    let rows: any[] = this.gridZonas.getrows();
    this.checkSelect = [];
    rows.forEach((element, index) => {
      if (idRow.includes(element.boundindex)) {
        let zona = this.zonas.find((elem) => elem.Id == element.id);
        this.checkSelect.push(zona);
      }
    });
    this.checkNumerosSubject.next(this.checkSelect.length);
  }

  onrowClick(event: any) {
    this.zonaSelec = this.zonas.find((element) => element.Id === event.args.row.bounddata.id);
  }

  // deseleccion de elementos en el grid modelos
  async onUnselect(event: any) {
    if (event.args.row) {
      this.checkSelect = this.checkSelect.filter(element => element.Id !== event.args.row.id);
      this.checkNumerosSubject.next(this.checkSelect.length);

      // Verifica si this.select no es null o undefined y si this.select es un array
      if (Array.isArray(this.zonasFijas) && this.zonasFijas.some(item => item.id === event.args.row.id)) {
        this.zonasFijas = this.zonasFijas.filter(item => item.id !== event.args.row.id);
        if (this.zonasFijas.length === 0) {
          await this.configService.setUsuEmpApp('zones-fixed', JSON.stringify([]));
        }
        this.gridZonas.refresh();
        this.gridZonas.refreshaggregates();
      }
    }
  }

  async eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getZones();
  }

  async eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.zonas.forEach(zon => {
      if (
        zon?.Nombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        zon?.TipoNombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        zon?.TipoGeoNombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        zon?.Observaciones?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (zon?.Radio + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (zon?.Area + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (zon?.EntradasCount + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (zon?.SalidasCount + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (zon?.EntradasSalidasCount + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ) {
        zon['selec'] = 'selec';
      } else {
        zon['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.gridZonas.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.gridZonas.addfilter('selec', filtergroup);
    }
    this.gridZonas.applyfilters();
    this.gridZonas.updatebounddata('data');
  }

  onExportar() {
    if (this.gridZonas.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = JSON.parse(JSON.stringify(this.gridZonas.getrows()));

      // Elimino las columnas que no quiero exportar
      json.forEach(element => {
        delete element.id;
        delete element.uid;
        delete element.uniqueid;
        delete element.visibleindex;
        delete element.boundindex;
        delete element[''];
        element.area = NumberUtils.format(element.area, 0)

        // cambio el nombre de las columnas tipo_geo = Tipo, observ = Observaciones
        Object.keys(element).forEach(key => {
          if (key === 'tipo_geo') {
            element['Tipo'] = element[key];
            delete element[key];
          } else if (key === 'observ') {
            element['Observaciones'] = element[key];
            delete element[key];
          }
        });
      });

      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
      this.generateAutofilterHeader(ws);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Zonas') + '.xlsx');
    }
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  async onCancelar() {
    // cierro el formulario de edición de zonas
    this.removeMarker(this.zonaEdit.Id);
    this.mostrarEditZona = false;
    this.formEditZona.close();
  }

  // Boton para imprimir
  onPrint(event: any) {
    if (this.gridZonas.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.gridZonas.hidecolumn('Botones');
      let gridContent = this.gridZonas.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>' + this.translate('Zonas') + '</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.gridZonas.showcolumn('Botones');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }
}
