import { NgModule } from '@angular/core';

import { EntradaSalidaComponent } from './entrada-salida.component';
import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    EntradaSalidaComponent
  ],
  imports: [
    FormsModule,
    SharedModule,
    jqxGridModule,
    jqxInputModule,
    jqxWindowModule,
    jqxButtonModule,
  ],
  exports: [
    EntradaSalidaComponent
  ]
})
export class EntradaSalidaModule { }
