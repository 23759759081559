import { Component, HostListener, Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationError, NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MainComponent } from './components/main/main.component';
import { ApiService } from './services/api/api.service';
import { LangService } from './services/lang/lang.service';
import { SsoService } from './services/sso/sso.service';
import { AuditoriaService } from './services/auditoria/auditoria.service';
import { Accion } from './services/auditoria/models/accion.model';
import { AuditoriaModel } from './services/auditoria/models/auditoria.model';
import { CustomForms } from './components/forms/custom-forms';
import { HttpClient } from '@angular/common/http';
import { MenuModel } from './services/menu/models/menu.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @HostListener('window:resize', ['$event'])
  onResize(event) {
  }

  @ViewChild('mainContainer', { read: ViewContainerRef }) mainContainer;
  private static instance: AppComponent;
  public idioma = 'es-ES';
  public title = 'newp';
  public urlOrigin = '';
  public entorno = '';
  private usuarioEmail = '';
  private auditoria = new AuditoriaModel(this.usuarioEmail, 0);
  public menuData: any = null;

  constructor(
    private ssoService: SsoService,
    private router: Router,
    private apiService: ApiService,
    private langService: LangService,
    private auditoriaService: AuditoriaService,
    private http: HttpClient
  ) {
    AppComponent.instance = this;

    // Llamo a la función para registrar la inactividad
    this.registrarInactividad();

    const t = setTimeout(async () => {
      clearTimeout(t);
      // Compruebo la URL de origen
      this.urlOrigin = new URLSearchParams(window.location.search).get('origin');
      if (!this.urlOrigin) {
        this.urlOrigin = sessionStorage.getItem('np-origen');
        if (!this.urlOrigin) {
          this.urlOrigin = '';
        }
      }
      if (this.urlOrigin.startsWith('my.movisat.com')) {
        environment.apiSSO = 'https://api.sso.movisat.com';
        environment.webSSO = 'https://my.movisat.com';
        this.entorno = '';
      } else if (this.urlOrigin.startsWith('preproduccion.my.movisat.com')) {
        environment.apiSSO = 'https://api.sso.preproduccion.movisat.com';
        environment.webSSO = 'https://preproduccion.my.movisat.com';
        this.entorno = 'Pre-producción';
      } else {
        environment.apiSSO = 'https://api.sso.dev.movisat.com';
        environment.webSSO = 'https://dev.my.movisat.com';
        this.entorno = 'Desarrollo';
      }
      sessionStorage.setItem('np-origen', this.urlOrigin);

      // Antes de nada pido el token del SSO para que se almacene en el localstorage
      await this.ssoService.getToken();

      // Control de eventos de enrutamiento
      this.router.events.subscribe(async (event) => {
        if (event instanceof NavigationStart) {
          if (!event.url.startsWith('/?ticketId=')) {
            const ticket = this.ssoService.getTicket();
            if (ticket === null || !await this.ssoService.isUsuarioValidado(ticket.Usuario.Id)) {
              this.logout();
            }
          }
        }
        if (event instanceof NavigationError) {
          this.logout();
        }
      });

      // Recupero el ticket del SSO
      const ticketId = new URLSearchParams(window.location.search).get('ticketId');
      if (ticketId || !await this.ssoService.getTicket()) {
        if (await this.ssoService.initSession(ticketId)) {
          this.usuarioEmail = this.ssoService.getTicket().Usuario.Email;
          this.auditoria = new AuditoriaModel(this.usuarioEmail, 0);
          this.auditoria.AccionId = Accion.ACCESO_USUARIO;
          this.auditoriaService.addAuditoria(this.auditoria);
          window.location.href = "/";
        } else {
          this.logout();
        }
      } else {
        if (window.location.pathname === '/') {
          this.login();
        }
      }
    }, 0);
  }

  // Función para registrar la inactividad del usuario
  registrarInactividad() {
    let t;

    // Resetea el temporizador en cada acción del usuario
    window.onload = reiniciarTiempo;
    document.onmousemove = reiniciarTiempo;
    document.onkeypress = reiniciarTiempo;
    document.ontouchstart = reiniciarTiempo;
    document.onclick = reiniciarTiempo;
    document.onscroll = reiniciarTiempo;

    // Función que se ejecuta después de 15 minutos de inactividad
    function tiempoExcedido() {
      alert("Se ha cerrado la sesión después de 15 minutos sin actividad.");
      window.location.replace(environment.webSSO); // Redirige al login
    }

    // Reinicia el temporizador
    function reiniciarTiempo() {
      clearTimeout(t);
      t = setTimeout(tiempoExcedido, 900000); // 900000 ms = 15 minutos
    }
  }

  public static getInstance(): AppComponent {
    return AppComponent.instance;
  }

  // Redirijo a la página principal
  private async login(): Promise<void> {
    const ticketSSO = this.ssoService.getTicket();

    if (ticketSSO.UrlApi && ticketSSO.UrlApi.length > 0) {
      if (!(await this.apiService.initSessionApi())) {
        alert('No se ha obtenido token de ' + ticketSSO.UrlApi);
        this.logout();
      }
    }

    if (environment.urlApiBdt && environment.urlApiBdt.length > 0) {
      if (!this.apiService.initSessionApiBdt()) {
        alert('No se ha obtenido token de ' + environment.urlApiBdt);
        this.logout();
      }
    }

    if (ticketSSO.UrlApiIA && ticketSSO.UrlApiIA.length > 0) {
      if (!this.apiService.initSessionApiIA()) {
        alert('No se ha obtenido token de ' + ticketSSO.UrlApiIA);
      }
    }

    if (environment.carto.baseUrl && environment.carto.baseUrl.length > 0) {
      if (!this.apiService.initSessionApiCarto()) {
        alert('No se ha obtenido token de ' + environment.carto.baseUrl);
      }
    }

    this.idioma = ticketSSO.Usuario.Idioma.Codigo;
    const idIdioma = ticketSSO.Usuario.Idioma.Id;
    if (idIdioma) {
      await this.langService.getText(idIdioma);
    }

    await this.loadMenu();
    this.mainContainer.createComponent(MainComponent);
  }

  async loadMenu() {
    this.menuData = await this.http.get<MenuModel>(environment.apiSSO + '/sso/menu/aplicacion/rol/' +
      this.ssoService.getTicket().Empresa.Id + '/' + this.ssoService.getTicket().Aplicacion.Id + "/" +
      this.ssoService.getTicket().Rol.Id).toPromise();

    if (this.menuData && this.menuData.Opciones) {
      this.menuData.Opciones.forEach(item => {
        if (item.AccionId.length > 0) {
          CustomForms.menuActions.set(item.AccionId, item);
        }
        if (item.AccionId.length > 0 && item.Permisos && item.Permisos.length > 0) {
          CustomForms.permisos.set(item.AccionId, item.Permisos);
        }
      });
    }
  }

  private logout(): void {
    this.ssoService.endAppSession();
  }

  public static translate(text): string {
    try {
      return AppComponent.getInstance().langService.translate(text);
    } catch (e) {
      // Error en la traducción
    }
  }
}

// Clase para controlar las redirecciones
@Injectable()
export class AuthGuard  {

  constructor(private ssoService: SsoService) { }

  canActivate() {
    return this.ssoService.getTicket() !== null;
  }

  canActivateChild() {
    return this.ssoService.getTicket() !== null;
  }
}

