import { UserLangService } from 'src/app/services/common/user-lang/user-lang.service';
import { AfterViewInit, Component, computed, OnInit, signal, ViewChild } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../forms/custom-forms';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { environment } from 'src/environments/environment';
import { SsoService } from 'src/app/services/sso/sso.service';
import { Utils } from 'src/app/utils/utils';
import { CiudadanosService } from 'src/app/services/ciudadanos/ciudadanos.service';
import { CiudadanosValidacionModel } from 'src/app/services/ciudadanos/models/ciudadanosValidar.model';
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import { MainComponent } from '../main/main.component';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';
import { AplicacionModel } from 'src/app/services/sso/models/aplicacion.model';
import { RendererUtils } from 'src/app/utils/RendererUtils';

@Component({
  selector: 'app-ciudadanos-validar',
  templateUrl: './ciudadanos-validar.component.html',
  styleUrls: ['./ciudadanos-validar.component.css']
})
export class CiudadanosValidarComponent extends CustomForms implements OnInit, AfterViewInit {

  @ViewChild('myGrid') myGrid: jqxGridComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('tabs') tabs: jqxTabsComponent;

  componentRef: any;
  public static _this: CiudadanosValidarComponent;

  public langGrid = JqWidgets.getLocalization('es');
  public theme = environment.tema;
  appsConfValid: CiudadanosValidacionModel[] = [];
  apps: AplicacionModel[] = [];
  titleTabGeneral = this.translate('General');

  countPartialResults = signal<number>(0);
  titleTabGeneralSignal = computed(() => `${this.titleTabGeneral} (${this.countPartialResults()})`);

  columns: any = [
    { text: 'id', datafield: 'id', hidden: true },
    { text: 'idAplicacion', datafield: 'idAplicacion', hidden: true },
    { text: this.translate('Aplicacion'), datafield: 'aplicacion', width: 250, cellsrenderer: RendererUtils.rendexTextGeneric },
    {
      text: this.translate('Validar'), columntype: 'checkbox', datafield: 'requiereValidacion', type: 'boolean',
      width: 50,
      filtertype: 'checkedlist',
      filteritems: [
        this.translate('SI'),
        this.translate('NO'),
      ],
    },
  ];

  dataSource: any = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'number', map: 'id' },
      { name: 'aplicacion', type: 'string', map: 'nombreAplicacion' },
      { name: 'idAplicacion', type: 'number', map: 'idAplicacion' },
      { name: 'requiereValidacion', type: 'bool', map: 'requiereValidacion' },
    ],

    localdata: this.appsConfValid,
    sortcolumn: 'aplicacion',
    sortdirection: 'asc'
  };

  dataAdapter = new jqx.dataAdapter(this.dataSource);
  isSaving: boolean = false;
  showLoader: boolean = false;

  constructor(private ssoService: SsoService,
    private ciudadanosService: CiudadanosService,
    private auditoriaService: AuditoriaService,
    private userLangService: UserLangService) {
    super();
    CiudadanosValidarComponent._this = this;
  }

  async ngOnInit(): Promise<void> {
    this.langGrid = JqWidgets.getLocalization(this.userLangService.getUserLangShort());
    await this.loadApplications();
  }

  private loadApplications = async (): Promise<void> => {
    this.showLoader = true;
    try {
      const [appsTodas, appsByProyect] = await Promise.all([
        this.auditoriaService.getApps(),
        this.ssoService.getAppsByProyects(),
      ]);

      this.apps = this.filterAppsByProject(appsTodas, appsByProyect);
    } catch (error) {
      console.error(error);
    }
  }

  private filterAppsByProject(allApps: any[], projectApps: any[]): any[] {
    return allApps.filter(app => projectApps.some(projApp => projApp.AplicacionId === app.Id));
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    await this.addMissingAppsToConfig();
    this.initializeGrid();
    this.setTabTitle();
  }

  private addMissingAppsToConfig = async (): Promise<void> => {
    this.appsConfValid = await this.ciudadanosService.getConfiguracionValidacionAplicacionByEmpresa();
    const empresaId = this.ssoService.getTicket().Empresa.IdGestion;

    this.appsConfValid = Array.isArray(this.appsConfValid) ? this.appsConfValid : [this.appsConfValid];

    this.apps.forEach((app) => {
      const existingAppConfig = this.appsConfValid.find((a) => a.idAplicacion === app.Id);
      if (existingAppConfig) {
        existingAppConfig.nombreAplicacion = app.Nombre;
      } else {
        this.appsConfValid.push({
          id: 0,
          empresa: empresaId,
          idAplicacion: app.Id,
          nombreAplicacion: app.Nombre,
          requiereValidacion: false,
          fechaCreacion: '',
          fechaModificacion: '',
          fechaBaja: ''
        });
      }
    });
  }

  private initializeGrid(): void {
    this.dataSource.localdata = this.appsConfValid;
    this.myGrid.updatebounddata('data');
    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
    this.showLoader = false;
  }

  private setTabTitle(): void {
    this.tabs.setTitleAt(0, this.titleTabGeneralSignal());
  }

  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  onGridDataUpdate(): void {
    const rows = this.myGrid.getrows();
    if (rows) {
      this.countPartialResults.set(rows.length);
    }
  }

  filter = (cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean => {
    let filterColumns = ['aplicacion', 'validar'];
    if (dataField == 'requiereValidacion') {
      let filterValue = filterGroup.getfilters()[0].value;

      switch (filterValue) {
        case this.translate('SI'):
          return cellValue;
        case this.translate('NO'):
          return !cellValue;

        default:
          return defaultFilterResult;
      }
    }
    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  };

  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    if (group === undefined) {
      return;
    }

    let num = data?.subItems.length;
    let name = group;
    let header = '';

    if (name === true) {
      header = '(S)';
    } else if (name === false) {
      header = '(N)';
    } else {
      header = name;
    }

    return '<div style="margin-left: 4px;">' + header + ': ' + num + '</div>';
  }

  onClose() {
    this.componentRef.destroy();
    CiudadanosValidarComponent._this = null;
  }

  async save() {
    this.isSaving = true;
    this.showLoader = true;
    let data = [];

    this.myGrid.getrows().forEach((row) => {
      data.push({
        id: row.id === undefined ? 0 : row.id,
        empresa: this.ssoService.getTicket().Empresa.IdGestion,
        idAplicacion: row.idAplicacion,
        requiereValidacion: row.requiereValidacion,
      });
    });

    try {
      await Promise.all(
        data.map((item) => this.ciudadanosService.postConfiguracionValidacionAplicacion(item))
      );

      MainComponent.showSuccess('ATENCION', 'Cambios_guardados', 2000);
      await this.addMissingAppsToConfig();
      this.initializeGrid();
    } catch (error) {
      console.error(error);
      MainComponent.showError('ATENCION', 'Ha_ocurrido_un_error', 2000);
    }
    this.isSaving = false;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

}
