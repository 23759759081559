<jqxWindow #windowStates jqxWindowAutoResize [theme]="environment.tema" [zIndex]="90" [autoOpen]="true"
  [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
  <div class="formHeader">
  </div>
  <div class="form">
    <div>
      <app-header #header [exportar]="true" [periodo]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
        [noLibre]="false" (eventExportar)="onExportar()" (eventFilter)="onFilter()"
        (eventBuscador)="initSearchControl()" (eventResetFilter)="onResetFilter()">
      </app-header>
    </div>
    <div class="container-history">
      <div class="grid-incidences-container">
        <jqxGrid #statesGrid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [columnsresize]="true"
          [source]="dataAdapter" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true" [columns]="columns"
          [altrows]="true" [sortmode]="'many'" [enabletooltips]="true" [filterable]="false" [groupsheaderheight]="25"
          (onBindingcomplete)="onBindingComplete()" [rowsheight]="25" [columnsheight]="20" [localization]="langGrid"
          [groupable]="true" [autoloadstate]="true" (onRowselect)="onRowSelect($event)">
        </jqxGrid>
        <jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
        </jqxLoader>
      </div>
      <div class="buttons-container">
        <jqxButton style="float: left; margin-left: 4px;" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="windowStates.close()"
          [value]="translate('Salir')">
        </jqxButton>
      </div>
    </div>
  </div>
</jqxWindow>