import { MovilesAlarmasGeoService } from './../../../services/movilesAlarma/moviles-alarmas-geo.service';
import { AfterViewInit, Component, ElementRef, OnInit, signal, ViewChild, ViewContainerRef } from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { GeoUtils } from 'src/app/utils/geo-utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { NumberUtils } from 'src/app/utils/number-utils';

import { MapBounds } from 'movisat-maps';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';
import {
  MapCircle,
  MapComponent,
  MapLatLng,
  MapPolygon
} from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { AlarmsService } from 'src/app/services/alarms/alarms.service';
import { ResourcesService } from 'src/app/services/resources/resources.service';

import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { AlarmModel } from 'src/app/services/alarms/models/alarm.model';
import { MovilModel } from 'src/app/services/resources/models/movil.model';
import * as xlsx from 'xlsx';
import { ZonesComponent } from '../../zones/zones.component';
import { HeaderComponent } from '../../header/header.component';
import { UsuarioModel } from 'src/app/services/sso/models/usuario.model';
import { jqxTextAreaComponent } from 'jqwidgets-ng/jqxtextarea';
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserLangService } from 'src/app/services/common/user-lang/user-lang.service';

@Component({
  selector: 'app-alarmsgeo',
  templateUrl: './alarmsgeo.component.html',
  styleUrls: ['./alarmsgeo.component.css']
})
export class AlarmsgeoComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('gridAlarmas') gridAlarmas: jqxGridComponent;
  @ViewChild('formEdit') formEdit: jqxWindowComponent;
  @ViewChild('cbZonas') cbZonas: jqxDropDownListComponent;
  @ViewChild('sUsuarios') sUsuarios: jqxDropDownListComponent;
  @ViewChild('ebNombre') ebNombre: jqxInputComponent;
  @ViewChild('horaEntrada') horaEntrada: jqxDateTimeInputComponent;
  @ViewChild('horaSalida') horaSalida: jqxDateTimeInputComponent;
  @ViewChild('gridMoviles1') gridMoviles1: jqxGridComponent;
  @ViewChild('gridMoviles2') gridMoviles2: jqxGridComponent;
  @ViewChild('emails') emails: jqxTextAreaComponent;
  @ViewChild('menuContainer', { read: ViewContainerRef }) menuContainer;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('tabs') tabs: jqxTabsComponent;

  totalDisponibles = signal<number>(0);
  totalAsignadas = signal<number>(0);

  public static _this: AlarmsgeoComponent;

  private map: MapComponent;
  public alarmaEdit: AlarmModel;
  public dataSource: any;
  public dataAdapter: any;
  public mostrarEditForm = false;
  public mostrarAsigForm = false;
  private componentRef = null;
  private component: any = null;
  public environment = environment;
  public canEdit = true;
  private circle: MapCircle = null;
  private polygons: MapPolygon[] = [];
  alarmas: AlarmModel[] = [];
  private zonas: ZonaModel[] = [];
  private usuarios: UsuarioModel[] = [];
  private usuario: UsuarioModel = new UsuarioModel();
  private moviles = [];
  private movilesAsig = [];
  formZonas: boolean = true;
  subcribe: any;
  zona: ZonaModel;
  mapWidth: number;
  mapHeight: number;
  inputDate: jqxDateTimeInputComponent;
  sinZona: boolean = false;

  public dias = {
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
    domingo: false
  };

  cellClass = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'cellTooltip';
    }
    return '';
  }

  createTooltip = (content: string) => {
    return (columnHeaderElement?: any) => {
      const jqElement = columnHeaderElement[0].parentElement;
      const options = {
        content: content,
        theme: environment.tema,
        width: 'auto',
        height: 'auto',
        position: 'mouse',
        autoHide: true,
      };
      jqwidgets.createInstance(jqElement, 'jqxTooltip', options);
    };
  };

  // Preparo las columnas del grid
  public columns: any = [
    {
      text: '',
      width: 130,
      columntype: 'text',
      sortable: false,
      editable: false,
      groupable: false,
      exportable: false,
      menu: false,
      rendered: (columnHeaderElement) => {
        const buttonContainer1 = document.createElement('div');
        buttonContainer1.style.width = '100%';
        buttonContainer1.style.display = 'flex';
        buttonContainer1.style.justifyContent = 'center';

        buttonContainer1.id = `buttonContainerColumn_jqxButton`;
        columnHeaderElement[0].appendChild(buttonContainer1);
        const btnCrear = document.createElement('div');
        btnCrear.innerHTML =
          `<button class="button" style="height: 20px; width: 100%; padding: 0; margin: 0; cursor: pointer !important ; title="` +
          AppComponent.translate('Crear') + `"> <i style="font-size: 18px;" class="fa-solid fa-plus"></i></button>`;
        btnCrear.id = `buttonCrear_jqxButton`;
        buttonContainer1.appendChild(btnCrear);
        btnCrear.style.width = '50%'
        btnCrear.addEventListener('click', async (event: any) => {
          this.onCrearAlarma();
        });
        return columnHeaderElement[0];
      },
      createwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ): void => {
        this.initBtnColumn(row, column, value, htmlElement);
      },
      initwidget: (
        row: any,
        column: any,
        value: string,
        htmlElement: HTMLElement
      ) => {
        this.initBtnColumn(row, column, value, htmlElement);
      },
    },
    { text: AppComponent.translate('Num_alarmas_asig'), columntype: 'textbox', width: '55', filtertype: 'textbox', datafield: AppComponent.translate('Num_alarmas_asig'), align: 'center', cellsalign: 'right', rendered: this.createTooltip(AppComponent.translate('Num_alarmas_asig')), },
    { text: AppComponent.translate('Nombre'), columntype: 'textbox', width: '200', filtertype: 'textbox', datafield: AppComponent.translate('Nombre'), align: 'center', cellsrenderer: this.rendexTextGeneric, rendered: this.createTooltip(AppComponent.translate('Nombre')), },
    { text: AppComponent.translate('Zona'), columntype: 'textbox', width: '200', filtertype: 'textbox', datafield: AppComponent.translate('Zona'), align: 'center', cellsrenderer: this.rendexTextGeneric, rendered: this.createTooltip(AppComponent.translate('Zona')), },
    { text: AppComponent.translate('Radio') + ' (m)', columntype: 'textbox', width: '70', filtertype: 'textbox', datafield: AppComponent.translate('Radio') + ' (m)', align: 'center', cellsalign: 'center', cellsrenderer: this.rendexTextGeneric, rendered: this.createTooltip(AppComponent.translate('Radio') + ' (m)'), },
    { text: AppComponent.translate('Superficie') + ' (m²)', columntype: 'textbox', width: '100', filtertype: 'textbox', datafield: AppComponent.translate('Superficie') + ' (m²)', align: 'center', cellsalign: 'center', cellsrenderer: this.rendexTextGeneric, rendered: this.createTooltip(AppComponent.translate('Superficie') + ' (m²)'), },
    { text: AppComponent.translate('Dias'), columntype: 'textbox', filtertype: 'textbox', width: '85', datafield: AppComponent.translate('Dias'), align: 'center', cellsalign: 'center', cellsrenderer: this.renderDias, rendered: this.createTooltip(AppComponent.translate('Dias')), },
    {
      text: 'E', columntype: 'checkbox', width: '50', datafield: AppComponent.translate('Entrada'), align: 'center', cellclassname: 'checkGray',
      filtertype: 'checkedlist',
      filteritems: [
        this.translate('SI'),
        this.translate('NO')
      ],
      rendered: this.createTooltip(AppComponent.translate('Entrada')),
    },
    { text: AppComponent.translate('Hora_entrada'), columntype: 'datetimeinput', width: '90', filtertype: 'date', datafield: AppComponent.translate('Hora_entrada'), align: 'center', cellsformat: 'HH:mm:ss', rendered: this.createTooltip(AppComponent.translate('Hora_de_entrada')), },
    {
      text: 'S', columntype: 'checkbox', width: '50', datafield: AppComponent.translate('Salida'), align: 'center', cellclassname: 'checkGray',
      filtertype: 'checkedlist',
      filteritems: [
        this.translate('SI'),
        this.translate('NO')
      ],
      rendered: this.createTooltip(AppComponent.translate('Salida')),
    },
    { text: AppComponent.translate('Hora_salida'), columntype: 'datetimeinput', width: '90', filtertype: 'date', datafield: AppComponent.translate('Hora_salida'), align: 'center', cellsformat: 'HH:mm:ss', rendered: this.createTooltip(AppComponent.translate('Hora_de_salida')), },
    { text: "id", datafield: "id", hidden: true },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
  ];

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let esFecha: boolean = false;
    let filterColumns = [];

    if (dataField !== AppComponent.translate('Hora_entrada') && dataField !== AppComponent.translate('Hora_salida')) {
      filterColumns = [
       AppComponent.translate('Nombre'),
        AppComponent.translate('Zona'),
        AppComponent.translate('Dias'),
        AppComponent.translate('Matricula'),
        AppComponent.translate('Descripcion'),
      ];

    } else {
      filterColumns = [
        AppComponent.translate('Hora_entrada'),
        AppComponent.translate('Hora_salida')
      ]

      esFecha = true;
    }

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns, esFecha);
  }

  // Cuando se usan los filtro del header
  onFilter(event: any) {
    // Itero a través de todos los filtros aplicados
    for (let filterObj of event.args.filters) {
      let column = filterObj.datafield;
      let filter = filterObj.filter.getfilters();

      if (column === 'entrada' || column === 'salida') {
        let filter1;
        filter.forEach(filt => {
          let filtergroup = new jqx.filter();
          let filter_operator = filt.operator;
          let filtercondition = filt.condition;
          let filtervalue;

          if (filt.value === this.translate('SI')) {
            filtervalue = true;
          } else if (filt.value === this.translate('NO')) {
            filtervalue = false;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridAlarmas.addfilter(column, filtergroup);
            this.gridAlarmas.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', filt.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridAlarmas.addfilter(column, filtergroup);
          }
        });
      }
    }
  }

  // Variables para el grid de móviles no asignados
  public movilesSinAsignar: MovilModel[] = [];
  public sourceMoviles1: any = [];
  public dataAdapterMoviles1;

  rendexTextGeneric(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (typeof value === 'string') {
      return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;
    } else if (typeof value === 'number') {
      switch (columnfield) {
        case AppComponent.translate('Radio') + ' (m)':
        case AppComponent.translate('Superficie') + ' (m²)':
          return `<div style="margin-right: 4px; margin-top: 4px; text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${NumberUtils.format(value, 0)}</span></div>`;
        default:
          return `<div style="margin-right: 4px; margin-top: 4px; text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${value}</span></div>`;
      }
    }
  }

  public columnsMoviles1 = [
    {
      text: AppComponent.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', width: 70, datafield: 'matricula', cellsrenderer: this.rendexTextGeneric, rendered: this.createTooltip(AppComponent.translate('Matricula')),
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left;">' + AppComponent.translate('Total') + ': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'descripcion', cellsrenderer: this.rendexTextGeneric, rendered: this.createTooltip(AppComponent.translate('Descripcion')), width: 150 },
    { text: AppComponent.translate('Subflota'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'subflota', cellsrenderer: this.rendexTextGeneric, rendered: this.createTooltip(AppComponent.translate('Subflota')), width: 100 },
    { text: AppComponent.translate('Recurso'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'recurso', cellsrenderer: this.rendexTextGeneric, rendered: this.createTooltip(AppComponent.translate('Recurso')), width: 100 },
    { text: AppComponent.translate('Clase'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'clase', rendered: this.createTooltip(AppComponent.translate('Clase')), cellclassname: this.cellClass, width: 80 },
  ];

  // Variables para el grid de moviles asignados
  public movilesAsignados: MovilModel[] = [];
  public sourceMoviles2: any = [];
  public dataAdapterMoviles2;

  // Matricula, Descripción, SubFlota Recurso y Clase)
  public columnsMoviles2 = [
    {
      text: AppComponent.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', width: 70, datafield: 'matricula', cellsrenderer: this.rendexTextGeneric, rendered: this.createTooltip(AppComponent.translate('Matricula')),
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left;">' + AppComponent.translate('Total') + ': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'descripcion', cellsrenderer: this.rendexTextGeneric, rendered: this.createTooltip(AppComponent.translate('Descripcion')), width: 150 },
    { text: AppComponent.translate('Subflota'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'subflota', cellsrenderer: this.rendexTextGeneric, rendered: this.createTooltip(AppComponent.translate('Subflota')), width: 100 },
    { text: AppComponent.translate('Recurso'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'recurso', cellsrenderer: this.rendexTextGeneric, rendered: this.createTooltip(AppComponent.translate('Recurso')), width: 100 },
    { text: AppComponent.translate('Clase'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'clase', cellclassname: this.cellClass, rendered: this.createTooltip(AppComponent.translate('Clase')), width: 80 },
  ];

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');
  handleCheckChange: boolean = true;
  selectedUsuarios: any[];
  usuarioAdapter: any;
  showLoader: boolean = true;

  constructor(private ssoService: SsoService,
    private alarmsService: AlarmsService,
    private zonesService: ZonesService,
    private resourcesService: ResourcesService,
    private modal: NzModalService,
    private movilesAlarmasGeoService: MovilesAlarmasGeoService,
    private userlangService: UserLangService
    ) {
    super();
    AlarmsgeoComponent._this = this;
  }

  ngOnInit(): void {
    this.getUsuarios();
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.canEdit = true; // TODO: por hacer...
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.userlangService.getUserLangShort());
    this.map = MainComponent.getInstance().getActiveMap();
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    await this.getAlarms();

    if (this.gridAlarmas.getrows().length == 0) {
      this.gridAlarmas.showemptyrow(true);
    }
    //Utils.renderSizeGrid(this.gridAlarmas);
  }

  onBindingLibres() {
    this.updateTotalTitle();
  }

  onBindingAsignadas() {
    this.updateTotalTitle();
  }

  closeWindow() {
    this.form.close();
  }

  // Incializa la columna de botones
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    let rowdata: any;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.gridAlarmas.getrowdata(row);
    }

    htmlElement.innerHTML = '';
    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'flex-start';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
      <button class="button" style="height: 23px; width: 30px; cursor: pointer;" title="`+ AppComponent.translate('Editar') + `">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;
    btnEdit.id = `buttonEdit`;
    btnContainer.appendChild(btnEdit);

    btnEdit.addEventListener('click', async (event: any) => {
      this.alarmaEdit = this.alarmas.find(elem => elem.Id == rowdata.id);
      if (this.alarmaEdit.Zona.Nombre == undefined) {
        this.sinZona = true;
      } else {
        this.sinZona = false;
      }
      MainComponent.getInstance().minimizeWindows();
      this.onEditarAlarma();
      await this.ensureTabsLoaded();
    });

    const btnAssign = document.createElement('div');
    btnAssign.innerHTML = `
      <button class="button" style="height: 23px; width: 30px; cursor: pointer;" title="`+ AppComponent.translate('Asignar') + `">
        <i class="fa-solid fa-link"></i>
      </button>
    `;
    btnAssign.id = `buttonAssign`;
    btnContainer.appendChild(btnAssign);

    btnAssign.addEventListener('click', async () => {
      const selectedAlarm = this.alarmas.find(({ Id }) => Id === rowdata.id);

      if (!selectedAlarm) {
        return;
      }

      this.prepareAssignment(selectedAlarm);
      await this.ensureTabsLoaded();
      this.tabs.selectedItem(1);
      await this.updateListaDisponibles();

    });

    // onVer
    const btnVer = document.createElement('div');
    btnVer.innerHTML = `
      <button class="button" style="height: 23px; width: 30px; cursor: pointer;" title="`+ AppComponent.translate('Ver') + `">
        <i class="fa-solid fa-eye"></i>
      </button>
    `;
    btnVer.id = `buttonVer`;
    btnContainer.appendChild(btnVer);

    btnVer.addEventListener('click', async (event: any) => {
      this.alarmaEdit = this.alarmas.find(elem => elem.Id == rowdata.id);
      MainComponent.getInstance().minimizeWindows();
      this.onVerAlarma();
    });

    if (rowdata.numeroAlarmaAsig == 0) {
      const btnDelete = document.createElement('div');
      btnDelete.innerHTML = `
        <button class="button" style="height: 23px; width: 30px; cursor: pointer;" title="`+ AppComponent.translate('Borrar') + `">
          <i class="fa-solid fa-trash"></i>
        </button>
      `;
      btnDelete.id = `buttonDelete`;
      btnContainer.appendChild(btnDelete);

      btnDelete.addEventListener('click', async (event: any) => {
        this.alarmaEdit = this.alarmas.find(elem => elem.Id == rowdata.id);
        this.onBorrarAlarma();
      });
    }

    htmlElement.appendChild(btnContainer);
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.circle) {
      this.map.removeCircle(this.circle)
    }
    if (this.polygons) {
      this.polygons.forEach(polygon => {
        this.map.removePolygon(polygon);
      });
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    AlarmsgeoComponent._this = null;
  }

  sortedColumn: any;

  customsortfunc = (column: any, direction: string | boolean): void => {
    let sortdata = new Array();
    if (direction == 'ascending') direction = true;
    if (direction == 'descending') direction = false;
    if (direction != null) {
      for (let i = 0; i < this.alarmas.length; i++) {
        sortdata.push(this.alarmas[i]);
      }
    } else {
      sortdata = this.alarmas;
    }
    this.sortedColumn = column;

    let tmpToString = Object.prototype.toString;
    Object.prototype.toString = (typeof column == 'function') ? column : () => { return this[column] };
    if (direction != null) {
      sortdata.sort(this.compare);
      if (!direction) {
        sortdata.reverse();
      }
    }
    this.dataSource.localdata = sortdata;
    this.gridAlarmas.updatebounddata('sort');
    Object.prototype.toString = tmpToString;
  }

  compare = (value1: any, value2: any): any => {
    if (this.sortedColumn === 'nombre') {
      value1 = value1.Nombre;
      value2 = value2.Nombre;
    } else if (this.sortedColumn === 'zona') {
      value1 = value1.Zona.Nombre;
      value2 = value2.Zona.Nombre;
    } else if (this.sortedColumn === 'radio') {
      value1 = value1.Zona.Radio;
      value2 = value2.Zona.Radio;
    } else if (this.sortedColumn === 'area') {
      value1 = value1.Zona.Area;
      value2 = value2.Zona.Area;
    } else if (this.sortedColumn === 'dias') {
      value1 = value1.Dias;
      value2 = value2.Dias;
    } else if (this.sortedColumn === 'entrada') {
      value1 = value1.Entrada;
      value2 = value2.Entrada;
    } else if (this.sortedColumn === 'horaEntrada') {
      value1 = value1.HoraEntrada;
      value2 = value2.HoraEntrada;
    } else if (this.sortedColumn === 'salida') {
      value1 = value1.Salida;
      value2 = value2.Salida;
    } else if (this.sortedColumn === 'horaSalida') {
      value1 = value1.HoraSalida;
      value2 = value2.HoraSalida;
    } else if (this.sortedColumn === 'numeroAlarmaAsig') {
      value1 = value1.numeroAlarmaAsig;
      value2 = value2.numeroAlarmaAsig;
    }

    try {
      let tmpvalue1 = parseFloat(value1);
      if (isNaN(tmpvalue1)) {
        if (value1 < value2) { return -1; }
        if (value1 > value2) { return 1; }
      }
      else {
        let tmpvalue2 = parseFloat(value2);
        if (tmpvalue1 < tmpvalue2) { return -1; }
        if (tmpvalue1 > tmpvalue2) { return 1; }
      }
    }
    catch (error) {
      let er = error;
    }
    return 0;
  };

  private async ensureTabsLoaded(): Promise<void> {
    return new Promise<void>((resolve) => {
      const checkTabs = () => {
        if (this.tabs) {
          this.tabs.setTitleAt(0, AppComponent.translate('Alarmas_geograficas'));
          this.tabs.setTitleAt(1, `${AppComponent.translate('Asignacion_alarmas')}`);
          resolve();
        } else {
          setTimeout(checkTabs, 50);
        }
      };
      checkTabs();
    });
  }

  private async ensureWindowLoaded(): Promise<void> {
    return new Promise<void>((resolve) => {
      const checkWindow = () => {
        if (this.formEdit) {
          this.formEdit.setTitle(`${AppComponent.translate('Edicion_alarmas')}`);
          this.addCustomForm(this.formEdit);
          resolve();
        } else {
          setTimeout(checkWindow, 50);
        }
      };
      checkWindow();
    });
  }

  private prepareAssignment(alarm: AlarmModel): void {
    this.alarmaEdit = alarm;
    MainComponent.getInstance().minimizeWindows();

    this.alarmaEdit = alarm;
    this.mostrarEditForm = true;
  }

  showRowDetails: any[] = [];

  onSort(event: any) {
    this.showRowDetails.forEach(row => {
      this.gridAlarmas.getrows().forEach(elem => {
        if (row === elem.id) {
          this.gridAlarmas.showrowdetails(elem.boundindex)
        }
      });
    });
  }

  onRowClick(event: any) {
    if (event.args.row.rowdetailshidden) {
      this.showRowDetails.push(event.args.row.bounddata.id);
    } else {
      this.showRowDetails.splice(this.showRowDetails.indexOf(event.args.row.bounddata.id), 1);
    }
  }

  // Obtengo las alarmas por empresa
  async getAlarms() {
    this.alarmas = await this.alarmsService.getAlarmas();

    if (this.alarmas) {
      this.dataSource = {
        sort: this.customsortfunc,
        datatype: 'json',
        datafields: [
          { name: AppComponent.translate('Num_alarmas_asig'), type: 'int', map: 'numeroAlarmaAsig' },
          { name: AppComponent.translate('Nombre'), type: 'string', map: 'Nombre' },
          { name: AppComponent.translate('Zona'), type: 'string', map: 'Zona>Nombre' },
          { name: AppComponent.translate('Radio') + ' (m)', type: 'number', map: 'Zona>Radio' },
          { name: AppComponent.translate('Superficie') + ' (m²)', type: 'number', map: 'Zona>Area' },
          { name: AppComponent.translate('Dias'), type: 'string', map: 'Dias' },
          { name: AppComponent.translate('Entrada'), type: 'bool', map: 'Entrada' },
          { name: AppComponent.translate('Hora_entrada'), type: 'date', map: 'HoraEntrada' },
          { name: AppComponent.translate('Salida'), type: 'bool', map: 'Salida' },
          { name: AppComponent.translate('Hora_salida'), type: 'date', map: 'HoraSalida' },
          { name: 'id', type: 'number', map: 'Id' }, //HIDDEN
          { name: 'selec', map: 'selec' } //HIDDEN
        ],
        localdata: this.alarmas,
      };
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);
    } else {
      this.alarmas = [];
    }
    this.showLoader = false;
    this.gridAlarmas.sortby('zona', 'asc');
    const t = setTimeout(() => {
      clearTimeout(t);
      this.form.setTitle(`${AppComponent.translate('Alarmas_geograficas')} (${this.gridAlarmas?.getrows().length})`);
    }, 100)
  }

  private updateGridSource(grid: jqxGridComponent, data: MovilModel[]): void {
    const source = {
      datatype: 'json',
      datafields: [
        { name: 'matricula', map: 'Matricula' },
        { name: 'descripcion', map: 'ConjuntoVehiculo>Recurso>Descripcion' },
        { name: 'subflota', map: 'Subflota>Nombre' },
        { name: 'recurso', map: 'ConjuntoVehiculo>Recurso>Nombre' },
        { name: 'clase', map: 'conjVehClase' },
      ],
      localdata: data,
    };
    grid.source(new jqx.dataAdapter(source));
  }

  renderRow(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    switch (columnfield) {
      case 'horaEntrada':
      case 'horaSalida':
        if (value.getDate() !== new Date('0001-01-01T00:00:00').getDate()) {
          value = value ? DateUtils.formatTime(value) : '';
        } else {
          value = '';
        }
        return '<div style="text-align: center; margin-top:4px; ">' + value + '</div>';
      case 'entrada':
      case 'salida':
        value = value ? 'X' : '';
        return '<div style="text-align: ' + columnproperties.cellsalign + ';">' + value + '</div>';
      case 'radio': return '<div style="float: right; margin-top: 4px; margin-right:2px;">' + NumberUtils.format(value, 0) + '</div>';
      case 'area': return '<div style="float: right; margin-top: 4px; margin-right:2px;">' + NumberUtils.format(value, 0) + '</div>';
    }
  }

  renderDias(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    // pongo los dias entre guiones L-M-X-J-V-S-D
    let dias = '';
    if (value.indexOf('L') > -1) {
      dias += 'L-';
    }
    if (value.indexOf('M') > -1) {
      dias += 'M-';
    }
    if (value.indexOf('X') > -1) {
      dias += 'X-';
    }
    if (value.indexOf('J') > -1) {
      dias += 'J-';
    }
    if (value.indexOf('V') > -1) {
      dias += 'V-';
    }
    if (value.indexOf('S') > -1) {
      dias += 'S-';
    }
    if (value.indexOf('D') > -1) {
      dias += 'D-';
    }
    if (dias.length > 0) {
      dias = dias.substring(0, dias.length - 1);
    }
    return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${dias}</div>`;
  }

  public columnmenuopening(menu?: any, datafield?: any, height?: number | string): boolean | void {
    if (menu.length === 1) {
      const divElement: HTMLElement = menu[0];
      const dateTimeInputs = divElement.querySelectorAll('.jqx-datetimeinput');
      if (dateTimeInputs && dateTimeInputs.length > 0) {
        dateTimeInputs.forEach((input: HTMLElement) => {
          const elementRef = new ElementRef(input);
          const jqxDateTimeInput = new jqxDateTimeInputComponent(elementRef);
          this.inputDate = jqwidgets.createInstance('#' + jqxDateTimeInput.elementRef.nativeElement.id, 'jqxDateTimeInput', { width: '100%', height: 25, formatString: 'HH:mm:ss' });
          this.inputDate.setOptions({ showTimeButton: true, showCalendarButton: false });
        });
      }
    }
  }

  onRowSelect(event: any) {
    this.alarmaEdit = this.alarmas[event.args.rowindex];
  }

  onRowdoubleclick(event: any) {
    if (event.currentTarget === event.target) {
      if (this.canEdit) {
        this.alarmaEdit = this.alarmas[event.args.rowindex];
        this.onEditarAlarma();
      }
    }
  }

  async onCrearAlarma() {
    if (!this.canEdit) {
      return;
    }

    this.alarmaEdit = new AlarmModel();
    this.dias = {
      lunes: false,
      martes: false,
      miercoles: false,
      jueves: false,
      viernes: false,
      sabado: false,
      domingo: false
    };

    this.mostrarEditForm = true;
    this.form.collapse();

    this.sinZona = true;
    await this.ensureTabsLoaded();
    await this.updateListaDisponibles();
  }

  onEditarAlarma() {
    if (this.alarmaEdit) {
      this.alarmaEdit = Utils.clone(this.alarmaEdit);
      this.dias.lunes = this.alarmaEdit.Dias.indexOf('L') > -1;
      this.dias.martes = this.alarmaEdit.Dias.indexOf('M') > -1;
      this.dias.miercoles = this.alarmaEdit.Dias.indexOf('X') > -1;
      this.dias.jueves = this.alarmaEdit.Dias.indexOf('J') > -1;
      this.dias.viernes = this.alarmaEdit.Dias.indexOf('V') > -1;
      this.dias.sabado = this.alarmaEdit.Dias.indexOf('S') > -1;
      this.dias.domingo = this.alarmaEdit.Dias.indexOf('D') > -1;
      this.alarmaEdit.HoraEntrada = new Date(this.alarmaEdit.HoraEntrada);
      this.alarmaEdit.HoraSalida = new Date(this.alarmaEdit.HoraSalida);
      if (this.alarmaEdit.HoraEntrada.getDate() !== new Date('0001-01-01T00:00:00').getDate()) {
        this.alarmaEdit.HayHoraEntrada = true;
      } else {
        this.alarmaEdit.HayHoraEntrada = false;
      }
      if (this.alarmaEdit.HoraSalida.getDate() !== new Date('0001-01-01T00:00:00').getDate()) {
        this.alarmaEdit.HayHoraSalida = true;
      } else {
        this.alarmaEdit.HayHoraSalida = false;
      }
      this.openEditForm();
    } else {
      MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  // Muestra el formulario de edición de alarmas
  openEditForm() {
    this.mostrarEditForm = true;
    if (this.form !== undefined) {
      this.form.collapse();
    }
  }

  // Borrar una alarma
  onBorrarAlarma() {
    if (!this.canEdit) {
      return;
    }
    if (this.alarmaEdit !== null) {
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quiere_borrar_alarma') + ': ' + this.alarmaEdit.Nombre + ' ?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzOnOk: async () => {
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
          await this.alarmsService.deleteAlarma(this.alarmaEdit.Id);
          for (let i = 0; i < this.alarmas.length; i++) {
            if (this.alarmas[i].Id === this.alarmaEdit.Id) {
              this.alarmas.splice(i, 1);
              break;
            }
          }
          this.alarmaEdit = null;
          this.gridAlarmas.updatebounddata('cells');
        }
      });
    } else {
      MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  isVisible = false;
  verEjemplo() {
    this.isVisible = true;
  }

  handleCancel() {
    this.isVisible = false;
  }

  handleOk() {
    this.isVisible = false;
  }


  onVerAlarma() {
    if (!this.alarmaEdit) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      return;
    }

    this.limpiarMapa();
    let bounds;

    if (this.alarmaEdit.Zona.TipoGeo === 0) { // Círculo
      bounds = this.procesarCirculo();
    } else { // Polígono
      bounds = this.procesarPoligono();
    }

    if (bounds) {
      this.map.setCenter(bounds.center);
      this.map.fitTo(new MapBounds(bounds.swPoint, bounds.nePoint));
    }

    this.form.collapse();
  }

  limpiarMapa() {
    if (this.circle) {
      this.map.removeCircle(this.circle);
      this.circle = null;
    }
    if (this.polygons) {
      this.polygons.forEach(polygon => this.map.removePolygon(polygon));
      this.polygons = null;
    }
  }

  procesarCirculo() {
    const zona = this.alarmaEdit.Zona;
    this.circle = this.map.addCircle({
      dataModel: this.alarmaEdit,
      content: `${this.alarmaEdit.Nombre}, ${zona.Nombre}, Radio: ${NumberUtils.format(zona.Radio, 0)} m.`,
      strokeColor: '#ff0000',
      strokeOpacity: 0.3,
      strokeWeight: 1,
      fillColor: '#0000ff',
      fillOpacity: 0.1,
      position: new MapLatLng(zona.Lat, zona.Lng),
      radius: zona.Radio,
      draggable: false,
      editable: false
    });

    const center = new MapLatLng(zona.Lat, zona.Lng);
    const swPoint = GeoUtils.getNewLatLng(center, -this.circle.radius, -this.circle.radius);
    const nePoint = GeoUtils.getNewLatLng(center, this.circle.radius, this.circle.radius);

    return { center, swPoint, nePoint };
  }

  procesarPoligono() {
    if (!this.alarmaEdit.Zona.Geometria) return;
    let swPoint = new MapLatLng(180, 90);
    let nePoint = new MapLatLng(-180, -90);
    this.polygons = this.map.addPolygonsFromGeoJson(this.alarmaEdit.Zona.Geometria, false);

    this.polygons.forEach(polygon => {
      polygon.points.forEach(punto => {
        nePoint.lat = Math.max(nePoint.lat, punto.point.lat);
        nePoint.lng = Math.max(nePoint.lng, punto.point.lng);
        swPoint.lat = Math.min(swPoint.lat, punto.point.lat);
        swPoint.lng = Math.min(swPoint.lng, punto.point.lng);
      });
    });
    return { center: null, swPoint, nePoint }; // Polígono no tiene un único centro
  }

  subscribeZona() {
    if (!this.subcribe) {
      this.subcribe = this.zonesService
        .getZona()
        .subscribe((zona: ZonaModel) => this.onSelectZona(zona));
    }
  }

  //asigna el objeto zona
  onSelectZona(zona: ZonaModel) {
    if (zona) {
      this.zona = zona;
    }
    if (!this.zona) { // si no recupera ninguna zona de subsribeZona, se asigna la zona de la alarma que estamos editando o creando
      this.zona = this.alarmaEdit.Zona;
    }
  }

  onSelectUser(usuario: UsuarioModel) {
    if (usuario) {
      this.usuario = usuario;
    }
  }

  async selectZonaEdit() {
    //obtiene todas las zonas
    this.zonas = await this.zonesService.getZonas();
    this.onSelectZona(this.zona); //recoge la zona creada
    if (this.zona) { //si existe la zona, se asigna y si no existe aparece la primera opcion
      this.alarmaEdit.Zona = this.zona;
    } else {
      this.cbZonas.selectedIndex(0);
    }
    this.cbZonas.clear();
    if (this.zonas) {
      // Añadir la opción vacía al inicio
      this.cbZonas.addItem({ label: 'Seleccione una zona', value: null });

      // Recorrer y añadir las zonas disponibles
      this.zonas.forEach((zona, index) => {
        this.cbZonas.addItem({ label: zona.Nombre, value: zona.Id });


        // Comprobar si la zona de alarmaEdit coincide con alguna y seleccionarla
        if (this.alarmaEdit.Zona && this.alarmaEdit.Zona.Id === zona.Id) {
          this.cbZonas.selectedIndex(index + 1); // Se suma 1 por la opción vacía
        }
      });

      // Si no hay zona seleccionada, seleccionar la opción vacía
      if (this.alarmaEdit.Zona) {
        // si tiene zona y no coincide con ninguna, selecciona la primera
        let zonaBorrada = this.zonas.find(zona => zona.Id === this.alarmaEdit.Zona.Id);
        if (!zonaBorrada) {
          this.cbZonas.selectedIndex(0); // Seleccionar la primera opción
        }
      } else {
        this.cbZonas.selectedIndex(0); // Seleccionar la primera opción
      }
    }
    this.ebNombre.focus();
  }

  //cuando se expande al ventana editZone, carga todos los datos
  onExpandEdit(event) {
    if (event) {
      //this.subscribeZona();
      this.selectZonaEdit()
    }
  }

  async onOpenEdit() {
    await this.ensureWindowLoaded();
    if (!this.zona) {
      this.subscribeZona();
    }
    this.selectZonaEdit();
  }

  async getUsuarios() {
    this.usuarios = await this.alarmsService.getUsersByEmprApp();
    if (this.usuarios) {
      let dataSourceUser = {
        datatype: 'json',
        datafields: [
          { name: 'Id', type: 'string', map: 'Id' },
          { name: 'Nombre', type: 'string', map: 'Nombre' },
        ],
        localdata: this.usuarios,
      };
      this.usuarioAdapter = new jqx.dataAdapter(dataSourceUser, {
        beforeLoadComplete: (records: any[]): void => {
          if (records) {
            records.sort((a, b) => (a.Nombre < b.Nombre ? -1 : 1));
            records.splice(0, 0, 'Seleccionar Todo');
          }
        },
      });
    }
  }

  onChangeZona(event: any) {
    if (!event.args.item.value) {
      this.alarmaEdit.Zona = null;
      this.tabs.disableAt(1);
      return;
    }

    if (this.zonas) {
      this.zonas.forEach(zona => {
        if (zona.Id == event.args.item?.value) {
          this.alarmaEdit.Zona = zona;
          this.tabs.enableAt(1);
        }
      });
    }
  }

  onChangeUser(event): void {
    // añado al campo email la propiedad emial del usuario seleccionados del selector de usuarios
    if (event.args.checked) {
      if (event.args.item.value === 'Seleccionar Todo') {
        this.sUsuarios.checkAll();
        // concateno los emails de los usuarios seleccionados
        if (this.alarmaEdit.Emails !== null) {
          this.alarmaEdit.Emails = '';
          this.usuarios.forEach(usuario => {
            if (usuario.Email) {
              this.alarmaEdit.Emails = this.alarmaEdit.Emails + usuario.Email + ';';
            }
          });
        }
      } else {
        this.usuario = this.usuarios.find(usuario => usuario.Id == event.args.value);
        // concateno los emails de los usuarios seleccionados
        if (this.alarmaEdit.Emails) {
          this.alarmaEdit.Emails = this.alarmaEdit.Emails + this.usuario.Email + ';';
        } else {
          this.alarmaEdit.Emails = this.usuario.Email + ';';
        }
      }
    } else {
      if (event.args.item.value === 'Seleccionar Todo') {
        this.sUsuarios.clearSelection();
        this.sUsuarios.uncheckAll();
        this.alarmaEdit.Emails = '';
        this.emails.val('');
      } else {
        this.usuario = this.usuarios.find(usuario => usuario.Id == event.args.value);
        // elimino el email del usuario deseleccionado
        this.alarmaEdit.Emails = this.alarmaEdit.Emails.replace(this.usuario.Email + ';', '');
        if (this.alarmaEdit.Emails === '') {
          this.emails.val('');
        }
      }
    }
  }

  onChangeCtrlEntrada(event: any) {
    if (this.alarmaEdit.Entrada) {
      this.horaEntrada.disabled(false);
      if (this.horaEntrada.getDate() === null) {
        this.alarmaEdit.HoraEntrada = new Date();
      }
      this.alarmaEdit.HayHoraEntrada = true;
    } else {
      this.horaEntrada.disabled(true);
      this.alarmaEdit.HoraEntrada = null;
      this.alarmaEdit.HayHoraEntrada = false;
    }
  }

  onChangeCtrlSalida(event: any) {
    if (this.alarmaEdit.Salida) {
      this.horaSalida.disabled(false);
      if (this.horaSalida.getDate() === null) {
        this.alarmaEdit.HoraSalida = new Date();
      }
      this.alarmaEdit.HayHoraSalida = true;
    } else {
      this.horaSalida.disabled(true);
      this.alarmaEdit.HoraSalida = null;
      this.alarmaEdit.HayHoraSalida = false;
    }
  }

  onChangeRepetir(event: any) {
    if (!this.alarmaEdit.Repetir) {
      this.dias.lunes = false;
      this.dias.martes = false;
      this.dias.miercoles = false;
      this.dias.jueves = false;
      this.dias.viernes = false;
      this.dias.sabado = false;
      this.dias.domingo = false;
      this.alarmaEdit.Dias = '';
    } else {
      if (this.alarmaEdit.Dias === null || this.alarmaEdit.Dias.length < 1) {
        this.dias.lunes = true;
        this.dias.martes = true;
        this.dias.miercoles = true;
        this.dias.jueves = true;
        this.dias.viernes = true;
        this.dias.sabado = true;
        this.dias.domingo = true;
        this.alarmaEdit.Dias = 'LMXJVSD';
      }
    }
  }

  onCloseEdit() {
    this.mostrarEditForm = false;
    this.form.enable();
    this.form.expand();
    this.zona = null;
    this.alarmaEdit = new AlarmModel();
  }

  isJqxWindowsComponent(obj) {
    return obj instanceof jqxWindowComponent;
  }

  calculatePolygonBounds(geoJson) {
    let arrLat = [];
    let arrLng = [];
    let arrayCoordenadas = geoJson.geometry.coordinates;

    arrayCoordenadas.forEach(polygon => {
      polygon[0].forEach(coord => {
        arrLat.push(coord[1]);
        arrLng.push(coord[0]);
      });
    });

    let swPoint = new MapLatLng(Math.min(...arrLat), Math.min(...arrLng));
    let nePoint = new MapLatLng(Math.max(...arrLat), Math.max(...arrLng));

    return { swPoint, nePoint };
  }

  encuadrarZonasSelec(zonas, map) {
    let globalSWPoint = new MapLatLng(180, 90);
    let globalNEPoint = new MapLatLng(-180, -90);

    zonas.forEach(zona => {
      let swPoint, nePoint;

      if (zona.TipoGeo === 0) { // Si es un círculo
        let center = new MapLatLng(zona.Lat, zona.Lng);
        swPoint = GeoUtils.getNewLatLng(center, -zona.Radio, -zona.Radio);
        nePoint = GeoUtils.getNewLatLng(center, zona.Radio, zona.Radio);
      } else { // Si es un polígono
        let bounds = this.calculatePolygonBounds(zona.Geometria); // Obtener límites
        swPoint = bounds.swPoint;
        nePoint = bounds.nePoint;
      }

      globalSWPoint.lat = Math.min(globalSWPoint.lat, swPoint.lat);
      globalSWPoint.lng = Math.min(globalSWPoint.lng, swPoint.lng);
      globalNEPoint.lat = Math.max(globalNEPoint.lat, nePoint.lat);
      globalNEPoint.lng = Math.max(globalNEPoint.lng, nePoint.lng);
    });

    map.fitTo(new MapBounds(globalSWPoint, globalNEPoint));
  }

  centeredPolygons(geoJson) {
    let arrLat = [];
    let arrLng = [];
    let arrayCoordenadas = geoJson.geometry.coordinates;
    arrayCoordenadas.forEach(polygon => {
      polygon[0].forEach(coord => {
        arrLat.push(coord[1]);
        arrLng.push(coord[0]);
      });
    });
    //calcular máximos y minimos para centar ppolígono en el mapa
    let arrLatMax = Math.max(...arrLat);
    let arrLatMin = Math.min(...arrLat);
    let arrLngmax = Math.max(...arrLng);
    let arrLngMin = Math.min(...arrLng);
    let coordMax = new MapLatLng(arrLatMax, arrLngmax);
    let coordMin = new MapLatLng(arrLatMin, arrLngMin);
    let mapBounds = new MapBounds(coordMin, coordMax);
    this.map.fitTo(mapBounds);
  }

  drawZone() {
    let zona = this.alarmaEdit.Zona;

    if (zona.TipoGeo === 0) { // Círculo
      this.circle = this.map.addCircle({
        dataModel: zona,
        content: zona.Nombre,
        strokeColor: '#ff0000',
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: '#0000ff',
        fillOpacity: 0.1,
        position: new MapLatLng(zona.Lat, zona.Lng),
        radius: zona.Radio,
        draggable: false,
        editable: false
      });

      this.map.setCenter(new MapLatLng(zona.Lat, zona.Lng));
      this.encuadrarZonasSelec([zona], this.map);

    } else { // Polígono
      if (zona.Geometria) {
        this.polygons = this.map.addPolygonsFromGeoJson(zona.Geometria, false);
        this.centeredPolygons(zona.Geometria);
      }
    }
  }

  deleteZones() {
    if (this.circle) {
      this.map.removeCircle(this.circle);
      this.circle = null;
    }

    if (this.polygons) {
      this.polygons.forEach(polygon => this.map.removePolygon(polygon));
      this.polygons = null;
    }
  }

  onVerZona(event) {
    this.deleteZones();
    if (this.alarmaEdit.Zona) {
      let zonaArray = [this.alarmaEdit.Zona];
      this.encuadrarZonasSelec(zonaArray, this.map);
      this.drawZone();
      MainComponent.getInstance().listComponentsMenu.forEach((component: any) => {
        let hasJqxWindowsComponent: any = Object.values(component.instance).find(this.isJqxWindowsComponent);
        if (hasJqxWindowsComponent) {
          hasJqxWindowsComponent.collapse();
        }
      });
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onGuardarAlarma(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.alarmaEdit.Zona === null) {
      MainComponent.getInstance().showError('ATENCION', 'Selecciona_zona', 2000);
      return;
    }
    if (this.alarmaEdit.Nombre === null || this.alarmaEdit.Nombre.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return;
    }
    if (!this.alarmaEdit.Entrada && !this.alarmaEdit.Salida) {
      MainComponent.getInstance().showError('ATENCION', 'Indique_alarma_entrada_salida', 2000);
      return;
    }
    this.alarmaEdit.Dias = '';
    this.alarmaEdit.Dias += this.dias.lunes ? 'L' : '';
    this.alarmaEdit.Dias += this.dias.martes ? 'M' : '';
    this.alarmaEdit.Dias += this.dias.miercoles ? 'X' : '';
    this.alarmaEdit.Dias += this.dias.jueves ? 'J' : '';
    this.alarmaEdit.Dias += this.dias.viernes ? 'V' : '';
    this.alarmaEdit.Dias += this.dias.sabado ? 'S' : '';
    this.alarmaEdit.Dias += this.dias.domingo ? 'D' : '';
    if (this.alarmaEdit.Repetir && this.alarmaEdit.Dias.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Indique_dias_semana', 2000);
      return;
    }
    if (!this.alarmaEdit.HayHoraEntrada) {
      this.alarmaEdit.HoraEntrada = new Date('0001-01-01T00:00:00');
    }
    if (!this.alarmaEdit.HayHoraSalida) {
      this.alarmaEdit.HoraSalida = new Date('0001-01-01T00:00:00');
    }
    this.saveAlarma();
  }

  // Guardo la alarma
  async saveAlarma() {
    const response = await this.alarmsService.saveAlarma(this.alarmaEdit);
    if (response) {
      if (this.alarmaEdit.Id === 0) {
        response.numeroAlarmaAsig = 0;
        this.alarmas.push(response);
      } else {
        for (let i = 0; i < this.alarmas.length; i++) {
          if (this.alarmas[i].Id === this.alarmaEdit.Id) {
            this.alarmas[i] = { ...response };
            break;
          }
        }
      }
      this.alarmaEdit = { ...response };
      this.gridAlarmas.updatebounddata('data');
      this.formEdit.close();
      MainComponent.getInstance().showInfo('ATENCION', 'Registro_almacenado', 2000);
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
    }
  }

  selectionUserRenderer = (): string => {
    if (this.sUsuarios) {
      let checkedItems = this.sUsuarios.getCheckedItems();
      let items = this.sUsuarios.getItems();
      if (items.length == checkedItems.length) {
        return `<div style="position: relative; margin-left: 3px; margin-top: 5px;">Seleccionar Todo</div>`;
      } else if (checkedItems.length > 0) {
        return checkedItems.map(item => item.label).join(', ');
      } else {
        return `<div style="position: relative; margin-left: 3px; margin-top: 5px;">Selecciona Usuario</div>`;
      }
    } else {
      return `<div style="position: relative; margin-left: 3px; margin-top: 5px;">Selecciona Usuario</div>`;
    }
  };

  updateListaDisponibles = async (): Promise<void> => {
    try {
      this.moviles = await this.resourcesService.getMoviles();

      if (this.alarmaEdit?.Id > 0) {
        const result = await this.alarmsService.getAlarmaMoviles(this.alarmaEdit.Id);
        const movilesAsignados = new Map<number, MovilModel>();
        this.movilesAsig = result?.map(movilId => {
          const movil = this.resourcesService.getMovil(movilId);
          movil['conjVehClase'] = movil.ConjuntoVehiculo.Clases.map(clase => clase.Nombre).join(', ');
          movilesAsignados.set(movil.Codigo, movil);
          return movil;
        }) || [];

        this.movilesAlarmasGeoService.updateMovilesAsignados(this.movilesAsig);

        const movilesSinAsignar = this.moviles?.filter(movil => !movilesAsignados.has(movil.Codigo)).map(movil => {
          movil['conjVehClase'] = movil.ConjuntoVehiculo.Clases.map(clase => clase.Nombre).join(', ');
          return movil;
        }) || [];

        this.movilesAlarmasGeoService.updateMovilesSinAsignar(movilesSinAsignar);
      } else {
        const movilesDisponibles = this.moviles?.map(movil => {
          movil['conjVehClase'] = movil.ConjuntoVehiculo.Clases.map(clase => clase.Nombre).join(', ');
          return movil;
        }) || [];
        this.movilesAlarmasGeoService.updateMovilesSinAsignar(movilesDisponibles);
        this.movilesAlarmasGeoService.updateMovilesAsignados([]);
      }
    } catch (error) {
      console.error('Error actualizando listas de móviles:', error);
    }
  }

  onMasClick() {
    if (!this.canEdit) {
      return;
    }
    const moviles = new Map<number, number>();
    const rowsSelec = this.gridMoviles1.getselectedrowindexes();
    if (rowsSelec.length > 0) {
      rowsSelec.forEach(rowIndex => {
        this.movilesAsig.push(this.moviles[rowIndex]);
        moviles.set(this.moviles[rowIndex].Codigo, this.moviles[rowIndex].Codigo);
      });
      for (let i = this.moviles.length - 1; i >= 0; i--) {
        if (moviles.get(this.moviles[i].Codigo)) {
          this.moviles.splice(i, 1);
        }
      };
      this.gridMoviles1.clearselection()
      this.updateGridSource(this.gridMoviles1, this.moviles);
      this.updateGridSource(this.gridMoviles2, this.movilesAsig);
    }
  }

  onMenosClick() {
    if (!this.canEdit) {
      return;
    }
    const moviles = new Map<number, number>();
    const rowsSelec = this.gridMoviles2.getselectedrowindexes();
    if (rowsSelec.length > 0) {
      rowsSelec.forEach(rowIndex => {
        this.moviles.push(this.movilesAsig[rowIndex]);
        moviles.set(this.movilesAsig[rowIndex].Codigo, this.movilesAsig[rowIndex].Codigo);
      });
      for (let i = this.movilesAsig.length - 1; i >= 0; i--) {
        if (moviles.get(this.movilesAsig[i].Codigo)) {
          this.movilesAsig.splice(i, 1);
        }
      };
      this.gridMoviles2.clearselection()
      this.updateGridSource(this.gridMoviles1, this.moviles);
      this.updateGridSource(this.gridMoviles2, this.movilesAsig);
    }
  }

  updateTotalTitle() {
    this.totalDisponibles.set(this.gridMoviles1.getrows().length);
    this.totalAsignadas.set(this.gridMoviles2.getrows().length);
  }

  async onGuardarAsig() {
    // Almaceno los móviles asignados
    let movilesId: number[] = [];
    if (this.movilesAsig.length) {
      this.movilesAsig.forEach(movil => {
        movilesId.push(movil.Codigo);
      });
    }
    const result = await this.alarmsService.saveMovilesAlarma(this.alarmaEdit.Id, movilesId);

    if (result) {
      MainComponent.getInstance().showSuccess('ATENCION', 'Registro_almacenado', 2000);
      this.alarmaEdit.numeroAlarmaAsig = this.movilesAsig.length;
      this.gridAlarmas.updatebounddata('data');
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
    }
  }

  onChangeAreasZonas(event) {
    this.formEdit.collapse();
    this.createComponentZones()
  }

  createComponentZones() {
    this.component = this.menuContainer.createComponent(ZonesComponent);
    this.component.instance.init(this.component, false, this.formEdit, this.formZonas);
  }

  async eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.alarmas = await this.alarmsService.getAlarmas();
    this.dataSource.localdata = this.alarmas;
    this.gridAlarmas.updatebounddata('data');
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  rowdetailstemplate = (index: any): any => {
    var details = {
      rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
      rowdetailshidden: true,
    };
    return details;
  };

  initRowDetails = async (index: any,
    parentElement: any,
    gridElement: any,
    datarecord: any): Promise<void> => {
    if (parentElement && datarecord) {
      const movilesAsig = new Map<number, number>();
      let movilesAsignadosList: any = []
      let nestedGridContainer = parentElement.children[0];
      const result = await this.alarmsService.getAlarmaMoviles(datarecord.id);
      if (result) {
        result.forEach(movilId => {
          movilesAsig.set(movilId, movilId)
          movilesAsignadosList.push(this.resourcesService.getMovil(movilId));
        });

        if (this.alarmaEdit.Id) {
          // Actualizo la lista de móviles asignados
          let sourceMovilesAsig = {
            datatype: 'json',
            datafields: [
              { name: 'matricula', map: 'Matricula' },
              { name: 'descripcion', map: 'ConjuntoVehiculo>Recurso>Descripcion' },
              { name: 'subflota', map: 'Subflota>Nombre' },
              { name: 'recurso', map: 'ConjuntoVehiculo>Recurso>Nombre' },
              { name: 'clase', map: 'conjVehClase' }
            ],
            localdata: movilesAsignadosList,
          };

          let dataAdapterMovilesAsig = new jqx.dataAdapter(sourceMovilesAsig);

          if (nestedGridContainer != null) {
            let setting = {
              width: '97%',
              height: 180,
              showstatusbar: true,
              showaggregates: true,
              source: dataAdapterMovilesAsig,
              theme: this.environment.tema,
              columns: this.columnsMoviles2,
              localization: this.langGrid,
              editable: false,
              columnsresize: true,
              filterable: true,
            };

            let gridDetail = jqwidgets.createInstance(
              `#${nestedGridContainer.id}`,
              'jqxGrid',
              setting
            );

            setTimeout(() => {
              this.resizeGridDetails(gridDetail)
              gridDetail.sortby('matricula', 'asc');
            }, 100);
          }
        }
      }
    }
  }

  resizeGridDetails(grid: jqxGridComponent) {
    if (grid) {
      grid.setOptions({
        columnsheight: 20,
        statusbarheight: 20,
        rowsheight: 22,
        columnsresize: true,
      });
    }
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.alarmas.forEach(alarm => {
      if (
        (alarm?.Nombre + '').compareWith(filtervalue) ||
        (alarm?.Zona?.Nombre + '').compareWith(filtervalue) ||
        (NumberUtils.format(alarm?.Zona?.Radio, 0) + '').compareWith(filtervalue) ||
        (NumberUtils.format(alarm?.Zona?.Area, 0) + '').compareWith(filtervalue) ||
        (alarm?.Dias + '').compareWith(filtervalue) ||
        (DateUtils.formatTime(alarm?.HoraEntrada) + '').compareWith(filtervalue) ||
        (DateUtils.formatTime(alarm?.HoraSalida) + '').compareWith(filtervalue)
      ) {
        alarm['selec'] = 'selec';
      } else {
        alarm['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.gridAlarmas.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.gridAlarmas.addfilter('selec', filtergroup);
    }
    this.gridAlarmas.applyfilters();
    this.gridAlarmas.updatebounddata('data');

    this.form.setTitle(`${AppComponent.translate('Alarmas_geograficas')} (${this.gridAlarmas?.getrows().length})`);
  }

  // Función para acceder a propiedades anidadas de un objeto
  getNestedProperty<T>(obj: any, propPath: string): T {
    const props = propPath.split('.');
    return props.reduce((acc, curr) => acc && acc[curr], obj);
  }
  private gridsInitialized = false;

  private initializeGridSubscriptions(): void {
    this.movilesAlarmasGeoService.movilesSinAsignar$
      .subscribe(moviles => {
        if (this.gridMoviles1) {
          this.updateGridSource(this.gridMoviles1, moviles);
        }
      });

    this.movilesAlarmasGeoService.movilesAsignados$
      .subscribe(moviles => {
        if (this.gridMoviles2) {
          this.updateGridSource(this.gridMoviles2, moviles);
        }
      });
  }


  async onTabClick(event): Promise<void> {
    if (event.args.item === 1) {
      if (!this.gridsInitialized) {
        this.initializeGridSubscriptions();
        this.gridsInitialized = true;
      }

      await this.updateListaDisponibles();
      this.gridMoviles1.sortby('matricula', 'asc');
      this.gridMoviles2.sortby('matricula', 'asc');
    } else {
      return;
    }
  }

  onExportar() {
    if (this.gridAlarmas.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    }
    const json = JSON.parse(JSON.stringify(this.gridAlarmas.getdisplayrows()));

    json.forEach(row => {
      delete row.id;
      delete row.uid;
      delete row.uniqueid;
      delete row.visibleindex;
      delete row.boundindex;

      row[AppComponent.translate('Hora_entrada')] =
        row[AppComponent.translate('Entrada')] ?
          new Date(row[AppComponent.translate('Hora_entrada')]).toLocaleTimeString() :
          '';

      row[AppComponent.translate('Hora_salida')] =
        row[AppComponent.translate('Salida')] ?
          new Date(row[AppComponent.translate('Hora_salida')]).toLocaleTimeString() :
          '';

      row[AppComponent.translate('Entrada')] = row[AppComponent.translate('Entrada')] ? AppComponent.translate('SI') : AppComponent.translate('NO');
      row[AppComponent.translate('Salida')] = row[AppComponent.translate('Salida')] ? AppComponent.translate('SI') : AppComponent.translate('NO');
    });

    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    this.generateAutofilterHeader(ws);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Alarmas_geograficas') + '.xlsx');
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Boton para imprimir
  onPrint() {
    if (this.gridAlarmas.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    }

    let rows = this.gridAlarmas.getrows();

    rows.forEach(row => {
      delete row.id;
      delete row.uid;
      delete row.uniqueid;
      delete row.visibleindex;
      delete row.boundindex;

      row[AppComponent.translate('Entrada')] =
        row[AppComponent.translate('Entrada')] ?
          AppComponent.translate('SI') :
          AppComponent.translate('NO');

      row[AppComponent.translate('Salida')] =
        row[AppComponent.translate('Salida')] ?
          AppComponent.translate('SI') :
          AppComponent.translate('NO');
    });

    let gridContent = this.gridAlarmas.exportdata('html', null, true, rows, false, null, 'utf-8');

    let newWindow = window.open('', '', 'width=800, height=500'),
      document = newWindow.document.open(),
      pageContent =
        '<!DOCTYPE html>\n' +
        '<html>\n' +
        '<head>\n' +
        '<meta charset="utf-8" />\n' +
        '<title>' + AppComponent.translate('Alarmas_geograficas') + '</title>\n' +
        '</head>\n' +
        '<body>\n' +
        gridContent +
        '\n</body>\n</html>';
    document.write(pageContent);
    document.close();
    newWindow.onafterprint = function () {
      newWindow.close();
    };
    newWindow.print();
  }
}
