import {
  OnInit,
  Component,
  ViewChild,
  ChangeDetectorRef,
  AfterContentChecked,
} from '@angular/core';

import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { environment } from 'src/environments/environment';
import { HeaderComponent } from '../header/header.component';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { PersonasService } from 'src/app/services/personas/personas.service';

import { PersonaModel } from 'src/app/services/personas/models/persona.model';

@Component({
  selector: 'app-personas',
  templateUrl: './personas.component.html',
  styleUrls: ['./personas.component.css']
})
export class PersonasComponent extends CustomForms implements OnInit, AfterContentChecked {
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('gridPeople') gridPersonas: jqxGridComponent;
  @ViewChild('windowPersonas') windowPersonas: jqxWindowComponent;

  environment = environment;
  private componentRef = null;
  public static _this: PersonasComponent;
  public langGrid = JqWidgets.getLocalization('es');

  protected sourceGridPersonas;
  protected sourceGridPersonasAdapter;

  protected columnsGroupPersonas;
  protected columnsGridPersonas: any = [];

  protected mapWidth: number;
  protected mapHeight: number;
  public showLoader: Boolean = false;

  private personas: PersonaModel[] = [];

  protected translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private personasService: PersonasService,
    private changeDetector: ChangeDetectorRef,
  ) {
    super();
    PersonasComponent._this = this;
  }

  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  ngOnInit(): void {
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.mapHeight = document.getElementById('map-container').offsetHeight;

    this.getPersonas();
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.windowPersonas);
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  initGridPersonas() {
    this.sourceGridPersonas = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },

        { name: 'icono', type: 'string',  },
        { name: 'codigo', type: 'string', map: 'guid' },
        { name: 'identificador', type: 'string', map: 'identificador' },
        { name: 'nombre', type: 'string', map: 'nombre' },
        { name: 'apellidos', type: 'string', map: 'apellidos' },
        { name: 'correo', type: 'string', map: 'correo' },
        { name: 'telefono', type: 'string', map: 'telefono' },
        { name: 'nif', type: 'string', map: 'nif' },
        { name: 'tipo', type: 'string', map: 'categoria>tipo>nombre' },

        { name: 'gls', type: 'string', map: 'gls' },
        { name: 'periferico', type: 'string', map: 'periferico' },
        { name: 'sensor', type: 'string', map: 'sensor' },

        { name: 'selec', type: 'string', map: 'selec' },
      ],
      localdata: this.personas
    };
    this.sourceGridPersonasAdapter = new jqx.dataAdapter(this.sourceGridPersonas);

    this.columnsGroupPersonas = [
      { text: AppComponent.translate('Informacion'), align: 'center', name: 'informacionGroup' },
      { text: AppComponent.translate('Resumen'), align: 'center', name: 'resumenGroup' },
    ]

    this.columnsGridPersonas = [
      { datafield: 'id', hidden: true },
      { datafield: 'selec', hidden: true },
      {
        text: '',
        width: 60,
        columntype: 'text',
        sortable: false,
        editable: false,
        groupable: false,
        menu: false,
        clickable: false,
        filterable: false,
        resizable: false,
        rendered: (columnHeaderElement) => {
          const buttonContainer = document.createElement('div');
          buttonContainer.style.width = '100%';
          buttonContainer.style.display = 'flex';
          buttonContainer.style.justifyContent = 'center';

          buttonContainer.id = `buttonContainerColumn_jqxButton`;
          columnHeaderElement[0].appendChild(buttonContainer);
          const btnCrear = document.createElement('div');
          btnCrear.innerHTML =
            `<button class="button" style="height: 20px; width: 30%; display: flex; justify-content: center; margin-top: 10px"
              title="${AppComponent.translate('Crear')}">
              <i class="fa-solid fa-plus"></i>
            </button>`;
          btnCrear.id = `buttonCrear_jqxButton`;
          btnCrear.style.width = '100%';
          btnCrear.style.height = '100%';
          btnCrear.style.display = 'flex';
          btnCrear.style.justifyContent = 'center';
          btnCrear.addEventListener('click', async (event: any) => {
          });
          buttonContainer.appendChild(btnCrear);
          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
      },
      { text: AppComponent.translate('Icono'), columntype: 'textbox', datafield: 'icono', columngroup: 'informacionGroup', width: 70 },
      { text: AppComponent.translate('CODIGO'), columntype: 'textbox', datafield: 'codigo', columngroup: 'informacionGroup', width: 270 },
      { text: AppComponent.translate('Identificador'), columntype: 'textbox', datafield: 'identificador', columngroup: 'informacionGroup', width: 60 },
      { text: AppComponent.translate('Nombre'), columntype: 'textbox', datafield: 'nombre', columngroup: 'informacionGroup', width: 70 },
      { text: AppComponent.translate('Apellidos'), columntype: 'textbox', datafield: 'apellidos', columngroup: 'informacionGroup', width: 70 },
      { text: AppComponent.translate('Correo_electronico'), columntype: 'textbox', datafield: 'correo', columngroup: 'informacionGroup', width: 80 },
      { text: AppComponent.translate('Telefono'), columntype: 'textbox', datafield: 'telefono', columngroup: 'informacionGroup', width: 80 },
      { text: AppComponent.translate('Dni'), columntype: 'textbox', datafield: 'nif', columngroup: 'informacionGroup', width: 80 },
      { text: AppComponent.translate('Tipo'), columntype: 'textbox', datafield: 'tipo', columngroup: 'informacionGroup', width: 80 },
    ]

    this.showLoader = false;
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  /* RENDERIZADOS COLUMNA ACCIONES Y TEXTOS GRID */
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';

    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.gridPersonas.getrowdata(row);
    }
    let persona = this.personas.find(persona => persona.id == rowdata.id);

    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'start';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    const btnEditar = document.createElement('div');
    btnEditar.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+ AppComponent.translate('Editar') + `">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;
    btnEditar.id = `buttonEdite_jqxButton`;
    btnContainer.appendChild(btnEditar);

    btnEditar.addEventListener('click', async (event: any) => {
    });

    const btnDelete = document.createElement('div');
    btnDelete.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; padding: 0; margin: 0; cursor: pointer !important;" title="`+ AppComponent.translate('Borrar') + `">
        <i class="fa-solid fa-trash"></i>
      </button>
    `;
    btnDelete.id = `buttonDelete_jqxButton`;
    btnContainer.appendChild(btnDelete);

    btnDelete.addEventListener('click', async (event: any) => {
    });

    htmlElement.appendChild(btnContainer);
  }

  async getPersonas() {
    this.showLoader = true;

    this.personasService.getPersonas().then(
      async (result) => {
        this.personas = result;
        this.initGridPersonas();
      }
    )
  }

  /* FUNCIONALIDAD CABECERA */
  async eventFilter() {
    /* this.header.searchInput['nativeElement'].value = '';
    this.getPersonas(); */
  }

  async eventResetFilter() {
    /* this.header.searchInput['nativeElement'].value = '';
    this.onBuscar(); */
  }

  onBuscar() {
    /* let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.edificios.forEach(zon => {
      if (
        zon?.Nombre?.compareWith(filtervalue) ||
        zon?.TipoBDTString?.compareWith(filtervalue) ||
        zon?.Domicilio?.compareWith(filtervalue) ||
        zon?.Poblacion?.compareWith(filtervalue) ||
        zon?.Provincia?.compareWith(filtervalue) ||
        (AppComponent.translate('Circulo').compareWith(filtervalue) && zon?.TipoGeo === 0) ||
        (AppComponent.translate('Poligono').compareWith(filtervalue) && zon?.TipoGeo === 1) ||
        (zon?.Radio + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (zon?.Area + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        zon?.Observaciones?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ) {
        zon['selec'] = 'selec';
      } else {
        zon['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.gridEdificios.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.gridEdificios.addfilter('selec', filtergroup);
    }
    this.gridEdificios.applyfilters();
    this.gridEdificios.updatebounddata('data'); */
  }

  onExportar() {
    if (this.gridPersonas.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.gridPersonas.exportdata('json');
      let datos = JSON.parse(json);
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);

      ws['!autofilter'] = { ref: ws['!ref'] };
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Personas') + '.xlsx');
    }
  }

  onPrint() {
    if (this.gridPersonas.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', AppComponent.translate('No_existen_datos'), 2000);
    } else {
      this.gridPersonas.hidecolumn('Botones');
      let gridContent = this.gridPersonas.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.gridPersonas.showcolumn('Botones');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }

  /* CIERRE DE LA VENTANA */
  closeWindow() {
    this.windowPersonas.close();
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    PersonasComponent._this = null;
  }
}
