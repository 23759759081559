import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { InformeMovilidadModel } from './models/informe-movilidad-model';
import { DateUtils } from 'src/app/utils/date-utils';
import { SsoService } from '../sso/sso.service';
import { SensorMovilidadModel } from './models/sensor-movilidad-model';
import { ResourcesService } from '../resources/resources.service';
import { EDigitalMovilidadModel } from './models/edigital-movilidad-model';

@Injectable({
  providedIn: 'root'
})
export class InformesService {

  constructor(private http: HttpClient,
    private ssoService: SsoService,
    private resorcesService: ResourcesService) { }

  async getInformeMovilidad(periodo: number, moviles: number[], fechaIni: Date, fechaFin: Date): Promise<any> {
    const informe: InformeMovilidadModel[] = [];
    let entradasDigitales: EDigitalMovilidadModel[] = [];
    let sensores: SensorMovilidadModel[] = [];
    const edMap = new Map<number, boolean>();
    const seMap = new Map<number, boolean>();
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      };
      const filtro = {
        moviles: moviles,
        fechaIni: DateUtils.formatDateTime(fechaIni, false),
        fechaFin: DateUtils.formatDateTime(fechaFin, false),
        periodo: periodo
      };
      const response = await this.http.post<InformeMovilidadModel[]>(this.ssoService.getTicket().UrlApi +
        '/api/movilidad', JSON.stringify(filtro), httpOptions).toPromise();
      if (response) {
        response.forEach(reg => {
          if (reg.fechaIA) {
            reg.Movil = this.resorcesService.moviles.get(reg.movil);
            informe.push(reg);
            // Añado la entrada digital a las columnas si no existe todavía
            if (reg.entradasDigitales) {
              const edAux = [];
              reg.entradasDigitales.forEach(ed => {
                if (!edMap.get(ed.entradaDigital)) {
                  edMap.set(ed.entradaDigital, true);
                  entradasDigitales.push(ed);
                }
                edAux[ed.entradaDigital] = ed;
              });
              reg.entradasDigitales = edAux;
              // Odeno las entradas por id
              entradasDigitales = entradasDigitales.sort((e1, e2) => {
                if (e1.entradaDigital > e2.entradaDigital) {
                  return 1;
                }
                if (e1.entradaDigital < e2.entradaDigital) {
                  return -1;
                }
                return 0;
              });
            }
            // Añado el sensor a las columnas si no existe todavía
            if (reg.sensores) {
              const seAux = [];
              reg.sensores.forEach(se => {
                if (!seMap.get(se.idSensor)) {
                  seMap.set(se.idSensor, true);
                  sensores.push(se);
                }
                seAux[se.idSensor] = se;
              });
              reg.sensores = seAux;
              // Odeno los sensores por id
              sensores = sensores.sort((s1, s2) => {
                if (s1.idSensor > s2.idSensor) {
                  return 1;
                }
                if (s1.idSensor < s2.idSensor) {
                  return -1;
                }
                return 0;
              });
            }
          }
        });
      }
    } catch (e) {
    }
    return {
      datos: informe,
      entradasDigitales: entradasDigitales,
      sensores: sensores
    }
  }

}
