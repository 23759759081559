<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form [jqxWindowAutoResize] [theme]="environment.tema" [position]="getFormPos(form, 24)" [zIndex]="900" [width]="mapWidth"
  [height]="mapHeight - 24" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" [title]="windowTitle()"
  (onClose)="onClose()">

  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Listado_configuración_ecoLock')}}
  </div>

  <div class="form" style="float:left; overflow-x: hidden;">
    <div style="float: left; width: 100%; height: 100%;">
      <div>
        <app-header #header [imprimir]="true" [exportar]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
          (eventExportar)="onExportar()" (eventBuscador)="onBuscar()" (eventFilter)="eventFilter()"
          (eventResetFilter)="onResetFilter($event)" (eventImprimir)="onPrint($event)"></app-header>
      </div>
      <div style="float:left; width: 100%; height: calc(100% - 80px);">
        <jqxGrid #gridConfiguraciones [theme]="environment.tema" [height]="'100%'" [width]="'100%'" [appCellClick]
          [enablebrowserselection]="true" [source]="adapterGridConfiguracion" [columns]="columnsGridConfiguracion"
          [showaggregates]="true" [columnsresize]="true" [localization]="langGrid" [filterable]="true"
          [selectionmode]="'singlerow'" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false"
          [sortable]="true" [altrows]="true" [filter]="filter" [showstatusbar]="true" [statusbarheight]="20"
          [rowsheight]="25" [columnsheight]="20" [groupable]="false" [editable]="false" [columnmenuopening]='columnmenuopening'
          [columngroups]="columngroups" (onRowdoubleclick)="onRowdoubleclick($event)" (onFilter)="onGridDataUpdated($event)"
          (onBindingcomplete)="onGridDataUpdated($event)">
        </jqxGrid>
      </div>
      <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
        [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
<div #formConfiguracion></div>
<div #asignarConfiguracion></div>
