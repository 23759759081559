import { AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import { Track } from './track';
import { CustomForms } from '../forms/custom-forms';
import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { MainComponent } from '../main/main.component';
import { environment } from 'src/environments/environment';

import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxNotificationComponent } from 'jqwidgets-ng/jqxnotification';

import { ConfigService } from 'src/app/services/config/config.service';
import { SensorsService } from 'src/app/services/sensors/sensors.service';
import { PositionService } from 'src/app/services/positions/position.service';
import { ResourcesService } from 'src/app/services/resources/resources.service';

import { MovilModel } from 'src/app/services/resources/models/movil.model';

import { MovilesComponent } from '../resources/moviles/moviles.component';
import { PeriodoSelectComponent } from '../periodo-select/periodo-select.component';
import { LeyendaMovilesComponent } from './leyenda-moviles/leyenda-moviles.component';
import { SensorFilterComponent } from '../sensor/sensor-filter/sensor-filter.component';
import { MapBounds, MapLatLng } from 'movisat-maps';

@Component({
  selector: 'app-tracks',
  templateUrl: './tracks.component.html',
  styleUrls: ['./tracks.component.css']
})
export class TracksComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('reproductor') reproductor: jqxWindowComponent;
  @ViewChild('periodoSelect') periodoSelect: PeriodoSelectComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('msgNotification', { static: false }) msgNotification: jqxNotificationComponent;
  @ViewChild('filterContainer', { read: ViewContainerRef }) filterContainer;
  @ViewChild('leyendaMoviles', { read: ViewContainerRef }) leyendaMoviles;

  private componentRef = null;
  public static instance: TracksComponent = null; // Para controlar que sólo se cree una instancia
  public static numInstances = 0; // Para controlar el número de reproductores abiertos
  public environment = environment;
  private fechaIni: Date;
  private fechaFin: Date;
  private movilesSelec: MovilModel[];
  private movilesVisibles: MovilModel[];
  private tracks: Track[] = [];
  public interval = 60; // 60% de velocidad de reproducción
  public infoVisible = false;
  public infoMessage = '';
  private sensoresSelec = new Map<number, number>();
  public leyendaMovilComponent: any;
  public subcribe: any = null;
  public lastPos: Date;

  constructor(
    private positionService: PositionService,
    private sensorService: SensorsService,
    private configService: ConfigService,
    private resourcesService: ResourcesService) {
    super();
    TracksComponent.instance = this;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.reproductor);
    this.reproductor.setTitle(AppComponent.translate('Recorridos'));
    // Posiciono el formulario
    const mapContainer = document.getElementById('center-container').getClientRects();
    this.reproductor.position({
      x: mapContainer[0].left + 2,
      y: mapContainer[0].top + 60
    });
    //me subcrivo y creo el componente.
    this.subcribeConfig();
    this.createComponenteLeyenda();
    const t = setTimeout(() => {
      clearTimeout(t);
      if(this.lastPos) {
        this.periodoSelect.desde = new Date(this.lastPos.getFullYear(), this.lastPos.getMonth(), this.lastPos.getDate(), 0, 0, 0);
        this.periodoSelect.dateForm.get('desde').setValue(this.periodoSelect.desde);

        this.periodoSelect.hasta = new Date(this.lastPos.getFullYear(), this.lastPos.getMonth(), this.lastPos.getDate(), 23, 59, 59);
        this.periodoSelect.dateForm.get('hasta').setValue(this.periodoSelect.hasta);
      }
    }, 500)
  }
  //me subcribo al evento y vuelvo a crear el componente de nuevo destruyendolo antes.
  subcribeConfig() {
    if (!this.subcribe) {
      this.subcribe = this.configService
        .getConfig().subscribe(() => {
          this.leyendaMovilComponent.instance.onClose();
          this.createComponenteLeyenda()
        });
    }
  }
  //creacion del componente leyenda-moviles
  createComponenteLeyenda() {
    this.leyendaMovilComponent = this.leyendaMoviles.createComponent(LeyendaMovilesComponent);
    this.leyendaMovilComponent.instance.init(this.leyendaMovilComponent);
  }

  // Cuando se cierra el reproductor
  onClose() {
    if (TracksComponent.numInstances > 0) {
      TracksComponent.numInstances--;
    }
    this.clearTracks();
    this.movilesVisibles.forEach(movil => {
      if (movil.marker) {
        movil.marker.setVisible(true);
      }
    });
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.leyendaMovilComponent) {
      this.leyendaMovilComponent.instance.onClose();
    }

    if (this.subcribe) {
      this.subcribe.unsubscribe();
      this.subcribe = null;

    }
  }

  // Inicialización del componente, recibe los móviles seleccionados
  async init(componentRef: any, movilesSelec: MovilModel[], movilesVisibles: MovilModel[]) {
    TracksComponent.numInstances++;
    this.componentRef = componentRef;
    this.movilesSelec = movilesSelec;
    this.movilesVisibles = movilesVisibles;

    this.movilesSelec.forEach(movil => {
      if(movil.ultimaPos) {
        this.lastPos = this.lastPos === undefined ? movil.ultimaPos.Fecha :
          this.lastPos < movil.ultimaPos.Fecha ? movil.ultimaPos.Fecha :
          this.lastPos;
      }

      if (movil.marker) {
        movil.marker.setVisible(false);
      }
    });
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Muestro el filtro de sensores
  onClickSensores() {
    const component = this.filterContainer.createComponent(SensorFilterComponent);
    component.instance.init(component, this.movilesSelec[0]);
  }

  // Reproducir el recorrido
  async onPlay(step2step: boolean): Promise<void> {
    let swPoint = new MapLatLng(180, 90);
    let nePoint = new MapLatLng(-180, -90);
    this.fechaIni = this.periodoSelect.getFechaIni();
    this.fechaFin = this.periodoSelect.getFechaFin();
    this.clearTracks();
    if (this.fechaIni <= this.fechaFin) {
      // Recupero el filtro de sensores para saber cuales tengo que pintar
      this.sensoresSelec.clear();
      let sensoresFiltro: any = await this.configService.getUsuEmpApp('sensores-filter', null);
      if (sensoresFiltro) {
        sensoresFiltro = JSON.parse(sensoresFiltro);
        sensoresFiltro.forEach(sensor => {
          this.sensoresSelec.set(sensor.id, sensor.id);
        });
      }
      let i = 0;
      //recupero la lista de manera dinamicamente al seleccionar un elemento movil en en el grid de la pantalla principal
      this.movilesSelec = MovilesComponent.getInstance().getListMoviles();
      if (!this.movilesSelec) {
        return
      }
      // Recupero las posiciones de cada uno de los móviles
      let recorrido = new Promise((resolve, reject) =>this.movilesSelec.forEach(async (movil, index, array) => {
        this.loader.open();
        const posiciones = await this.positionService.getPositions(movil.Codigo, this.fechaIni, this.fechaFin);
        const sensores = await this.sensorService.getSensoresMovil(movil.Codigo, this.fechaIni, this.fechaFin);
        if (posiciones && posiciones.length > 0) {
          if(!MainComponent.getInstance().marcoGeografico.marco.contains(new MapLatLng(posiciones[0].Lat, posiciones[0].Lng))){
            MainComponent.getInstance().showError('ATENCION', 'Fuera_marco', 3000, true, ' ' + movil.Nombre);
          }else {
            if(this.movilesSelec.length > 1) {
              posiciones.forEach(posicion => {
                nePoint.lat = Math.max(nePoint.lat, posicion.Lat);
                nePoint.lng = Math.max(nePoint.lng, posicion.Lng);

                swPoint.lat = Math.min(swPoint.lat, posicion.Lat);
                swPoint.lng = Math.min(swPoint.lng, posicion.Lng);
              })
            }
            // Creo un objeto para reproducir el recorrido de cada uno de los móviles
            const track = new Track(posiciones, sensores, this.sensoresSelec, this.sensorService,
              this.resourcesService, this.configService);
            this.tracks.push(track);
            // Reproduzco el recorrido paso a paso o del tirón
            // Si hay más de un móvil asigno un color para cada uno, de lo contrario
            // los colores irán en función de lo configurado
            if (step2step) {
              track.setInterval(this.interval);
              track.playStep2Step(this.movilesSelec.length > 1 ? this.getColor(i++) : null);
            } else {
              track.playTotal(this.movilesSelec.length > 1 ? this.getColor(i++) : null);
            }
          }
        } else {
          MainComponent.getInstance().showInfo('ATENCION', 'No_posiciones_periodo_selec', 3000, ' ' + movil.Nombre);
        }

        const t = setTimeout(() => {
          clearTimeout(t);
          if ((array.length - 1) === index) {
            resolve('');
          }
        }, 100)
      }));

      recorrido.then(() => {
        MainComponent.getMap().fitTo(new MapBounds(swPoint, nePoint));
        MainComponent.getMap().setZoom(MainComponent.getMap().zoom - 1);
        this.loader.close();
      })
    }
  }

  // Devuelve un color para pintar el recorrido de cada vehículo
  getColor(index: number): string {
    let color = '#000000';
    switch (index) {
      case 0:
        color = '#ff0000'; // Rojo
        break;
      case 1:
        color = '#00ff00'; // Verde
        break;
      case 2:
        color = '#0000ff'; // Azul
        break;
      case 3:
        color = '#ffff00'; // Amarillo
        break;
      case 4:
        color = '#ff00ff'; // Fuchsia
    }
    return color;
  }

  // Borra todos los recorridos
  clearTracks(): void {
    this.tracks.forEach(track => {
      track.clear();
    });
    this.tracks = [];
  }

  // Para la reproducción paso a paso
  onStop(): void {
    this.tracks.forEach(track => {
      track.stop();
    });
  }

  // Pausa la reproducción paso a paso
  onPause(): void {
    this.tracks.forEach(track => {
      track.pause();
    });
  }

  // Modifica la velocidad de reproducción paso a paso
  onChangeSpeed(event: any): void {
    this.tracks.forEach(track => {
      this.interval = event.args.value;
      track.setInterval(this.interval);
    });
  }

}
