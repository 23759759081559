import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ElementsService } from 'src/app/services/elements/elements.service';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';

import { ElementsComponent } from '../elements.component';
import { ElementsEditComponent } from '../elements-edit/elements-edit.component';
import { ElementsCatalogComponent } from '../elements-catalog/elements-catalog.component';
import { RendererUtils } from 'src/app/utils/RendererUtils';

@Component({
  selector: 'app-elements-detail',
  templateUrl: './elements-detail.component.html',
  styleUrls: ['./elements-detail.component.css']
})
export class ElementsDetailComponent implements OnInit, AfterViewInit {
  @ViewChild('grid') grid: jqxGridComponent;

  private componentRef = null;
  public static _this: ElementsDetailComponent;
  public canEdit = true;
  public environment = environment;
  private equipamientoId: number;
  private elemtList: ElementoModel[] = [];

  // Subscripciones
  private subscriptionOnModifyElements: any = null;
  private subscriptionOnDeleteElements: any = null;
  private subscriptionOnEndLoadElements: any = null;
  private subscriptionOnNewElements: any = null;

  // Variables para el grid
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);


  createTooltip = (content: string) => {
    return (columnHeaderElement?: any) => {
      const jqElement = columnHeaderElement[0].parentElement;
      const options = {
        content: content,
        theme: environment.tema,
        width: 'auto',
        height: 'auto',
        position: 'mouse',
        autoHide: true,
      };
      jqwidgets.createInstance(jqElement, 'jqxTooltip', options);
    };
  };


  public columns: jqwidgets.GridColumn[] = [
    {
      text: '', width: 65, columntype: 'textbox', datafield: 'btn', menu: false, sortable: false, filterable: false, resizable: false,
      createwidget: (row: any, column: any, value: string, htmlElement: HTMLElement) => {
        this.setButtonColumn(row, column, value, htmlElement);
      },
      initwidget: (row: any, column: any, value: string, htmlElement: HTMLElement) => {
        this.setButtonColumn(row, column, value, htmlElement);
      },
    },
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 60, hidden: true },
    {
      text: AppComponent.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox',
      datafield: 'descripcion', width: 250, rendered: this.createTooltip(AppComponent.translate('Descripcion')),
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Elementos') + ': ' +
            aggregates["Total"] + ' </div>';
        }
        return renderstring;
      }, cellsrenderer: RendererUtils.rendexTextGeneric
    },
    { text: AppComponent.translate('Estado'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'estado', width: 100, rendered: this.createTooltip(AppComponent.translate('Estado')), cellsrenderer: RendererUtils.rendexTextGeneric },
    { text: AppComponent.translate('Informacion_geografica'), columntype: 'textbox', menu: false, datafield: 'infogeo', width: 300, rendered: this.createTooltip(AppComponent.translate('Informacion_geografica')), cellsrenderer: RendererUtils.rendexTextGeneric }
  ];

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(private ssoService: SsoService,
    private elementsService: ElementsService,
    private modal: NzModalService) {
    ElementsDetailComponent._this = this;
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, equipamientoId: number) {
    this.componentRef = componentRef;
    this.equipamientoId = equipamientoId;
    // Me subscribo a eventos
    this.subscribeOnEndLoadElements();
    this.subscribeOnNewElements();
    this.subscribeModifyElements();
    this.subscribeDeleteElements();
  }

  ngOnInit(): void {
    this.canEdit = true; // TODO: por hacer...
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  async ngAfterViewInit(): Promise<void> {
    this.elemtList = [];
    const totalElem = MainComponent.getElements();
    await totalElem.forEach(elem => {
      if (elem.IdEquipamiento === this.equipamientoId) {
        this.elemtList.push(elem);
      }
    });
    this.source = {
      datatype: 'json',
      datafields: [
        { name: 'btn', type: 'number', map: 'Id' },
        { name: 'id', type: 'number', map: 'Id' },
        { name: 'descripcion', type: 'string', map: 'Nombre' },
        { name: 'estado', type: 'string', map: 'estadoTexto' },
        { name: 'infogeo', type: 'string', map: 'Infogeo' }
      ],
      localdata: this.elemtList,
      // sortcolumn: 'descripcion',
      // sortdirection: 'asc'
    };
    this.dataAdapter = new jqx.dataAdapter(this.source);

    Utils.renderSizeGrid(this.grid, 500);
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'descripcion',
      'estado',
      'infogeo'
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  // Cuando se destruye el componente borro las subscripciones
  ngOnDestroy() {
    if (this.subscriptionOnModifyElements) {
      this.subscriptionOnModifyElements.unsubscribe();
    }
    if (this.subscriptionOnDeleteElements) {
      this.subscriptionOnDeleteElements.unsubscribe();
    }
    if (this.subscriptionOnNewElements) {
      this.subscriptionOnNewElements.unsubscribe();
    }
    if (this.subscriptionOnEndLoadElements) {
      this.subscriptionOnEndLoadElements.unsubscribe();
    }
  }

  // Cuando se edita un elemento
  onClickEdit(elemId: number) {
    let ele: ElementoModel;
    if ((ele = MainComponent.getElements().find(e => e.Id === elemId)) !== undefined) {
      ElementsCatalogComponent._this.form.collapse();

      let btnExpand = <HTMLElement>document.getElementById(`btnExpandElemEdit${ele?.Id}`);

      if (btnExpand) {
        btnExpand.click();
      } else {
        const component = ElementsComponent.getInstance().elementsContainer.createComponent(ElementsEditComponent);
        component.instance.init(component, ele);
      }
    }
  }

  onBindingComplete() {
    setTimeout(() => {
      try {
        this.grid.sortby('descripcion', 'asc');
      } catch (error) {
      }
    }, 100);
  }

  // Prepara la columna con los botones
  setButtonColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    const container = document.createElement('div');
    container.style.display = 'flex';
    container.style.gap = '2px';
    container.style.padding = '2px';
    container.style.height = '100%';
    container.style.justifyContent = 'start';
    container.style.alignItems = 'center';

    // Botón de editar
    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
      <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Editar') + `">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;
    btnEdit.addEventListener('click', (event) => {
      let index = 0;
      if (!isNaN(row.boundindex)) {
        index = row.boundindex;
      } else {
        index = row;
      }
      const data = this.grid.getrowdata(index);
      this.onClickEdit(data.id);
    });
    container.appendChild(btnEdit);
    // Botón de borrar
    const btnDelete = document.createElement('div');
    btnDelete.innerHTML = `
      <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Borrar') + `">
        <i class="fa-solid fa-trash"></i>
      </button>
    `;
    btnDelete.addEventListener('click', async (event: any) => {
      let index = 0;
      if (!isNaN(row.boundindex)) {
        index = row.boundindex;
      } else {
        index = row;
      }
      const data = this.grid.getrowdata(index);
      let elem: ElementoModel;
      if ((elem = MainComponent.getElements().find(e => e.Id === data.id)) !== undefined) {
        let result = await this.elementsService.getResiduoPendiente(elem.Id);
        if (result.id) {
          MainComponent.getInstance().showWarning('ATENCION', 'Elemento_con_residuo', 3000, ' ' + '[' + elem.Nombre + ']');
        } else {
          this.modal.confirm({
            nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
            nzContent: AppComponent.translate('Quiere_borrar_elemento') + ': ' + elem.Nombre + ' ?',
            nzCentered: true,
            nzCancelText: AppComponent.translate('CANCELAR'),
            nzOkText: AppComponent.translate('SI'),
            nzOnOk: async () => {
              await this.elementsService.deleteElemento(elem);
              MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
              this.refreshData();
            }
          });
        }
      }
    });
    // Añado los botones a la fila
    container.appendChild(btnDelete);
    htmlElement.appendChild(container);
  }

  // Cuando se modifican los datos de un elemento
  public refreshData() {
    const t = setTimeout(async () => {
      this.ngAfterViewInit();
    }, 1000);
  }

  // Cuando se ha terminado de descargar los elementos
  async subscribeOnEndLoadElements(): Promise<void> {
    this.subscriptionOnEndLoadElements = this.elementsService.endLoadElementsEmiter.subscribe(() => {
      this.refreshData();
    });
  }

  // Permite añadir un nuevo elemento al mapa y al grid
  async subscribeOnNewElements(): Promise<void> {
    this.subscriptionOnNewElements = this.elementsService.newElementEmiter.subscribe(elemento => {
      this.refreshData();
    });
  }

  // Permite saber que se han modificado elementos
  subscribeModifyElements(): void {
    this.subscriptionOnModifyElements = this.elementsService.modifyElementEmiter.subscribe(elemento => {
      this.refreshData();
    });
  }

  // Permite saber que se han borrado elementos
  subscribeDeleteElements(): void {
    this.subscriptionOnDeleteElements = this.elementsService.deleteElementEmiter.subscribe(elemento => {
      this.refreshData();
    });
  }

}
