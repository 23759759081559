import { ElemInstalacionDetalleModel } from "./elem-instalacion-detalle.model";
import { ElemInstalacionEstadoModel } from "./elem-instalacion-estado.model";
import { ElemInstalacionNuevaInstalacion } from "./elem-instalacion-nueva-instalacion.model";

export class ElemInstalacionModel {
  Detalle: ElemInstalacionDetalleModel[] = [];
  empresa: number = 0;
  estado: ElemInstalacionEstadoModel = new ElemInstalacionEstadoModel();
  fechaBaja: string = '';
  fechaCreacion: string = '';
  fechaModificacion: string = '';
  guid: string = '';
  id: number = 0;
  idElemento: number = 0;
  idEstado: number = 1; /* SinInstalar = 1, EnProgreso = 2, Finalizada = 3 */
  instalaciones: ElemInstalacionNuevaInstalacion[] = [];
  observaciones: string = '';
  requiereInstalacionCerradura: Boolean = false;
  requiereInstalacionIdentificador: Boolean = false;
  requiereInstalacionTag: Boolean = false;
  requiereInstalacionVolumetrico: Boolean = false;
}
