String.prototype.normaliceAccents = function () {
  if (this !== null && this !== undefined) {
    return this.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }
  return null;
};

String.prototype.compareWith = function (value) {
  if (typeof value === 'string' && typeof this === 'string') {
    return this.normaliceAccents().toUpperCase().indexOf(value.normaliceAccents().toUpperCase()) > -1;
  }
  return false;
};

String.prototype.equalsTo = function (value) {
  if (typeof value === 'string' && typeof this === 'string') {
    return this.normaliceAccents().toUpperCase() === value.normaliceAccents().toUpperCase();
  }
  return false;
};
