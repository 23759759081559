import { Utils } from 'src/app/utils/utils';
import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { DireccionModel } from 'src/app/services/ciudadanos/models/direccion.model';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { environment } from 'src/environments/environment';
import { AsociarTarjetasComponent } from '../asociar-tarjetas.component';
import { RendererUtils } from 'src/app/utils/RendererUtils';

@Component({
  selector: 'app-detalles-asociar-tarjetas',
  templateUrl: './detalles-asociar-tarjetas.component.html',
  styleUrls: ['./detalles-asociar-tarjetas.component.css']
})
export class DetallesAsociarTarjetasComponent implements OnInit {
  public static _this: DetallesAsociarTarjetasComponent;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  public direcciones: DireccionModel[] = [];

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() { }

  ngOnInit(): void {
    DetallesAsociarTarjetasComponent._this = this;
  }

  public init(direcciones: DireccionModel[]) {
    this.direcciones = direcciones
  }

  ngAfterViewInit() {
    this.initGridDetalle();
  }

  public columnsDirecciones;
  public sourceDirecciones;
  public dataAdapterDirecciones;

  initGridDetalle() {
    this.columnsDirecciones = [
      { text: 'Id', columntype: 'textbox', datafield: 'id', width: 80, hidden: true },
      { datafield: 'idCiudadano', hidden: true },
      { text: this.translate('Num_tarjetas'), columntype: 'textbox', datafield: 'numeroTarjetas', width: 80, cellsrenderer: RendererUtils.numberrenderer, },
      { text: this.translate('Num_abonado'), columntype: 'textbox', datafield: 'numeroAbonado', cellsrenderer: RendererUtils.rendexTextGeneric, },
      { text: this.translate('Numero_catastro'), columntype: 'textbox', datafield: 'numeroCatastro', cellsrenderer: RendererUtils.rendexTextGeneric, },
      { text: this.translate('Direccion_catastro'), columntype: 'textbox', datafield: 'direccionCatastro', cellsrenderer: RendererUtils.rendexTextGeneric, },
      { text: this.translate('Direccion'), columntype: 'textbox', datafield: 'direccion', cellsrenderer: RendererUtils.rendexTextGeneric, },
      { text: this.translate('Numero'), columntype: 'textbox', datafield: 'numero', cellsrenderer: RendererUtils.numberrenderer, },
      { text: this.translate('Municipio'), columntype: 'textbox', datafield: 'municipio', cellsrenderer: RendererUtils.rendexTextGeneric, },
      { text: this.translate('Provincia'), columntype: 'textbox', datafield: 'provincia', cellsrenderer: RendererUtils.rendexTextGeneric, },
    ];

    this.sourceDirecciones = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'numeroTarjetas', type: 'number', map: 'numeroTarjetas' },
        { name: 'numeroAbonado', type: 'number', map: 'numeroAbonado' },
        { name: 'numeroCatastro', type: 'string', map: 'catastro>referenciaCatastral' },
        { name: 'direccionCatastro', type: 'string', map: 'catastro>direccionCatastral' },
        { name: 'direccion', type: 'string', map: 'direccion' },
        { name: 'numero', type: 'number', map: 'numero' },
        { name: 'municipio', type: 'string', map: 'municipio' },
        { name: 'provincia', type: 'string', map: 'provincia' },
        { name: 'idCiudadano', map: 'idCiudadano' },
      ],
      localdata: this.direcciones,
    }

    this.dataAdapterDirecciones = new jqx.dataAdapter(this.sourceDirecciones);

    this.columnsDirecciones.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
  }

  rowClickAsociarTarjeta(event) {
    let ciudadano = AsociarTarjetasComponent._this.ciudadanos.find(ciud => ciud.id === event.args.row.bounddata.idCiudadano);
    let direccion = this.direcciones.find(ciud => ciud.id === event.args.row.bounddata.id);

    AsociarTarjetasComponent._this.onSelecDireccion(ciudadano, direccion);
  }
}
