import { PeriodoEjercicioAnualModel } from "./periodo-ejercicio-anual.model";

export class EjercicioAnualModel {
  Id: number = 0;
  empresa: number = 0;
  inicio: PeriodoEjercicioAnualModel = new PeriodoEjercicioAnualModel();
  fin: PeriodoEjercicioAnualModel = new PeriodoEjercicioAnualModel();

  constructor() { }
}
