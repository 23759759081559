import { NgModule } from '@angular/core';
import { LecVolumGraphComponent } from './volum/lec-volum-graph/lec-volum-graph.component';
import { CommonModule } from '@angular/common';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxChartModule } from 'jqwidgets-ng/jqxchart';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';

@NgModule({
  declarations: [
    LecVolumGraphComponent
  ],
  imports: [
    CommonModule,
    jqxWindowModule,
    jqxChartModule,
    jqxButtonModule,
  ]
})
export class GraphicsModule { }
