import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { PeriodoSelectModule } from '../periodo-select/periodo-select.module';
import { ModalComponent} from './modal/modal.component';
import { EditStateComponent } from './edit-state/edit-state.component';
import { NewIncidenceComponent } from './new-incidence/new-incidence.component';
import { StatusHistoryComponent } from './status-history/status-history.component';
import { GridIncidencesComponent } from './grid-incidences/grid-incidences.component';
import { HeaderModule } from '../header/header.module';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    ModalComponent,
    EditStateComponent,
    NewIncidenceComponent,
    StatusHistoryComponent,
    GridIncidencesComponent
  ],
  imports: [
    HeaderModule,
    CommonModule,
    NzIconModule,
    NzImageModule,
    PeriodoSelectModule,
    SharedModule
   
  ],
  exports: [NewIncidenceComponent]
})
export class IncidencesModule { }
