import { ConjuntoVehiculoCanbus } from "./conjunto-vehiculo-canbus.model";
import { ConjuntoVehiculoCombustible } from "./conjunto-vehiculo-combustible.model";
import { ConjuntoVehiculoModelo } from "./conjunto-vehiculo-modelo.model";

export class ConjuntoVehiculoChasis {
  id: number;
  codigo: string;
  descripcion: string;
  carga_maxima: number;
  ancho: number;
  tara: number;
  canBusMotor: ConjuntoVehiculoCanbus; // Tipo canbus
  vehiculoIndustrial: number;
  chasisCarrozado: string;
  anyoFabricacion: number;
  anyo_vida: number;
  precio_compra: number;
  anyo_amortizacion: number;
  mantenimiento_anual: number;
  suspension: string;
  o_bdii: number;
  localizacion_obdii: string;
  c_anbusfms: number;
  localizacion_canbusfms: string;
  c_anbus: number;
  localizacion_canbus: string;
  contador_horas: number;
  modelo_id: number;
  numPersonas: number;
  numOperarios: number;
  combustible: ConjuntoVehiculoCombustible;

  modelo: ConjuntoVehiculoModelo;
}
