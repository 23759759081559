<lib-loader [message]="translate('Cargando_datos')" [showLoader]="isLoading"></lib-loader>
x<jqxWindow #form jqxWindowAutoResize [theme]="environment.tema" [zIndex]="900" [title]="titleSensorsSignal()"
  [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()"
  (onCollapse)="form.collapse()">
  <div class="formHeader" style="overflow-x: hidden;"></div>
  <div class="form" style="height: 100%; overflow: hidden;">
    <div style="float: left; margin-top: 2px; height: calc(100% - 40px); width: 100%;">
      <jqxSplitter #mainSplitter [panels]="[{ min: '15%', size: '15%' }]" [height]="'100%'" [width]="'100%'">
        <div>
          <div style="height: 100%;">
            <app-moviles-select (passArray)="selected($event)"></app-moviles-select>
          </div>
        </div>
        <div>
          <div style="height: calc(100% - 40px);">
            <div>
              <app-header #header [exportar]="true" [periodo]="true" [filtro]="true" [resetFiltro]="true"
                [buscador]="true" (eventExportar)="onExportar()" (eventFilter)="onAceptar()"
                (eventResetFilter)="onResetFilter()" (eventBuscador)="onBuscar()">
              </app-header>
            </div>

            <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapter"
              [appCellClick] [enablebrowserselection]="true" [columnsresize]="true" [filterable]="true"
              [columnmenuopening]="columnmenuopening" (onBindingcomplete)="onGridDataUpdate()" [showfilterrow]="false"
              [columns]="columns" [sortable]="true" [altrows]="true" [showrowlines]="false"
              (onFilter)="onGridDataUpdate()" [filterrowheight]="30" [rowsheight]="20" [columnsheight]="20"
              [groupsheaderheight]="25" [groupable]="true" [localization]="langGrid" [filter]="filter"
              [updatefilterconditions]="updatefilterconditions" (onRowselect)="onRowClick($event)">
            </jqxGrid>

            <div class="form" style="float: left; width: 100%; height: 100%;">
              <jqxButton style="float:left; margin-left: 10px;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/center.png"'
                [value]="translate('Centrar')" (onClick)="onCentrar($event)">
              </jqxButton>

              <jqxButton [width]="100" [height]="26" style="float:left; margin-left: 10px;"
                [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
                [value]="translate('Salir')">
              </jqxButton>
            </div>
          </div>
        </div>
      </jqxSplitter>
    </div>
    <div class="form" style="float: left; width: 100%; height: 100%; margin-top: 10px;">
      <jqxButton style="float:left; margin-left: 10px;" [width]='100' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/center.png"'
        [value]="translate('Centrar')" (onClick)="onCentrar($event)">
      </jqxButton>

      <jqxButton [width]="100" [height]="26" style="float:left; margin-left: 10px;"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
        [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>