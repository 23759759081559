import { UserLangService } from 'src/app/services/common/user-lang/user-lang.service';
import { Component, computed, ElementRef, OnInit, signal, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxSplitterComponent } from 'jqwidgets-ng/jqxsplitter';

import { SsoService } from 'src/app/services/sso/sso.service';
import { SensorsService } from 'src/app/services/sensors/sensors.service';
import { InformesService } from 'src/app/services/reports/reports-service';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { Accion } from 'src/app/services/auditoria/models/accion.model';
import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { SubflotaModel } from 'src/app/services/flota/models/subflota.model';
import { AuditoriaModel } from 'src/app/services/auditoria/models/auditoria.model';
import { SensorMovilidadModel } from 'src/app/services/reports/models/sensor-movilidad-model';
import { InformeMovilidadModel } from 'src/app/services/reports/models/informe-movilidad-model';
import { EDigitalMovilidadModel } from 'src/app/services/reports/models/edigital-movilidad-model';

import { HeaderComponent } from 'src/app/components/header/header.component';
import { MovilesSelectComponent } from 'src/app/components/moviles-select/moviles-select.component';
import { PeriodoSelectTipo } from '../../../periodo-select/periodo-select.component';
import { RendererUtils } from 'src/app/utils/RendererUtils';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';

@Component({
  selector: 'app-listado-movilidad',
  templateUrl: './listado-movilidad.component.html',
  styleUrls: ['./listado-movilidad.component.css']
})
export class ListadoMovilidadComponent extends CustomForms implements OnInit {
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('mainSplitter') mainSplitter: jqxSplitterComponent;
  @ViewChild('movilesSelectComp') movilesSelectComp: MovilesSelectComponent;

  public static _this: ListadoMovilidadComponent;

  public movilFilter: string = '';

  private componentRef = null;
  public eventSplitter: boolean;
  public environment = environment;
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);

  // Variables para filtrado
  public fechaIni: Date;
  public fechaFin: Date;

  // Variables para generar el listado
  private informe: InformeMovilidadModel[] = [];
  private entradasDigitales: EDigitalMovilidadModel[] = [];
  private sensores: SensorMovilidadModel[] = [];
  private movilesSelect: MovilModel[] = [];
  public movilesInforme: MovilModel[] = [];
  public subflotas: SubflotaModel[] = [];


  // Preparo las columnas del grid
  public columns: any[] = [];
  public columngroups = [];

  dataSource = {
    datatype: 'json',
    datafields: [
      { name: 'movilMatricula', map: 'Movil>Matricula', type: 'string' },
      { name: 'movilNombre', map: 'Movil>Nombre', type: 'string' },
      { name: 'fechaIA', type: 'date' },
      { name: 'horaIA', type: 'date', map: 'fechaIA' },
      { name: 'fechaFA', type: 'date' },
      { name: 'horaFA', type: 'date', map: 'fechaFA' },
      { name: 'tiempoTotalSegundos', type: 'number' },
      { name: 'tiempoIgnicionActivaSegundos', type: 'number' },
      { name: 'tiempoIgnicionInactivaSegundos', type: 'number' },
      { name: 'tiempoRalentiSegundos', type: 'number' },
      { name: 'tiempoMarchaSegundos', type: 'number' },
      { name: 'distanciaTotal', type: 'int' },
      { name: 'numeroParadas', type: 'int' },
      { name: 'tiempoParadoSegundos', type: 'number' },
      { name: 'tiempoParadaMinimaSegundos', type: 'number' },
      { name: 'tiempoParadaMaximaSegundos', type: 'number' },
      { name: 'tiempoParadaMediaSegundos', type: 'number' },
      { name: 'velocidadMaxima', type: 'number' },
      { name: 'velocidadMedia', type: 'number' },
      { name: 'selec', map: 'selec' }
    ],
    localdata: this.informe,
  }

  dataAdapter = new jqx.dataAdapter(this.dataSource);

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  countPartialResults = signal<number>(0);
  titleWindow = this.translate('Listado_movilidad');

  titleWindowSignal = computed(() => `${this.titleWindow} (${this.countPartialResults()})`);

  constructor(
    private ssoService: SsoService,
    private informesService: InformesService,
    private sensorService: SensorsService,
    private auditoriaService: AuditoriaService,
    private userLangService: UserLangService) {
    super();
    ListadoMovilidadComponent._this = this;
  }

  ngOnInit(): void {
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.userLangService.getUserLangShort());
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);

    const t = setTimeout(() => {
      clearTimeout(t);
      this.header.periodoSelect.setPeriodo(PeriodoSelectTipo.DIA);
      /*
        Por defecto se establece el dia anterior
        ya que es el que tiene los datos calculados
      */
      this.header.periodoSelect.setDiaAnterior(new Date());

      this.header.periodoSelect.dateForm.get('desde').
        setValue(this.header.periodoSelect.desde);

      this.header.periodoSelect.dateForm.get('hasta').
        setValue(this.header.periodoSelect.hasta);
      /* ------------------------------------------ */

      this.initListado();
      if (this.grid.getrows().length == 0) {
        this.grid.showemptyrow(false);
      }
    }, 1000);
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  closeWindow() {
    this.form.close();
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    ListadoMovilidadComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  cellClass = (row: number, columnfield: any, value: any): string => {
    if (value.length > 10 || (typeof value === 'object')) {
      return 'cellTooltip';
    }
    return '';
  }

  public columnmenuopening(menu?: any, datafield?: any, height?: number | string): boolean | void {
    if (menu.length === 1) {
      if (datafield === 'fechaIA' || datafield === 'fechaFA' || datafield === 'horaIA' || datafield === 'horaFA') {
        const configureDateTimeInput = (menuElement: HTMLElement, options: { showTimeButton: boolean; showCalendarButton: boolean, formatString: string }) => {
          const dateTimeInputs = menuElement.querySelectorAll('.jqx-datetimeinput');
          if (dateTimeInputs && dateTimeInputs.length > 0) {
            dateTimeInputs.forEach((input: HTMLElement) => {
              const elementRef = new ElementRef(input);
              const jqxDateTimeInput = new jqxDateTimeInputComponent(elementRef);
              const inputDate = jqwidgets.createInstance('#' + jqxDateTimeInput.elementRef.nativeElement.id, 'jqxDateTimeInput', { width: '100%', height: 25 });
              inputDate.setOptions(options);
            });
          }
        };

        const divElement: HTMLElement = menu[0];
        if (datafield === 'fechaIA' || datafield === 'fechaFA') {
          configureDateTimeInput(divElement, { showTimeButton: false, showCalendarButton: true, formatString: 'dd/MM/yyyy' });
        } else if (datafield === 'horaIA' || datafield === 'horaFA') {
          configureDateTimeInput(divElement, { showTimeButton: true, showCalendarButton: false, formatString: 'HH:mm:ss' });
        }
      }
    }
  }

  initListado() {
    this.columngroups = [
      { text: AppComponent.translate('Movil'), align: 'center', name: 'movilGroup' },
      { text: AppComponent.translate('Ignicion'), align: 'center', name: 'ignicionGroup' },
      { text: AppComponent.translate('Paradas'), align: 'center', name: 'paradasGroup' },
      { text: AppComponent.translate('Velocidad'), align: 'center', name: 'velocidadGroup' }
    ];

    this.columns = [
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },
      { text: AppComponent.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', datafield: 'movilMatricula', width: 100, columngroup: 'movilGroup', cellsrenderer: this.textRenderer },
      { text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'movilNombre', width: 180, columngroup: 'movilGroup', cellsrenderer: this.textRenderer },

      { text: AppComponent.translate('Fecha_inicio'), columntype: 'date', filtertype: 'date', datafield: 'fechaIA', cellsformat: 'dd/MM/yy', width: 90, columngroup: 'ignicionGroup', cellclassname: this.cellClass },
      { text: AppComponent.translate('Hora_inicio'), columntype: 'date', filtertype: 'date', datafield: 'horaIA', cellsformat: 'HH:mm:ss', width: 80, columngroup: 'ignicionGroup', cellclassname: this.cellClass },
      { text: AppComponent.translate('Fecha_fin'), columntype: 'date', filtertype: 'date', datafield: 'fechaFA', cellsformat: 'dd/MM/yy', width: 90, columngroup: 'ignicionGroup', cellclassname: this.cellClass },
      { text: AppComponent.translate('Hora_fin'), columntype: 'date', filtertype: 'date', datafield: 'horaFA', cellsformat: 'HH:mm:ss', width: 80, columngroup: 'ignicionGroup', cellclassname: this.cellClass },
      {
        text: AppComponent.translate('Total'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tiempoTotalSegundos',
        align: 'center', width: 100, cellsalign: 'right', cellsrenderer: this.secondsRenderer, columngroup: 'ignicionGroup'
      },
      {
        text: AppComponent.translate('Activa'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'ignicionGroup', datafield: 'tiempoIgnicionActivaSegundos', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Inactiva'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'ignicionGroup', datafield: 'tiempoIgnicionInactivaSegundos', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Ralenti'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tiempoRalentiSegundos',
        align: 'center', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer, columngroup: 'ignicionGroup'
      },
      {
        text: AppComponent.translate('Marcha'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tiempoMarchaSegundos',
        align: 'center', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer, columngroup: 'ignicionGroup'
      },
      {
        text: AppComponent.translate('Distancia') + ' (km)', columntype: 'textbox', filtertype: 'textbox', datafield: 'distanciaTotal',
        align: 'center', width: 110, cellsalign: 'center', columngroup: 'ignicionGroup', cellsrenderer: RendererUtils.numberrenderer,
        aggregates: [{
          'Tot': (aggregatedValue: number, currentValue: number) => {
            return currentValue + aggregatedValue;
          }
        }],
        aggregatesrenderer: (aggregates: number) => {
          return aggregates["Tot"] ? '<div style="text-align: right; margin-right: 4px">' + NumberUtils.format(aggregates["Tot"], 0) + '</div>' : '';
        }
      },
      {
        text: AppComponent.translate('Num_paradas'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'paradasGroup', datafield: 'numeroParadas', width: 100, cellsalign: 'center', cellsrenderer: RendererUtils.numberrenderer
      },
      {
        text: AppComponent.translate('Tiempo_parado'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'paradasGroup', datafield: 'tiempoParadoSegundos', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Minima'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'paradasGroup', datafield: 'tiempoParadaMinimaSegundos', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Maxima'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'paradasGroup', datafield: 'tiempoParadaMaximaSegundos', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Media'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'paradasGroup', datafield: 'tiempoParadaMediaSegundos', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Maxima'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'velocidadGroup', datafield: 'velocidadMaxima', width: 100, cellsalign: 'center', cellsrenderer: RendererUtils.numberrenderer
      },
      {
        text: AppComponent.translate('Media'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'velocidadGroup', datafield: 'velocidadMedia', width: 100, cellsalign: 'center', cellsrenderer: RendererUtils.numberrenderer
      },
    ];
    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
    // Creo las columnas para las entradas digitales
    if (this.entradasDigitales) {
      this.entradasDigitales.forEach(ed => {
        // Creo un grupo para cada entrada
        this.columngroups.push({
          text: ed.nombreSensor, align: 'center', name: ed.entradaDigital + '-edGroup'
        });
        // Creo las columnas
        this.columns.push({
          text: AppComponent.translate('Activaciones'), columntype: 'textbox', filtertype: 'textbox', align: 'right',
          columngroup: ed.entradaDigital + '-edGroup', datafield: ed.entradaDigital + '-ed-numeroActivaciones', width: 80, cellsalign: 'right',
          cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
            return '<div style="text-align: center; margin-right: 4px; margin-top: 1.5px">' + NumberUtils.format(value, 0) + '</div>';
          }
        });
        this.columns.push({
          text: AppComponent.translate('Distancia') + ' (Km)', columntype: 'textbox', filtertype: 'textbox', align: 'right',
          columngroup: ed.entradaDigital + '-edGroup', datafield: ed.entradaDigital + '-ed-distancia', width: 110, cellsalign: 'right',
          cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
            return '<div style="text-align: right; margin-right: 4px; margin-top: 1.5px">' + NumberUtils.format(value, 2) + '</div>';
          },
          aggregates: [{
            'TotDistED': (aggregatedValue: number, currentValue: number) => {
              if (currentValue) {
                return currentValue + aggregatedValue;
              }
              return aggregatedValue;
            }
          }],
          aggregatesrenderer: (aggregates: number) => {
            return aggregates["TotDistED"] ? '<div style="text-align: right; margin-right: 4px;">' + NumberUtils.format(aggregates["TotDistED"], 2) + '</div>' : '';
          }
        });
        this.columns.push({
          text: AppComponent.translate('Duracion'), columntype: 'textbox', filtertype: 'textbox', align: 'right',
          columngroup: ed.entradaDigital + '-edGroup', datafield: ed.entradaDigital + '-ed-duracion', width: 100, cellsalign: 'right', cellsrenderer: this.secondsRenderer
        });
      });
    }
    // Creo las columnas para los sensores
    if (this.sensores) {
      this.sensores.forEach(se => {
        if (se.idSensor === 42) { // Peso neto
          // Creo un grupo para cada sensor
          this.columngroups.push({
            text: se.nombreSensor, align: 'center', name: se.idSensor + '-seGroup'
          });
          // Creo las columnas del grupo
          this.columns.push({
            text: AppComponent.translate('Cantidad'), columntype: 'textbox', filtertype: 'textbox', align: 'right',
            columngroup: se.idSensor + '-seGroup', datafield: se.idSensor + '-se-cantidad', width: 100, cellsalign: 'right',
            cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
              return '<div style="text-align: right; margin-right: 4px;">' + NumberUtils.format(value, 2) + '</div>';
            },
            // aggregates: [{
            //   'TotCantSE': (aggregatedValue: number, currentValue: number) => {
            //     if (currentValue) {
            //       return currentValue + aggregatedValue;
            //     }
            //     return aggregatedValue;
            //   }
            // }],
            // aggregatesrenderer: (aggregates: number) => {
            //   return aggregates["TotCantSE"] ? '<div style="text-align: center;">' + NumberUtils.format(aggregates["TotCantSE"], 2) + '</div>' : '';
            // }
          });
          this.columns.push({
            text: AppComponent.translate('Suma'), columntype: 'textbox', filtertype: 'textbox', align: 'right',
            columngroup: se.idSensor + '-seGroup', datafield: se.idSensor + '-se-suma', width: 110, cellsalign: 'right',
            cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
              return '<div style="text-align: right;margin-right: 4px;">' + NumberUtils.format(value, 2) + '</div>';
            },
            // aggregates: [{
            //   'TotSumSE': (aggregatedValue: number, currentValue: number) => {
            //     if (currentValue) {
            //       return currentValue + aggregatedValue;
            //     }
            //     return aggregatedValue;
            //   }
            // }],
            // aggregatesrenderer: (aggregates: number) => {
            //   return aggregates["TotSumSE"] ? '<div style="text-align: center;">' + NumberUtils.format(aggregates["TotSumSE"], 2) + '</div>' : '';
            // }
          });
          this.columns.push({
            text: AppComponent.translate('Media'), columntype: 'textbox', filtertype: 'textbox', align: 'right',
            columngroup: se.idSensor + '-seGroup', datafield: se.idSensor + '-se-media', width: 80, cellsalign: 'right',
            cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
              return '<div style="text-align: right; margin-right: 4px;">' + NumberUtils.format(value, 2) + '</div>';
            }
          });
        } else {
          // Creo las columnas sin grupo
          this.columns.push({
            text: se.nombreSensor, columntype: 'textbox', filtertype: 'textbox', align: 'right',
            datafield: se.idSensor + '-se-cantidad', width: 100, cellsalign: 'right',
            cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
              return '<div style="text-align: right; margin-right: 4px">' + NumberUtils.format(value, 2) + '</div>';
            },
            // aggregates: [{
            //   'TotCantSE': (aggregatedValue: number, currentValue: number) => {
            //     if (currentValue) {
            //       return currentValue + aggregatedValue;
            //     }
            //     return aggregatedValue;
            //   }
            // }],
            // aggregatesrenderer: (aggregates: number) => {
            //   return aggregates["TotCantSE"] ? '<div style="text-align: center;">' + NumberUtils.format(aggregates["TotCantSE"], 2) + '</div>' : '';
            // }
          });
        }
      });
    }
    this.resizeColumns();
  }

  resizeColumns() {
    Utils.renderSizeGrid(this.grid);
  }

  async onAceptar(resetFilt = false) {
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaFin = this.header.periodoSelect.getFechaFin();
    const moviles: number[] = [];
    if (this.movilesSelect.length > 0 && !resetFilt) {
      this.movilesSelect.forEach(mov => {
        moviles.push(mov.Codigo);
      });
    } else if (!resetFilt) {
      this.mainSplitter.expand();
      MainComponent.showWarning('ATENCION', 'Seleccione_movil', 2000);
      return;
    } else {
      this.mainSplitter.expand();
      if (this.informe.length > 0) {
        this.informe = [];
        this.generaInforme();
        this.onBuscar();
      }
      return;
    }

    let periodo = 0;
    switch (this.header.periodoSelect.getPeriodo()) {
      case 0: // Dia
        periodo = 1;
        break;
      case 1: // Semana
        periodo = 2;
        break;
      case 2: // Mes
        periodo = 3;
        break;
      case 3: // Trimestre
        periodo = 3;
        break;
      case 4: // Semestre
        periodo = 3;
        break;
      case 5: // Año
        periodo = 4;
        break;
      case 6: // Libre
        periodo = 1;
        break;
    }

    // Recupero los sensores
    await this.sensorService.getSensors();
    // Recupero el listado de movilidad
    let result = await this.informesService.getInformeMovilidad(periodo, moviles, this.fechaIni, this.fechaFin);
    this.informe = result.datos;
    this.entradasDigitales = result.entradasDigitales;

    // TODO: Esto es temporal hasta que se sepa que hay realmente conectado en cada una de las entradas digitales
    this.entradasDigitales.forEach(entrada => {
      entrada.nombreSensor = 'Entrada digital ' + entrada.entradaDigital;
    });
    this.sensores = [];
    result.sensores.forEach(sensor => {
      const s = this.sensorService.getSensor(sensor.idSensor);
      if (s && s.Codigo) { // Sólo sensores BDT
        this.sensores.push(sensor);
      }
    });
    if (this.grid.getrows().length == 0) {
      this.grid.showemptyrow(true);
    }
    // this.sensores = result.sensores;
    this.generaInforme();
    if (!resetFilt) {
      this.generateEventSplitter();
    }
    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    if (this.movilesSelectComp && this.movilesSelectComp.returnMoviles().length > 0) {
      this.movilFilter = filtervalue;
    }

    if (this.informe.length > 0) {
      this.informe.forEach(inf => {
        if (
          inf?.Movil?.Matricula?.compareWith(filtervalue) ||
          inf?.Movil?.Nombre?.compareWith(filtervalue) ||
          (inf?.fechaIA + '')?.compareWith(filtervalue) ||
          (inf?.fechaFA + '')?.compareWith(filtervalue) ||
          (inf?.tiempoTotalSegundos + '')?.compareWith(filtervalue) ||
          (inf?.tiempoIgnicionActivaSegundos + '')?.compareWith(filtervalue) ||
          (inf?.tiempoIgnicionInactivaSegundos + '')?.compareWith(filtervalue) ||
          (inf?.tiempoRalentiSegundos + '')?.compareWith(filtervalue) ||
          (inf?.tiempoMarchaSegundos + '')?.compareWith(filtervalue) ||
          (inf?.distanciaTotal + '')?.compareWith(filtervalue) ||
          (inf?.numeroParadas + '')?.compareWith(filtervalue) ||
          (inf?.tiempoParadoSegundos + '')?.compareWith(filtervalue) ||
          (inf?.tiempoParadaMinimaSegundos + '')?.compareWith(filtervalue) ||
          (inf?.tiempoParadaMaximaSegundos + '')?.compareWith(filtervalue) ||
          (inf?.tiempoParadaMediaSegundos + '')?.compareWith(filtervalue) ||
          (inf?.velocidadMaxima + '')?.compareWith(filtervalue) ||
          (inf?.velocidadMedia + '')?.compareWith(filtervalue)
        ) {
          inf['selec'] = 'selec';
        } else {
          inf['selec'] = '';
        }
      });

      // Compruebo si ya he creado el filtro "selec" anteriormente
      const filters = this.grid.getfilterinformation();

      if (filters.find(s => s.datafield === 'selec') === undefined) {
        const filtergroup = new jqx.filter();
        filtergroup.operator = 'and';
        filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
        this.grid.addfilter('selec', filtergroup);
      }

      setTimeout(() => {
        this.grid.applyfilters();
        this.grid.updatebounddata('data');
      }, 0)
    }
  }

  onGridDataUpdate() {
    this.countPartialResults.set(this.grid.getrows().length);
  }

  // resetea los filtros
  onResetFilter() {
    this.header.periodoSelect.cbPeriodo.selectIndex(0);

    this.header.periodoSelect.setDiaAnterior(new Date());

    this.header.periodoSelect.dateForm.get('desde').
      setValue(this.header.periodoSelect.desde);

    this.header.periodoSelect.dateForm.get('hasta').
      setValue(this.header.periodoSelect.hasta);

    this.header.searchInput['nativeElement'].value = '';
    this.movilFilter = '';

    this.onAceptar(true);
  }

  // Metodo para cerrar el splitter cuando le damos clic al boton aceptar
  generateEventSplitter() {
    this.mainSplitter.collapse();
  }

  // Genera el informe con los datos obtenidos
  generaInforme() {
    if (this.informe !== undefined && this.informe !== null) {
      this.initListado();
      this.informe.sort((a, b) => (a.fechaIA < b.fechaIA ? 1 : -1));
      this.dataSource.localdata = this.informe;
      this.grid.updatebounddata('data');
    }
  }

  movilesSelected(mov: MovilModel[]) {
    this.movilesSelect = mov;
  }

  onExportar() {
    const data = JSON.parse(this.grid.exportdata('json'));
    const json = JSON.parse(JSON.stringify(this.grid.getdisplayrows()));
    for (let i = 0; i < json.length; i++) {
      data[i][AppComponent.translate('Total')] = DateUtils.numberToDaysHoursMinutesSeconds(json[i].tiempoTotalSegundos);
      data[i][AppComponent.translate('Activa')] = DateUtils.numberToDaysHoursMinutesSeconds(json[i].tiempoIgnicionActivaSegundos);
      data[i][AppComponent.translate('Inactiva')] = DateUtils.numberToDaysHoursMinutesSeconds(json[i].tiempoIgnicionInactivaSegundos);
      data[i][AppComponent.translate('Ralenti')] = DateUtils.numberToDaysHoursMinutesSeconds(json[i].tiempoRalentiSegundos);
      data[i][AppComponent.translate('Marcha')] = DateUtils.numberToDaysHoursMinutesSeconds(json[i].tiempoMarchaSegundos);
      data[i][AppComponent.translate('Tiempo_parado')] = DateUtils.numberToDaysHoursMinutesSeconds(json[i].tiempoParadoSegundos);
      data[i][AppComponent.translate('Minima')] = DateUtils.numberToDaysHoursMinutesSeconds(json[i].tiempoParadaMinimaSegundos);
      data[i][AppComponent.translate('Maxima')] = DateUtils.numberToDaysHoursMinutesSeconds(json[i].tiempoParadaMaximaSegundos);
      data[i][AppComponent.translate('Media')] = DateUtils.numberToDaysHoursMinutesSeconds(json[i].tiempoParadaMediaSegundos);
    }
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(data);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + AppComponent.translate('Listado_movilidad') + '.xlsx');
    this.auditoria.AccionId = Accion.EXPORTAR_INFORME_MOVILIDAD;
    this.auditoriaService.addAuditoria(this.auditoria);
  }

  secondsRenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {

      return `<div style="margin-right: 4px; margin-top: 4px;  text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${DateUtils.numberToDaysHoursMinutesSeconds(value)}</span></div>`;
    } else {
      return `<div style="margin-right: 4px; margin-top: 4px;  text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${value}</span></div>`;
    }
  }

  textRenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${value}</span></div>`;
    } else if (value instanceof Date) {
      value = value ? DateUtils.formatTime(value) : '';
      return `<div style=" margin-top: 4px;  text-align: center;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${value}</span></div>`;
    }
  }


}
