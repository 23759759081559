import { Component, OnInit, ViewChild } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxRadioButtonGroupComponent } from 'jqwidgets-ng/jqxRadioButtonGroup';

import { EjercicioAnualModel } from 'src/app/services/ejercicioAnual/models/ejercicio-anual.model';
import { EjercicioAnualService } from 'src/app/services/ejercicioAnual/ejercicio-anual.service';
import { DiaLaboralService } from 'src/app/services/diaLaboral/dia-laboral.service';
import { DiaLaboralModel } from 'src/app/services/diaLaboral/models/dia-laboral.model';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MM-YYYY',
  },
  display: {
    dateInput: 'DD-MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-gestion-tiempo-list',
  templateUrl: './gestion-tiempo-list.component.html',
  styleUrls: ['./gestion-tiempo-list.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class GestionTiempoListComponent implements OnInit {
  @ViewChild('winGestionTiempoList') winGestionTiempoList: jqxWindowComponent;
	@ViewChild('groupAnyo', { static: false }) groupAnyo: jqxRadioButtonGroupComponent;
  @ViewChild('groupDia', { static: false }) groupDia: jqxRadioButtonGroupComponent;

  private componentRef = null;
  public environment = environment;

  public diaLaboral: DiaLaboralModel = new DiaLaboralModel();
  public ejercicioAnual: EjercicioAnualModel = new EjercicioAnualModel();

  public desde: Date = new Date();
  public hasta: Date = new Date();

  public localization = 'es-ES';

	itemsAnyo: any[] = [
    AppComponent.translate('Año_natural'),
    AppComponent.translate('Seleccionar'),
	]
  valueAnyo: any = this.itemsAnyo[0];

	itemsDia: any[] = [
    AppComponent.translate('Dia_natural'),
    AppComponent.translate('Seleccionar'),
	]
  valueDia: any = this.itemsDia[0];

  constructor
  (
    private ejercicioAnualService: EjercicioAnualService,
    private diaLaboralService: DiaLaboralService
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.getEjercicioAnual();
    this.getDiaLaboral();
  }

  public async init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  async getEjercicioAnual() {
    this.ejercicioAnual = await this.ejercicioAnualService.getEjercicioAnual();

    if(this.ejercicioAnual.inicio.dia === 1 &&
      this.ejercicioAnual.inicio.mes === 1 &&
      this.ejercicioAnual.fin.dia === 31 &&
      this.ejercicioAnual.fin.mes === 12
    ){
      this.groupAnyo.checkAt(0);
    }else {
      this.groupAnyo.checkAt(1);
    }
  }

  async getDiaLaboral() {
    this.diaLaboral = await this.diaLaboralService.getDiaLaboral();

    if(this.diaLaboral.inicio.hora === 0 &&
      this.diaLaboral.inicio.minutos === 0 &&
      this.diaLaboral.fin.hora === 23 &&
      this.diaLaboral.fin.minutos === 59
    ) {
      this.groupDia.checkAt(0);
    }else {
      this.groupDia.checkAt(1);
    }
  }

  changeTipoEjercicio = (item) => {
    if(item.checked) {
      this.valueAnyo = item.value;

      if(this.valueAnyo === this.itemsAnyo[0]) {
        this.ejercicioAnual.inicio.dia = 1;
        this.ejercicioAnual.inicio.mes = 1;


        this.ejercicioAnual.fin.dia = 31;
        this.ejercicioAnual.fin.mes = 12;
      }
    }
	}

  changeTipoDia = (item) => {
    if(item.checked) {
      this.valueDia = item.value;

      if(this.valueDia === this.itemsDia[0]) {
        this.diaLaboral.inicio.hora = 0;
        this.diaLaboral.inicio.minutos = 0;


        this.diaLaboral.fin.hora = 23;
        this.diaLaboral.fin.minutos = 59;
      }
    }
  }

  changeDiaDesde(event) {
    this.ejercicioAnual.inicio.dia = Number.parseInt(event.target.value);

    this.calcularFin()
  }

  changeMesDesde(event) {
    this.ejercicioAnual.inicio.mes = Number.parseInt(event.target.value);

    this.calcularFin();
  }

  calcularFin() {
    let fecha = new Date(`2000/${this.ejercicioAnual.inicio.mes}/${this.ejercicioAnual.inicio.dia}`);
    fecha.setFullYear(fecha.getFullYear() + 1);
    fecha.setDate(fecha.getDate() - 1);

    const dia = fecha.getDate();
    const mes = (fecha.getMonth() + 1);

    this.ejercicioAnual.fin.dia = dia;
    this.ejercicioAnual.fin.mes = mes;
  }

  async onGuardar() {
    await this.diaLaboralService.saveDiaLaboral(this.diaLaboral);
    await this.ejercicioAnualService.saveEjercicioAnual(this.ejercicioAnual);
  }

  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  closeWindow() {
    this.winGestionTiempoList.close()
  }
}
