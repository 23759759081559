<jqxWindow #form [theme]="environment.tema" [width]="1110" [height]="528" [zIndex]="90" [position]="getFormPos(form)"
    [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="false" [resizable]="false" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{ translate('Grafico_medidas') }}
    </div>
    <div class="form" style="float: left; overflow-x: hidden;">
        <jqxChart *ngIf="dataAdapter && xAxis && valueAxis && seriesGroups" #myChart [width]="'100%'" [height]="'95%'"
            [title]="translate('Lecturas')" [description]="elemento.Nombre" [enableCrosshairs]="true"
            [enableAnimations]="true" [padding]="padding" [xAxis]="xAxis" [titlePadding]="titlePadding"
            [source]="dataAdapter" [animationDuration]="500" [valueAxis]="valueAxis" [seriesGroups]="seriesGroups"
            [colorScheme]="'scheme01'">
        </jqxChart>

        <jqxButton style="float: left; margin-left: 6px" [imgPosition]="'left'" [textImageRelation]="'imageBeforeText'"
            [width]="100" [height]="26" [textPosition]="'left'" [value]="translate('CANCELAR')"
            (onClick)="form.close()">
        </jqxButton>
    </div>
</jqxWindow>