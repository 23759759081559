import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MainComponent } from 'src/app/components/main/main.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { ElementoModel } from '../elements/models/elem.model';
import { SsoService } from '../sso/sso.service';
import { VolumConfigModel } from './models/volum-config.model';
import { VolumLectruraModel as VolumLecturaModel } from './models/volum-lectura.model';
import { VolumetricoModel } from './models/volumetrico.model';

@Injectable({
  providedIn: 'root'
})
export class VolumService {
  public volumConfig = new Map<number, VolumConfigModel>();
  public elementos = new Map<string, ElementoModel>();

  constructor(private ssoService: SsoService, private http: HttpClient) {
    MainComponent.onLoadElements(elementos => {
      if (elementos) {
        elementos.forEach(elem => {
          if (elem.ImeiVolum && elem.ImeiVolum.length >= 15) {
            this.elementos.set(elem.ImeiVolum, elem);
          }
        });
      }
    });
  }

  // Obtiene los volumetricos
  async getVolumetricos(): Promise<VolumetricoModel[]> {
    let result: VolumetricoModel[] = [];
    try {
      result = await this.http.get<VolumetricoModel[]>(
        `${this.ssoService.getTicket().UrlApi}/api/volumetricos?enterprise${this.ssoService.getTicket().Empresa.IdGestion}`
      ).toPromise();
    } catch (error) {

    }
    return result;
  }

  // Obtiene los volumetricos sin elementos
  async getVolumetricosWithoutElement(): Promise<VolumetricoModel[]> {
    let result: VolumetricoModel[] = [];
    try {
      result = await this.http.get<VolumetricoModel[]>(
        `${this.ssoService.getTicket().UrlApi}/api/volumetricos/libres?enterprise=${this.ssoService.getTicket().Empresa.IdGestion}`
      ).toPromise();
    } catch (error) {

    }
    return result;
  }

  // Actualiza el volumetrico indicado
  async updateVolumetrico(imei: string, idElemento: number): Promise<VolumetricoModel> {
    let result: VolumetricoModel;

    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      };

      let asociacion: any = {
        "empresa": this.ssoService.getTicket().Empresa.IdGestion,
        "imei": imei,
        "idElemento": idElemento
      };

      result = await this.http.post<VolumetricoModel>(
        `${this.ssoService.getTicket().UrlApi}/api/volumetrico/update/elemento`,
        JSON.stringify(asociacion),
        httpOptions
      ).toPromise();
    } catch (error) {

    }

    return result;
  }

  // Devuelve las lecturas entre dos fechas
  async getLecturas(empresa: number, desde: Date, hasta: Date): Promise<VolumLecturaModel[]> {
    let result: VolumLecturaModel[] = null;
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      };
      const body = {
        empresa: empresa,
        desde: DateUtils.formatDateTime(desde, false),
        hasta: DateUtils.formatDateTime(hasta, false)
      }
      result = await this.http.post<VolumLecturaModel[]>(this.ssoService.getTicket().UrlApi +
        '/api/lecturas/volumetricos', JSON.stringify(body), httpOptions).toPromise();
      result.forEach(lectura => {
        lectura.Fecha = new Date(lectura.Fecha);
        lectura.FechaLectura = new Date(lectura.FechaLectura);
        lectura.Elemento = this.elementos.get(lectura.Imei);
        if (lectura.Elemento &&  lectura.Elemento.IdConfigVolum) {
          lectura.Config = this.volumConfig.get(lectura.Elemento.IdConfigVolum);
        }
      });
    } catch (e) {
    }
    return result;
  }

  //Devuelve las lecturas entre dos fechas teniendo en cuenta el imei del volumetrico
  async getLecturasByVolum(imei: string, desde: Date, hasta: Date): Promise<VolumLecturaModel[]> {
    let result: VolumLecturaModel[] = null;
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      };

      let desdeUrl = DateUtils.formatDateTime(desde, false);
      let hastaUrl = DateUtils.formatDateTime(hasta, false);

      result = await this.http.get<VolumLecturaModel[]>(this.ssoService.getTicket().UrlApi +
        '/api/lecturas/volumetricos?imei='+imei+'&from='+desdeUrl+'&to='+hastaUrl).toPromise();
      result.forEach(lectura => {
        lectura.Fecha = new Date(lectura.Fecha);
        lectura.FechaLectura = new Date(lectura.FechaLectura);
        lectura.Elemento = this.elementos.get(lectura.Imei);
        if (lectura.Elemento &&  lectura.Elemento.IdConfigVolum) {
          lectura.Config = this.volumConfig.get(lectura.Elemento.IdConfigVolum);
        }
      });
    } catch (e) {
    }
    return result;
  }

  // Devuelve las lecturas entre dos fechas de volumétricos que no tienen elemento asociado
  async getLecturasSinElemento(desde: Date, hasta: Date): Promise<VolumLecturaModel[]> {
    let result: VolumLecturaModel[] = null;
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      };
      const body = {
        desde: DateUtils.formatDateTime(desde, false),
        hasta: DateUtils.formatDateTime(hasta, false)
      }
      result = await this.http.post<VolumLecturaModel[]>(this.ssoService.getTicket().UrlApi +
        '/api/lecturasSinElemento/volumetricos', JSON.stringify(body), httpOptions).toPromise();
      result.forEach(lectura => {
        lectura.Fecha = new Date(lectura.Fecha);
        lectura.FechaLectura = new Date(lectura.FechaLectura);
        lectura.Elemento = new ElementoModel();
        lectura.Elemento.Nombre = MainComponent.translate('Sin_elemento');
      });
    } catch {
    }
    return result;
  }

  // Permite obtener las configuraciones de volumétricos
  async getConfiguraciones(): Promise<VolumConfigModel[]> {
    let result: VolumConfigModel[] = null;
    try {
      result = await this.http.get<VolumConfigModel[]>(this.ssoService.getTicket().UrlApi +
        '/api/configuracion/volumetricos/' + this.ssoService.getTicket().Empresa.IdGestion).toPromise();
      if (result && result.length > 0) {
        result.forEach(conf => {
          conf.HoraInicio = new Date(conf.HoraInicio);
          this.volumConfig.set(conf.Id, conf);
        });
      }
    } catch (e) {
      console.log(e);
    }
    return result;
  }
}
