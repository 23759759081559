<jqxRibbon style="overflow: hidden" [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [position]="'top'">
  <ul style="width: 100%; text-align: left">
    <li style="height: 26px; vertical-align: middle">
      {{ translate("Datos_generales") }}
    </li>
    <li style="height: 26px; vertical-align: middle"
      [hidden]="!elemEdit?.Instalacion?.requiereInstalacionTag">
      {{ translate("Tag") }}
    </li>
    <li style="height: 26px; vertical-align: middle"
     [hidden]="!elemEdit?.Instalacion?.requiereInstalacionVolumetrico">
      {{ translate("Volumetrico") }}
    </li>
    <li style="height: 26px; vertical-align: middle"
      [hidden]="!elemEdit?.Instalacion?.requiereInstalacionCerradura &&
        !elemEdit?.Instalacion?.requiereInstalacionIdentificador">
      {{ translate("Ecolock") }}
    </li>
  </ul>
  <div>
    <div class="row tab"
      style="background-color: transparent; padding: 0%; overflow-y: hidden !important; margin-top: 40px;">
      <div id="fieldInstalacionGeneral">
        <fieldset>
          <legend>{{ translate('Datos_generales') }}</legend>

          <div style="display: flex; flex-direction: column; gap: 10px;">
            <span>
              <div style="float: left;">
                <jqxCheckBox [checked]="elemEdit?.Instalacion ? elemEdit?.Instalacion?.requiereInstalacionTag : false"
                  (onChange)="checkRequire($event, 1)">
                  {{translate('Requiere_instalacion')}} {{translate('Tag')}}
                </jqxCheckBox>
              </div>
            </span>

            <span>
              <div style="float: left;">
                <jqxCheckBox [checked]="elemEdit?.Instalacion ? elemEdit?.Instalacion?.requiereInstalacionVolumetrico : false"
                  (onChange)="checkRequire($event, 2)">
                  {{translate('Requiere_instalacion')}} {{translate('Volumetrico')}}
                </jqxCheckBox>
              </div>
            </span>

            <span>
              <div style="float: left;">
                <jqxCheckBox #checkCerraduraGeneral
                  [checked]="elemEdit?.Instalacion ? elemEdit?.Instalacion?.requiereInstalacionCerradura : false"
                  (onChange)="checkRequire($event, 3)" [disabled]="!elemEdit?.Instalacion?.requiereInstalacionIdentificador">
                  {{translate('Requiere_instalacion')}} {{translate('Cerradura')}}
                </jqxCheckBox>
              </div>
            </span>

            <span>
              <div style="float: left;">
                <jqxCheckBox
                  [checked]="elemEdit?.Instalacion ? elemEdit?.Instalacion?.requiereInstalacionIdentificador : false"
                  (onChange)="checkRequire($event, 4)">
                  {{translate('Requiere_instalacion')}} {{translate('Identificacion')}}
                </jqxCheckBox>
              </div>
            </span>
          </div>

          <div style="margin-top: 10px;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Estado_instalacion") }}
            </div>

            <jqxDropDownList #dropEstadoInstalacionGeneral [ngModel]="elemEdit?.Instalacion?.idEstado"
              [width]="'100%'" [disabled]="true" [source]="sourceEstadoInstalacionGeneral">
            </jqxDropDownList>
          </div>

          <div style="margin-top: 10px;">
            <div style="width: 100%; height: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Observaciones") }}
              </div>
              <jqxTextArea [ngModel]="elemEdit?.Instalacion?.observaciones"
                (ngModelChange)="elemEdit.Instalacion.observaciones = $event" [width]="'100%'" [height]="100">
              </jqxTextArea>
            </div>
          </div>
        </fieldset>
      </div>
    </div>

    <div style="background-color: transparent; padding: 0%; overflow-y: hidden !important; margin-top: 40px;">
      <div  *ngIf="elemEdit?.Instalacion?.requiereInstalacionTag" id="fieldInstalacionTag">
        <fieldset>
          <legend>{{ translate('Tag') }}</legend>
          <div style="margin-top: 3%;">
            <span style="width: 50%;">
              <div style="float: left;">
                <jqxCheckBox [checked]="elemEdit?.Instalacion ? elemEdit?.Instalacion?.requiereInstalacionTag : false"
                  [disabled]="true">
                  {{translate('Requiere_instalacion')}}
                </jqxCheckBox>
              </div>
            </span>
          </div>

          <div style="margin-top: 10%;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Estado_instalacion") }}
            </div>

            <jqxDropDownList #dropEstadoInstalacionTag [ngModel]="getDetalleInstalacion(1)?.idEstado"
              [dropDownHeight]="sourceEstadoInstalacion.length * 30" [source]="sourceEstadoInstalacion"
              [disabled]="!editTag" [width]="'100%'" (focusout)="dropEstadoInstalacionTag.close()"
              (onSelect)="selectEstadoDetalleInstalacion($event, 1)">
            </jqxDropDownList>
          </div>

          <div style="margin-top: 10%;">
            <div style="width: 100%; height: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Observaciones") }}
              </div>
              <jqxTextArea [ngModel]="getDetalleInstalacion(1)?.observaciones"
                (ngModelChange)="getDetalleInstalacion(1).observaciones = $event" [width]="'100%'" [height]="130">
              </jqxTextArea>
            </div>
          </div>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>{{ translate("Imagenes") }}</legend>

          <app-images id="tagImages" [from]="'detalle'" [imagenes]="getDetalleInstalacion(1)?.imagenes"
            [asociateId]="getDetalleInstalacion(1)?.id" (onAddImage)="addImageDetalle($event, 1)"
            (onRmvImage)="rmvImageDetalle($event, 1)">
          </app-images>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Notificaciones_instalacion") }}
          </legend>

          <jqxGrid #gridIncidenciasTag [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 5px)'"
            [appCellClick] [source]="dataAdapterIncidenciasTag" [columns]="columnsIncidenciasTag"
            [showdefaultloadelement]="false" [enablebrowserselection]="true" [localization]="langGrid"
            [columnsheight]="20" [rowsheight]="25">
          </jqxGrid>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Imagenes_notificaciones_instalacion") }}
          </legend>

          <app-images id="notifTagImages" [from]="'notificacion'" [imagenes]="incdTagSelec?.Imagenes"
            [asociateId]="incdTagSelec?.Id" (onAddImage)="addImageNotificacion($event, incdTagSelec)"
            (onRmvImage)="rmvImageNotificacion($event, incdTagSelec)">
          </app-images>
        </fieldset>
      </div>
    </div>

    <div style="background-color: transparent; padding: 0%; overflow-y: hidden !important; margin-top: 40px;">
      <div *ngIf="elemEdit?.Instalacion?.requiereInstalacionVolumetrico" id="fieldInstalacionVolumetrico">
        <fieldset>
          <legend>{{ translate('Volumetrico') }}</legend>
          <div style="margin-top: 3%;">
            <span style="width: 50%;">
              <div style="float: left;">
                <jqxCheckBox [checked]="elemEdit ? elemEdit?.Instalacion?.requiereInstalacionVolumetrico : false"
                  [disabled]="true">
                  {{translate('Requiere_instalacion')}}
                </jqxCheckBox>
              </div>
            </span>
          </div>

          <div style="margin-top: 10%;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Estado_instalacion") }}
            </div>

            <jqxDropDownList #dropEstadoInstalacionVolum [ngModel]="getDetalleInstalacion(2)?.idEstado"
              [dropDownHeight]="sourceEstadoInstalacion.length * 30" [source]="sourceEstadoInstalacion"
              [disabled]="!editVolumetrico" [width]="'100%'" (focusout)="dropEstadoInstalacionVolum.close()"
              (onSelect)="selectEstadoDetalleInstalacion($event, 2)">
            </jqxDropDownList>
          </div>

          <div style="margin-top: 10%;">
            <div style="width: 100%; height: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Observaciones") }}
              </div>
              <jqxTextArea [ngModel]="getDetalleInstalacion(2)?.observaciones"
                (ngModelChange)="getDetalleInstalacion(2).observaciones = $event" [width]="'100%'" [height]="130">
              </jqxTextArea>
            </div>
          </div>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>{{ translate("Imagenes") }}</legend>

          <app-images id="volumImages" [from]="'detalle'" [imagenes]="getDetalleInstalacion(2)?.imagenes"
            [asociateId]="getDetalleInstalacion(2)?.id" (onAddImage)="addImageDetalle($event, 2)"
            (onRmvImage)="rmvImageDetalle($event, 2)">
          </app-images>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Notificaciones_instalacion") }}
          </legend>

          <jqxGrid #gridIncidenciasVolum [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 5px)'"
            [appCellClick] [source]="dataAdapterIncidenciasVolum" [enablebrowserselection]="true"
            [columns]="columnsIncidenciasVolum" [showdefaultloadelement]="false" [localization]="langGrid"
            [columnsheight]="20" [rowsheight]="25">
          </jqxGrid>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Imagenes_notificaciones_instalacion") }}
          </legend>

          <app-images id="notifVolumImages" [from]="'notificacion'" [imagenes]="incdVolumSelec?.Imagenes"
            [asociateId]="incdVolumSelec?.Id" (onAddImage)="addImageNotificacion($event, incdVolumSelec)"
            (onRmvImage)="rmvImageNotificacion($event, incdVolumSelec)">
          </app-images>
        </fieldset>
      </div>
    </div>

    <div class="row tab" style="background-color: transparent; padding: 0%; overflow-y: hidden !important; margin-top: 40px;">
      <div *ngIf="elemEdit?.Instalacion?.requiereInstalacionCerradura || elemEdit?.Instalacion?.requiereInstalacionIdentificador" id="fieldInstalacionEcoLock">
        <fieldset>
          <legend>{{ translate('Ecolock') }}</legend>
          <div style="margin-top: 3%;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Requiere_instalacion") }}:
            </div>
            <div style="margin-top: 5px; margin-left: 10px; display: flex; gap: 10px">
              <span style="width: 50%;">
                <jqxCheckBox #checkCerraduraEcolock
                  [checked]="elemEdit?.Instalacion ? elemEdit?.Instalacion?.requiereInstalacionCerradura : false"
                  [disabled]="true">
                  {{translate('Cerradura')}}
                </jqxCheckBox>
              </span>

              <span style="width: 50%;">
                <jqxDropDownList #dropEstadoInstalacionEcolock [ngModel]="getDetalleInstalacion(3)?.idEstado"
                  [dropDownHeight]="sourceEstadoInstalacion.length * 30" [source]="sourceEstadoInstalacion"
                  [disabled]="!editEcoLock" [width]="'100%'" (focusout)="dropEstadoInstalacionEcolock.close()"
                  (onSelect)="selectEstadoDetalleInstalacion($event, 3)">
                </jqxDropDownList>
              </span>
            </div>

            <div style="margin-top: 5px; margin-left: 10px; display: flex; gap: 10px">
              <span style="width: 50%;">
                <jqxCheckBox
                  [checked]="elemEdit?.Instalacion ? elemEdit?.Instalacion?.requiereInstalacionIdentificador : false"
                  [disabled]="true">
                  {{translate('Identificacion')}}
                </jqxCheckBox>
              </span>

              <span style="width: 50%;">
                <jqxDropDownList #dropEstadoInstalacionIdentificaciones [ngModel]="getDetalleInstalacion(4)?.idEstado"
                  [dropDownHeight]="sourceEstadoInstalacion.length * 30" [source]="sourceEstadoInstalacion"
                  [disabled]="!editIdentificaciones" [width]="'100%'" (focusout)="dropEstadoInstalacionIdentificaciones.close()"
                  (onSelect)="selectEstadoDetalleInstalacion($event, 4)">
                </jqxDropDownList>
              </span>
            </div>
          </div>

          <div style="margin-top: 10px;">
            <div style="margin-top: 5px; clear: both">
              {{ translate("Tipo_dispositivo") }}
            </div>
            <jqxDropDownList #dropTipoDispositivoEcolock [ngModel]="elemEdit?.TipoDispositivoEcolock"
              (focusout)="dropTipoDispositivoEcolock.close()" [width]="'100%'" [dropDownHeight]="30"
              [source]="sourceTipoDispositivoEcolock" [disabled]="!editEcoLock">
            </jqxDropDownList>
          </div>

          <div style="margin-top: 10px;">
            <div style="width: 100%; height: 100%;">
              <div style="margin-top: 5px; clear: both">
                {{ translate("Observaciones") }}
              </div>
              <jqxTextArea [ngModel]="getDetalleInstalacion(3)?.observaciones"
                (ngModelChange)="getDetalleInstalacion(3).observaciones = $event" [width]="'100%'" [height]="100">
              </jqxTextArea>
            </div>
          </div>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>{{ translate("Imagenes") }}</legend>

          <app-images id="ecolockImages" [from]="'detalle'" [imagenes]="getDetalleInstalacion(3)?.imagenes"
            [asociateId]="getDetalleInstalacion(3)?.id" (onAddImage)="addImageDetalle($event, 3)"
            (onRmvImage)="rmvImageDetalle($event, 3)">
          </app-images>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Notificaciones_instalacion") }}
          </legend>

          <jqxGrid #gridIncidenciasEcolock [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 5px)'"
            [appCellClick] [source]="dataAdapterIncidenciasEcolock" [columns]="columnsIncidenciasEcolock"
            [enablebrowserselection]="true" [showdefaultloadelement]="false" [localization]="langGrid"
            [columnsheight]="20" [rowsheight]="25">
          </jqxGrid>
        </fieldset>

        <fieldset style="margin-left: 1%;">
          <legend>
            {{ translate("Imagenes_notificaciones_instalacion") }}
          </legend>

          <app-images id="notifEcolockImages" [from]="'notificacion'" [imagenes]="incdEcolockSelec?.Imagenes"
            [asociateId]="incdEcolockSelec?.Id" (onAddImage)="addImageNotificacion($event, incdEcolockSelec)"
            (onRmvImage)="rmvImageNotificacion($event, incdEcolockSelec)">
          </app-images>
        </fieldset>
      </div>
    </div>
  </div>
</jqxRibbon>
