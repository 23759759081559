import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { MapModule } from 'movisat-maps';
import { HeaderModule } from '../header/header.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { EdificiosInstalacionesListComponent } from './edificios-instalaciones-list/edificios-instalaciones-list.component';
import { EdificiosInstalacionesEditComponent } from './edificios-instalaciones-edit/edificios-instalaciones-edit.component';

import { EdificiosInstalacionesEntradasSalidasListComponent } from './edificiosInstalacionesEntradasSalidas/edificios-instalaciones-entradas-salidas-list/edificios-instalaciones-entradas-salidas-list.component';
import { EdificiosInstalacionesEntradasSalidasEditComponent } from './edificiosInstalacionesEntradasSalidas/edificios-instalaciones-entradas-salidas-edit/edificios-instalaciones-entradas-salidas-edit.component';
import { LoaderComponent } from 'movisat-common-gui';

@NgModule({
  declarations: [
    EdificiosInstalacionesListComponent,
    EdificiosInstalacionesEditComponent,
    EdificiosInstalacionesEntradasSalidasListComponent,
    EdificiosInstalacionesEntradasSalidasEditComponent,
  ],
  imports: [
    MapModule,
    FormsModule,
    CommonModule,
    HeaderModule,
    SharedModule,
    BrowserModule,
    LoaderComponent
  ]
})
export class EdificiosInstalacionesModule { }
