import {
  signal,
  OnInit,
  ViewChild,
  Component,
  ElementRef,
  ViewContainerRef,
} from '@angular/core';

import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ConfiguracionCerraduraService } from 'src/app/services/cerraduras/configuracion-cerradura.service';

import { BdtResiduoModel } from 'src/app/services/bdt/models/bdt-residuo.model';
import { ConfiguracionCerraduraModel } from 'src/app/services/cerraduras/models/configuracionCerradura.model';

import { HeaderComponent } from '../header/header.component';
import { ConfiguracionCerradurasEditComponent } from './configuracion-cerraduras-edit/configuracion-cerraduras-edit.component';
import { ConfiguracionCerraduraAsignarComponent } from './configuracion-cerradura-asignar/configuracion-cerradura-asignar.component';

@Component({
  selector: 'app-configuracion-cerraduras',
  templateUrl: './configuracion-cerraduras.component.html',
  styleUrls: ['./configuracion-cerraduras.component.css']
})
export class ConfiguracionCerradurasComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('gridConfiguraciones') gridConfiguraciones: jqxGridComponent;
  @ViewChild('formConfiguracion', { read: ViewContainerRef }) editConfiguracionComponent;
  @ViewChild('asignarConfiguracion', { read: ViewContainerRef }) configuracionCerraduraAsignarComponent;

  static _this: any;
  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  public windowTitle = signal<string>(this.translate('Listado_configuración_ecoLock') + '(0)');

  formConfiguracion: any;
  asignarConfiguracion: any;
  inputDate: jqxDateTimeInputComponent;
  configuraciones: ConfiguracionCerraduraModel[] = [];
  public configuracionSelect: ConfiguracionCerraduraModel;
  showLoader: boolean = true;

  public columngroups = [];
  arrayGridAlarmas: { tipo: string; value: number; }[];
  orientacionIdentificador: any = ['0º', '180º', '90º', '270º', this.translate('Tapa')];
  tiposComunicaciones: any = ['NB_IoT', 'LTE_Cat_M', 'GPRS_2G'];
  tiposCerraruda: any = [this.translate('No_equipada'), this.translate('Cerradura_welock')];
  tiposContenedor: any = [this.translate('Contenedor_tipo_apertura_vaciado'), this.translate('Contenedor_tipo_comparte_trampilla'), this.translate('Contenedor_tipo_soterrado'), this.translate('Papelera')];
  frecuenciAnuncBT: any = [this.translate('Muy_alta'), this.translate('Alta'),this.translate('Media'),this.translate('Baja'),this.translate('Muy_baja')];

  // Recoge las medidas de la parte equivalente al mapa
  mapWidth: number;
  mapHeight: number;

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private configuracionCerraduraService: ConfiguracionCerraduraService,
    private modal: NzModalService,
    private ssoService: SsoService
  ) {
    super();
    ConfiguracionCerradurasComponent._this = this;
  }

  ngOnInit(): void {
    this.initGrid();
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    this.getConfiguraciones();
    this.renderEvent();
  }

  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  closeWindow() {
    this.form.close();
  }

  onClose() {
    this.componentRef.destroy();
    ConfiguracionCerradurasComponent._this = null;
  }

  async getConfiguraciones() {
    this.configuraciones = await this.configuracionCerraduraService.getConfiguracionesCerradua();
    if (!this.configuraciones) {
      this.configuraciones = [];
    }
    this.sourceGridConfiguracion.localdata = this.configuraciones;
    this.adapterGridConfiguracion = new jqx.dataAdapter(this.sourceGridConfiguracion);
    this.onBuscar();
  }

  // Incializa la columna de botones
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    let rowdata: ConfiguracionCerraduraModel;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.gridConfiguraciones.getrowdata(row);
    }

    htmlElement.innerHTML = '';
    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'flex-start';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
      <button class="button" style="height: 23px; width: 23px; cursor: pointer; display: flex; justify-content: center; align-items: center;"
        title="`+ AppComponent.translate('Editar') + `">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;
    btnEdit.id = `buttonEdit`;
    btnContainer.appendChild(btnEdit);

    btnEdit.addEventListener('click', async (event: any) => {
      this.configuracionSelect = this.configuraciones.find(elem => elem.id == rowdata.id);
      this.onEditarConfiguracion();
    });

    const btnAssign = document.createElement('div');
    btnAssign.innerHTML = `
      <button class="button" style="height: 23px; width: 23px; cursor: pointer; display: flex; justify-content: center; align-items: center;"
        title="`+ AppComponent.translate('Asignar_cerraduras') + `">
        <i class="fa-solid fa-link"></i>
      </button>
    `;
    btnAssign.id = `buttonAssign`;
    btnContainer.appendChild(btnAssign);

    btnAssign.addEventListener('click', async (event: any) => {
      this.configuracionSelect = this.configuraciones.find(elem => elem.id == rowdata.id);
      this.onAsignarConfiguracion();
    });

    // Muestra el boton de borrar si no tiene cerraduras
    if (rowdata && this.configuraciones.find(elem => elem.id == rowdata.id).numeroCerraduras == 0) {
      const btnDelete = document.createElement('div');
      btnDelete.innerHTML = `
        <button class="button" style="height: 23px; width: 23px; cursor: pointer; display: flex; justify-content: center; align-items: center;"
          title="`+ AppComponent.translate('Borrar') + `">
          <i class="fa-solid fa-trash"></i>
        </button>
      `;
      btnDelete.id = `buttonDelete`;
      btnContainer.appendChild(btnDelete);

      btnDelete.addEventListener('click', async (event: any) => {
        this.configuracionSelect = this.configuraciones.find(elem => elem.id == rowdata.id);
        this.onBorrarConfiguracion();
      });
    }
    htmlElement.appendChild(btnContainer);
  }

  /* Grid configuraciones */
  public columnsGridConfiguracion;
  public sourceGridConfiguracion: any;
  public adapterGridConfiguracion;

  initGrid() {
    this.columnsGridConfiguracion = [
      {
        text: '',
        width: 80,
        columntype: 'text',
        sortable: false,
        editable: false,
        datafield: 'acciones',
        groupable: false,
        menu: false,
        columngroup: 'accionesGroup',
        rendered: (columnHeaderElement) => {
          const buttonContainer1 = document.createElement('div');
          buttonContainer1.style.width = '100%';
          buttonContainer1.style.display = 'flex';
          buttonContainer1.style.justifyContent = 'center';
          buttonContainer1.style.alignItems = 'center';

          buttonContainer1.id = `buttonContainerColumn_jqxButton`;
          columnHeaderElement[0].appendChild(buttonContainer1);

          const btnCrear = document.createElement('div');
          btnCrear.innerHTML = `<button type="button" class="button" style="margin-top: 20px; display: flex; cursor: pointer;" title="` + AppComponent.translate('Crear') + `"> <i style="font-size: 18px; position: relative;" class="fa-solid fa-plus"></i></button>`;

          btnCrear.id = `buttonCrear_jqxButton`;

          buttonContainer1.appendChild(btnCrear);
          btnCrear.addEventListener('click', async (event: any) => {
            this.onCrearConfiguracion();
          });

          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        }
      },
      { text: this.translate('Num_cerraduras'), columntype: 'textbox', width: '50px', datafield: 'numeroCerraduras', filtertype: 'number', cellsalign: 'right' },
      //DATOS GENERALES
      { text: this.translate('Nombre'), columntype: 'textbox', datafield: 'nombre', width: '110px', enabletooltips: 'true', filtertype: 'textbox', columngroup: 'DatosGenerales', cellsrenderer: this.rendexTextGeneric, },
      { text: this.translate('Servidor'), columngroup: 'DatosGenerales', width: '110px', enabletooltips: 'false', columntype: 'textbox', datafield: 'host', filtertype: 'textbox', cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Puerto'), columntype: 'textbox', width: '50px', columngroup: 'DatosGenerales', cellsalign: 'right', datafield: 'puerto', filtertype: 'textbox', cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Tipo_contenedor'), width: '110px', columntype: 'textbox', filtertype: 'checkedlist', columngroup: 'DatosGenerales', datafield: 'tipoContenedor', cellsrenderer: this.renderTipoContenedorColumn, filteritems: this.tiposContenedor },
      { text: this.translate('Tipo_cerradura'), width: '100px', columntype: 'textbox', filtertype: 'checkedlist', columngroup: 'DatosGenerales', datafield: 'tipoCerradura', cellsrenderer: this.renderTipoCerraduraColumn, filteritems: this.tiposCerraruda },
      { text: this.translate('Orientacion'), width: '80px', columntype: 'textbox', filtertype: 'checkedlist', columngroup: 'DatosGenerales', datafield: 'orientacion', cellsrenderer: this.renderOrientacionColumn, filteritems: this.orientacionIdentificador },
      { text: this.translate('Siempre_abierto'), width: '50px', columntype: 'textbox', filtertype: 'checkedlist', columngroup: 'DatosGenerales', datafield: 'siempreAbierto', cellsrenderer: this.renderCheck },
      // EQUIPAMIENTO
      { text: this.translate('Marca'), columntype: 'textbox', width: '90px', columngroup: 'EquipamientoGroup', filtertype: 'checkedlist', datafield: 'equipamientoMarca', cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Modelo'), columntype: 'textbox', width: '90px', columngroup: 'EquipamientoGroup', filtertype: 'checkedlist', datafield: 'equipamientoModelo', cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Residuo'), columntype: 'textbox', width: '90px', columngroup: 'EquipamientoGroup', filtertype: 'checkedlist', datafield: 'equipamientoResiduo', cellsrenderer: this.renderResiduoColumn },
      { text: this.translate('Capacidad'), columntype: 'textbox', width: '90px', cellsalign: 'right', columngroup: 'EquipamientoGroup', filtertype: 'number', datafield: 'equipamientoCapacidad', cellsrenderer: this.rendexTextGeneric },
      /// fin equipamiento

      // CONFIGURACION MODEM
      { text: 'APN', columntype: 'textbox', columngroup: 'ModemGroup', datafield: 'apn', width: '100px', filtertype: 'textbox', cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('USUARIO'), columngroup: 'ModemGroup', enabletooltips: 'true', columntype: 'textbox', datafield: 'usuario', width: '60px', filtertype: 'textbox', cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('PASSWORD'), columngroup: 'ModemGroup', columntype: 'textbox', datafield: 'password', width: '80px', filtertype: 'textbox', cellsrenderer: this.rendexTextGeneric },
      { text: 'PIN', columntype: 'textbox', columngroup: 'ModemGroup', datafield: 'pin', width: '40px', filtertype: 'textbox', cellsrenderer: this.rendexTextGeneric },

      // CONFIGURACION COMUNICACION
      { text: this.translate('Comunicaciones'), columntype: 'textbox', columngroup: 'ComunicacionGroup', filtertype: 'checkedlist', datafield: 'tecnologia', width: '80px', cellsrenderer: this.renderComunicacionesColumn, filteritems: this.tiposComunicaciones },
      { text: this.translate('Hora_envio'), columngroup: 'ComunicacionGroup', columntype: 'textbox', filtertype: 'date', datafield: 'horaEnvio', width: '50px', cellsrenderer: this.renderHoraEnvioColumn },
      { text: this.translate('Intervalo_envio') + '(' + this.translate('En_hora') + ')', columngroup: 'ComunicacionGroup', columntype: 'textbox', datafield: 'intervaloEnvio', width: '50px', filtertype: 'number', cellsalign: 'right', cellsrenderer: this.renderIntervaloEnvioColumn },

      // INTERACCION USUARIOS
      { text: this.translate('Tiempo_apertura') + ' (seg.)', columngroup: 'IteraccionUsuariosGroup', columntype: 'textbox', datafield: 'tiempoApertura', width: '40px', filtertype: 'number', cellsalign: 'right', },
      { text: this.translate('Tiempo_aporte') + ' (seg.)', columngroup: 'IteraccionUsuariosGroup', columntype: 'textbox', datafield: 'tiempoAporte', width: '40px', filtertype: 'number', cellsalign: 'right', },
      { text: this.translate('Tiempo_cierre') + ' (seg.)', columngroup: 'IteraccionUsuariosGroup', columntype: 'textbox', datafield: 'tiempoCierre', width: '40px', filtertype: 'number', cellsalign: 'right', },

      //INTERACCION IDENTIFICACION
      { text: this.translate('Tiempo_max_apertura') + ' (seg.)', columntype: 'textbox', columngroup: 'IteraccionIdenGroup', datafield: 'tiempoMaxApertura', width: '40px', filtertype: 'number', cellsalign: 'right', },
      { text: this.translate('Tiempo_max_apertura_bateria_baja') + ' (seg.)', columntype: 'textbox', columngroup: 'IteraccionIdenGroup', datafield: 'tiempoMaxAperturaBatBaja', width: '40px', filtertype: 'number', cellsalign: 'right', },
      { text: this.translate('Tiempo_max_cierre') + ' (seg.)', columngroup: 'IteraccionIdenGroup', columntype: 'textbox', datafield: 'tiempoMaxCierre', width: '40px', filtertype: 'number', cellsalign: 'right', },
      { text: this.translate('Tiempo_max_cierre_bateria_baja') + ' (seg.)', columntype: 'textbox', columngroup: 'IteraccionIdenGroup', datafield: 'tiempoMaxCierreBatBaja', width: '40px', filtertype: 'number', cellsalign: 'right', },
      { text: this.translate('Deteccion_vaciado'), columntype: 'textbox', columngroup: 'IteraccionIdenGroup', datafield: 'deteccionVaciado', width: '40px', filtertype: 'textbox', cellsalign: 'right', },
      { text: this.translate('Anunc_bt'), columntype: 'textbox', columngroup: 'IteraccionIdenGroup', datafield: 'anuncioBT', width: '40px', filtertype: 'textbox', cellsalign: 'right', cellsrenderer: this.renderFrecuAnuncBT , filteritems: this.frecuenciAnuncBT },

      //DETECCION APORTE
      { text: this.translate('Distancia_pared_opuesta'), columntype: 'textbox', columngroup: 'DeteccionAporteGroup', datafield: 'distanciaParedOpuesta', width: '60px', filtertype: 'textbox', cellsalign: 'right', cellsrenderer: this.renderDistanciaPared },

      //CONFIGURACION ALARMAS
      { text: this.translate('Tipos_alarmas'), columntype: 'textbox', columngroup: 'AlarmasGroup', datafield: 'tipoAlarmas', width: '100px', cellsalign: 'left', cellsrenderer: this.renderTipoAlarmasColumn },
      { text: this.translate('Alarma_bateria_baja') + ' (v)', columngroup: 'AlarmasGroup', columntype: 'textbox', datafield: 'bateriaBaja', width: '60px', cellsalign: 'right', cellsrenderer: this.renderBateriaBajaColumn, },
      { text: this.translate('Alarma_bateria_critica') + ' (v)', columngroup: 'AlarmasGroup', columntype: 'textbox', datafield: 'bateriaCritica', width: '60px', filtertype: 'number', cellsalign: 'right', cellsrenderer: this.renderBateriaCriticaColumn },
      { text: this.translate('Temperatura') + ' (ºC)', columngroup: 'AlarmasGroup', columntype: 'textbox', datafield: 'temperatura', width: '60px', filtertype: 'number', cellsalign: 'right', cellsrenderer: this.renderTemperaturaColumn },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },


    ],

    this.columngroups = [
      { text: this.translate('Acciones'), align: 'center', name: 'AccionesGroup', },
      { text: this.translate('Datos_generales'), align: 'center', name: 'DatosGenerales' },
      { text: this.translate('Equipamiento'), align: 'center', name: 'EquipamientoGroup', parentgroup: 'DatosGenerales', },
      { text: this.translate('Configuracion_modem'), align: 'center', name: 'ModemGroup', },
      { text: this.translate('Configuracion_comunicacion'), align: 'center', name: 'ComunicacionGroup' },
      { text: this.translate('Iteraccion_usuarios'), align: 'center', name: 'IteraccionUsuariosGroup', },
      { text: this.translate('Iteraccion_identificacion'), align: 'center', name: 'IteraccionIdenGroup' },
      { text: this.translate('Deteccion_aporte'), align: 'center', name: 'DeteccionAporteGroup', },
      { text: this.translate('Configuracion_alarmas'), align: 'center', name: 'AlarmasGroup', }
    ];

    this.sourceGridConfiguracion = {
      datatype: 'json',
      datafields: [
        { name: 'selec', map: 'selec' },
        { name: 'id', type: 'number', map: 'id' },
        { name: 'nombre', type: 'string', map: 'nombre' },
        { name: 'host', type: 'string', map: 'host' },
        { name: 'puerto', type: 'number', map: 'puerto' },
        { name: 'tipoContenedor', type: 'number', map: 'tipoContenedor' },
        { name: 'tipoCerradura', type: 'number', map: 'tipoCerradura' },
        { name: 'orientacion', type: 'number', map: 'orientacion' },
        { name: 'siempreAbierta', type: 'number', map: 'siempreAbierto' },
        { name: 'numeroCerraduras', type: 'number', map: 'numeroCerraduras' },

        // equipamiento
        { name: 'equipamientoMarca', type: 'string', map: 'equipamiento>marca>nombre' },
        { name: 'equipamientoModelo', type: 'string', map: 'equipamiento>modelo>nombre' },
        { name: 'equipamientoResiduo', type: 'textbox', map: 'equipamiento>residuos' },
        { name: 'equipamientoCapacidad', type: 'number', map: 'equipamiento>capacidad_litros' },
        // configuracion modem
        { name: 'apn', type: 'string', map: 'apn' },
        { name: 'usuario', type: 'string', map: 'apnUsuario' },
        { name: 'password', type: 'string', map: 'apnPassw' },
        { name: 'pin', type: 'string', map: 'pin' },
        // configuracion comunicacion
        { name: 'tecnologia', type: 'number', map: 'tecnologia' },
        { name: 'horaEnvio', type: 'number', map: 'horaEnvio' },
        { name: 'intervaloEnvio', type: 'number', map: 'intervaloEnvio' },
        // iteraccion usuarios
        { name: 'tiempoApertura', type: 'number', map: 'tiempoAbrir' },
        { name: 'tiempoAporte', type: 'number', map: 'tiempoAporte' },
        { name: 'tiempoCierre', type: 'number', map: 'tiempoCerrar' },
        // iteraccion identificacion
        { name: 'tiempoMaxApertura', type: 'number', map: 'tiempoMaxApertura' },
        { name: 'tiempoMaxAperturaBatBaja', type: 'number', map: 'tiempoMaxAperturaBatBaja' },
        { name: 'tiempoMaxCierre', type: 'number', map: 'tiempoMaxCierre' },
        { name: 'tiempoMaxCierreBatBaja', type: 'number', map: 'tiempoMaxCierreBatBaja' },
        { name: 'deteccionVaciado', type: 'string', map: 'deteccionVaciado' },
        { name: 'anuncioBT', type: 'number', map: 'frecuenciaAnunciaBT' },
        //detecion aporte
        { name: 'distanciaParedOpuesta', type: 'number', map: 'distanciaPared' },
        // configuracion alarmas
        { name: 'tipoAlarmas', type: 'string', map: 'alarmas' },
        { name: 'bateriaBaja', type: 'number', map: 'bateriaBaja' },
        { name: 'bateriaCritica', type: 'number', map: 'bateriaCritica' },
        { name: 'temperatura', type: 'number', map: 'temperatura' },
      ],
      localdata: this.configuraciones,
    };

    this.adapterGridConfiguracion = new jqx.dataAdapter(this.sourceGridConfiguracion);

    let isFirstIteration = true;
    this.columnsGridConfiguracion.forEach(column => {
      if (!isFirstIteration) {
        column.rendered = (element) => { Utils.tooltiprenderer(element) };
      } else {
        isFirstIteration = false;
      }
    });
  }

  renderEvent() {
    setTimeout(() => {
      // Recoge los textos de los encabezados de las columnas
      let titulosList = [];
      document.querySelectorAll('.jqx-grid-column-header').forEach((element) => {
        titulosList.push(element.textContent);
      });

      // Crear una lista de los textos traducidos de 'this.columngroups'
      let textosGrupo = this.columngroups.map(group => group.text);

      // Filtrar 'titulosList' para incluir solo los elementos que están en 'textosGrupo'
      titulosList = titulosList.filter(titulo => textosGrupo.includes(titulo));

      document.querySelectorAll('.jqx-grid-column-header').forEach((element, index) => {

        element.addEventListener('mouseenter', (event) => {
          // Compruebo si el evento es un Mouseevent
          if (event instanceof MouseEvent && titulosList.includes(element.textContent)) {
            // Creo el tooltip
            const tooltip = document.createElement('div');
            tooltip.className = 'jqx-tooltip jqx-tooltip-material jqx-popup jqx-popup-material';
            tooltip.textContent = element.textContent;

            // Eestilos y ubicacion del puntero
            tooltip.style.position = 'absolute';
            tooltip.style.left = event.clientX + 'px';
            tooltip.style.top = event.clientY + 'px';
            tooltip.style.pointerEvents = 'none';
            tooltip.style.zIndex = '9999';

            // añado al dom el tooltip
            document.body.appendChild(tooltip);

            // elimino el el tooltip al salir de la celda
            element.addEventListener('mouseleave', () => {
              try {
                // Intentaoeliminar el tooltip si aún es un hijo del body
                if (document.body.contains(tooltip)) {
                  document.body.removeChild(tooltip);
                }
              } catch (error) {
              }
            });
          }
        });
      });
      this.gridConfiguraciones.sortby('nombre', 'asc');
      this.showLoader = false;
    }, 2000);
  }

  /* Renderiza las columnas de la tabla para mostrar un texto leible al usuario */
  renderTipoContenedorColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    switch (value) {
      case 0:
        return this.crearDivConHover(AppComponent.translate("Contenedor_tipo_apertura_vaciado"));
      case 1:
        return this.crearDivConHover(AppComponent.translate("Contenedor_tipo_comparte_trampilla"));
      case 2:
        return this.crearDivConHover(AppComponent.translate("Contenedor_tipo_soterrado"));
      case 3:
        return this.crearDivConHover(AppComponent.translate("Papelera"));
      default:
        return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;"></div>';
    }
  };

  renderTipoCerraduraColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    switch (value) {
      case 0:
        return this.crearDivConHover(AppComponent.translate("No_equipada"))
      case 1:
        return this.crearDivConHover(AppComponent.translate("Cerradura_welock"))
      default:
        return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;"></div>';
    }
  }

  renderComunicacionesColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    switch (value) {
      case 0:
        this.crearDivConHover('>NB IoT');

      case 1:
        return this.crearDivConHover('LTE Cat M');

      case 2:
        return this.crearDivConHover('GPRS 2G');

      default:
        return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;"></div>';
    }
  }

  renderHoraEnvioColumn = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    let horas = Math.floor(value / 60);
    let minutos = Math.floor(value % 60);
    return this.crearDivConHover((horas.toString().length != 1 ? horas : '0' + horas) + ':' + (minutos.toString().length != 1 ? minutos : '0' + minutos));
  }


  renderIntervaloEnvioColumn = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    let horas = Math.floor(value / 60);
    let minutos = Math.floor(value % 60);
    return this.crearDivConHover((horas.toString().length != 1 ? horas : '0' + horas) + ':' + (minutos.toString().length != 1 ? minutos : '0' + minutos));
  }

  renderBateriaBajaColumn = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    return '<div class="jqx-grid-cell-left-align" style="margin-right: 4px; margin-top: 5px; text-align: right;">' + NumberUtils.format((value / 100), 2) + '</div>';
  }

  renderBateriaCriticaColumn = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    return '<div class="jqx-grid-cell-left-align" style="margin-right: 4px; margin-top: 5px; text-align: right;">' + NumberUtils.format((value / 100), 2) + '</div>';
  }

  renderTemperaturaColumn = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    return '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' + NumberUtils.format((value / 10), 2) + '</div>';
  }

  renderDistanciaPared = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    return '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' + NumberUtils.format((value / 10), 2) + '</div>';
  }

  renderTipoAlarmasColumn = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    let content = [];

    if ((value & 2) > 0) {
      content.push(this.translate("Alarma_bateria_baja"));
    }

    if ((value & 4) > 0) {
      content.push(this.translate("Alarma_bateria_critica"));
    }

    if ((value & 8) > 0) {
      content.push(this.translate("Cambio_bateria"));
    }

    if ((value & 16) > 0) {
      content.push(this.translate("Temperatura_elevada"));
    }

    if ((value & 32) > 0) {
      content.push(this.translate("Vaciado"));
    }

    if ((value & 64) > 0) {
      content.push(this.translate("Diagnostico"));
    }

    if ((value & 128) > 0) {
      content.push(this.translate("Puerta_abierta"));
    }

    if ((value & 256) > 0) {
      content.push(this.translate("Puerta_violentada"));
    }

    if ((value & 512) > 0) {
      content.push(this.translate("Vandalismo"));
    }

    const commaSeparatedContent = content.join(', ');
    return this.crearDivConHover(commaSeparatedContent);
  }

  crearDivConHover(content: string): string {
    return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${content}</div>`;
  }

  renderOrientacionColumn = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (value !== undefined && value !== null) {
      let textToShow = this.orientacionIdentificador[value];
      return `
        <div style="margin-left: 4px; margin-top: 5px;  text-align: left;"
          onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"
          onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';"
        >
          ${textToShow}
        </div>
      `;
    } else {
      return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"></div>`;
    }
  }

  renderFrecuAnuncBT = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (value !== undefined && value !== null) {
      let textToShow = '';
      if(value === 1){
        textToShow = this.frecuenciAnuncBT[0];
      }else if(value === 3) {
        textToShow = this.frecuenciAnuncBT[1];
      }else if(value === 5) {
        textToShow = this.frecuenciAnuncBT[2];
      }else if(value === 8) {
        textToShow = this.frecuenciAnuncBT[3];
      }else if(value === 10) {
        textToShow = this.frecuenciAnuncBT[4];
      }

      return `
        <div style="margin-left: 4px; margin-top: 5px;  text-align: left;"
          onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"
          onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';"
        >
          ${textToShow}
        </div>
      `;
    } else {
      return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"></div>`;
    }
  }

  renderResiduoColumn = (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (value?.length) {
      if (value.length > 0 && value.length == 1) {
        return this.crearDivConHover(value[0].nombre);
      } else {
        let content = [];
        value.forEach(element => {
          content.push(element.nombre);
        });
        // Convierte el array en una cadena separada por comas
        const commaSeparatedContent = content.join(', ');
        return this.crearDivConHover(commaSeparatedContent);
      }
    } else {
      return '';
    }
  }

  rendexTextGeneric = (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (typeof value === 'string') {
      return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;

    } else if (typeof value === 'number') {
      switch (columnfield) {
        case 'equipamientoCapacidad':
          return `<div style="margin-right: 4px; margin-top: 4px;  text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${NumberUtils.format(value, 0)}</span></div>`;
        default: return `<div style="margin-right: 4px; margin-top: 4px;  text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${value}</span></div>`;
      }
    }
  }

  // Cambia el color de los checkbox para que aparezcan como deshabilitados
  renderCheck(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        `
        <div style="position: absolute; top: 50%; left: 50%; margin-top: -9px; margin-left: -12px; overflow: visible; cursor: auto;"
        id="jqxWidgete18472e433a4" tabindex="0" class="jqx-widget jqx-widget-material jqx-checkbox jqx-checkbox-material" checked="true">
          <div class="jqx-checkbox-default jqx-checkbox-default-material jqx-fill-state-normal jqx-fill-state-normal-material jqx-rc-all jqx-rc-all-material ripple" style="filter: grayscale(1)">
            <div style="width: 16px; height: 16px;">
              <span style="width: 16px; height: 16px;" class="jqx-checkbox-check-checked jqx-checkbox-check-checked-material"></span>
            </div>
            <span style="height: 18px; width: 18px; top: -1px; left: -1px;"></span>
          </div>
          <div style="clear: both;"></div>
        </div>
      `)
    } else {
      return (
        `
        <div style="position: absolute; top: 50%; left: 50%; margin-top: -9px; margin-left: -12px; overflow: visible; cursor: auto;"
        id="jqxWidgetd0791853769a" tabindex="0" class="jqx-widget jqx-widget-material jqx-checkbox jqx-checkbox-material">
          <div class="jqx-checkbox-default jqx-checkbox-default-material jqx-fill-state-normal jqx-fill-state-normal-material jqx-rc-all jqx-rc-all-material ripple" style="filter: grayscale(1)">
            <div style="width: 16px; height: 16px;">
              <span style="width: 16px; height: 16px;"></span>
            </div>
            <span style="height: 18px; width: 18px; top: -1px; left: -1px;"></span>
          </div>
          <div style="clear: both;"></div>
        </div>
      `)
    }
  }

  public columnmenuopening(menu?: any, datafield?: any, height?: number | string): boolean | void {
    if (menu.length === 1) {
      const divElement: HTMLElement = menu[0];
      const dateTimeInputs = divElement.querySelectorAll('.jqx-datetimeinput');
      if (dateTimeInputs && dateTimeInputs.length > 0) {
        dateTimeInputs.forEach((input: HTMLElement) => {
          const elementRef = new ElementRef(input);
          const jqxDateTimeInput = new jqxDateTimeInputComponent(elementRef);
          this.inputDate = jqwidgets.createInstance('#' + jqxDateTimeInput.elementRef.nativeElement.id, 'jqxDateTimeInput', { width: '100%', height: 25, formatString: 'HH:mm:ss' });
          this.inputDate.setOptions({ showTimeButton: true, showCalendarButton: false });
        });
      }
    }
  }

  // filtro de tipo de producto
  filter = (cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean => {
    if (dataField === 'tipoContenedor') {
      let filterValue = filterGroup.getfilters()[0].value;

      switch (filterValue) {
        case this.translate('Contenedor_tipo_apertura_vaciado'):
          if (cellValue === 0) {
            return true;
          }
          break;
        case this.translate('Contenedor_tipo_comparte_trampilla'):
          if (cellValue === 1) {
            return true;
          }
          break;
        case this.translate('Contenedor_tipo_soterrado'):
          if (cellValue === 2) {
            return true;
          }
          break;
        case this.translate('Papelera'):
          if (cellValue === 3) {
            return true;
          }
          break;
        default:
          return false;
      }
    } else if (dataField === 'tipoCerradura') {
      let filterValue = filterGroup.getfilters()[0].value;

      switch (filterValue) {
        case this.translate('No_equipada'):
          if (cellValue === 0) {
            return true;
          }
          break;
        case this.translate('Cerradura_welock'):
          if (cellValue === 1) {
            return true;
          }
          break;
        default:
          return false;
      }
    } else if (dataField === 'tecnologia') {
      let filterValue = filterGroup.getfilters()[0].value;

      switch (filterValue) {
        case 'NB_IoT':
          if (cellValue === 0) {
            return true;
          }
          break;
        case 'LTE_Cat_M':
          if (cellValue === 1) {
            return true;
          }
          break;
        case 'GPRS_2G':
          if (cellValue === 2) {
            return true;
          }
          break;
        default:
          return false;
      }
    } else if (dataField === 'orientacion') {
      let filterValue = filterGroup.getfilters()[0].value;

      switch (filterValue) {
        case this.translate('Normal'):
          if (cellValue === false) {
            return true;
          }
          break;
        case this.translate('Invertida'):
          if (cellValue === true) {
            return true;
          }
          break;
        default:
          return false;
      }
    }
  }

  onResetFilter(event: any) {
    this.gridConfiguraciones.clearfilters();
  }

  /* Acciones del grid */
  onRowdoubleclick(event: any) {
    this.configuracionSelect = this.configuraciones[event.args.rowindex];

    this.onEditarConfiguracion();
  }

  /* Acciones de los botones */
  public onCrearConfiguracion() {
    this.form.collapse();

    this.formConfiguracion = this.editConfiguracionComponent.createComponent(ConfiguracionCerradurasEditComponent);
    this.formConfiguracion.instance.init(this.formConfiguracion, null, false);
  }

  public onEditarConfiguracion() {
    if (this.configuracionSelect) {
      this.form.collapse();

      this.formConfiguracion = this.editConfiguracionComponent.createComponent(ConfiguracionCerradurasEditComponent);
      this.formConfiguracion.instance.init(this.formConfiguracion, this.configuracionSelect, false);
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  /* Muestra la confirmación del borrado */
  public onBorrarConfiguracion() {
    if (this.configuracionSelect) {
      this.modal.confirm({
        nzTitle: '<i>' + this.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quiere_borrar_configuracion') + ' ' + this.configuracionSelect.nombre + ' ?',
        nzCentered: true,
        nzCancelText: this.translate('CANCELAR'),
        nzOnCancel: () => {
          this.form.expand();
        },
        nzOkText: this.translate('SI'),
        nzOnOk: async () => {
          this.removeConfiguracion();
          this.modal.closeAll();
        }
      });
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  /* Metodo que se llama al confirmar el borrado */
  async removeConfiguracion() {
    if (await this.configuracionCerraduraService.deleteConfiguracionCerradura(this.configuracionSelect.id)) {
      this.configuraciones.splice(this.configuraciones.indexOf(this.configuracionSelect), 1);

      this.adapterGridConfiguracion = new jqx.dataAdapter(this.sourceGridConfiguracion);
      this.configuracionSelect = null;

      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_borrado',
        2000
      );
    } else {
      MainComponent.getInstance().showWarning(
        'Error',
        'Error_api',
        2000
      );
    }
  }

  public onAsignarConfiguracion() {
    this.form.collapse();

    this.asignarConfiguracion = this.configuracionCerraduraAsignarComponent.createComponent(ConfiguracionCerraduraAsignarComponent);
    this.asignarConfiguracion.instance.init(this.asignarConfiguracion, this.configuraciones, this.configuracionSelect);
  }

  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getConfiguraciones();
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.configuraciones.forEach(conf => {
      // Comprueba si el valor del filtro coincide con algun residuo
      let residuo = false;
      conf?.equipamiento?.residuos?.forEach(res => {
        if (res?.nombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1) {
          residuo = true;
        }
      })

      // Comprueba si el valor del filtro coincide con alguna alarma
      let alarma = false;
      let content = [];
      if ((conf?.alarmas & 2) > 0) {
        content.push(this.translate("Alarma_bateria_baja"));
      }

      if ((conf?.alarmas & 4) > 0) {
        content.push(this.translate("Alarma_bateria_critica"));
      }

      if ((conf?.alarmas & 8) > 0) {
        content.push(this.translate("Cambio_bateria"));
      }

      if ((conf?.alarmas & 16) > 0) {
        content.push(this.translate("Temperatura_elevada"));
      }

      if ((conf?.alarmas & 32) > 0) {
        content.push(this.translate("Vaciado"));
      }

      if ((conf?.alarmas & 64) > 0) {
        content.push(this.translate("Diagnostico"));
      }

      if ((conf?.alarmas & 128) > 0) {
        content.push(this.translate("Puerta_abierta"));
      }

      if ((conf?.alarmas & 256) > 0) {
        content.push(this.translate("Puerta_violentada"));
      }

      if ((conf?.alarmas & 512) > 0) {
        content.push(this.translate("Vandalismo"));
      }

      content.forEach(alarm => {
        if (alarm?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1) {
          alarma = true;
        }
      })

      /*
        Filtra teniendo en cuenta los campos que se renderizan,
        para comparar con el texto renderizado en lugar del valor numerico
      */
      if (
        (conf?.nombre + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.host + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.puerto + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.tipoContenedor == 0 ?
          AppComponent.translate('Contenedor_tipo_apertura_vaciado')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 :
          conf?.tipoContenedor == 1 ?
            AppComponent.translate('Contenedor_tipo_comparte_trampilla')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 :
            conf?.tipoContenedor == 2 ?
              AppComponent.translate('Contenedor_tipo_soterrado')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 :
              conf?.tipoContenedor == 3 ?
                AppComponent.translate('Papelera')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 :
                false) ||
        (conf?.tipoCerradura == 0 ?
          AppComponent.translate('No_equipada')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 :
          conf?.tipoCerradura == 1 ?
            AppComponent.translate('Cerradura_welock')?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 :
            false) ||
        (conf?.equipamiento?.marca['nombre'] + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.equipamiento?.modelo['nombre'] + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (residuo) ||
        (conf?.equipamiento?.capacidad_litros + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.apn + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.apnUsuario + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.apnPassw + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.pin + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.tecnologia == 0 ?
          'NB Iot'?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 :
          conf?.tecnologia == 1 ?
            'LTE Cat M'.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 :
            conf?.tecnologia == 2 ?
              'GPRS 2G'.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 :
              false) ||
        (((conf?.horaEnvio / 60) + ':' + (conf?.horaEnvio % 60) + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1) ||
        (((conf?.intervaloEnvio / 60) + ':' + (conf?.intervaloEnvio % 60) + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1) ||
        (conf?.tiempoAbrir + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.tiempoAporte + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.tiempoCerrar + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.tiempoMaxApertura + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.tiempoMaxAperturaBatBaja + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.tiempoMaxCierre + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.tiempoMaxCierreBatBaja + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.deteccionVaciado + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (conf?.distanciaPared + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        (alarma) ||
        ((conf?.bateriaBaja / 100) + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        ((conf?.bateriaCritica / 100) + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        ((conf?.temperatura / 10) + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ) {
        conf['selec'] = 'selec';
      } else {
        conf['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.gridConfiguraciones.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.gridConfiguraciones.addfilter('selec', filtergroup);
    }
    this.gridConfiguraciones.applyfilters();
    this.gridConfiguraciones.updatebounddata('data');
  }

  onExportar() {
    if (this.gridConfiguraciones.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.gridConfiguraciones.exportdata('json');
      let datos = JSON.parse(json);
      datos.forEach(dato => {
        for (let prop in dato) {
          if (prop === '' || prop === undefined) {
            delete dato[prop];
          }
        }

        switch (dato[this.translate('Tipo_contenedor')]) {
          case '0,00':
            dato[this.translate('Tipo_contenedor')] = this.translate('Contenedor_tipo_apertura_vaciado');
            break;
          case '1,00':
            dato[this.translate('Tipo_contenedor')] = this.translate('Contenedor_tipo_comparte_trampilla');
            break;
          case '2,00':
            dato[this.translate('Tipo_contenedor')] = this.translate('Contenedor_tipo_soterrado');
            break;
          case '3,00':
            dato[this.translate('Tipo_contenedor')] = this.translate('Papelera');
            break;
          default:
            break;
        }

        switch (dato[this.translate('Tipo_cerradura')]) {
          case '0,00':
            dato[this.translate('Tipo_cerradura')] = this.translate('No_equipada');
            break;
          case '1,00':
            dato[this.translate('Tipo_cerradura')] = this.translate('Cerradura_welock');
            break;
          default:
            break;
        }

        // Residuos
        if (dato[this.translate('Residuo')]) {
          const residuos = [];
          dato[this.translate('Residuo')].forEach(({ nombre }) => {
            residuos.push(nombre);
          }
          );
          const commaSeparatedResiduos = residuos.join(', ');
          dato[this.translate('Residuo')] = commaSeparatedResiduos;
        }

        // hora envio
        const horaEnvio: any = isNaN(this.parseLocalizedNumber(dato[this.translate('Hora_envio')])) ? "" : this.parseLocalizedNumber(dato[this.translate('Hora_envio')]);
        const minutos = horaEnvio % 60;
        const horas = (horaEnvio - minutos) / 60;
        dato[this.translate('Hora_envio')] = horas + ':' + minutos;

        switch (dato[this.translate('Orientacion')]) {
          case true:
            dato[this.translate('Orientacion')] = this.translate('Normal');
            break;
          case false:
            dato[this.translate('Orientacion')] = this.translate('Invertida');
            break;
          default:
            break;
        }

        const alarmTypes = [
          { flag: 2, translationKey: "Alarma_bateria_baja" },
          { flag: 4, translationKey: "Alarma_bateria_critica" },
          { flag: 8, translationKey: "Cambio_bateria" },
          { flag: 16, translationKey: "Temperatura_elevada" },
          { flag: 32, translationKey: "Vaciado" },
          { flag: 64, translationKey: "Diagnostico" },
          { flag: 128, translationKey: "Puerta_abierta" },
          { flag: 256, translationKey: "Puerta_violentada" },
          { flag: 512, translationKey: "Vandalismo" },
        ];

        let content = [];

        const alarmFlags = dato[this.translate('Tipos_alarmas')];

        alarmTypes.forEach(({ flag, translationKey }) => {
          if (alarmFlags & flag) {
            content.push(this.translate(translationKey));
          }
        });

        const commaSeparatedContent = content.join(', ');
        dato[this.translate('Tipos_alarmas')] = commaSeparatedContent;

        switch (dato[this.translate('Comunicaciones')]) {
          case '0,00':
            dato[this.translate('Comunicaciones')] = 'NB IoT';
            break;
          case '1,00':
            dato[this.translate('Comunicaciones')] = 'LTE Cat M';
            break;
          case '2,00':
            dato[this.translate('Comunicaciones')] = 'GPRS 2G';
            break;
          default:
            break;
        }

        dato[this.translate('Alarma_bateria_baja') + '(volt)'] = isNaN(this.parseLocalizedNumber(dato[this.translate('Alarma_bateria_baja') + ' (v)'])) ? "" : this.parseLocalizedNumber(dato[this.translate('Alarma_bateria_baja') + ' (v)'])
        dato[this.translate('Alarma_bateria_critica') + '(volt)'] = isNaN(this.parseLocalizedNumber(dato[this.translate('Alarma_bateria_critica') + ' (v)'])) ? "" : this.parseLocalizedNumber(dato[this.translate('Alarma_bateria_critica') + ' (v)'])
        dato[this.translate('Temperatura') + ' (ºC)'] = isNaN(this.parseLocalizedNumber(dato[this.translate('Temperatura') + ' (ºC)'])) ? "" : this.parseLocalizedNumber(dato[this.translate('Temperatura') + ' (ºC)'])
        dato[this.translate('Distancia_pared_opuesta')] = isNaN(this.parseLocalizedNumber(dato[this.translate('Distancia_pared_opuesta')])) ? "" : this.parseLocalizedNumber(dato[this.translate('Distancia_pared_opuesta')]);
        dato[this.translate('Puerto')] = isNaN(this.parseLocalizedNumber(dato[this.translate('Puerto')])) ? "" : this.parseLocalizedNumber(dato[this.translate('Puerto')]);
        dato[this.translate('Intervalo_envio') + '(' + this.translate('En_hora') + ')'] = isNaN(this.parseLocalizedNumber(dato[this.translate('Intervalo_envio') + '(' + this.translate('En_hora') + ')'])) ? "" : this.parseLocalizedNumber(dato[this.translate('Intervalo_envio') + '(' + this.translate('En_hora') + ')']);
        dato[this.translate('Tiempo_apertura') + ' (seg.)'] = isNaN(this.parseLocalizedNumber(dato[this.translate('Tiempo_apertura') + ' (seg.)'])) ? "" : this.parseLocalizedNumber(dato[this.translate('Tiempo_apertura') + ' (seg.)']);
        dato[this.translate('Tiempo_aporte') + ' (seg.)'] = isNaN(this.parseLocalizedNumber(dato[this.translate('Tiempo_aporte') + ' (seg.)'])) ? "" : this.parseLocalizedNumber(dato[this.translate('Tiempo_aporte') + ' (seg.)']);
        dato[this.translate('Tiempo_cierre') + ' (seg.)'] = isNaN(this.parseLocalizedNumber(dato[this.translate('Tiempo_cierre') + ' (seg.)'])) ? "" : this.parseLocalizedNumber(dato[this.translate('Tiempo_cierre') + ' (seg.)']);
        dato[this.translate('Tiempo_max_apertura') + ' (seg.)'] = isNaN(this.parseLocalizedNumber(dato[this.translate('Tiempo_max_apertura') + ' (seg.)'])) ? "" : this.parseLocalizedNumber(dato[this.translate('Tiempo_max_apertura') + ' (seg.)']);
        dato[this.translate('Tiempo_max_apertura_bateria_baja') + ' (seg.)'] = isNaN(this.parseLocalizedNumber(dato[this.translate('Tiempo_max_apertura_bateria_baja') + ' (seg.)'])) ? "" : this.parseLocalizedNumber(dato[this.translate('Tiempo_max_apertura_bateria_baja') + ' (seg.)']);
        dato[this.translate('Tiempo_max_cierre') + '(seg.)'] = isNaN(this.parseLocalizedNumber(dato[this.translate('Tiempo_max_cierre') + ' (seg.)'])) ? "" : this.parseLocalizedNumber(dato[this.translate('Tiempo_max_cierre') + ' (seg.)']);
        dato[this.translate('Tiempo_max_cierre_bateria_baja') + ' (seg.)'] = isNaN(this.parseLocalizedNumber(dato[this.translate('Tiempo_max_cierre_bateria_baja') + ' (seg.)'])) ? "" : this.parseLocalizedNumber(dato[this.translate('Tiempo_max_cierre_bateria_baja') + ' (seg.)']);
        dato[this.translate('Capacidad')] = isNaN(this.parseLocalizedNumber(dato[this.translate('Capacidad')])) ? "" : this.parseLocalizedNumber(dato[this.translate('Capacidad')]);

        // compruebo que son de tipo number
        if (typeof dato[this.translate('Alarma_bateria_baja') + ' (v)'] === 'number' && typeof dato[this.translate('Alarma_bateria_critica') + ' (v)'] === 'number' && typeof dato[this.translate('Alarma_temperatura') + ' (ºC)'] === 'number') {
          dato[this.translate('Alarma_bateria_baja') + ' (v)'] = (dato[this.translate('Alarma_bateria_baja') + '(volt)']) / 100;
          dato[this.translate('Alarma_bateria_critica') + ' (v)'] = (dato[this.translate('Alarma_bateria_critica') + '(volt)']) / 100;
          dato[this.translate('Temperatura') + ' (ºC)'] = (dato[this.translate('Alarma_temperatura') + '(ºC)']) / 10;
        }
      })
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
      this.generateAutofilterHeader(ws);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Listado_configuración_ecoLock') + '.xlsx');
    }
  }

  parseLocalizedNumber(numberString) {
    numberString = numberString.replace(/\./g, '');
    numberString = numberString.replace(/,/g, '.');
    return parseFloat(numberString);
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Boton para imprimir
  onPrint(event: any) {
    if (this.gridConfiguraciones.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    }

    let rows = [...this.gridConfiguraciones.getrows()];

    rows.forEach(row => {
      let config: ConfiguracionCerraduraModel = this.configuraciones.find(conf => conf.id === row.id);

      row.tipoContenedor = this.__getTipoContenedorLabel(config.tipoContenedor);
      row.tipoCerradura = this.__getTipoCerraduraLabel(config.tipoCerradura);
      row.orientacion = this.__getOrientacionLabel(config.orientacion);
      if(config.equipamiento?.residuos) {
        row.equipamientoResiduo = this.__getResiduosLabel(config.equipamiento?.residuos);
      }
      row.horaEnvio = this.__getEnviosLabel(config.horaEnvio);
      row.intervaloEnvio = this.__getEnviosLabel(config.intervaloEnvio);
      row.anuncioBT = this.__getFrecuAnuncBt(config.frecuenciaAnunciaBT);
      row.distanciaParedOpuesta = this.__getFormatedNumberLabel(config.distanciaPared, 10);
      row.tipoAlarmas = this.__getTipoAlarmaLabel(config.alarmas);
      row.bateriaBaja = this.__getFormatedNumberLabel(config.bateriaBaja, 100);
      row.bateriaCritica = this.__getFormatedNumberLabel(config.bateriaCritica, 100);
      row.temperatura = this.__getFormatedNumberLabel(config.temperatura, 10);
    });

    let gridContent = this.gridConfiguraciones.exportdata('html', null, true, rows, false, null, 'utf-8');

    let newWindow = window.open('', '', 'width=800, height=500'),
      document = newWindow.document.open(),
      pageContent =
        '<!DOCTYPE html>\n' +
        '<html>\n' +
        '<head>\n' +
        '<meta charset="utf-8" />\n' +
        '<title>'+this.form.title()+'</title>\n' +
        '</head>\n' +
        '<body style="zoom: 50%; size: landscape">\n' +
        gridContent +
        '\n</body>\n</html>';
    document.write(pageContent);
    document.close();
    newWindow.onafterprint = function () {
      newWindow.close();
    };
    newWindow.print();
  }

  public onGridDataUpdated(event: any): void {
    this.__updateWindowTitle(this.gridConfiguraciones.getrows().length);
  }

  private __getTipoCerraduraLabel(tipoCerradura: number): string {
    let label: string = '';
    switch (tipoCerradura) {
      case 0:
        label = AppComponent.translate("No_equipada");
        break;
      case 1:
        label = AppComponent.translate("Cerradura_welock");
        break;
    }
    return label;
  }

  private __getTipoContenedorLabel(tipoContenedor: number): string {
    let label: string = '';
    switch(tipoContenedor) {
      case 0:
        label = AppComponent.translate("Contenedor_tipo_apertura_vaciado");
        break;
      case 1:
        label = AppComponent.translate("Contenedor_tipo_comparte_trampilla");
        break;
      case 2:
        label = AppComponent.translate("Contenedor_tipo_soterrado");
        break;
      case 3:
        label = AppComponent.translate("Papelera");
        break;
    }
    return label;
  }

  private __getOrientacionLabel(orientacion: number): string {
    let label: string = '';
    switch(orientacion) {
      case 0:
        label = '0º';
        break;
      case 1:
        label = '180º';
        break;
      case 2:
        label = '90º';
        break;
      case 3:
        label = '270º';
        break;
      case 4:
        label = AppComponent.translate('Tapa');
        break;
    }
    return label;
  }

  private __getResiduosLabel(residuos: BdtResiduoModel[]): string{
    let label: string = '';

    residuos.forEach((residuo, idx) => {
      if(idx === 0){
        label += residuo.nombre;
      }else {
        label += ', ' + residuo.nombre;
      }
    });

    return label;
  }

  private __getEnviosLabel(tiempo: number): string {
    let horas: number = Math.floor(tiempo / 60);
    let minutos: number = Math.floor(tiempo % 60);

    return (horas.toString().length != 1 ? horas : '0' + horas) + ':' + (minutos.toString().length != 1 ? minutos : '0' + minutos);
  }

  private __getFrecuAnuncBt(tipoAnunc: number): string {
    let label: string = '';

    if(tipoAnunc === 1){
      label = this.frecuenciAnuncBT[0];
    }else if(tipoAnunc === 3) {
      label = this.frecuenciAnuncBT[1];
    }else if(tipoAnunc === 5) {
      label = this.frecuenciAnuncBT[2];
    }else if(tipoAnunc === 8) {
      label = this.frecuenciAnuncBT[3];
    }else if(tipoAnunc === 10) {
      label = this.frecuenciAnuncBT[4];
    }

    return label;
  }

  private __getFormatedNumberLabel(value: number, divisor: number): string {
    let label: string = NumberUtils.format((value/divisor), 2);

    return label;
  }

  private __getTipoAlarmaLabel(tipoAlarma: number): string {
    let label: string = '';

    if ((tipoAlarma & 2) > 0) {
      label += (label !== '' ? ', ' : '') + this.translate("Alarma_bateria_baja");
    }

    if ((tipoAlarma & 4) > 0) {
      label += (label !== '' ? ', ' : '') + this.translate("Alarma_bateria_critica");
    }

    if ((tipoAlarma & 8) > 0) {
      label += (label !== '' ? ', ' : '') + this.translate("Cambio_bateria");
    }

    if ((tipoAlarma & 16) > 0) {
      label += (label !== '' ? ', ' : '') + this.translate("Temperatura_elevada");
    }

    if ((tipoAlarma & 32) > 0) {
      label += (label !== '' ? ', ' : '') + this.translate("Vaciado");
    }

    if ((tipoAlarma & 64) > 0) {
      label += (label !== '' ? ', ' : '') + this.translate("Diagnostico");
    }

    if ((tipoAlarma & 128) > 0) {
      label += (label !== '' ? ', ' : '') + this.translate("Puerta_abierta");
    }

    if ((tipoAlarma & 256) > 0) {
      label += (label !== '' ? ', ' : '') + this.translate("Puerta_violentada");
    }

    if ((tipoAlarma & 512) > 0) {
      label += (label !== '' ? ', ' : '') + this.translate("Vandalismo");
    }

    return label;
  }

  private __updateWindowTitle(countCerradures: number): void {
    this.windowTitle.set(this.translate('Listado_configuración_ecoLock') + ` (${countCerradures})`)
  }
}
