<jqxWindow #windowJob [jqxWindowAutoResize] [theme]="environment.tema" [position]="getFormPos(windowJob, 24)" [width]="mapWidth"
  [height]="mapHeight - 24" [zIndex]="900" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [title]="windowTitle()"
  [resizable]="true" (onExpand)="onExpand()" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <div style="float: left; width: 100%; height: 100%;">
      <div>
        <app-header #header [exportar]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
          (eventExportar)="onExportar()" (eventBuscador)="onBuscar()" (eventFilter)="eventFilter()"
          (eventResetFilter)="eventResetFilter()">
        </app-header>
      </div>
      <jqxGrid #gridJobs [theme]="environment.tema" [height]="'calc(100% - 80px)'" [width]="'100%'"
        [source]="adapterJobs"  [appCellClick] [columnsresize]="true" [localization]="langGrid" [filterable]="true"
        [showfilterrow]="false" [columns]="columnsJobs" [showrowlines]="false" [showcolumnlines]="true" [groupsheaderheight]="25"
        [pageable]="false" [enablebrowserselection]="true" [sortable]="true" [altrows]="true" [showstatusbar]="false"
        [showaggregates]="true" [rowsheight]="25" [columnsheight]="30" [enabletooltips]="false" [groupable]="true"
        [groupsrenderer]="groupsrenderer" [editable]="editable" [filter]="filter" [updatefilterconditions]="updatefilterconditions"
        (onGroupschanged)="resizeColumns()" (onCellclick)="onCellClick($event)" (onRowdoubleclick)="onRowdoubleclick($event)"
        (onCellvaluechanged)="onCellvaluechanged($event)" (onFilter)="onFilter($event)" (onBindingcomplete)="onBindingComplete()">
      </jqxGrid>
      <div>
        <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
          [value]="translate('Salir')">
        </jqxButton>
      </div>
    </div>
  </div>
</jqxWindow>

<div #formJob></div>
