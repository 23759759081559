import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';
import { AfterViewInit, Component, ElementRef, OnInit, signal, ViewChild } from '@angular/core';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from 'src/app/components/main/main.component';
import { AlarmaCerraduraModel } from 'src/app/services/cerraduras/models/alarmaCerradura';
import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { HistoricoEnvioIdentiiicadorModel } from 'src/app/services/historicoEnviosIdentificador/models/historicoEnviosIdentificador.model';
import { DateUtils } from 'src/app/utils/date-utils';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { NumberUtils } from 'src/app/utils/number-utils';
import { Utils } from 'src/app/utils/utils';
import { environment } from 'src/environments/environment';
import { PeriodoSelectComponent } from 'src/app/components/periodo-select/periodo-select.component';
import { DateIdentificacionModel } from 'src/app/services/cerraduras/models/dateIdentificacion';
import { CerraduraModel } from 'src/app/services/cerraduras/models/cerradura.model';
import { IdentificacionCerraduraModel } from 'src/app/services/cerraduras/models/identificacionCerradura';
import { ListadoCerraduraComponent } from '../listado-cerradura.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import * as xlsx from 'xlsx';
import { RendererUtils } from 'src/app/utils/RendererUtils';

@Component({
  selector: 'app-jqx-sub-tabs',
  templateUrl: './jqx-sub-tabs.component.html',
  styleUrls: ['./jqx-sub-tabs.component.css']
})
export class JqxSubTabsComponent implements OnInit, AfterViewInit {
  private componentRef = null;
  public theme = environment.tema;
  public langGrid = JqWidgets.getLocalization('es');

  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('gridAlarmas') myGrid: jqxGridComponent;
  @ViewChild('myGridEnvios') myGridEnvios: jqxGridComponent;
  @ViewChild('headerIdentificaciones') headerIdentificaciones: HeaderComponent;
  @ViewChild('headerAlarmas') headerAlarmas: HeaderComponent;
  @ViewChild('headerEnvios') headerEnvios: HeaderComponent;

  public cerradura: CerraduraModel = new CerraduraModel();
  columns = [];
  dataAdapter: any;
  alarmas: AlarmaCerraduraModel[] = [];
  historicosEnviosIdentificador: HistoricoEnvioIdentiiicadorModel[] = [];

  identificacion: IdentificacionCerraduraModel = new IdentificacionCerraduraModel;
  identificaciones: IdentificacionCerraduraModel[] = [];
  elementos: ElementoModel[] = [];
  sourceAlarmas: any;
  dataAdapteAlarmas: any;
  columnsAlarmas = [];
  columnsEnvios = [];
  sourceEnvios: any;
  dataAdapterEnvios: any;
  public static _this: JqxSubTabsComponent;
  showLoader: boolean = false;
  titleIdentificaciones: string = this.translate('Identificaciones');
  titleAlarmas: string = this.translate('Alarmas');
  titleEnvios: string = this.translate('Envios');

  countPartialResultsIdentificaciones = signal<number>(0);
  countPartialResultsAlarmas = signal<number>(0);
  countPartialResultsEnvios = signal<number>(0);



  constructor(private cerraduraService: CerraduraService) {
    JqxSubTabsComponent._this = this;
  }

  ngAfterViewInit(): void {
    this.setColumnHeightGrid();
  }

  ngOnInit(): void {
    this.initIdentificaciones();
  }

  getDateGroup(header: HeaderComponent) {
    return {
      fechaIni: header.periodoSelect.getFechaIni(),
      fechaFin: header.periodoSelect.getFechaFin()
    };
  }

  loadDataTabs() {
    const dateGroup = this.getDateGroup(this.headerIdentificaciones);
    ['alarmas', 'identificacion', 'envios'].forEach(action => this.handleAction(action, dateGroup));
  }

  handleAction(action: string, dateGroup: { fechaIni: Date; fechaFin: Date }) {
    const actionMap = {
      alarmas: () => this.aceptarAlarmas(dateGroup.fechaIni, dateGroup.fechaFin),
      identificacion: () => this.aceptarIdentificaciones(dateGroup.fechaIni, dateGroup.fechaFin),
      envios: () => this.aceptarEnvios(dateGroup.fechaIni, dateGroup.fechaFin),
    };
    actionMap[action] ? actionMap[action]() : console.warn(`Acción desconocida: ${action}`);
  }

  setColumnHeightGrid() {
    this.grid.columnsheight(20);
    this.myGrid.columnsheight(20);
    this.myGridEnvios.columnsheight(20);

  }

  onSelect(event: any) {
    if (event.args.selectedIndex == 1 && this.dataAdapteAlarmas == null) {
      this.getAlarmasByCerraduras();
    }
    else if (event.args.selectedIndex == 2 && this.dataAdapterEnvios == null) {
      this.initEnvios();
    }
  }

  renderRow(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      let date = new Date(value);
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px; text-align: left">' + DateUtils.formatDateTimeShort(date, true) + '</div>';
    }
  }

  renderPermiso(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value == 0) {
      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">  ${AppComponent.translate('Tarjeta_nfc')}</div>`;
    } else if (value == 1) {

      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';"> Bluetooth</div>`;
    }
  }

  renderTarjeta(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return `<div style="margin-top: 4px;  text-align: center"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';">  ${AppComponent.translate('Master')}</span></div>`;
    }
    else {
      return `<div style="margin-top: 4px;  text-align: center"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';">  ${AppComponent.translate('Blanca')}</span></div>`;
    }
  }

  rendexTextGeneric(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {

    if (typeof value === 'string') {
      return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;

    } else if (typeof value === 'number') {
      // para los campos altura libre, ancho y largo los muestro con dos decimales
      if (columnfield === AppComponent.translate('Temperatura_grados')) {
        value = value / 10;
        if (value === 0 || value) {
          value = NumberUtils.format(value, 2)
        }
      } else if (columnfield === AppComponent.translate('Voltaje_vatios')) {
        if (value === 0 || value) {
          value = value / 100;
          value = NumberUtils.format(value, 2)
        } else {
          value = NumberUtils.format(value, 2);
        }
      }
      return `<div style="margin-right: 4px; margin-top: 4px;  text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${value}</span></div>`;
    }
  }

  renderCheck(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        `
        <div style="position: absolute; top: 50%; left: 50%; margin-top: -9px; margin-left: -12px; overflow: visible; cursor: auto;"
        id="jqxWidgete18472e433a4" tabindex="0" class="jqx-widget jqx-widget-material jqx-checkbox jqx-checkbox-material" checked="true">
          <div class="jqx-checkbox-default jqx-checkbox-default-material jqx-fill-state-normal jqx-fill-state-normal-material jqx-rc-all jqx-rc-all-material ripple" style="filter: grayscale(1)">
            <div style="width: 16px; height: 16px;">
              <span style="width: 16px; height: 16px;" class="jqx-checkbox-check-checked jqx-checkbox-check-checked-material"></span>
            </div>
            <span style="height: 18px; width: 18px; top: -1px; left: -1px;"></span>
          </div>
          <div style="clear: both;"></div>
        </div>
      `)
    } else {
      return (
        `
        <div style="position: absolute; top: 50%; left: 50%; margin-top: -9px; margin-left: -12px; overflow: visible; cursor: auto;"
        id="jqxWidgetd0791853769a" tabindex="0" class="jqx-widget jqx-widget-material jqx-checkbox jqx-checkbox-material">
          <div class="jqx-checkbox-default jqx-checkbox-default-material jqx-fill-state-normal jqx-fill-state-normal-material jqx-rc-all jqx-rc-all-material ripple" style="filter: grayscale(1)">
            <div style="width: 16px; height: 16px;">
              <span style="width: 16px; height: 16px;"></span>
            </div>
            <span style="height: 18px; width: 18px; top: -1px; left: -1px;"></span>
          </div>
          <div style="clear: both;"></div>
        </div>
      `)
    }
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (columnfield == 'bateriaPorcentaje') {
      return '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' +
        NumberUtils.format(value, 0) +
        '%</div>';
    } else if (columnfield === 'temperatura') {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' +
        NumberUtils.format(value, 0) +
        'º</div>'
      );
    } else {
      return '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' + NumberUtils.format(value, 0) + '</div>';
    }
  }

  renderText(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (rowdata.idElemento == 0 || rowdata.idElemento == null) {
      return '';
    } else if (rowdata.nombreElemento == null || rowdata.nsCerradura == null) {
      return '';
    } else {
      return '<div style="margin-right: 4px; margin-top: 5px;">' + rowdata.nombreElemento + '</div>';
    }
  }

  renderIdentificacion(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value == 0) {
      return '<div  style="margin-left: 4px; margin-top: 4px">NFC</div>';
    } else if (value == 1) {
      return '<div  style="margin-left: 4px; margin-top: 4px">Bluetooth</div>';
    }
  }

  renderCiudadano(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (rowdata.nombreCiudadano == null) {
      return '';
    } else {
      return '<div style="margin-left: 4px; margin-top: 4px;">' + rowdata.nombreCiudadano + '</div>';
    }
  }

  renderNsTarjeta(row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      return `<div style="margin-right: 4px; margin-top: 4px; text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${NumberUtils.format(value, 0)}</span></div>`;
    }
  }

  cellClass = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'cellTooltip';
    }
    return '';
  }

  public columnmenuopening(menu?: any, datafield?: any, height?: number | string): boolean | void {
    if (menu.length === 1) {
      if (datafield === 'fechaEnvio' || datafield === 'horaEnvio' || datafield === 'fecha' || datafield === 'hora') {
        const configureDateTimeInput = (menuElement: HTMLElement, options: { showTimeButton: boolean; showCalendarButton: boolean, formatString: string }) => {
          const dateTimeInputs = menuElement.querySelectorAll('.jqx-datetimeinput');
          if (dateTimeInputs && dateTimeInputs.length > 0) {
            dateTimeInputs.forEach((input: HTMLElement) => {
              const elementRef = new ElementRef(input);
              const jqxDateTimeInput = new jqxDateTimeInputComponent(elementRef);
              const inputDate = jqwidgets.createInstance('#' + jqxDateTimeInput.elementRef.nativeElement.id, 'jqxDateTimeInput', { width: '100%', height: 25 });
              inputDate.setOptions(options);
            });
          }
        };

        const divElement: HTMLElement = menu[0];
        if (datafield === 'fechaEnvio' || datafield === 'fecha') {
          configureDateTimeInput(divElement, { showTimeButton: false, showCalendarButton: true, formatString: 'dd/MM/yyyy' });
        } else if (datafield === 'horaEnvio' || datafield === 'hora') {
          configureDateTimeInput(divElement, { showTimeButton: true, showCalendarButton: false, formatString: 'HH:mm:ss' });
        }
      }
    }
  }

  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    // El cierre del div se hace en el return del texto a mostrar
    let showText = `
      <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
        <b>`+ data?.groupcolumn?.datafield + `: </b>
      `;
    if (
      data?.groupcolumn?.datafield === AppComponent.translate('Aporte_residuo') ||
      data?.groupcolumn?.datafield === AppComponent.translate('Apertura_tapa') ||
      data?.groupcolumn?.datafield === AppComponent.translate('No_cerro_tapa')
    ) {
      if (group === true) {
        showText += AppComponent.translate('SI');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      } else if (group === false) {
        showText += AppComponent.translate('NO');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      }
      return showText + "</div>";
    } else if (data?.groupcolumn?.datafield === AppComponent.translate('Tipo_identificacion')) {
      if (group === 1) {
        showText += 'Bluetooth';
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      } else if (group === 0) {
        showText += AppComponent.translate('Tarjeta_nfc');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      }
      return showText + "</div>";
    } else if (data?.groupcolumn?.datafield === AppComponent.translate('Tipo_permiso')) {
      if (group) {
        showText += AppComponent.translate('Master');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      } else {
        showText += AppComponent.translate('Blanca');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      }
      return showText + "</div>";
    }
  }

  renderDate(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    return `<div style=" margin-top: 4px;  text-align: center;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${value}</span></div>`;
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(
    cellValue?: any,
    rowData?: any,
    dataField?: string,
    filterGroup?: any,
    defaultFilterResult?: boolean
  ): any {

    if (!dataField || !filterGroup) {
      return defaultFilterResult;
    }

    const filterOption = filterGroup.getfilters()?.[0]?.value;
    if (dataField === 'noCerroTapa' || dataField === 'aperturaTapa' || dataField === 'aporte') {
      switch (filterOption) {
        case AppComponent.translate('SI'):
          return cellValue === true;
        case AppComponent.translate('NO'):
          return cellValue === false;
        default:
          return defaultFilterResult;
      }
    } else if (dataField === 'medio') {
      switch (filterOption) {
        case 'Bluetooth':
          return cellValue === 1;
        case AppComponent.translate('Tarjeta_nfc'):
          return cellValue === 0;
        default:
          return defaultFilterResult;
      }
    } else if (dataField === 'tipoPermiso') {
      switch (filterOption) {
        case AppComponent.translate('Master'):
          return cellValue === 'MASTER';
        case AppComponent.translate('Blanca'):
          return cellValue === 'BLANCA';
        default:
          return defaultFilterResult;
      }
    }
    else {

      const filterColumns = [
        'fecha',
        'nsTarjeta',
        'nombreCiudadano',
        'nombreElemento',
        'marcaElemento',
        'modeloElemento',
        'matriculaElemento',
        'direccionElemento',
        'municipioElemento',
        'poblacionElemento',
      ];

      return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);

    }
  }

  public filterAlarmas(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      AppComponent.translate('Ns_movisat'),
      AppComponent.translate('Alarma'),
      AppComponent.translate('Nombre_elemento'),
      AppComponent.translate('Marca'),
      AppComponent.translate('Modelo'),
      AppComponent.translate('Matricula'),
      AppComponent.translate('Observaciones'),
      AppComponent.translate('Direccion'),
      AppComponent.translate('Municipio'),
      AppComponent.translate('Poblacion')
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  onfilter(event) {
    this.countPartialResultsIdentificaciones.set(this.grid.getrows().length);
  }

  onfilterAlarmas(event) {
    this.countPartialResultsAlarmas.set(this.myGrid.getrows().length);
  }

  onfilterEnvios(event) {
    this.countPartialResultsEnvios.set(this.myGridEnvios.getrows().length);
  }

  onBindingcompleteEnvios(event) {
    this.countPartialResultsEnvios.set(this.myGridEnvios.getrows().length);
  }

  onBindingcompleteIdentificaciones(event) {
    this.countPartialResultsIdentificaciones.set(this.grid.getrows().length);
  }

  onBindingcompleteAlarmas(event) {
    this.countPartialResultsAlarmas.set(this.myGrid.getrows().length);
  }

  resizeColumnsAlarmas() {
    if (this.alarmas.length != 0) {
      this.myGrid.attrColumns.forEach((column: any) => {
        try {
          if (column.datafield !== this.translate('Temperatura_grados') &&
            column.datafield !== this.translate('Voltaje_vatios') &&
            column.datafield !== this.translate('Bateria_porcentaje')
          ) {
            this.myGrid.autoresizecolumn(column.datafield, column.columntype);
          }
        } catch (e) { }
      });
      Utils.renderSizeGrid(
        this.myGrid,
        500,
        [this.translate('Temperatura_grados'), this.translate('Voltaje_vatios'), this.translate('Bateria_porcentaje')]
      );
    }
  }

  dataAdapterIdentificaciones: any;
  sourceIdentificaciones: any;
  public columnsIdentificacines = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'idCerradura', hidden: true },
    {
      text: this.translate('Fecha'),
      columntype: 'datetimeinput',
      filtertype: 'textbox',
      datafield: 'fecha',
      width: 150,
    },
    {
      text: this.translate('Ns_tarjeta'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'nsTarjeta',
      width: 100,
      cellsrenderer: this.renderNsTarjeta,
    },
    {
      text: this.translate('Ciudadano'),
      columntype: 'textbox',
      filtertype: 'textbox',
      width: 100,
      datafield: 'nombreCiudadano',
      cellsrenderer: this.renderCiudadano
    },
    { text: this.translate('Aporte_residuo'), columntype: 'checkbox', filtertype: 'bool', width: 110, datafield: 'aporteResiduo' },
    { text: this.translate('Apertura_tapa'), columntype: 'checkbox', filtertype: 'bool', width: 100, datafield: 'aperturaTapa' },
    { text: this.translate('No_cerro_tapa'), columntype: 'checkbox', filtertype: 'bool', width: 90, datafield: 'noCerroTapa' },
    {
      text: this.translate('Tipo_identificacion'),
      columntype: 'textbox',
      width: 130,
      filtertype: 'textbox',
      datafield: 'medio',
      cellsrenderer: this.renderIdentificacion
    },
    {
      text: this.translate('Tipo_permiso'),
      columntype: 'textbox',
      width: 100,
      filtertype: 'textbox',
      datafield: 'tipoPermiso',
      cellsrenderer: this.renderRow,
    },
    { text: 'selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
  ];

 
  initIdentificaciones() {
    this.columns = [
      { text: this.translate('Fecha'), columntype: 'datetimeinput', filtertype: 'date', datafield: 'fecha', cellsformat: 'dd/MM/yyyy', width: 90, cellclassname: this.cellClass, },
      { text: this.translate('Hora'), columntype: 'datetimeinput', filtertype: 'date', datafield: 'hora', cellsformat: 'HH:mm:ss', width: 90, cellclassname: this.cellClass, },
      { text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', width: 100, datafield: 'nsMovisat', cellclassname: this.cellClass },
      { text: this.translate('Ns_tarjeta'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nsTarjeta', width: 100, cellsrenderer: this.renderNsTarjeta, },

      { text: this.translate('Ciudadano'), columntype: 'textbox', filtertype: 'textbox', datafield: 'ciudadano', width: 90, cellclassname: this.cellClass },

      {
        text: this.translate('Aporte_residuo'), columntype: 'checkbox', datafield: 'aporte', cellsrenderer: this.renderCheck, type: 'boolean',
        width: 110,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('SI'),
          this.translate('NO'),
        ],
      },
      {
        text: this.translate('Apertura_tapa'), columntype: 'checkbox', datafield: 'aperturaTapa', cellsrenderer: this.renderCheck, type: 'boolean',
        width: 100,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('SI'),
          this.translate('NO'),
        ],
      },
      {
        text: this.translate('No_cerro_tapa'), columntype: 'checkbox', datafield: 'noCerroTapa', cellsrenderer: this.renderCheck, type: 'boolean',
        width: 85,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('SI'),
          this.translate('NO'),
        ],
      },

      {
        text: this.translate('Tipo_identificacion'), columntype: 'textbox', datafield: 'medio', cellsrenderer: this.renderPermiso, sortable: false, cellclassname: this.cellClass,
        filtertype: 'checkedlist', width: 110,
        filteritems: [
          this.translate('Tarjeta_nfc'),
          'Bluetooth',
        ],
      },
      {
        text: this.translate('Tipo_permiso'), columntype: 'textbox', datafield: 'tipoPermiso', cellsrenderer: this.renderTarjeta,
        filtertype: 'checkedlist', width: 100,
        filteritems: [
          this.translate('Master'),
          this.translate('Blanca')
        ],
        cellclassname: this.cellClass
      },

      { text: this.translate('Nombre_elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombreElemento', width: 120, cellclassname: this.cellClass },
      { text: this.translate('Marca'), columntype: 'textbox', filtertype: 'textbox', datafield: 'marcaElemento', width: 110, cellclassname: this.cellClass },
      { text: this.translate('Modelo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'marcaEModelo', width: 110, cellclassname: this.cellClass },
      { text: this.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', datafield: 'matriculaElemento', width: 110, cellclassname: this.cellClass },
      { text: this.translate('Direccion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'direccionElemento', width: 100, cellclassname: this.cellClass },
      { text: this.translate('Municipio'), columntype: 'textbox', filtertype: 'textbox', datafield: 'municipioElemento', width: 100, cellclassname: this.cellClass },
      { text: this.translate('Poblacion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'poblacionElemento', width: 110, cellclassname: this.cellClass },
      { text: 'select', datafield: 'selec', columntype: 'textbox', filtertype: 'textbox', filterable: true, hidden: true }
    ];

    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });

    this.sourceIdentificaciones = {
      datatype: 'json',
      dataFields: [
        { name: 'fecha', type: 'date', map: 'fecha' },
        { name: 'hora', type: 'date', map: 'fecha' },
        { name: 'nsMovisat', type: 'string', map: 'nsCerradura' },
        { name: 'nombreCiudadano', type: 'string', map: 'nombreCiudadano' },
        { name: 'aporte', type: 'bool', map: 'aporte' },
        { name: 'aperturaTapa', type: 'bool', map: 'aperturaTapa' },
        { name: 'noCerroTapa', type: 'bool', map: 'noCerroTapa' },
        { name: 'medio', type: 'string', map: 'medio' },
        { name: 'tipoPermiso', type: 'string', map: 'tipoPermiso' },
        { name: 'nombreElemento', type: 'string', map: 'nombreElemento' },
        { name: 'marcaElemento', type: 'string', map: 'marcaElemento' },
        { name: 'modeloElemento', type: 'string', map: 'modeloElemento' },
        { name: 'matriculaElemento', type: 'string', map: 'matriculaElemento' },
        { name: 'observacionesElemento', type: 'string', map: 'observacionesElemento' },
        { name: 'direccionElemento', type: 'string', map: 'direccionElemento' },
        { name: 'municipioElemento', type: 'string', map: 'municipioElemento' },
        { name: 'poblacionElemento', type: 'string', map: 'poblacionElemento' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.identificaciones,
    };
    this.dataAdapterIdentificaciones = new jqx.dataAdapter(this.sourceIdentificaciones);
  }

  //ALARMAS
  getAlarmasByCerraduras() {
    this.columnsAlarmas = [
      { text: this.translate('Fecha'), columntype: 'datetimeinput', filtertype: 'date', datafield: 'fecha', cellsformat: 'dd/MM/yyyy', width: 90, cellclassname: this.cellClass, },
      { text: this.translate('Hora'), columntype: 'datetimeinput', filtertype: 'date', datafield: 'hora', cellsformat: 'HH:mm:ss', width: 80, cellclassname: this.cellClass, },
      { text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nsMovisat', width: 110, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Alarma'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'alarma', width: 90, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Bateria_porcentaje'), columntype: 'textbox', filtertype: 'textbox', datafield: 'bateriaPorcentaje', width: 90, cellsrenderer: this.rendexTextGeneric, },
      { text: this.translate('Temperatura_grados'), columntype: 'textbox', filtertype: 'textbox', datafield: 'temperatura', width: 100, cellsrenderer: this.rendexTextGeneric, },
      { text: this.translate('Voltaje_vatios'), columntype: 'textbox', filtertype: 'textbox', datafield: 'voltaje', width: 70, cellsrenderer: this.rendexTextGeneric, },
      { text: this.translate('Nombre_elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombreElemento', width: 120, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Marca'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'marcaElemento', width: 70, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Modelo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'modeloMatricula', width: 70, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', datafield: 'matriculaElemento', width: 90, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox', datafield: 'observacionesElemento', sortable: false, filterable: false, width: 100, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Direccion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'direccionElemento', width: 100, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Municipio'), columntype: 'textbox', filtertype: 'textbox', datafield: 'municipioElemento', width: 90, cellsrenderer: this.rendexTextGeneric },
      { text: this.translate('Poblacion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'poblacionElemento', width: 90, cellsrenderer: this.rendexTextGeneric },
      { name: 'selec', map: 'selec', filterable: true, columntype: 'textbox', filtertype: 'textbox', hidden: true }
    ];

    this.columnsAlarmas.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });

    this.sourceAlarmas = {
      datatype: 'json',
      dataFields: [
        { name: 'fecha', type: 'date', map: 'fecha' },
        { name: 'hora', type: 'date', map: 'fecha' },
        { name: 'nsMovisat', type: 'string', map: 'nsCerradura' },
        { name: 'alarma', type: 'string', map: 'alarma' },

        { name: 'bateriaPorcentaje', type: 'number', map: 'bateriaPorcentaje' },
        { name: 'temperatura', type: 'number', map: 'temperatura' },
        { name: 'voltaje', type: 'number', map: 'voltaje' },

        { name: 'nombreElemento', type: 'string', map: 'nombreElemento' },
        { name: 'marcaElemento', type: 'string', map: 'marcaElemento' },
        { name: 'modeloElemento', type: 'string', map: 'modeloElemento' },
        { name: 'matriculaElemento', type: 'string', map: 'matriculaElemento' },
        { name: 'observacionesElemento', type: 'string', map: 'observacionesElemento' },

        { name: 'direccionElemento', type: 'string', map: 'direccionElemento' },
        { name: 'municipioElemento', type: 'string', map: 'municipioElemento' },
        { name: 'poblacionElemento', type: 'string', map: 'poblacionElemento' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.alarmas
    };
    this.dataAdapteAlarmas = new jqx.dataAdapter(this.sourceAlarmas);
  }

  initEnvios() {
    this.columnsEnvios = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
      {
        text: this.translate('Fecha'),
        columntype: 'datetimeinput',
        filtertype: 'date',
        cellsformat: 'dd/MM/yyyy',
        width: 90,
        datafield: 'fechaEnvio',
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Hora'),
        columntype: 'datetimeinput',
        filtertype: 'date',
        width: 80,
        datafield: 'horaEnvio',
        cellsformat: 'HH:mm:ss',
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Ns_movisat'),
        width: 160,
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'nsMovisat',
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Num_identificaciones'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'cantidad',
        width: 100,
        cellsrenderer: RendererUtils.numberrenderer,
      },
      {
        text: this.translate('Bateria') + ' (%)',
        columntype: 'textbox',
        filtertype: 'textbox',
        width: 60,
        datafield: 'bateriaPorcentaje',
        cellsrenderer: RendererUtils.numberrenderer,
      },
      {
        text: this.translate('Temperatura') + ' (ºC)',
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'temperatura',
        width: 80,
        cellsrenderer: RendererUtils.numberrenderer,
      },

      {
        text: this.translate('Nombre_elemento'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'nombreElemento',
        width: 110,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Matricula'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'matriculaElemento',
        width: 75,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Codigo_postal'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'codigoPostal',
        width: 80,
        cellsrenderer: RendererUtils.numberrenderer,
      },
      {
        text: this.translate('Latitud'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'lat',
        width: 80,
        cellsalign: 'right',
        cellsrenderer: RendererUtils.numberrenderer,
      },
      {
        text: this.translate('Longitud'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'lng',
        width: 80,
        cellsalign: 'right',
        cellsrenderer: RendererUtils.numberrenderer,
      },
      {
        text: this.translate('Estado'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'nombreEstado',
        width: 100,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Num_calle'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'numeroCalle',
        cellalign: 'right',
        width: 60,
        cellsrenderer: RendererUtils.numberrenderer,
      },
      {
        text: this.translate('Direccion'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'calle',
        width: 100,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Municipio'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'municipio',
        width: 70,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Poblacion'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'poblacion',
        width: 70,
        cellclassname: this.cellClass
      },
      {
        text: this.translate('Provincia'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'provincia',
        width: 70,
        cellclassname: this.cellClass
      },
      { text: 'selec', datafield: 'selec', filterable: true, columntype: 'textbox', filtertype: 'textbox', hidden: true }
    ];

    this.columnsEnvios.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });

    this.elementos = MainComponent.getInstance().elementsList;

    this.sourceEnvios = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'idCerradura', type: 'number', map: 'idCerradura' },
        { name: 'fechaEnvio', type: 'date', map: 'fecha' },
        { name: 'horaEnvio', type: 'date', map: 'fecha' },
        { name: 'estado', type: 'number', map: 'estado' },
        { name: 'nombreEstado', type: 'string', map: 'nombreEstado' },
        { name: 'estadoInfo', type: 'number', map: 'estadoInfo' },
        { name: 'red', type: 'number', map: 'red' },
        { name: 'mcc', type: 'number', map: 'mcc' },
        { name: 'mnc', type: 'number', map: 'mnc' },
        { name: 'csq', type: 'number', map: 'csq' },
        { name: 'bateriaPorcentaje', type: 'number', map: 'bateria' },
        { name: 'temperatura', type: 'number', map: 'temperatura' },
        { name: 'temperaturaMin', type: 'number', map: 'temperaturaMin' },
        { name: 'temperaturaMax', type: 'number', map: 'temperaturaMax' },
        { name: 'lat', type: 'number', map: 'lat' },
        { name: 'lng', type: 'number', map: 'lng' },
        { name: 'empresa', type: 'number', map: 'empresa' },
        { name: 'nsMovisat', type: 'string', map: 'nsCerradura' },
        { name: 'modoOperacion', type: 'number', map: 'modoOperacion' },
        { name: 'nombreElemento', type: 'string', map: 'nombreElemento' },
        { name: 'matriculaElemento', type: 'string', map: 'matriculaElemento' },
        { name: 'idElemento', type: 'number', map: 'idElemento' },
        { name: 'pais', type: 'string', map: 'pais' },
        { name: 'comunidadAutonoma', type: 'string', map: 'comunidadAutonoma' },
        { name: 'provincia', type: 'string', map: 'provincia' },
        { name: 'municipio', type: 'string', map: 'municipio' },
        { name: 'poblacion', type: 'string', map: 'poblacion' },
        { name: 'calle', type: 'string', map: 'calle' },
        { name: 'numeroCalle', type: 'string', map: 'numeroCalle' },
        { name: 'codigoPostal', type: 'string', map: 'codigoPostal' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.historicosEnviosIdentificador,
    };
    this.dataAdapterEnvios = new jqx.dataAdapter(this.sourceEnvios);
  };

  envioIdentificador: HistoricoEnvioIdentiiicadorModel = new HistoricoEnvioIdentiiicadorModel();
  gridIdentificaciones: jqxGridComponent;
  initRowDetails = (
    index: any,
    parentElement: any,
    gridElement: any,
    datarecord: any
  ): void => {
    if (parentElement && datarecord) {
      let nestedGridContainer = parentElement.children[0];

      let i = this.historicosEnviosIdentificador.findIndex(
        (envio) => envio.id === datarecord.id
      );

      if (this.envioIdentificador) {
        this.envioIdentificador = new HistoricoEnvioIdentiiicadorModel();
      }
      this.envioIdentificador = this.historicosEnviosIdentificador[i];
      if (this.envioIdentificador) {
        let sourceDetalle = {
          datatype: 'array',
          datafields: [
            { name: 'idCerradura', type: 'number', map: 'idCerradura' },
            { name: 'fecha', type: 'date', map: 'fecha' },
            { name: 'nsTarjeta', type: 'number', map: 'nsTarjeta' },
            { name: 'nsMovisat', type: 'string', map: 'nsCerradura' },
            { name: 'nombreCiudadano', type: 'string', map: 'nombreCiudadano' },
            { name: 'aporteResiduo', type: 'boolean', map: 'aporte' },
            { name: 'aperturaTapa', type: 'boolean', map: 'aperturaTapa' },
            { name: 'noCerroTapa', type: 'boolean', map: 'noCerroTapa' },
            //{ name: 'nsCerradura', type: 'string', map: 'nsCerradura' },
            { name: 'observaciones', type: 'string', map: 'observacionesElemento' },
            { name: 'matricula', type: 'string', map: 'matriculaElemento' },
            { name: 'medio', type: 'string', map: 'medio' },
            { name: 'tipoPermiso', type: 'string', map: 'tipoPermiso' },
            { name: 'marca', type: 'string', map: 'marcaElemento' },
            { name: 'modelo', type: 'string', map: 'modeloElemento' },
            { name: 'nombreElemento', type: 'string', map: 'nombreElemento' },
            { name: 'direccion', type: 'string', map: 'direccionElemento' },
            { name: 'municipio', type: 'string', map: 'municipioElemento' },
            { name: 'poblacion', type: 'string', map: 'poblacionElemento' },
            { name: 'selec', map: 'selec' }
          ],
          localdata: this.envioIdentificador.Detalle,
        };

        let dataDetalleEnvios = new jqx.dataAdapter(sourceDetalle);

        if (nestedGridContainer != null) {
          let setting = {
            width: '97%',
            height: '90%',
            source: dataDetalleEnvios,
            theme: this.theme,
            columns: this.columnsIdentificacines,
            localization: this.langGrid,
            editable: false,
            columnsresize: true
            // me subcribo al evento de doble click en la fila
          };
          this.gridIdentificaciones = jqwidgets.createInstance(
            `#${nestedGridContainer.id}`,
            'jqxGrid',
            setting
          );
        }
      }
    } else {
      return;
    }
  };

  showRowDetails: any[] = [];
  rowCollapse(event: any) {
    let id = this.myGrid.getrowdata(event.args.rowindex)?.id;
    this.showRowDetails.splice(this.showRowDetails.indexOf(id), 1);
  }

  rowdetailstemplate = (index: any): any => {
    var details = {
      rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
      rowdetailsheight: 180,
      rowdetailshidden: true,
    };
    this.editrow = index;
    //Saca el usuario y adapta el row height al numero de accesos que tenga
    let rowId = this.myGrid.getcellvalue(index, 'id');
    let envio = this.historicosEnviosIdentificador.find(x => x.id == rowId);

    if (envio) {
      if (!envio.Detalle) {
        return details;
      }
    }
    return details;
  };

  onSort(event: any) {
    this.showRowDetails.forEach(row => {
      this.myGrid.getrows().forEach(elem => {
        if (row === elem.id) {
          this.myGrid.showrowdetails(elem.boundindex);
        }
      });
    });
  }

  rowExpand(event: any) {
    let rowId = this.myGrid.getcellvalue(event.args.rowindex, 'id');
    let envio = this.historicosEnviosIdentificador.find(x => x.id == rowId);

    if (!envio || envio.cantidad == 0) {
      this.myGrid.hiderowdetails(event.args.rowindex);
    } else {
      let id = this.myGrid.getrowdata(event.args.rowindex).id;
      if (!(this.showRowDetails.includes(id))) {
        this.showRowDetails.push(id);
      }
    }
  }

  editrow: number = -1;
  aceptarEnvios = async (fechaIni: Date, fechaFin: Date) => {
    if (!this.dataAdapterEnvios) { return; }
    this.showLoader = true;

    let date = new DateIdentificacionModel();
    date.Desde = fechaIni;
    date.Hasta = fechaFin;

    this.cerradura = ListadoCerraduraComponent.instance.cerradura;

    this.historicosEnviosIdentificador = await this.cerraduraService.getHistoricoCerradura(this.cerradura.id, date);

    this.sourceEnvios.localdata = this.historicosEnviosIdentificador;
    this.myGridEnvios.updatebounddata();
    this.showLoader = false;
  }

  aceptarAlarmas = async (fechaIni: Date, fechaFin: Date) => {
    if (!this.sourceAlarmas) { return; }
    this.showLoader = true;

    let date = new DateIdentificacionModel;;
    date.Desde = fechaIni;
    date.Hasta = fechaFin;

    this.cerradura = ListadoCerraduraComponent.instance.cerradura;

    this.alarmas = await this.cerraduraService.getAlarmasCerradura(this.cerradura.id, date);
    this.alarmas.forEach((element) => {
      element.fecha = new Date(element.fecha);
      element.fechaRecibido = new Date(element.fechaRecibido);
    });

    this.sourceAlarmas.localdata = this.alarmas;
    this.myGrid.updatebounddata();
    this.showLoader = false;
  }

  aceptarIdentificaciones = async (fechaIni: Date, fechaFin: Date) => {
    this.showLoader = true;

    let date = new DateIdentificacionModel();
    date.Desde = fechaIni;
    date.Hasta = fechaFin;

    this.cerradura = ListadoCerraduraComponent.instance.cerradura;

    this.identificaciones = await this.cerraduraService.getIdentificacionesDate(this.cerradura.id, date);

    this.sourceIdentificaciones.localdata = this.identificaciones;
    this.grid.updatebounddata();
    this.showLoader = false;
  }

  onExportar(arg0: string, $event: any) {
    switch (arg0) {
      case 'alarmas':
        this.onExportarAlarmas();
        break;
      case 'identificacion':
        this.onExportarIdentificaciones();
        break;
      case 'envios':
        this.onExportarEnvios();
        break;
      default:
        return;
    }
  }

  // Función común para eliminar campos innecesarios
  removeUnwantedFields(element: any, fields: string[]): any {
    fields.forEach(field => delete element[field]);
    return element;
  }

  // Función común para formatear fecha y hora
  formatDateAndTime(element: any, dateField: string): any {
    if (element[dateField]) {
      const fechaCompleta = new Date(element[dateField]);
      const [fecha, hora] = element[dateField].split(' ');
      element[dateField] = fecha;
      element['Hora'] = hora;
    }
    return element;
  }

  onExportarAlarmas() {
    const json = JSON.parse(JSON.stringify(this.myGrid.getrows()));

    json.forEach(element => {
      // Eliminar campos innecesarios
      this.removeUnwantedFields(element, ['uid', 'uniqueid', 'visibleindex', 'boundindex', 'selec', 'dataindex']);

      // Formatear fecha y hora
      element = this.formatDateAndTime(element, this.translate('Fecha'));

      // Formatear otros campos numéricos
      element[this.translate('Temperatura_grados')] = this.formatNumericField(element[this.translate('Temperatura_grados')], 10, 2);
      element[this.translate('Voltaje_vatios')] = this.formatNumericField(element[this.translate('Voltaje_vatios')], 100, 2);
      element[this.translate('Bateria_porcentaje')] = this.formatNumericField(element[this.translate('Bateria_porcentaje')], 1, 2);
    });

    this.exportToExcel(json, 'Historico_alarmas_IoT');
  }

  // Función reutilizable para formatear campos numéricos
  formatNumericField(value: any, divisor: number, decimalPlaces: number): string {
    const result = (value / divisor).toFixed(decimalPlaces);
    return isNaN(Number(result)) ? '' : result;
  }

  onExportarIdentificaciones() {
    const json = JSON.parse(JSON.stringify(this.grid.getdisplayrows()));

    json.forEach(element => {
      // Eliminar campos innecesarios
      this.removeUnwantedFields(element, ['uid', 'uniqueid', 'visibleindex', 'boundindex']);

      // Formatear fecha
      if (element[this.translate('Fecha')]) {
        element[this.translate('Fecha')] = DateUtils.formatDateTimeShort(new Date(element[this.translate('Fecha')]), true);
      }

      // Asignar traducciones a valores booleanos
      element[this.translate('Aporte_residuo')] = element[this.translate('Aporte_residuo')] ? this.translate('SI') : this.translate('NO');
      element[this.translate('Apertura_tapa')] = element[this.translate('Apertura_tapa')] ? this.translate('SI') : this.translate('NO');
      element[this.translate('No_cerro_tapa')] = element[this.translate('No_cerro_tapa')] ? this.translate('SI') : this.translate('NO');

      // Formatear tipo de identificación y permisos
      element[this.translate('Tipo_identificacion')] = element[this.translate('Tipo_identificacion')] == 0 ? this.translate('Tarjeta_nfc') : 'Bluetooth';
      element[this.translate('Tipo_permiso')] = element[this.translate('Tipo_permiso')] ? this.translate('Master') : this.translate('Blanca');
    });

    this.exportToExcel(json, 'Historicos_identificaciones');
  }

  onExportarEnvios() {
    if (this.myGridEnvios.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = JSON.parse(this.myGridEnvios.exportdata('json'));

      json.forEach((element, index) => {
        element[this.translate('Bateria')] = this.formatNumericField(element[this.translate('Bateria')], 1, 0);
        element[this.translate('Temperatura')] = this.formatNumericField(element[this.translate('Temperatura')], 1, 0);
        element[this.translate('Num_identificaciones')] = this.formatNumericField(element[this.translate('Num_identificaciones')], 1, 0);
        element[this.translate('Fecha_envio')] = DateUtils.formatDateTime(this.myGrid.getrows()[index].fechaEnvio, false).replace('T', ' ');
      });

      this.exportToExcel(json, 'Historico_envios_dispositivo_identificador');
    }
  }

  // Función común para exportar a Excel
  exportToExcel(data: any[], fileName: string) {
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(data);
    this.generateAutofilterHeader(ws);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate(fileName) + '.xlsx');
  }

  generateAutofilterHeader(sheet: any) {
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  onPrint(arg0: string, $event: any) {
    switch (arg0) {
      case 'alarmas':
        this.onPrintGrid(this.myGrid);
        break;
      case 'identificacion':
        this.onPrintGrid(this.grid);
        break;
      case 'envios':
        this.onPrintGrid(this.myGridEnvios);
        break;
      default:
        return;
    }
  }

  // Función reutilizable para manejar la impresión de cualquier grid
  onPrintGrid(grid: any) {
    if (grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const gridContent = grid.exportdata('html');
      const newWindow = window.open('', '', 'width=800, height=500');
      const document = newWindow.document.open();
      const pageContent =
        '<!DOCTYPE html>\n' +
        '<html>\n' +
        '<head>\n' +
        '<meta charset="utf-8" />\n' +
        '<title>jQWidgets Grid</title>\n' +
        '</head>\n' +
        '<body>\n' +
        gridContent +
        '\n</body>\n</html>';

      document.write(pageContent);
      document.close();

      newWindow.onafterprint = function () {
        newWindow.close();
      };

      newWindow.print();
    }
  }

  selectHeader(headerName: string) {
    switch (headerName) {
      case 'alarmas':
        return this.headerAlarmas;
      case 'identificacion':
        return this.headerIdentificaciones;
      case 'envios':
        return this.headerEnvios;
      default:
        return;
    }
  }

  onBuscar(arg0: string, $event: any) {
    let header = this.selectHeader(arg0);
    const filterValue = header.searchInput['nativeElement'].value.length >= 3
      ? header.searchInput['nativeElement'].value.toUpperCase()
      : '';

    const applyFilterToAllColumns = (grid: jqxGridComponent, data: any[]) => {
      if (filterValue) {
        data.forEach((row) => {
          const matchesSearch = Object.values(row).some(
            (value) => value !== undefined && value !== null && String(value).toUpperCase().includes(filterValue)
          );
          row['selec'] = matchesSearch ? 'selec' : '';
        });
        this.__ensureGridFiltersAreRegistered(grid);
        this.__tirggerGridFilters(grid);
      } else {
        // Limpia los filtros si el valor es menor que 3
        this.__clearGridFilters(grid);
      }
    };

    switch (arg0) {
      case 'alarmas':
        let alarmas = this.alarmas;
        applyFilterToAllColumns(this.myGrid, alarmas);
        break;

      case 'identificacion':
        let identificaciones = this.identificaciones;
        applyFilterToAllColumns(this.grid, identificaciones);
        break;

      case 'envios':
        let envios = this.historicosEnviosIdentificador;
        applyFilterToAllColumns(this.myGridEnvios, envios);
        break;

      default:
        return;
    }
  }

  // Limpia los filtros de la grilla
  private __clearGridFilters(grid: jqxGridComponent): void {
    grid.clearfilters(); // Método de jqxGrid para limpiar todos los filtros
    grid.updatebounddata('data'); // Refresca los datos en la grilla
  }

  // Mantén las funciones existentes
  private __ensureGridFiltersAreRegistered(grid: jqxGridComponent): void {
    const filters = grid.getfilterinformation();
    if (!filters.some(filter => filter.datafield === 'selec')) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';

      const filter = filtergroup.createfilter('stringfilter', 'selec', 'contains');
      filtergroup.addfilter(0, filter);
      grid.addfilter('selec', filtergroup);
    }
  }

  private __tirggerGridFilters(grid: jqxGridComponent): void {
    grid.applyfilters();
    grid.updatebounddata('data');
  }

  eventFilter(arg0: string, $event: any) {
    let header = this.selectHeader(arg0);
    const dateGroup = this.getDateGroup(header);
    this.handleAction(arg0, dateGroup);
  }

  eventResetFilter(arg0: string, $event: any) {
    let header = this.selectHeader(arg0);
    this.resetPeriodSelection(header);
    this.resetSearchInput(header);
    let dateGroup = this.getDateGroup(header);
    this.handleAction(arg0, dateGroup);
  }

  resetPeriodSelection(header: HeaderComponent) {
    const now = new Date();
    header.periodoSelect.desde = new Date(now.setHours(0, 0, 0));
    header.periodoSelect.hasta = new Date(now.setHours(23, 59, 59));

    PeriodoSelectComponent._this.dateForm.get('desde').setValue(header.periodoSelect.desde);
    PeriodoSelectComponent._this.dateForm.get('hasta').setValue(header.periodoSelect.hasta);
    header.periodoSelect.cbPeriodo.selectedIndex(0);
  }

  resetSearchInput(header: HeaderComponent) {
    header.searchInput['nativeElement'].value = '';
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

}
