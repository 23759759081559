import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClusterZoomVisibilityConfigComponent } from './cluster-zoom-visibility-config.component';
import { MapModule } from 'movisat-maps';



@NgModule({
  declarations: [ClusterZoomVisibilityConfigComponent],
  imports: [
    MapModule,
    CommonModule,
    SharedModule
  ]
})
export class ClusterZoomVisibilityModule { }
