<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form jqxWindowAutoResize [theme]="theme" [zIndex]="1000" [autoOpen]="true" [showCloseButton]="false">
  <div hidden="true"></div>
  <div class="form">
    <jqxTabs #myTabs [width]="'100%'" [height]="'100%'" [theme]="theme" [scrollable]="false"
      (onTabclick)="onTabClick($event)" style="position: absolute;">
      <ul>
        <li style="height: 15px !important;"> {{titleTabLecturasSignal()}}</li>
        <li style="height: 15px !important;">{{titleTabCartografia}}</li>
      </ul>
      <div style="width: calc(100% - 5px); height: calc(100% - 8px); overflow: hidden;">
        <div>
          <app-header #header [exportar]="true" [imprimir]="true" [periodo]="true" [buscador]="true"
            [checkMetros]="true" [filtro]="true" [resetFiltro]="true" [noLibre]="true" (eventExportar)="onExportar()"
            (eventImprimir)="onPrint()" (eventBuscador)="onBuscar()" (eventCheckMasMetros)="checkMasXmetros()"
            (eventChangeMasXMetros)="changeMasXmetros($event)" (eventFilter)="onAceptar()"
            (eventResetFilter)="onResetFilter()">
          </app-header>
        </div>
        <jqxGrid #myGrid [theme]="theme" [width]="'calc(100% - 3px)'" [height]="'calc(100% - 40px)'"
          [source]="dataAdapter" [appCellClick] [enablebrowserselection]="true" [columnsresize]="true" [sortable]="true"
          [showrowlines]="false" [showcolumnlines]="true" [columns]="columns" [columngroups]="columngroups"
          [altrows]="true" [filterable]="true" [rowdetails]="true" [rowsheight]="20" [columnsheight]="20"
          [localization]="langGrid" [groupable]="true" [rowdetailstemplate]="rowdetailstemplate" [filter]="filter"
          [initrowdetails]="initRowDetails" [updatefilterconditions]="updatefilterconditions" [groupsheaderheight]="25"
          (onRowexpand)="rowExpand($event)" (onRowcollapse)="rowCollapse($event)"
          (onBindingcomplete)="onGridDataUpdate()" (onFilter)="onGridDataUpdate()"
          (onRowdoubleclick)="onRowdoubleclick($event)" (onSort)="onSort($event)"
          [columnmenuopening]='columnmenuopening'>
        </jqxGrid>
      </div>
      <div style="width: calc(100% - 5px); height: calc(100% - 8px)">
        <movisat-map [provider]="mapProvider" (mapReady)="onMapReady($event)" [lang]="lang"
          [searchCountry]="searchCountry" (boundsChange)="onBoundsChange($event)" [noSearch]="true" [zoom]="6">
        </movisat-map>
      </div>
    </jqxTabs>
  </div>
</jqxWindow>