import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PeriodoSelectModule } from '../periodo-select/periodo-select.module';

import { InformesComponent } from './informes/informes.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccionesComponent } from './acciones/acciones.component';
import { LoaderComponent } from 'projects/movisat-common-gui/src/public-api';

@NgModule({
  declarations: [
    InformesComponent,
    AccionesComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    SharedModule,
    PeriodoSelectModule,
    LoaderComponent,
  ]
})
export class AuditoriaModule { }
