import { NgModule } from '@angular/core';

import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';


import { AdministradorComponent } from './administrador.component';
import { EditTareaComponent } from './tareas/edit-tarea/edit-tarea.component';
import { ListadoTareasComponent } from './tareas/listado-tareas/listado-tareas.component';
import { HeaderModule } from '../header/header.module';
import { SharedModule } from 'src/app/shared/shared.module';


@NgModule({
  declarations: [
    EditTareaComponent,
    AdministradorComponent,
    ListadoTareasComponent,
  ],
  imports: [
    FormsModule,
    SharedModule,
    HeaderModule,
    CommonModule,
    BrowserModule,

  ]
})
export class AdministradorModule { }
