<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form jqxWindowAutoResize [theme]="theme" [zIndex]="900" [autoOpen]="true" [showCloseButton]="true"
  [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()" [title]="title">
  <div class="formHeader" style="overflow-x: hidden;"></div>
  <div class="form" style="float:left; overflow: hidden;">
    <div>
      <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
        (eventExportar)="onExportar()" (eventImprimir)="onPrint()" (eventBuscador)="onBuscar()"
        (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()">
      </app-header>
    </div>

    <div class="tables-wrapper">
      <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="'calc(100% - 5px)'" [source]="dataAdapter"
        [columnsresize]="true" [localization]="langGrid" [filterable]="true" [appCellClick] [showfilterrow]="false"
        [enablebrowserselection]="true" [columns]="columns" [showrowlines]="false" [groupable]="true"
        [columnmenuopening]='columnmenuopening' [showcolumnlines]="true" [pageable]="false" [sortable]="true"
        [altrows]="true" [rowsheight]="25" [columnsheight]="30" [editable]="false" (onRowclick)="onRowClick($event)"
        (onRowdoubleclick)="onRowdoubleclick()" [updatefilterconditions]="updatefilterconditions" [filter]="filter"
        [groupsheaderheight]="25">
      </jqxGrid>
    </div>
    <div class="form-buttons">
      <jqxButton [width]="100" [height]="26" [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        (onClick)="closeWindow()" [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>

<div #editCalendar></div>
<div #viewCalendar></div>
