<div id="fieldDetalleGeneral">
  <fieldset>
    <legend>{{ translate('Generales') }}</legend>

    <div class="row">
      <div class="row" style="width: 50%;">
        <span style="width: 100%;">
          <div style="margin-top: 5px; clear: both">
            {{ translate("Tiempo_recogida") }}
          </div>
          <app-input-number [value]="elemEdit?.Equipamiento?.TiempoRecogida"
            (onChange)="elemEdit?.Equipamiento != null ? elemEdit?.Equipamiento.TiempoRecogida = $event : ''"
            [render]="renderer()" [width]="'70%'" [renderizar]="false" [disabled]="true">
          </app-input-number>
        </span>
      </div>

      <div class="row" style="width: 50%;">
        <span style="width: 100%;">
          <div style="margin-top: 5px; clear: both">
            {{ translate("Tipo_material") }}
          </div>
          <app-input-number [value]="elemEdit?.Equipamiento?.TipoMaterial"
            (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.TipoMaterial = $event : ''"
            [render]="renderer()" [width]="'100%'" [decimal]="2" [renderizar]="false" [disabled]="true">
          </app-input-number>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="row" style="width: 50%;">
        <span style="width: 100%;">
          <div style="margin-top: 5px; clear: both">
            {{ translate("Tiene_ruedas_pregunta") }}
          </div>
          <jqxDropDownList (onChange)="elemEdit.Equipamiento != null ? elemEdit?.Equipamiento.Ruedas = $event : null"
            [width]="'100%'" [dropDownHeight]="60" [source]="sourceRuedas"
            [selectedIndex]="elemEdit?.Equipamiento?.Ruedas">
          </jqxDropDownList>
        </span>
      </div>

      <div class="row" style="width: 50%;">
        <span style="width: 100%;">
          <div style="margin-top: 5px; clear: both">
            {{ translate("Tiene_pedal_pregunta") }}
          </div>
          <jqxDropDownList (onChange)="elemEdit.Equipamiento != null ? elemEdit?.Equipamiento.Pedal = $event : null"
            [width]="'100%'" [dropDownHeight]="60" [source]="sourcePedal"
            [selectedIndex]="elemEdit?.Equipamiento?.Pedal">
          </jqxDropDownList>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="row" style="width: 40%;">
        <span style="width: 100%;">
          <div style="margin-top: 5px; clear: both">
            {{ translate("Emplazamiento") }}
          </div>
          <app-input-number [value]="elemEdit?.Equipamiento?.Emplazamiento?.Nombre"
            (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Emplazamiento.Nombre = $event : ''"
            [render]="renderer()" [width]="'100%'" [decimal]="2" [renderizar]="false" [disabled]="true">
          </app-input-number>
        </span>
      </div>

      <div class="row" style="width: 60%;">
        <span style="width: 100%;">
          <div style="margin-top: 5px; clear: both">
            {{ translate("Area_influencia") }} (m)
          </div>
          <app-input-number [value]="elemEdit?.Equipamiento?.AreaInfluencia"
            (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AreaInfluencia = $event : ''"
            [render]="renderer()" [width]="'40%'">
          </app-input-number>
        </span>
      </div>
    </div>

    <div class="row" style="width: 50%;">
      <span style="width: 100%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Numero_fracciones") }}
        </div>
        <div class="row">
          <app-input-number [value]="elemEdit?.Equipamiento?.NumeroFracciones"
            (onChange)="elemEdit?.Equipamiento != null ? elemEdit?.Equipamiento.NumeroFracciones = $event : ''"
            [render]="renderer()" [width]="'70%'" [renderizar]="false" [disabled]="true">
          </app-input-number>

          <jqxButton #myButton [width]="80" [height]="25" [textPosition]="'center'"
            [hidden]="elemEdit?.Equipamiento?.NumeroFracciones == 0 || !elemEdit?.Equipamiento?.NumeroFracciones"
            [value]='translate("Fracciones")' [theme]="environment.tema" (onClick)="openFracciones($event)">
          </jqxButton>
        </div>
      </span>
    </div>
  </fieldset>
</div>

<div id="fieldDetallePesosMedidas">
  <fieldset>
    <legend>{{ translate('Pesos_medidas') }}</legend>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Ancho") }} (cm)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.Ancho"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Ancho = $event : ''" [render]="renderer()"
          [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>

      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Alto") }} (cm)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.Altura"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Altura = $event : ''" [render]="renderer()"
          [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>
    </div>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Largo") }} (cm)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.Largo"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Largo = $event : ''" [render]="renderer()"
          [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>

      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Diametro") }} (cm)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.Diametro"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Diametro = $event : ''"
          [render]="renderer()" [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>
    </div>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Ancho_interior") }} (cm)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.AnchoInterior"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AnchoInterior = $event : ''"
          [render]="renderer()" [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>

      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Altura_interior") }} (cm)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.AltoInterior"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AltoInterior = $event : ''"
          [render]="renderer()" [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>
    </div>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Altura_sensor") }} (cm)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.AlturaSensor"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AlturaSensor = $event : ''"
          [render]="renderer()" [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>

      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Grados_sensor") }} (º)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.GradosSensor"
          (onChange)="elemEdit?.Equipamiento?.GradosSensor != null ? elemEdit.Equipamiento.GradosSensor = $event : '0'"
          [render]="renderer()" [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>
    </div>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Altura_muerta") }} (cm)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.AlturaMuerta"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AlturaMuerta = $event : ''"
          [render]="renderer()" [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>

      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Capacidad") }} (L)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.CapacidadLitros"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.CapacidadLitros = $event : ''"
          [render]="renderer()" [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>
    </div>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Coeficiente_conversion") }}
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.CoeficienteConversion"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.CoeficienteConversion = $event : ''"
          [render]="renderer()" [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>

      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Capacidad") }} (Kg)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.CapacidadKilos"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.CapacidadKilos = $event : ''"
          [render]="renderer()" [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>
    </div>

    <div class="row" style="justify-content: start;">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Carga_maxima") }} (Kg)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.CargaMaximaAutorizada"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.CargaMaximaAutorizada = $event : ''"
          [render]="renderer()" [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>

      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Tara") }} (Kg)
        </div>
        <app-input-number [value]="elemEdit?.Equipamiento?.Tara"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Tara = $event : ''" [render]="renderer()"
          [width]="'40%'" [decimal]="2" [disabled]="true">
        </app-input-number>
      </span>
    </div>
  </fieldset>
</div>

<div id="fieldDetalleInformacionGrafica">
  <fieldset>
    <legend>{{ translate('Informacion_grafica') }}</legend>

    <div class="row" style="justify-content: flex-start !important;">
      <span>
        <div style="margin-top: 5px; clear: both">
          {{ translate("Icono_elemento") }}
        </div>
        <img #imgPrincipal *ngIf="elemEdit?.Equipamiento?.Icono && elemEdit?.Equipamiento?.Icono?.length > 100"
          src="data:image/png;base64,{{elemEdit.Equipamiento?.Icono}}">
      </span>
    </div>

    <div class="row" style="justify-content: flex-start !important;">
      <span>
        <div style="margin-top: 5px; clear: both">
          {{ translate("Icono_punto_ubicacion") }}
        </div>
        <img #imgPrincipal *ngIf="elemEdit?.PU?.Equipamiento?.Icono && elemEdit?.PU?.Equipamiento?.Icono?.length > 100 "
          src="data:image/png;base64,{{elemEdit.Pu?.Equipamiento?.Icono}}">
      </span>
    </div>
  </fieldset>
</div>

<div id="fieldDetalleClasesElemento">
  <fieldset>
    <legend>{{ translate('Clases_asociadas_elemento') }}</legend>

    <jqxGrid #gridClasesAsociadas [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 5px)'"
      [appCellClick] [source]="dataAdapterClasesAsociadas" [enablebrowserselection]="true"
      [columns]="columnsClasesAsociadas" [showdefaultloadelement]="false" [localization]="langGrid" [columnsheight]="20"
      [rowsheight]="22">
    </jqxGrid>
  </fieldset>
</div>

<jqxWindow #fracciones id="winFracciones" [width]="200" [height]="220" [zIndex]="101" [isModal]="true"
  [position]="'center'" [showCloseButton]="true" [autoOpen]="false" [theme]="environment.tema" [resizable]="false"
  [title]="translate('Tabla_fracciones')" [position]="{ x: winFracLeft, y: winFracTop }">

  <div class="form" style="float: left; overflow-x: hidden;">
    <div style="float: left; width: 100%; height: 100%;">
      <jqxGrid #gridFracciones [theme]="environment.tema" [height]="'calc(100% - 50px)'" [width]="'100%'"
        [enablebrowserselection]="true" [source]="dataAdapterFracciones" [columnsresize]="true" [appCellClick]
        [columns]="columnsFracciones" [sortable]="true" [altrows]="true" [showrowlines]="false" [rowsheight]="20"
        [columnsheight]="20" [localization]="langGrid">
      </jqxGrid>

      <div style="display: flex; justify-content: center;">
        <jqxButton style="float: left; margin-right: 2px; margin-top: 5px;" [width]="55" [height]="25"
          [textPosition]="'center'" [value]="translate('Cerrar')" (onClick)="clickCerrar($event)">
        </jqxButton>
      </div>
    </div>
  </div>
</jqxWindow>
