import { SpecialDaysModel } from "./special-days.model";

export class CalendarModel {
  selec: string; // Campo usado para filtrar búsquedas

  id: number;
  empresa: number = 0;
  nombre: string = '';
  observaciones: string = '';
  fechaInicio: Date = new Date();
  fechaFin: Date = new Date();
  todosLosDias: boolean = false;
  horaInicio1: Date = new Date();
  horaFin1: Date = new Date();
  horaInicio2: Date = new Date();
  horaFin2: Date = new Date();
  horaInicio3: Date = new Date();
  horaFin3: Date = new Date();
  horaInicio4: Date = new Date();
  horaFin4: Date = new Date();
  horaInicio5: Date = new Date();
  horaFin5: Date = new Date();
  horaInicio6: Date = new Date();
  horaFin6: Date = new Date();
  horaInicio7: Date = new Date();
  horaFin7: Date = new Date();
  calendario: [] = [];
  diasEspecialesConServicio: SpecialDaysModel[] = [];
  diasEspecialesSinServicio: SpecialDaysModel[] = [];
  enUso: boolean = false;

  //Agregado
  diasSemana: string = '';
  calendarId: string = '';
  excepto: string = '';

}
