import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { SubflotasComponent } from './subflotas/subflotas.component';
import { MovilesCercaComponent } from './moviles-cerca/moviles-cerca.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    SubflotasComponent,
    MovilesCercaComponent,
  ],
  imports: [
    FormsModule,
    SharedModule,
    CommonModule,
  ]
})
export class FlotaModule { }
