import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JqxSubTabsComponent } from './jqx-sub-tabs.component';
import { SharedModule } from 'src/app/shared/shared.module';



@NgModule({
  declarations: [
    JqxSubTabsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    JqxSubTabsComponent
  ]
})
export class JqxSubTabsModule { }
