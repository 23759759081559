import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { CustomForms } from '../forms/custom-forms';
import { environment } from 'src/environments/environment';
import { AppComponent } from 'src/app/app.component';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { SsoService } from 'src/app/services/sso/sso.service';
import { MapBounds, MapComponent, MapLatLng, MapMarker } from 'movisat-maps';
import { MainComponent } from '../main/main.component';
import { AmbitoActividadModel } from 'src/app/services/geographics/ambito-actividad.model';
import { MarcoGeograficoModel } from 'src/app/services/geographics/marco-geografico.model';
import { ClusterZoomConfigService } from 'src/app/services/cluster-zoom-config.service';
import { ElementsComponent } from '../elements/elements.component';
import { MapClusterV2 } from 'movisat-maps/lib/movisat/map-cluster-v2';
import { ElementoModel } from 'src/app/services/elements/models/elem.model';

@Component({
  selector: 'app-cluster-zoom-visibility-config',
  templateUrl: './cluster-zoom-visibility-config.component.html',
  styleUrls: ['./cluster-zoom-visibility-config.component.css'],
})
export class ClusterZoomVisibilityConfigComponent
  extends CustomForms
  implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('window') window: jqxWindowComponent;

  public tema = environment.tema;

  mapWidth: number;
  mapHeight: number;
  langGrid: any;
  componentRef: any;
  public static _this: any;

  public map: MapComponent | null = null;
  public mapProvider = this.ssoService.getTicket().Empresa.Cartografia;
  public lang = this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2);
  public searchCountry = 'ES';

  private lastZoom: number | null = null;
  private lastBounds: MapBounds | null = null;
  private minZoom: number | null = null;
  public marcoGeografico: MarcoGeograficoModel;
  public ambitoActividad: AmbitoActividadModel;
  public elemCluster: MapClusterV2 | null = null;
  public clusterZoom: number;

  constructor(private ssoService: SsoService, private zoomConfigService: ClusterZoomConfigService) {
    super();
    ClusterZoomVisibilityConfigComponent._this = this;
  }

  ngOnInit(): void {
    const mapContainer = document.getElementById('map-container');
    if (mapContainer) {
      this.mapHeight = mapContainer.offsetHeight;
      this.mapWidth = mapContainer.offsetWidth;
    }
    this.langGrid = JqWidgets.getLocalization(this.lang);
  }

  ngAfterViewInit(): void {
    this.window.setTitle(this.translate('Cluster_zoom'));
    this.addCustomForm(this.window);
    this.loadZoomSettings();
    this.loadMarkerCluster();
  }

  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  async onMapReady(map: MapComponent): Promise<void> {
    this.map = map;
    const mainInstance = MainComponent.getInstance();
    this.marcoGeografico = mainInstance.marcoGeografico;
    this.ambitoActividad = mainInstance.ambitoActividad;

    if (!this.marcoGeografico.marco.contains(map.center)) {
      map.onBoundsChange(map.getBounds());
    } else {
      this.minZoom = map.zoom;
      setTimeout(() => (this.lastBounds = map.getBounds()), 500);
    }
  }

  onBoundsChange(bounds: MapBounds): void {
    const mainInstance = MainComponent.getInstance();
    this.marcoGeografico = mainInstance.marcoGeografico;
    this.ambitoActividad = mainInstance.ambitoActividad;

    if (!this.marcoGeografico) return;

    if (!this.lastBounds) {
      this.resetMapView();
    } else {
      this.handleBoundsChange(bounds);
    }
  }

  private resetMapView(): void {
    this.map?.fitTo(this.marcoGeografico.marco);
    this.lastZoom = this.map?.zoom ?? null;
    this.minZoom = this.lastZoom;
  }

  private handleBoundsChange(bounds: MapBounds): void {
    if (!this.map || !this.lastBounds || !this.marcoGeografico) return;

    if (!this.marcoGeografico.marco.contains(this.map.center)) {
      if (this.lastBounds.contains(this.marcoGeografico.marco.swCorner) &&
        this.lastBounds.contains(this.marcoGeografico.marco.neCorner)) {
        this.map.setZoom(this.lastZoom! + 1);
        this.map.fitTo(this.marcoGeografico.marco);
      } else {
        this.map.fitTo(this.lastBounds);
      }
    } else if (bounds.contains(this.marcoGeografico.marco.swCorner) &&
      bounds.contains(this.marcoGeografico.marco.neCorner) &&
      this.map.zoom < this.lastZoom!) {
      this.map.setZoom(Math.max(this.lastZoom!, this.minZoom!));
      this.map.fitTo(this.marcoGeografico.marco);
    }
  }

  onSlider(event: any): void {
    this.clusterZoom = event.args.value;
    this.elemCluster?.setZoom(this.clusterZoom);
  }

  async onSaveConfigZoom(): Promise<void> {
    try {
      const zoomValue = this.clusterZoom.toString();
      await Promise.all([
        this.zoomConfigService.setEmpApp('elements-zoom', zoomValue),
        this.zoomConfigService.setEmpApp('pu-zoom', zoomValue),
      ]);
      MainComponent.showSuccess('ATENCION', 'Cambios_guardados', 2000);
    } catch (error) {
      MainComponent.showError('ATENCION', 'Ha_ocurrido_un_error', 2000);
    }
  }

  async loadZoomSettings(): Promise<void> {
    try {
      const [savedZoomElements, savedZoomPU] = await Promise.all([
        this.zoomConfigService.getEmpApp('elements-zoom'),
        this.zoomConfigService.getEmpApp('pu-zoom'),
      ]);

      this.clusterZoom = this.parseZoom(savedZoomElements, this.clusterZoom);
      this.clusterZoom = this.parseZoom(savedZoomPU, this.clusterZoom);
    } catch (error) {
      console.error('Error loading zoom settings:', error);
    }
  }

  private parseZoom(value: string | null, defaultValue: number): number {
    const parsedValue = parseInt(value ?? '', 10);
    return isNaN(parsedValue) ? defaultValue : parsedValue;
  }

  loadMarkerCluster(): void {
    if (!this.map) return;

    this.elemCluster = this.map.addClusterV2(this.clusterZoom);

    const elementos = ElementsComponent.getInstance().elementList || [];
    elementos.forEach(elem => this.addMarker(elem, true));

    this.elemCluster.setZoom(this.clusterZoom);
  }

  addMarker(elem: ElementoModel, refresh = true): MapMarker {
    const icono = 'assets/images/elemento.png';
    return this.map!.addMarkerClusterV2(
      this.elemCluster!,
      {
        dataModel: elem,
        label: elem.Nombre,
        position: new MapLatLng(elem.Lat, elem.Lng),
        icon: icono,
        zIndex: 10,
        drag: false,
        visible: true,
      },
      refresh
    );
  }

  translate(key: string): string {
    return AppComponent.translate(key);
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
      ClusterZoomVisibilityConfigComponent._this = null;
    }
  }

  onClose() {
    this.window?.destroy();
    this.window = null;
  }

}
