import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";

import { HeaderModule } from "../header/header.module";
import { SharedModule } from "src/app/shared/shared.module";

import { PersonasComponent } from "./personas.component";

@NgModule({
  declarations: [
    PersonasComponent,
  ],
  imports: [
    FormsModule,
    HeaderModule,
    CommonModule,
    SharedModule,
    BrowserModule,
  ]
})

export class PersonasModule { }
