<jqxWindow #form [theme]="environment.tema" [width]="280" [showCloseButton]="true" [showCollapseButton]="true"
    [zIndex]="90" [height]="120" [resizable]="false" [position]="getFormPos(form)" (onClose)="onClose()">
    <div class="formHeader">
        {{translate('Marco_geografico')}}
    </div>
    <div style="float: left; overflow: hidden;" class="form">
        <div style="float:left; display: flex;flex-direction: column-reverse; width: 100%;">
            <div style="display: flex;justify-content: space-between">
                <div style="float:left; margin-top: 4px;">
                    <input [(ngModel)]="visible" type="checkbox" (change)="onChangeVisible($event)"
                        [disabled]="!canEdit" />
                    {{translate('Ver_marco_geografico')}}
                </div>

                <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                    [textPosition]='"left"' [imgSrc]='"/assets/images/zonas.png"' (onClick)="onDefinir($event)"
                    [value]="translate('Definir')" [disabled]="!canEdit">
                </jqxButton>
            </div>

            <div style="float:left; margin-top: 4px;">
                <input type="checkbox" (change)="onVerAmbito($event)" [disabled]="!canEdit" />
                {{translate('Ver_marco_ambito')}}
            </div>

        </div>
        <div style="position:absolute; bottom:5px; clear: both; display: flex; gap: 5px;">
            <jqxButton style="float: left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardar($event)"
                [value]="translate('Guardar')" [disabled]="!canEdit">
            </jqxButton>

            <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
                [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="form.close()"
                [value]="translate('CANCELAR')">
            </jqxButton>


        </div>
    </div>
</jqxWindow>