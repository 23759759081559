import { CuadriculaService } from 'src/app/services/cuadricula/cuadricula.service';
import { UserLangService } from 'src/app/services/common/user-lang/user-lang.service';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { environment } from 'src/environments/environment';
import { CustomForms } from '../forms/custom-forms';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { AppComponent } from 'src/app/app.component';
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import { HeaderComponent } from '../header/header.component';
import { jqxNumberInputComponent } from 'jqwidgets-ng/jqxnumberinput';
import { MainComponent } from '../main/main.component';

@Component({
  selector: 'app-gestion-espacial',
  standalone: false,
  templateUrl: './gestion-espacial.component.html',
  styleUrl: './gestion-espacial.component.css'
})
export class GestionEspacialComponent extends CustomForms implements OnInit, AfterViewInit, OnDestroy {

  static _this: any;
  @ViewChild('window') window: jqxWindowComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('tabs') tabs: jqxTabsComponent;
  @ViewChild('inputLadoCelda') inputLadoCelda: jqxNumberInputComponent;

  public showLoader = true;
  public tema = environment.tema;

  langGrid = JqWidgets.getLocalization(this.userLangService.getUserLangShort());
  componentRef: any;

  constructor(private userLangService: UserLangService, private cuadriculaService: CuadriculaService) {
    super();
    GestionEspacialComponent._this = this;
  }

  ngOnInit(): void { }

  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.window);
    this.setTitleTabs();
  }

  setTitleTabs() {
    this.tabs.setTitleAt(0, this.translate('Zonas_cartograficas'));
    this.tabs.setTitleAt(1, this.translate('Cuadriculas'));
  }

  async onGuardarConfLadoCelda() {
    try {
      const response = await this.cuadriculaService.saveConfigLado(this.inputLadoCelda.val());
      response ? MainComponent.showSuccess('ATENCION', 'Cambios_guardados', 2000) :
        MainComponent.showError('ATENCION', 'Error_guardar', 2000);
    } catch (error) {
      console.error(error);
    }
  }

  translate(key: string) {
    return AppComponent.translate(key);
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
      GestionEspacialComponent._this = null;
    }
  }

  onClose() {
    this.window?.destroy();
    this.window = null;
  }

}
