import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InfogeoModel } from './models/infogeo.model';
import { SsoService } from '../sso/sso.service';
import { lastValueFrom, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapsService {
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(private http: HttpClient, private ssoService: SsoService) { }

  async getInfogeoFromPoint(lat: number, lng: number): Promise<InfogeoModel> {
    try {
      let urlApi = this.ssoService.getTicket().UrlApi;

      return await lastValueFrom(this.http.get(
        urlApi + '/api/maps',
        {
          params: {
            mapType: 1, //NOTE: {aalzate} Should we change the provider? HERE = 1, GMaps = 2
            lat: lat,
            lng: lng
          }
        }).pipe(
          map((address: any): InfogeoModel => ({
            Label: [
              address.Street,
              address.StreetNumber,
              `${address.PostalCode} ${address.AdministrativeArea2} (${address.AdministrativeArea1})`,
              address.Country
            ].filter(l => l).join(', '),
            Country: address.Country,
            State: address.AdministrativeArea1,
            County: address.AdministrativeArea2,
            City: address.Locality,
            District: address.District,
            Street: address.Street,
            HouseNumber: address.StreetNumber,
            PostalCode: address.PostalCode,
          })
        )));
    } catch (e) {
      return null;
    }
  }

  async getCoordinatesByDirection(
    numero: string,
    calle: string,
    municipio: string,
    provincia: string,
    comunidadAutonoma: string,
    codigoPostal: string,
    poblacion: string
  ): Promise<any> {
    let response = null;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    let direction: any = new Object();
    direction.Street = calle;
    //direction.PostalCode = codigoPostal;
    direction.District = poblacion;
    direction.Locality = municipio;
    direction.AdministrativeArea2 = provincia;
    direction.AdministrativeArea1 = comunidadAutonoma;
    direction.StreetNumber = numero;

    try {
      response = await this.http.post<any>(
        this.urlApi + '/api/maps?mapType='+(this.ssoService.getTicket().Empresa.CartoId+1),
        JSON.stringify(direction),
        httpOptions
      ).toPromise();
    }catch(e){
      response = null;
    }

    return response;
  }
}
