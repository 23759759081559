import { Injectable } from '@angular/core';
import { SsoService } from '../../sso/sso.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { AmbitoActividadModel } from '../ambito-actividad.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AmbitoActividadService {
  private urlApi = this.ssoService.getTicket().UrlApi;
  private aplicacionId: number = this.ssoService.getTicket().Aplicacion.Id;
  private empresaId: number = this.ssoService.getTicket().Empresa.Id;

  constructor(
    private ssoService: SsoService,
    private http: HttpClient,
  ) {}

  async setAmbitoActividad(ambito: AmbitoActividadModel): Promise<boolean> {
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    //TODO: coger el valor que se defina en la ventana que se esta creando
    ambito.lado = 500;
    ambito.empresaIdSSO = this.empresaId;
    ambito.aplicacionId = this.aplicacionId;

    try {
      await lastValueFrom(this.http.post<any>(
        `${this.urlApi}/api/configuracion/zona/administrativa`,
        JSON.stringify(ambito),
        httpOptions
      ));
      response = true;
    } catch (e) {
      response = false;
    }

    return response;
  }
}
