import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { MapComponent, MapLatLng, MapMarker } from 'movisat-maps';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';

import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { EntradaModel } from 'src/app/services/zones/models/zona-entrada.model';

import { EdificiosInstalacionesEditComponent } from '../../edificios-instalaciones-edit/edificios-instalaciones-edit.component';

@Component({
  selector: 'app-edificios-instalaciones-entradas-salidas-edit',
  templateUrl: './edificios-instalaciones-entradas-salidas-edit.component.html',
  styleUrls: ['./edificios-instalaciones-entradas-salidas-edit.component.css']
})

export class EdificiosInstalacionesEntradasSalidasEditComponent extends CustomForms implements OnInit {
  // variables recuperadas del html
  @ViewChild('formEntradaSalida') formEntradaSalida: jqxWindowComponent;
  @ViewChild('gridEntrLocal') gridEntrLocal: jqxGridComponent;

  edificio: ZonaModel = new ZonaModel();
  edificioES: EntradaModel = new EntradaModel();

  private componentRef = null;
  public static _this: EdificiosInstalacionesEntradasSalidasEditComponent;

  public tipoEntrada: boolean = false;
  public tipoSalida: boolean = false;

  // variables para el mapa
  private map: MapComponent;
  markers: MapMarker[] = [];
  marker: MapMarker;

  // suscripción a los eventos del mapa
  private subscriptionOnMapClick = null;
  private subscriptionOnMarkerMove = null;
  private subscriptionOnCircleClick = null;
  private subscriptionOnPolygonClick = null;

  public entradas: EntradaModel[] = [];
  public entradasLocal: EntradaModel[] = [];

  // carga el tema necesario para los compontes del jqwidgets
  public theme = environment.tema;

  // Para traducir los textos
  public langGrid = JqWidgets.getLocalization('es');
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(private zonesService: ZonesService, private ssoService: SsoService) {
    super();
    EdificiosInstalacionesEntradasSalidasEditComponent._this = this;
  }

  init(componentRef: any, edificio:ZonaModel, zonaES: EntradaModel) {
    this.componentRef = componentRef;

    if(edificio) {
      this.edificio = edificio;
    }

    if(zonaES) {
      this.edificioES = zonaES;
    }
  }

  ngOnInit(): void {
    this.map = MainComponent.getInstance().getActiveMap();
    this.initGridLocal();
    this.subscribeToMapEvents();
  }

  ngAfterViewInit(){
    this.addCustomForm(this.formEntradaSalida);
    this.setGridLocal(this.gridEntrLocal);
    this.updateEntryType(this.edificioES.Tipo);
  }

  setGridLocal(grid: jqxGridComponent) {
    grid.setOptions({
      statusbarheight: 15,
      columnsresize: true,
      rowsheight: 20,
      columnsheight: 20
    });
  }

  // abrir el formulario
  onOpen() {
    if (this.edificio.Id !== 0) {
      EdificiosInstalacionesEditComponent._this.deleteZonesMap();
      this.zonesService.showZone(this.edificio, this.map);
    }
  }

  // cierro el formulario
  onClose() {
    if(this.componentRef) {
      this.componentRef.destroy();
    }
  }

  async onGuardarES(event: any) {
    try {
      await this.prepareZona();
      await this.handleMarkers();

      if (this.markers.length > 0) {
        await this.processMarkers();
        this.finalizeSave();
      } else {
        this.showError('ATENCION', 'Fallo_almacenar_info', 2000);
      }
    } catch (error) {
      this.showError('ATENCION', 'Error_interno', 2000);
    }
  }

  async createEntrada(lat: number, lng: number, marker: MapMarker) {
    this.edificioES.Lat = lat;
    this.edificioES.Lng = lng;
    this.edificioES.ZonaId = this.edificio.Id;

    let nuevaEntrada = await this.zonesService.addEntrada(this.edificioES);

    if (nuevaEntrada) {
      this.edificioES = nuevaEntrada;
      marker.setData(this.edificioES);

      // Eliminar entrada existente si ya está en la lista
      const entradaIndex = this.entradas.findIndex(e => e.Id === nuevaEntrada.Id);
      if (entradaIndex !== -1) {
        this.entradas.splice(entradaIndex, 1);
      }

      this.entradas.push(nuevaEntrada);
    }
  }

  private async prepareZona() {
    this.edificio = EdificiosInstalacionesEditComponent._this.edificio;

    if (this.edificio.Id === 0) {
      await EdificiosInstalacionesEditComponent._this.onGuardarZona();
      this.edificio = EdificiosInstalacionesEditComponent._this.edificio;
      this.edificioES.ZonaId = this.edificio.Id;
    }
  }

  private async handleMarkers() {
    if (this.markers.length > 0) {
      for (const marker of this.markers) {
        if (this.edificioES.Id === 0) {
          this.edificioES = marker.dataModel;
        }
        await this.createEntrada(marker.position.lat, marker.position.lng, marker);
      }
    }
  }

  private async processMarkers() {
    await Promise.all(
      this.entradas.map(element => this.getDireccion(element, element.Lat, element.Lng))
    );

    /* if (this.auxMarker.length > 0) {
      this.markers = this.auxMarker.concat(this.markers);
    } */
  }

  private finalizeSave() {
    /* MainComponent.getInstance().showInfo('ATENCION', 'Registro_almacenado', 2000);
    this.formEntradaSalida.close();
    if (ZonesComponent._this.creandoZonaMasEntradas) {
      ZonesComponent._this.mostrarEditZona = true;
      ZonesComponent._this.formEditZona.expand();
      setTimeout(() => {
        ZonesComponent._this.tabZonasEdit.select(0);
        ZonesComponent._this.creandoZonaMasEntradas = false;
      }, 500);
    } else {
      ZonesComponent._this.formEditZona.expand();
      setTimeout(() => {
        ZonesComponent._this.tabZonasEdit.select(1);
      }, 500);
    } */
  }

  private showError(title: string, message: string, duration: number) {
    MainComponent.getInstance().showError(title, message, duration);
  }

  async getDireccion(element: any, lat: number, lng: number): Promise<void> {
    try {
      const data = await this.zonesService.getDireccion(lat, lng);
      if (data?.District !== null && data?.District !== undefined) {
        element.Municipio = data.Locality;
      } else if (data?.District == null && data?.Locality == undefined) {
        element.Poblacion = data?.Locality;
      }
      element.Poblacion = data?.District;
    } catch (error) {
    }
  }

  typeEntrada() {
    if (this.tipoEntrada && this.tipoSalida) {
      this.edificioES.Tipo = 3;
    } else if (this.tipoEntrada) {
      this.edificioES.Tipo = 1;
    } else if (this.tipoSalida) {
      this.edificioES.Tipo = 2;
    }
  }

  //pinto el icono del marcador segun el tipo de entrada y si existe el marcador lo pinto de una manera u otra
  iconMarker(entrada: any): string {
    const icons = {
      1: '/assets/images/entrada.png',
      2: '/assets/images/salida.png',
      3: '/assets/images/entrada_salida.png'
    };
    const iconUrl = icons[entrada.Tipo] || '';

    if (this.marker && this.edificioES.Id !== 0) {
      this.marker.setIcon(iconUrl);
    }
    return iconUrl;
  }

  check() {
    this.typeEntrada();
    if (this.marker && this.edificioES.Id !== 0) {
      this.iconMarker(this.edificioES);
    }
  }

  // Grid entradasSalidas
  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean {
    if (dataField == 'tipo') {
      let filterValue = filterGroup.getfilters()[0].value;

      switch (filterValue) {
        case this.translate('Entrada'):
          if (cellValue == 1) {
            return true;
          }
          break;
        case this.translate('Salida'):
          if (cellValue == 2) {
            return true;
          }
          break;
        case this.translate('Entrada') + '/' + this.translate('Salida'):
          if (cellValue == 3) {
            return true;
          }
        default:
          return false;
      }
    } else {
      let filterColumns = [
        'nombre',
        'direccion',
        'poblacion',
        'municipio'
      ];

      return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
    }
  }

  dataSource: any;
  dataAdapter: any;
  public columns = [
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', width: 140, filtertype: 'textbox', datafield: 'nombre',
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">Total: ' +
            aggregates['Total'] + '</div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', datafield: 'tipo', width: 90, cellsrenderer: this.renderRow },
    { text: AppComponent.translate('Direccion'), columntype: 'textbox', datafield: 'direccion', },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true, width: 1 }
  ];

  initGridLocal() {
    this.dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'int', map: 'Id' },
        { name: 'nombre', type: 'string', map: 'Nombre' },
        { name: 'tipo', type: 'string', map: 'Tipo' },
        { name: 'direccion', type: 'string', map: 'Direccion' },
        { name: 'selec', type: 'string' },
      ],
      localdata: this.entradas,
    };
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);
  }

  renderRow(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    switch (columnfield) {
      case 'tipo':
        switch (value) {
          case 1:
            return '<div style="margin-left: 4px; margin-top: 2px;"' + columnproperties.cellsalign + ';">' + AppComponent.translate('Entrada') + '</div>';
          case 2:
            return '<div style="margin-left: 4px; margin-top: 2px;" ' + columnproperties.cellsalign + ';">' + AppComponent.translate('Salida') + '</div>';
          case 3:
            return '<div style="margin-left: 4px; margin-top: 2px;"' + columnproperties.cellsalign + ';">' + AppComponent.translate('Entrada') + '/' + AppComponent.translate('Salida') + '</div>';
        }
    }
  }

  // Suscripción a los eventos del mapa
  subscribeToMapEvents() {
    // si no hay subscripción, la creo
    if (!this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick = this.map.subscribeOnMapClick(this, this.onCreateEntrada);
      return;
    }
    if (!this.subscriptionOnMarkerMove) {
      this.subscriptionOnMarkerMove = this.map.subscribeOnMarkerMove(this, this.onMarkerMove);
      return;
    }
    if (!this.subscriptionOnCircleClick) {
      this.subscriptionOnCircleClick = this.map.subscribeOnCircleClick(this, this.onCreateEntrada);
      return;
    }
    if (!this.subscriptionOnPolygonClick) {
      this.subscriptionOnPolygonClick = this.map.subscribeOnPolygonClick(this, this.onCreateEntrada);
      return;
    }
  }

  onCreateEntrada(_this: EdificiosInstalacionesEntradasSalidasEditComponent, position: MapLatLng) {
    _this.createMarker(position);
  }

  onMarkerMove(_this: EdificiosInstalacionesEntradasSalidasEditComponent, marker: MapMarker) {
    if (_this.marker == marker) {
      _this.marker.setPosition(new MapLatLng(marker.position.lat, marker.position.lng));
    }
  }

  //para crear un marcador y pinto su icono correspondiente
  async createMarker(position: any) {
    if (!this.controlForm()) {
      return;
    }

    position = this.normalizePosition(position);

    this.marker = this.map.addMarker(this.getMarkerOptions(position));
    this.markers.push(this.marker);

    const data = await this.zonesService.getDireccion(position.lat, position.lng);
    this.updateEntradaDireccion(data);

    this.entradasLocal.push(this.edificioES);
    this.gridEntrLocal.updatebounddata();

    if (this.entradasLocal.length === 1) {
      MainComponent.getInstance().showInfo('ATENCION', 'Puede_anadir_mas_entradas', 2000);
    }

    this.sortAndResetForm();
  }

  //control de formulario nombre y check obligatorios
  controlForm(): boolean {
    if (!this.edificioES.Nombre || this.edificioES.Nombre.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return false;
    }
    if (!this.tipoEntrada && !this.tipoSalida) {
      MainComponent.getInstance().showError('ATENCION', 'Chequee_entrada_salida', 2000);
      return false;
    }
    return true;
  }

  normalizePosition(position: any): any {
    if (position.lat === undefined && position.lng === undefined) {
      position.lat = position.clickPoint.lat;
      position.lng = position.clickPoint.lng;
    }
    return position;
  }

  getMarkerOptions(position: any): any {
    return {
      dataModel: this.edificioES,
      title: this.edificioES.Nombre,
      content: this.edificio.Observaciones,
      position: position.id ? position.clickPoint : position,
      icon: this.iconMarker(this.edificioES),
      zIndex: 999,
      drag: this.edificioES || true,
      visible: true
    };
  }

  updateEntradaDireccion(data: any): void {
    this.edificioES.Direccion = data.Street;
  }

  sortAndResetForm(): void {
    setTimeout(() => {
      this.gridEntrLocal.sortby('nombre', 'asc');
      this.resetForm();
    }, 220);
  }

  onRowClickEdit(event) {
    const rowIndex = event.args.rowindex;
    const rowData = this.gridEntrLocal.getrowdata(rowIndex);

    if (rowData) {
      this.updateEntryType(this.edificioES.Tipo);
      this.gridEntrLocal.setcellvalue(rowIndex, 'selec', this.markers[rowIndex].id);

      // Encuentra y anima el marcador correspondiente
      const markerToAnimate = this.markers.find(marker => marker.id === rowData.selec);
      if (markerToAnimate) {
        markerToAnimate.animate(2000);
      }
    }
  }

  // reseteo el formulario
  resetForm() {
    this.edificioES = new EntradaModel();
    this.tipoEntrada = false;
    this.tipoSalida = false;
  }

  // recupero el tipo de entrada para pintar el check
  updateEntryType(type: number) {
    switch (type) {
      case 1:
        this.tipoEntrada = true;
        this.tipoSalida = false;
        break;
      case 2:
        this.tipoEntrada = false;
        this.tipoSalida = true;
        break;
      case 3:
        this.tipoEntrada = true;
        this.tipoSalida = true;
        break;
      default:
        this.tipoEntrada = false;
        this.tipoSalida = false;
    }
  }
}
