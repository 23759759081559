import { Component, OnInit, ViewChild, AfterViewInit, computed, signal, ElementRef } from '@angular/core';

import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { MapLatLng } from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxSplitterComponent } from 'jqwidgets-ng/jqxsplitter';

import { SsoService } from 'src/app/services/sso/sso.service';
import { SensorsService } from 'src/app/services/sensors/sensors.service';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { Accion } from 'src/app/services/auditoria/models/accion.model';
import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { AuditoriaModel } from 'src/app/services/auditoria/models/auditoria.model';
import { SensorDatosModel } from 'src/app/services/sensors/models/sensor-datos.model';
import { EDigitalMovilidadModel } from 'src/app/services/reports/models/edigital-movilidad-model';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { UserLangService } from 'src/app/services/common/user-lang/user-lang.service';
import { debounce } from 'lodash';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';

@Component({
  selector: 'app-listado-sensor',
  templateUrl: './listado-sensor.component.html',
  styleUrls: ['./listado-sensor.component.css']
})
export class ListadoSensorComponent extends CustomForms implements OnInit, AfterViewInit {

  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('mainSplitter') mainSplitter: jqxSplitterComponent;

  public static _this: ListadoSensorComponent;

  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  // Variables para generar el listado
  private movilSelect: MovilModel[] = []
  private entradasDigitales: EDigitalMovilidadModel[] = [];
  private sensores: SensorDatosModel[] = [];
  private sensorSelec: SensorDatosModel;
  public dataSource;
  public dataAdapter;
  //Variables para mapa
  private map;
  private marker;

  private componentRef = null;
  public environment = environment;

  // Variables para filtrado
  public fechaIni: Date;
  public fechaFin: Date;

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');
  isLoading: boolean = false;
  countPartialResults = signal<number>(0);
  titleSensors = this.translate('Listado_sensores');
  titleSensorsSignal = computed(() => `${this.titleSensors} (${this.countPartialResults()})`);

  constructor(
    private ssoService: SsoService,
    private sensorService: SensorsService,
    private auditoriaService: AuditoriaService,
    private userLangService: UserLangService
  ) {
    super();
    ListadoSensorComponent._this = this;
  }

  ngOnInit(): void {
    this.map = MainComponent.getActiveMap();
    this.langGrid = JqWidgets.getLocalization(this.userLangService.getUserLangShort());
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
  }

  closeWindow() {
    this.form.close();
  }


  cellClass = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'cellTooltip';
    }
    return '';
  }

  public columnmenuopening(menu?: any, datafield?: any, height?: number | string): boolean | void {
    if (menu.length === 1) {
      if (datafield === 'fecha' || datafield === 'hora') {
        const configureDateTimeInput = (menuElement: HTMLElement, options: { showTimeButton: boolean; showCalendarButton: boolean, formatString: string }) => {
          const dateTimeInputs = menuElement.querySelectorAll('.jqx-datetimeinput');
          if (dateTimeInputs && dateTimeInputs.length > 0) {
            dateTimeInputs.forEach((input: HTMLElement) => {
              const elementRef = new ElementRef(input);
              const jqxDateTimeInput = new jqxDateTimeInputComponent(elementRef);
              const inputDate = jqwidgets.createInstance('#' + jqxDateTimeInput.elementRef.nativeElement.id, 'jqxDateTimeInput', { width: '100%', height: 25 });
              inputDate.setOptions(options);
            });
          }
        };

        const divElement: HTMLElement = menu[0];
        if (datafield === 'fecha') {
          configureDateTimeInput(divElement, { showTimeButton: false, showCalendarButton: true, formatString: 'dd/MM/yyyy' });
        } else if (datafield === 'hora') {
          configureDateTimeInput(divElement, { showTimeButton: true, showCalendarButton: false, formatString: 'HH:mm:ss' });
        }
      }
    }
  }

  createTooltip = (content: string) => {
    return (columnHeaderElement?: any) => {
      const jqElement = columnHeaderElement[0].parentElement;
      const options = {
        content: content,
        theme: environment.tema,
        width: 'auto',
        height: 'auto',
        position: 'mouse',
        autoHide: true,
      };
      jqwidgets.createInstance(jqElement, 'jqxTooltip', options);
    };
  };


  columns = [
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },
    { text: 'Fecha', datafield: 'fecha', width: 100, filtertype: 'date', columntype: 'datetimeinput', cellsformat: 'dd/MM/yyyy', rendered: this.createTooltip('Fecha'), cellclassname: this.cellClass, },
    { text: 'Hora', datafield: 'hora', columntype: 'datetimeinput', filtertype: 'date', width: 80, cellsformat: 'HH:mm:ss', rendered: this.createTooltip('Hora'), cellclassname: this.cellClass },
    { text: AppComponent.translate('Movil'), columntype: 'textbox', width: '20%', filtertype: 'checkedlist', datafield: 'movil', cellclassname: this.cellClass, rendered: this.createTooltip(AppComponent.translate('Movil')) },
    { text: AppComponent.translate('Sensor'), columntype: 'textbox', width: 150, filtertype: 'checkedlist', datafield: 'sensor', cellclassname: this.cellClass, rendered: this.createTooltip(AppComponent.translate('Sensor')) },
    { text: AppComponent.translate('Lectura'), columntype: 'numberinput', width: 120, filtertype: 'number', datafield: 'valor', cellsalign: 'center', cellclassname: this.cellClass, rendered: this.createTooltip(AppComponent.translate('Lectura')) },
  ];


  // Trae los moviles seleccionados del hijo
  selected(event) {
    this.movilSelect = event;
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.delMarker();
    ListadoSensorComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Obtiene los sensores
  private async getSensors() {
    if (this.movilSelect) {
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'fecha', type: 'date', map: 'Fecha' },
          { name: 'hora', type: 'date', map: 'Fecha' },
          { name: 'movil', type: 'string', map: 'movil>Nombre' },
          // { name: 'sensor', type: 'string', map: 'sensor>Nombre' },
          { name: 'sensor', type: 'string', map: 'NombreSensor' },
          { name: 'valor', type: 'number', map: 'Valor' },
          { name: 'tipoValor', type: 'string', map: 'sensor>TipoValor' }
        ],
        localdata: this.sensores,
        sortcolumn: 'fecha',
        sortdirection: 'asc'
      };
      //Antes de completar la carga modifica el registro según el tipo de valor
      this.dataAdapter = new jqx.dataAdapter(this.dataSource, {
        beforeLoadComplete: (records: any[]): void => (
          records.forEach(registro => {
            if (registro.tipoValor === 'int' && Number(registro.valor) !== 0) {
              registro.valor = NumberUtils.format(Number(registro.valor), 0);
            }
            if (registro['tipoValor'] === 'float' && Number(registro.valor) !== 0) {
              registro.valor = NumberUtils.format(Number(registro.valor), 2);
            }
          })
        )
      });
    } else {
      this.sensores = [];
    }
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {

    let filterColumns = [
      'movil',
      'valor',
      'tipoValor'
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  imagerenderer(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    return value ? '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="data:image/jpg;base64,' + value + '"/>' :
      '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="assets/images/car.png" />';
  }

  async onAceptar(resetFilt = false) {
    this.isLoading = true;
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaFin = this.header.periodoSelect.getFechaFin();

    if (this.movilSelect.length === 0 && !resetFilt) {
      MainComponent.showWarning('ATENCION', 'Seleccione_movil', 2000);
      this.isLoading = false;
      return;
    } else if (resetFilt) {
      this.mainSplitter.expand();
      if (this.sensores.length > 0) {
        this.sensores = [];
        this.dataSource.localdata = this.sensores;
        this.grid.updatebounddata();
        this.isLoading = false;
      }
      return;
    }

    this.generateEventSplitter();

    let periodo = 0;
    switch (this.header.periodoSelect.getPeriodo()) {
      case 0:
        periodo = 1; // Dia
        break;
      case 1:
        periodo = 2; // Semana
        break;
      case 2:
        periodo = 3; // Mes
        break;
      default:
        periodo = 4; // Año
        break;
    }

    const result = await this.sensorService.getSensoresMoviles(this.movilSelect, this.fechaIni, this.fechaFin);
    this.sensores = result;
    this.getSensors();
    this.isLoading = false;
  }

  onGridDataUpdate() {
    this.countPartialResults.set(this.grid.getrows().length);
  }

  onBuscar = debounce(() => {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.sensores.forEach(sens => {
      if (
        (sens?.Fecha + '').toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        sens?.movil?.Nombre?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        sens?.NombreSensor?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        sens?.Valor?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1 ||
        sens?.sensor?.TipoValor?.toUpperCase().indexOf(filtervalue.toUpperCase()) > -1
      ) {
        sens['selec'] = 'selec';
      } else {
        sens['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.grid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.grid.addfilter('selec', filtergroup);
    }
    this.grid.applyfilters();
    this.grid.updatebounddata('data');
  }, 150);

  // resetea los filtros
  onResetFilter() {
    this.header.periodoSelect.cbPeriodo.selectIndex(0);

    this.header.periodoSelect.setDiaAnterior(new Date());

    this.header.periodoSelect.dateForm.get('desde').
      setValue(this.header.periodoSelect.desde);

    this.header.periodoSelect.dateForm.get('hasta').
      setValue(this.header.periodoSelect.hasta);

    this.header.searchInput['nativeElement'].value = '';

    this.onAceptar(true);
  }

  // Metodo para cerrar el splitter cuando le damos clic al boton aceptar
  generateEventSplitter() {
    this.mainSplitter.collapse();
  }

  onRowClick(event: any) {
    this.sensorSelec = this.sensores[event.args.rowindex];
  }

  // Elimina el marcador del mapa
  delMarker() {
    if (this.marker && this.map) {
      this.map.removeMarker(this.marker);
    }
  }

  onCentrar(event: any) {
    // Crea y posiciona el marcador
    if (this.sensorSelec) {
      this.delMarker();
      this.crearMarker();
      this.form.collapse();
      this.map.setCenter(this.marker.position);
      this.map.setZoom(18);
      this.marker.animate(2850);
    } else {
      MainComponent.showWarning('ATENCION', 'Seleccione_registro', 3000);
    }
  }

  crearMarker() {
    let valor = this.sensorSelec.Valor;
    if (this.sensorSelec.sensor.TipoValor === 'int' && Number(this.sensorSelec.Valor) !== 0) {
      valor = NumberUtils.format(Number(this.sensorSelec.Valor), 0);
    }
    if (this.sensorSelec.sensor.TipoValor === 'float' && Number(this.sensorSelec.Valor) !== 0) {
      valor = NumberUtils.format(Number(this.sensorSelec.Valor), 2);
    }
    this.marker = this.map.addMarker({
      dataModel: { codigo: 1, nombre: 'Marcador 1' },
      title: `Sensor ${this.sensorSelec.sensor.Nombre}`, // Texto que aparece al pasar el cursor por encima
      content: `<h3>${this.sensorSelec.sensor.Nombre}</h3>
                      <hr>
                      <b>Móvil: ${this.sensorSelec.movil.Nombre}</b>
                      <br>
                      Fecha: ${DateUtils.formatDateTimeShort(this.sensorSelec.Fecha, true)}
                      <br>
                      Lectura: ${valor}`, // Contenido html de la ventana que aparece al pinchar
      position: new MapLatLng(this.sensorSelec.Lat, this.sensorSelec.Lng),
      icon: '',
      zIndex: 0,
      drag: false
    });
  }

  onExportar() {
    this.grid.getrows().length === 0
      ? null
      : (() => {
        let gridContent = this.grid.exportdata('json');
        let tabla = JSON.parse(gridContent);
        const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(tabla);
        const wb: xlsx.WorkBook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
        xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Listado_sensores') + '.xlsx');
        this.auditoria.AccionId = Accion.EXPORTAR_INFORME_SENSORES;
        this.auditoriaService.addAuditoria(this.auditoria);
      })();
  }
}
