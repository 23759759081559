import { Component, ViewChild } from '@angular/core';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { AppComponent } from 'src/app/app.component';
import { HeaderComponent } from '../../header/header.component';

import { jqxRibbonComponent } from 'jqwidgets-ng/jqxribbon';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { VehiculosMaquinariaResumenComponent } from './vehiculos-maquinaria-resumen/vehiculos-maquinaria-resumen.component';

@Component({
  selector: 'app-vehiculos-maquinaria',
  templateUrl: './vehiculos-maquinaria.component.html',
  styleUrl: './vehiculos-maquinaria.component.css'
})
export class VehiculosMaquinariaComponent extends CustomForms{
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('movilesRibbon') movilesRibbon: jqxRibbonComponent;
  @ViewChild('windVehiculoMaquinaria') windVehiculoMaquinaria: jqxWindowComponent;

  @ViewChild('resumen') resumen: VehiculosMaquinariaResumenComponent;

  componentRef: any;
  public environment = environment;

  protected showLoader: boolean = false;

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() {
    super();
  }

  ngOnInit() {
  }

  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  public async onClose() {
    if(this.componentRef) {
      this.componentRef.destroy();
    }
  }

  changeTab(event: any) {
  }
}
