<lib-loader [message]="translate('Cargando_datos')" [showLoader]="showLoader"></lib-loader>
<jqxWindow #windowEdificios jqxWindowAutoResize [theme]="environment.tema" [zIndex]="900" [showCloseButton]="true"
  [title]="titleWindow + ' (' + countPartialResults() + ')'" [showCollapseButton]="true" [resizable]="true"
  (onExpand)="expandWind()" (onClose)="onClose()">

  <div class="formHeader" style="overflow-x: hidden;"></div>

  <div class="form">
    <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
      [tipoEdificios]="true" (eventImprimir)="onPrint($event)" (eventExportar)="onExportar()"
      (eventBuscador)="onBuscar()" (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()"
      (eventCheckEdificios)="onCheckEdificios($event)">
    </app-header>

    <div style="height: 89%">
      <div class="grid-container">
        <jqxGrid #gridEdificios [theme]="environment.tema" [columns]="columnsGridEdificios" [width]="'100%'"
          [height]="'100%'" [appCellClick] [source]="sourceGridEdificiosAdapter" [filterable]="true"
          [showrowlines]="false" [columnsresize]="true" [sortable]="true" [enablebrowserselection]="true"
          [altrows]="true" [statusbarheight]="20" [rowsheight]="25" [columnsheight]="20" [groupable]="true"
          [editable]="true" [groupsheaderheight]="25" [updatefilterconditions]="updatefilterconditions" [filter]="filter"
          (onRowselect)="onChecked()" (onRowunselect)="onUnselect($event)" (onBindingcomplete)="onBindingComplete()"
          [localization]="langGrid" (onRowdoubleclick)="onDoubleClick($event)" (onFilter)="onFilter($event)">
        </jqxGrid>
      </div>

      <div class="button-exit">
        <jqxButton style="margin-left: 5px" [width]="100" [height]="26" [textImageRelation]="'imageBeforeText'"
          [textPosition]="'left'" [value]="translate('Salir')" (onClick)="closeWindow()">
        </jqxButton>
      </div>
    </div>
  </div>
</jqxWindow>

<div #formEdificiosInstalaciones></div>