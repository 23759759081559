import { PeriodoDiaLaboralModel } from "./periodo-dia-laboral.model";

export class DiaLaboralModel {
  Id: number = 0;
  empresa: number = 0;
  inicio: PeriodoDiaLaboralModel = new PeriodoDiaLaboralModel();
  fin: PeriodoDiaLaboralModel = new PeriodoDiaLaboralModel();;

  constructor() { }
}
