<div class="loader-container" *ngIf="!columnsSubflotas.length">
  <div class=" loader"></div>
</div>
<jqxWindow #form [theme]="environment.tema" [position]="getFormPos(form)" [width]="760" [height]="402" [zIndex]="900"
  [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
  [title]="translate('Subflotas') + ' (' + subflotas.length + ')'" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Subflotas')}}
  </div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <div>
      <app-header #header [buscador]="true" [filtro]="true" [resetFiltro]="true" (eventBuscador)="onBuscar()"
        (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()">
      </app-header>
    </div>
    <div style="float:left; width: 100%; height: calc(100% - 80px);">
      <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapterSubflotas"
        [appCellClick] [columnsresize]="true" [filterable]="true" [showfilterrow]="false" [columns]="columnsSubflotas"
        [sortable]="true" [altrows]="true" [showrowlines]="false" [filterrowheight]="30" [rowsheight]="25"
        [columnsheight]="30" [enablebrowserselection]="true" [showemptyrow]="true" [localization]="langGrid"
        [filter]="filter" (onSort)="onSort($event)" [updatefilterconditions]="updatefilterconditions"
        [rowdetails]="true" [rowdetailstemplate]="rowdetailstemplate" [initrowdetails]="initRowDetails"
        (onRowexpand)="rowexpand($event)" (onRowcollapse)="rowcollapse($event)"
        (onRowclick)="onRowSubflotaSelect($event)" (onRowdoubleclick)="onRowdoubleclick($event)"
        (onBindingcomplete)="subflotaBindingComplete()">
      </jqxGrid>
    </div>
    <div style="float: left; margin-top: 8px;">
      <jqxButton style="float: left; margin-left: 5px;" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
        [value]="translate('Salir')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>

<div *ngIf="mostrarEditForm">
  <jqxWindow #formEdit style="overflow: hidden;" [theme]="environment.tema" [position]="getFormPos(formEdit)"
    [width]="950" [height]="490" [zIndex]="900" [autoOpen]="true" [isModal]="false" [showCloseButton]="true"
    [showCollapseButton]="true" [resizable]="true" [title]="translate('Edicion_subflotas')" (onClose)="onCloseEdit()"
    (onOpen)="onOpenEditSubflota()">
    <div class="formHeader" style="overflow-x: hidden;">
      {{translate('Edicion_subflotas')}}
    </div>
    <div class="form" style="float:left; overflow: hidden;">
      <div class="row">
        <div class="rowC" style="float: left; width: calc(20% - 10px);">
          <span>
            <div style="margin-top: 5px">
              {{ translate('Nombre') }}
            </div>
            <jqxInput #ebNombre [(ngModel)]="subflotaEdit.Nombre" [width]="'100%'" [height]="25" [disabled]="!canEdit">
            </jqxInput>
          </span>
        </div>

        <div class="rowC" style="float: left; width: calc(80% - 25px); margin-left: 25px;">
          <span>
            <div style="margin-top: 5px">
              {{translate('Observaciones')}}
            </div>
            <jqxInput [(ngModel)]="subflotaEdit.Observaciones" [width]="'100%'" [height]="25" [disabled]="!canEdit">
            </jqxInput>
          </span>
        </div>
      </div>

      <div style="float: left; margin-top: 5px; clear: both; display: contents;">
        <div style="float: left; width: calc(50% - 25px); height: 340px;">
          <div style="float: none; padding-top: 4px;">{{translate('Subflota')}} {{subflotaEdit.Nombre}}
            ({{movilesSubflota.length}})</div>
          <jqxGrid #gridMoviles2 [theme]="environment.tema" [height]="'100%'" [width]="'100%'" [appCellClick]
            [enablebrowserselection]="true" [source]="dataAdapterMoviles2" [columnsresize]="true" [sortable]="true"
            [showfilterrow]="false" [selectionmode]="'checkbox'" [showrowlines]="false" [showcolumnlines]="true"
            [showrowlines]="false" [columns]="columnsMoviles" [altrows]="true" [enabletooltips]="true"
            [filterable]="true" [filtermode]="'excel'" [autoshowfiltericon]="true" [filterrowheight]="30"
            [rowsheight]="20" [columnsheight]="30" [showaggregates]="true" [showstatusbar]="false"
            [statusbarheight]="20" [localization]="langGrid" [disabled]="!canEdit" [filter]="filter"
            [updatefilterconditions]="updatefilterconditions">
          </jqxGrid>
        </div>
        <div style="float: left; margin-left: 6px; margin-top: 150px;">
          <jqxButton style="float:left; clear: both;" [width]='25' [height]='26' [textImageRelation]='"imageBeforeText"'
            [textPosition]='"right"' [imgPosition]='"left"' [imgSrc]='"/assets/images/derecha.png"'
            (onClick)="onMenosClick($event)" [disabled]="!canEdit">
          </jqxButton>
          <jqxButton style="float:left; clear: both; margin-top: 5px;" [width]='25' [height]='26'
            [textImageRelation]='"imageAfterText"' [textPosition]='"left"' [imgPosition]='"right"'
            [imgSrc]='"/assets/images/izquierda.png"' (onClick)="onMasClick($event)" [disabled]="!canEdit">
          </jqxButton>

        </div>
        <div style="float: left; margin-left: 4px; width: calc(50% - 25px); height: 340px;">
          <div style="float: none; padding-top: 4px;">{{translate('Resto_flota')}} ({{movilesResto.length}})</div>
          <jqxGrid #gridMoviles1 [theme]="environment.tema" [height]="'100%'" [width]="'100%'" [appCellClick]
            [source]="dataAdapterMoviles1" [columnsresize]="true" [sortable]="true" [showfilterrow]="false"
            [selectionmode]="'checkbox'" [showrowlines]="false" [showcolumnlines]="true" [enablebrowserselection]="true"
            [showrowlines]="false" [columns]="columnsMoviles" [altrows]="true" [enabletooltips]="true"
            [filterable]="true" [filtermode]="'excel'" [autoshowfiltericon]="true" [filterrowheight]="30"
            [rowsheight]="20" [columnsheight]="30" [showaggregates]="false" [showstatusbar]="true"
            [statusbarheight]="20" [localization]="langGrid" [filter]="filter"
            [updatefilterconditions]="updatefilterconditions" [disabled]="!canEdit">
          </jqxGrid>
        </div>
      </div>
      <div style="position:absolute; bottom:5px; left:5px; margin-top: 15px; clear: both;">
        <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]="'imageBeforeText'"
          [textPosition]="'center'" [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardarClick($event)"
          [value]="translate('Guardar')" [disabled]="!canEdit">
        </jqxButton>
        <jqxButton style="float: left; margin-left: 5px;" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="formEdit.close()"
          [value]="translate('CANCELAR')">
        </jqxButton>
      </div>
    </div>
  </jqxWindow>
</div>