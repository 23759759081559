<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>

<div toastContainer>
  <!-- En este espacio aparecen todas las notificaciones -->
</div>

<div class="main-container">
  <div id="header-container" class="header-container">
    <app-appbar></app-appbar>
  </div>
  <div id="body-container" class="body-container">
    <div class="menu">
      <img src="/assets/images/menu/menu.png" class="menu" style="margin-top: 0px; width: 24px; height: 24px;"
        title="Menú" (click)="tongleMenu()" />
    </div>
    <div id="menu-container" class="menu-container">
      <app-menu></app-menu>
    </div>
    <div id="center-container" class="center-container">
      <jqxSplitter #rightSplitter [theme]="environment.tema" [width]="'100%'" [height]="'100%'"
        [orientation]="'vertical'" [panels]="[{ size: '65%', collapsible: false }, { size: '35%'}]"
        (onResize)="onResizeSpliter($event)">
        <div>
          <jqxSplitter #downSplitter [theme]="environment.tema" [width]="'100%'" [height]="'100%'"
            [orientation]="'horizontal'" [panels]="[{ size: '60%', collapsible: false }, { size: '40%'}]"
            (onResize)="onResizeSpliter($event)">
            <div class="map-container" id="map-container">

              <div id="superiorTabs" class="tab">
                <button id="tabMap0" class="tablinks" style="height: 22px; vertical-align: middle;"
                  (click)="onClickTabMap($event, 0)">
                  <span id="mapaPrincipal" style="float: left;"><img width="16" height="16" src={{maps[0].icon}}>
                    {{translate('Mapa')}}
                  </span>
                </button>
                <button [hidden]="!maps[1].visible" id="tabMap1" class="tablinks"
                  style="height: 22px; vertical-align: middle;" (click)="onClickTabMap($event, 1)">
                  <span style="float: left;"><img width="16" height="16" src={{maps[1].icon}}>
                    {{maps[1].label}}
                  </span>
                  <input [hidden]="!maps[1].closeButton" type="button" class="button-close-map" value="X"
                    (click)="removeMap(1)" />
                </button>
                <button [hidden]="!maps[2].visible" id="tabMap2" class="tablinks"
                  style="height: 22px; vertical-align: middle;" (click)="onClickTabMap($event, 2)">
                  <span style="float: left;"><img width="16" height="16" src={{maps[2].icon}}>
                    {{maps[2].label}}
                  </span>
                  <input [hidden]="!maps[2].closeButton" type="button" class="button-close-map" value="X"
                    (click)="removeMap(2)" />
                </button>
                <button [hidden]="!maps[3].visible" id="tabMap3" class="tablinks"
                  style="height: 22px; vertical-align: middle;" (click)="onClickTabMap($event, 3)">
                  <span style="float: left;"><img width="16" height="16" src={{maps[3].icon}}>
                    {{maps[3].label}}
                  </span>
                  <input [hidden]="!maps[3].closeButton" type="button" class="button-close-map" value="X"
                    (click)="removeMap(3)" />
                </button>
                <button [hidden]="!maps[4].visible" id="tabMap4" class="tablinks"
                  style="height: 22px; vertical-align: middle;" (click)="onClickTabMap($event, 4)">
                  <span style="float: left;"><img width="16" height="16" src={{maps[4].icon}}>
                    {{maps[4].label}}
                  </span>
                  <input [hidden]="!maps[4].closeButton" type="button" class="button-close-map" value="X"
                    (click)="removeMap(4)" />
                </button>
                <button [hidden]="!maps[5].visible" id="tabMap5" class="tablinks"
                  style="height: 22px; vertical-align: middle;" (click)="onClickTabMap($event, 5)">
                  <span style="float: left;"><img width="16" height="16" src={{maps[5].icon}}>
                    {{maps[5].label}}
                  </span>
                  <input [hidden]="!maps[4].closeButton" type="button" class="button-close-map" value="X"
                    (click)="removeMap(5)" />
                </button>

                <ng-container *ngFor="let item of tabsInfo; let i = index;">
                  <ng-container *ngIf="item.visible">
                    <div class="button-container">
                      <button class="tabcontent" title="{{translate(item.label)}}" id="{{item.id}}"
                        [ngClass]="{'button-selected': item.activated}" (click)="onClickTabMap(item)">
                        <span class="button-label">
                          {{ translate(item.label) }}
                        </span>
                        <button (click)="destroyComponent(item); $event.stopPropagation();"> <i class="fa fa-times"
                            aria-hidden="true"></i></button>
                      </button>
                    </div>
                  </ng-container>
                </ng-container>

              </div>

              <div class="tabcontent" id="map0">
                <movisat-map [provider]="mapProvider" [cartoType]="cartoType" [lang]="lang"
                  [texts]="langService.getCurrentTexts()" [searchBounds]="searchBounds" [searchCountry]="searchCountry"
                  [zoom]="zoom" [center]="center" [noSearch]="false" (mapReady)="onMapReady($event)"
                  (mapClick)="onMapClick($event)" (boundsChange)="onBoundsChange($event)"
                  (zoomChange)="onZoomChange($event)" (markerClick)="onMarkerClick($event)"
                  (markerDragEnd)="onMarkerDragEnd($event)" (polylineClick)="onPolylineClick($event)"
                  (polygonClick)="onPolygonClick($event)" (polygonDragEnd)="onPolygonDragEnd($event)"
                  (circleClick)="onCircleClick($event)" (mapMouseMove)="onMapMouseMove($event)"
                  (circleDragEnd)="onCircleDragEnd($event)" (circleRadiusChanged)="onCircleRadiusChanged($event)">
                </movisat-map>
              </div>
              <app-map-toolbar></app-map-toolbar>
              <div [hidden]="!maps[1].visible" class="tabcontent" id="map1">
                <movisat-map *ngIf="!maps[1].destroyed" [provider]="mapProvider" [lang]="lang" [zoom]="zoom"
                  [center]="center" (mapReady)="maps[1].onMapReady($event, 1)">
                </movisat-map>
              </div>
              <div [hidden]="!maps[2].visible" class="tabcontent" id="map2">
                <movisat-map *ngIf="!maps[2].destroyed" [provider]="mapProvider" [lang]="lang" [zoom]="zoom"
                  [center]="center" (mapReady)="maps[2].onMapReady($event, 2)">
                </movisat-map>
              </div>
              <div [hidden]="!maps[3].visible" class="tabcontent" id="map3">
                <movisat-map *ngIf="!maps[3].destroyed" [provider]="mapProvider" [lang]="lang" [zoom]="zoom"
                  [center]="center" (mapReady)="maps[3].onMapReady($event, 3)">
                </movisat-map>
              </div>
              <div [hidden]="!maps[4].visible" class="tabcontent" id="map4">
                <movisat-map *ngIf="!maps[4].destroyed" [provider]="mapProvider" [lang]="lang" [zoom]="zoom"
                  [center]="center" (mapReady)="maps[4].onMapReady($event, 4)">
                </movisat-map>
              </div>
              <div [hidden]="!maps[5].visible" class="tabcontent" id="map5">
                <movisat-map *ngIf="!maps[5].destroyed" [provider]="mapProvider" [lang]="lang" [zoom]="zoom"
                  [center]="center" (mapReady)="maps[5].onMapReady($event, 5)">
                </movisat-map>
              </div>

              <!-- Contenedores de contenido de pestañas -->
              <ng-template #dynamicContainer></ng-template>

              <!-- <div #historicEnvios id="tab-historic"></div>
                            <div #listadoIdentificaciones id="tab-lisIdentificaciones"></div>
                            <div #listadoAlarmas id="tab-listAlarmas"></div> -->

            </div>
            <div #gestionContainer id="area-gestion-container" class="area-gestion-container">
              <jqxRibbon #tabGestion style="overflow: hidden;" [theme]="environment.tema" [width]="'100%'"
                [height]="'100%'" [position]="'top'">
                <ul style="width: 100%; text-align: left;">
                  <li #divTab id="tabMoviles" style="height: 28px; vertical-align: middle;"
                    (click)="onClickTab(_this.MOVILES_TAB)" [hidden]="!hasMenuAction('MOVILES') || !formMovVisible">
                    <span style="float: left;"><img width="20" height="20" src="assets/images/movilidad.png">
                      {{translate('Flota')}}: {{recordMovilesCountTotal() }} {{ ' (' +
                      recordCountMovilesUpdate() + ')' }}
                    </span>
                    <input type="button" style="margin-left: 6px;" class="button-close-bottom" value="X"
                      (click)="hideMovilesTab()" />
                  </li>
                  <li #divTab id="tabElementos" style="height: 28px; vertical-align: middle;"
                    (click)="onClickTab(_this.ELEMENTOS_TAB)"
                    [hidden]="!hasMenuAction('CATALOGO_ELEMENTOS') || !formElemVisible">
                    <span style="float: left;"><img width="20" height="20" src="assets/images/elements.png">
                      {{translate('Elementos')}}: {{recordCountElementsTotal()}} {{ ' (' +
                      recordCountElementsUpdate() + ')' }}
                    </span>
                    <input type="button" style="margin-left: 6px;" class="button-close-bottom" value="X"
                      (click)="hideElementsTab()" />
                  </li>
                  <li #divTab id="tabPU" [hidden]="!formPuVisible" style="height: 28px; vertical-align: middle;"
                    (click)="onClickTab(_this.PU_TAB)">
                    <span style="float: left;"><img width="20" height="20" src="assets/images/pu.png">
                      {{translate('Puntos_ubicacion')}}: {{recordCountPuTotal()}} {{ ' (' +
                      recordCountPuUpdate() + ')' }}
                    </span>
                    <input type="button" style="margin-left: 6px;" class="button-close-bottom" value="X"
                      (click)="hidePUTab()" />
                  </li>
                  <li #divTab id="tabCiudadano" [hidden]="!formCiudadanosVisible"
                    style="height: 28px; vertical-align: middle;" (click)="onClickTab(_this.CIUDADANOS_TAB)">
                    <span style="float: left;">
                      {{translate('Ciudadanos')}}: {{getNumCiudadanos()}}
                    </span>
                    <input type="button" style="margin-left: 6px;" class="button-close-bottom" value="X"
                      (click)="hideCiudadanosTab()" />
                  </li>
                  <li #divTab id="tabIA" [hidden]="!formIaVisible && !isEcoEvolution"
                    style="height: 28px; vertical-align: middle;" (click)="onClickTab(_this.IA_TAB)">
                    <span style="float: left;"><img width="20" height="20" src="assets/images/ia.png">
                      {{translate('Elementos')}}-IA
                    </span>
                  </li>
                  <li #divTab id="tabItinerary" [hidden]="!formItineraryVisible || isEcoEvolution"
                    style="height: 28px; vertical-align: middle;" (click)="onClickTab(_this.ITINERARIOS_TAB)">
                    <span style="float: left;"><img width="20" height="20" src="assets/images/itinerary.png">
                      {{translate('Itinerarios')}}
                    </span>
                  </li>
                  <li #divTab id="tabGestion0" [hidden]="!gestionTabs[0].visible"
                    style="height: 28px; vertical-align: middle;" (click)="onClickTab(_this.GESTION_TAB0)">
                    <span style="float: left;"><img width="20" height="20" src={{gestionTabs[0].icon}}>
                      {{gestionTabs[0].label}}
                    </span>
                    <input [hidden]="!gestionTabs[0].closeButton" type="button" style="margin-left: 6px;"
                      class="button-close" value="X" (click)="removeGestionTab(0)" />
                  </li>
                  <li #divTab id="tabGestion1" [hidden]="!gestionTabs[1].visible"
                    style="height: 28px; vertical-align: middle;" (click)="onClickTab(_this.GESTION_TAB1)">
                    <span style="float: left;"><img width="20" height="20" src={{gestionTabs[1].icon}}>
                      {{gestionTabs[1].label}}
                    </span>
                    <input [hidden]="!gestionTabs[1].closeButton" type="button" style="margin-left: 6px;"
                      class="button-close" value="X" (click)="removeGestionTab(1)" />
                  </li>
                  <li #divTab id="tabGestion2" [hidden]="!gestionTabs[2].visible"
                    style="height: 28px; vertical-align: middle;" (click)="onClickTab(_this.GESTION_TAB2)">
                    <span style="float: left;"><img width="20" height="20" src={{gestionTabs[2].icon}}>
                      {{gestionTabs[2].label}}
                    </span>
                    <input [hidden]="!gestionTabs[2].closeButton" type="button" style="margin-left: 6px;"
                      class="button-close" value="X" (click)="removeGestionTab(2)" />
                  </li>
                  <li #divTab id="tabGestion3" [hidden]="!gestionTabs[3].visible"
                    style="height: 28px; vertical-align: middle;" (click)="onClickTab(_this.GESTION_TAB3)">
                    <span style="float: left;"><img width="20" height="20" src={{gestionTabs[3].icon}}>
                      {{gestionTabs[3].label}}
                    </span>
                    <input [hidden]="!gestionTabs[3].closeButton" type="button" style="margin-left: 6px;"
                      class="button-close" value="X" (click)="removeGestionTab(3)" />
                  </li>
                  <li #divTab id="tabGestion4" [hidden]="!gestionTabs[4].visible"
                    style="height: 28px; vertical-align: middle;" (click)="onClickTab(_this.GESTION_TAB4)">
                    <span style="float: left;"><img width="20" height="20" src={{gestionTabs[4].icon}}>
                      {{gestionTabs[4].label}}
                    </span>
                    <input [hidden]="!gestionTabs[4].closeButton" type="button" style="margin-left: 6px;"
                      class="button-close" value="X" (click)="removeGestionTab(4)" />
                  </li>
                </ul>
                <div>
                  <div [hidden]="!hasMenuAction('MOVILES') || !formMovVisible">
                    <app-moviles></app-moviles>
                  </div>
                  <div [hidden]="!hasMenuAction('CATALOGO_ELEMENTOS') || !formElemVisible">
                    <app-elements></app-elements>
                  </div>
                  <div [hidden]="!formPuVisible">
                    <app-pu></app-pu>
                  </div>
                  <div>
                    <app-ciudadanos [show]="formCiudadanosVisible"></app-ciudadanos>
                  </div>
                  <div>
                    <div [hidden]="!formIaVisible && !isEcoEvolution" #gridElemIaContainer>
                      <!-- Contenedor para el grid de elementos IA -->
                    </div>
                  </div>
                  <div>
                    <div [hidden]="!formItineraryVisible || isEcoEvolution" id="itineraryInfo">
                      <!-- Contenedor para los itinerarios -->
                    </div>
                  </div>
                  <div>
                    <div #divGestion0 [hidden]="!gestionTabs[0].visible" id="divGestion0">
                      <!-- Contenedor para gestión 1 -->
                    </div>
                  </div>
                  <div>
                    <div #divGestion1 [hidden]="!gestionTabs[1].visible" id="divGestion1">
                      <!-- Contenedor para gestión 2 -->
                    </div>
                  </div>
                  <div>
                    <div #divGestion2 [hidden]="!gestionTabs[2].visible" id="divGestion2">
                      <!-- Contenedor para gestión 3 -->
                    </div>
                  </div>
                  <div>
                    <div #divGestion3 [hidden]="!gestionTabs[3].visible" id="divGestion3">
                      <!-- Contenedor para gestión 4 -->
                    </div>
                  </div>
                  <div>
                    <div #divGestion4 [hidden]="!gestionTabs[4].visible" id="divGestion4">
                      <!-- Contenedor para gestión 5 -->
                    </div>
                  </div>
                </div>
              </jqxRibbon>
            </div>
          </jqxSplitter>
        </div>
        <div id="right-container" class="right-container">
          <div #rightContainer>
            <!-- Contenedor de la derecha multiproposito -->
          </div>
        </div>
      </jqxSplitter>
    </div>
  </div>
  <div id="footer-container" class="footer-container">
    <app-footer></app-footer>
  </div>
</div>
