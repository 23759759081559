<jqxWindow #window jqxWindowAutoResize [theme]="tema" [title]="translate('Gestion_espacial')" [zIndex]="998"
    [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;"></div>
    <div class="form" style="float:left; overflow-x: hidden; ">
        <div>
            <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true"
                [resetFiltro]="true">
            </app-header>
        </div>

        <jqxTabs #tabs [width]="'100%'" [height]="'100%'" [theme]="tema" [auto-create]="true">
            <ul>
                <li style="height: 15px !important;">{{translate('Edificios_instalaciones')}}</li>
                <li style="height: 15px !important;">{{ translate('Cuadriculas') }}</li>
            </ul>
            <div class="zones-container"></div>
            <div>
                <div class="cuadriculas-container">
                    <div class="form-container">
                        <label>{{ translate('Lado_celda') }}:</label>
                        <jqxNumberInput #inputLadoCelda [width]="150" [height]="25" [spinButtons]="true" [min]="0"
                            [theme]="tema" [value]="500" [decimalDigits]="0" [inputMode]="'simple'">
                        </jqxNumberInput>
                    </div>
                </div>
                <div class="form-buttons">
                    <jqxButton style="float: left; margin-left: 1px" [width]="100" [height]="26" [imgPosition]="'left'"
                        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
                        [imgSrc]='"/assets/images/save.png"' [value]="translate('Guardar')"
                        (onClick)="onGuardarConfLadoCelda()"> </jqxButton>

                    <jqxButton style="float: left; margin-left: 1px" [width]="100" [height]="26" [imgPosition]="'left'"
                        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
                        [imgSrc]='"/assets/images/salir-ventana.png"' [value]="translate('Salir')"
                        (onClick)="window.close()"> </jqxButton>
                </div>
            </div>
        </jqxTabs>
    </div>
</jqxWindow>