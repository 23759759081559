import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { MovilModel } from '../resources/models/movil.model';

@Injectable({
  providedIn: 'root'
})
export class MovilesAlarmasGeoService {

  constructor() { }

  private movilesSinAsignarSubject = new BehaviorSubject<MovilModel[]>([]);
  private movilesAsignadosSubject = new BehaviorSubject<MovilModel[]>([]);

  movilesSinAsignar$: Observable<MovilModel[]> = this.movilesSinAsignarSubject.asObservable();
  movilesAsignados$: Observable<MovilModel[]> = this.movilesAsignadosSubject.asObservable();

  movilesSinAsignarCount$: Observable<number> = this.movilesSinAsignar$.pipe(
    map((moviles) => moviles.length)
  );

  movilesAsignadosCount$: Observable<number> = this.movilesAsignados$.pipe(
    map((moviles) => moviles.length)
  );

  updateMovilesSinAsignar(moviles: MovilModel[]): void {
    this.movilesSinAsignarSubject.next(moviles);
  }

  updateMovilesAsignados(moviles: MovilModel[]): void {
    this.movilesAsignadosSubject.next(moviles);
  }
}
