import { HttpClient } from '@angular/common/http';
import { ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MenuModel } from './models/menu.model';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private viewContainerRef: ViewContainerRef;

  constructor(private http: HttpClient) { }

  // Recupera un menú según la aplicación y la empresa
  async getMenu(aplicacionId: number, empresaId: number, rolId: number): Promise<MenuModel> {
    try {
      return await this.http.get<MenuModel>(environment.apiSSO + '/sso/menu/aplicacion/rol/' +
        empresaId + '/' + aplicacionId + "/" + rolId).toPromise();
    } catch (e) {
      console.log(e);
    }
  }

  public setViewContainerRef(ref: ViewContainerRef): void {
    this.viewContainerRef = ref;
  }

  public createComponent<T>(component: new (...args: any[]) => T): ComponentRef<T> {
    if (!this.viewContainerRef) {
      throw new Error('No ViewContainerRef set!');
    }

    const componentRef = this.viewContainerRef.createComponent<T>(component);

    return componentRef;
  }
}
