<div style="width: 100%; height: 100%;">
  <div style="display: flex; align-content: center; flex-wrap: wrap;">
    <app-header #header style="width: 100%;" [subFlota]="true" (onChangeSubflotas)="onChangeSubflotas($event)">
    </app-header>
  </div>
  <div style="height: calc(100% - 40px)">
    <label class="label-moviles-total">{{ translate('Moviles') + ' (' + countPartialResults() + ')'}}</label>
    <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapter" [appCellClick]
      [columnsresize]="true" [filterable]="true" [showfilterrow]="false" [filtermode]="'excel'"
      (onFilter)="onFilterGrid($event)" [enablebrowserselection]="true" [selectionmode]="'checkbox'" [columns]="columns"
      [sortable]="true" (onBindingcomplete)="onGridDataUpdate($event)" (onFilter)="onGridDataUpdate($event)"
      [altrows]="true" [showrowlines]="false" [filterrowheight]="30" [rowsheight]="25" [columnsheight]="20"
      [toolbarheight]="32" [localization]="langGrid" [updatefilterconditions]="updatefilterconditions"
      (onRowselect)="onSelect()" (onRowunselect)="onSelect()">
    </jqxGrid>
  </div>
</div>