import { Injectable } from '@angular/core';
import { DateIdentificacionModel } from '../cerraduras/models/dateIdentificacion';
import { HistoricoEnvioIdentiiicadorModel } from './models/historicoEnviosIdentificador.model';
import { HttpClient } from '@angular/common/http';
import { SsoService } from '../sso/sso.service';
import { element } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class HistoricoEnviosIdentificadorService {

  constructor(private http: HttpClient, private ssoService: SsoService) { }

  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;
  private urlApi = this.ssoService.getTicket().UrlApi;

  async getHistoricosEnviosIdentificador(date: DateIdentificacionModel): Promise<HistoricoEnvioIdentiiicadorModel[]> {
    let result: HistoricoEnvioIdentiiicadorModel[] = [];
    let desde = date.Desde.toISOString();
    let hasta = date.Hasta.toISOString();
    try {
      result = await this.http.get<HistoricoEnvioIdentiiicadorModel[]>(this.urlApi + '/api/cerraduras/historico/listado/envios/identificaciones?enterprise='
        + this.empresaId + '&from=' + desde + '&to=' + hasta).toPromise();

      // Negamos el campo noCerroTapa porque tiene lógica negativa
      if (result !== undefined || result?.length || result !== null) {
        result.forEach(element => {
          if (element.Detalle !== null || element.Detalle?.length) {
            element.Detalle.forEach(identificacion => {
              identificacion.noCerroTapa = !identificacion.noCerroTapa;
            });
          }
        });
      }
    } catch (e) {
    }
    return result;
  }

}
