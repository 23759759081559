<div class="loader-container" *ngIf="showLoader">
    <div class="loader"></div>
</div>
<jqxWindow #form style="--actions: L;" jqxWindowAutoResize [theme]="environment.tema" [zIndex]="900" [autoOpen]="true"
    [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Alarmas_geograficas')}}
    </div>
    <div class="form" style="float:left;">
        <div>
            <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true"
                [resetFiltro]="true" (eventExportar)="onExportar()" (eventImprimir)="onPrint()"
                (eventBuscador)="onBuscar()" (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()">
            </app-header>
        </div>
        <div style="float:left; margin-top: 6px; width: 100%; height: 100%;">
            <jqxGrid #gridAlarmas [theme]="environment.tema" [width]="'100%'" [height]="'calc(100% - 90px)'"
                [source]="dataAdapter" [columnsautoresize]="true" [columnsresize]="true" [localization]="langGrid"
                [filterable]="true" [columns]="columns" [showrowlines]="false" [showcolumnlines]="true"
                [pageable]="false" [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20"
                [columnmenuopening]='columnmenuopening' [showaggregates]="true" [rowsheight]="25" [columnsheight]="20"
                [editable]="false" [rowdetails]="true" [rowdetailstemplate]="rowdetailstemplate" [filter]="filter"
                [updatefilterconditions]="updatefilterconditions" [enablebrowserselection]="true"
                [initrowdetails]="initRowDetails" (onFilter)="onFilter($event)" (onRowclick)="onRowSelect($event)"
                (onSort)="onSort($event)" (onRowdoubleclick)="onRowdoubleclick($event)">
            </jqxGrid>

            <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="70" [height]="26"
                [textPosition]="'center'" [value]="translate('Salir')" (onClick)="closeWindow()">
            </jqxButton>
        </div>
    </div>
</jqxWindow>
<div *ngIf="mostrarEditForm">

    <nz-modal [(nzVisible)]="isVisible" [nzWidth]="380" [nzAutofocus]="'ok'" [nzZIndex]="1000" nzTitle="Info"
        (nzOnCancel)="handleCancel()" (nzOnOk)="handleOk()">
        <ng-container *nzModalContent>
            <p>example&#64;example.com;example2&#64;example.com</p>
        </ng-container>
    </nz-modal>

    <jqxWindow #formEdit [position]="getFormPos(formEdit)" [width]="mapWidth" [height]="mapHeight" [autoOpen]="true"
        [isModal]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
        (onClose)="onCloseEdit()" (onOpen)="onOpenEdit()" (onExpand)="onExpandEdit($event)">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Edicion_alarmas')}}
        </div>
        <div class="form" style="float:left;overflow: hidden">

            <jqxTabs #tabs [width]="'100%'" [height]="'100%'" [theme]="environment.tema" [auto-create]="true"
                (onSelected)="onTabClick($event)">
                <ul>
                    <li style="height: 15px !important;">{{ translate('Alarmas_geograficas') }}</li>
                    <li style="height: 15px !important;">{{ translate('Asignacion_alarmas') }}</li>
                </ul>

                <div class="form-container">
                    <div class="form-content-grid">
                        <div class="form-content">
                            <div class="form-column">
                                <div class="form-group">
                                    {{translate('Zona_cartografica')}}
                                    <div class="form-row">
                                        <div style="width: calc(100% - 50px);">
                                            <jqxDropDownList #cbZonas [width]="'100%'" [height]="25" [selectedIndex]="0"
                                                (onSelect)="onChangeZona($event)" [searchMode]="'containsignorecase'"
                                                [filterable]="true" [filterPlaceHolder]="'Buscar...'">
                                            </jqxDropDownList>
                                        </div>
                                        <div class="button-group">
                                            <jqxButton class="button" [title]="translate('Ver_zona')"
                                                (onClick)="onVerZona($event)" [disabled]="sinZona">
                                                <i class="fa-solid fa-eye"></i>
                                            </jqxButton>
                                            <jqxButton class="button" [title]="translate('Añadir_zona')"
                                                (onClick)="onChangeAreasZonas($event)">
                                                <i class="fa-solid fa-plus"></i>
                                            </jqxButton>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label>Nombre</label>
                                    <div style="width: calc(100% - 50px);">
                                        <jqxInput #ebNombre [(ngModel)]="alarmaEdit.Nombre" [width]="'100%'"
                                            [height]="25" [disabled]="!canEdit">
                                        </jqxInput>
                                    </div>
                                </div>
                            </div>

                            <div class="form-column">
                                <div class="form-group">
                                    <div class="checkbox-row">
                                        <input width="" [(ngModel)]="alarmaEdit.Entrada" type="checkbox"
                                            (change)="onChangeCtrlEntrada($event)" [disabled]="!canEdit" />
                                        <label>{{ translate('Controlar_entrada') }}</label>
                                    </div>
                                    <div class="form-row">
                                        <label>{{translate('Hora_maxima')}}</label>
                                        <jqxDateTimeInput #horaEntrada [(ngModel)]="alarmaEdit.HoraEntrada" [width]="95"
                                            [height]="22" [dropDownVerticalAlignment]="'top'"
                                            [formatString]="'HH:mm:ss'" [showTimeButton]="true"
                                            [showCalendarButton]="false" [disabled]="!alarmaEdit.Entrada || !canEdit">
                                        </jqxDateTimeInput>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="checkbox-row">
                                        <input type="checkbox" [(ngModel)]="alarmaEdit.Salida"
                                            (change)="onChangeCtrlSalida($event)" [disabled]="!canEdit" />
                                        <label> {{translate('Controlar_salida')}}</label>
                                    </div>
                                    <div class="form-row">
                                        <label> {{translate('Hora_maxima')}}</label>
                                        <jqxDateTimeInput #horaSalida [(ngModel)]="alarmaEdit.HoraSalida" [width]="95"
                                            [height]="22" [dropDownVerticalAlignment]="'top'"
                                            [formatString]="'HH:mm:ss'" [showTimeButton]="true"
                                            [showCalendarButton]="false" [disabled]="!alarmaEdit.Salida || !canEdit">
                                        </jqxDateTimeInput>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="checkbox-row">
                                        <input type="checkbox" [(ngModel)]="alarmaEdit.Repetir"
                                            (change)="onChangeRepetir($event)" [disabled]="!canEdit" />
                                        <label>{{ translate('Repetir') }}</label>
                                    </div>
                                    <div class="days-row">
                                        <label>Días:</label>
                                        <div class="days-checkboxes">
                                            <label><input type="checkbox" [(ngModel)]="dias.lunes"
                                                    [disabled]="!alarmaEdit.Repetir || !canEdit" /> L</label>
                                            <label><input type="checkbox" [(ngModel)]="dias.martes"
                                                    [disabled]="!alarmaEdit.Repetir || !canEdit" /> M</label>
                                            <label><input type="checkbox" [(ngModel)]="dias.miercoles"
                                                    [disabled]="!alarmaEdit.Repetir || !canEdit" /> X</label>
                                            <label><input type="checkbox" [(ngModel)]="dias.jueves"
                                                    [disabled]="!alarmaEdit.Repetir || !canEdit" /> J</label>
                                            <label><input type="checkbox" [(ngModel)]="dias.viernes"
                                                    [disabled]="!alarmaEdit.Repetir || !canEdit" /> V</label>
                                            <label><input type="checkbox" [(ngModel)]="dias.sabado"
                                                    [disabled]="!alarmaEdit.Repetir || !canEdit" /> S</label>
                                            <label><input type="checkbox" [(ngModel)]="dias.domingo"
                                                    [disabled]="!alarmaEdit.Repetir || !canEdit" /> D</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-column">
                                <div class="form-group">
                                    <label>{{ translate('Lista_emails') }}</label>
                                    <jqxDropDownList #sUsuarios (onCheckChange)="onChangeUser($event)" [width]="'100%'"
                                        [height]="'25px'" [displayMember]="'Nombre'" [valueMember]="'Id'"
                                        [source]="usuarioAdapter" [filterable]="true" [checkboxes]="true">
                                    </jqxDropDownList>
                                    <jqxTextArea #emails [(ngModel)]="alarmaEdit.Emails" [width]="'100%'"
                                        [height]="'70px'" [disabled]="!canEdit">
                                    </jqxTextArea>
                                </div>

                                <div class="form-group">
                                    <label>{{ translate('Observaciones') }}</label>
                                    <jqxInput #observaciones [(ngModel)]="alarmaEdit.Observaciones" [width]="'100%'"
                                        [height]="'70px'" [disabled]="!canEdit">
                                    </jqxInput>
                                </div>
                            </div>
                        </div>

                        <div class="form-buttons-left">
                            <jqxButton style="float: left;" [width]='100' [height]='26'
                                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                                [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardarAlarma($event)"
                                [value]="translate('Guardar')" [disabled]="!canEdit">
                            </jqxButton>
                            <jqxButton style="float: left; margin-left: 5px;" [width]="100" [height]="26"
                                [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
                                (onClick)="formEdit.close()" [value]="translate('CANCELAR')">
                            </jqxButton>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="tables-wrapper">
                        <div class="grid-container">
                            <div class="grid-header">
                                <div>{{ translate('Moviles_disponibles') }}: ({{ totalDisponibles() }})</div>
                            </div>

                            <jqxGrid #gridMoviles1 [theme]="environment.tema" [width]="'100%'"
                                [height]="'calc(100% - 22px)'" [appCellClick] [source]="dataAdapterMoviles1"
                                [columnsresize]="true" [sortable]="true" [selectionmode]="'checkbox'"
                                [showrowlines]="false" [showcolumnlines]="true" [enablebrowserselection]="true"
                                [showrowlines]="false" [columns]="columnsMoviles1" [altrows]="true" [filterable]="true"
                                [autoshowfiltericon]="true" [filterrowheight]="30" [rowsheight]="22"
                                [columnsheight]="20" [statusbarheight]="20" [localization]="langGrid" [filter]="filter"
                                [updatefilterconditions]="updatefilterconditions" [disabled]="!canEdit" (onBindingcomplete)="onBindingLibres()">
                            </jqxGrid>

                        </div>

                        <div class="move-buttons">
                            <jqxButton [width]="25" [height]="26" [imgSrc]="'/assets/images/derecha.png'"
                                (onClick)="onMasClick()" [disabled]="!canEdit">
                            </jqxButton>
                            <jqxButton [width]="25" [height]="26" [imgSrc]="'/assets/images/izquierda.png'"
                                (onClick)="onMenosClick()" [disabled]="!canEdit">
                            </jqxButton>
                        </div>

                        <div class="grid-container">
                            <div class="grid-header">
                                <div>{{ translate('Moviles_asignados') }}: ({{ totalAsignadas() }})</div>
                            </div>

                            <jqxGrid #gridMoviles2 [theme]="environment.tema" [width]="'100%'"
                                [height]="'calc(100% - 22px)'" [source]="dataAdapterMoviles2" [columnsresize]="true"
                                [sortable]="true" [appCellClick] [selectionmode]="'checkbox'" [showrowlines]="false"
                                [showcolumnlines]="true" [enablebrowserselection]="true" [showrowlines]="false"
                                [columns]="columnsMoviles2" [altrows]="true" [filterable]="true"
                                [autoshowfiltericon]="true" [filterrowheight]="30" [rowsheight]="22"
                                [columnsheight]="20" [statusbarheight]="20" [localization]="langGrid" [filter]="filter"
                                [updatefilterconditions]="updatefilterconditions" [disabled]="!canEdit" (onBindingcomplete)="onBindingAsignadas()">
                            </jqxGrid>

                        </div>
                    </div>
                    <div class="form-buttons">
                        <jqxButton style="float: left; margin-left: 1px" [width]="100" [height]="26"
                            [imgPosition]="'left'" [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
                            [imgSrc]='"/assets/images/save.png"' [value]="translate('Guardar')"
                            (onClick)="onGuardarAsig()"> </jqxButton>

                        <jqxButton style="float: left; margin-left: 6px" [imgPosition]="'left'"
                            [textImageRelation]="'imageBeforeText'" [width]="100" [height]="26" [textPosition]="'left'"
                            [value]="translate('CANCELAR')" (onClick)="formEdit.close()">
                        </jqxButton>
                    </div>
                </div>
            </jqxTabs>
        </div>
    </jqxWindow>
    <div #menuContainer></div>
</div>