import { jqxGridComponent } from "jqwidgets-ng/jqxgrid";
import { environment } from "src/environments/environment";

/**
 * @deprecated Use especialized services instead
 */
export class Utils {
  static clone<T>(a: T): T {
    return JSON.parse(JSON.stringify(a));
  }

  static sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // ajusta el tamaño de las columnas del grid
  static renderSizeGrid(grid: jqxGridComponent, timeout = 100, exclude = []) {
    /* const t = setTimeout(() => {
      if (grid == null) return;
      grid.attrColumns.forEach((column: any) => {
        try {
          if (!exclude.includes(column.datafield)) {
            grid.autoresizecolumn(column.datafield, column.columntype);
          }
        } catch (e) {
        }
      })
    }, timeout); */
  }

  // quita los elementos duplicados del array y lo devuelve sin undefined
  static onChangeArray(list: any[]): any[] {
    list = list.filter((elem, index, self) =>
      index == self.findIndex((t) => t?.Id == elem?.Id) && elem !== undefined && elem !== null
    );
    return list;
  }

  static tooltiprenderer(element, tooltipMessage?) {
    if (element) {
      const jqElement = element[0].parentElement;
      const options = {
        content: tooltipMessage ? tooltipMessage : element.text(),
        theme: environment.tema,
        width: 'auto',
        height: 'auto',
        position: 'mouse',
        autoHide: true,
      };
      jqwidgets.createInstance(jqElement, 'jqxTooltip', options);
    }
  }

  static updatefilterconditions(type: string, defaultconditions: any): string[] {
    let stringcomparisonoperators = ['EMPTY', 'NOT_EMPTY', 'CONTAINS',
      'DOES_NOT_CONTAIN', 'STARTS_WITH', 'ENDS_WITH', 'EQUAL'];

    let dateNumberComparisonOperators = ['EQUAL', 'NOT_EQUAL', 'LESS_THAN',
      'LESS_THAN_OR_EQUAL', 'GREATER_THAN', 'GREATER_THAN_OR_EQUAL',
      'NULL', 'NOT_NULL'];

    switch (type) {
      case 'stringfilter':
        return stringcomparisonoperators;
      case 'numericfilter':
      case 'datefilter':
        return dateNumberComparisonOperators;
      case 'booleanfilter':
        return defaultconditions;
    }
  }

  static filterRow(
    cellValue: any,
    dataField: string,
    filterGroup: any,
    defaultFilterResult: boolean,
    filterColumns: string[],
    esFecha: boolean = false
  ) {
    if (!filterColumns.includes(dataField)) {
      return defaultFilterResult;
    }
    if (cellValue == null || cellValue === '') {
      return defaultFilterResult;
    }

    let filters = filterGroup.getfilters();
    let exists = true;

    for (let i = 0; i < filters.length; i++) {
      let filter = filters[i];
      let filterValue = filter.value;
      let filterCondition = filter.condition;
      let filterOperator = filter.operator;

      let timeToCompare;

      if (esFecha) {
        try {
          const parsedCellValue = new Date(cellValue);
          if (isNaN(parsedCellValue.getTime())) {
            return defaultFilterResult;
          }

          timeToCompare = new Date(filterValue);
          if (isNaN(timeToCompare.getTime())) {
            return defaultFilterResult;
          }
        } catch (e) {
          console.error(e);
          return defaultFilterResult;
        }
      }

      if (i === 0 || filterOperator === 1 || exists) {
        switch (filterCondition) {
          case 'EMPTY':
          case 'NULL':
            if (cellValue === null || cellValue === '') {
              exists = true;
            } else if (filterOperator === 0) {
              exists = false;
            }
            break;

          case 'NOT_EMPTY':
          case 'NOT_NULL':
            exists = !cellValue || filterOperator !== 0; //  Sugerido por Alex 2024-12-05
            break;

          case 'CONTAINS':
            if (String(cellValue).includes(String(filterValue))) {
              exists = true;
            } else if (filterOperator === 0) {
              exists = false;
            }
            break;

          case 'DOES_NOT_CONTAIN':
            if (!String(cellValue).includes(String(filterValue))) {
              exists = true;
            } else if (filterOperator === 0) {
              exists = false;
            }
            break;

          case 'STARTS_WITH':
            if (String(cellValue).toUpperCase().startsWith(String(filterValue).toUpperCase())) {
              exists = true;
            } else if (filterOperator === 0) {
              exists = false;
            }
            break;

          case 'ENDS_WITH':
            if (String(cellValue).toUpperCase().endsWith(String(filterValue).toUpperCase())) {
              exists = true;
            } else if (filterOperator === 0) {
              exists = false;
            }
            break;

          case 'EQUAL':
            if (esFecha) {
              if (new Date(cellValue).getTime() === new Date(filterValue).getTime()) {
                exists = true;
              } else if (filterOperator === 0) {
                exists = false;
              }
            } else if (String(cellValue) === String(filterValue)) {
              exists = true;
            } else if (filterOperator === 0) {
              exists = false;
            }
            break;

          case 'NOT_EQUAL':
            if (esFecha) {
              if (new Date(cellValue).getTime() !== new Date(filterValue).getTime()) {
                exists = true;
              } else if (filterOperator === 0) {
                exists = false;
              }
            } else if (String(cellValue) !== String(filterValue)) {
              exists = true;
            } else if (filterOperator === 0) {
              exists = false;
            }
            break;

          case 'LESS_THAN':
            if (esFecha) {
              if (new Date(cellValue).getTime() < new Date(filterValue).getTime()) {
                exists = true;
              } else {
                exists = false;
              }
            } else if (cellValue < filterValue) {
              exists = true;
            } else {
              exists = false;
            }
            break;

          case 'LESS_THAN_OR_EQUAL':
            if (esFecha) {
              if (new Date(cellValue).getTime() <= new Date(filterValue).getTime()) {
                exists = true;
              } else {
                exists = false;
              }
            } else if (cellValue <= filterValue) {
              exists = true;
            } else {
              exists = false;
            }
            break;

          case 'GREATER_THAN':
            if (esFecha) {
              if (new Date(cellValue).getTime() > new Date(filterValue).getTime()) {
                exists = true;
              } else {
                exists = false;
              }
            } else if (cellValue > filterValue) {
              exists = true;
            } else {
              exists = false;
            }
            break;

          case 'GREATER_THAN_OR_EQUAL':
            if (esFecha) {
              if (new Date(cellValue).getTime() >= new Date(filterValue).getTime()) {
                exists = true;
              } else {
                exists = false;
              }
            } else if (cellValue >= filterValue) {
              exists = true;
            } else {
              exists = false;
            }
            break;

          default:
            break;
        }
      }
    }
    return exists;
  }

  static generateAutofilterHeader(sheet) {
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }
}
