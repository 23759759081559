import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';
import { MapComponent, MapLatLng, MapMarker } from 'movisat-maps';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonComponent } from 'jqwidgets-ng/jqxbuttons';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { EntradaModel } from 'src/app/services/zones/models/zona-entrada.model';
import { ZonesComponent } from '../zones.component';

@Component({
  selector: 'app-entrada-salida',
  templateUrl: './entrada-salida.component.html',
  styleUrls: ['./entrada-salida.component.css']
})
export class EntradaSalidaComponent extends CustomForms implements OnInit {
  // variables recuperadas del html
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('gridEntrLocal') gridEntrLocal: jqxGridComponent;
  @ViewChild('formEntradaSalida') formEntradaSalida: jqxWindowComponent;
  @ViewChild('create') create: jqxButtonComponent;
  @ViewChild('edit') edit: jqxButtonComponent;
  @ViewChild('delete') delete: jqxButtonComponent;
  // variables para el mapa
  public theme = environment.tema;
  private map: MapComponent;
  // variables para el grid
  dataSourceLocal: any;
  dataAdapterLocal: any;
  public langGrid = JqWidgets.getLocalization('es');
  @Input() editable: boolean;
  row: number;
  public static _this: any;
  // variables para el crud
  public editEntrada: boolean = false;
  public entrada: EntradaModel = new EntradaModel();
  public entradas: EntradaModel[] = [];
  entradasLocal: EntradaModel[] = [];
  entradaLocal: EntradaModel = new EntradaModel();
  public tipoEntrada: boolean = false;
  public tipoSalida: boolean = false;
  viewFormEntrada: boolean = false;
  // marcadores
  markers: MapMarker[] = [];
  auxMarker: MapMarker[] = [];
  marker: MapMarker;
  public zonaSelect: ZonaModel = new ZonaModel();
  // suscripción a los eventos del mapa
  private subscriptionOnMapClick = null;
  private subscriptionOnMarkerMove = null;
  private subscriptionOnCircleClick = null;
  private subscriptionOnPolygonClick = null;
  isVisible = false;

  public columns = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'input', datafield: 'nombre', width: 140,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">Total: ' +
            aggregates['Total'] + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Direccion'),
      columntype: 'textbox',
      filtertype: 'input',
      datafield: 'direccion',
      align: 'left',
      width: 140
    },
    { text: AppComponent.translate('Poblacion'), columntype: 'textbox', filtertype: 'input', datafield: 'poblacion', width: 140 },
    { text: AppComponent.translate('Municipio'), columntype: 'textbox', filtertype: 'input', datafield: 'municipio', width: 140 },
    {
      text: AppComponent.translate('Tipo'),
      columntype: 'textbox',
      filtertype: 'checkedlist',
      filteritems: [this.translate('Entrada'), this.translate('Salida'), this.translate('Entrada') + '/' + this.translate('Salida')],
      datafield: 'tipo',
      align: 'center',
      cellsrenderer: this.renderRow,
      width: 100
    },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true, width: 1 }
  ];


  dataSource = {
    datatype: 'json',
    datafields: [
      { name: 'id', type: 'int', map: 'Id' },
      { name: 'nombre', type: 'string', map: 'Nombre' },
      { name: 'direccion', type: 'string', map: 'Direccion' },
      { name: 'poblacion', type: 'string', map: 'Poblacion' },
      { name: 'municipio', type: 'string', map: 'Municipio' },
      { name: 'tipo', type: 'string', map: 'Tipo' },
      { name: 'selec', type: 'string' },
    ],
    localdata: this.entradas,
  };
  dataAdapter = new jqx.dataAdapter(this.dataSource);


  public columnsLocal = [
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', width: 140, filtertype: 'textbox', datafield: 'nombre',
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">Total: ' +
            aggregates['Total'] + '</div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', datafield: 'tipo', width: 90, cellsrenderer: this.renderRow },
    { text: AppComponent.translate('Direccion'), columntype: 'textbox', datafield: 'direccion', },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true, width: 1 }
  ];

  constructor(private zonesService: ZonesService, private ssoService: SsoService) {
    super();
    EntradaSalidaComponent._this = this;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.map = MainComponent.getInstance().getActiveMap();
    this.initGridLocal();
  }

  ngAfterViewInit() {
    this.setGridLocal(this.grid);
    if (ZonesComponent._this.zonaEdit.Id !== 0) {
      this.cargarMarkers();
    }
  }

  async getDireccion(element: any, lat: number, lng: number): Promise<void> {
    try {
      const data = await this.zonesService.getDireccion(lat, lng);
      if (data?.District !== null && data?.District !== undefined) {
        element.Municipio = data.Locality;
      } else if (data?.District == null && data?.Locality == undefined) {
        element.Poblacion = data?.Locality;
      }
      element.Poblacion = data?.District;
    } catch (error) {
      console.error(error);
    }
  }

  //obtengo todas las entradas
  async getEntradas(edit: boolean) {
    // Limpieza inicial de entradas
    this.entradas = [];
    this.entradasLocal = [];
    if (this.dataSource.localdata.length > 0) {
      this.dataSource.localdata = [];
      this.grid.updatebounddata('data');
    }

    if (edit) {
      this.zonaSelect = ZonesComponent._this.zonaEdit;
      try {
        // Obtener entradas de la zona seleccionada
        this.entradas = await this.zonesService.getEntradas(this.zonaSelect.Id);
        // Obtener direcciones de todas las entradas
        for (const element of this.entradas) {
          await this.getDireccion(element, element.Lat, element.Lng);
        }
        // Actualizar la tabla de entradas
        if (this.entradas.length > 0) {
          this.dataSource.localdata = this.entradas;
          this.grid.updatebounddata();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      this.dataSource.localdata = this.entradas;
      this.grid.updatebounddata('data');
    }
  }

  initGridLocal() {
    this.dataSourceLocal = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'int', map: 'Id' },
        { name: 'nombre', type: 'string', map: 'Nombre' },
        { name: 'tipo', type: 'string', map: 'Tipo' },
        { name: 'direccion', type: 'string', map: 'Direccion' },
        { name: 'selec', type: 'string' },
      ],
      localdata: this.entradasLocal,
    };
    this.dataAdapterLocal = new jqx.dataAdapter(this.dataSourceLocal);
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean {
    if (dataField == 'tipo') {
      let filterValue = filterGroup.getfilters()[0].value;

      switch (filterValue) {
        case this.translate('Entrada'):
          if (cellValue == 1) {
            return true;
          }
          break;
        case this.translate('Salida'):
          if (cellValue == 2) {
            return true;
          }
          break;
        case this.translate('Entrada') + '/' + this.translate('Salida'):
          if (cellValue == 3) {
            return true;
          }
        default:
          return false;
      }
    } else {
      let filterColumns = [
        'nombre',
        'direccion',
        'poblacion',
        'municipio'
      ];

      return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
    }
  }

  async cargarMarkers() {
    this.zonaSelect.Id = ZonesComponent._this.zonaEdit.Id;
    if (this.zonaSelect.Id === 0) {
      return
    } else {
      this.entradas = await this.zonesService.getEntradas(this.zonaSelect.Id);
      this.entradas = Utils.onChangeArray(this.entradas);
      this.entradas.forEach(entrada => {
        this.paintMarkers(entrada, new MapLatLng(entrada.Lat, entrada.Lng));
      });
    }
  }

  // Suscripción a los eventos del mapa
  subscribeToMapEvents() {
    // si no hay subscripción, la creo
    if (!this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick = this.map.subscribeOnMapClick(this, this.onCreateEntrada);
      return;
    }
    if (!this.subscriptionOnMarkerMove) {
      this.subscriptionOnMarkerMove = this.map.subscribeOnMarkerMove(this, this.onMarkerMove);
      return;
    }
    if (!this.subscriptionOnCircleClick) {
      this.subscriptionOnCircleClick = this.map.subscribeOnCircleClick(this, this.onCreateEntrada);
      return;
    }
    if (!this.subscriptionOnPolygonClick) {
      this.subscriptionOnPolygonClick = this.map.subscribeOnPolygonClick(this, this.onCreateEntrada);
      return;
    }
  }

  onCreateEntrada(_this: EntradaSalidaComponent, position: MapLatLng) {
    _this.createMarker(position);
  }

  onMarkerMove(_this: EntradaSalidaComponent, marker: MapMarker) {
    if (_this.marker == marker) {
      _this.marker.setPosition(new MapLatLng(marker.position.lat, marker.position.lng));
    }
  }

  //para crear un marcador y pinto su icono correspondiente
  async createMarker(position: any) {
    if (!this.controlForm()) {
      return;
    }

    position = this.normalizePosition(position);

    this.marker = this.map.addMarker(this.getMarkerOptions(position));
    this.markers.push(this.marker);

    const data = await this.zonesService.getDireccion(position.lat, position.lng);
    this.updateEntradaDireccion(data);

    this.entradasLocal.push(this.entrada);
    this.dataSourceLocal.localdata = this.entradasLocal;
    this.gridEntrLocal.updatebounddata();

    if (this.entradasLocal.length === 1) {
      MainComponent.getInstance().showInfo('ATENCION', 'Puede_anadir_mas_entradas', 2000);
    }

    this.sortAndResetForm();
  }


  updateEntradaDireccion(data: any): void {
    this.entrada.Direccion = data.Street;
  }

  sortAndResetForm(): void {
    setTimeout(() => {
      this.gridEntrLocal.sortby('nombre', 'asc');
      this.resetForm();
    }, 220);
  }

  normalizePosition(position: any): any {
    if (position.lat === undefined && position.lng === undefined) {
      position.lat = position.clickPoint.lat;
      position.lng = position.clickPoint.lng;
    }
    return position;
  }

  getMarkerOptions(position: any): any {
    return {
      dataModel: this.entrada,
      title: this.entrada.Nombre,
      content: this.zonaSelect.Observaciones,
      position: position.id ? position.clickPoint : position,
      icon: this.iconMarker(this.entrada),
      zIndex: 999,
      drag: this.editEntrada || true,
      visible: true
    };
  }

  setGridLocal(grid: jqxGridComponent) {
    grid.setOptions({
      statusbarheight: 15,
      columnsresize: true,
      rowsheight: 20,
      columnsheight: 20
    });
  }

  onRowClickEdit(event) {
    const rowIndex = event.args.rowindex;
    const rowData = this.gridEntrLocal.getrowdata(rowIndex);

    if (rowData) {
      this.updateEntryType(this.entrada.Tipo);
      this.gridEntrLocal.setcellvalue(rowIndex, 'selec', this.markers[rowIndex].id);

      // Encuentra y anima el marcador correspondiente
      const markerToAnimate = this.markers.find(marker => marker.id === rowData.selec);
      if (markerToAnimate) {
        markerToAnimate.animate(2000);
      }
    }
  }

  paintMarkers(entrada: EntradaModel, position: MapLatLng, map?: MapComponent) {
    const addMarkerToMap = (mapInstance: MapComponent, addList: boolean) => {
      let marker = mapInstance.addMarker({
        dataModel: entrada,
        title: entrada.Nombre,
        content: entrada.Nombre,
        position: position,
        icon: this.iconMarker(entrada),
        zIndex: 999,
        drag: false,
        visible: true
      });

      if (addList) {
        this.markers.push(marker);
      } else {
        ZonesComponent._this.markersZone.push(marker);
      }
    };

    // Añadir el marcador al mapa proporcionado, si existe
    if (map) {
      addMarkerToMap(map, false);
    }

    // Añadir el marcador al mapa por defecto
    let markerMap = this.map.getMarkerList();
    // busco si existe el marcador en el mapa
    let marker = Array.from(markerMap.values()).find(marker => marker.dataModel.Id === entrada.Id);
    if (!marker) {
      addMarkerToMap(this.map, true);
    }
  }


  async createEntrada(lat: number, lng: number, marker: MapMarker) {
    this.entrada.Lat = lat;
    this.entrada.Lng = lng;
    this.entrada.ZonaId = ZonesComponent._this.creandoZonaMasEntradas ? ZonesComponent._this.idZonaEntrada : this.zonaSelect.Id;

    let nuevaEntrada = await this.zonesService.addEntrada(this.entrada);

    if (nuevaEntrada) {
      this.entrada = nuevaEntrada;
      marker.setData(this.entrada);

      // Eliminar entrada existente si ya está en la lista
      const entradaIndex = this.entradas.findIndex(e => e.Id === nuevaEntrada.Id);
      if (entradaIndex !== -1) {
        this.entradas.splice(entradaIndex, 1);
      }

      this.entradas.push(nuevaEntrada);
    }
  }


  //control de formulario nombre y check obligatorios
  controlForm(): boolean {
    if (!this.entrada.Nombre || this.entrada.Nombre.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return false;
    }
    if (!this.tipoEntrada && !this.tipoSalida) {
      MainComponent.getInstance().showError('ATENCION', 'Chequee_entrada_salida', 2000);
      return false;
    }
    return true;
  }

  typeEntrada() {
    if (this.tipoEntrada && this.tipoSalida) {
      this.entrada.Tipo = 3;
    } else if (this.tipoEntrada) {
      this.entrada.Tipo = 1;
    } else if (this.tipoSalida) {
      this.entrada.Tipo = 2;
    }
  }

  check() {
    this.typeEntrada();
    if (this.marker && this.editEntrada) {
      this.iconMarker(this.entrada);
    }
  }

  //pinto el icono del marcador segun el tipo de entrada y si existe el marcador lo pinto de una manera u otra
  iconMarker(entrada: any): string {
    const icons = {
      1: '/assets/images/entrada.png',
      2: '/assets/images/salida.png',
      3: '/assets/images/entrada_salida.png'
    };
    const iconUrl = icons[entrada.Tipo] || '';

    if (this.marker && this.editEntrada) {
      this.marker.setIcon(iconUrl);
    }
    return iconUrl;
  }

  //select grid
  onRowclick(event) {

    this.row = event.args.row.bounddata.id;
    const rowIndex = event.args.rowindex;
    const rowData = this.grid.getrowdata(rowIndex);

    if (rowData) {
      this.updateEntryType(this.entrada.Tipo);
      this.grid.setcellvalue(rowIndex, 'selec', this.markers[rowIndex]?.id);

      // Encuentra y anima el marcador correspondiente
      const markerToAnimate = this.markers.find(marker => marker.id === rowData.selec);
      if (markerToAnimate) {
        markerToAnimate.animate(2000);
      }
    }
  }

  onRowdoubleclick(event) {
    this.onEditarES(event);
  }

  //cambio el formato de celda a string, segun el tip de entrada
  renderRow(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    switch (columnfield) {
      case 'tipo':
        switch (value) {
          case 1:
            return '<div style="margin-left: 4px; margin-top: 2px;"' + columnproperties.cellsalign + ';">' + AppComponent.translate('Entrada') + '</div>';
          case 2:
            return '<div style="margin-left: 4px; margin-top: 2px;" ' + columnproperties.cellsalign + ';">' + AppComponent.translate('Salida') + '</div>';
          case 3:
            return '<div style="margin-left: 4px; margin-top: 2px;"' + columnproperties.cellsalign + ';">' + AppComponent.translate('Entrada') + '/' + AppComponent.translate('Salida') + '</div>';
        }
    }
  }

  //FUNCIONALIDADES DE ENTRADAS Y SALIDAS
  // crear entrada
  async onCrearES(event: any) {
    if (ZonesComponent._this.circle != null || ZonesComponent._this.polygon != undefined
      || ZonesComponent._this.polygonGeoJson != null) {
      this.formEntradaSalida.open();
      this.setGridLocal(this.gridEntrLocal);
      this.auxMarker = this.markers;
      this.markers = [];
      this.marker = null;
      if (ZonesComponent._this.subscriptionOnMapClick) {
        ZonesComponent._this.subscriptionOnMapClick.unsubscribe();
        ZonesComponent._this.subscriptionOnMapClick = null;
      }
    } else {
      MainComponent.getInstance().showError(
        'ATENCION', 'Seleccione_punto_cartografia', 2000
      );
    }
  }

  async onGuardarES(event: any) {
    try {
      await this.prepareZona();
      await this.handleMarkers();

      if (this.markers.length > 0) {
        await this.processMarkers();
        this.finalizeSave();
      } else {
        this.showError('ATENCION', 'Fallo_almacenar_info', 2000);
      }
    } catch (error) {
      this.showError('ATENCION', 'Error_interno', 2000);
    }
  }

  private async prepareZona() {
    this.zonaSelect = ZonesComponent._this.zonaEdit;
    if (this.editEntrada && this.zonaSelect.Id === 0) {
      this.editEntrada = false;
    }

    if (this.zonaSelect.Id === 0) {
      await ZonesComponent._this.onGuardarZona();
      this.entrada.ZonaId = ZonesComponent._this.idZonaEntrada;
      this.zonaSelect = ZonesComponent._this.zonaSelec;
    }
  }

  private async handleMarkers() {
    if (this.markers.length > 0) {
      for (const marker of this.markers) {
        if (!this.editEntrada) {
          this.entrada = marker.dataModel;
        }
        await this.createEntrada(marker.position.lat, marker.position.lng, marker);
      }
    }
  }

  private async processMarkers() {
    await Promise.all(
      this.entradas.map(element => this.getDireccion(element, element.Lat, element.Lng))
    );

    if (this.auxMarker.length > 0) {
      this.markers = this.auxMarker.concat(this.markers);
    }
  }

  private finalizeSave() {
    MainComponent.getInstance().showInfo('ATENCION', 'Registro_almacenado', 2000);
    this.formEntradaSalida.close();
    if (ZonesComponent._this.creandoZonaMasEntradas) {
      ZonesComponent._this.mostrarEditZona = true;
      ZonesComponent._this.formEditZona.expand();
      setTimeout(() => {
        ZonesComponent._this.tabZonasEdit.select(0);
        ZonesComponent._this.creandoZonaMasEntradas = false;
      }, 500);
    } else {
      ZonesComponent._this.formEditZona.expand();
      setTimeout(() => {
        ZonesComponent._this.tabZonasEdit.select(1);
      }, 500);
    }
  }

  private showError(title: string, message: string, duration: number) {
    MainComponent.getInstance().showError(title, message, duration);
  }


  // editar entrada
  async onEditarES(event: any) {
    // pinto el marcador en el mapa y lo centro
    let elem = this.entradas.find(elem => elem.Id == this.row);
    if (elem) {
      this.formEntradaSalida.open();
      this.setGridLocal(this.gridEntrLocal);
      this.editEntrada = true;
      this.entrada = elem;
      this.updateEntryType(this.entrada.Tipo);
      this.marker = this.markers.find(marker => marker.dataModel.Id === this.row);
      this.marker.setDraggable(true);
      this.marker.animate(2850);
      this.map.setCenter(this.marker.position);
      this.map.setZoom(18);
      // no dejo mover los demas marcadores
      this.markers.forEach(marker => {
        if (marker.dataModel.Id !== this.row) {
          marker.setDraggable(false);
        }
      });
      this.auxMarker = this.markers.filter(marker => marker.dataModel.Id !== this.row);
      this.markers = [];
      this.markers.push(this.marker);
      setTimeout(() => {
        if (this.subscriptionOnMapClick) {
          this.subscriptionOnMapClick.unsubscribe();
          this.subscriptionOnMapClick = null;
        }
      }, 200);
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  // borrar entrada
  onBorrarES() {
    let elem = this.entradas.find(elem => elem.Id == this.row);
    if (elem) {
      ZonesComponent._this.isVisible = true;
      ZonesComponent._this.rowSelectEntradaSalida = elem;
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  // abrir el formulario
  onOpen() {
    this.subscribeToMapEvents();
    this.addCustomForm(this.formEntradaSalida);
    this.formEntradaSalida.position(this.getFormPos(this.formEntradaSalida, 24));
    this.formEntradaSalida.setTitle(AppComponent.translate('Titulo_entr_salidas') + ' ' + this.zonaSelect.Nombre);
    if (this.zonaSelect.Id !== 0) {
      ZonesComponent._this.deleteZonesMap();
      this.zonesService.showZone(this.zonaSelect, this.map);
    }
    ZonesComponent._this.formEditZona.collapse();

  }

  // cierro el formulario
  onClose() {
    this.unsubscribeMapEvents();
    this.getEntradas(true);
    this.resetForm();
    this.editEntrada = false;
    this.removeMarker();
    // this.cargarMarkers();
    this.entradaLocal = new EntradaModel();
    this.entradasLocal = [];
    this.dataSourceLocal.localdata = [];
    this.gridEntrLocal.updatebounddata('data');

  }

  changePositionFormEntradaSalida() {
    const height: any = this.formEntradaSalida.height();
    const width: any = this.formEntradaSalida.width();

    const mapCenter = this.map.center;

    const zonalat = this.zonaSelect.Lat;
    const zonalng = this.zonaSelect.Lng;
    let zona = 0;

    if (this.zonaSelect.TipoGeo === 0) {
      // Ensure 'Radio' is a number and finite
      zona = Number(this.zonaSelect.Radio) || 0;  // Default to 0 if not a number
    } else if (this.zonaSelect.TipoGeo === 1) {
      let bounds = ZonesComponent._this.calculatePolygonBounds(this.zonaSelect.Geometria);
      zona = (Math.abs(bounds.nePoint.lat - bounds.swPoint.lat) + Math.abs(bounds.nePoint.lng - bounds.swPoint.lng)) / 4;
    }

    let newLat = mapCenter.lat;
    let newLng = mapCenter.lng;

    const overlapsVertical = Math.abs(mapCenter.lat - zonalat) < zona + height / 2;
    const overlapsHorizontal = Math.abs(mapCenter.lng - zonalng) < zona + width / 2;

    // Move the map only if there is an overlap
    if (overlapsVertical || overlapsHorizontal) {
      if (overlapsVertical) {
        newLat += height / 2 + zona; // Adjust latitude based on overlap
      }
      if (overlapsHorizontal) {
        newLng += width / 2 + zona; // Adjust longitude based on overlap
      }

      this.map.setCenter(new MapLatLng(newLat, newLng)); // Ensure MapLatLng is a valid constructor or replace with proper object structure
    }
  }





  // Desuscripción a los eventos del mapa
  unsubscribeMapEvents() {
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
      this.subscriptionOnMapClick = null;
    }
    if (this.subscriptionOnMarkerMove) {
      this.subscriptionOnMarkerMove.unsubscribe();
      this.subscriptionOnMarkerMove = null;
    }

    if (this.subscriptionOnCircleClick) {
      this.subscriptionOnCircleClick.unsubscribe();
      this.subscriptionOnCircleClick = null;
    }

    if (this.subscriptionOnPolygonClick) {
      this.subscriptionOnPolygonClick.unsubscribe();
      this.subscriptionOnPolygonClick = null;
    }

  }

  // reseteo el formulario
  resetForm() {
    this.entrada = new EntradaModel();
    this.tipoEntrada = false;
    this.tipoSalida = false;
  }

  // elimino los marcadores
  removeMarker() {
    if (this.markers.length > 0) {
      this.markers.forEach(marker => {
        this.map.removeMarker(marker);
      });
      this.marker = null;
      this.markers = [];
    }

    if (this.markers.length > 0) {
      this.markers.forEach(marker => {
        ZonesComponent._this.mapZonaEdit.removeMarker(marker);
      });
      this.marker = null;
      this.markers = [];
    }

    if (this.auxMarker.length > 0) {
      this.auxMarker.forEach(marker => {
        this.map.removeMarker(marker);
      });
      this.marker = null;
      this.auxMarker = [];
    }

    if (ZonesComponent._this.markersZone.length > 0) {
      ZonesComponent._this.markersZone.forEach(marker => {
        this.map.removeMarker(marker);
      });
      ZonesComponent._this.markersZone = [];

    }




  }

  // recupero el tipo de entrada para pintar el check
  updateEntryType(type: number) {
    switch (type) {
      case 1:
        this.tipoEntrada = true;
        this.tipoSalida = false;
        break;
      case 2:
        this.tipoEntrada = false;
        this.tipoSalida = true;
        break;
      case 3:
        this.tipoEntrada = true;
        this.tipoSalida = true;
        break;
      default:
        this.tipoEntrada = false;
        this.tipoSalida = false;
    }
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

}
