
import { UserLangService } from './services/common/user-lang/user-lang.service';
import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, AuthGuard } from './app.component';
import { ApiInterceptor } from './services/api/api.interceptor';
import { MainModule } from './components/main/main.module';
import es from '@angular/common/locales/es';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NzFormModule } from 'ng-zorro-antd/form';
import { IconDefinition } from '@ant-design/icons-angular';
import { NzIconModule, NZ_ICONS } from 'ng-zorro-antd/icon';
import { GUI_CONFIG } from 'movisat-common-gui';

registerLocaleData(es);

// Importo los iconos que usa Ng-Zorro
import * as AllIcons from '@ant-design/icons-angular/icons';
import { es_ES, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { environment } from 'src/environments/environment';

import '../app/extensions/string.extension';
import '../app/extensions/date.extension';


const antDesignIcons = AllIcons as {
  [key: string]: IconDefinition;
}
const icons: IconDefinition[] = Object.keys(antDesignIcons).map(key => antDesignIcons[key])

//Licencia JqxWidgets
jqx.credits = environment.jqLicense;

if (window.jqx) {
  window.jqx.credits = environment.jqLicense
}
else {
  window.jqx = {
    credits: environment.jqLicense
  }
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    MainModule,
    FormsModule,
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule,
    NzIconModule.forRoot(icons),
    NzFormModule,
    NzModalModule,
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: GUI_CONFIG,
      useFactory: (userLangService: UserLangService, _environment: typeof environment) => {
        return {
          theme: _environment.tema,
          language: userLangService.getUserLangShort(),
        };
      },
    },
    AuthGuard,
    {
      provide: NZ_I18N,
      useValue: es_ES
    }, {
      provide: NZ_ICONS,
      useValue: icons
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
