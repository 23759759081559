import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output, signal } from '@angular/core';
import { MainComponent } from 'src/app/components/main/main.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { BdtService } from '../bdt/bdt.service';
import { BdtEquipamientoModel } from '../bdt/models/bdt-equipamiento.model';
import { ConfigService } from '../config/config.service';
import { SsoService } from '../sso/sso.service';
import { ElementoModel } from './models/elem.model';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { Accion } from '../auditoria/models/accion.model';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { ElemImageModel } from './models/elem-image-model';
import { PuService } from '../pu/pu.service';
import { InstalacionService } from './instalacion/instalacion.service';
import { DetalleService } from './instalacion/detalleInstalacion/detalle.service';

@Injectable({
  providedIn: 'root'
})
export class ElementsService {
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  private REG_PAG = 50000; // Número de registros por página

  // Evento para añadir elementos
  @Output() addElementEmiter: EventEmitter<ElementoModel[]> = new EventEmitter();
  // Evento para indicar que se han terminado de cargar los elementos
  @Output() endLoadElementsEmiter: EventEmitter<void> = new EventEmitter();
  // Evento para crear elementos
  @Output() newElementEmiter: EventEmitter<ElementoModel> = new EventEmitter();
  // Evento para modificar elementos
  @Output() modifyElementEmiter: EventEmitter<ElementoModel> = new EventEmitter();
  // Evento para eliminar elementos
  @Output() deleteElementEmiter: EventEmitter<ElementoModel> = new EventEmitter();
  // Evento para avisar que se ha cambiado el filtro de elementos
  @Output() changeFilterEmiter: EventEmitter<void> = new EventEmitter();

  public elementos = new Map<number, ElementoModel>();
  public elemGenericos = true;
  private timerElem = undefined;
  private INT_ELEMENTOS = 15000; // Intervalo de descarga de elementos modificados
  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;
  recordCountTotal = signal<number>(0);
  recordCountUpdate = signal<number>(0);

  constructor(private http: HttpClient,
    private ssoService: SsoService,
    private puService: PuService,
    private instalacionService: InstalacionService,
    private detalleInstalacionService: DetalleService,
    private configService: ConfigService,
    private bdtService: BdtService,
    private auditoriaService: AuditoriaService) {
  }

  // Recupera los modelos de elementos
  async getElementsEquip(): Promise<BdtEquipamientoModel[]> {
    let result: BdtEquipamientoModel[] = [];
    try {
      if (BdtService.equipamiento.size < 1) {
        await this.bdtService.getCatalogoEquipamiento();
      }
      this.elemGenericos = this.bdtService.elemGenericos;
      return new Promise<BdtEquipamientoModel[]>((resolve, reject) => {
        for (const [key, value] of BdtService.equipamiento) {
          if (value.AreaInfluencia < 1) {
            value.AreaInfluencia = 30; // Valor por defecto
          }
          result.push(value);
        }
        resolve(result);
      });
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  async getElement(element: ElementoModel): Promise<ElementoModel> {
    let result: ElementoModel;
    try {
      await this.http.get<ElementoModel>(this.ssoService.getTicket().UrlApi + '/api/elemento?include=all&elementoId=' + element.Id).toPromise().then(
        res => {
          result = res;
        }
      )
    } catch (e) {
    }
    return result;
  }

  async getInstalacionElemento(idElement: number): Promise<any> {
    let result: any;
    try {
      await this.http.get<any>(this.ssoService.getTicket().UrlApi + '/api/instalacion/elemento?id=' + idElement + '&enterprise=' + this.ssoService.getTicket().Empresa.IdGestion + '&include=detalle').
        toPromise().then(
          res => {
            result = res;
          }
        )
    } catch (e) {
    }
    return result;
  }

  // Recupero los elementos
  async getElements(): Promise<void> {
    try {
      const from = new Date(0, 0, 1); // 01/01/1900 00:00:00
      let fechaMod = '';
      for (let pag = 1, result = null; (result = await this.getElementsPag(pag, from)) && result.length > 0; pag++) {
        result.forEach(elem => {
          if (!elem.FechaBaja) {
            this.elementos.set(elem.Id, elem);
          }
          if (elem.FechaModificacion > fechaMod) { // Me quedo con la fecha de modificación mayor
            fechaMod = elem.FechaModificacion;
          }
        });
        this.addElementEmiter.emit(result);
      }
      this.endLoadElementsEmiter.emit();
      // A partir de esta versión de la api se pueden pedir los elementos que han cambiado
      if (MainComponent.getInstance().apiVersion.apiVer >= '220804.01') {
        let lastTime = 0;
        if (this.timerElem) {
          clearInterval(this.timerElem);
        }
        this.timerElem = setInterval(async () => {
          if (lastTime > -1 && new Date().getTime() - lastTime >= this.INT_ELEMENTOS) {
            lastTime = -1; // Para evitar la reentrada
            if (fechaMod === '') {
              fechaMod = '1900-01-01T00:00:00';
            }
            for (let pag = 1, result = null; (result = await this.getElementsPag(pag, new Date(fechaMod))) && result.length > 0; pag++) {
              result.forEach(elem => {
                const existe = this.elementos.get(elem.Id);
                if (!elem.FechaBaja) {
                  this.elementos.set(elem.Id, elem);
                }
                if (elem.FechaModificacion > fechaMod) { // Me quedo con la fecha de modificación mayor
                  fechaMod = elem.FechaModificacion;
                }
                if (elem.FechaBaja) {
                  this.deleteElementEmiter.emit(elem);
                } else {
                  if (!existe) {
                    this.newElementEmiter.emit(elem);
                  } else {
                    this.modifyElementEmiter.emit(elem);
                  }
                }
              });
            }
            lastTime = new Date().getTime();
          }
        }, 5000);
      }
    } catch (e) {
      console.log(e);
    }
  }

  // Recupera una página de elementos
  async getElementsPag(pagina: number, from: Date): Promise<ElementoModel[]> {
    try {
      const ia = this.ssoService.getTicket().UrlApiIA && this.ssoService.getTicket().UrlApiIA.length > 0 ? '/ia' : '';
      // Cuando se pide información de IA reduzco el número de registros para no saturar el buffer del servidor http
      const regPag = ia.length > 0 ? this.REG_PAG / 4 : this.REG_PAG;
      const result = await this.http.get<ElementoModel[]>(this.ssoService.getTicket().UrlApi + '/api/elementos' + ia + '?empresaId=' +
        this.ssoService.getTicket().Empresa.IdGestion + "&pag=" + pagina + "&reg=" + regPag +
        "&from=" + DateUtils.formatDateTime(from, false)).toPromise();
      return result;
    } catch (e) {
      console.log(e);
    }
  }

  // Crea o modifica un elemento
  async saveElemento(elem: ElementoModel): Promise<ElementoModel> {
    let response: ElementoModel = new ElementoModel();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      const elemento = { ...elem }; // Para no modificar el elemento original
      const instalacion = { ...elem.Instalacion };
      let newReg = elemento.Id < 1;
      // Quito los campos agregados y los que provocan una referencia circular que da problemas
      // al serializar el elemento en JSON ya que contiene una referencia al propio elemento
      delete elemento.Marker;
      delete elemento.PU;
      delete elemento.Infogeo;
      delete elemento.Calle;
      delete elemento.CodigoPostal;
      delete elemento.ComunidadAutonoma;
      delete elemento.NumeroCalle;
      delete elemento.Municipio;
      delete elemento.Provincia;
      delete elemento.Instalacion;

      response = await this.http.post<ElementoModel>(this.ssoService.getTicket().UrlApi + '/api/elemento',
        JSON.stringify(elemento), httpOptions).toPromise();
      if (response) {
        // await this.puService.savePU(elem.PU);

        elemento.Instalacion = instalacion;
        let instal = await this.instalacionService.saveInstalacion(elem.Instalacion, response.Id);

        if (instal && instal.Detalle) {
          instal.Detalle.forEach(det => {
            elem.Instalacion.Detalle.find(elDet => elDet.idTipo = det.idTipo).id = det.id;
          });
        }

        if (elemento.Instalacion) {
          elemento.Instalacion.Detalle = await this.detalleInstalacionService.saveDetalleInstalacion(elem.Instalacion.Detalle, instal.id)
        }

        // Recupero los datos que rellena la API
        elemento.Id = response.Id;
        elemento.Marker = elem.Marker;
        elemento.Calle = response.Calle;
        elemento.CodigoPostal = response.CodigoPostal;
        elemento.ComunidadAutonoma = response.ComunidadAutonoma
        elemento.NumeroCalle = response.NumeroCalle;
        elemento.Municipio = response.Municipio;
        elemento.Provincia = response.Provincia;
        elemento.Instalacion = response.Instalacion;
        if (elem.PU) {
          elemento.PU = elem.PU;
        } else {
          elemento.IdPU = response.IdPU;
        }
        // Si se trata de un elemento nuevo lo añado a la lista de elementos
        if (newReg) {
          // Si no tiene nombre se lo asigno ahora
          if (response.Nombre === '' || response.Nombre.length < 1) {
            response.Nombre = elemento.Equipamiento.Elemento.Acronimo + ' ' + response.Id;
            elemento.Nombre = response.Nombre;
            await this.http.post<ElementoModel>(this.ssoService.getTicket().UrlApi + '/api/elemento',
              JSON.stringify(response), httpOptions).toPromise();
          }
          this.newElementEmiter.emit(elemento);
        } else { // Notifico que se ha modificado un elemento
          this.modifyElementEmiter.emit(elemento);
        }
      }
    } catch (e) {
    }
    if (elem.Id && response) {
      this.auditoria.AccionId = Accion.EDITAR_ELEMENTO;
    } else if (response && !elem.Id) {
      this.auditoria.AccionId = Accion.CREAR_ELEMENTO;
    }
    this.msgChangeResponse(response);
    this.auditoriaService.addAuditoria(this.auditoria);

    return response;
  }

  // Borra un elemento
  async deleteElemento(elemento: ElementoModel): Promise<boolean> {
    let response = true;
    try {
      response = await this.http.delete<boolean>(this.ssoService.getTicket().UrlApi +
        '/api/elemento?elementoId=' + elemento.Id).toPromise();
      if (response) {
        this.deleteElementEmiter.emit(elemento);
        this.auditoria.AccionId = Accion.ELIMINAR_ELEMENTO;
        this.auditoria.Info = 'ID:' + elemento.Id;
        this.auditoriaService.addAuditoria(this.auditoria)
      }
    } catch (e) {
      console.log(e);
    }

    return response;
  }

  // Recupero el filtro de modelos de elementos guardado
  async getFilterModel(): Promise<any[]> {
    let modelFilter: any = await this.configService.getUsuEmpApp('elem-model-filter', null);
    if (modelFilter) {
      modelFilter = JSON.parse(modelFilter);
    } else {
      modelFilter = JSON.parse("[]");
    }
    return modelFilter;
  }

  // Recupero el filtro de modelos de elementos guardado para IA
  async getFilterModelIA(): Promise<any[]> {
    let modelFilter: any = await this.configService.getEmp('elem-model-IA', null);
    if (modelFilter) {
      modelFilter = JSON.parse(modelFilter);
    } else {
      modelFilter = JSON.parse("[]");
    }
    return modelFilter;
  }

  // Aplico el filtro de modelos visibles en el mapa
  setFilterVisible() {
    this.changeFilterEmiter.emit();
  }

  msgChangeResponse(response: any): string {
    return this.auditoria.Info = 'ID: ' + response?.Id + ', ' + MainComponent.getInstance().translate('Nombre') + ': ' + response?.Nombre;
  }

  async uploadImage(imagen): Promise<ElemImageModel> {
    let response = null;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const img = { ...imagen };

    delete img.id;

    img.borrado = false;

    await this.http.post<ElemImageModel>(this.ssoService.getTicket().UrlApi + '/api/elementos/imagen',
      JSON.stringify(img), httpOptions).toPromise().then(
        res => {
          response = res;
        }
      );

    return response;
  }

  async getImagesElement(elemento): Promise<ElemImageModel[]> {
    let response: ElemImageModel[] = [];

    try {
      await this.http.get<ElemImageModel[]>(this.ssoService.getTicket().UrlApi + '/api/elementos/imagenes?idElemento=' + elemento.Id).toPromise().then(
        res => {
          response = res;
        }
      );
    } catch (e) {
      return [];
    }

    return response;
  }

  async removeImageElement(imagen): Promise<boolean> {
    let response = false;
    try {
      await this.http.delete<any>(this.ssoService.getTicket().UrlApi + '/api/elementos/imagen?idImagen=' + imagen.id).toPromise().then(
        res => {
          response = true;
        }, error => {
          response = false;
        }
      )
    } catch (e) {
      return false;
    }

    return response;
  }

  // sincronizar incidencias por elemento
  async sincronizarIncidenciasElemento(elementoId: number, date: Date): Promise<boolean> {
    let response = false;
    try {
      response = await this.http.get<boolean>(this.ssoService.getTicket().UrlApi + 'api/incidencias/elemento?idElemento=' + elementoId + 'empresa=' + this.empresaId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return response;
  }

  // metodo para saber si tiene resiudo pendiente
  async getResiduoPendiente(elementoId: number): Promise<any> {
    let response = false;
    try {
      response = await this.http.get<any>(this.ssoService.getTicket().UrlApi + '/api/residuo_registro_elemento_calculo/pendiente/elemento?id=' + elementoId + '&enterprise=' + this.empresaId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return response;
  }

  async moveElement(elementId: number, cerraduraId: number, toMove: string): Promise<any> {
    return true;
  }

  recordCountElementsTotal(count: number): void {
    this.recordCountTotal.set(count);
  }

  recordCountElementsUpdate(count: number): void {
    this.recordCountUpdate.set(count);
  }

}
