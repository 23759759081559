import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SsoService } from '../sso/sso.service';

import { PersonaModel } from './models/persona.model';

@Injectable({
  providedIn: 'root'
})
export class PersonasService {
  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(private http: HttpClient, private ssoService: SsoService) { }

  async getPersonas(): Promise<PersonaModel[]> {
    let response: PersonaModel[] = [];

    try {
      await this.http.get<PersonaModel[]>(
        `${this.urlApi}/api/personas?enteprise=${this.empresaId}&include=all`
      ).toPromise().then(
        res => {
          response = res;
        }
      )
    } catch (e) {
    }

    return response;
  }

  async savePersona(persona: PersonaModel): Promise<PersonaModel> {
    let response: PersonaModel = null;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    try {
      await this.http.post<PersonaModel>(
        `${this.urlApi}/api/persona`,
        JSON.stringify(persona),
        httpOptions
      ).toPromise();
    } catch (e) {
    }

    return response;
  }
}
