<jqxWindow #winGestionTiempoList jqxWindowAutoResize [zIndex]="900"
  [theme]="environment.tema" [isModal]="false" [showCloseButton]="true"
  [resizable]="true" [showCollapseButton]="true" [title]="translate('Gestion_tiempo')"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Gestion_tiempo") }}
  </div>

    <div class="form" style="float: left; overflow-x: hidden;">
        <jqxRibbon #ciudRibbon [theme]="environment.tema" [position]="'top'" [height]="'calc(100% - 30px)'">
            <ul style="width: 100%; text-align: left; z-index: 99; background-color: rgb(225, 229, 231);">
                <li>
                    {{ translate('General') }}
                </li>
                <li>
                    {{ translate('Calendario') }}
                </li>
                <li>
                    {{ translate('Turno') }}
                </li>
            </ul>

            <div>
                <div style="background-color: transparent; padding: 0%; overflow-y: hidden !important">
                  <div style="display: flex !important;flex-direction: row;justify-content: space-between;">
                    <fieldset>
                      <legend>{{ translate('Ejercicio_anual') }}</legend>

                      <jqxRadioButtonGroup #groupAnyo [change]="changeTipoEjercicio" [items]="itemsAnyo" layout="'vertical'" [value]="valueAnyo" [layout]='"horizontal"'>
                      </jqxRadioButtonGroup>

                      <div class="row" style="display: flex; flex-direction: row; margin-left: 2px">
                        <div style="float: left;  margin-right: 5px; width: 250px;">
                          {{translate('Desde')}}
                          <span style="width: 80%;">
                            <div style="margin-top: 5px; clear: both">
                              {{ translate("Dia") }}
                            </div>
                            <jqxInput [value]="ejercicioAnual.inicio.dia" (onChange)="changeDiaDesde($event)" [width]="'100%'"
                              [disabled]="valueAnyo === itemsAnyo[0]">
                            </jqxInput>
                          </span>
                          <span style="width: 80%;">
                            <div style="margin-top: 5px; clear: both">
                              {{ translate("Mes") }}
                            </div>
                            <jqxInput [value]="ejercicioAnual.inicio.mes" (onChange)="changeMesDesde($event)" [width]="'100%'"
                              [disabled]="valueAnyo === itemsAnyo[0]">
                            </jqxInput>
                          </span>
                        </div>

                        <div style="float: left;  margin-right: 5px; width: 250px;">
                          {{translate('Hasta')}}
                          <span style="width: 80%;">
                            <div style="margin-top: 5px; clear: both">
                              {{ translate("Dia") }}
                            </div>
                            <jqxInput [value]="ejercicioAnual.fin.dia" [width]="'100%'"
                              [disabled]="true">
                            </jqxInput>
                          </span>
                          <span style="width: 80%;">
                            <div style="margin-top: 5px; clear: both">
                              {{ translate("Mes") }}
                            </div>
                            <jqxInput [value]="ejercicioAnual.fin.mes" [width]="'100%'"
                              [disabled]="true">
                            </jqxInput>
                          </span>
                        </div>
                      </div>
                    </fieldset>

                    <fieldset>
                      <legend>{{ translate('Dia_trabajo') }}</legend>

                      <jqxRadioButtonGroup #groupDia [change]="changeTipoDia" [items]="itemsDia" layout="'vertical'" [value]="valueDia" [layout]='"horizontal"'>
                      </jqxRadioButtonGroup>

                      <div class="row" style="display: flex; flex-direction: row; margin-left: 2px;">
                        <div style="float: left;  margin-right: 5px; width: 250px;">
                          {{translate('Desde')}}
                          <span style="width: 80%;">
                            <div style="margin-top: 5px; clear: both">
                              {{ translate("Hora") }}
                            </div>
                            <jqxInput [value]="diaLaboral.inicio.hora" (onChange)="diaLaboral.inicio.hora = $event?.args?.value" [width]="'100%'"
                              [disabled]="valueDia === itemsDia[0]">
                            </jqxInput>
                          </span>
                          <span style="width: 80%;">
                            <div style="margin-top: 5px; clear: both">
                              {{ translate("Minutos") }}
                            </div>
                            <jqxInput [value]="diaLaboral.inicio.minutos" (onChange)="diaLaboral.inicio.minutos = $event?.args?.value" [width]="'100%'"
                              [disabled]="valueDia === itemsDia[0]">
                            </jqxInput>
                          </span>
                        </div>

                        <div style="float: left;  margin-right: 5px; width: 250px;">
                          {{translate('Hasta')}}
                          <span style="width: 80%;">
                            <div style="margin-top: 5px; clear: both">
                              {{ translate("Hora") }}
                            </div>
                            <jqxInput [value]="diaLaboral.fin.hora" (onChange)="diaLaboral.fin.hora = $event?.args?.value" [width]="'100%'"
                              [disabled]="valueDia === itemsDia[0]">
                            </jqxInput>
                          </span>
                          <span style="width: 80%;">
                            <div style="margin-top: 5px; clear: both">
                              {{ translate("Minutos") }}
                            </div>
                            <jqxInput [value]="diaLaboral.fin.minutos" (onChange)="diaLaboral.fin.minutos = $event?.args?.value" [width]="'100%'"
                              [disabled]="valueDia === itemsDia[0]">
                            </jqxInput>
                          </span>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>

                <div style="background-color: transparent; padding: 0%; overflow-y: hidden !important;">

                </div>

                <div style="background-color: transparent; padding: 0%; overflow-y: hidden !important;">

                </div>
            </div>
        </jqxRibbon>

        <jqxButton style="float: left; margin-left: 5px; margin-top: 5px;" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
          (onClick)="onGuardar()" [value]="translate('Guardar')">
        </jqxButton>

        <jqxButton style="float: left; margin-left: 5px; margin-top: 5px;" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
        [value]="translate('CANCELAR')">
        </jqxButton>
    </div>
</jqxWindow>
