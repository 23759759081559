import { MapBounds, MapLatLng } from "movisat-maps";

export class AmbitoActividadModel {
    empresaIdSSO: number;
    aplicacionId: number;
    idZona: number;
    marco: MapBounds;
    lado: number;
    zonas: number[];

    constructor(marco: MapBounds, zonas: number[]) {
        this.marco = marco;
        this.zonas = zonas;
    }
}