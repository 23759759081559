<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form jqxWindowAutoResize [theme]="environment.tema" [zIndex]="900" [autoOpen]="true"
  [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" [title]="translate('Listado_tarjetas')"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;"></div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <jqxRibbon #tarjRibbon [theme]="environment.tema" [position]="'top'" [height]="'calc(100% - 40px)'"
      (onSelect)="tabSelected($event)">
      <ul>
        <li>
          {{ translate('Tarjetas') }} ({{ recordTagetsCount() }})
        </li>
        <li>
          {{ translate('Rangos') }} ({{ recordRangesCount() }})
        </li>
      </ul>

      <div>
        <div style="float: left; height: '100%'; width: '100%'; overflow: hidden;">
          <div>
            <app-header #header [exportar]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
              [sourceZonas]="zonas" [seeDropModoTarj]="true" [seeZonas]="true"
              (eventChangeModoTarjeta)="onChangeModoTarjeta($event)" (eventChangeZona)="onChangeZona($event)"
              (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()" (eventExportar)="onExportar()"
              (eventBuscador)="onBuscar()">
            </app-header>
          </div>

          <div style="float:left; margin-top: 5px; width: 100%; height: calc(100% - 45px);">
            <jqxGrid #gridTarjetas [theme]="environment.tema" [width]="'100%'" [height]="'100%'"
              [source]="adapterTarjetas" [appCellClick] [columnsresize]="true" [localization]="langGrid"
              [filterable]="true" [columns]="columnsTarjetas" [showrowlines]="false" [showcolumnlines]="true"
              [pageable]="false" [groupsheaderheight]="25" [sortable]="true" [altrows]="true"
              [autoshowloadelement]="true" [autoloadstate]="true" [enablebrowserselection]="true" [rowsheight]="25"
              [groupable]="true" [columnsheight]="20" [groupsrenderer]="groupsrenderer" [editable]="false"
              [filter]="filter" [updatefilterconditions]="updatefilterconditions" (onFilter)="onFilter($event)">
            </jqxGrid>
          </div>
        </div>

        <div style="float: left; height: '100%'; width: '100%'; overflow: hidden;">
          <app-tab-rangos #tabRangos></app-tab-rangos>
        </div>
      </div>
    </jqxRibbon>

    <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
      [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
      [value]="translate('Salir')">
    </jqxButton>
  </div>
</jqxWindow>

<div #formRango></div>
<div #formAsignacion></div>