import { Component, OnInit, ViewChild } from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { CustomForms } from '../forms/custom-forms';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { KmlService } from 'src/app/services/kml/kml.service';
import { SsoService } from 'src/app/services/sso/sso.service';

import { KmlModel } from 'src/app/services/kml/models/kml.model';

import { MapComponent } from 'movisat-maps';

@Component({
  selector: 'app-kml',
  templateUrl: './kml.component.html',
  styleUrls: ['./kml.component.css']
})
export class KmlComponent extends CustomForms implements OnInit {
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('gridKml') gridKml: jqxGridComponent;
  @ViewChild('formEditKml') formEditKml: jqxWindowComponent;

  public static _this: KmlComponent;

  private map: MapComponent;
  public kmlSelec: KmlModel;
  public kmlEdit: KmlModel;
  public dataSource;
  public dataAdapter;
  public mostrarEditKml = false;
  private componentRef = null;
  public environment = environment;
  public canEdit = true;
  private kmls: KmlModel[] = [];

  // Preparo las columnas del grid
  public columns = [
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 250,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    { text: 'Url', columntype: 'textbox', filtertype: 'textbox', datafield: 'url', width: 400 },
    { text: AppComponent.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox', datafield: 'observ', width: 350 }
  ];

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(private ssoService: SsoService,
    private kmlService: KmlService,
    private modal: NzModalService) {
    super();
    KmlComponent._this = this;
  }

  ngOnInit(): void {
    this.canEdit = true; // TODO: por hacer...
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.map = MainComponent.getInstance().getActiveMap();
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Capas_kml'));
    await this.getKmls();
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  closeWindow(){
    this.form.close();
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    KmlComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Obtengo los KML de la empresa
  async getKmls() {
    this.kmls = await this.kmlService.getKmls();
    if (this.kmls) {
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'nombre', type: 'string', map: 'Nombre' },
          { name: 'url', type: 'string', map: 'Url' },
          { name: 'observ', type: 'string', map: 'Observaciones' }
        ],
        localdata: this.kmls,
        sortcolumn: 'nombre',
        sortdirection: 'asc'
      };
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);
      this.loader.close();
    } else {
      this.kmls = [];
    }
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'nombre',
      'url',
      'observ'
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  onRowSelect(event: any) {
    this.kmlSelec = this.kmls[event.args.rowindex];
  }

  onRowdoubleclick(event: any) {
    this.kmlSelec = this.kmls[event.args.rowindex];
    this.onEditarKml(null);
  }

  // Centrar
  onVer(event: any) {
    if (this.kmlSelec) {
      this.form.collapse();
      MainComponent.getInstance().centrarKml(this.kmlSelec.Id);
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onCrearKml(event: any) {
    if (!this.canEdit) {
      return;
    }
    this.kmlEdit = new KmlModel();
    this.kmlSelec = this.kmlEdit;
    this.onEditarKml(null);
  }

  onEditarKml(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.kmlSelec) {
      // Creo una copia para preservar el contenido original
      this.kmlEdit = Utils.clone(this.kmlSelec);
      this.openEditKmlForm();
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onOpenEditKml() {
    const t = setTimeout(async () => {
      clearTimeout(t);
      this.addCustomForm(this.formEditKml);
      this.formEditKml.setTitle(AppComponent.translate('Edicion_kml'));
    }, 0);
  }

  onExpandKml() {
  }

  onCloseEditKml() {
    this.mostrarEditKml = false;
    this.form.enable();
    this.form.expand();
  }

  // Muestra el formulario de edición
  openEditKmlForm() {
    this.mostrarEditKml = true;
    if (this.form) {
      this.form.collapse();
      this.form.disable();
    }
  }

  // Borrar un KML
  onBorrarKml(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.kmlSelec !== null) {
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quiere_borrar_kml') + ': ' + this.kmlSelec.Nombre + ' ?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzOnOk: async () => {
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
          await this.kmlService.deleteKml(this.kmlSelec.Id);
          for (let i = 0; i < this.kmls.length; i++) {
            if (this.kmls[i].Id === this.kmlSelec.Id) {
              this.kmls.splice(i, 1);
              break;
            }
          }
          this.kmlSelec = null;
          this.gridKml.updatebounddata('cells');

          this.resizeColumns(this.gridKml);

          // Actualizo los KML's en el mapa
          MainComponent.getInstance().loadKmls();
        }
      });
    } else {
      MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onGuardarKml(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.kmlEdit.Nombre === null || this.kmlEdit.Nombre.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return;
    }
    if (this.kmlEdit.Url === null || this.kmlEdit.Url.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_url', 2000);
      return;
    }
    this.saveKml();
  }

  // Guardo el KML
  async saveKml() {
    if (!this.canEdit) {
      return;
    }
    this.kmlEdit = this.kmlEdit;
    const response = await this.kmlService.saveKml(this.kmlEdit);
    if (response) {
      if (this.kmlEdit.Id === 0) {
        this.kmls.push(response);
      } else {
        for (let i = 0; i < this.kmls.length; i++) {
          if (this.kmls[i].Id === this.kmlEdit.Id) {
            this.kmls[i] = Utils.clone(response);
            break;
          }
        }
      }
      this.kmlEdit = Utils.clone(response);
      this.kmlSelec = Utils.clone(this.kmlEdit);
      this.gridKml.updatebounddata('cells');

      this.resizeColumns(this.gridKml);

      MainComponent.getInstance().showInfo('ATENCION', 'Registro_almacenado', 2000);
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
    }
    this.formEditKml.close();
    // Actualizo los KML's en el mapa
    MainComponent.getInstance().loadKmls();
  }

  resizeColumns(grid: jqxGridComponent){
    Utils.renderSizeGrid(grid, 500);
  }
}
