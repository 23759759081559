import { Component, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { GeoUtils } from 'src/app/utils/geo-utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';
import { MapBounds, MapCircle, MapComponent, MapLatLng, MapMarker, MapPolygon, MapPolygonPoint } from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { BDT_RECURSOS, BdtService } from 'src/app/services/bdt/bdt.service';

import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { BdtRecursoModel } from 'src/app/services/bdt/models/bdt-recurso.model';
import { MarcoGeograficoModel } from 'src/app/services/geographics/marco-geografico.model';
import { AmbitoActividadModel } from 'src/app/services/geographics/ambito-actividad.model';

import { EdificiosInstalacionesListComponent } from '../edificios-instalaciones-list/edificios-instalaciones-list.component';
import { EdificiosInstalacionesEntradasSalidasListComponent } from '../edificiosInstalacionesEntradasSalidas/edificios-instalaciones-entradas-salidas-list/edificios-instalaciones-entradas-salidas-list.component';
import { style } from '@angular/animations';

export enum TiposMaster {
  EDIFICIOS = 4,
}

@Component({
  selector: 'app-edificios-instalaciones-edit',
  templateUrl: './edificios-instalaciones-edit.component.html',
  styleUrls: ['./edificios-instalaciones-edit.component.css']
})

export class EdificiosInstalacionesEditComponent extends CustomForms implements OnInit {
  @ViewChild('winFormEdificios') winFormEdificios: jqxWindowComponent;

  @ViewChild('tabEdificiosEdit') tabEdificiosEdit: jqxTabsComponent;

  @ViewChild('cbTiposGeo') cbTiposGeo: jqxDropDownListComponent;
  @ViewChild('cbTiposZonas') cbTiposZonas: jqxDropDownListComponent;
  @ViewChild('cbTiposMaster') cbTiposMaster: jqxDropDownListComponent;

  public static _this: EdificiosInstalacionesEditComponent;

  public zoom = 6;
  public lang = 'es-ES';
  public cartoType = 'raster';
  public searchCountry = 'ES';
  public mapProvider = 'Google';
  public searchBounds: string = '';
  public marcoGeografico: MarcoGeograficoModel;
  public ambitoActividad: AmbitoActividadModel;

  protected showLoader = false;
  protected mapWidth: number = 1000;
  protected mapHeight: number = 450;

  private componentRef = null;
  protected environment = environment;
  protected langGrid = JqWidgets.getLocalization('es');

  modificandoPolygon = false;
  public idZonaEntrada: number = 0;

  superficie: any;
  public edificio: ZonaModel = new ZonaModel();

  mapZonaEdit: MapComponent;
  public circle: MapCircle = null;
  public polygon: MapPolygon = null;

  public subscriptionOnMapClick = null;
  public subscriptionOnCircleDragend = null;

  private subscriptionOnRadiusChange = null;
  private subscriptionOnPolygonDragEnd = null;

  polygonsMapEdit: any[] = [];
  circlesMapEdit: MapCircle[] = [];

  polygonPoints: MapLatLng[] = [];

  polygonsMap: any[] = [];
  circlesMap: MapCircle[] = [];

  public polygonGeoJson = null;
  public polygonGeoJsonMapEdit = null;

  onVerZonas: boolean = false;
  // private map: MapComponent;

  public markersZone: MapMarker[] = [];

  protected translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private ssoService: SsoService,
    private zonesService: ZonesService,
    private bdtService: BdtService,
  ) {
    super();
    EdificiosInstalacionesEditComponent._this = this;
  }

  init(componentRef: any, edificio: ZonaModel) {
    this.componentRef = componentRef;
    if (edificio) {
      this.edificio = edificio;
    }

    //this.openEditZonaForm();
    if (this.edificio.id !== 0) { //Editando zona
      this.openEditZonaForm();
      this.disabledBotton(true);
    } else { //Creando zona
      this.openEditZonaForm();
      this.disabledBotton(false);
      setTimeout(async () => {
        this.populateTiposZonasCombo();
        this.populateTiposGeoCombo();

        if (this.edificio.Id > 0) {
          this.cbTiposGeo.disabled(true);
        }
      }, 0);
    }
  }

  ngOnInit(): void {
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    //this.map = MainComponent.getInstance().getActiveMap();
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.winFormEdificios);

    // Me subscribo a los cambios de radio en los círculos
    if (this.subscriptionOnRadiusChange) {
      this.subscriptionOnRadiusChange.unsubscribe();
    }
    // this.subscriptionOnRadiusChange = this.map.subscribeOnCircleRadiusChange(this, this.onChangeRadiusOnMap);
    // // Me subscribo a los cambios en los polígonos
    // if (this.subscriptionOnPolygonDragEnd) {
    //   this.subscriptionOnPolygonDragEnd.unsubscribe();
    // }
    // this.subscriptionOnPolygonDragEnd = this.map.subscribeOnPolygonDragEnd(this, this.onChangePolygonOnMap);

    this.tabEdificiosEdit.setTitleAt(0, this.translate('Detalle'));
    this.tabEdificiosEdit.setTitleAt(1, this.translate('Entradas_salidas'));

    this.cbTiposMaster.addItem({ label: this.translate('Edificios_e_instalaciones'), value: TiposMaster.EDIFICIOS });
    this.cbTiposMaster.selectIndex(0);

    this.populateTiposZonasCombo();
    this.populateTiposGeoCombo();
  }

  /* CARGA INICIAL */
  // Muestra el formulario de edición de zonas
  openEditZonaForm() {
    this.polygonPoints = [];
    this.superficie = NumberUtils.format(this.edificio.Area, 0);
    // Si se está modificando una zona la pinto en el mapa
    setTimeout(() => {
      if (this.edificio.Id > 0) {
        this.winFormEdificios.setTitle(this.translate('Edicion_zonas_cartograficas') + ' ' + '<b>' + this.edificio.Nombre + '</b>');
        this.zonesService.hideZone(this.edificio.Id);

        //comprobar si es zona administrativa
        //this.drawZone();
        this.drawZoneMapEdit();

        this.edificio.Entradas.forEach(entrada => {
          EdificiosInstalacionesEntradasSalidasListComponent._this.paintMarkers(entrada, new MapLatLng(entrada.Lat, entrada.Lng), this.mapZonaEdit);
        });

      } else {
        this.winFormEdificios.setTitle(this.translate('Edicion_zonas_cartograficas'));
      }

      this.tabEdificiosEdit.setTitleAt(0, this.translate('Informacion'));
      this.tabEdificiosEdit.setTitleAt(1, this.translate('Entradas_salidas') + ' (' + this.edificio.Entradas.length + ')');
      const liElements = this.tabEdificiosEdit?.elementRef?.nativeElement?.querySelectorAll('ul > li');
      if (liElements) {
        liElements.forEach(li => {
          li.style.height = '15px';
        });
      }

      // Me subscribo a los cambios de radio en los círculos
      if (this.subscriptionOnRadiusChange) {
        this.subscriptionOnRadiusChange.unsubscribe();
      }
      // this.subscriptionOnRadiusChange = this.map.subscribeOnCircleRadiusChange(this, this.onChangeRadiusOnMap);
      // // Me subscribo a los cambios en los polígonos
      // if (this.subscriptionOnPolygonDragEnd) {
      //   this.subscriptionOnPolygonDragEnd.unsubscribe();
      // }
      // this.subscriptionOnPolygonDragEnd = this.map.subscribeOnPolygonDragEnd(this, this.onChangePolygonOnMap);
    }, 1000);
  }

  /* CARGA DATOS DESPLEGABLES */
  private tipos: BdtRecursoModel[] = [];

  async populateTiposZonasCombo() {
    this.tipos = await this.bdtService.getRecursos(BDT_RECURSOS.Edificios);
    this.tipos.sort((a, b) => a.Nombre.localeCompare(b.Nombre));

    this.cbTiposZonas.clear();
    this.tipos.forEach((tipo, i) => {
      this.cbTiposZonas.addItem({ label: tipo.Nombre, value: tipo.Id });
      if (this.edificio.Id > 0 && this.edificio.TipoBDT === tipo.Id) {
        this.cbTiposZonas.selectIndex(i);
      }
    });

    if (this.edificio.Id < 1) {
      this.cbTiposZonas.selectIndex(0);
    }
  }

  populateTiposGeoCombo() {
    this.cbTiposGeo.clear();
    this.cbTiposGeo.addItem({ label: this.translate('Circulo'), value: 0 });
    this.cbTiposGeo.addItem({ label: this.translate('Poligono'), value: 1 });
    this.cbTiposGeo.selectIndex(this.edificio.TipoGeo);
  }
  /* ------------------------------------------------------ */

  /* GUARDADO Y FUNCIONAMIENTO DE LA VENTANA */
  async onGuardarZona(): Promise<void> {
    if (this.edificio.Nombre === '') {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return;
    }

    if (this.edificio.TipoGeo === 0) {
      if (!this.circle) {
        MainComponent.getInstance().showError('ATENCION', 'Seleccione_punto_cartografia', 2000);
        return;
      }

      this.edificio.Lat = this.circle.center.lat;
      this.edificio.Lng = this.circle.center.lng;
      this.edificio.Puntos = null;
      this.edificio.Area = 0;

      if (this.edificio.Lat === 0 && this.edificio.Lng === 0) {
        MainComponent.getInstance().showError('ATENCION', 'Seleccione_punto_cartografia', 2000);
        return;
      }

      if (this.edificio.Radio < 1) {
        MainComponent.getInstance().showError('ATENCION', 'Introduzca_radio', 2000);
        return;
      }

      this.edificio.Geometria = null;
    } else {
      this.edificio.Radio = 0;
      this.edificio.Lat = 0;
      this.edificio.Lng = 0;
      if (!this.edificio.Geometria) {
        MainComponent.getInstance().showError('ATENCION', 'Defina_poligono', 2000);
        return;
      }
    }
    return await this.saveZona();
  }

  // Guardo la zona
  async saveZona() {
    const response = await this.zonesService.saveZona(this.edificio);

    if (response) {
      this.idZonaEntrada = response.Id;
      // Elimino los agregados por que dan problemas de referencia circular
      this.edificio = Utils.clone(response);

      EdificiosInstalacionesListComponent._this.updateGridEdificios(this.edificio);
      MainComponent.getInstance().showInfo('ATENCION', 'Registro_almacenado', 2000);

      setTimeout(() => {
        this.cbTiposGeo.disabled(true);
        this.drawZoneMapEdit();
      }, 50);
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
    }
  }

  async onExpand(event: any) {
    this.unsubscribeMapEvents();
    // actualizo el titulo del tab de entradas y salidas
    if (this.edificio.Id > 0) {
      this.edificio.Entradas = await this.zonesService.getEntradas(this.edificio.Id);
      this.disabledBotton(false);
      this.removeMarker(this.edificio.Id);
      this.edificio.Entradas.forEach(entrada => {
        EdificiosInstalacionesEntradasSalidasListComponent._this.paintMarkers(entrada, new MapLatLng(entrada.Lat, entrada.Lng), this.mapZonaEdit);
      });
    }

    if (this.edificio.Id < 1 && this.circle || this.polygon || this.polygonGeoJson && this.edificio.Nombre !== '') {
      this.disabledBotton(true);
    }

    setTimeout(() => {
      this.drawZoneMapEdit();
    }, 200);
  }

  onClose() {
    this.unsubscribeMapEvents();
    this.deleteZonesMap();
    this.removeMarker(this.edificio.Id);
    EdificiosInstalacionesListComponent._this.windowEdificios.expand();
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    EdificiosInstalacionesEditComponent._this = null;
  }

  async onCancelar() {
    // cierro el formulario de edición de zonas
    this.removeMarker(this.edificio.Id);
    this.winFormEdificios.close();
  }
  /* ------------------------------------------------------ */

  /* FUNCIONAMIENTO MAPA */
  onMapReady(map: MapComponent) {
    if (this.mapZonaEdit) {
      this.mapZonaEdit = null;
    }

    this.mapZonaEdit = map;
    this.marcoGeografico = MainComponent.getInstance().marcoGeografico;
    this.ambitoActividad = MainComponent.getInstance().ambitoActividad;
    if (!this.marcoGeografico.marco.contains(this.mapZonaEdit.center)) {
      this.mapZonaEdit.onBoundsChange(this.mapZonaEdit.getBounds());
    } else {
      this.minZoom = this.mapZonaEdit.zoom;
      try {
        setTimeout(() => {
          this.lastBounds = this.mapZonaEdit.getBounds();
        }, 500);
      } catch (error) {
      }
    }
  }

  private lastZoom;
  private lastBounds: MapBounds;
  private minZoom;

  onBoundsChange(bounds: MapBounds) {
    this.marcoGeografico = MainComponent.getInstance().marcoGeografico;
    this.ambitoActividad = MainComponent.getInstance().ambitoActividad;
    if (this.marcoGeografico) {
      if (!this.lastBounds) {
        this.mapZonaEdit.fitTo(this.marcoGeografico.marco);
        this.lastZoom = this.mapZonaEdit.zoom;
        this.minZoom = this.mapZonaEdit.zoom;
      } else {
        if (!this.lastBounds && (bounds.contains(this.marcoGeografico.marco.swCorner) ||
          bounds.contains(this.marcoGeografico.marco.neCorner))) {
          this.mapZonaEdit.fitTo(this.marcoGeografico.marco);
          this.minZoom = this.mapZonaEdit.zoom;
        } else {
          if (!this.marcoGeografico.marco.contains(this.mapZonaEdit.center)) {
            if (this.lastBounds.contains(this.marcoGeografico.marco.swCorner) &&
              this.lastBounds.contains(this.marcoGeografico.marco.neCorner)) {
              this.mapZonaEdit.setZoom(this.lastZoom + 1);
              this.mapZonaEdit.fitTo(this.marcoGeografico.marco);
            } else {
              this.mapZonaEdit.fitTo(this.lastBounds);
            }
            return;
          } else {
            if (bounds.contains(this.marcoGeografico.marco.swCorner) &&
              bounds.contains(this.marcoGeografico.marco.neCorner) && this.mapZonaEdit.zoom < this.lastZoom) {
              this.mapZonaEdit.setZoom(Math.max(this.lastZoom, this.minZoom));
              this.mapZonaEdit.fitTo(this.marcoGeografico.marco);
              const center = {
                lat: this.marcoGeografico.marco.swCorner.lat +
                  (this.marcoGeografico.marco.neCorner.lat - this.marcoGeografico.marco.swCorner.lat) / 2,
                lng: this.marcoGeografico.marco.swCorner.lng +
                  (this.marcoGeografico.marco.neCorner.lng - this.marcoGeografico.marco.swCorner.lng) / 2
              };
              return;
            }
          }
        }
      }
    }
  }

  // elimino marcador de la zona
  removeMarker(id: number) {
    // if (this.markersZone.length > 0) {
    //   this.markersZone.forEach(marker => {
    //     if (marker.dataModel.Id === id) {
    //       this.map.removeMarker(marker);
    //     }
    //   });
    // }

    if (EdificiosInstalacionesEntradasSalidasListComponent?._this?.markers?.length > 0) {
      EdificiosInstalacionesEntradasSalidasListComponent._this.markers.forEach(marker => {
        if (marker.dataModel.Id === id) {
          this.mapZonaEdit.removeMarker(marker);
        }
      });
    }
  }
  /* ------------------------------------------------------ */

  /* INTERACCIONES USUARIO */
  onKey(nombre: any) {
    if (nombre.target.value.length > 0) {
      this.disabledBotton(true);
    } else {
      this.disabledBotton(false);
    }
  }

  disabledBotton(value: boolean) {
    setTimeout(() => {
      this.activarDesactivarTab(value);
    }, 200);
  }

  activarDesactivarTab(value: boolean) {
    if (value) {
      this.tabEdificiosEdit.enableAt(1);
    } else {
      this.tabEdificiosEdit.disableAt(1);
    }
  }

  onTabclick(event: any) {
    if (event.args.item === 1 && this.edificio.Id > 0) {
      EdificiosInstalacionesEntradasSalidasListComponent._this.getEntradas(true);
    } else if (event.args.item === 1 && this.edificio.Id < 1) {
      EdificiosInstalacionesEntradasSalidasListComponent._this.getEntradas(false);
    } else {
      return;
    }
  }

  // Cuando se cambia el tipo de zona
  onChangeTipoZona(event: any) {
    this.edificio.TipoBDT = event.args.item.value;
  }

  // Cuando se cambia el tipo de geometría
  onChangeTipoGeo(event: any) {
    this.edificio.TipoGeo = event.args.item.value;
    if (this.edificio.Id < 1) {
      // if (this.circle) {
      //   this.map.removeCircle(this.circle);
      //   this.mapZonaEdit.removeCircles();
      //   this.circle = null;
      // }
      // if (this.polygon) {
      //   this.map.removePolygon(this.polygon);
      //   this.mapZonaEdit.removePolygons();
      //   this.polygon = null;
      // }
    }
  }

  async onCheckTextoChange(event: any) {
    // muestro el texto en el circulo o poligono si esta seleccionado el check
    this.edificio.TextoVisible = event.args.checked;

    if (this.edificio.TipoGeo === 0 && this.circlesMapEdit.length > 0) {
      this.circlesMapEdit.forEach(circle => {
        if (circle.dataModel.Id === this.edificio.Id) {
          circle.setContent(event.args.checked ? this.edificio.Nombre : '');
        }
      });
    } else if (this.edificio.TipoGeo === 1 && this.polygonsMapEdit.length > 0) {
      this.polygonsMapEdit.forEach(polygon => {
        if (this.edificio.Id) {
          if (polygon.dataModel) {
            const objeto = polygon.dataModel;
            Object.keys(objeto).forEach(key => {
              if ('Id' in objeto[key] && objeto[key].Id === this.edificio.Id) {
                polygon.setContent(event.args.checked ? this.edificio.Nombre : '');
              }
            });
          } else {
            const objeto = polygon.polygons[0];
            if (polygon.Id == this.edificio.Id) {
              objeto.setContent(event.args.checked ? polygon.Nombre : '');
            }
          }
        }
      });
    }
  }

  // Cuando se pulsa el botón para dibujar una zona nueva o para modificarla
  onDibujarZona(event: any) {
    this.edificio.Radio = Number.parseInt('' + this.edificio.Radio);
    if (this.edificio.TipoGeo === 0 && this.edificio.Radio < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_radio', 2000);
      return;
    }
    if (this.edificio.TipoGeo === 0) {
      MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_punto_cartografia', 2000);
    } else {
      MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_puntos_poligono_cartografia', 2000);
    }
    // this.subscriptionOnCircleDragend = this.map.subscribeOnCircleDragEnd(this, (_this: EdificiosInstalacionesEditComponent, circle: MapCircle) => {
    //   if (circle.id === this.circle.id) {
    //     this.edificio.Lat = circle.center.lat;
    //     this.edificio.Lng = circle.center.lng;
    //   }
    // });

    // Me subscribo al evento click sobre el mapa sólo si es una zona nueva
    if (this.edificio.Id < 1) {
      // Cambio el puntero del ratón sobre el mapa
      if (this.subscriptionOnMapClick) {
        this.subscriptionOnMapClick.unsubscribe();
      }
      //this.subscriptionOnMapClick = this.map.subscribeOnMapClick(this, this.onMapClick);
    } else {
      // Dibujo las zonas sobre el mapa
      this.zonesService.hideZone(this.edificio.Id);
      this.drawZone();
    }
    if (this.circle) {
      this.circle.setRadius(this.edificio.Radio);
    }
    this.winFormEdificios.collapse();
  }

  // Cuando se pincha sobre el mapa, este método sólo se llama cuando es una zona nueva
  // async onMapClick(_this: EdificiosInstalacionesEditComponent, position: MapLatLng) {
  //   // Calculo la posición que corresponde con el centro del icono que he puesto como puntero del ratón en ambos mapas
  //   const pointMap = _this.map.latLngToScreenPoint(position);
  //   const newPositionMap = _this.map.screenPointToLatLng(pointMap);

  //   const pointMapEditZone = _this.mapZonaEdit.latLngToScreenPoint(position);
  //   const newPositionMapEditZone = _this.mapZonaEdit.screenPointToLatLng(pointMapEditZone);

  //   switch (_this.edificio.TipoGeo) {
  //     case 0: // Círculo
  //       _this.edificio.Radio = Number.parseInt('' + _this.edificio.Radio);
  //       _this.edificio.Lat = newPositionMap.lat;
  //       _this.edificio.Lng = newPositionMap.lng;

  //       _this.deleteZonesMap();
  //       _this.deleteZonesMapEdit();

  //       _this.circle = _this.map.addCircle({
  //         id: 0,
  //         dataModel: _this.edificio,
  //         content: _this.edificio.Nombre,
  //         strokeColor: '#ff0000',
  //         strokeOpacity: 0.3,
  //         strokeWeight: 1,
  //         fillColor: '#0000ff',
  //         fillOpacity: 0.1,
  //         position: new MapLatLng(newPositionMap.lat, newPositionMap.lng),
  //         radius: _this.edificio.Radio,
  //         draggable: true,
  //         editable: true
  //       });

  //       _this.circlesMap.push(_this.circle);

  //       // Agregar círculo también en mapEditZone
  //       const circleEdit = _this.mapZonaEdit.addCircle({
  //         id: 0,
  //         dataModel: _this.edificio,
  //         content: _this.edificio.Nombre,
  //         strokeColor: '#ff0000',
  //         strokeOpacity: 0.3,
  //         strokeWeight: 1,
  //         fillColor: '#0000ff',
  //         fillOpacity: 0.1,
  //         position: new MapLatLng(newPositionMapEditZone.lat, newPositionMapEditZone.lng),
  //         radius: _this.edificio.Radio,
  //         draggable: false,
  //         editable: false
  //       });
  //       _this.circlesMapEdit.push(circleEdit);
  //       break;
  //     case 1: // Polígono
  //       if (!_this.polygon) {
  //         _this.polygon = _this.map.addPolygon({
  //           dataModel: _this.edificio,
  //           content: _this.edificio.Nombre,
  //           position: new MapLatLng(newPositionMap.lat, newPositionMap.lng),
  //           strokeColor: '#ff0000',
  //           strokeOpacity: 0.3,
  //           strokeWeight: 1,
  //           fillColor: '#0000ff',
  //           fillOpacity: 0.1,
  //           editable: true,
  //           zIndex: 100,
  //         });

  //         _this.polygonsMap.push(_this.polygon);
  //       }

  //       _this.map.addPolygonPoint(_this.polygon, {
  //         position: new MapLatLng(newPositionMap.lat, newPositionMap.lng)
  //       });

  //       _this.polygonPoints.push(new MapLatLng(newPositionMap.lat, newPositionMap.lng));
  //       _this.calcArea();
  //       _this.superficie = NumberUtils.format(_this.edificio.Area, 0);
  //       break;
  //   }
  // }
  /* ------------------------------------------------------ */

  /* DIBUJA/BORRA ELEMENTOS SOBRE EL MAPA Y CALCULOS */
  // Calcula el área del polígono
  drawZone() {
    // Pinto sobre el mapa la zona
    if (!this.onVerZonas) {
      this.deleteZonesMap();
    }

    if (this.edificio.TipoGeo === 0) { // Círculo
      if (this.edificio.Lat > 0) {
        // this.edificio.Geometria = {};
        // this.circle = this.map.addCircle({
        //   dataModel: this.edificio,
        //   content: this.edificio.Nombre,
        //   strokeColor: '#ff0000',
        //   strokeOpacity: 0.3,
        //   strokeWeight: 1,
        //   fillColor: '#0000ff',
        //   fillOpacity: 0.1,
        //   position: new MapLatLng(this.edificio.Lat, this.edificio.Lng),
        //   radius: Number(this.edificio.Radio),
        //   draggable: this.onVerZonas ? false : true,
        //   editable: this.onVerZonas ? false : true
        // });
        this.circlesMap.push(this.circle);

        // if (!this.onVerZonas) {
        //   this.map.setCenter(new MapLatLng(this.edificio.Lat, this.edificio.Lng));
        //   EdificiosInstalacionesListComponent._this.encuadrarEdificioSelec([this.edificio]);
        // }
      }
    } else { // Polígono
      this.superficie = NumberUtils.format(this.edificio.Area, 0);
      let editable = this.onVerZonas ? false : true;
      //cargar polígono en el mapa a partir de un geojson
      // this.polygon = this.map.addPolygonsFromGeoJson(this.edificio.Geometria, editable, {
      //   dataModel: this.edificio,
      //   content: this.edificio.Nombre,
      //   strokeColor: '#ff0000',
      //   strokeOpacity: 0.3,
      //   strokeWeight: 1,
      //   fillColor: '#0000ff',
      //   fillOpacity: 0.1,
      //   zIndex: 100,
      // })[0];
      if (!this.onVerZonas) {
        EdificiosInstalacionesListComponent._this.centeredPolygons(this.edificio.Geometria);
      }
      this.polygonsMap.push(this.polygon);
    }
    if (!this.onVerZonas) {
      this.activarDesactivarTab(true);
    }
  }

  drawZoneMapEdit() {
    // Pinto sobre el mapa la zona
    this.deleteZonesMapEdit();

    if (this.edificio.TipoGeo === 0) { // Círculo
      if (this.edificio.Lat > 0) {
        this.edificio.Geometria = {};
        let circle = this.mapZonaEdit.addCircle({
          dataModel: this.edificio,
          content: this.edificio ? this.edificio.Nombre : '',
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          position: new MapLatLng(this.edificio.Lat, this.edificio.Lng),
          radius: Number(this.edificio.Radio),
          draggable: false,
          editable: false
        });
        this.circlesMapEdit.push(circle);
        this.mapZonaEdit.setCenter(new MapLatLng(this.edificio.Lat, this.edificio.Lng));
        let zonaArray = [];
        zonaArray[0] = this.edificio;
        this.encuadrarZonasSelec(zonaArray, this.mapZonaEdit);
      }
    } else { // Polígono
      this.superficie = NumberUtils.format(this.edificio.Area, 0);
      //cargar polígono en el mapa a partir de un geojson
      let polygon
      if (this.edificio.Geometria && !this.modificandoPolygon) {
        polygon = this.mapZonaEdit.addPolygonsFromGeoJson(this.edificio.Geometria, false, {
          dataModel: this.edificio,
          content: this.edificio ? this.edificio.Nombre : '',
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          zIndex: 100,
        })[0];
        this.centeredPolygons(this.edificio.Geometria);
      } else {
        // pinto el polígono en el mapa
        polygon = this.mapZonaEdit.addPolygon({
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          content: this.edificio.Nombre,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          editable: false,
          zIndex: 100,
        });

        this.polygon.points.forEach(punto => {
          this.mapZonaEdit.addPolygonPoint(polygon, {
            content: this.edificio.Nombre,
            position: punto.point
          });
          this.polygonPoints.push(punto.point);
        });

        // centro el polígono en el mapa
        this.centerPolygon(this.polygonPoints);
        this.modificandoPolygon = false;
      }

      this.polygonsMapEdit.push(polygon);
    }
    if (!this.onVerZonas) {
      this.activarDesactivarTab(true);
    }
  }

  encuadrarZonasSelec(zonas: ZonaModel[], map?: MapComponent) {
    let globalSWPoint = new MapLatLng(180, 90);
    let globalNEPoint = new MapLatLng(-180, -90);
    // Procesa cada zona
    zonas.forEach(zona => {
      let swPoint, nePoint;
      if (zona.TipoGeo === 0) { // Si es un círculo
        let center = new MapLatLng(zona.Lat, zona.Lng);
        swPoint = GeoUtils.getNewLatLng(center, -zona.Radio, -zona.Radio);
        nePoint = GeoUtils.getNewLatLng(center, zona.Radio, zona.Radio);
      } else { // Si es un polígono
        let bounds = this.calculatePolygonBounds(zona.Geometria); // Devuelve un objeto con swPoint y nePoint
        swPoint = bounds.swPoint;
        nePoint = bounds.nePoint;
      }
      globalSWPoint.lat = Math.min(globalSWPoint.lat, swPoint.lat);
      globalSWPoint.lng = Math.min(globalSWPoint.lng, swPoint.lng);
      globalNEPoint.lat = Math.max(globalNEPoint.lat, nePoint.lat);
      globalNEPoint.lng = Math.max(globalNEPoint.lng, nePoint.lng);
    });
    map.fitTo(new MapBounds(globalSWPoint, globalNEPoint));
  }

  centerPolygon(polygon) {
    let arrLat = [];
    let arrLng = [];
    polygon.forEach(coord => {
      arrLat.push(coord.lat);
      arrLng.push(coord.lng);
    });
    let swPoint = new MapLatLng(Math.min(...arrLat), Math.min(...arrLng));
    let nePoint = new MapLatLng(Math.max(...arrLat), Math.max(...arrLng));
    let bounds = new MapBounds(swPoint, nePoint);
    this.mapZonaEdit.fitTo(bounds);
  }

  centeredPolygons(geoJson) {
    let arrLat = [];
    let arrLng = [];
    let arrayCoordenadas = geoJson.geometry.coordinates;
    arrayCoordenadas.forEach(polygon => {
      polygon[0].forEach(coord => {
        arrLat.push(coord[1]);
        arrLng.push(coord[0]);
      });
    });
    //calcular máximos y minimos para centar ppolígono en el mapa
    let arrLatMax = Math.max(...arrLat);
    let arrLatMin = Math.min(...arrLat);
    let arrLngmax = Math.max(...arrLng);
    let arrLngMin = Math.min(...arrLng);
    let coordMax = new MapLatLng(arrLatMax, arrLngmax);
    let coordMin = new MapLatLng(arrLatMin, arrLngMin);
    let mapBounds = new MapBounds(coordMin, coordMax);
    //funcion que centra el mapa a un area
    if (!this.onVerZonas) {
      this.mapZonaEdit.fitTo(mapBounds);
    }

    //this.map.fitTo(mapBounds);
  }

  calculatePolygonBounds(geoJson) {
    let arrLat = [];
    let arrLng = [];
    let arrayCoordenadas = geoJson.geometry.coordinates;
    arrayCoordenadas.forEach(polygon => {
      polygon[0].forEach(coord => {
        arrLat.push(coord[1]);
        arrLng.push(coord[0]);
      });
    });
    let swPoint = new MapLatLng(Math.min(...arrLat), Math.min(...arrLng));
    let nePoint = new MapLatLng(Math.max(...arrLat), Math.max(...arrLng));
    return { swPoint, nePoint };
  }

  calcArea() {
    this.edificio.Area = 0;
    for (let i = 0; i < this.polygon.points.length - 1; i++) {
      const p1 = this.polygon.points[i].point;
      const p2 = this.polygon.points[i + 1].point;
      this.edificio.Area += this.degToRad(p2.lng - p1.lng) *
        (2 + Math.sin(this.degToRad(p1.lat)) + Math.sin(this.degToRad(p2.lat)));
    }
    // Invento un último punto igual al primero
    const p1 = this.polygon.points[this.polygon.points.length - 1].point;
    const p2 = this.polygon.points[0].point;
    this.edificio.Area += this.degToRad(p2.lng - p1.lng) *
      (2 + Math.sin(this.degToRad(p1.lat)) + Math.sin(this.degToRad(p2.lat)));
    this.edificio.Area = Math.abs(this.edificio.Area * 6378137 * 6378137 / 2.0);
    this.edificio.Area = Number.parseInt(this.edificio.Area.toFixed(2));
  }

  // Pasa grados a radianes
  degToRad(deg: number): number {
    return deg * Math.PI / 180.0;
  }

  deleteZonesMap() {
    const removeMapElement = (element, removeFunction) => {
      if (element) {
        removeFunction(element);
      }
    };
  }

  deleteZonesMapEdit() {
    const removeMapElement = (element: any, removeFunction: (element: any) => void) => {
      if (element) {
        removeFunction(element);
      }
    };
    if (this.circlesMapEdit.length) {
      this.circlesMapEdit.forEach(circle => {
        removeMapElement(circle, (elem) => this.mapZonaEdit.removeCircle(elem));
        this.circlesMapEdit = this.circlesMapEdit.filter(c => c !== circle);
      });
    }

    if (this.polygonsMapEdit.length) {
      this.polygonsMapEdit.forEach(polygon => {
        removeMapElement(polygon, (elem) => this.mapZonaEdit.removePolygon(elem));
        this.polygonsMapEdit = this.polygonsMapEdit.filter(p => p !== polygon);
      });
    }

    removeMapElement(this.polygonGeoJsonMapEdit, (elem) => this.mapZonaEdit.removeGeoJson(elem));
  }

  // Cuando se modifica el radio del círculo sobre la cartografía
  onChangeRadiusOnMap(_this: EdificiosInstalacionesEditComponent, circle: MapCircle) {
    _this.edificio.Radio = circle.radius;
  }

  // Cuando se modifica el el polígono sobre la cartografía
  onChangePolygonOnMap(_this: EdificiosInstalacionesEditComponent, point: MapPolygonPoint) {
    _this.modificandoPolygon = true;
    _this.calcArea();
    _this.superficie = NumberUtils.format(_this.edificio.Area, 0);
  }

  unsubscribeMapEvents() {
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
      this.subscriptionOnMapClick = null;
    }

    if (this.subscriptionOnRadiusChange) {
      this.subscriptionOnRadiusChange.unsubscribe();
      this.subscriptionOnRadiusChange = null;
    }

    if (this.subscriptionOnPolygonDragEnd) {
      this.subscriptionOnPolygonDragEnd.unsubscribe();
      this.subscriptionOnPolygonDragEnd = null;
    }

    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
    }

    /* if (this.subscriptionOnMarkerClick) {
      this.subscriptionOnMarkerClick.unsubscribe();
    }

    EntradaSalidaComponent._this.unsubscribeMapEvents(); */
  }
  /* ------------------------------------------------------ */
}
