<header class="appbar backgroundBlue">
  <div style="float: left; height: 100%;">
    <img src="/assets/images/movisat.png" class="movisatLogo" style="float:left;" />
    <div class="vertical-line" style="margin-left: 10px;"></div>
    <img src="data:image/jpg;base64,{{ssoService.getTicket().Aplicacion.Imagen}}" class="aplicacionLogo"
      style="float: left;" />
  </div>
  <img src="data:image/jpg;base64,{{ssoService.getTicket().Empresa.Imagen}}" class="empresaLogo" />
  <div class="avatar">
    <div style="float: left;">
      <div class="empresaNombre">{{ssoService.getTicket().Empresa.Nombre}}</div><br>
      <div style="float: right;">{{ ssoService.getTicket().Rol.Nombre }}</div>
    </div>
    <div style="float: left;">
      <img id="popoverButton" src="/assets/images/usuario.png" class="avatarProfile" />
    </div>
    <jqxPopover [theme]="environment.tema" [selector]="'#popoverButton'" [arrowOffsetValue]="50"
      [offset]="{ left: -50, top: 0 }">
      <div style="font-size: 90%; font-family: Verdana;">
        <div><b>{{ ssoService.getTicket().Usuario.Nombre }}</b></div>
        <div>{{ ssoService.getTicket().Usuario.Email }}</div>
        <div style="float: right;">{{ ssoService.getTicket().Rol.Nombre }}</div><br>
        <hr />
        <div>
          <button class="enlace" role="link"
            (click)="ssoService.endAppSession()">{{translate('Cerrar_sesion')}}</button>
        </div><br>
      </div>
    </jqxPopover>
  </div>
</header>