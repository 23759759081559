import { ApplicationRef, createComponent, EmbeddedViewRef, Injectable, Type } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class JqxDomService {
  componentRef: any;

  constructor(
    private appRef: ApplicationRef,
  ) { }

  loadComponent(component: Type<any>, ownerElement: HTMLElement) {
    const componentRef = createComponent(component, {
      environmentInjector: this.appRef.injector,
      hostElement: ownerElement,
    });

    this.appRef.attachView(componentRef.hostView);

    const domElement = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;

    // Validar si el elemento ya está contenido
    if (!ownerElement.contains(domElement)) {
      ownerElement.appendChild(domElement);
    }

    this.componentRef = componentRef;

    return { componentRef, domElement };
  }


  destroy() {
    if (this.componentRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }


}
