import { Injectable } from '@angular/core';
import { ConfigService } from './config/config.service';

@Injectable({
  providedIn: 'root',
})
export class ClusterZoomConfigService {
  constructor(private configService: ConfigService) { }

  setEmpApp(key: string, value: string): void {
    this.configService.setEmpApp(key, value);
  }

  async getEmpApp(key: string): Promise<string | null> {
    return this.configService.getEmpApp(key, null);
  }
}
