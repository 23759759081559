import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { Component, ElementRef, OnInit, signal, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxSplitterComponent } from 'jqwidgets-ng/jqxsplitter';
import { SsoService } from 'src/app/services/sso/sso.service';
import { AlarmsService } from 'src/app/services/alarms/alarms.service';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { Accion } from 'src/app/services/auditoria/models/accion.model';
import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { SubflotaModel } from 'src/app/services/flota/models/subflota.model';
import { AuditoriaModel } from 'src/app/services/auditoria/models/auditoria.model';
import { ListadoAlarmasModel } from 'src/app/services/alarms/models/listado-alarmas.model';

import { PeriodoSelectComponent, PeriodoSelectTipo } from '../../../periodo-select/periodo-select.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { UserLangService } from 'src/app/services/common/user-lang/user-lang.service';
import { debounce } from 'lodash';
import { RendererUtils } from 'src/app/utils/RendererUtils';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-listado-alarmas',
  templateUrl: './listado-alarmas.component.html',
  styleUrls: ['./listado-alarmas.component.css']
})
export class ListadoAlarmasComponent extends CustomForms implements OnInit {

  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('periodoSelect') periodoSelect: PeriodoSelectComponent;
  @ViewChild('mainSplitter') mainSplitter: jqxSplitterComponent;
  @ViewChild('header') header: HeaderComponent;

  public static _this: ListadoAlarmasComponent;

  private componentRef = null;
  public environment = environment;

  // Variables para filtrado
  public fechaIni: Date;
  public fechaFin: Date;

  // Variables para generar el listado
  private informe: ListadoAlarmasModel[] = [];
  private movilesSelect: MovilModel[] = [];
  public movilesInforme: MovilModel[] = [];
  public subflotas: SubflotaModel[] = [];
  public dataSource: any = [];
  public dataAdapter = new jqx.dataAdapter(this.dataSource);
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);


  // Preparo las columnas del grid
  public columns: any[] = [];
  public columngroups = [];

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');
  countPartialResults = signal<number>(0);
  title = this.translate('Listado_alarmas_geo');
  showLoader = true;

  constructor(
    private ssoService: SsoService,
    private alarmsService: AlarmsService, private auditoriaService: AuditoriaService,
    private userLangService: UserLangService) {
    super();
    ListadoAlarmasComponent._this = this;
  }

  renderRow(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    switch (columnfield) {
      case 'horaEntrada':
      case 'horaSalida':
        if (value.getDate() !== new Date('0001-01-01T00:00:00').getDate()) {
          value = value ? DateUtils.formatTime(value) : '';
        } else {
          value = '';
        }
        return `<div style="margin-right: 4px; margin-top: 5px;  text-align: right;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;
      case 'entrada':
      case 'salida':
        value = value ? 'X' : '';
        return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;
      case 'motivo':
        return `<div style="margin-left: 4px; margin-top: 5px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${AppComponent.translate(value)}</div>`;
    }
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.userLangService.getUserLangShort());
  }

  ngAfterViewInit(): void {
    this.setupFormAndGrid();
    this.setupPeriodSelection();
    this.initListado();
  }

  private setupFormAndGrid(): void {
    this.addCustomForm(this.form);
    this.grid.localizestrings(this.langGrid);
  }

  private setupPeriodSelection(): void {
    this.header.periodoSelect.setPeriodo(PeriodoSelectTipo.DIA);
    this.setFechaRange();
  }

  private setFechaRange(): void {
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaFin = this.header.periodoSelect.getFechaFin();
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  closeWindow() {
    this.form.close();
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    ListadoAlarmasComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Metodo para cerrar el splitter cuando le damos clic al boton aceptar
  generateEventSplitter() {
    this.mainSplitter.collapse();
  }

  rendexTextGeneric = (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    switch (columnfield) {
      case 'fecha':
        let date = new Date(value);
        return '<div style="margin-left: 4px; margin-top: 5px;" onmouseover="this.style.backgroundColor=\'gray\'; this.style.color=\'white\'; this.style.position=\'fixed\';"'
          + ' onmouseout="this.style.backgroundColor=\'\'; this.style.color=\'\'; this.style.position=\'\';">' + DateUtils.formatDateTimeShort(date, true) + '</div>';
      default:
        return `
          <div style="margin-left: 4px; margin-top: 5px; text-align: left;"
            onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"
            onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">
              ${value}
          </div>`;
    }
  }

  cellClass = (row: number, columnfield: any, value: any): string =>
    value ? 'cellTooltip' : '';


  public columnmenuopening = (menu?: any, datafield?: string, height?: number | string): boolean | void => {
    if (this.isRelevantField(menu, datafield)) {
      const dateTimeInputs = this.getDateTimeInputs(menu[0]);
      this.initializeDateTimeInputs(dateTimeInputs);
    }
  };

  private isRelevantField(menu?: any, datafield?: string): boolean {
    return menu?.length === 1 && (datafield === 'horaEntrada' || datafield === 'horaSalida');
  }

  private getDateTimeInputs(container: HTMLElement): NodeListOf<HTMLElement> {
    return container.querySelectorAll('.jqx-datetimeinput');
  }

  private initializeDateTimeInputs(inputs: NodeListOf<HTMLElement>): void {
    if (inputs.length > 0) {
      inputs.forEach((input: HTMLElement) => this.initializeDateTimeInput(input));
    }
  }

  private initializeDateTimeInput(input: HTMLElement): void {
    const elementRef = new ElementRef(input);
    const jqxDateTimeInput = new jqxDateTimeInputComponent(elementRef);
    const inputDateTime = jqwidgets.createInstance(
      `#${jqxDateTimeInput.elementRef.nativeElement.id}`,
      'jqxDateTimeInput',
      { width: '100%', height: 25, formatString: 'HH:mm:ss' }
    );
    inputDateTime.setOptions({ showTimeButton: true, showCalendarButton: false });
  }

  initListado() {
    this.dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'movil', map: 'Movil>Nombre', type: 'string' },
        { name: 'movilMatricula', map: 'Movil>Matricula', type: 'string' },
        { name: 'motivo', map: 'MotivoAlarma>TraduccionId', type: 'string' },
        { name: 'fecha', type: 'date', map: 'Fecha' },
        { name: 'nombreAlarma', map: 'Alarma>Nombre', type: 'string' },
        { name: 'zona', type: 'string', map: 'Alarma>Zona>Nombre' },
        { name: 'radio', type: 'number', map: 'Alarma>Zona>Radio' },
        { name: 'area', type: 'number', map: 'Alarma>Zona>Area' },
        { name: 'dias', type: 'string', map: 'Alarma>Dias' },
        { name: 'entrada', type: 'string', map: 'Alarma>Entrada' },
        { name: 'horaEntrada', type: 'date', map: 'Alarma>HoraEntrada' },
        { name: 'salida', type: 'string', map: 'Alarma>Salida' },
        { name: 'horaSalida', type: 'date', map: 'Alarma>HoraSalida' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.informe
    };
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);

    this.columngroups = [
      { text: AppComponent.translate('Alarmas_geograficas'), align: 'center', name: 'alarmGroup' },
      { text: AppComponent.translate('Zona'), align: 'center', name: 'zoneGroup' }
    ];
    this.columns = [
      { text: AppComponent.translate('Fecha'), columntype: 'date', filtertype: 'date', datafield: 'fecha', cellsformat: 'dd/MM/yy HH:mm:ss', width: 145, cellclassname: this.cellClass },
      { text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'movil', width: 160, cellsrenderer: RendererUtils.rendexTextGeneric },
      { text: AppComponent.translate('Matricula'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'movilMatricula', width: 85, cellsrenderer: RendererUtils.rendexTextGeneric },
      { text: AppComponent.translate('Motivo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'motivo', width: 185, cellsrenderer: this.renderRow },
      { text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', width: 140, datafield: 'nombreAlarma', columngroup: 'alarmGroup', cellsrenderer: RendererUtils.rendexTextGeneric },
      { text: AppComponent.translate('Zona'), columntype: 'textbox', filtertype: 'textbox', datafield: 'zona', width: 140, columngroup: 'alarmGroup', cellsrenderer: RendererUtils.rendexTextGeneric },
      { text: AppComponent.translate('Radio'), columntype: 'textbox', filtertype: 'textbox', datafield: 'radio', width: 55, align: 'center', cellsalign: 'center', cellsrenderer: RendererUtils.numberrenderer, columngroup: 'alarmGroup' },
      { text: AppComponent.translate('Superficie'), columntype: 'textbox', filtertype: 'textbox', datafield: 'area', width: 80, align: 'center', cellsalign: 'center', cellsrenderer: RendererUtils.numberrenderer, columngroup: 'alarmGroup' },
      { text: AppComponent.translate('Dias'), columntype: 'textbox', filtertype: 'textbox', datafield: 'dias', width: 70, align: 'center', cellsalign: 'center', columngroup: 'alarmGroup', cellsrenderer: RendererUtils.rendexTextGeneric },
      { text: AppComponent.translate('E'), columntype: 'textbox', filtertype: 'textbox', datafield: 'entrada', width: 5, align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow, columngroup: 'alarmGroup', },
      { text: AppComponent.translate('Hora_entrada'), columntype: 'date', filtertype: 'date', datafield: 'horaEntrada', cellsformat: 'HH:mm:ss', width: 85, columngroup: 'alarmGroup', cellclassname: this.cellClass },
      { text: AppComponent.translate('S'), columntype: 'textbox', filtertype: 'textbox', datafield: 'salida', width: 5, align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow, columngroup: 'alarmGroup' },
      { text: AppComponent.translate('Hora_salida'), columntype: 'date', filtertype: 'date', datafield: 'horaSalida', cellsformat: 'HH:mm:ss', width: 85, columngroup: 'alarmGroup', cellclassname: this.cellClass, },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
    ];
    this.loadTooltips(this.columns);
    this.showLoader = false;
  }

  loadTooltips = (columns: any[]) => {
    columns.forEach(column => {
      column.rendered = (element) => {
        const tooltipMessage = column.text;
        Utils.tooltiprenderer(element, tooltipMessage);
      };
    });
  };

  // Genera el informe con los datos obtenidos
  generaInforme() {
    if (this.informe !== undefined && this.informe !== null) {
      this.dataSource.localdata = this.informe;
    }
  }

  onBuscar = debounce(() => {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.informe.forEach(inf => {
      if (
        inf?.Movil?.Nombre?.compareWith(filtervalue) ||
        inf?.Movil?.Matricula?.compareWith(filtervalue) ||
        inf?.MotivoAlarma?.Descripcion?.compareWith(filtervalue) ||
        inf?.Alarma?.Nombre?.compareWith(filtervalue) ||
        inf?.Alarma?.Zona?.Nombre?.compareWith(filtervalue) ||
        (inf?.Alarma?.Zona?.Radio + '').compareWith(filtervalue) ||
        (inf?.Alarma?.Zona?.Area + '').compareWith(filtervalue)
      ) {
        inf['selec'] = 'selec';
      } else {
        inf['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.grid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.grid.addfilter('selec', filtergroup);
    }
    this.grid.applyfilters();
    this.grid.updatebounddata('data');
    this.grid.sortby('fecha', 'desc');
    this.countPartialResults.set(this.grid.getrows().length);
  }, 150);

  async onAceptar(resetFilt = false) {
    this.header.searchInput['nativeElement'].value = '';
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaIni.setHours(0, 0, 0)
    this.fechaFin = this.header.periodoSelect.getFechaFin();
    this.fechaFin.setHours(23, 59, 59)
    const moviles: number[] = [];
    if (this.movilesSelect.length > 0 && !resetFilt) {
      this.movilesSelect.forEach(mov => {
        moviles.push(mov.Codigo);
      });
    } else if (!resetFilt) {
      this.mainSplitter.expand();
      MainComponent.showWarning('ATENCION', 'Seleccione_movil', 2000);
      return;
    } else {
      this.mainSplitter.expand();
      if (this.informe.length > 0) {
        this.informe = [];
        this.generaInforme();
        this.onBuscar();
      }
      return;
    }

    // Recupero el listado de alarmas
    this.informe = await this.alarmsService.getListadoAlarmas(moviles, this.movilesSelect, this.fechaIni, this.fechaFin);
    if (this.grid.getrows().length == 0) {
      this.grid.showemptyrow(true);
    }
    this.generaInforme();
    this.generateEventSplitter();
    this.onBuscar();
    //Utils.renderSizeGrid(this.grid);
    this.grid.sortby('fecha', 'desc');
    this.showLoader = false
  }

  onGridDataUpdate() {
    this.countPartialResults.set(this.grid.getrows().length);
  }

  onResetFilter() {
    this.header.periodoSelect.desde = new Date();
    this.header.periodoSelect.hasta = new Date();
    this.header.periodoSelect.resetForm();
    this.header.searchInput['nativeElement'].value = '';
    this.header.periodoSelect.cbPeriodo.selectedIndex(0);

    //actualiza grid
    this.onAceptar(true);
  }

  movilesSelected(mov: MovilModel[]) {
    this.movilesSelect = mov;
  }

  onExportar() {
    let data = this.grid.getrows();
    if (data.length === 0) {
      MainComponent.showWarning('ATENCIÓN', 'No_hay_datos', 2000);
      return;
    }

    const transformedData = data.map((row: any) => {
      const { uid, visibleindex, dataindex, selec, uniqueid, boundindex, ...filteredRow } = row;
      return {
        ...filteredRow,
        entrada: row.entrada ? 'X' : '',
        horaEntrada: row.horaEntrada ? DateUtils.formatTime(row.horaEntrada) : '',
        salida: row.salida ? 'X' : '',
        horaSalida: row.horaSalida ? DateUtils.formatTime(row.horaSalida) : '',
        motivo: AppComponent.translate(row.motivo)
      };
    });
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(transformedData);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(
      wb,
      DateUtils.formatDateAMDhms(new Date()) +
      '_' +
      AppComponent.translate('Listado_alarmas_geo') +
      '.xlsx'
    );

    // Registrar la auditoría
    this.auditoria.AccionId = Accion.EXPORTAR_INFORME_ALARMAS;
    this.auditoriaService.addAuditoria(this.auditoria);
  }
}
