<div class="loader-container" *ngIf="showLoader">
    <div class="loader"></div>
</div>
<jqxWindow #form jqxWindowAutoResize [theme]="theme" [zIndex]="900" [autoOpen]="true" [showCloseButton]="true"
    [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()" [title]="translate('Ciudadanos_validar')">
    <div class="formHeader" style="overflow-x: hidden;"></div>
    <div class="form" style="float:left; overflow-x: hidden;  ">
        <jqxTabs #tabs [theme]="theme" [width]="'100%'" [height]="'calc(100% - 30px)'" [animationType]="'none'">
            <ul>
                <li style="height: 15px !important;">{{titleTabGeneralSignal()}}</li>
            </ul>
            <div>
                <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="'calc(100% - 5px)'" [source]="dataAdapter"
                    [columnsresize]="true" [localization]="langGrid" [filterable]="true" [columns]="columns"
                    [filter]="filter" [showrowlines]="false" [appCellClick] [enablebrowserselection]="true"
                    [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true" [groupable]="true"
                    [groupsheaderheight]="25" (onBindingcomplete)="onGridDataUpdate()" (onFilter)="onGridDataUpdate()"
                    [groupsrenderer]="groupsrenderer" [editable]="true" [rowsheight]="25" [columnsheight]="25">
                </jqxGrid>
            </div>
        </jqxTabs>
        <jqxButton style="float: left; margin-right: 2px; margin-top: 4px;" [width]="100" [height]="26"
            [imgPosition]="'left'" [textImageRelation]="'imageBeforeText'" [textPosition]="'center'"
            [value]="translate('Guardar')" (onClick)="save()" [disabled]="isSaving">
        </jqxButton>
        <jqxButton style="float: left; margin-left: 1px; margin-top: 4px;" [width]="100" [height]="26"
            [imgPosition]="'left'" [textImageRelation]="'imageBeforeText'" [textPosition]="'center'"
            [value]="translate('Salir')" (onClick)="form.close()">
        </jqxButton>
    </div>
</jqxWindow>