<lib-loader [message]="translate('Cargando_datos')" [showLoader]="isLoading"></lib-loader>
<jqxWindow jqxWindowAutoResize #form [theme]="theme" [zIndex]="900" [autoOpen]="true" [showCloseButton]="true"
    [showCollapseButton]="true" [resizable]="true" [title]="titleWindow + ' (' + countPartialResults() + ')'"
    (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;"></div>
    <div class="form" style="float:left; overflow-x: hidden; ">

        <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" (eventFilter)="onFilter($event)"
            [filtro]="true" [resetFiltro]="true" (eventImprimir)="onPrint($event)" (eventExportar)="onExportar()"
            (eventBuscador)="onBuscar()" (eventResetFilter)="eventResetFilter()">
        </app-header>

        <div
            style="float: left; margin-top: 4px;width: 100%;height:calc(100% - 42px);display: flex;flex-direction: column;justify-content: space-between;">
            <div style="height: 100%;">
                <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="'99%'" [source]="dataAdapter" [appCellClick]
                    [columnsresize]="true" [localization]="langGrid" [filterable]="true" [selectionmode]="'singlecell'"
                    [columns]="columns" [showrowlines]="false" [showcolumnlines]="true" [sortable]="true"
                    [groupable]="true" [rowdetails]="true" [rowdetailstemplate]="rowdetailstemplateApp"
                    [groupsheaderheight]="25" (onBindingcomplete)="onGridDataUpdate()" (onFilter)="onGridDataUpdate()"
                    [initrowdetails]="initrowdetailsApp" [editable]="false" [altrows]="true" [rowsheight]="20"
                    [columnsheight]="20" [editable]="false" [enablebrowserselection]="true" [filter]="filter"
                    [updatefilterconditions]="updatefilterconditions">
                </jqxGrid>
            </div>
            <div>
                <jqxButton style="float: left; margin-left: 1px;" [width]="100" [height]="26" [imgPosition]="'left'"
                    [textImageRelation]="'imageBeforeText'" [textPosition]="'center'" [value]="translate('Salir')"
                    (onClick)="form.close()">
                </jqxButton>
            </div>
        </div>
    </div>
</jqxWindow>