
import { ConjuntoVehiculoCarrozado } from "./conjunto-vehiculo-carrozado.model";
import { ConjuntoVehiculoChasis } from "./conjunto-vehiculo-chasis.model";
import { ConjuntoVehiculoClase } from "./conjunto-vehiculo-clase.model";
import { ConjuntoVehiculoImagen } from "./conjunto-vehiculo-imagen.model";
import { ConjuntoVehiculoRecurso } from "./conjunto-vehiculo-recurso.model";
import { ConjuntoVehiculoVehiculo } from "./conjunto-vehiculo-vehiculo.model";

export class ConjuntoVehiculo {
  selec: string;

  id: number;
  codigo: string;
  nombre: string;
  carga_maxima_neta: number;
  ancho: number;
  alto: number;
  largo: number;
  volumen: number;
  kmco2: number;
  coste: number;
  anyo_vida: number;
  precio_compra: number;
  anyo_amortizacion: number;
  mantenimiento_anual: number;
  anyoMatriculacion: number;
  icono: string;

  recurso_id: number;
  chasis_id: number;
  clase_id: number;

  clase: ConjuntoVehiculoClase;
  carrozado: ConjuntoVehiculoCarrozado;

  recurso: ConjuntoVehiculoRecurso = new ConjuntoVehiculoRecurso;
  chasis: ConjuntoVehiculoChasis = new ConjuntoVehiculoChasis;
  carrozados: ConjuntoVehiculoCarrozado[] = [];
  clases: ConjuntoVehiculoClase[] = [];
  vehiculos: ConjuntoVehiculoVehiculo[] = [];
  imagenes: ConjuntoVehiculoImagen[] = [];

  claseString: string;
}
