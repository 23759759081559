<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>

<div #elementsContainer id="elemGrid" style="overflow: hidden; height: 100%;">
  <jqxGrid #gridElements [theme]="environment.tema" [height]="'100%'" [width]="'100%'" [source]="dataAdapter"
    [columnsresize]="true" [sortable]="true" [selectionmode]="'checkbox'" [showrowlines]="false" [groupable]="true"
    [rendertoolbar]="createToolBar" [showcolumnlines]="true" [showtoolbar]="true" [columns]="columns" [altrows]="true"
    [toolbarheight]="30" [filterable]="true" [showaggregates]="true" [groupsheaderheight]="20" [showstatusbar]="true"
    [statusbarheight]="20" [appCellClick] [enablebrowserselection]="true" [autoshowfiltericon]="true"
    [filterrowheight]="20" [rowsheight]="25" [columnsheight]="20" [localization]="langGrid"
    [groupsrenderer]="groupsrenderer" [filter]="filter" [updatefilterconditions]="updatefilterconditions"
    [columnmenuopening]="columnmenuopening" (onCellclick)="onCellClick($event)"
    (onBindingcomplete)="onBindingComplete()" (onFilter)="onChangeFilter($event)"
    (onRowdoubleclick)="onRowdoubleclick($event)">
  </jqxGrid>
</div>

<div #elementsEdit></div>

<nz-modal #modalBorrar
  [nzCentered]="true"
  [nzTitle]="modalTitle"
  [nzFooter]="modalFooter"
  [nzContent]="modalContent"
  [(nzVisible)]="modalVisible"
  (nzOnCancel)="closeModalDeleteElement()"
>
  <ng-template #modalTitle>
    <i>{{translate('Borrando')}} {{elementToDelete?.Nombre}}</i>
  </ng-template>

  <ng-template #modalContent>
    {{translate('Quieres_borrar')}}
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="default" class="ant-btn ant-btn-primary ant-btn-background-ghost ng-star-inserted" (click)="deleteElement()">{{translate('Elemento')}}</button>
    <button nz-button nzType="default" class="ant-btn ant-btn-primary ant-btn-background-ghost ng-star-inserted" (click)="deleteElementWithPU()">{{translate('Elemento_y_punto_ubicacion')}}</button>
    <button nz-button nzType="default" class="ant-btn ant-btn-primary ant-btn-dangerous ng-star-inserted" (click)="closeModalDeleteElement()">{{translate('CANCELAR')}}</button>
  </ng-template>
</nz-modal>
