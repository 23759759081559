import { CerraduraModel } from "../../cerraduras/models/cerradura.model";
import { ZonaModel } from "../../zones/models/zona.model";

export class RangoModel {
  id: number = 0;
  asociaciones: any[] = [];
  empresa: number = 0;
  nombre: string = '';
  idTarjetaInicial: number = 0;
  idTarjetaFinal: number = 1;
  listaBlanca: boolean = true;
  listaMaster: boolean = false;
  listaNegra: boolean = false;
  cantidadTarjetas: number = 0;
  cantidadZonas: number = 0;
  zonas: ZonaModel[] = [];
  zonasNombre: string = '';
  cerraduras: CerraduraModel[] = [];

  selec: string = '';
}
