<jqxDropDownButton #cbEquip style="float: left" [width]="'100%'" (onClose)="onCloseDrop($event)"
    (onOpen)="onOpenDrop($event)">
    <div [hidden]="gridDrop">
        <jqxGrid #myGrid [theme]="theme" [width]="'17%'" [height]="'15%'" [autoheight]="false" [source]="dataAdapter"
            [appCellClick] [columnsresize]="true" [selectionmode]="'singlerow'" [columns]="columns" [sortable]="true"
            [altrows]="true" [showrowlines]="true" [rowsheight]="20" [selectionmode]="'checkbox'" [columnsheight]="20"
            [enabletooltips]="true" [localization]="langGrid" (onRowclick)="onRowclick($event)"
            [enablebrowserselection]="true" (onRowselect)="onSelect($event)" (onRowunselect)="onUnSelect($event)"
            style="position: fixed;">
        </jqxGrid>
    </div>
</jqxDropDownButton>