<jqxWindow #form jqxWindowAutoResize [theme]="theme" [zIndex]="900" [autoOpen]="true" [showCloseButton]="false">
  <div hidden="true"></div>
  <div class="form">
    <div>
      <app-header #header [buscador]="true" [exportar]="true" [periodo]="true" [filtro]="true" [resetFiltro]="true"
        [seeCheckAlarms]="true" (eventExportar)="onExportar()" (eventFilter)="onAceptar()"
        (eventResetFilter)="onResetFilter()" (eventBuscador)="onBuscar()" (eventCheckAlarms)="onCheckAlarms($event)">
      </app-header>
    </div>
    <div style="width: 100% !important;height: calc(100% - 40px) !important; position: absolute; z-index: 999;">
      <jqxGrid #grid [theme]="theme" [width]="'100%'" [height]="'98%'" [source]="dataAdapter" [columnsresize]="true"
        [appCellClick] [sortable]="true" [showrowlines]="false" [showcolumnlines]="true" [columns]="columns"
        [columnmenuopening]='columnmenuopening' [groupsheaderheight]="25" [columnmenuopening]='columnmenuopening'
        [altrows]="true" [filterable]="true" [columnsautoresize]="true" [enablebrowserselection]="true"
        [localization]="langGrid" [groupable]="true" [showemptyrow]="false" (onGroupschanged)="resizeColumns()"
        [columnsheight]="20" [rowsheight]="20" [filter]="filter" [updatefilterconditions]="updatefilterconditions">
      </jqxGrid>
    </div>
  </div>
</jqxWindow>