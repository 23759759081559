<jqxWindow #windowCiudadanosForm [jqxWindowAutoResize] [width]="mapWidth" [height]="mapHeight - 24" [zIndex]="900"
  [theme]="environment.tema" [isModal]="false" [position]="getFormPos(windowCiudadanosForm, 24)"
  [showCloseButton]="true" [resizable]="true" [showCollapseButton]="true" (onExpand)="onExpand($event)"
  (onClose)="onClose()" [title]="translate('Ficha_ciudadano') + ': ' + ciudadano?.nombre + ' ' + ciudadano?.apellidos">

  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Ficha_ciudadano") }}
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <jqxRibbon #ciudRibbon [theme]="environment.tema" [position]="'top'" [height]="'calc(100% - 30px)'"
      (onSelect)="tabSelected($event)">
      <ul style="width: 100%; text-align: left; z-index: 99; background-color: rgb(225, 229, 231);">
        <li>
          {{ translate('Informacion') }}
        </li>
        <li>
          {{ translate('Direcciones') }}
        </li>
        <li>
          {{ translate('Tarjetas') }}
        </li>
      </ul>

      <div>
        <!-- PESTAÑA DEL FORMULARIO -->
        <div style="background-color: transparent; padding: 0%; overflow-y: auto;">
          <div style="display: inline-table !important; width: 100%; height: 100%;">
            <div #fieldsetPersonal id="fieldsetPersonal">
              <fieldset>
                <legend>{{ translate('Personal_contacto') }}</legend>

                <div class="row">
                  <span style="width: 20%;" *ngIf="camposCiudadanosVisible['Nombre']?.visible">
                    <div style="clear: both">
                      {{ translate('Nombre') }}
                      <span *ngIf="camposCiudadanosVisible['Nombre']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Nombre']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [ngModel]="ciudadano?.nombre" (ngModelChange)="ciudadano.nombre = $event"
                      [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 30%;" *ngIf="camposCiudadanosVisible['Apellidos']?.visible">
                    <div style="clear: both">
                      {{ translate('Apellidos') }}
                      <span *ngIf="camposCiudadanosVisible['Apellidos']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Apellidos']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [ngModel]="ciudadano?.apellidos" (ngModelChange)="ciudadano.apellidos = $event"
                      [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 20%;" *ngIf="camposCiudadanosVisible['Nif']?.visible">
                    <div style="clear: both">
                      {{ translate('Dni') }}
                      <span *ngIf="ciudadanoService?.getDniExists()" style="color: #d40c0c;">{{ translate('DNI_existe') }}</span>
                      <span *ngIf="camposCiudadanosVisible['Nif']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Nif']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [ngModel]="ciudadano?.nif" (ngModelChange)="ciudadano.nif = $event" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 15%;">
                    <div style="clear: both">
                      {{ translate('Estado') }}
                    </div>
                    <!-- <jqxInput *ngIf="ciudadano.id !== 0" [value]="ciudadano.validadoTexto" style="float: left"
                      [width]="'100%'" [disabled]="true">
                    </jqxInput> -->

                    <jqxDropDownList #dropValidado [source]="source" (onChange)="changeValidado($event)"
                      style="float: left" [width]="'100%'" [dropDownHeight]="30*source.length">
                    </jqxDropDownList>
                  </span>

                  <span *ngIf="ecoIdentificadores" style="width: 7%;">
                    <div style="clear: both">
                      {{ translate('Num_tarjetas') }}
                    </div>
                    <jqxInput [value]="ciudadano?.tarjetas ? ciudadano?.tarjetas.length : 0" [rtl]="true"
                      [width]="'60%'" disabled="true">
                    </jqxInput>
                  </span>

                  <span style="width: 20%;" *ngIf="camposCiudadanosVisible['Telefono']?.visible">
                    <div style="clear: both">
                      {{ translate('Telefono') }}
                      <span *ngIf="camposCiudadanosVisible['Telefono']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Telefono']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [ngModel]="ciudadano?.telefono" (ngModelChange)="ciudadano.telefono = $event" [rtl]="true"
                      [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 30%;" *ngIf="camposCiudadanosVisible['Correoelectronico']?.visible">
                    <div style="clear: both">
                      {{ translate('Correo_electronico') }}
                      <span *ngIf="ciudadanoEmailExist" style="color: #d40c0c;">{{translate('Correo_existe')}}</span>
                      <span *ngIf="camposCiudadanosVisible['Correoelectronico']?.obligatorio"
                        style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Correoelectronico']?.personal"
                        style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [ngModel]="ciudadano?.correoElectronico"
                      (ngModelChange)="ciudadano.correoElectronico = $event" [width]="'100%'"
                      (onChange)="checkCiudadano($event)">
                    </jqxInput>
                  </span>

                  <span *ngIf="ecoCompostaje" style="width: 25%;">
                    <div style="margin-top: 5px; clear: both">
                      Tipo
                    </div>
                    <jqxInput [ngModel]="ciudadano?.tipo" (ngModelChange)="ciudadano.tipo = $event" [width]="'100%'"
                      disabled="true">
                    </jqxInput>
                  </span>

                </div>

                <!-- <div class="row">

                </div> -->
              </fieldset>
            </div>

            <div #fieldsetDireccion id="fieldsetDireccion">
              <fieldset>
                <legend>{{ translate('Direccion_principal') }}</legend>

                <h3>{{ translate('Direccion_cartografica') }}</h3>

                <div class="row" *ngIf="camposCiudadanosVisible['Direccion']?.visible">
                  <span style="width: 25%;">
                    <div style="clear: both">
                      {{ translate('Buscar') }}
                    </div>
                    <span #mapSearch style="height: 25px;">
                      <movisat-map-search style="float: left; width: calc(100% - 30px); height: 25px;"
                        (mapSearch)="onMapSearch($event)">
                      </movisat-map-search>
                      <jqxButton class="button" style="float: left; margin-left: 5px; cursor: pointer;
                        display: flex; align-items: center; justify-content: center;" [width]="25" [height]="25"
                        [title]="translate('Selecciona_una_ubicacion')" (onClick)="onPosicionar()">
                        <img src="/assets/images/posicion.png" style="height: 20px; width: 20px;">
                      </jqxButton>
                    </span>
                  </span>

                  <span style="width: 28%;" *ngIf="camposCiudadanosVisible['Direccion']?.visible">
                    <div style="clear: both">
                      {{ translate('Domicilio') }}
                      <span *ngIf="camposCiudadanosVisible['Direccion']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Direccion']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [value]="ciudadano?.direccion" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span *ngIf="camposCiudadanosVisible['Numero']?.visible" style="width: 28%;">
                    <div style="clear: both">
                      {{ 'Número' }}
                      <span *ngIf="camposCiudadanosVisible['Numero']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Numero']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [value]="ciudadano?.numero" [width]="'100%'" [disabled]="true"></jqxInput>
                  </span>

                  <span *ngIf="camposCiudadanosVisible['Codigopostal']?.visible">
                    <div style="clear: both">
                      {{ translate('Codigo_postal') }}
                      <span *ngIf="camposCiudadanosVisible['Codigopostal']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Codigopostal']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [value]="ciudadano?.codigoPostal" [rtl]="true" [width]="'55%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 5%;" *ngIf="camposCiudadanosVisible['Planta']?.visible">
                    <div style="clear: both">
                      {{ translate('Planta') }}
                      <span *ngIf="camposCiudadanosVisible['Planta']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Planta']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [ngModel]="ciudadano?.planta" (ngModelChange)="ciudadano.planta = $event" [rtl]="true"
                      [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 5%;" *ngIf="camposCiudadanosVisible['Puerta']?.visible">
                    <div style="clear: both">
                      {{ translate('Puerta') }}
                      <span *ngIf="camposCiudadanosVisible['Puerta']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Puerta']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [ngModel]="ciudadano?.puerta" (ngModelChange)="ciudadano.puerta = $event" [rtl]="true"
                      [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 22%;" *ngIf="camposCiudadanosVisible['Poblacion']?.visible">
                    <div style="clear: both">
                      {{ translate('Poblacion') }}
                      <span *ngIf="camposCiudadanosVisible['Poblacion']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Poblacion']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [value]="ciudadano?.poblacion" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 25%;" *ngIf="camposCiudadanosVisible['Municipio']?.visible">
                    <div style="clear: both">
                      {{ translate('Municipio') }}
                      <span *ngIf="camposCiudadanosVisible['Municipio']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Municipio']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [value]="ciudadano?.municipio" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 25%;" *ngIf="camposCiudadanosVisible['Provincia']?.visible">
                    <div style="clear: both;">
                      {{ translate('Provincia') }}
                      <span *ngIf="camposCiudadanosVisible['Provincia']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Provincia']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [value]="ciudadano?.provincia" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 25%;" *ngIf="camposCiudadanosVisible['Comunidadautonoma']?.visible">
                    <div style="clear: both;">
                      {{ translate('Comunidad_autonoma') }}
                      <span *ngIf="camposCiudadanosVisible['Comunidadautonoma']?.obligatorio"
                        style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Comunidadautonoma']?.personal"
                        style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [value]="ciudadano?.comunidadAutonoma" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 8%;" *ngIf="camposCiudadanosVisible['Numeroabonado']?.visible">
                    <div style="clear: both;">
                      {{ translate('Num_abonado') }}
                      <span *ngIf="camposCiudadanosVisible['Numeroabonado']?.obligatorio" style="color: red;">(*)</span>
                      <span *ngIf="camposCiudadanosVisible['Numeroabonado']?.personal" style="color: blue;">(DP)</span>
                    </div>
                    <jqxInput [ngModel]="ciudadano?.numeroAbonado" (ngModelChange)="ciudadano.numeroAbonado = $event"
                      [width]="'100%'" [disabled]="false">
                    </jqxInput>
                  </span>
                </div>
                <!-- <div class="row">

                </div> -->
                <div *ngIf="camposCiudadanosVisible['Numerocastastro']?.visible">
                  <h3>{{ translate('Catastro') }}</h3>
                  <app-selector-catastro #selectorCatastro [ciudadano]="ciudadano"
                    [camposVisibles]="camposCiudadanosVisible"></app-selector-catastro>
                </div>
              </fieldset>
            </div>
          </div>
        </div>

        <!-- PESTAÑA DEL LISTADO DE DIRECCIONES -->
        <div style="background-color: transparent; padding: 0%; overflow-y: hidden !important;">
          <app-direccion-listado #ciudadanoDirecciones [ciudadano]="ciudadano"
            (outputDireccion)="asignarDireccion($event)"></app-direccion-listado>
        </div>

        <!-- PESTAÑA DE ASIGNACION DE TARJETAS -->
        <div style="background-color: transparent; padding: 0%; overflow-y: hidden !important;">
          <app-ciudadanos-tarjetas #ciudadanoTarjetas [ciudadano]="ciudadano"
            [inputDireccion]="direccion"></app-ciudadanos-tarjetas>
        </div>
      </div>
    </jqxRibbon>
    <jqxButton style="float: left; margin-left: 5px; margin-top: 5px;" [width]="100" [height]="26"
      [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
      (onClick)="onGuardar()" [value]="translate('Guardar')">
    </jqxButton>

    <jqxButton style="float: left; margin-left: 5px; margin-top: 5px;" [width]="100" [height]="26"
      [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
      [value]="translate('CANCELAR')">
    </jqxButton>
  </div>
</jqxWindow>

<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>

<div style="display: hidden;">
  <button id="btnExpandCiudadanoEdit{{ciudadano?.id}}" (click)="expandForm()"></button>
</div>
