<div class="row">
  <span style="width: 30%;">
    <div style="clear: both;">
      {{ translate('Direccion_catastro') }}
    </div>
    <span #mapSearch>
      <movisat-map-search style="float: left; width: 90%;" (mapSearch)="onMapSearch($event)">
      </movisat-map-search>
    </span>

    <jqxButton class="button" style="display: flex; margin: auto; cursor: pointer;"
      [hidden]="direccion?.catastro == null && ciudadano?.catastro == null" [height]="23"
      [title]="translate('Desvincular')" (onClick)="desvincularCatastro()">
      <i class="fa-solid fa-trash"></i>
    </jqxButton>
  </span>

  <span style="width: 30%;">
    <div style="clear: both;">
      {{ translate('Domicilio') }}
    </div>

    <jqxInput [value]="catastroText" [width]="'100%'" [disabled]="true">
    </jqxInput>
  </span>

  <span style="width: 20%;" *ngIf="camposVisibles['Numerocastastro']?.visible">
    <div style="clear: both;">
      {{ translate('Numero_de_catastro') }}
      <span *ngIf="camposVisibles['Numerocastastro']?.obligatorio" style="color: red;">(*)</span>
      <span *ngIf="camposVisibles['Numerocastastro']?.personal" style="color: blue;">(DP)</span>
    </div>

    <jqxInput *ngIf="ciudadano" [(ngModel)]="ciudadano.numeroCatastro" (onChange)="checkCatastro($event)"
      [width]="'100%'">
    </jqxInput>

    <jqxInput *ngIf="direccion" [(ngModel)]="direccion.referenciaCatastral" (onChange)="checkCatastro($event)"
      [width]="'100%'">
    </jqxInput>
  </span>
  <span style="width: 20%;">
    <div style="clear: both;">
      {{ translate('Titular_catastro') }}
    </div>
    <jqxInput *ngIf="ciudadano" [value]="ciudadano.catastro?.titular" (onChange)="changeTitular($event, ciudadano)"
      [width]="'100%'">
    </jqxInput>

    <jqxInput *ngIf="direccion" [value]="direccion.catastro?.titular" (onChange)="changeTitular($event, direccion)"
      [width]="'100%'">
    </jqxInput>
  </span>
</div>