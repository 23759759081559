import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SsoService } from '../sso/sso.service';
import { AuditoriaService } from '../auditoria/auditoria.service';

import { EjercicioAnualModel } from './models/ejercicio-anual.model';

@Injectable({
  providedIn: 'root'
})
export class EjercicioAnualService {
  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(private ssoService: SsoService, private http: HttpClient, private auditoriaService: AuditoriaService) { }

  async getEjercicioAnual(): Promise<EjercicioAnualModel> {
    let response: EjercicioAnualModel = null;

    try {
      response = await this.http.get<EjercicioAnualModel>(
        `${this.urlApi}/api/configuracion/ejercicio/anual?enterprise=${this.empresaId}`
      ).toPromise();
    } catch (error) {
    }

    return response;
  }

  async saveEjercicioAnual(ejercicioAnual: EjercicioAnualModel): Promise<EjercicioAnualModel> {
    let response: EjercicioAnualModel = null;
    ejercicioAnual.empresa = this.empresaId;
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'responseType': 'json'
        })
      }

      response = await this.http.post<EjercicioAnualModel>(
        `${this.urlApi}/api/configuracion/ejercicio/anual`,
        JSON.stringify(ejercicioAnual), httpOptions
      ).toPromise();
    } catch (error) {
    }

    return response;
  }
}
