import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClipboardService {
  private copyText: string = '';
  constructor() { }

  setCopyText(text: string) {
    this.copyText = text;
  }

  copyToClipboard(text: string) {
    navigator.clipboard.writeText(text).then(() => {
    }).catch(err => {
      console.error(err);
    });
  }
}
