import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { SsoService } from '../sso/sso.service';
import { AuditoriaService } from '../auditoria/auditoria.service';

import { DiaLaboralModel } from './models/dia-laboral.model';

@Injectable({
  providedIn: 'root'
})
export class DiaLaboralService {
  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(private ssoService: SsoService, private http: HttpClient, private auditoriaService: AuditoriaService) { }

  async getDiaLaboral(): Promise<DiaLaboralModel> {
    let response: DiaLaboralModel = null;

    try {
      response = await this.http.get<DiaLaboralModel>(
        `${this.urlApi}/api/configuracion/dia/laboral?enterprise=${this.empresaId}`
      ).toPromise();
    } catch (error) {
    }

    return response;
  }

  async saveDiaLaboral(diaLaboral: DiaLaboralModel): Promise<DiaLaboralModel> {
    let response: DiaLaboralModel = null;

    try {
      diaLaboral.empresa = this.empresaId;

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'responseType': 'json'
        })
      }

      response = await this.http.post<DiaLaboralModel>(
        `${this.urlApi}/api/configuracion/dia/laboral`,
        JSON.stringify(diaLaboral), httpOptions
      ).toPromise();
    } catch (error) {
    }

    return response;
  }
}
