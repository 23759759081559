import { ElemInstalacionDetalleEstadoModel } from "./elem-instalacion-detalle-estado.model";
import { ElemInstalacionDetalleImagenModel } from "./elem-instalacion-detalle-imagen.model";
import { ElemInstalacionDetalleNotifModel } from "./elem-instalacion-detalle-notif.model";

export class ElemInstalacionDetalleModel {
  empresa: number;
  estado: ElemInstalacionDetalleEstadoModel;
  estados: ElemInstalacionDetalleEstadoModel[] = [];
  fechaBaja: string;
  fechaCreacion: string;
  fechaModificacion: string;
  fechaUltimoEstadoPendiente: string;
  guid: string;
  guidInstalacion: string;
  id: number;
  idAccion: number; /* Instalacion = 1, Revision = 2 */
  idEstado: number; /* Pendiente = 4, SinLectura = 5, SinCobertura = 29, Instalado = 6 */
  idInstalacion: number;
  idTipo: number; /* Tag = 1, Volumetrico = 2, Cerradura = 3, Identificador = 4 */
  imagenes: ElemInstalacionDetalleImagenModel[] = [];
  notificaciones: ElemInstalacionDetalleNotifModel[] = [];
  observaciones: string;
  ultimoEstadoUsuarioId: number;

  constructor() { }
}
