import { Directive, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { Subscription } from 'rxjs';

const MINIMIZED_WINDOW_WIDTH = 150;
const WINDOW_HEADER_HEIGHT = 30;
const TABS_GAP = 5;
const HEIGHT_TABS = 24;

@Directive({
  selector: '[jqxWindowAutoResize]'
})
export class JqxWindowAutoResizeDirective implements OnInit, OnDestroy {
  @Input() jqxWindowAutoResize: any;
  private __isMaximized: boolean = true;
  private __containerObject = null;
  private __windowEventsSubscriptions: Subscription[];
  private __resizeObserver: ResizeObserver;

  private static __collapsedTabs: JqxWindowAutoResizeDirective[] = [];
  private static __windows: JqxWindowAutoResizeDirective[] = [];

  constructor(
    @Inject(jqxWindowComponent)
    private __window: jqxWindowComponent
  )
  { }

  ngOnInit() {
    this.__containerObject = document.getElementById('map-container');

    this.__addToWindows()

    this.__window.maxWidth(window.innerWidth);
    this.__window.maxHeight(window.innerHeight);
    this.__window.draggable(false);

    this.__registerWindowEvents();
    this.__registerResizeObserver();

    this.__expand();
  }

  ngOnDestroy() {
    this.__resizeObserver.disconnect;
    this.__windowEventsSubscriptions.forEach(s => s.unsubscribe());

    if (this.__window.collapsed()) {
      this.__removeFromCollapsedWindows();
      this.__updateCollapsedWindows();
    }

    this.__removeFromWindows();
  }

  private __registerWindowEvents(): void {
    this.__windowEventsSubscriptions = [
      this.__window.onResizing.subscribe(() => {
        this.__isMaximized = false;
      }),

      this.__window.onMoved.subscribe(() => {
        if (this.__isMaximized) {
          this.__expand();
        }
      }),

      this.__window.onCollapse.subscribe((...p) => {
        this.__collpase(JqxWindowAutoResizeDirective.__collapsedTabs.length);
        JqxWindowAutoResizeDirective.__setFocusOnPreviousWindow();

        this.__addAsCollapsedWindow();
      }),

      this.__window.onExpand.subscribe((...p) => {
        this.__expand()
        this.__removeFromCollapsedWindows();
        this.__updateCollapsedWindows();
      }),
    ]
  }

  private __registerResizeObserver(): void {
    this.__resizeObserver = new ResizeObserver(_ => {
      if (this.__isMaximized) {
        this.__expand();
      }
      this.__updateCollapsedWindows();
    });

    this.__resizeObserver.observe(this.__containerObject);
  }

  private __addAsCollapsedWindow(): void {
    JqxWindowAutoResizeDirective.__collapsedTabs.push(this);
  }

  private __removeFromCollapsedWindows(): void {
    JqxWindowAutoResizeDirective.__collapsedTabs = JqxWindowAutoResizeDirective.__collapsedTabs.filter(window => window !== this);
  }

  private __updateCollapsedWindows(): void {
    JqxWindowAutoResizeDirective.__collapsedTabs.forEach((window, idx) => window.__collpase(idx));
  }

  private __addToWindows(): void {
    JqxWindowAutoResizeDirective.__windows.push(this);
  }

  private __removeFromWindows(): void {
    JqxWindowAutoResizeDirective.__windows = JqxWindowAutoResizeDirective.__windows.filter(window => window !== this);
  }

  private __collpase(collapsedIndex) {
    const containerRect = this.__containerObject.getBoundingClientRect();
    const x = containerRect.left + (MINIMIZED_WINDOW_WIDTH + TABS_GAP) * collapsedIndex;
    const y = containerRect.bottom - WINDOW_HEADER_HEIGHT;

    this.__window.width(MINIMIZED_WINDOW_WIDTH);
    this.__setPosition(x, y);

    this.__window.resizable(false);
    this.__window.draggable(false);
  }

  private __expand() {
    const containerRect = this.__containerObject.getBoundingClientRect();
    const x = containerRect.left;
    const y = containerRect.top + HEIGHT_TABS;

    this.__setPosition(x, y);

    const width = containerRect.width;
    const height = containerRect.height - HEIGHT_TABS;

    this.__setSize(width, height);

    this.__window.resizable(true);
    this.__window.draggable(true);
    this.__isMaximized = true;
  }

  private __setSize(width: number, height: number): void {
    this.__window.width(width);
    this.__window.height(height);
  }

  private __setPosition(x: number, y: number): void {
    this.__window.position({ x, y });
  }

  private static __setFocusOnPreviousWindow(): void {
    JqxWindowAutoResizeDirective.__windows.find(w => !w.__window.collapsed())?.__window.focus();
  }
}
