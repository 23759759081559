import { ZonaModel } from "../../zones/models/zona.model";
import { CiudadanoModel } from "./ciudadano.model";
import { DireccionModel } from "./direccion.model";
import { RangoModel } from "./rango.model";

export class TarjetaModel {
  id: number;
  descripcion: string;
  empresa: number;
  idTarjeta: number;
  idZona: number;
  idInterno: number;
  tecnologia: number;
  nombreTecnologia: string;
  uuid: number;
  uuidHex: string;
  idUsuario: number;
  fechaCreacion: Date;
  fechaModificacion: Date;
  fechaBaja: Date;
  nsMovisat: string;
  master: boolean;
  Virtual: boolean;
  virtual: boolean;
  nombreCiudadano: string;
  idExterno: string;
  grabada: boolean;
  rango: RangoModel;

  ciudadano: CiudadanoModel;
  direccion: DireccionModel;
  zonas: ZonaModel[];
  consulta: boolean; // Campo usado para consultar el UUID de la tarjeta puesta en el lector

  idDireccion: number; // Campo usado para la asignacion/desasignacion de direcciones
}
