<div class="loader-container" *ngIf="!columns.length">
  <div class="loader"></div>
</div>
<jqxWindow #form jqxWindowAutoResize [theme]="environment.tema" [zIndex]="900" [title]="titleWindowSignal()"
  [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;"></div>

  <div class="form" style="float:left; overflow: hidden; height: 100%; width: 100%;">
    <div style="float: left; height: calc(100% - 40px); width: 100%;">
      <jqxSplitter #mainSplitter [panels]="[{ min: '15%', size: '18%' }]" [height]="'100%'" [width]="'100%'">
        <div>
          <div style="height: 100%;">
            <div style="height: 100%;">
              <app-moviles-select #movilesSelectComp [filterText]="movilFilter"
                (passArray)="movilesSelected($event)"></app-moviles-select>
            </div>
          </div>
        </div>
        <div>
          <div style="height: 100%;">
            <div style="display: flex; align-content: center; flex-wrap: wrap;">
              <app-header #header style="width: 100%;" [exportar]="true" [periodo]="true" [periodoAnterior]="true"
                [filtro]="true" [resetFiltro]="true" [buscador]="true" [noLibre]="true" (eventExportar)="onExportar()"
                (eventFilter)="onAceptar()" (eventResetFilter)="onResetFilter()" (eventBuscador)="onBuscar()">
              </app-header>
            </div>
            <div style="height: calc(100% - 40px)">
              <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapter"
                [appCellClick] [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true"
                [columns]="columns" [altrows]="true" [groupsheaderheight]="25" [filterable]="true"
                [columnmenuopening]='columnmenuopening' (onBindingcomplete)="onGridDataUpdate()"
                (onFilter)="onGridDataUpdate()" [enablebrowserselection]="true" [showstatusbar]="true"
                [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20" [columnsheight]="20"
                [localization]="langGrid" [columngroups]="columngroups" [groupable]="true"
                (onGroupschanged)="resizeColumns()">
              </jqxGrid>
            </div>
          </div>
        </div>
      </jqxSplitter>
    </div>
    <jqxButton style="float: left; margin-left: 5px; margin-top: 10px" [width]="100" [height]="26"
      [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
      [value]="translate('Salir')">
    </jqxButton>
  </div>
</jqxWindow>