import { Injectable } from '@angular/core';
import { SsoService } from '../../sso/sso.service';
import { TarjetaModel } from '../models/tarjeta.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuditoriaService } from '../../auditoria/auditoria.service';
import { AuditoriaModel } from '../../auditoria/models/auditoria.model';
import { Accion } from '../../auditoria/models/accion.model';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TarjetasService {
  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;
  private urlApi = this.ssoService.getTicket().UrlApi;
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);

  constructor(private ssoService: SsoService, private http: HttpClient, private auditoriaService: AuditoriaService) { }

  async getTarjetas(): Promise<TarjetaModel[]> {
    let result: TarjetaModel[] = [];

    try {
      result = await firstValueFrom(
        this.http.get<TarjetaModel[]>(
          `${this.urlApi}/api/tarjetas?enterprise=760&include=all`
        )
      );
    } catch (error) {
      console.error(error);
      result = [];
    }
    return result;
  }

  async getTarjetasWithCiudadanos(): Promise<TarjetaModel[]> {
    let result: TarjetaModel[] = [];

    try {
      result = await firstValueFrom(
        this.http.get<TarjetaModel[]>(
          `${this.urlApi}/api/tarjetas?enterprise=${this.empresaId}&include=all`
        )
      );
    } catch (error) {
      result = [];
    }

    return result;
  }

  async getTarjetasLibres(): Promise<TarjetaModel[]> {
    let result: TarjetaModel[] = [];

    try {
      await this.http.get<TarjetaModel[]>(this.urlApi + '/api/tarjetas/libres?enterprise=' +
        this.empresaId).toPromise().then(
          res => {
            result = res;
          }, error => {
            result = [];
          }
        );
    } catch (e) {
      result = [];
    }

    return result;
  }

  async getTarjetasSinRango(): Promise<TarjetaModel[]> {
    let result: TarjetaModel[] = [];

    try {
      await this.http.get<TarjetaModel[]>(`${this.urlApi}/api/tarjetas/sin/rango?enterprise=${this.empresaId}&include=all`)
        .toPromise().then(
          res => {
            result = res;
          }, err => {
            result = [];
          }
        )
    } catch (error) {
      result = [];
    }

    return result;
  }

  async associateTarjetasToCiudadano(tarjetas: any, idCiudadano: number): Promise<boolean> {
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let aso = {
      "empresa": this.empresaId,
      "tarjetas": tarjetas,
      "idCiudadano": idCiudadano,
      "fecha": new Date()
    };

    try {
      await this.http.post<any>(this.urlApi + '/api/tarjeta/asociar/ciudadano/list',
        JSON.stringify(aso), httpOptions).toPromise().then(
          res => {
            response = true;

            this.auditoria.AccionId = Accion.ASOCIAR_TARJETA_CIUDADANO;
            this.auditoria.Info = 'ID ciudadano: ' + idCiudadano + ', ID tarjetas: ' + tarjetas;
            this.auditoriaService.addAuditoria(this.auditoria);
          }, error => {
            response = false;
          }
        )
    } catch (e) {
      response = false;
    }

    return response;
  }

  async disassociateTarjetasFromCiudadano(tarjetas: any, idCiudadano: number): Promise<boolean> {
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let aso = {
      "empresa": this.empresaId,
      "tarjetas": tarjetas,
      "idCiudadano": idCiudadano,
      "fecha": new Date()
    };

    try {
      await this.http.post<any>(this.urlApi + '/api/tarjeta/desasociar/ciudadano/list',
        JSON.stringify(aso), httpOptions).toPromise().then(
          res => {
            response = true;

            this.auditoria.AccionId = Accion.DESASOCIAR_TARJETA_CIUDADANO;
            this.auditoria.Info = 'ID ciudadano: ' + idCiudadano + ', ID tarjetas: ' + tarjetas;
            this.auditoriaService.addAuditoria(this.auditoria);
          }, error => {
            response = false;
          }
        )
    } catch (e) {
      response = false;
    }

    return response;
  }

  async creaTarjeta(tarjeta: TarjetaModel): Promise<TarjetaModel> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      let response = await this.http.post<TarjetaModel>(this.urlApi + '/api/tarjeta', JSON.stringify(tarjeta), httpOptions).toPromise();

      this.auditoria.AccionId = Accion.CREAR_TARJETA;
      this.auditoria.Info = 'ID tarjeta: ' + tarjeta.id;
      this.auditoriaService.addAuditoria(this.auditoria);

      return response;
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async deleteTarjeta(tarjetaId: Number): Promise<Boolean> {
    let response: Boolean = false;

    try {
      await this.http.delete(
        this.urlApi + '/api/tarjeta?id=' + tarjetaId + '&enterprise=' + this.empresaId
      ).toPromise().then(
        res => {
          response = true;

          this.auditoria.AccionId = Accion.BORRAR_TARJETA;
          this.auditoria.Info = 'ID tarjeta: ' + tarjetaId;
          this.auditoriaService.addAuditoria(this.auditoria);
        }, error => {
          response = false;
        }
      );
    } catch (e) {
      response = false;
    }

    return response;
  }

  async consultarTarjetaAGrabar(): Promise<TarjetaModel> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      const tarjeta = new TarjetaModel();
      tarjeta.consulta = true;
      return await this.http.post<TarjetaModel>('http://localhost:8444', JSON.stringify(tarjeta), httpOptions).toPromise();
    } catch (e) {
      try {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
        const tarjeta = new TarjetaModel();
        tarjeta.consulta = true;
        return await this.http.post<TarjetaModel>('https://localhost:8443', JSON.stringify(tarjeta), httpOptions).toPromise();
      } catch (e) {
        console.log(e);
      }
    }
    return null;
  }

  async grabaTarjeta(tarjeta: TarjetaModel): Promise<TarjetaModel> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      tarjeta.empresa = this.empresaId;
      tarjeta.consulta = false;
      return await this.http.post<TarjetaModel>('http://localhost:8444', JSON.stringify(tarjeta), httpOptions).toPromise();
    } catch (e) {
      try {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
        tarjeta.empresa = this.empresaId;
        return await this.http.post<TarjetaModel>('https://localhost:8443', JSON.stringify(tarjeta), httpOptions).toPromise();
      } catch (e) {
        console.log(e);
      }
    }
    return null;
  }

  public async getUltimaTarjeta(zonaId: number): Promise<TarjetaModel> {
    try {
      return await this.http.get<TarjetaModel>(this.urlApi + '/api/tarjeta/ultima?zoneId=' + zonaId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return null;
  }

}
