<lib-loader [message]="translate('Cargando_datos')" [showLoader]="isLoading"></lib-loader>

<div>
  <app-header #header [buscador]="true" [filtro]="true" [resetFiltro]="true"
      (eventFilter)="eventFilter()" (eventResetFilter)="eventResetFilter()" (eventBuscador)="onBuscar()">
  </app-header>
</div>

<jqxGrid #gridVehiculos [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapter" [appCellClick]
  [columnsresize]="true" [localization]="langGrid" [filterable]="true" [showfilterrow]="false"
  [columns]="columns" [showrowlines]="false" [showcolumnlines]="true" [enablebrowserselection]="true" [pageable]="false"
  [sortable]="true" [altrows]="true" [rowsheight]="30" [filterrowheight]="25" [columnsheight]="25" [enabletooltips]="false"
  [editable]="false" [filtermode]="'default'" [updatefilterconditions]="utils.updatefilterconditions" (onFilter)="onFilter()">
</jqxGrid>
