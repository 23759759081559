<div #puContainer id="puGrid" style="overflow: hidden; height: 100%;">
  <jqxGrid #gridPU [theme]="environment.tema" [height]="'100%'" [width]="'100%'" [source]="dataAdapter" [appCellClick]
    [columnsresize]="true" [sortable]="true" [selectionmode]="'checkbox'" [groupable]="true" [showrowlines]="false"
    [rendertoolbar]="createToolBar" [showcolumnlines]="true" [showtoolbar]="true" [columns]="columns" [altrows]="true"
    [sortmode]="'many'" [enabletooltips]="false" [toolbarheight]="30" [groupsheaderheight]="20" [filterable]="true"
    [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20" [autoshowfiltericon]="true"
    [filterrowheight]="20" [rowsheight]="25" [columnsheight]="20" [enablebrowserselection]="true"
    [localization]="langGrid" [filter]="filter" [updatefilterconditions]="updatefilterconditions"
    (onCellclick)="onCellClick($event)" (onBindingcomplete)="onBindingComplete()" (onFilter)="onChangeFilter($event)"
    (onRowdoubleclick)="onRowdoubleclick($event)">
  </jqxGrid>
</div>

<ng-template #btnTemplate>
  <div class="btn-container" style="display: flex; justify-content: flex-start; gap: 2px; padding: 2px;">
    <button class="button" (click)="onEdit()" style="height: 23px; cursor: pointer;" title="Editar">
      <i class="fa-solid fa-pen-to-square"></i>
    </button>
    <button class="button" (click)="onPosicionar()" style="height: 23px; cursor: pointer;" title="Posicionar">
      <i class="fa-solid fa-location-dot"></i>
    </button>
    <button class="button" (click)="onDelete()" style="height: 23px; cursor: pointer;" title="Borrar">
      <i class="fa-solid fa-trash"></i>
    </button>
  </div>
</ng-template>
