import { Injectable } from '@angular/core';
import { SsoService } from '../sso/sso.service';
import { HttpClient } from '@angular/common/http';
import { ConjuntoVehiculo } from './models/conjunto-vehiculo.model';

@Injectable({
  providedIn: 'root'
})
export class ConjuntoVehiculoService {
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(private ssoService: SsoService, private http: HttpClient) { }

  async getConjuntosVehiculo(): Promise<ConjuntoVehiculo[]>{

    let response: ConjuntoVehiculo[] = [];

    try {
      await this.http.get<ConjuntoVehiculo[]>(this.urlApi + `/api/conjuntos/vehiculos?enterprise=`
      + this.ssoService.getTicket().Empresa.IdGestion + `&include=all`)
      .toPromise().then(
        res => {
          response = res;

          response.forEach(conjVeh => {
            if (conjVeh.clases && conjVeh.clases.length != 0) {
              conjVeh.claseString = conjVeh.clases[0].nombre;
            }else {
              conjVeh.claseString = '';
            }

            if (conjVeh.carrozados && conjVeh.carrozados.length != 0) {
              conjVeh.carrozado = conjVeh.carrozados[0];
            }else {
              conjVeh.carrozado = null;
            }

            if (conjVeh.vehiculos && conjVeh.vehiculos.length != 0) {
              conjVeh['visible'] = conjVeh.vehiculos[0].idLicencia;
            }
          });
        }, error => {
          response = [];
        }
      );
    } catch (e) {
      response = [];
    }

    return response;
  }
}
