import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { HeaderModule } from '../header/header.module';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModeloSelectModule } from '../modelo-select/modelo-select.module';

import { GestionTiempoListComponent } from './gestion-tiempo-list/gestion-tiempo-list.component';
import { GestionTiempoEditComponent } from './gestion-tiempo-edit/gestion-tiempo-edit.component';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';


@NgModule({
  declarations: [
    GestionTiempoListComponent,
    GestionTiempoEditComponent
  ],
  imports: [
    FormsModule,
    SharedModule,
    CommonModule,
    HeaderModule,
    BrowserModule,
    MatInputModule,
    ModeloSelectModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDatepickerModule,
  ]
})
export class GestionTiempoModule { }
