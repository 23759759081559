import { UserLangService } from './../../../../services/common/user-lang/user-lang.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { DateIdentificacionModel } from 'src/app/services/cerraduras/models/dateIdentificacion';
import { IdentificacionCerraduraModel } from 'src/app/services/cerraduras/models/identificacionCerradura';

import { ModeloSelectComponent } from 'src/app/components/modelo-select/modelo-select.component';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { Utils } from 'src/app/utils/utils';

import { PeriodoSelectComponent } from 'src/app/components/periodo-select/periodo-select.component';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { RendererUtils } from 'src/app/utils/RendererUtils';

@Component({
  selector: 'app-listado-identificacion',
  templateUrl: './listado-identificacion.component.html',
  styleUrls: ['./listado-identificacion.component.css']
})
export class ListadoIdentificacionComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('header') header: HeaderComponent;

  public static _this: ListadoIdentificacionComponent;

  cerradura: boolean;
  unSelect: boolean;
  elementosSelect: ElementoModel[] = [];
  identificacion: IdentificacionCerraduraModel = new IdentificacionCerraduraModel;
  identificaciones: IdentificacionCerraduraModel[] = [];
  dateFin = new Date();
  dateInicio = new Date();
  dateIdentificacion: DateIdentificacionModel = new DateIdentificacionModel();
  loader: boolean = true;
  public ocultar: boolean = true;
  private componentRef = null;
  public theme = environment.tema;
  public langGrid = JqWidgets.getLocalization('es');

  columns: any[] = [];
  showLoader: boolean = true;

  source = {
    datatype: 'json',
    dataFields: [
      { name: 'fecha', type: 'date', map: 'fecha' },
      { name: 'hora', type: 'date', map: 'fecha' },
      { name: this.translate('Ns_movisat'), type: 'string', map: 'nsCerradura' },
      { name: this.translate('Ns_tarjeta'), type: 'string', map: 'idTarjeta' },
      { name: this.translate('Ciudadano'), type: 'string', map: 'nombreCiudadano' },

      { name: this.translate('Aporte_residuo'), type: 'bool', map: 'aporte' },
      { name: this.translate('Apertura_tapa'), type: 'bool', map: 'aperturaTapa' },
      { name: this.translate('No_cerro_tapa'), type: 'bool', map: 'noCerroTapa' },

      { name: this.translate('Tipo_identificacion'), type: 'string', map: 'medio' },
      { name: this.translate('Tipo_permiso'), type: 'string', map: 'tarjetaMaster' },

      { name: this.translate('Nombre_elemento'), type: 'string', map: 'nombreElemento' },
      { name: this.translate('Marca'), type: 'string', map: 'marcaElemento' },
      { name: this.translate('Modelo'), type: 'string', map: 'modeloElemento' },
      { name: this.translate('Matricula'), type: 'string', map: 'matriculaElemento' },

      { name: this.translate('Direccion'), type: 'string', map: 'direccionElemento' },
      { name: this.translate('Municipio'), type: 'string', map: 'municipioElemento' },
      { name: this.translate('Poblacion'), type: 'string', map: 'poblacionElemento' },
      { name: 'selec', map: 'selec' }
    ],
    localdata: this.identificaciones || [],
  };

  dataAdapter = new jqx.dataAdapter(this.source);

  cellClass = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'cellTooltip';
    }
    return '';
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private userLangService: UserLangService,
    private serviceCerraduras: CerraduraService) {
    super();
    ListadoIdentificacionComponent._this = this;
  }

  // Inicializa el componente
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.userLangService.getUserLangShort());
    this.initGrid();
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form, false);
    setTimeout(() => {
      this.onAceptar();
    }, 500);

    if (this.grid.getrows().length == 0) {
      this.grid.showemptyrow(true);
    }
  }

  renderDate(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    return `<div style=" margin-top: 4px;  text-align: center;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${DateUtils.formatDateTime(value, true)}</span></div>`;
  }

  initGrid() {
    this.columns = [
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },
      { text: this.translate('Fecha'), columntype: 'datetimeinput', filtertype: 'date', datafield: 'fecha', width: 90, cellsformat: 'dd/MM/yyyy', cellclassname: this.cellClass },
      { text: this.translate('Hora'), columntype: 'datetimeinput', filtertype: 'date', datafield: 'hora', width: 70, cellsformat: 'HH:mm:ss', cellclassname: this.cellClass },
      { text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', width: 100, datafield: this.translate('Ns_movisat'), cellclassname: this.cellClass },
      { text: this.translate('Ns_tarjeta'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Ns_tarjeta'), width: 100, cellsrenderer: RendererUtils.numberrenderer, },
      { text: this.translate('Ciudadano'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Ciudadano'), width: 90, cellclassname: this.cellClass },
      {
        text: this.translate('Aporte_residuo'), columntype: 'checkbox', datafield: this.translate('Aporte_residuo'), cellsrenderer: this.renderCheck,
        width: 110,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('SI'),
          this.translate('NO'),
        ],
      },
      {
        text: this.translate('Apertura_tapa'), columntype: 'checkbox', datafield: this.translate('Apertura_tapa'), cellsrenderer: this.renderCheck,
        width: 100,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('SI'),
          this.translate('NO'),
        ],
      },
      {
        text: this.translate('No_cerro_tapa'), columntype: 'checkbox', datafield: this.translate('No_cerro_tapa'), cellsrenderer: this.renderCheck,
        width: 85,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('SI'),
          this.translate('NO'),
        ],
      },

      {
        text: this.translate('Tipo_identificacion'), columntype: 'textbox', datafield: this.translate('Tipo_identificacion'), cellsrenderer: this.renderPermiso, sortable: false, cellclassname: this.cellClass,
        filtertype: 'checkedlist', width: 110,
        filteritems: [
          this.translate('Tarjeta_nfc'),
          'Bluetooth',
        ],
      },
      {
        text: this.translate('Tipo_permiso'), columntype: 'textbox', datafield: this.translate('Tipo_permiso'), cellsrenderer: this.renderTarjeta,
        filtertype: 'checkedlist', width: 100,
        filteritems: [
          this.translate('Master'),
          this.translate('Blanca')
        ],
        cellclassname: this.cellClass
      },

      { text: this.translate('Nombre_elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Nombre_elemento'), width: 120, cellclassname: this.cellClass },
      { text: this.translate('Marca'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Marca'), width: 110, cellclassname: this.cellClass },
      { text: this.translate('Modelo'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Modelo'), width: 110, cellclassname: this.cellClass },
      { text: this.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Matricula'), width: 110, cellclassname: this.cellClass },
      { text: this.translate('Direccion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Direccion'), width: 100, cellclassname: this.cellClass },
      { text: this.translate('Municipio'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Municipio',), width: 100, cellclassname: this.cellClass },
      { text: this.translate('Poblacion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Poblacion'), width: 110, cellclassname: this.cellClass },
    ];

    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public columnmenuopening(menu?: any, datafield?: any, height?: number | string): boolean | void {
    if (menu.length === 1) {
      if (datafield === 'fecha' || datafield === 'hora') {
        const configureDateTimeInput = (menuElement: HTMLElement, options: { showTimeButton: boolean; showCalendarButton: boolean, formatString: string }) => {
          const dateTimeInputs = menuElement.querySelectorAll('.jqx-datetimeinput');
          if (dateTimeInputs && dateTimeInputs.length > 0) {
            dateTimeInputs.forEach((input: HTMLElement) => {
              const elementRef = new ElementRef(input);
              const jqxDateTimeInput = new jqxDateTimeInputComponent(elementRef);
              const inputDate = jqwidgets.createInstance('#' + jqxDateTimeInput.elementRef.nativeElement.id, 'jqxDateTimeInput', { width: '100%', height: 25 });
              inputDate.setOptions(options);
            });
          }
        };

        const divElement: HTMLElement = menu[0];
        if (datafield === 'fecha') {
          configureDateTimeInput(divElement, { showTimeButton: false, showCalendarButton: true, formatString: 'dd/MM/yyyy' });
        } else if (datafield === 'hora') {
          configureDateTimeInput(divElement, { showTimeButton: true, showCalendarButton: false, formatString: 'HH:mm:ss' });
        }
      }
    }
  }

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      AppComponent.translate('Ns_movisat'),
      AppComponent.translate('Ciudadano'),
      AppComponent.translate('Tipo_identificacion'),
      AppComponent.translate('Tipo_permiso'),
      AppComponent.translate('Nombre_elemento'),
      AppComponent.translate('Marca'),
      AppComponent.translate('Modelo'),
      AppComponent.translate('Matricula'),
      AppComponent.translate('Direccion'),
      AppComponent.translate('Municipio'),
      AppComponent.translate('Poblacion'),
      AppComponent.translate('Fecha'),
    ]
    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  // Renderiza los textos al agrupar por ciertos campos
  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    // El cierre del div se hace en el return del texto a mostrar
    let showText = `
      <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
        <b>`+ data?.groupcolumn?.datafield + `: </b>
      `;
    if (
      data?.groupcolumn?.datafield === AppComponent.translate('Aporte_residuo') ||
      data?.groupcolumn?.datafield === AppComponent.translate('Apertura_tapa') ||
      data?.groupcolumn?.datafield === AppComponent.translate('No_cerro_tapa')
    ) {
      if (group === true) {
        showText += AppComponent.translate('SI');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      } else if (group === false) {
        showText += AppComponent.translate('NO');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      }
      return showText + "</div>";
    } else if (data?.groupcolumn?.datafield === AppComponent.translate('Tipo_identificacion')) {
      if (group === 1) {
        showText += 'Bluetooth';
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      } else if (group === 0) {
        showText += AppComponent.translate('Tarjeta_nfc');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      }
      return showText + "</div>";
    } else if (data?.groupcolumn?.datafield === AppComponent.translate('Tipo_permiso')) {
      if (group) {
        showText += AppComponent.translate('Master');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      } else {
        showText += AppComponent.translate('Blanca');
        if (data?.subGroups?.length == 0) {
          showText += '(' + data?.subItems?.length + ')';
        } else {
          showText += '(' + data?.subGroups?.length + ')';
        }
      }
      return showText + "</div>";
    }
  }


  renderCiudadano(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      return '<div style="margin-left: 4px; margin-top: 4px">' + value + '</div>';
    }
  }

  // Cambia el color de los checkbox para que aparezcan como deshabilitados
  renderCheck(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        `
        <div style="position: absolute; top: 50%; left: 50%; margin-top: -9px; margin-left: -12px; overflow: visible; cursor: auto;"
        id="jqxWidgete18472e433a4" tabindex="0" class="jqx-widget jqx-widget-material jqx-checkbox jqx-checkbox-material" checked="true">
          <div class="jqx-checkbox-default jqx-checkbox-default-material jqx-fill-state-normal jqx-fill-state-normal-material jqx-rc-all jqx-rc-all-material ripple" style="filter: grayscale(1)">
            <div style="width: 16px; height: 16px;">
              <span style="width: 16px; height: 16px;" class="jqx-checkbox-check-checked jqx-checkbox-check-checked-material"></span>
            </div>
            <span style="height: 18px; width: 18px; top: -1px; left: -1px;"></span>
          </div>
          <div style="clear: both;"></div>
        </div>
      `)
    } else {
      return (
        `
        <div style="position: absolute; top: 50%; left: 50%; margin-top: -9px; margin-left: -12px; overflow: visible; cursor: auto;"
        id="jqxWidgetd0791853769a" tabindex="0" class="jqx-widget jqx-widget-material jqx-checkbox jqx-checkbox-material">
          <div class="jqx-checkbox-default jqx-checkbox-default-material jqx-fill-state-normal jqx-fill-state-normal-material jqx-rc-all jqx-rc-all-material ripple" style="filter: grayscale(1)">
            <div style="width: 16px; height: 16px;">
              <span style="width: 16px; height: 16px;"></span>
            </div>
            <span style="height: 18px; width: 18px; top: -1px; left: -1px;"></span>
          </div>
          <div style="clear: both;"></div>
        </div>
      `)
    }
  }

  renderRow(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      let date = new Date(value);
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px; text-align: left">' + DateUtils.formatDateTimeShort(date, true) + '</div>';
    }
  }

  renderPermiso(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value == 0) {
      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">  ${AppComponent.translate('Tarjeta_nfc')}</div>`;
    } else if (value == 1) {

      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';"> Bluetooth</div>`;
    }
  }

  renderTarjeta(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return `<div style="margin-top: 4px;  text-align: center"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';">  ${AppComponent.translate('Master')}</span></div>`;
    }
    else {
      return `<div style="margin-top: 4px;  text-align: center"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';">  ${AppComponent.translate('Blanca')}</span></div>`;
    }
  }

  selecElement(listElement: any) {
    this.elementosSelect = listElement;
    if (this.elementosSelect.length <= 0 && this.source) {
      this.identificaciones = [];
      this.source.localdata = this.identificaciones;
      this.grid.updatebounddata();
    }
  }

  onfilter(event) {
    // Itero a través de todos los filtros aplicados
    for (let filterObj of event.args.filters) {
      let filter1;
      let column = filterObj.datafield;
      let filter = filterObj.filter.getfilters();

      if (column === this.translate('Tipo_identificacion')) {   // 0 BT, 1 nfc,
        let filter1;
        filter.forEach(element => {
          let filtergroup = new jqx.filter();
          let filter_operator = element.operator;
          let filtercondition = element.condition;
          let filtervalue;

          if (element.value === this.translate('Tarjeta_nfc') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = 0;
          } else if (element.value === 'Bluetooth' && filtercondition !== 'NOT_EQUAL') {
            filtervalue = 1;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('numericfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
            this.grid.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', element.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
          } else {

          }
        });
      } else if (column === this.translate('Tipo_permiso')) {
        filter.forEach(element => {
          let filtergroup = new jqx.filter();
          let filter_operator = element.operator;
          let filtercondition = element.condition;
          let filtervalue;

          if (element.value === this.translate('Master') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = true;
          } else if (element.value === this.translate('Blanca') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = false;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
            this.grid.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', element.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
          }
        });
      } else if (
        column === AppComponent.translate('Aporte_residuo') ||
        column === AppComponent.translate('Apertura_tapa') ||
        column === AppComponent.translate('No_cerro_tapa')
      ) {
        filter.forEach(element => {
          let filtergroup = new jqx.filter();
          let filter_operator = element.operator;
          let filtercondition = element.condition;
          let filtervalue;

          if (element.value === this.translate('SI') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = true;
          } else if (element.value === this.translate('NO') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = false;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
            this.grid.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', element.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
          }
        });
      }
    }
  }

  // asigno las fechas del periodo seleccionado
  onAceptar() {
    this.dateInicio = this.header.periodoSelect.getFechaIni();
    this.dateFin = this.header.periodoSelect.getFechaFin();
    this.dateIdentificacion.Desde = this.dateInicio;
    this.dateIdentificacion.Hasta = this.dateFin;

    this.header.searchInput['nativeElement'].value = '';

    this.onSearch();

    this.grid.setOptions({
      rowsheight: 20,
      columnsheight: 20,
    });
  }

  // resetea los filtros
  onResetFilter() {
    this.header.periodoSelect.desde = new Date();
    this.header.periodoSelect.desde.setHours(0, 0, 0);
    this.header.periodoSelect.hasta = new Date();
    this.header.periodoSelect.hasta.setHours(23, 59, 59);
    PeriodoSelectComponent._this.dateForm.get('desde').setValue(new Date());
    PeriodoSelectComponent._this.dateForm.get('hasta').setValue(new Date());
    this.header.periodoSelect.cbPeriodo.selectedIndex(0);

    this.header.searchInput['nativeElement'].value = '';

    this.onAceptar();
  }

  // recojo las identificaciones por cerradura y por fechas
  async onSearch() {
    this.showLoader = true;
    this.identificaciones = await this.serviceCerraduras.getIndentificacionesDate(this.dateIdentificacion);
    this.source.localdata = this.identificaciones;
    this.grid.updatebounddata(this.identificaciones);

    let filtervalue = this.header.searchInput['nativeElement'].value != null ? this.header.searchInput['nativeElement'].value : '';

    if (filtervalue != '') {
      this.grid.columns()['records'].forEach(column => {
        if (column.filtertype === 'textbox') {
          let filtergroup = new jqx.filter();
          filtergroup.operator = 'or'
          let filter_or_operator = 1;
          let filtercondition = 'contains';
          let filter1 = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);

          filtergroup.addfilter(filter_or_operator, filter1);
          this.grid.addfilter(column.datafield, filtergroup);
          this.grid.applyfilters();
        }
      });
    }
    this.grid.sortby(this.translate('Fecha'), 'desc');
    this.showLoader = false;
  }

  // resizeColumns(timeout = 500) {
  //   Utils.renderSizeGrid(
  //     this.grid,
  //     timeout,
  //     [AppComponent.translate('Aporte_residuo'), AppComponent.translate('Apertura_tapa'), AppComponent.translate('No_cerro_tapa')]
  //   );
  // }


  onBuscar() {
    const filtro = this.obtenerValorFiltro();
    if (filtro) {
      this.aplicarFiltroIdentificaciones(filtro);
    } else {
      this.grid.clearfilters();
      return
    }
    this.actualizarGrid();
  }

  obtenerValorFiltro() {
    let filtervalue = '';
    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      return filtervalue = this.header.searchInput['nativeElement'].value.normaliceAccents().toUpperCase();
    } else {
      return
    }
  }

  aplicarFiltroIdentificaciones(filtro) {
    this.identificaciones.forEach(identificacion => {
      const coincide = this.verificarCoincidencia(identificacion, filtro);
      identificacion['selec'] = coincide ? 'selec' : '';
    });
  }

  limpiarFiltrosIdentificaciones() {
    this.identificaciones.forEach(identificacion => {
      identificacion['selec'] = '';
    });
  }

  verificarCoincidencia(identificacion, filtro) {
    const camposParaComparar = [
      'nsCerradura', 'nombreCiudadano', 'nombreElemento', 'marcaElemento',
      'modeloElemento', 'matriculaElemento', 'observacionesElemento',
      'direccionElemento', 'municipioElemento', 'poblacionElemento'
    ];

    for (const campo of camposParaComparar) {
      if (identificacion[campo]?.normaliceAccents().toUpperCase().includes(filtro)) {
        return true;
      }
    }

    if (identificacion['idTarjeta']?.toString().includes(filtro)) {
      return true;
    }

    const medio = identificacion.medio === 0 ? AppComponent.translate('Tarjeta_nfc') : 'Bluetooth';
    if (medio.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(filtro)) {
      return true;
    }

    const tarjetaMaster = identificacion.tarjetaMaster ? AppComponent.translate('Master') : AppComponent.translate('Blanca');
    if (tarjetaMaster.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(filtro)) {
      return true;
    }

    return false;
  }

  actualizarGrid() {
    const filters = this.grid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.grid.addfilter('selec', filtergroup);
    }

    setTimeout(() => {
      this.grid.applyfilters();
      this.grid.updatebounddata('data');
    }, 0)
  }


  onExportar() {
    const json = JSON.parse(JSON.stringify(this.grid.getdisplayrows()));
    /*
      Elimino los campos que no se quieren mostrar en el excel y
      cambio el texto de los campos que vienen como booleano o numero
    */
    json.forEach(element => {
      delete element.uid;
      delete element.uniqueid;
      delete element.visibleindex;
      delete element.boundindex;

      if (element[this.translate('Fecha')]) {
        element[this.translate('Fecha')] = DateUtils.formatDateTimeShort(new Date(element[this.translate('Fecha')]), true)
      }

      if (element[this.translate('Aporte_residuo')]) {
        element[this.translate('Aporte_residuo')] = this.translate('SI');
      } else {
        element[this.translate('Aporte_residuo')] = this.translate('NO');
      }

      if (element[this.translate('Apertura_tapa')]) {
        element[this.translate('Apertura_tapa')] = this.translate('SI');
      } else {
        element[this.translate('Apertura_tapa')] = this.translate('NO');
      }

      if (element[this.translate('No_cerro_tapa')]) {
        element[this.translate('No_cerro_tapa')] = this.translate('SI');
      } else {
        element[this.translate('No_cerro_tapa')] = this.translate('NO');
      }

      if (element[this.translate('Tipo_identificacion')] == 0) {
        element[this.translate('Tipo_identificacion')] = this.translate('Tarjeta_nfc');
      } else if (element[this.translate('Tipo_identificacion')] == 1) {
        element[this.translate('Tipo_identificacion')] = 'Bluetooth';
      }

      if (element[this.translate('Tipo_permiso')]) {
        element[this.translate('Tipo_permiso')] = this.translate('Master');
      } else {
        element[this.translate('Tipo_permiso')] = this.translate('Blanca');
      }
    });
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Historicos_identificaciones') + '.xlsx');
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    ListadoIdentificacionComponent._this = null;
  }

  onCollapse(event) {
    ModeloSelectComponent._this.onCollapse();
  }

  onExpand(event) {
    ModeloSelectComponent._this.onExpand();
  }
}
