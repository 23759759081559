<div class="loader-container" *ngIf="showLoader">
  <div class="loader"></div>
</div>
<jqxWindow #form jqxWindowAutoResize [theme]="theme" [zIndex]="1000" [autoOpen]="true" [showCloseButton]="false">
  <div hidden="true"></div>
  <div class="form">
    <div>
      <app-header #header [exportar]="true" [periodo]="true" [buscador]="true" [filtro]="true" [resetFiltro]="true"
        [noLibre]="true" (eventExportar)="onExportar()" (eventFilter)="onAceptar()" (eventResetFilter)="onResetFilter()"
        (eventBuscador)="onBuscar()">
      </app-header>
    </div>
    <div style="width: 100% !important;height: calc(100% - 40px) !important; position: absolute; z-index: 999;">
      <jqxGrid #grid [theme]="theme" [width]="'100%'" [height]="'99%'" [source]="dataAdapter" [columnsresize]="true"
        [appCellClick] [sortable]="true" [showrowlines]="false" [showcolumnlines]="true" [columns]="columns"
        [altrows]="true" [enablebrowserselection]="true" [filterable]="true" [rowsheight]="20" [columnsheight]="20"
        [localization]="langGrid" [groupable]="true" [groupsrenderer]="groupsrenderer" [filter]="filter"
        [groupsheaderheight]="25" [columnmenuopening]='columnmenuopening'
        [updatefilterconditions]="updatefilterconditions" (onFilter)="onfilter($event)">
      </jqxGrid>
    </div>
  </div>
</jqxWindow>