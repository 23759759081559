import { Component, computed, OnInit, signal, ViewChild, ViewContainerRef } from '@angular/core';

import * as xlsx from 'xlsx';
import { MapLatLng } from 'movisat-maps';
import { Utils } from 'src/app/utils/utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ElementsService } from 'src/app/services/elements/elements.service';
import { IncidencesService } from 'src/app/services/incidences/incidences.service';

import { UsuarioModel } from 'src/app/services/sso/models/usuario.model';
import { TipoModel } from 'src/app/services/incidences/models/tipo.model';
import { EstadoModel } from 'src/app/services/incidences/models/estado.model';
import { ModeloModel } from 'src/app/services/incidences/models/modelo.model';
import { MotivoModel } from 'src/app/services/incidences/models/motivo.model';
import { IncidenciaModel } from 'src/app/services/incidences/models/incidencia.model';

import { EditStateComponent } from '../edit-state/edit-state.component';
import { NewIncidenceComponent } from '../new-incidence/new-incidence.component';
import { StatusHistoryComponent } from '../status-history/status-history.component';
import { PeriodoSelectComponent, PeriodoSelectTipo } from '../../periodo-select/periodo-select.component';
import { HeaderComponent } from '../../header/header.component';
import { BehaviorSubject } from 'rxjs';
import { UserLangService } from 'src/app/services/common/user-lang/user-lang.service';

@Component({
  selector: 'app-grid-incidences',
  templateUrl: './grid-incidences.component.html',
  styleUrls: ['./grid-incidences.component.css']
})
export class GridIncidencesComponent extends CustomForms implements OnInit {
  @ViewChild('windowIncidences') windowIncidences: jqxWindowComponent;
  @ViewChild('incidencesGrid') incidencesGrid: jqxGridComponent;
  @ViewChild('loader') loaderComponent: jqxLoaderComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('gridIncidencesContainer', { read: ViewContainerRef }) gridIncidencesContainer;

  public static _this: GridIncidencesComponent;
  showLoader = false;
  private componentRef = null;
  public environment = environment;
  private static instance: GridIncidencesComponent;
  private searchText = '';
  private static toolbarContainer = null;
  private static searchControl = null;
  //variables públicas del grid
  columns: any[] = [];
  dataSource: any;
  dataAdapter: any;
  //modelos
  tipos: TipoModel[] = [];
  modelos: ModeloModel[] = [];
  motivos: MotivoModel[] = [];
  estados: EstadoModel[] = [];
  incidences: IncidenciaModel[] = [];
  //variables
  fechaIni: Date = new Date();
  fechaFin: Date = new Date();
  mostrarEdit = false;
  selectedEstados = [];
  selectedIncidence;
  searchControl;
  map;
  markerIncidence: any;
  sourceEstados: { datatype: string; datafields: { name: string; }[]; id: string; localdata: EstadoModel[]; };
  adapterEstados: any;
  users: UsuarioModel[];
  isCheckedAll: boolean = false;
  actualizarEstados: boolean;
  isOpen: boolean;
  isPartialSelection: boolean;
  binarySelection;
  private countIncidencesSubject = new BehaviorSubject<number>(0);
  countIncidences$ = this.countIncidencesSubject.asObservable();
  component: any = null;
  countPartialResults = signal<number>(0);
  titleWindow = this.translate('Incidencias');

  titleWindowSignal = computed(() => `${this.titleWindow} (${this.countPartialResults()})`);

  constructor(private incidencesService: IncidencesService,
    private ssoService: SsoService,
    private elementsService: ElementsService,
    private modal: NzModalService,
    private userLangService: UserLangService) {
    super();
    GridIncidencesComponent.instance = this;
    GridIncidencesComponent._this = this;
  }

  public static getInstance(): GridIncidencesComponent {
    return GridIncidencesComponent.instance;
  }

  ngOnInit() {
    //recupera el mapa principal
    this.map = MainComponent.getInstance().getMap();
    this.initGrid();
  }

  resizeColumns(grid: jqxGridComponent) {
    Utils.renderSizeGrid(grid, 500, ['acciones']);
  }

  // textos del grid en el idioma correspondiente
  langGrid = JqWidgets.getLocalization(this.userLangService.getUserLangShort());

  async getData() {
    //traer usuarios
    this.users = await this.ssoService.getUsuariosByEmpresaId();

    //añadir opción de todos
    let estadoAll = new EstadoModel();
    estadoAll.Nombre = 'Todos';
    estadoAll.Id = -1;
    this.estados.push(estadoAll);

    //trae stados
    this.estados.push(...await this.incidencesService.getEstados());

    //inicaliza dropdownlist
    this.initDropdownlist();

    //actualiza los datos del dropdownlist
    this.sourceEstados.localdata = this.estados;

    //trae tipos
    this.tipos = await this.incidencesService.getTipos();

    //guardar modelos
    await Promise.all(this.tipos.map(async (tipo) => {
      this.modelos.push(...await this.incidencesService.getModelos(tipo.Id));
    }));

    //asignar tipos a modelos
    this.modelos.forEach(modelo => {
      modelo.Tipo = this.tipos.find(tipo => tipo.Id === modelo.TipoId);
    });

    //guardar motivos
    await Promise.all(this.modelos.map(async (modelo) => {
      this.motivos.push(...await this.incidencesService.getMotivos(modelo.Id));
    }));

    //asignar modelos a motivos
    this.motivos.forEach(motivo => {
      motivo.Modelo = this.modelos.find(modelo => modelo.Id === motivo.ModeloId);
    });
  }

  refreshGrid = async () => {
    this.showLoader = true;
    // Limpiar selección
    this.incidencesGrid.clearselection();

    // Obtener incidencias
    this.incidences = await this.incidencesService.getIncidencesByDate(this.fechaIni, this.fechaFin);

    if (this.incidences) {
      // Asignar datos y filtrar
      this.incidences.forEach(incidence => {
        incidence.Motivo = this.motivos.find(motivo => motivo.Id == incidence.MotivoId);
        incidence.Estado = this.estados.find(estado => estado.Id == incidence.EstadoId);
        incidence.Usuario = this.users.find(usuario => usuario.Id == incidence.UsuarioId);
        incidence.Elemento = this.elementsService.elementos.get(incidence.ElementoId);
      });

      let filterEstados = [];
      this.incidences.forEach(incidence => {
        this.selectedEstados.forEach(state => {
          if (incidence.Estado.Id == state['originalItem'].Id) {
            incidence.selec = 'selec';
            filterEstados.push(incidence);
          }
        });
      });

      this.incidences = filterEstados;

      if (this.dataSource) {
        this.dataSource.localdata = this.incidences;
      }

      if (this.searchText == '') {
        this.incidencesGrid.updatebounddata('data');
        this.incidencesGrid.sortby('Fecha', 'asc');
        this.resizeColumns(this.incidencesGrid);
      } else {
        this.initSearchControl();
      }
      this.countIncidencesSubject.next(this.incidencesGrid.getrows().length);
      this.showLoader = false;
    }
  }


  //evento que se lanza cuando se ha cargado el dropdownlist
  onLoadList() {
    setTimeout(() => {
      //se hace check en los estados por defecto
      this.selectItems();
    }, 50);
  }

  onGridDataUpdate() {
    this.countPartialResults.set(this.incidencesGrid.getrows().length);
  }

  ngAfterViewInit() {
    this.getData();

    this.binarySelection = new Array(5).fill(0);
    this.incidencesGrid.autoshowloadelement(false);
    //this.incidencesGrid.showdefaultloadelement(false);
    //hace visible la ventana del gridIncidence component
    this.hideWindow(false);
    //se extraen las fechas del selector de periodo
    setTimeout(() => {
      this.addCustomForm(this.windowIncidences);
      //se actualiza el grid en el periodo actual
      this.header.periodoSelect.setPeriodo(PeriodoSelectTipo.DIA);
      this.header.periodoSelect.setDiaActual(new Date());
      this.header.periodoSelect.resetForm();
      this.fechaIni = new Date(this.header.periodoSelect.getFechaIni());
      this.fechaFin = new Date(this.header.periodoSelect.getFechaFin());
      this.refreshGrid();
    }, 500);
  }

  closeWindow() {
    this.windowIncidences.close();
    this.component?.instance?.formIncidence?.destroy();
    this.onClose();
  }

  //seleccionar estados por defecto
  selectItems() {
    if (this.header.listEstados) {
      let items = this.header.listEstados.getItems();
      if (items) {
        items.forEach((item, i) => {
          if (item['originalItem'].Nombre == 'En proceso' || item['originalItem'].Nombre == 'Abierta') {
            this.header.listEstados.checkIndex(i)
          } else {
            this.header.listEstados.uncheckIndex(i);
          }
        })
      }
    }
  }

  eventFilter() {
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaFin = this.header.periodoSelect.getFechaFin();
    this.header.searchInput['nativeElement'].value = '';
    this.refreshGrid();
  }

  onResetFilter() {
    this.header.periodoSelect.desde = new Date();
    this.header.periodoSelect.desde.setHours(0, 0, 0);
    this.header.periodoSelect.hasta = new Date();
    this.header.periodoSelect.hasta.setHours(23, 59, 59);
    PeriodoSelectComponent._this.dateForm.get('desde').setValue(new Date());
    PeriodoSelectComponent._this.dateForm.get('hasta').setValue(new Date());
    this.header.periodoSelect.cbPeriodo.selectedIndex(0);

    this.header.searchInput['nativeElement'].value = '';

    //extrae las fechas del selector de periodo
    this.fechaIni = new Date(this.header.periodoSelect.getFechaIni());
    this.fechaFin = new Date(this.header.periodoSelect.getFechaFin());

    //restablece el listado de estados
    this.selectItems();

    //actualiza grid
    this.refreshGrid();
  }

  onClickCrear() {
    if (this.markerIncidence) this.map.removeMarker(this.markerIncidence);
    this.hideWindow(true);
    const component = GridIncidencesComponent.getInstance().gridIncidencesContainer.createComponent(NewIncidenceComponent);
    component.instance.init(component, this.tipos, this.modelos, this.motivos, this.estados, '', new IncidenciaModel, this.translate('Crear_incidencia'));
    component.instance.cerrar.subscribe(() => {
      this.hideWindow(false);
      this.refreshGrid();
    });
  }

  onClickCambiar() {
    this.hideWindow(true);
    const component = GridIncidencesComponent.getInstance().gridIncidencesContainer.createComponent(EditStateComponent);
    component.instance.init(component, this.estados, this.selectedIncidence);
    component.instance.cerrar.subscribe(() => {
      this.hideWindow(false);
      this.refreshGrid();
    });
  }

  onExportar() {
    const json = JSON.parse(JSON.stringify(this.incidencesGrid.getdisplayrows()));
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Incidencias') + '.xlsx');
  }

  async onClickBorrar() {
    this.modal.confirm({
      nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
      nzContent: AppComponent.translate('Quiere_borrar_incidencia') + '?',
      nzCentered: true,
      nzCancelText: AppComponent.translate('CANCELAR'),
      nzOkText: AppComponent.translate('SI'),
      nzOnOk: async () => {
        await this.incidencesService.deleteIncidence(this.selectedIncidence.Guid);
        MainComponent.showSuccess('ATENCION', 'Registro_borrado', 2000);
        this.refreshGrid();
      }
    });
  }

  onClickVer() {
    this.windowIncidences.collapse();
    this.addMarkerIncidence();
  }

  private openWindowIds: Set<number> = new Set();

  onClickEditar = async () => {
    if (!this.selectedIncidence) {
      return; // Asegúrate de que haya algo seleccionado antes de continuar
    }

    if (this.component?.instance?.incidenceGuid !== this.selectedIncidence.Guid && this.component?.instance) {
      this.component.instance.formIncidence.close();
    }

    // Comprueba si ya existe un componente para la incidencia seleccionada
    if (this.openWindowIds.has(this.selectedIncidence.Guid)) {
      this.component.instance.formIncidence.bringToFront();
      this.hideWindow(true);
      return;
    }

    // Ocultar ventana actual
    this.hideWindow(true);

    // Crear el nuevo componente
    this.component = GridIncidencesComponent.getInstance()
      .gridIncidencesContainer.createComponent(NewIncidenceComponent);

    // Inicializar el componente con los datos requeridos
    this.component.instance.init(
      this.component,
      this.tipos,
      this.modelos,
      this.motivos,
      this.estados,
      this.selectedIncidence.Guid,
      this.selectedIncidence,
      this.translate('Editar_incidencia')
    );
    this.openWindowIds.add(this.selectedIncidence.Guid);
    await this.ensureComponentLoaded();
  }

  private async ensureComponentLoaded(): Promise<void> {
    return new Promise<void>((resolve) => {
      const checkWindow = () => {
        if (this.component.instance.formIncidence) {
          this.component.instance.formIncidence.onClose.subscribe(() => {
            this.openWindowIds.delete(this.selectedIncidence.Guid);
            this.component.destroy();
            this.windowIncidences.expand();
          });
          resolve();
        } else {
          setTimeout(checkWindow, 10);
        }
      };
      checkWindow();
    });
  }

  //cuando se pulsa en el enlace
  onClickHref() {
    this.hideWindow(true);
    const component = GridIncidencesComponent.getInstance().gridIncidencesContainer.createComponent(StatusHistoryComponent);
    component.instance.init(component, this.estados, this.users);
    component.instance.cerrar.subscribe(() => {
      this.hideWindow(false);
    });
  }
  //end eventos botones

  //ocultar o mostrar ventana
  hideWindow(hidden: boolean) {
    if (hidden) {
      if (this.markerIncidence) this.map.removeMarker(this.markerIncidence);
      //se mueve la ventana del grid a una zona no visible
      // this.windowIncidences.position({ x: -2000, y: 0 });
      this.windowIncidences.collapse();
    } else {
      // this.windowIncidences.position(this.getFormPos());
      this.windowIncidences.expand();
    }
  }

  //acaba la carga de datos en el grid
  onBindingComplete() {
    this.loaderComponent.close();
    this.countPartialResults.set(this.incidencesGrid.getrows().length);
  }

  //obtener fila seleccionada
  onRowSelect(event) {
    if (event.args.datafield == 'acciones') {
      this.selectedIncidence = this.incidences.find(incidence => incidence.Id == event.args.row.bounddata.Id);
      this.onClickEditar();
    }
  }

  onRowdoubleclick(event: any) {
    this.selectedIncidence = this.incidences.find(incidence => incidence.Id == event.args.row.bounddata.Id);
    this.onClickEditar();
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  //inicializar dropdownlist
  initDropdownlist() {
    this.header.listEstados.source = this.adapterEstados;

    this.sourceEstados = {
      datatype: 'json',
      datafields: [
        { name: 'Nombre' },
        { name: 'Id' }
      ],
      id: 'Id',
      localdata: []
    };

    this.adapterEstados = new jqx.dataAdapter(this.sourceEstados);
  }

  initGrid() {
    this.columns = [
      {
        text: '',
        datafield: 'acciones',
        width: 100,
        columntype: 'text',
        sortable: false,
        editable: false,
        groupable: false,
        menu: false,
        rendered: (columnHeaderElement) => {
          const buttonContainer1 = document.createElement('div');
          buttonContainer1.style.width = '100%';
          buttonContainer1.style.display = 'flex';
          buttonContainer1.style.justifyContent = 'center';

          buttonContainer1.id = `buttonContainerColumn_jqxButton`;
          columnHeaderElement[0].appendChild(buttonContainer1);
          const btnCrear = document.createElement('div');
          btnCrear.innerHTML =
            `<button class="button" style="height: 20px; width: 100%; padding: 0; margin: 0; cursor: pointer !important ; title="` +
            AppComponent.translate('Crear') + `"> <i style="font-size: 18px;" class="fa-solid fa-plus"></i></button>`;
          btnCrear.id = `buttonCrear_jqxButton`;
          buttonContainer1.appendChild(btnCrear);
          btnCrear.style.width = '50%'
          btnCrear.addEventListener('click', async (event: any) => {
            this.onClickCrear();
          });
          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
      },
      { text: 'Id', datafield: 'Id', align: 'center', cellsalign: 'center', hidden: true },
      { text: this.translate('Fecha'), datafield: 'Fecha', align: 'center', cellsalign: 'center', cellsformat: 'dd/MM/yy HH:mm:ss' },
      { text: this.translate('Tipo'), datafield: 'Tipo', cellsalign: 'left', align: 'left' },
      { text: this.translate('Modelo'), datafield: 'Modelo', cellsalign: 'left', align: 'left' },
      { text: this.translate('Motivo'), datafield: 'Motivo', cellsalign: 'left', align: 'left' },
      { text: this.translate('Elemento'), datafield: 'Elemento', cellsalign: 'left', align: 'left' },
      { text: this.translate('Estado'), datafield: 'Estado', cellsalign: 'left', align: 'left' },
      { text: this.translate('USUARIO'), datafield: 'UsuarioEmail', cellsalign: 'left', align: 'left' },
      { text: this.translate('Observaciones'), datafield: 'Observaciones', cellsalign: 'left', align: 'left', },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
    ];

    this.columns.forEach(column => {
      if (column.datafield !== 'acciones') {
        column.rendered = (element) => { Utils.tooltiprenderer(element) };
      }
    });

    this.dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'Id', map: 'Id', type: 'number' },
        { name: 'Fecha', map: 'Fecha', type: 'date' },
        { name: 'Tipo', map: 'Motivo>Modelo>Tipo>Nombre', type: 'string' },
        { name: 'Modelo', map: 'Motivo>Modelo>Nombre', type: 'string' },
        { name: 'Motivo', map: 'Motivo>Nombre', type: 'string' },
        { name: 'Estado', map: 'Estado>Nombre', type: 'string' },
        { name: 'Elemento', map: 'Elemento>Nombre', type: 'string' },
        { name: 'Observaciones', map: 'Observaciones', type: 'string' },
        { name: 'UsuarioEmail', map: 'Usuario>Email', type: 'string' },
        { name: 'Guid', map: 'Guid', type: 'string' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: [],
    };
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);
    this.resizeColumns(this.incidencesGrid);
  }

  // Incializa la columna de botones
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'flex-start';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    let rowdata;
    if (row && isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.incidencesGrid.getrowdata(row);
    }

    this.selectedIncidence = this.incidences.find(inc => inc.Id == rowdata.Id);

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Editar') + `">
          <i class="fa-solid fa-pen-to-square"></i>
        </button>
      `;
    btnEdit.id = `buttonInfoMap_jqxButton`;
    btnContainer.appendChild(btnEdit);

    btnEdit.addEventListener('click', (event: any) => {
      this.onClickEditar();
    });

    const btnVer = document.createElement('div');
    btnVer.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Ver') + `">
          <i class="fa-solid fa-eye"></i>
        </button>
      `;
    btnVer.id = `buttonInfoMap_jqxButton`;
    btnContainer.appendChild(btnVer);

    btnVer.addEventListener('click', async (event: any) => {
      this.onClickVer()
    });

    const btnEstado = document.createElement('div');
    btnEstado.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Cambiar_estado') + `">
          <i class="fa-solid fa-arrows-rotate"></i>
        </button>
      `;
    btnEstado.id = `buttonInfoMap_jqxButton`;
    btnContainer.appendChild(btnEstado);

    btnEstado.addEventListener('click', async (event: any) => {
      this.onClickCambiar()
    });

    /* const btnBorrar = document.createElement('div');
    btnBorrar.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Borrar') + `">
          <i class="fa-solid fa-trash"></i>
        </button>
      `;
    btnBorrar.id = `buttonInfoMap_jqxButton`;
    btnContainer.appendChild(btnBorrar);

    btnBorrar.addEventListener('click', async (event: any) => {
      this.onClickBorrar()
    }); */

    htmlElement.appendChild(btnContainer);
  }

  // Inicializa el control de búsqueda de la cabecera
  initSearchControl(): void {
    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      this.searchText = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      this.searchText = '';
    }
    // Marco los registros que cumplen la condición de búsqueda y pongo el campo
    // oculto a "selec"
    this.incidences.forEach(incidence => {
      if ((incidence.Id && incidence.Id.toString().toUpperCase().indexOf(this.searchText) > -1) ||
        (incidence.Usuario && incidence.Usuario.Email && incidence.Usuario.Email.toUpperCase().indexOf(this.searchText) > -1) ||
        (incidence.Motivo.Modelo.Tipo.Nombre && incidence.Motivo.Modelo.Tipo.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
        (incidence.Motivo.Modelo.Nombre && incidence.Motivo.Modelo.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
        (incidence.Motivo.Nombre && incidence.Motivo.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
        (incidence.Estado.Nombre && incidence.Estado.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
        (incidence.Usuario && incidence.Usuario.Nombre.toUpperCase().indexOf(this.searchText) > -1)
      ) {
        incidence.selec = 'selec';
      } else {
        incidence.selec = '';
      }
    });
    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.incidencesGrid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.addfilter(1, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.incidencesGrid.addfilter('selec', filtergroup);
    }
    this.incidencesGrid.applyfilters();
    this.incidencesGrid.updatebounddata('data');
    this.countIncidencesSubject.next(this.incidencesGrid.getrows().length);
    this.resizeColumns(this.incidencesGrid);
  }

  //evento que se lanza al cambiar los checks del dropdownlist de estados
  onChangeEstados() {
    let selecteds = this.header.listEstados.getCheckedItems();
    this.selectedEstados = [];
    //no se almacena la selección de todos, ya que este es un estado auxiliar del front
    selecteds.forEach(option => {
      this.selectedEstados.push(option);
    });
  }

  //cuando se hace check en alguna opción
  onCheckChange(event) {
    if (event.args.value != -1) {
      if (!this.isCheckedAll) {
        this.saveOptions();
      } else {
        if (!event.args.checked) {
          this.isPartialSelection = true;
          this.header.listEstados.uncheckIndex(0);
          this.saveOptions();
          this.isPartialSelection = false;
        } else {
          this.saveOptions();
        }
      }
    }
    //si se hace check en todos
    if (event.args.value == -1 && !this.isPartialSelection) {
      if (event.args.checked) {
        this.isCheckedAll = true;
        this.header.listEstados.checkAll();
        this.saveOptions();
      } if (!event.args.checked) {
        this.isCheckedAll = false;
        this.header.listEstados.uncheckAll();
        this.saveOptions();
      }
    }
  }

  saveOptions() {
    this.selectedEstados = [];
    let selecteds = this.header.listEstados.getCheckedItems();
    selecteds.forEach(option => {
      if (option.value != -1) {
        this.selectedEstados.push(option)
      }
    })

    if (this.selectedEstados.length == (this.header.listEstados.getItems().length - 1)) {
      this.header.listEstados.checkAll();
    }
  }

  onCloseCombo() {
    if (this.isCheckedAll) {
    }
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    GridIncidencesComponent._this = null;

  }

  // Cuando se destruye el componente
  ngOnDestroy() {
    if (this.markerIncidence) this.map.removeMarker(this.markerIncidence);
    try {
      GridIncidencesComponent.toolbarContainer.remove();
      GridIncidencesComponent.searchControl.remove();
      // me desuscribo del evento de contar incidencias
      this.countIncidencesSubject.unsubscribe();
      this.countIncidencesSubject = null;
    } catch (error) {

    }
  }

  //añadir marcador
  addMarkerIncidence() {
    if (this.markerIncidence) {
      this.map.removeMarker(this.markerIncidence);
    }
    this.markerIncidence = this.map.addMarker({
      dataModel: this.selectedIncidence,
      title: this.addMarkerContent(this.selectedIncidence),
      content: this.addMarkerContent(this.selectedIncidence),
      position: new MapLatLng(this.selectedIncidence.Lat, this.selectedIncidence.Lng),
      icon: '/assets/images/warning.png',
      zIndex: 999,
      drag: false,
      visible: true
    });
    this.map.setCenter(new MapLatLng(this.selectedIncidence.Lat, this.selectedIncidence.Lng));
    this.map.setZoom(15);
  }

  //añadir contenido al marcador
  addMarkerContent(incidence: IncidenciaModel) {
    let result = this.translate('Tipo') + ': ' + incidence.Motivo.Modelo.Tipo.Nombre + '<br>' +
      this.translate('Modelo') + ': ' + incidence.Motivo.Modelo.Nombre + '<br>' +
      this.translate('Motivo') + ': ' + incidence.Motivo.Nombre + '<br>' +
      this.translate('Estado') + ': ' + incidence.Estado.Nombre + '<br>' +
      this.translate('USUARIO') + ': ' + incidence.Usuario.Email + '<br>' +
      this.translate('Fecha') + ': ' + DateUtils.formatDateTime(new Date(incidence.Fecha), true) + '<br>' +
      this.translate('Observaciones') + ': ' + incidence.Observaciones + '<br>';

    return result;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}
