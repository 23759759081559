import { Injectable } from '@angular/core';
import { SsoService } from '../../sso/sso.service';

@Injectable({
  providedIn: 'root'
})
export class UserLangService {
  private userLang: string;

  constructor(private ssoService: SsoService) {
    this.userLang = this.extractUserLang();
  }

  private extractUserLang(): string {
    const ssoTicket = this.ssoService.getTicket();
    return ssoTicket?.Usuario?.Idioma?.Codigo || 'es';
  }

  /**
   * @returns {string} Return the first two characters of the language user.
   */
  getUserLangShort(): string {
    return this.userLang.substring(0, 2);
  }

  /**
   * @returns {string} Return the language user.
   */
  getUserLang(): string {
    return this.userLang;
  }
}