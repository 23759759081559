import { Component, computed, ElementRef, OnInit, signal, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { CustomForms } from '../../forms/custom-forms';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { NumberUtils } from 'src/app/utils/number-utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { HistoricoEnviosIdentificadorService } from 'src/app/services/historicoEnviosIdentificador/historico-envios-identificador.service';
import { HistoricoEnvioIdentiiicadorModel } from 'src/app/services/historicoEnviosIdentificador/models/historicoEnviosIdentificador.model';
import { DateIdentificacionModel } from 'src/app/services/cerraduras/models/dateIdentificacion';
import { MainComponent } from '../../main/main.component';
import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { MapBounds, MapComponent, MapLatLng, MapMarker, MapPolyline } from 'movisat-maps';
import { jqxCheckBoxComponent } from 'jqwidgets-ng/jqxcheckbox';
import { CerraduraModel } from 'src/app/services/cerraduras/models/cerradura.model';
import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';
import { GeoUtils } from 'src/app/utils/geo-utils';
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import { LangService } from 'src/app/services/lang/lang.service';
import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';
import { HeaderComponent } from '../../header/header.component';
import { MarcoGeograficoModel } from '../../../services/geographics/marco-geografico.model';
import { AmbitoActividadModel } from '../../../services/geographics/ambito-actividad.model';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';
import { RendererUtils } from 'src/app/utils/RendererUtils';

@Component({
  selector: 'app-historico-envios-disp-identificador',
  templateUrl: './historico-envios-disp-identificador.component.html',
  styleUrls: ['./historico-envios-disp-identificador.component.css']
})
export class HistoricoEnviosDispIdentificadorComponent extends CustomForms implements OnInit {
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  @ViewChild('checkTodas') checkTodas: jqxCheckBoxComponent;
  @ViewChild('myTabs') myTabs: jqxTabsComponent;

  private componentRef = null;
  public theme = environment.tema;
  // map
  public mapProvider = 'Google';
  public cartoType = 'raster';
  public lang = 'es-ES'; // Idioma por defecto
  public searchCountry = 'ES'; // Pais por defecto (para las búsquedas en el mapa)
  public searchBounds = ''; // Marco por defecto (para las búsquedas en el mapa)
  public environment = environment;
  public zoom = 6;
  public center = { // Cieza
    lat: 38.2378331,
    lng: -1.42102,
    weight: 1
  };
  public marcoGeografico: MarcoGeograficoModel;
  public ambitoActividad: AmbitoActividadModel;
  // grid
  dataAdapter: any;
  source: any = null;
  public langGrid = JqWidgets.getLocalization('es');
  editrow: number = -1;
  // variables
  gridIdentificaciones: jqxGridComponent;
  historicosEnviosIdentificador: HistoricoEnvioIdentiiicadorModel[] = [];
  envioIdentificador: HistoricoEnvioIdentiiicadorModel = new HistoricoEnvioIdentiiicadorModel();
  dateIdentificacion: DateIdentificacionModel = new DateIdentificacionModel();
  elementos: ElementoModel[] = [];
  marker: MapMarker;
  markerIdentificador: MapMarker;
  markerElemento: MapMarker;
  markers: MapMarker[] = [];
  private map: MapComponent;
  cerraduras: CerraduraModel[] = [];
  private polyline: MapPolyline = null;
  private polylines: MapPolyline[] = [];
  distanciaTotal: number = 0;
  mapWidth: number;
  mapHeight: number;
  rowVisible: number = -1;
  public static _this: any;
  showRowDetails: any[] = [];
  showLoader: boolean = true;
  public ocultar: boolean = true;
  boundChange: boolean = true;
  checkMaxMetros: boolean = false;
  inputMaxMetros: number = 50;
  countPartialResults = signal<number>(0);
  titleTabLecturas = this.translate('Lecturas');
  titleTabCartografia = this.translate('Cartografia');
  titleTabLecturasSignal = computed(() => `${this.titleTabLecturas} (${this.countPartialResults()})`);

  cellClassToolTip = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'cellTooltip';
    }
    return '';
  };

  public columnmenuopening(menu?: any, datafield?: any, height?: number | string): boolean | void {
    if (menu.length === 1) {
      if (datafield === 'fechaEnvio' || datafield === 'horaEnvio' || datafield === 'fecha' || datafield === 'hora') {
        const configureDateTimeInput = (menuElement: HTMLElement, options: { showTimeButton: boolean; showCalendarButton: boolean, formatString: string }) => {
          const dateTimeInputs = menuElement.querySelectorAll('.jqx-datetimeinput');
          if (dateTimeInputs && dateTimeInputs.length > 0) {
            dateTimeInputs.forEach((input: HTMLElement) => {
              const elementRef = new ElementRef(input);
              const jqxDateTimeInput = new jqxDateTimeInputComponent(elementRef);
              const inputDate = jqwidgets.createInstance('#' + jqxDateTimeInput.elementRef.nativeElement.id, 'jqxDateTimeInput', { width: '100%', height: 25 });
              inputDate.setOptions(options);
            });
          }
        };

        const divElement: HTMLElement = menu[0];
        if (datafield === 'fechaEnvio' || datafield === 'fecha') {
          configureDateTimeInput(divElement, { showTimeButton: false, showCalendarButton: true, formatString: 'dd/MM/yyyy' });
        } else if (datafield === 'horaEnvio' || datafield === 'hora') {
          configureDateTimeInput(divElement, { showTimeButton: true, showCalendarButton: false, formatString: 'HH:mm:ss' });
        }
      }
    }
  }

  renderDate(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    return `<div style=" margin-top: 4px;  text-align: center;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${DateUtils.formatDateTime(value, true)}</span></div>`;
  }
  columns = [];
  columngroups: any = [];

  constructor(
    private historicEnviosIdentificadorService: HistoricoEnviosIdentificadorService,
    private cerraduraService: CerraduraService,
    public langService: LangService
  ) {
    super();
    HistoricoEnviosDispIdentificadorComponent._this = this;
  }

  ngOnInit() {
    this.map = MainComponent.getInstance().getMap();
    this.ocultar = false;
  }

  /**Inicializa el componente
   */
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form, false);
    this.cerraduras = await this.cerraduraService.getCerraduras();
    this.valuesByDefault();
    this.initGridHistorico();
    Utils.renderSizeGrid(this.myGrid);
    this.gethistoricoEnviosIdentificador();
  }

  onRowclick(event: any): void {
    if (event.target.id !== 'nestedGrid1') {
      this.envioIdentificador = this.historicosEnviosIdentificador.find(x => x.id == event.args.row.bounddata.id);
    } else {
      return;
    }
  }

  valuesByDefault() {
    this.dateIdentificacion.Desde = this.header.periodoSelect.desde;
    this.dateIdentificacion.Hasta = this.header.periodoSelect.hasta;
    this.header.masXMetrosInput.value(50);
    this.header.masXMetrosInput.disabled(true);
    this.myTabs.setTitleAt(0, this.titleTabLecturasSignal());
    this.myTabs.setTitleAt(1, this.titleTabCartografia);
  }

  onSort(event: any) {
    this.showRowDetails.forEach(row => {
      this.myGrid.getrows().forEach(elem => {
        if (row === elem.id) {
          this.myGrid.showrowdetails(elem.boundindex);
        }
      });
    });
  }

  onRowClick(event: any) {
    this.envioIdentificador = this.historicosEnviosIdentificador.find(x => x.id == event.args.row.bounddata.id);
    if (this.envioIdentificador.cantidad !== 0) {
      this.myGrid.unselectrow(event.args.rowindex);
    } else {
      this.myGrid.selectrow(event.args.rowindex);
    }
  }

  initGridHistorico() {
    this.columngroups = [
      { text: AppComponent.translate('Datos') + ' ' + AppComponent.translate('Envios').toLowerCase(), align: 'center', name: 'datosEnvioGroup' },
      { text: AppComponent.translate('Direccion_identificador'), align: 'center', name: 'direccionIdentificadorGroup' },
      { text: AppComponent.translate('Elemento'), align: 'center', name: 'elementoGroup' },
      { text: AppComponent.translate('Direccion') + ' ' + AppComponent.translate('Elemento').toLowerCase(), align: 'center', name: 'direccionElementoGroup' }
    ];

    this.columns = [
      { datafield: 'selec', hidden: true },
      { datafield: 'id', hidden: true },
      {
        text: this.translate('Fecha'),
        columntype: 'datetimeinput',
        filtertype: 'date',
        cellsformat: 'dd/MM/yyyy',
        width: 90,
        datafield: 'fechaEnvio',
        columngroup: 'datosEnvioGroup',
        cellclassname: this.cellClassToolTip
      },
      {
        text: this.translate('Hora'),
        columntype: 'datetimeinput',
        filtertype: 'date',
        width: 80,
        datafield: 'horaEnvio',
        cellsformat: 'HH:mm:ss',
        columngroup: 'datosEnvioGroup',
        cellclassname: this.cellClassToolTip
      },
      {
        text: this.translate('Ns_movisat'),
        width: 160,
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'nsMovisat',
        cellclassname: this.cellClassToolTip,
        columngroup: 'datosEnvioGroup'
      },
      {
        text: this.translate('Num_identificaciones'),
        datafield: 'cantidad',
        width: 100,
        cellsrenderer: RendererUtils.numberrenderer,
        columngroup: 'datosEnvioGroup'
      },
      {
        text: this.translate('Bateria'),
        columntype: 'textbox',
        filtertype: 'textbox',
        width: 60,
        datafield: 'bateriaPorcentaje',
        cellsrenderer: this.numberrenderer,
        cellclassname: this.cellClassToolTip,
        columngroup: 'datosEnvioGroup'
      },
      {
        text: this.translate('Temperatura'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'temperatura',
        width: 80,
        cellsrenderer: this.numberrenderer,
        cellclassname: this.cellClassToolTip,
        columngroup: 'datosEnvioGroup'
      },
      {
        text: this.translate('Direccion'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'calle',
        width: 100,
        cellclassname: this.cellClassToolTip,
        columngroup: 'direccionIdentificadorGroup'
      },
      {
        text: this.translate('Municipio'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'municipio',
        width: 70,
        cellclassname: this.cellClassToolTip,
        columngroup: 'direccionIdentificadorGroup'
      },
      {
        text: this.translate('Poblacion'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'poblacion',
        width: 70,
        cellclassname: this.cellClassToolTip,
        columngroup: 'direccionIdentificadorGroup'
      },
      {
        text: this.translate('Provincia'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'provincia',
        width: 70,
        cellclassname: this.cellClassToolTip,
        columngroup: 'direccionIdentificadorGroup'
      },
      {
        text: this.translate('Num_calle'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'numero',
        cellsalign: 'right',
        width: 60,
        cellclassname: this.cellClassToolTip,
        cellsrenderer: this.renderText,
        columngroup: 'direccionIdentificadorGroup'
      },
      {
        text: this.translate('Metros'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'metros',
        width: 60,
        cellsrenderer: RendererUtils.numberrendererDecimales,
        cellclassname: this.cellClassToolTip,
        columngroup: 'direccionIdentificadorGroup'
      },
      {
        text: this.translate('Nombre_elemento'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'nombreElemento',
        width: 110,
        cellclassname: this.cellClassToolTip,
        columngroup: 'elementoGroup'
      },
      {
        text: this.translate('Marca'),
        columntype: 'textbox',
        filtertype: 'checkedlist',
        datafield: 'marcaElemento',
        width: 100,
        cellclassname: this.cellClassToolTip,
        columngroup: 'elementoGroup'
      },
      {
        text: this.translate('Modelo'),
        columntype: 'textbox',
        filtertype: 'checkedlist',
        datafield: 'modeloElemento',
        width: 100,
        cellclassname: this.cellClassToolTip,
        columngroup: 'elementoGroup'
      },
      {
        text: this.translate('Matricula'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'matriculaElemento',
        width: 75,
        cellclassname: this.cellClassToolTip,
        columngroup: 'elementoGroup'
      },
      {
        text: this.translate('Direccion'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'direccionElemento',
        width: 100,
        cellclassname: this.cellClassToolTip,
        columngroup: 'direccionElementoGroup'
      },
      {
        text: this.translate('Municipio'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'municipioElemento',
        width: 70,
        cellclassname: this.cellClassToolTip,
        columngroup: 'direccionElementoGroup'
      },
      {
        text: this.translate('Poblacion'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'poblacionElemento',
        width: 70,
        cellclassname: this.cellClassToolTip,
        columngroup: 'direccionElementoGroup'
      },
      {
        text: this.translate('Observaciones'),
        columntype: 'textbox',
        filtertype: 'textbox',
        datafield: 'observacionesElemento',
        menu: false,
        sortable: false,
        width: 100,
        cellclassname: this.cellClassToolTip,
      }
    ];
  }

  async gethistoricoEnviosIdentificador() {
    this.columns.forEach(column => {
      column.rendered = (element) => {
        Utils.tooltiprenderer(element);
      };
    });

    this.source = {
      datatype: 'json',
      sort: this.customsortfunc,
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'fechaEnvio', type: 'date', map: 'fechaEnvio' },
        { name: 'horaEnvio', type: 'date', map: 'fechaEnvio' },
        { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
        { name: 'cantidad', map: 'cantidad' },
        { name: 'bateriaPorcentaje', type: 'number', map: 'bateriaPorcentaje' },
        { name: 'temperatura', type: 'number', map: 'temperatura' },
        { name: 'latElemento', type: 'number', map: 'latElemento' },
        { name: 'lngElemento', type: 'number', map: 'lngElemento' },
        { name: 'idElemento', type: 'number', map: 'idElemento' },
        { name: 'metros', type: 'number', map: 'metros' },
        { name: 'provincia', type: 'string', map: 'provincia' },
        { name: 'municipio', type: 'string', map: 'municipio' },
        { name: 'poblacion', type: 'string', map: 'poblacion' },
        { name: 'calle', type: 'string', map: 'calle' },
        { name: 'numero', type: 'number', map: 'numero' },
        { name: 'codigoPostal', type: 'number', map: 'codigoPostal' },
        { name: 'nombreElemento', type: 'string', map: 'nombreElemento' },
        { name: 'marcaElemento', type: 'string', map: 'marcaElemento' },
        { name: 'modeloElemento', type: 'string', map: 'modeloElemento' },
        { name: 'matriculaElemento', type: 'string', map: 'matriculaElemento' },
        { name: 'direccionElemento', type: 'string', map: 'direccionElemento' },
        { name: 'municipioElemento', type: 'string', map: 'municipioElemento' },
        { name: 'poblacionElemento', type: 'string', map: 'poblacionElemento' },
        { name: 'observacionesElemento', type: 'string', map: 'observacionesElemento' },
        { name: 'selec', type: 'string', map: 'selec' },
      ],
      localdata: this.historicosEnviosIdentificador,
    };
    this.dataAdapter = new jqx.dataAdapter(this.source);

    this.onAceptar();
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      'nombreElemento',
      'marcaElemento',
      'modeloElemento',
      'matriculaElemento',
      'direccionElemento',
      'municipioElemento',
      'poblacionElemento',
      'observacionesElemento',
      'Fecha_envio',
    ];

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  onGridDataUpdate(): void {
    this.countPartialResults.set(this.myGrid.getrows().length);
    this.myTabs.setTitleAt(0, this.titleTabLecturasSignal());
  }

  async onMapReady(map: MapComponent): Promise<void> {
    this.map = map;
    const mainInstance = MainComponent.getInstance();
    this.marcoGeografico = mainInstance.marcoGeografico;
    this.ambitoActividad = mainInstance.ambitoActividad;

    if (!this.marcoGeografico.marco.contains(map.center)) {
      map.onBoundsChange(map.getBounds());
    } else {
      this.minZoom = map.zoom;
      setTimeout(() => (this.lastBounds = map.getBounds()), 500);
    }
  }

  private lastZoom;
  private lastBounds: MapBounds;
  private minZoom;

  onBoundsChange(bounds: MapBounds): void {
    const mainInstance = MainComponent.getInstance();
    this.marcoGeografico = mainInstance.marcoGeografico;
    this.ambitoActividad = mainInstance.ambitoActividad;

    if (!this.marcoGeografico) return;

    if (!this.lastBounds) {
      this.resetMapView();
    } else {
      this.handleBoundsChange(bounds);
    }
  }

  private resetMapView(): void {
    this.map?.fitTo(this.marcoGeografico.marco);
    this.lastZoom = this.map?.zoom ?? null;
    this.minZoom = this.lastZoom;
  }

  private handleBoundsChange(bounds: MapBounds): void {
    if (!this.map || !this.lastBounds || !this.marcoGeografico) return;

    if (!this.marcoGeografico.marco.contains(this.map.center)) {
      if (this.lastBounds.contains(this.marcoGeografico.marco.swCorner) &&
        this.lastBounds.contains(this.marcoGeografico.marco.neCorner)) {
        this.map.setZoom(this.lastZoom! + 1);
        this.map.fitTo(this.marcoGeografico.marco);
      } else {
        this.map.fitTo(this.lastBounds);
      }
    } else if (bounds.contains(this.marcoGeografico.marco.swCorner) &&
      bounds.contains(this.marcoGeografico.marco.neCorner) &&
      this.map.zoom < this.lastZoom!) {
      this.map.setZoom(Math.max(this.lastZoom!, this.minZoom!));
      this.map.fitTo(this.marcoGeografico.marco);
    }
  }

  // asigno las fechas del periodo seleccionado
  async onAceptar() {
    this.showLoader = true;
    this.header.searchInput['nativeElement'].value = '';

    this.dateIdentificacion.Desde = this.header.periodoSelect.getFechaIni();
    this.dateIdentificacion.Hasta = this.header.periodoSelect.getFechaFin();
    this.historicosEnviosIdentificador = await this.historicEnviosIdentificadorService.getHistoricosEnviosIdentificador(this.dateIdentificacion);

    this.onBuscar();

    this.source.localdata = this.historicosEnviosIdentificador;
    this.myGrid.updatebounddata('data');

    this.myGrid.sortby('fechaEnvio', 'descending');
    this.showLoader = false;
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    // this.deleteMapElements();
    this.distanciaTotal = 0;
    HistoricoEnviosDispIdentificadorComponent._this = null;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  renderRow(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (columnfield == 'tipoPermiso') {
      if (value == 'BLANCA') {
        return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';"this.style.position='fixed'; onmouseout="this.style.backgroundColor='';this.style.position='';" this.style.color='';"> ${AppComponent.translate('Blanca')}</span></div>`;
      } else if (value == 'MASTER') {
        return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';"this.style.position='fixed'; onmouseout="this.style.backgroundColor='';this.style.position='';" this.style.color='';"> ${AppComponent.translate('Master')}</span></div>`;
      }
    } else {
      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';"this.style.position='fixed'; onmouseout="this.style.backgroundColor='';this.style.position='';" this.style.color='';"> ${value}</span></div>`;
    }
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (columnfield == 'bateriaPorcentaje') {
      // return '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' +
      //   NumberUtils.format(value, 0) +
      //   '%</div>';
      // como en renderRow
      return `<div style=" margin-top: 4px;  text-align: center;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${NumberUtils.format(value, 0)}%</span></div>`;
    } else if (columnfield === 'temperatura') {
      // return (
      //   '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' +
      //   NumberUtils.format(value, 0) +
      //   'º</div>'
      // );
      // como en renderRow
      return `<div style=" margin-top: 4px;  text-align: center;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${NumberUtils.format(value, 0)}º</span></div>`;
    } else {
      //return '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' + NumberUtils.format(value, 0) + '</div>';
      // como en renderRow
      return `<div style=" margin-top: 4px;  text-align: center;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';"> ${NumberUtils.format(value, 0)}</span></div>`;
    }
  }

  renderText(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (rowdata.idElemento == 0 || rowdata.idElemento == null) {
      return '';
    } else if (rowdata.nombreElemento == null || rowdata.nsCerradura == null) {
      return '';
    } else {
      // return '<div style="margin-right: 4px; margin-top: 5px;">' + rowdata.nombreElemento + '</div>';
      // como en renderRow
      return `<div style="margin-right: 4px; margin-top: 4px;  text-align: right;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';  this.style.position='fixed'" onmouseout="this.style.backgroundColor=''; this.style.color='';this.style.position=''"> ${rowdata.nombreElemento}</span></div>`;
    }
  }

  renderIdentificacion(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value == 0) {

      // return '<div  style="margin-left: 4px; margin-top: 4px">NFC</div>';
      // como en renderRow
      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';  this.style.position='fixed'" onmouseout="this.style.backgroundColor=''; this.style.color='';this.style.position=''"> NFC</span></div>`;
    } else if (value == 1) {
      //return '<div  style="margin-left: 4px; margin-top: 4px">Bluetooth</div>';
      // como en renderRow
      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"><span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';  this.style.position='fixed'" onmouseout="this.style.backgroundColor=''; this.style.color='';this.style.position=''"> Bluetooth</span></div>`;
    }
  }

  renderCiudadano(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (rowdata.nombreCiudadano == null) {
      return '';
    } else {
      return '<div style="margin-left: 4px; margin-top: 4px;">' + rowdata.nombreCiudadano + '</div>';
    }
  }

  renderNsTarjeta(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return `
        <div style="margin-right: 4px; margin-top: 4px; text-align: right;">
          <span onmouseover="this.style.backgroundColor='gray'; this.style.color='white';" onmouseout="this.style.backgroundColor=''; this.style.color='';">
            ${NumberUtils.format(value, 0)}
          </span>
        </div>
      `;
    }
  }

  // identificaciones grid
  dataAdapterIdentificaciones: any;
  sourceIdentificaciones: any;

  public columnsIdentificacines = [
    { datafield: 'idEnvio', hidden: true },
    {
      text: this.translate('Fecha'),
      columntype: 'datetimeinput',
      filtertype: 'date',
      datafield: 'fecha',
      cellsformat: 'dd/MM/yyyy',
      cellclassname: this.cellClassToolTip,
      width: 90,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: any) {
          const aggregated = Number(aggregatedValue) || 0;
          return aggregated + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        const total = Number(aggregates['Total']);

        if (!isNaN(total)) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' +
            AppComponent.translate('Total') + ': ' +
            NumberUtils.format(total, 0) + '</div>';
        } else {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' +
            AppComponent.translate('Total') + ': 0</div>';
        }

        return renderstring;
      }
    },
    {
      text: this.translate('Hora'),
      columntype: 'datetimeinput',
      filtertype: 'date',
      datafield: 'hora',
      cellsformat: 'HH:mm:ss',
      cellclassname: this.cellClassToolTip,
      width: 80,
    },
    {
      text: this.translate('Ns_tarjeta'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'nsTarjeta',
      width: 100,
      cellsrenderer: this.renderNsTarjeta,
    },
    // { text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', width: 150, datafield: 'nsMovisat', cellsrenderer: this.renderRow, },
    {
      text: this.translate('Ciudadano'),
      columntype: 'textbox',
      filtertype: 'textbox',
      width: 100,
      datafield: 'nombreCiudadano',
      cellsrenderer: this.renderCiudadano
    },
    { text: this.translate('Aporte_residuo'), columntype: 'checkbox', filtertype: 'bool', width: 110, datafield: 'aporteResiduo' },
    { text: this.translate('Apertura_tapa'), columntype: 'checkbox', filtertype: 'bool', width: 100, datafield: 'aperturaTapa' },
    { text: this.translate('No_cerro_tapa'), columntype: 'checkbox', filtertype: 'bool', width: 90, datafield: 'noCerroTapa' },
    {
      text: this.translate('Tipo_identificacion'),
      columntype: 'textbox',
      width: 130,
      filtertype: 'textbox',
      datafield: 'medio',
      cellsrenderer: this.renderIdentificacion
    },
    {
      text: this.translate('Tipo_permiso'),
      columntype: 'textbox',
      width: 100,
      filtertype: 'textbox',
      datafield: 'tipoPermiso',
      cellsrenderer: this.renderRow,
    },
  ];

  onRowdoubleclick(event: any): void {
    if (event.target.id !== 'nestedGrid1') {
      // Elimino los marcadores del mapa
      //this.deleteMapElements();
      this.distanciaTotal = 0;

      this.boundChange = false;
      // Encuentro el identificador del envío
      if (!event.args.row.bounddata.idEnvio) {
        this.envioIdentificador = this.historicosEnviosIdentificador.find(x => x.id == event.args.row.bounddata.id);
        if (!this.envioIdentificador) {
          return MainComponent.getInstance().showError('ATENCION', 'No_existen_coordenadas', 2000);
        }
        this.showEnviosIdentificacionesMap();
      } else {
        this.envioIdentificador = this.historicosEnviosIdentificador.find(x => x.id == event.args.row.bounddata.idEnvio);
        if (!this.envioIdentificador) {
          return MainComponent.getInstance().showError('ATENCION', 'No_existen_coordenadas', 2000);
        }
        this.showEnviosIdentificacionesMap();
      }
    } else {
      return;
    }
  }

  showEnviosIdentificacionesMap(): void {
    let posicion = null;

    // Intentar ubicar usando la información del envío (Identificador)
    if (
      this.envioIdentificador.lat && this.envioIdentificador.lat != 0 &&
      this.envioIdentificador.lng && this.envioIdentificador.lng != 0
    ) {
      posicion = new MapLatLng(this.envioIdentificador.lat, this.envioIdentificador.lng);
    } else if (
      this.envioIdentificador.cerraduraLat && this.envioIdentificador.cerraduraLat != 0 &&
      this.envioIdentificador.cerraduraLng && this.envioIdentificador.cerraduraLng != 0
    ) {
      posicion = new MapLatLng(this.envioIdentificador.cerraduraLat, this.envioIdentificador.cerraduraLng);
    }

    // Si no se encuentra ninguna posición, mostrar error
    if (!posicion) {
      return MainComponent.getInstance().showError('ATENCION', 'No_existen_coordenadas', 2000);
    } else {
      this.myTabs.select(1);
      let iconoIdentificador = 'assets/images/cerradura-gris.png';
      this.markerIdentificador = this.createMarkers(posicion, iconoIdentificador);
      this.markerIdentificador.content = '<div style="margin: 10px;">' + this.translate('Ubicacion_por_envio') + '<br>' + this.envioIdentificador.nsMovisat + '</div>';
    }

    // Lógica para manejar los marcadores
    if (this.markerIdentificador) {
      this.encuadrarCerradurasSelec([this.markerIdentificador]);

      setTimeout(() => {
        this.boundChange = true;
      }, 100)

      if (this.markerIdentificador) {
        this.markerIdentificador.animate(2800);
      }
    } else {
      return MainComponent.getInstance().showError('ATENCION', 'Error', 2000);
    }
  }

  encuadrarCerradurasSelec(values: MapMarker[]): void {
    let globalSWPoint = new MapLatLng(180, 90);
    let globalNEPoint = new MapLatLng(-180, -90);

    values.forEach(value => {
      let swPoint = new MapLatLng(value.position.lat, value.position.lng);
      let nePoint = new MapLatLng(value.position.lat, value.position.lng);

      globalSWPoint.lat = Math.min(globalSWPoint.lat, swPoint.lat);
      globalSWPoint.lng = Math.min(globalSWPoint.lng, swPoint.lng);
      globalNEPoint.lat = Math.max(globalNEPoint.lat, nePoint.lat);
      globalNEPoint.lng = Math.max(globalNEPoint.lng, nePoint.lng);
    });

    this.map.fitTo(new MapBounds(globalSWPoint, globalNEPoint));
  }

  showDistancePolyline(position1: MapLatLng, position2: MapLatLng): void {
    let distancia = GeoUtils.getDistance(position1.lat, position1.lng, position2.lat, position2.lng);
    distancia = Number.parseFloat(distancia.toFixed(2));
    this.distanciaTotal = distancia;

    if (!this.polyline) {
      this.polyline = this.addPolyline();
    }

    this.map.addPolylinePoint(this.polyline, {
      dataModel: this.envioIdentificador,
      content: this.distanciaTotal + ' m',
      position: position1
    });

    this.map.addPolylinePoint(this.polyline, {
      dataModel: this.envioIdentificador,
      position: position2,
      content: this.distanciaTotal + ' m',
    });
  }

  addPolylinePoint(): void {
    if (!this.polyline) {
      this.polyline = this.addPolyline();
    }
    // Añadir un punto a la polilínea
    this.map.addPolylinePoint(this.polyline, {
      dataModel: this.envioIdentificador,
      content: this.distanciaTotal + ' m',
      position: new MapLatLng(this.envioIdentificador.latElemento, this.envioIdentificador.lngElemento)
    });

    this.map.addPolylinePoint(this.polyline, {
      dataModel: this.envioIdentificador,
      position: new MapLatLng(this.envioIdentificador.lat, this.envioIdentificador.lng),
      content: this.distanciaTotal + ' m',
    });
  }

  createMarkers(position: MapLatLng, icono: string): MapMarker {
    //this.deleteMapElements();
    let marker = this.map.addMarker({
      dataModel: '',
      title: '',
      content: '',
      position: position,
      icon: icono,
      zIndex: 999,
      drag: false,
      visible: true
    });
    return marker;
  }

  // añado una polylinea al mapa entre dos puntos (inicio y fin)
  addPolyline(): MapPolyline {
    const polyline = this.map.addPolyline({
      color: 'red',
      weight: 7,
      opacity: 0.5,
      clickable: true,
    });
    this.polylines.push(polyline);
    return polyline;
  }

  onTabClick(event: any): void {
    this.deleteMapElements();
  }

  deleteMapElements(): void {
    // Elimino los marcadores del mapa
    if (this.markerElemento) {
      this.map.removeMarker(this.markerElemento);
      this.markerElemento = null;
    }
    if (this.markerIdentificador) {
      this.map.removeMarker(this.markerIdentificador);
      this.markerIdentificador = null;
    }

    // Elimino las polilineas del mapa
    if (this.polylines.length > 0) {
      this.polylines.forEach(element => {
        this.map.removePolyline(element);
      });
      this.polylines = [];
      this.polyline = null;
    }
  }

  async onResetFilter() {
    this.showLoader = true;
    this.header.searchInput['nativeElement'].value = '';
    this.header.periodoSelect.setPeriodo(0);

    this.header.periodoSelect.dateForm.get('desde').setValue(new Date());
    this.header.periodoSelect.dateForm.get('hasta').setValue(new Date());
    this.header.periodoSelect.resetForm();

    this.dateIdentificacion.Desde = new Date();
    this.dateIdentificacion.Desde.setHours(0, 0, 0);
    this.dateIdentificacion.Hasta = new Date();
    this.dateIdentificacion.Hasta.setHours(23, 59, 0, 0);

    this.header.checkMasXmetros.checked(false);
    this.header.masXMetrosInput.disabled(true);
    this.header.masXMetrosInput.val(50);
    //this.deleteMapElements();
    this.historicosEnviosIdentificador = await this.historicEnviosIdentificadorService.getHistoricosEnviosIdentificador(this.dateIdentificacion);
    this.source.localdata = this.historicosEnviosIdentificador;
    this.myGrid.clearfilters();
    this.myGrid.updatebounddata();
    this.myGrid.sortby('fechaEnvio', 'asc');
    this.showLoader = false;
  }

  checkMasXmetros() {
    if (this.header.checkMasXmetros.widgetObject.checked) {
      this.checkMaxMetros = true;
      this.inputMaxMetros = Number.parseInt((this.header.masXMetrosInput.widgetObject.decimal + ''));
    } else {
      this.checkMaxMetros = false;
    }
    this.onBuscar();
  }

  changeMasXmetros(event: any) {
    this.inputMaxMetros = Number.parseInt(event);
    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value.toUpperCase();
    } else {
      filtervalue = '';
    }

    this.historicosEnviosIdentificador.forEach(historico => {
      if (
        ((historico?.nsMovisat + '').compareWith(filtervalue) ||
          (historico?.cantidad + '').compareWith(filtervalue) ||
          (historico?.bateriaPorcentaje + '').compareWith(filtervalue) ||
          (historico?.temperatura + '').compareWith(filtervalue) ||
          (historico?.latElemento + '').compareWith(filtervalue) ||
          (historico?.lngElemento + '').compareWith(filtervalue) ||
          (historico?.idElemento + '').compareWith(filtervalue) ||
          (historico?.provincia + '').compareWith(filtervalue) ||
          (historico?.municipio + '').compareWith(filtervalue) ||
          (historico?.poblacion + '').compareWith(filtervalue) ||
          (historico?.calle + '').compareWith(filtervalue) ||
          (historico?.numero + '').compareWith(filtervalue) ||
          (historico?.codigoPostal + '').compareWith(filtervalue) ||
          (historico?.nombreElemento + '').compareWith(filtervalue) ||
          (historico?.marcaElemento + '').compareWith(filtervalue) ||
          (historico?.modeloElemento + '').compareWith(filtervalue) ||
          (historico?.matriculaElemento + '').compareWith(filtervalue) ||
          (historico?.direccionElemento + '').compareWith(filtervalue) ||
          (historico?.municipioElemento + '').compareWith(filtervalue) ||
          (historico?.poblacionElemento + '').compareWith(filtervalue) ||
          (historico?.observacionesElemento + '').compareWith(filtervalue))
      ) {
        if (this.checkMaxMetros) {
          if (historico?.metros !== null && historico?.metros >= this.inputMaxMetros) {
            historico['selec'] = 'selec';
          } else {
            historico['selec'] = '';
          }
        } else {
          historico['selec'] = 'selec';
        }
      } else {
        historico['selec'] = '';
      }
    })

    // Compruebo si ya he creado el filtro "selec" anteriormente
    const filters = this.myGrid.getfilterinformation();
    if (filters.find(s => s.datafield === 'selec') === undefined) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';
      filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      this.myGrid.addfilter('selec', filtergroup);
    }
    this.myGrid.applyfilters();
    this.myGrid.updatebounddata('data');
  }

  onExportar() {
    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.myGrid.exportdata('json');
      let datos = JSON.parse(json);
      datos.forEach((element, index) => {
        element[this.translate('Bateria')] = isNaN(parseFloat(element[this.translate('Bateria')])) ? '' : parseFloat(element[this.translate('Bateria')]).toFixed(0);
        element[this.translate('Temperatura')] = isNaN(parseFloat(element[this.translate('Temperatura')])) ? '' : parseFloat(element[this.translate('Temperatura')]).toFixed(0);
        element[this.translate('Num_identificaciones')] = isNaN(parseFloat(element[this.translate('Num_identificaciones')])) ? '' : parseFloat(element[this.translate('Num_identificaciones')]).toFixed(0);
        element[this.translate('Fecha_envio')] = isNaN(parseFloat(element[this.translate('Fecha_envio')])) ? '' : element[this.translate('Fecha_envio')] = DateUtils.formatDateTime(this.myGrid.getrows()[index].fechaEnvio, false).replace('T', ' ');
      });
      // Utiliza el array modificado en lugar del JSON original
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
      this.generateAutofilterHeader(ws);

      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Historico_envios_dispositivo_identificador') + '.xlsx');
    }
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Boton para imprimir
  onPrint() {
    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.myGrid.hidecolumn('Botones');
      let gridContent = this.myGrid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.myGrid.showcolumn('Botones');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }

  rowdetailstemplate = (index: any): any => {
    var details = {
      rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
      rowdetailsheight: 180,
      rowdetailshidden: true,
    };
    this.editrow = index;
    //Saca el usuario y adapta el row height al numero de accesos que tenga
    let rowId = this.myGrid.getcellvalue(index, 'id');
    let envio = this.historicosEnviosIdentificador.find(x => x.id == rowId);

    if (envio) {
      if (!envio.Detalle) {
        return details;
      }
    }
    return details;
  };

  initRowDetails = (
    index: any,
    parentElement: any,
    gridElement: any,
    datarecord: any
  ): void => {
    if (parentElement && datarecord) {
      let nestedGridContainer = parentElement.children[0];

      let i = this.historicosEnviosIdentificador.findIndex(
        (envio) => envio.id === datarecord.id
      );

      this.envioIdentificador = this.historicosEnviosIdentificador[i];
      if (this.envioIdentificador) {
        let sourceDetalle = {
          datatype: 'array',
          datafields: [
            { name: 'idEnvio', type: 'number', map: 'idEnvio' },
            { name: 'fecha', type: 'date', map: 'fecha' },
            { name: 'hora', type: 'date', map: 'fecha' },
            { name: 'nsTarjeta', type: 'number', map: 'nsTarjeta' },
            { name: 'nsMovisat', type: 'string', map: 'nsCerradura' },
            { name: 'nombreCiudadano', type: 'string', map: 'nombreCiudadano' },
            { name: 'aporteResiduo', type: 'boolean', map: 'aporte' },
            { name: 'aperturaTapa', type: 'boolean', map: 'aperturaTapa' },
            { name: 'noCerroTapa', type: 'boolean', map: 'noCerroTapa' },
            //{ name: 'nsCerradura', type: 'string', map: 'nsCerradura' },
            { name: 'observaciones', type: 'string', map: 'observacionesElemento' },
            { name: 'matricula', type: 'string', map: 'matriculaElemento' },
            { name: 'medio', type: 'string', map: 'medio' },
            { name: 'tipoPermiso', type: 'string', map: 'tipoPermiso' },
            { name: 'marca', type: 'string', map: 'marcaElemento' },
            { name: 'modelo', type: 'string', map: 'modeloElemento' },
            { name: 'nombreElemento', type: 'string', map: 'nombreElemento' },
            { name: 'direccion', type: 'string', map: 'direccionElemento' },
            { name: 'municipio', type: 'string', map: 'municipioElemento' },
            { name: 'poblacion', type: 'string', map: 'poblacionElemento' },
          ],
          localdata: this.envioIdentificador.Detalle,
        };

        let dataDetalleEnvios = new jqx.dataAdapter(sourceDetalle);

        if (nestedGridContainer != null) {
          let setting = {
            width: '97%',
            height: '90%',
            rowsheight: 25,
            columnsheight: 25,
            source: dataDetalleEnvios,
            showaggregates: true,
            showstatusbar: true,
            statusbarheight: 20,
            columnmenuopening: this.columnmenuopening,
            filterable: true,
            sortable: true,
            theme: this.theme,
            columns: this.columnsIdentificacines,
            localization: this.langGrid,
            editable: false,
            columnsresize: true
          };
          this.gridIdentificaciones = jqwidgets.createInstance(
            `#${nestedGridContainer.id}`,
            'jqxGrid',
            setting
          );
        }
      }
    } else {
      return;
    }
  };

  rowExpand(event: any) {
    let rowId = this.myGrid.getcellvalue(event.args.rowindex, 'id');
    let envio = this.historicosEnviosIdentificador.find(x => x.id == rowId);

    if (!envio || envio.cantidad == 0) {
      this.myGrid.hiderowdetails(event.args.rowindex);
    } else {
      let id = this.myGrid.getrowdata(event.args.rowindex).id;
      if (!(this.showRowDetails.includes(id))) {
        this.showRowDetails.push(id);
      }
    }
  }

  rowCollapse(event: any) {
    let id = this.myGrid.getrowdata(event.args.rowindex).id;
    this.showRowDetails.splice(this.showRowDetails.indexOf(id), 1);
  }

  sortedColumn: any;

  customsortfunc = (column: any, direction: string | boolean): void => {
    let sortdata = new Array();
    if (direction == 'ascending') direction = true;
    if (direction == 'descending') direction = false;
    if (direction != null) {
      for (let i = 0; i < this.historicosEnviosIdentificador.length; i++) {
        sortdata.push(this.historicosEnviosIdentificador[i]);
      }
    } else {
      sortdata = this.historicosEnviosIdentificador;
    }
    this.sortedColumn = column;

    let tmpToString = Object.prototype.toString;
    Object.prototype.toString = (typeof column == 'function') ? column : () => { return this[column] };
    if (direction != null) {
      sortdata.sort(this.compare);
      if (!direction) {
        sortdata.reverse();
      }
    }
    this.source.localdata = sortdata;
    this.myGrid.updatebounddata('sort');
    Object.prototype.toString = tmpToString;
  }

  compare = (value1: any, value2: any): any => {
    if (this.sortedColumn === 'fechaEnvio') {
      value1 = new Date(value1[this.sortedColumn]).getTime();
      value2 = new Date(value2[this.sortedColumn]).getTime();
    } else {
      value1 = value1[this.sortedColumn];
      value2 = value2[this.sortedColumn];
    }

    try {
      let tmpvalue1 = parseFloat(value1);
      if (isNaN(tmpvalue1)) {
        if (value1 < value2) { return -1; }
        if (value1 > value2) { return 1; }
      }
      else {
        let tmpvalue2 = parseFloat(value2);
        if (tmpvalue1 < tmpvalue2) { return -1; }
        if (tmpvalue1 > tmpvalue2) { return 1; }
      }
    } catch (error) {
    }
    return 0;
  };

  resizeGridDetails(grid: jqxGridComponent) {
    if (grid) {
      grid.setOptions({
        columnsheight: 20,
        rowsheight: 20,
        statusbarheight: 20,
        columnsresize: true,
      });
      grid.refresh();
      grid.showrowdetails(this.editrow);
    }
  }
}
