<jqxWindow #windowForm [width]="1400" [height]="400" [zIndex]="900" [theme]="environment.tema"
  [isModal]="false" [showCloseButton]="true" [autoOpen]="true" [showCollapseButton]="true"
  [resizable]="true" (onExpand)="onExpand($event)" (onClose)="onClose()">
  <div class="formHeader">
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <jqxRibbon #direcRibbon [theme]="environment.tema" [position]="'top'" [height]="'calc(100% - 50px)'">
      <ul style="width: 100%; text-align: left; z-index: 99; background-color: rgb(225, 229, 231);">
        <li>
          {{ translate('Direccion') }}
        </li>
        <li>
          {{ translate('Tarjetas') }}
        </li>
      </ul>

      <div style="margin-top: 10px;">
        <div style="background-color: transparent; padding: 0%; overflow-y: auto;">
          <div #fieldsetCartografica id="fieldsetCartografica">
            <fieldset>
              <legend>{{ translate('Direccion_cartografica') }}</legend>

              <div class="row">
                <span style="width: 25%;">
                  <div style="clear: both">
                    {{ translate('Buscar') }}
                  </div>
                  <span #mapSearch>
                    <movisat-map-search style="float: left; width: calc(100% - 30px);" (mapSearch)="onMapSearch($event)">
                    </movisat-map-search>
                    <jqxButton class="button" style="float: left; margin-left: 5px; cursor: pointer;
                      display: flex; align-items: center; justify-content: center;" [width]="25"
                      [height]="25"  [title]="translate('Selecciona_una_ubicacion')"
                      (onClick)="onPosicionar()">
                      <img src="/assets/images/posicion.png" style="height: 20px; width: 20px;">
                    </jqxButton>
                  </span>
                </span>

                <span style="width: 20%;">
                  <div style="clear: both">
                    {{ translate('Domicilio') }}
                  </div>
                  <jqxInput [value]="direccion.direccion" [width]="'100%'" [disabled]="true">
                  </jqxInput>
                </span>

                <span style="width: 10%;">
                  <div style="clear: both">
                    {{ translate('Numero') }}
                  </div>
                  <jqxInput [value]="direccion.numero" [rtl]="true" [width]="'100%'" [disabled]="true">
                  </jqxInput>
                </span>

                <span style="width: 10%;">
                  <div style="clear: both">
                    {{ translate('Codigo_postal') }}
                  </div>
                  <jqxInput [value]="direccion.codigoPostal" [rtl]="true" [width]="'100%'" [disabled]="true">
                  </jqxInput>
                </span>

                <span style="width: 5%;">
                  <div style="clear: both">
                    {{ translate('Planta') }}
                  </div>
                  <jqxInput [(ngModel)]="direccion.planta" [rtl]="true" [width]="'100%'">
                  </jqxInput>
                </span>

                <span style="width: 5%;">
                  <div style="clear: both">
                    {{ translate('Puerta') }}
                  </div>
                  <jqxInput [(ngModel)]="direccion.puerta" [rtl]="true" [width]="'100%'">
                  </jqxInput>
                </span>

                <span style="width: 25%;">
                  <div style="clear: both">
                    {{ translate('Poblacion') }}
                  </div>
                  <jqxInput [value]="direccion.poblacion" [width]="'100%'" [disabled]="true">
                  </jqxInput>
                </span>
              </div>
              <div class="row">
                <span style="width: 25%;">
                  <div style="clear: both">
                    {{ translate('Municipio') }}
                  </div>
                  <jqxInput [value]="direccion.municipio" [width]="'100%'" [disabled]="true">
                  </jqxInput>
                </span>
                <span style="width: 25%;">
                  <div style="clear: both;">
                    {{ translate('Provincia') }}
                  </div>
                  <jqxInput [value]="direccion.provincia" [width]="'100%'" [disabled]="true">
                  </jqxInput>
                </span>
                <span style="width: 25%;">
                  <div style="clear: both;">
                    {{ translate('Comunidad_autonoma') }}
                  </div>
                  <jqxInput [value]="direccion.comunidadAutonoma" [width]="'100%'" [disabled]="true">
                  </jqxInput>
                </span>
                <span style="width: 8%;">
                  <div style="clear: both;">
                    {{ translate('Num_abonado') }}
                  </div>
                  <jqxInput [ngModel]="direccion?.numeroAbonado" (ngModelChange)="direccion.numeroAbonado = $event" [width]="'100%'" [disabled]="false">
                  </jqxInput>
                </span>
              </div>
            </fieldset>
          </div>

          <div #fieldsetCatastro id="fieldsetCatastro">
            <fieldset>
              <legend>{{ translate('Catastro') }}</legend>
              <app-selector-catastro #selectorCatastro [direccion]="direccion"></app-selector-catastro>
            </fieldset>
          </div>
        </div>

        <div style="background-color: transparent; padding: 0%; overflow-y: hidden !important;">
          <div style="height: 100%;">
            <jqxGrid #gridTarjetas [theme]="environment.tema" [width]="'99%'" [height]="'100%'" [source]="dataAdapterTarjetas"
              [columnsresize]="true" [filterable]="true" [showfilterrow]="true" [columns]="columnsTarjetas" [sortable]="true"
              [altrows]="true" [showrowlines]="false" [filterrowheight]="30" [rowsheight]="25" [columnsheight]="25"
              [enabletooltips]="true" [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20"
              [localization]="langGrid" [filter]="filter" [updatefilterconditions]="updatefilterconditions">
            </jqxGrid>
          </div>
        </div>
      </div>
    </jqxRibbon>

    <div class="row" style="align-items: end;">
      <jqxButton style="float:left; margin-top: 10px;" [width]='100' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"'
        [value]="translate('Guardar')" (onClick)="onGuardar()">
      </jqxButton>

      <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        (onClick)="closeWindow()" [value]="translate('CANCELAR')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
