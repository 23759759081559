export class EntradaModel {
    Id: number = 0;
    ZonaId: number = 0;
    Nombre: String = '';
    Tipo: number = 1; //1= entrada, 2= salida, 3= entrada y salida
    Observaciones: String = '';
    Direccion: String = '';
    FechaCreacion: Date;
    FechaModificacion: Date;
    FechaBaja: Date;
    Lat: number = 0;
    Lng: number = 0;

    //agregados
    Poblacion: String = '';
    Municipio: String = '';
    Provincia: String = '';
}
