<div class="loader-container" *ngIf="showLoader">
    <div class=" loader"></div>
</div>
<jqxRibbon #ciudRibbon [theme]="theme" [position]="'top'" [height]="'99%'" (onSelect)="onSelect($event)">
    <ul style="width: 100%; text-align: left; z-index: 99; background-color: rgb(225, 229, 231);">
        <li> {{ titleIdentificaciones }} ({{ countPartialResultsIdentificaciones() }}) </li>
        <li> {{ titleAlarmas }} ({{ countPartialResultsAlarmas() }}) </li>
        <li> {{ titleEnvios }} ({{ countPartialResultsEnvios() }}) </li>
    </ul>
    <div>
        <div class="inner-container">
            <div class="header-container">
                <app-header #headerIdentificaciones [exportar]="true" [imprimir]="true" [buscador]="true"
                    [filtro]="true" [resetFiltro]="true" (eventExportar)="onExportar('identificacion', $event)"
                    [periodo]="true" (eventImprimir)="onPrint('identificacion', $event)"
                    (eventBuscador)="onBuscar('identificacion', $event)"
                    (eventFilter)="eventFilter('identificacion', $event)"
                    (eventResetFilter)="eventResetFilter('identificacion', $event)">
                </app-header>
            </div>
            <div class="tables-wrapper">
                <jqxGrid #grid [theme]="theme" [width]="'100%'" [height]="'calc(100% - 5px)'"
                    [source]="dataAdapterIdentificaciones" [columnsresize]="true" [appCellClick] [sortable]="true"
                    [showrowlines]="false" [showcolumnlines]="true" [columns]="columns" [altrows]="true"
                    [enablebrowserselection]="true" [filterable]="true" [rowsheight]="25" [localization]="langGrid"
                    [groupable]="true" [columnmenuopening]='columnmenuopening' [groupsrenderer]="groupsrenderer"
                    (onBindingcomplete)="onBindingcompleteIdentificaciones($event)" [filter]="filter"
                    [groupsheaderheight]="25" [updatefilterconditions]="updatefilterconditions"
                    (onFilter)="onfilter($event)" [columnsheight]="20">
                </jqxGrid>
            </div>
        </div>

        <div class="inner-container">
            <div class="header-container">
                <app-header #headerAlarmas [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true"
                    [resetFiltro]="true" (eventExportar)="onExportar('alarmas', $event)" [periodo]="true"
                    (eventImprimir)="onPrint('alarmas', $event)" (eventBuscador)="onBuscar('alarmas', $event)"
                    (eventFilter)="eventFilter('alarmas', $event)"
                    (eventResetFilter)="eventResetFilter('alarmas', $event)">
                </app-header>
            </div>

            <div class="tables-wrapper">
                <jqxGrid #gridAlarmas [theme]="theme" [width]="'100%'" [height]="'calc(100% - 5px)'"
                    [source]="dataAdapteAlarmas" [columnsresize]="true" [appCellClick] [sortable]="true"
                    [showrowlines]="false" [showcolumnlines]="true" [columns]="columnsAlarmas"
                    [columnmenuopening]='columnmenuopening' [altrows]="true" [filterable]="true"
                    [enablebrowserselection]="true" [localization]="langGrid" [groupable]="true"
                    (onFilter)="onfilterAlarmas($event)" [groupsheaderheight]="25"
                    (onBindingcomplete)="onBindingcompleteAlarmas($event)" (onGroupschanged)="resizeColumnsAlarmas()"
                    [columnsheight]="20" [rowsheight]="25" [filter]="filterAlarmas"
                    [updatefilterconditions]="updatefilterconditions">
                </jqxGrid>
            </div>
        </div>

        <div class="inner-container">
            <div class="header-container">
                <app-header #headerEnvios [exportar]="true" [imprimir]="true" [buscador]="true" [filtro]="true"
                    [resetFiltro]="true" (eventExportar)="onExportar('envios', $event)" [periodo]="true"
                    (eventImprimir)="onPrint('envios', $event)" (eventBuscador)="onBuscar('envios', $event)"
                    (eventFilter)="eventFilter('envios', $event)"
                    (eventResetFilter)="eventResetFilter('envios', $event)">
                </app-header>
            </div>
            <div class="tables-wrapper">
                <jqxGrid #myGridEnvios [theme]="theme" [width]="'100%'" [height]="'calc(100% - 5px)'"
                    [source]="dataAdapterEnvios" [appCellClick] [enablebrowserselection]="true" [columnsresize]="true"
                    [sortable]="true" [showrowlines]="false" [showcolumnlines]="true" [columns]="columnsEnvios"
                    [altrows]="true" [filterable]="true" [rowdetails]="true" [rowsheight]="20" [columnsheight]="20"
                    [localization]="langGrid" [groupable]="true" [rowdetailstemplate]="rowdetailstemplate"
                    [filter]="filter" [initrowdetails]="initRowDetails"
                    [updatefilterconditions]="updatefilterconditions" [groupsheaderheight]="25"
                    (onRowexpand)="rowExpand($event)" (onRowcollapse)="rowCollapse($event)"
                    (onBindingcomplete)="onBindingcompleteEnvios($event)" (onSort)="onSort($event)"
                    [columnmenuopening]='columnmenuopening'>
                </jqxGrid>
            </div>
        </div>
    </div>
</jqxRibbon>