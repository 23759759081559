<jqxWindow jqxWindowAutoResize #form [theme]="theme" [zIndex]="900" [autoOpen]="true" [showCloseButton]="true"
  [showCollapseButton]="true" [resizable]="true" [title]="titleWindowSignal()" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;"></div>
  <div class="form" style="float:left; overflow-x: hidden; ">
    <div>
      <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" [periodo]="true" [informesSso]="true"
        [filtro]="true" [resetFiltro]="true" [noLibre]="true" (eventBuscador)="onBuscar()" (eventFilter)="onFilter()"
        (eventResetFilter)="eventResetFilter()" (informeSearchChange)="onSearchAccion($event)"
        (eventExportar)="onExportar()" (eventImprimir)="onPrint($event)">
      </app-header>
    </div>

    <div
      style="float: left; margin-top: 4px;width: 100%;height:calc(100% - 42px);display: flex;flex-direction: column;justify-content: space-between;">
      <div style="height: 100%;">
        <jqxGrid #myGrid style="margin: auto; margin-top: 10px" [theme]="theme" [width]="'100%'" [height]="'99%'"
          [groupsrenderer]="groupsrenderer" [altrows]="true" [showrowlines]="false" [showcolumnlines]="true"
          [columnmenuopening]='columnmenuopening' [columns]="columns" [columnsautoresize]="true" [sortable]="true"
          (onBindingcomplete)="onGridDataUpdate()" (onFilter)="onGridDataUpdate()" [editable]="false"
          [columnsresize]="true" [filter]="filter" [groupsheaderheight]="25" [filterable]="true" [source]="dataAdapter"
          [localization]="langGrid" [groupable]="true" [autoshowfiltericon]="true" [filterrowheight]="20"
          [rowsheight]="32" [columnsheight]="25" [toolbarheight]="30" [sortable]="true"
          [updatefilterconditions]="updatefilterconditions">
        </jqxGrid>
      </div>
      <div>

        <jqxButton style="float: left; margin-left: 5px; margin-top: 10px;" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="closeWindow()"
          [value]="translate('Salir')">
        </jqxButton>
      </div>
    </div>
  </div>
</jqxWindow>