import { MapModule } from 'movisat-maps';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { MovilesSelectModule } from '../moviles-select/moviles-select.module';
import { PeriodoSelectModule } from '../periodo-select/periodo-select.module';
import { TotalVolumComponent } from './volum/total-volum/total-volum.component';
import { ElementosSelectModule } from '../elementos-select/elementos-select.module';
import { ListadoVolumComponent } from './volum/listado-volum/listado-volum.component';
import { ResumenVolumComponent } from './volum/resumen-volum/resumen-volum.component';
import { ListadoSensorComponent } from './sensor/listado-sensor/listado-sensor.component';
import { ListadoAlarmasComponent } from './alarmas/listado-alarmas/listado-alarmas.component';
import { ListadoAlarmaComponent } from './cerraduras/listado-alarma/listado-alarma.component';
import { ListadoMovilidadComponent } from './movilidad/listado-movilidad/listado-movilidad.component';
import { ListadoCerraduraComponent } from './cerraduras/listado-cerradura/listado-cerradura.component';
import { ListadoIdentificacionComponent } from './cerraduras/listado-identificacion/listado-identificacion.component';
import { HistoricoEnviosDispIdentificadorComponent } from './historico-envios-disp-identificador/historico-envios-disp-identificador.component';
import { JqxSubTabsModule } from './cerraduras/listado-cerradura/jqx-sub-tabs/jqx-sub-tabs.module';
import { LoaderComponent } from 'movisat-common-gui';

@NgModule({
  declarations: [
    TotalVolumComponent,
    ListadoVolumComponent,
    ResumenVolumComponent,
    ListadoSensorComponent,
    ListadoAlarmaComponent,
    ListadoAlarmasComponent,
    ListadoMovilidadComponent,
    ListadoCerraduraComponent,
    ListadoIdentificacionComponent,
    HistoricoEnviosDispIdentificadorComponent,
  ],
  imports: [
    MapModule,
    MapModule,
    FormsModule,
    CommonModule,
    SharedModule,
    PeriodoSelectModule,
    MovilesSelectModule,
    ElementosSelectModule,
    JqxSubTabsModule,
    LoaderComponent
    

]
})
export class ReportsModule { }
