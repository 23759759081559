<jqxDropDownList #cbPeriodo [width]="110" [theme]="theme"
    [selectedIndex]="0" [autoDropDownHeight]="true" (onSelect)="onSelectPeriodo($event)">
</jqxDropDownList>
<form [formGroup]="dateForm" autocomplete="off">
  <mat-label>{{ translate('Desde') }}</mat-label>
  <mat-form-field>
    <input #inputFecha1 matInput [matDatepicker]="fecha1" (dateChange)="change($event, 'desde')" (click)="fecha1.open()" formControlName="desde">
    <mat-datepicker-toggle [for]="fecha1"></mat-datepicker-toggle>
    <mat-datepicker #fecha1 (yearSelected)="onYearSelected($event, fecha1)" (monthSelected)="onMonthSelected($event, fecha1)"></mat-datepicker>
  </mat-form-field>
  <mat-label>{{ translate('Hasta') }}</mat-label>
  <mat-form-field>
    <input #inputFecha2 matInput [matDatepicker]="fecha2" (dateChange)="change($event, 'hasta')" formControlName="hasta">
    <mat-datepicker-toggle [for]="fecha2"></mat-datepicker-toggle>
    <mat-datepicker #fecha2 [disabled]="periodoSelec !== 6"></mat-datepicker>
  </mat-form-field>
</form>
