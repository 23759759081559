import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';

import {AppComponent} from 'src/app/app.component';
import {CustomForms} from '../../forms/custom-forms';
import {environment} from 'src/environments/environment';
import {MainComponent} from 'src/app/components/main/main.component';

import {MapBounds, MapComponent, MapLatLng, MapMarker, MapPolygon} from 'movisat-maps';

import {jqxWindowComponent} from 'jqwidgets-ng/jqxwindow';

import {SsoService} from 'src/app/services/sso/sso.service';
import {ConfigService} from 'src/app/services/config/config.service';
import {AuditoriaService} from 'src/app/services/auditoria/auditoria.service';

import {Accion} from 'src/app/services/auditoria/models/accion.model';
import {AuditoriaModel} from 'src/app/services/auditoria/models/auditoria.model';
import {MarcoGeograficoModel} from 'src/app/services/geographics/marco-geografico.model';

@Component({
  selector: 'app-marco',
  templateUrl: './marco.component.html',
  styleUrls: ['./marco.component.css']
})
export class MarcoComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;

  public static _this: MarcoComponent;

  private componentRef = null;
  public environment = environment;
  public visible = false;
  public canEdit = false;
  private map: MapComponent;
  private markerSw: MapMarker;
  private markerNw: MapMarker;
  private markerNe: MapMarker;
  private markerSe: MapMarker;
  private polygon: MapPolygon;
  private oldMarco: MarcoGeograficoModel;
  private subscriptionOnMarkerMove = null;
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  visibleAmbito: any;
  polygonMarco: MapPolygon;

  constructor(private configService: ConfigService, private auditoriaService: AuditoriaService, private ssoService: SsoService) {
    super();
    MarcoComponent._this = this;
  }

  ngOnInit(): void {
    this.canEdit = true; // TODO: por hacer...
  }

  async ngAfterViewInit() {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Marco_geografico'));
    // Posiciono el formulario
    const mapContainer = document.getElementById('center-container').getClientRects();
    this.form.position({
      x: mapContainer[0].left + 2,
      y: mapContainer[0].top + 60
    });
    this.map = MainComponent.getActiveMap();
    // Me subscribo a los movimientos de los marcadores
    this.subscriptionOnMarkerMove = this.map.subscribeOnMarkerMove(this, (_this: any, marker: MapMarker) => {
      if (marker === this.markerSw) {
        this.markerNw.setPosition(new MapLatLng(this.markerNw.position.lat, marker.position.lng));
        this.markerSe.setPosition(new MapLatLng(marker.position.lat, this.markerSe.position.lng));
      }
      if (marker === this.markerNw) {
        this.markerSw.setPosition(new MapLatLng(this.markerSw.position.lat, marker.position.lng));
        this.markerNe.setPosition(new MapLatLng(marker.position.lat, this.markerNe.position.lng));
      }
      if (marker === this.markerNe) {
        this.markerNw.setPosition(new MapLatLng(marker.position.lat, this.markerNw.position.lng));
        this.markerSe.setPosition(new MapLatLng(this.markerSe.position.lat, marker.position.lng));
      }
      if (marker === this.markerSe) {
        this.markerSw.setPosition(new MapLatLng(marker.position.lat, this.markerSw.position.lng));
        this.markerNe.setPosition(new MapLatLng(this.markerNe.position.lat, marker.position.lng));
      }
      if (this.polygon) {
        this.map.removePolygon(this.polygon);
      }
      this.polygon = this.map.addPolygon({
        strokeColor: '#ff0000',
        strokeOpacity: 0.3,
        strokeWeight: 4,
        fillColor: '#000000',
        fillOpacity: 0
      });
      this.map.addPolygonPoint(this.polygon, {
        dataModel: this,
        content: '',
        position: this.markerSw.position
      });
      this.map.addPolygonPoint(this.polygon, {
        dataModel: this,
        content: '',
        position: new MapLatLng(this.markerNe.position.lat, this.markerSw.position.lng)
      });
      this.map.addPolygonPoint(this.polygon, {
        dataModel: this,
        content: '',
        position: this.markerNe.position
      });
      this.map.addPolygonPoint(this.polygon, {
        dataModel: this,
        content: '',
        position: new MapLatLng(this.markerSw.position.lat, this.markerNe.position.lng)
      });
    });
    this.visible = await this.configService.getEmpApp('ver-marco-geografico', 'false') === 'true';
    // Si no está visible el marco fuerzo para que se cargue
    if (!this.visible) {
      MainComponent.getInstance().showMarcoGeografico(false);
    }
    this.map.fitTo(MainComponent.getInstance().marcoGeografico.marco);
    this.oldMarco = {...MainComponent.getInstance().marcoGeografico};
  }

  // Cuando se cierra
  async onClose() {
    if (this.subscriptionOnMarkerMove) {
      this.subscriptionOnMarkerMove.unsubscribe();
    }
    if (this.polygon) {
      this.map.removePolygon(this.polygon);
    }
    if (this.markerSw) {
      this.map.removeMarker(this.markerSw);
    }
    if (this.markerNe) {
      this.map.removeMarker(this.markerNe);
    }
    if (this.markerNw) {
      this.map.removeMarker(this.markerNw);
    }
    if (this.markerSe) {
      this.map.removeMarker(this.markerSe);
    }

    MainComponent.getInstance().loadMarcoGeografico();
    MainComponent.getInstance().hideAmbito();
    // if (await this.configService.getEmpApp('ver-marco-geografico', 'false') === 'true') {
    //   MainComponent.getInstance().loadMarcoGeografico();
    // } else {
    //   MainComponent.getInstance().hideMarcoCartografico();
    // }
    if (this.componentRef) {
      this.componentRef.destroy();
      this.form.close();
    }

    MarcoComponent._this = null;
  }

  // Inicialización del componente
  async init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  onChangeVisible(event: any) {
    this.visible = event.currentTarget.checked;
  }

  onVerAmbito(event: any) {

    this.visibleAmbito = event.currentTarget.checked;
    if (this.visibleAmbito) {
      let marco = MainComponent.getInstance().ambitoActividad;
      MainComponent.getInstance().verMarcoAmbito = true;
      MainComponent.getInstance().showMarcoAmbito(marco);
    } else {
      MainComponent.getInstance().hideAmbito();
    }
  }

  onDefinir(event: any) {
    // this.form.collapse();
    if (!this.polygon) {
      this.map.fitTo(MainComponent.getInstance().marcoGeografico.marco);
      // Lo pongo a null para que no se limite el movimiento de la catografía
      MainComponent.getInstance().marcoGeografico = null;
      if (this.polygon) {
        this.map.removePolygon(this.polygon);
        this.polygon = null;
      }
      let marco = MainComponent.getInstance().marcoCartoPolygon;
      if (marco) {
        let lat1 = 90, lat2 = -90, lng1 = 180, lng2 = -180;
        this.polygon = this.map.addPolygon({
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 4,
          fillColor: '#000000',
          fillOpacity: 0
        });
        marco.points.forEach(point => {
          this.map.addPolygonPoint(this.polygon, {
            dataModel: this,
            content: '',
            position: point.point
          });
          if (point.point.lat < lat1) {
            lat1 = point.point.lat;
          }
          if (point.point.lng < lng1) {
            lng1 = point.point.lng;
          }
          if (point.point.lat > lat2) {
            lat2 = point.point.lat;
          }
          if (point.point.lng > lng2) {
            lng2 = point.point.lng;
          }
        });
        this.markerSw = this.map.addMarker({
          dataModel: this,
          title: '',
          content: '',
          position: new MapLatLng(lat1, lng1),
          icon: '/assets/images/angulo-sw.png',
          zIndex: 999,
          drag: true,
          visible: true
        });
        this.markerNw = this.map.addMarker({
          dataModel: this,
          title: '',
          content: '',
          position: new MapLatLng(lat2, lng1),
          icon: '/assets/images/angulo-nw.png',
          zIndex: 999,
          drag: true,
          visible: true
        });
        this.markerNe = this.map.addMarker({
          dataModel: this,
          title: '',
          content: '',
          position: new MapLatLng(lat2, lng2),
          icon: '/assets/images/angulo-ne.png',
          zIndex: 999,
          drag: true,
          visible: true
        });
        this.markerSe = this.map.addMarker({
          dataModel: this,
          title: '',
          content: '',
          position: new MapLatLng(lat1, lng2),
          icon: '/assets/images/angulo-se.png',
          zIndex: 999,
          drag: true,
          visible: true
        });
      }
      MainComponent.getInstance().hideMarcoCartografico(false);
    }
  }


  clearMapFeatures() {
    if (this.polygonMarco) {
      this.map.removePolygon(this.polygonMarco);
      this.polygonMarco = null;
    }

    if (this.polygon) {
      this.map.removePolygon(this.polygon);
      this.polygon = null;
    }

    ['markerSw', 'markerNe', 'markerNw', 'markerSe'].forEach(marker => {
      if (this[marker]) {
        this.map.removeMarker(this[marker]);
        this[marker] = null;
      }
    });
  }

  drawGeographicBoundary(marcoGeografico) {
    this.polygonMarco = this.map.addPolygon({
      strokeColor: '#ff0000',
      strokeOpacity: 0.3,
      strokeWeight: 4,
      fillColor: '#000000',
      fillOpacity: 0
    });
    // Add points to the polygon
    this.map.addPolygonPoint(this.polygonMarco, {
      dataModel: this,
      content: '',
      position: marcoGeografico.marco.swCorner
    });

    this.map.addPolygonPoint(this.polygonMarco, {
      dataModel: this,
      content: '',
      position: new MapLatLng(marcoGeografico.marco.neCorner.lat, marcoGeografico.marco.swCorner.lng)
    });

    this.map.addPolygonPoint(this.polygonMarco, {
      dataModel: this,
      content: '',
      position: marcoGeografico.marco.neCorner
    });

    this.map.addPolygonPoint(this.polygonMarco, {
      dataModel: this,
      content: '',
      position: new MapLatLng(marcoGeografico.marco.swCorner.lat, marcoGeografico.marco.neCorner.lng)
    });
  }

  addMarkers(bound) {
    // Logic to add markers
    if (this.polygon) {
      this.map.removePolygon(this.polygon);
      this.polygon = null;
    }
    let lat1 = 90, lat2 = -90, lng1 = 180, lng2 = -180;
    this.polygon = this.map.addPolygon({
      strokeColor: '#ff0000',
      strokeOpacity: 0.3,
      strokeWeight: 4,
      fillColor: '#000000',
      fillOpacity: 0
    });
    this.polygonMarco.points.forEach(point => {
      this.map.addPolygonPoint(this.polygon, {
        dataModel: this,
        content: '',
        position: point.point
      });
      if (point.point.lat < lat1) {
        lat1 = point.point.lat;
      }
      if (point.point.lng < lng1) {
        lng1 = point.point.lng;
      }
      if (point.point.lat > lat2) {
        lat2 = point.point.lat;
      }
      if (point.point.lng > lng2) {
        lng2 = point.point.lng;
      }
    });
    this.markerSw = this.map.addMarker({
      dataModel: this,
      title: '',
      content: '',
      position: new MapLatLng(lat1, lng1),
      icon: '/assets/images/angulo-sw.png',
      zIndex: 999,
      drag: true,
      visible: true
    });
    this.markerNw = this.map.addMarker({
      dataModel: this,
      title: '',
      content: '',
      position: new MapLatLng(lat2, lng1),
      icon: '/assets/images/angulo-nw.png',
      zIndex: 999,
      drag: true,
      visible: true
    });
    this.markerNe = this.map.addMarker({
      dataModel: this,
      title: '',
      content: '',
      position: new MapLatLng(lat2, lng2),
      icon: '/assets/images/angulo-ne.png',
      zIndex: 999,
      drag: true,
      visible: true
    });
    this.markerSe = this.map.addMarker({
      dataModel: this,
      title: '',
      content: '',
      position: new MapLatLng(lat1, lng2),
      icon: '/assets/images/angulo-se.png',
      zIndex: 999,
      drag: true,
      visible: true
    });

    if (this.polygonMarco) {
      this.map.removePolygon(this.polygonMarco);
      this.polygonMarco = null;
    }
  }


  async onGuardar(event: any) {
    // Al cerrar se almacena si está visible o no el marco
    await this.configService.setEmpApp('ver-marco-geografico', this.visible ? 'true' : 'false');
    if (this.markerSw && this.markerNe) {
      const ambito = MainComponent.getInstance().ambitoActividad.marco;

      const marcoCartografico = new MarcoGeograficoModel(new MapBounds(this.markerSw.position, this.markerNe.position));

      const isContained = marcoCartografico.marco.swCorner.lat >= ambito.swCorner.lat &&
        marcoCartografico.marco.swCorner.lng >= ambito.swCorner.lng &&
        marcoCartografico.marco.neCorner.lat <= ambito.neCorner.lat &&
        marcoCartografico.marco.neCorner.lng <= ambito.neCorner.lng;

      const notEqual = this.oldMarco.marco.swCorner.lat > ambito.swCorner.lat ||
        this.oldMarco.marco.swCorner.lng > ambito.swCorner.lng ||
        this.oldMarco.marco.neCorner.lat < ambito.neCorner.lat ||
        this.oldMarco.marco.neCorner.lng < ambito.neCorner.lng;

      if (isContained || notEqual) {


        const marcoGeo = await this.configService.getEmpApp('marco-geografico', null);
        if (!marcoGeo) {
          return;
        }
        this.clearMapFeatures();
        const mc = JSON.parse(marcoGeo);
        const bound = new MapBounds(mc.marco.swCorner, mc.marco.neCorner);
        let marcoGeografico = new MarcoGeograficoModel(bound);
        this.drawGeographicBoundary(marcoGeografico);
        this.addMarkers(bound);

        return MainComponent.showWarning('ATENCION', 'Error_marco', 2000);
      } else {
        await this.configService.setEmpApp('marco-geografico', JSON.stringify(marcoCartografico));
        this.map.fitTo(marcoCartografico.marco);
        // Compruebo si ha cambiado el marco
        if (this.oldMarco.marco.swCorner.lat !== marcoCartografico.marco.swCorner.lat ||
          this.oldMarco.marco.swCorner.lng !== marcoCartografico.marco.swCorner.lng ||
          this.oldMarco.marco.neCorner.lat !== marcoCartografico.marco.neCorner.lat ||
          this.oldMarco.marco.neCorner.lng !== marcoCartografico.marco.neCorner.lng) {
          window.location.reload();
          this.auditoria.AccionId = Accion.CREAR_MARCO_GEOGRAFICO;
          this.auditoriaService.addAuditoria(this.auditoria);
          MainComponent.getInstance().hideAmbito();
        }
      }

    }
    this.form.close();

  }

}
