import { Component, OnInit, Renderer2, ViewChild, ViewContainerRef, ComponentRef } from '@angular/core';


import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { ResourcesService } from 'src/app/services/resources/resources.service';

import { MovilEditComponent } from '../moviles/movil-edit/movil-edit.component';
import { DateUtils } from 'src/app/utils/date-utils';
import * as xlsx from 'xlsx';
import { HeaderComponent } from '../../header/header.component';
import { ConjuntoVehiculo } from 'src/app/services/conjuntoVehiculo/models/conjunto-vehiculo.model';
import { ConjuntoVehiculoService } from 'src/app/services/conjuntoVehiculo/conjunto-vehiculo.service';
import { BehaviorSubject } from 'rxjs';


@Component({
  selector: 'app-moviles-catalog',
  templateUrl: './moviles-catalog.component.html',
  styleUrls: ['./moviles-catalog.component.css']
})
export class MovilesCatalogComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('formMovil', { read: ViewContainerRef }) editMovilComponent;
  @ViewChild('header') header: HeaderComponent;

  public static _this: MovilesCatalogComponent;
  private componentRef = null;
  public environment = environment;
  public canEdit = true;
  private select: any = [];
  private fromIAConfig = false;
  formMovil: any;
  public movilSelect: ConjuntoVehiculo;
  selectRow: any;

  // Variables grid
  public columngroups;
  public columns: any[] = [];
  public source;
  public dataAdapter;

  protected conjuntoVehiculos: ConjuntoVehiculo[] = [];

  mapWidth: number;
  mapHeight: number;

  intervalScroll: any;
  movilesData$: BehaviorSubject<{ movilesLength: number, totalUnidades: number }> = new BehaviorSubject<{ movilesLength: number, totalUnidades: number }>({ movilesLength: 0, totalUnidades: 0 });
  private openEditComponents: Map<number, MovilEditComponent> = new Map();


  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');
  showLoader: boolean = true;

  constructor(private ssoService: SsoService,
    private configService: ConfigService,
    private resourcesService: ResourcesService,
    private conjuntoVehiculoService: ConjuntoVehiculoService,) {
    super();
    MovilesCatalogComponent._this = this;
  }

  async ngOnInit() {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.canEdit = true; // TODO: por hacer...
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    setTimeout(async () => {
      this.initGrid();
      this.getMoviles();
    }, 1000);
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);

    if (!this.fromIAConfig) {
      this.form.setTitle(AppComponent.translate('Catalogo_flota'));
    } else {
      this.form.setTitle(AppComponent.translate('Catalogo_flota') + ' - ' + AppComponent.translate('IA'));
    }
    // Recupero el filtro guardado
    if (!this.fromIAConfig) {
      this.select = await this.configService.getUsuEmpApp('moviles-visibles', null);
    } else {
      this.select = await this.configService.getEmp('moviles-IA', null);
    }
    if (this.select) {
      this.select = JSON.parse(this.select);
    } else {
      this.select = [];
    }
  }

  updateMovilesLength(movilesLength: number, totalUnidades: number) {
    // Emitir ambos valores juntos como un objeto
    this.movilesData$.next({ movilesLength, totalUnidades });

    // Actualizar el título (sin cambios)
    this.form.setTitle(
      AppComponent.translate('Catalogo_flota') + ' (' + movilesLength + ') - (' + totalUnidades + ')'
    );
  }


  // Este método es llamado por el creador del componente
  public init(componentRef: any, fromIAConfig: boolean) {
    this.componentRef = componentRef;
    this.fromIAConfig = fromIAConfig;
  }

  // Cierro el formulario y destruyo el componente
  public async onClose() {
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }

    if (this.intervalScroll) {
      clearInterval(this.intervalScroll);
    }

    MovilesCatalogComponent._this = null;
  }

  // Guardo los filtros y destruyo el componente
  public async onGuardar() {
    // Guardo la configuración
    const modelos: any[] = [];
    const rowsSelec = this.grid.getselectedrowindexes();
    if (rowsSelec) {
      rowsSelec.forEach(i => {
        this.conjuntoVehiculos[i].vehiculos.forEach(vehiculo => {
          modelos.push({ id: vehiculo.idLicencia });
        });
        //modelos.push({ id: this.movilesList[i].Codigo });
      });
    }
    // Guardo la variable de configuración con los datos del filtro
    if (!this.fromIAConfig) {
      await this.configService.setUsuEmpApp('moviles-visibles', JSON.stringify(modelos));
    } else {
      await this.configService.setEmp('moviles-IA', JSON.stringify(modelos));
    }
    // Mando actualizar el filtro para que se repinten los móviles
    this.resourcesService.setFilterVisible();
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  onBindingComplete() {
    const rows = this.grid.getrows();
    if (rows) {
      rows.forEach(row => {
        if (this.select.find(s => s.id === row.visible) !== undefined) {
          this.grid.selectrow(row.boundindex);
        }
      });
    }
  }

  closeWindow() {
    this.form.close();
  }

  initGrid() {



    this.columngroups = [
      { text: AppComponent.translate('Chasis'), align: 'center', name: 'chasisGroup' },
      { text: AppComponent.translate('Carrozado'), align: 'center', name: 'carrozadoGroup' },
      { text: AppComponent.translate('Pesos_medidas'), align: 'center', name: 'pesosMedidasGroup' },
      { text: AppComponent.translate('Combustibles_gases'), align: 'center', name: 'combustibleGroup' },
      { text: AppComponent.translate('Datos_economicos'), align: 'center', name: 'economiaGroup' },
    ]

    this.columns = [
      { text: '', columntype: 'textbox', filtertype: 'textbox', datafield: 'visible', hidden: true },
      { text: '', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true },
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
      {
        text: this.translate('Acciones'),
        width: 50,
        columntype: 'text',
        sortable: false,
        editable: false,
        datafield: 'acciones',
        groupable: false,
        menu: false,
        rendered: (columnHeaderElement) => {
          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        }
      },
      { text: AppComponent.translate('Unidades'), columntype: 'textbox', filtertype: 'number', datafield: 'unid', cellsrenderer: this.numberrenderer, width: 40, cellclassname: this.cellClass },
      { text: '', columntype: 'image', datafield: 'icono', cellsrenderer: this.imagerenderer, cellclassname: this.cellClass },
      { text: AppComponent.translate('Recurso'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'recurso', cellclassname: this.cellClass },
      { text: AppComponent.translate('Clase'), columntype: 'textbox', filtertype: 'textbox', datafield: 'clase', cellclassname: this.cellClass },

      { text: AppComponent.translate('Marca'), columntype: 'textbox', filtertype: 'textbox', datafield: 'marcaChasis', columngroup: 'chasisGroup', cellclassname: this.cellClass },
      { text: AppComponent.translate('Modelo'), columntype: 'string', filtertype: 'checkedlist', datafield: 'modeloChasis', columngroup: 'chasisGroup', cellclassname: this.cellClass },
      { text: AppComponent.translate('Año_fabricacion'), columntype: 'textbox', filtertype: 'number', datafield: 'fabricacionChasis', columngroup: 'chasisGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },
      { text: AppComponent.translate('Num_personas'), columntype: 'textbox', filtertype: 'number', datafield: 'personasChasis', columngroup: 'chasisGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },
      { text: AppComponent.translate('Num_operarios'), columntype: 'textbox', filtertype: 'number', datafield: 'operariosChasis', columngroup: 'chasisGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },
      { text: AppComponent.translate('Carga_util'), columntype: 'textbox', filtertype: 'number', datafield: 'cargaChasis', columngroup: 'chasisGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },
      { text: AppComponent.translate('Tipo_canbus'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'canbusChasis', columngroup: 'chasisGroup', cellclassname: this.cellClass },

      { text: AppComponent.translate('Marca'), columntype: 'textbox', filtertype: 'textbox', datafield: 'marcaCarrozado', columngroup: 'carrozadoGroup', cellclassname: this.cellClass },
      { text: AppComponent.translate('Modelo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'modeloCarrozado', columngroup: 'carrozadoGroup', cellclassname: this.cellClass },
      { text: AppComponent.translate('Tipo_de_residuo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'residuoCarrozado', columngroup: 'carrozadoGroup', cellclassname: this.cellClass },
      { text: AppComponent.translate('Tipo_canbus'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'canbusCarrozado', columngroup: 'carrozadoGroup', cellclassname: this.cellClass },
      { text: AppComponent.translate('Año_fabricacion'), columntype: 'textbox', filtertype: 'number', datafield: 'fabricacionCarrozado', columngroup: 'carrozadoGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },

      { text: AppComponent.translate('Ancho'), columntype: 'textbox', filtertype: 'number', datafield: 'anchoMedidas', columngroup: 'pesosMedidasGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },
      { text: AppComponent.translate('Alto'), columntype: 'textbox', filtertype: 'number', datafield: 'altoMedidas', columngroup: 'pesosMedidasGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },
      { text: AppComponent.translate('Largo'), columntype: 'textbox', filtertype: 'number', datafield: 'largoMedidas', columngroup: 'pesosMedidasGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },
      { text: AppComponent.translate('Carga_maxima_neta'), columntype: 'textbox', filtertype: 'number', datafield: 'cargaMedidas', columngroup: 'pesosMedidasGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },
      { text: AppComponent.translate('Volumen'), columntype: 'textbox', filtertype: 'number', datafield: 'volumenMedidas', columngroup: 'pesosMedidasGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },

      { text: AppComponent.translate('Combustible'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'combustibleCombustible', columngroup: 'combustibleGroup', cellclassname: this.cellClass },
      { text: 'Km/Co²', columntype: 'textbox', filtertype: 'number', datafield: 'kmCoCombustible', columngroup: 'combustibleGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },

      { text: AppComponent.translate('Precio_compra'), columntype: 'textbox', filtertype: 'number', datafield: 'precioEconomia', columngroup: 'economiaGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },
      { text: AppComponent.translate('Años_amortizacion'), columntype: 'textbox', filtertype: 'number', datafield: 'amortizacionEconomia', columngroup: 'economiaGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },
      { text: AppComponent.translate('Coste_euro_km'), columntype: 'textbox', filtertype: 'number', datafield: 'costeEconomia', columngroup: 'economiaGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },
      { text: AppComponent.translate('Año_modelo'), columntype: 'textbox', filtertype: 'number', datafield: 'modeloEconomia', columngroup: 'economiaGroup', cellsrenderer: this.numberrenderer, width: 60, cellclassname: this.cellClass },
    ];

    this.source = {
      datatype: 'json',
      datafields: [
        { name: 'visible', map: 'visible' },
        { name: 'selec', map: 'selec' },

        { name: 'id', type: 'number', map: 'id' },
        { name: 'acciones' },
        { name: 'icono', type: 'image', map: 'icono' },
        { name: 'unid', type: 'number', map: 'vehiculos>length' },
        { name: 'recurso', map: 'recurso>nombre' },
        { name: 'clase', type: 'string', map: 'claseString' },

        { name: 'marcaChasis', type: 'string', map: 'chasis>modelo>marca>nombre' },
        { name: 'modeloChasis', type: 'string', map: 'chasis>modelo>nombre' },
        { name: 'fabricacionChasis', type: 'number', map: 'chasis>anyoFabricacion' },
        { name: 'personasChasis', type: 'number', map: 'chasis>numPersonas' },
        { name: 'operariosChasis', type: 'number', map: 'chasis>numOperarios' },
        { name: 'cargaChasis', type: 'number', map: 'chasis>carga_maxima' },
        { name: 'canbusChasis', type: 'string', map: 'chasis>canBusMotor>tipoCombo' },

        { name: 'marcaCarrozado', type: 'string', map: 'carrozado>modelo>marca>nombre' },
        { name: 'modeloCarrozado', type: 'string', map: 'carrozado>modelo>nombre' },
        { name: 'residuoCarrozado', type: 'string', map: 'carrozado>residuo>nombre' },
        { name: 'canbusCarrozado', type: 'string', map: 'carrozado>chasisCarrozado>tipoCombo' },
        { name: 'fabricacionCarrozado', type: 'number', map: 'carrozado>anyoFabricacion' },

        { name: 'anchoMedidas', type: 'number', map: 'ancho' },
        { name: 'altoMedidas', type: 'number', map: 'alto' },
        { name: 'largoMedidas', type: 'number', map: 'largo' },
        { name: 'cargaMedidas', type: 'number', map: 'carga_maxima_neta' },
        { name: 'volumenMedidas', type: 'number', map: 'volumen' },

        { name: 'combustibleCombustible', type: 'string', map: 'chasis>combustible>nombre' },
        { name: 'kmCoCombustible', type: 'number', map: 'kmco2' },

        { name: 'precioEconomia', type: 'number', map: 'precio_compra' },
        { name: 'amortizacionEconomia', type: 'number', map: 'anyo_amortizacion' },
        { name: 'costeEconomia', type: 'number', map: 'coste' },
        { name: 'modeloEconomia', type: 'number', map: 'anyoMatriculacion' },
      ],
      localdata: this.conjuntoVehiculos,
    };

    this.columns.forEach(column => {
      column.rendered = (element) => { Utils.tooltiprenderer(element) };
    });

    this.dataAdapter = new jqx.dataAdapter(this.source);
  }

  cellClass = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'cellTooltip';
    }
    return '';
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    cellValue = cellValue ? cellValue.toString().toLowerCase() : '';
    let filterColumns = [
      'modeloChasis',
      'modeloCarrozado',
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  // Incializa la columna de botones
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'space-around';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';

    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.grid.getrowdata(row);
    }

    let conjuntoVehiculo = this.conjuntoVehiculos.find(conjVeh => conjVeh.id == rowdata.id);

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+ AppComponent.translate('Detalle') + `">
          <i class="fa-regular fa-eye"></i>
        </button>
      `;
    btnEdit.id = `buttonEdit_jqxButton`;
    btnContainer.appendChild(btnEdit);

    btnEdit.addEventListener('click', async (event: any) => {
      this.movilSelect = conjuntoVehiculo;
      this.onEditar();
    });

    htmlElement.appendChild(btnContainer);
  }

  // Recupera los móviles de la empresa
  async getMoviles() {
    this.conjuntoVehiculos = await this.conjuntoVehiculoService.getConjuntosVehiculo();

    if (this.conjuntoVehiculos) {
      this.conjuntoVehiculos.forEach(conjVeh => {
        if (conjVeh.clases && conjVeh.clases.length != 0) {
          conjVeh.clase = conjVeh.clases[0];
        }

        if (conjVeh.carrozados && conjVeh.carrozados.length != 0) {
          conjVeh.carrozado = conjVeh.carrozados[0];
        }

        if (conjVeh.vehiculos && conjVeh.vehiculos.length != 0) {
          conjVeh['visible'] = conjVeh.vehiculos[0].idLicencia;
        }

        for (const key in conjVeh) {
          if (conjVeh.hasOwnProperty(key)) {
            if(conjVeh[key] === undefined) {
              conjVeh[key] = '';
            }
          }
        }
      });

      this.source.localdata = this.conjuntoVehiculos;
      this.grid.updatebounddata();

      this.onBindingComplete();
      this.showLoader = false;

      this.updateMovilesLength(this.grid.getrows().length, this.grid.getrows().map(item => item.unid || 0).reduce((a, b) => a + b, 0)); // Si `unid` no está definido, usar 0.
    }
  }

  imagerenderer(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    return value ? '<img style="margin-left: 4px; margin-top: 6.5px;" height="16" width="16" src="data:image/jpg;base64,' + value + '"/>' :
      '<img style="margin-left: 4px; margin-top: 6.5px;" height="16" width="16" src="assets/images/car.png" />';
  }


  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div class="jqx-grid-cell-right-align cellTooltip" style="margin-top: 6.5px">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div class="jqx-grid-cell-right-align cellTooltip" style="margin-top: 6.5px">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    }
  }

  rendexTextGeneric(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {

    return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;
  }

  public onRowDoubleClick(event: any): void {
    this.movilSelect = this.conjuntoVehiculos[event.args.rowindex];
    this.onEditar();
  }

  public onEditar(): void {
    if (this.movilSelect) {
      this.form.collapse();
      this.checkEditingElement(this.movilSelect);
    }
  }

  private async openEditForm(movil: ConjuntoVehiculo): Promise<void> {
    const componentRef = this.editMovilComponent.createComponent(MovilEditComponent);
    const instance = componentRef.instance;
    instance.init(componentRef, movil);
    await this.ensureWindowFormLoaded(instance);

    instance.windowForm?.bringToFront();

    this.openEditComponents.set(movil.id, instance);
    instance.windowForm?.onClose.subscribe(() => {
      this.openEditComponents.delete(movil.id);
      componentRef.destroy();
    });
  }

  private async checkEditingElement(movil: ConjuntoVehiculo): Promise<void> {
    const existingComponent = this.openEditComponents.get(movil.id);

    if (existingComponent) {
      existingComponent.windowForm?.expand();
      existingComponent.windowForm?.bringToFront();
    } else {
      await this.openEditForm(movil);
    }
  }

  private ensureWindowFormLoaded(instance: MovilEditComponent): Promise<void> {
    return new Promise<void>((resolve) => {
      const interval = setInterval(() => {
        if (instance.windowForm) {
          clearInterval(interval);
          resolve();
        }
      }, 10);
    });
  }

  public onExportar(): void {
    const rows = this.grid.getrows();

    if (!this.hasDataToExport(rows)) {
      this.showWarningMessage("No_existen_datos");
      return;
    }

    const columnsToHide = ["imagen", "acciones"];
    this.toggleColumnsVisibility(columnsToHide, false);

    const exportableData = this.getCleanedData(rows);
    const groupHeaders = this.buildGroupHeaders();
    const mergeRanges = this.getMergeRanges(groupHeaders[0]);

    const worksheet = this.createWorksheet(exportableData, groupHeaders, mergeRanges);
    this.adjustColumnSettings(worksheet, exportableData);

    const workbook = this.createWorkbook(worksheet, AppComponent.translate("Vehiculos"));
    this.exportToFile(workbook, "Vehiculos");

    this.toggleColumnsVisibility(columnsToHide, true);
  }

  private hasDataToExport(rows: any[]): boolean {
    return rows && rows.length > 0;
  }

  private showWarningMessage(messageKey: string): void {
    MainComponent.getInstance().showWarning(
      "ATENCIÓN",
      AppComponent.translate(messageKey),
      2000
    );
  }

  private toggleColumnsVisibility(columns: string[], visible: boolean): void {
    columns.forEach((col) => visible ? this.grid.showcolumn(col) : this.grid.hidecolumn(col));
  }

  private getCleanedData(rows: any[]): any[] {
    return rows.map(({
      imagen, acciones, selec, visible, icono, id, uid, boundindex,
      visibleindex, uniqueid, DataIndex, ...cleanRow
    }) => cleanRow);
  }

  private buildGroupHeaders(): [string[], string[]] {
    const groupMap = new Map(this.columngroups.map((group) => [group.name, group.text]));
    const columnHeaders = this.columns
      .filter((col) => !col.hidden)
      .map((col) => ({
        text: col.text || "",
        group: col.columngroup ? groupMap.get(col.columngroup) || "" : "",
      }));

    const groupRow = columnHeaders.map((col, index) => (index === 0 || !col.group ? "" : col.group));
    const columnRow = columnHeaders.map((col) => col.text);

    return [groupRow as string[], columnRow as string[]];
  }

  private getMergeRanges(groupRow: string[]): any[] {
    const mergeRanges: any[] = [];
    groupRow.forEach((group, colIndex) => {
      if (group && (colIndex === 0 || groupRow[colIndex - 1] !== group)) {
        const start = colIndex;
        let end = colIndex;
        while (end + 1 < groupRow.length && groupRow[end + 1] === group) end++;
        mergeRanges.push({ s: { r: 0, c: start }, e: { r: 0, c: end } });
      }
    });
    return mergeRanges;
  }

  private createWorksheet(data: any[], groupHeaders: [string[], string[]], mergeRanges: any[]): any {
    const worksheet = xlsx.utils.json_to_sheet(data);
    xlsx.utils.sheet_add_aoa(worksheet, groupHeaders, { origin: "A1" });
    worksheet["!merges"] = mergeRanges;
    worksheet["!autofilter"] = {
      ref: `A2:${xlsx.utils.encode_col(groupHeaders[1].length - 1)}2`,
    };
    return worksheet;
  }

  private adjustColumnSettings(worksheet: any, data: any[]): void {
    const columnWidths = Object.keys(data[0] || {}).map(() => ({ wch: 15 }));
    worksheet["!cols"] = columnWidths;
  }

  private createWorkbook(worksheet: any, sheetName: string): any {
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, sheetName);
    return workbook;
  }

  private exportToFile(workbook: any, fileNameKey: string): void {
    const timestamp = DateUtils.formatDateAMDhms(new Date());
    const fileName = `${timestamp}_${AppComponent.translate(fileNameKey)}.xlsx`;
    xlsx.writeFile(workbook, fileName);
  }

  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getMoviles();
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.onBuscar();
  }

  onBuscar() {
    const filterValue = this.header.searchInput['nativeElement'].value.length >= 3
      ? this.header.searchInput['nativeElement'].value.toUpperCase()
      : '';

    const applyFilterToAllColumns = (grid: jqxGridComponent, data: any[]) => {
      if (filterValue) {
        data.forEach((row) => {
          const matchesSearch = Object.values(row).some(
            (value) => value !== undefined && value !== null && String(value).toUpperCase().includes(filterValue)
          );
          row['selec'] = matchesSearch ? 'selec' : '';
        });
        this.__ensureGridFiltersAreRegistered(grid);
        this.__tirggerGridFilters(grid);
      } else {
        grid.clearfilters();
        grid.updatebounddata('data');
      }
    };

    let moviles = this.conjuntoVehiculos;
    applyFilterToAllColumns(this.grid, moviles);
    this.updateMovilesLength(this.grid.getrows().length, this.grid.getrows().map(item => item.unid || 0).reduce((a, b) => a + b, 0));
  }

  private __ensureGridFiltersAreRegistered(grid: jqxGridComponent): void {
    const filters = grid.getfilterinformation();
    if (!filters.some(filter => filter.datafield === 'selec')) {
      const filtergroup = new jqx.filter();
      filtergroup.operator = 'and';

      const filter = filtergroup.createfilter('stringfilter', 'selec', 'contains');
      filtergroup.addfilter(0, filter);
      grid.addfilter('selec', filtergroup);
    }
  }

  private __tirggerGridFilters(grid: jqxGridComponent): void {
    grid.applyfilters();
    grid.updatebounddata('data');
  }
}
