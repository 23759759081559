import { NumberUtils } from "./number-utils";

export class RendererUtils {
  static numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value !== null && value !== undefined) {
      const formattedValue = NumberUtils.format(value, value === 0 ? 2 : 0);
      return `
            <div style="margin-right: 4px; margin-top: 4px; text-align: right; position: relative;">
              <span 
                onmouseover="
                  var rect = this.getBoundingClientRect();
                  var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                  var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
                  this.style.backgroundColor='gray';
                  this.style.color='white';
                  this.style.position='fixed';
                  this.style.top = rect.top + scrollTop + 'px';
                  this.style.left = rect.left + scrollLeft + 'px';
                  this.style.zIndex='9999';
                "
                onmouseout="
                  this.style.backgroundColor='';
                  this.style.color='';
                  this.style.position='';
                  this.style.top = '';
                  this.style.left = '';
                  this.style.zIndex='';
                "
              >
                ${formattedValue}
              </span>
            </div>
          `;
    }
    return defaulthtml;
  }

  static numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value !== null && value !== undefined) {
      const formattedValue = NumberUtils.format(value, 2);
      return `
            <div style="margin-right: 4px; margin-top: 4px; text-align: right; position: relative;">
                <span 
                    onmouseover="
                        var rect = this.getBoundingClientRect();
                        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;
                        var scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
                        this.style.backgroundColor='gray';
                        this.style.color='white';
                        this.style.position='fixed';
                        this.style.top = rect.top + scrollTop + 'px';
                        this.style.left = rect.left + scrollLeft + 'px';
                        this.style.zIndex='9999';
                    "
                    onmouseout="
                        this.style.backgroundColor='';
                        this.style.color='';
                        this.style.position='';
                        this.style.top = '';
                        this.style.left = '';
                        this.style.zIndex='';
                    "
                >
                    ${formattedValue}
                </span>
            </div>
            `;
    }
    return defaulthtml;
  }

  static rendexTextGeneric(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (typeof value === 'string') {
      return `<div style="margin-left: 4px; margin-top: 4px;  text-align: left;"" onmouseover="this.style.backgroundColor='gray'; this.style.color='white'; this.style.position='fixed';"onmouseout="this.style.backgroundColor=''; this.style.color=''; this.style.position='';">${value}</div>`;

    }
  }

}
