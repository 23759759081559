<div class="loader-container" *ngIf="showLoader">
    <div class=" loader"></div>
</div>
<jqxWindow #windVehiculoMaquinaria jqxWindowAutoResize [theme]="environment.tema" [zIndex]="900" [title]="translate('Vehiculos')"
    [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
    </div>
    <div class="form" style="float: left; overflow-x: hidden;">
        <jqxRibbon #movilesRibbon [theme]="environment.tema" [position]="'top'" (onChange)="changeTab($event)">
            <ul>
                <li>
                    {{ translate('Resumen') }} {{resumen.totalRegistros()}}
                </li>
                <li>
                    GLS
                </li>
                <li>
                    {{ translate('Perifericos')}}
                </li>
                <li>
                    {{ translate('Sensores')}}
                </li>
                <li>
                    {{ translate('Software')}}
                </li>
                <li>
                    {{ translate('Total')}}
                </li>
            </ul>
            <div>
                <div>
                    <app-vehiculos-maquinaria-resumen #resumen></app-vehiculos-maquinaria-resumen>
                </div>
                <div>
                    <p>GLS</p>
                </div>
                <div>
                    <p>PERIFERICOS</p>
                </div>
                <div>
                    <p>SENSOSRES</p>
                </div>
                <div>
                    <p>SOFTWARE</p>
                </div>
                <div>
                    <p>TOTAL</p>
                </div>
            </div>
        </jqxRibbon>
        <div style="float: left; margin-top: 5px; display: flex; gap: 2px;">
            <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
              [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" (onClick)="windVehiculoMaquinaria.close()"
              [value]="translate('Salir')">
            </jqxButton>
        </div>
    </div>
</jqxWindow>
