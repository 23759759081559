import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { LabelOverLenghtModule } from '../label-over-lenght/label-over-lenght.module';

import { HeaderModule } from '../header/header.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { MovilesComponent } from './moviles/moviles.component';
import { MovilEditComponent } from './moviles/movil-edit/movil-edit.component';
import { MovilesCatalogComponent } from './moviles-catalog/moviles-catalog.component';
import { VehiculosMaquinariaComponent } from './vehiculos-maquinaria/vehiculos-maquinaria.component';
import { VehiculosMaquinariaResumenComponent } from './vehiculos-maquinaria/vehiculos-maquinaria-resumen/vehiculos-maquinaria-resumen.component';
import { LoaderComponent } from 'movisat-common-gui';

@NgModule({
  declarations: [
    MovilesComponent,
    MovilEditComponent,
    MovilesCatalogComponent,
    VehiculosMaquinariaComponent,
    VehiculosMaquinariaResumenComponent,
  ],
  imports: [
    FormsModule,
    CommonModule,
    HeaderModule,
    BrowserModule,
    SharedModule,
    LabelOverLenghtModule,
    LoaderComponent,
  ],
  exports: [
    MovilesComponent
  ]
})
export class ResourcesModule { }
