import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { IncidencesService } from 'src/app/services/incidences/incidences.service';

import { UsuarioModel } from 'src/app/services/sso/models/usuario.model';
import { EstadoModel } from 'src/app/services/incidences/models/estado.model';
import { CambioEstadoModel } from 'src/app/services/incidences/models/cambioEstado.model';

import { PeriodoSelectComponent } from '../../periodo-select/periodo-select.component';
import { HeaderComponent } from '../../header/header.component';
import { Utils } from 'src/app/utils/utils';
import { BehaviorSubject } from 'rxjs';
import { UserLangService } from 'src/app/services/common/user-lang/user-lang.service';

@Component({
  selector: 'app-status-history',
  templateUrl: './status-history.component.html',
  styleUrls: ['./status-history.component.css']
})
export class StatusHistoryComponent extends CustomForms implements OnInit {
  @ViewChild('windowStates') windowStates: jqxWindowComponent;
  @ViewChild('statesGrid') statesGrid: jqxGridComponent;
  @ViewChild('loader') loaderComponent: jqxLoaderComponent;
  @ViewChild('header') header: HeaderComponent;

  @Output() cerrar = new EventEmitter<boolean>();
  //referencia de componente
  private componentRef = null;
  //variables de entorno
  public environment = environment;
  //instancia del componente
  private static instance: StatusHistoryComponent;
  fechaIni: Date;
  fechaFin: Date;
  //grid
  dataAdapter: any;
  dataSource: any;
  columns;
  private static toolbarContainer = null;
  private static searchControl = null;
  searchControl;
  private searchText = '';
  //variables
  cambiosEstado: CambioEstadoModel[] = [];
  estados;
  usuarios;
  private countHistoySubject = new BehaviorSubject<number>(0);
  countHistorys$ = this.countHistoySubject.asObservable();

  constructor(private incidencesService: IncidencesService, private userLangService: UserLangService) {
    super();
    StatusHistoryComponent.instance = this;
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, estados: EstadoModel[], usuarios: UsuarioModel[]) {
    this.componentRef = componentRef;
    this.estados = estados;
    this.usuarios = usuarios;
  }

  public static getInstance(): StatusHistoryComponent {
    return StatusHistoryComponent.instance;
  }

  ngOnInit(): void {
    this.initGrid();
  }

  resizeColumns(grid: jqxGridComponent){
    Utils.renderSizeGrid(grid, 500);
  }

  // textos del grid en el idioma correspondiente
  langGrid = JqWidgets.getLocalization(this.userLangService.getUserLangShort());

  ngAfterViewInit() {
    this.addCustomForm(this.windowStates);
    //cambia el titulo a la ventana
    this.countHistorys$.subscribe((count) => {
    this.windowStates.setTitle(this.translate('Historico_estados') + ' (' + count + ')');
    });
    setTimeout(() => {
      //extrae las fechas del selector de periodo
      this.fechaIni = new Date(this.header.periodoSelect.getFechaIni());
      this.fechaFin = new Date(this.header.periodoSelect.getFechaFin());
      //actualiza grid
      this.refreshGrid();
    }, 500);
  }

  //recuperar datos de cambios de estado
  async refreshGrid() {
    this.cambiosEstado = await this.incidencesService.getHistoricStates(this.fechaIni, this.fechaFin);
    //asignar usuarios y estados
    this.cambiosEstado.forEach(cambioEstado => {
      cambioEstado.UsuarioEmail = this.usuarios.find(usuario => cambioEstado.UsuarioId == usuario.Id).Email;
      cambioEstado.EstadoNombre = this.estados.find(estado => cambioEstado.EstadoId == estado.Id).Nombre;
      cambioEstado.selec = 'selec';
    });
    this.dataSource.localdata = this.cambiosEstado;
    if (this.searchText == '') {
      this.statesGrid.updatebounddata('data');
      this.statesGrid.sortby('Fecha', 'asc');
    } else {
      this.initSearchControl();
    }
    this.countHistoySubject.next(this.statesGrid.getrows().length);
  }

  //iniciar grid
  initGrid() {
    this.columns = [
      {
        text: 'Id', datafield: 'Id', align: 'left', cellsalign: 'right', width: 40,
      },
      { text: this.translate('Estado'), datafield: 'Estado', cellsalign: 'left', align: 'left', width: 90 },
      { text: this.translate('USUARIO'), datafield: 'UsuarioEmail', cellsalign: 'left', align: 'left', width: 190 },
      { text: this.translate('Observaciones'), datafield: 'Observaciones', cellsalign: 'left', align: 'left', width: 240 },
      { text: this.translate('Fecha'), datafield: 'Fecha', align: 'left', cellsalign: 'left', width: 140, cellsformat: 'dd/MM/yy HH:mm:ss' },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }
    ];

    this.dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'Id', map: 'IncidenciaId', type: 'number' },
        { name: 'Estado', map: 'EstadoNombre', type: 'string' },
        { name: 'UsuarioEmail', map: 'UsuarioEmail', type: 'string' },
        { name: 'Observaciones', map: 'Observaciones', type: 'string' },
        { name: 'Fecha', map: 'Fecha', type: 'date' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: [],
      sortcolumn: 'Fecha',
      sortdirection: 'asc'
    };
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);

    this.resizeColumns(this.statesGrid);
  }

  // Inicializa el control de búsqueda de la cabecera
  initSearchControl(): void {
    if(this.header.searchInput['nativeElement'].value.length >= 3){
      this.searchText = this.header.searchInput['nativeElement'].value.toUpperCase();
    }else {
      this.searchText = '';
    }

    let lastSearch = '';
    let timer = null;
    // Cada segundo compruebo si se ha filtrado la información
    setInterval(() => {
      if (this.searchText !== lastSearch) {
        if (timer) {
          clearTimeout(timer);
        }
        lastSearch = this.searchText;
        timer = setTimeout(() => {
          // Marco los registros que cumplen la condición de búsqueda y pongo el campo
          // oculto a "selec"
          if (this.cambiosEstado) {
            this.cambiosEstado.forEach(cambioEstado => {
              if ((cambioEstado.IncidenciaId && cambioEstado.IncidenciaId.toString().toUpperCase().indexOf(this.searchText) > -1) ||
                (cambioEstado.EstadoNombre && cambioEstado.EstadoNombre.toUpperCase().indexOf(this.searchText) > -1) ||
                (cambioEstado.UsuarioEmail && cambioEstado.UsuarioEmail.toUpperCase().indexOf(this.searchText) > -1)
              ) {
                cambioEstado.selec = 'selec';
              } else {
                cambioEstado.selec = '';
              }
            });
          }
          // Compruebo si ya he creado el filtro "selec" anteriormente
          const filters = this.statesGrid.getfilterinformation();
          if (filters.find(s => s.datafield === 'selec') === undefined) {
            const filtergroup = new jqx.filter();
            filtergroup.addfilter(1, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
            this.statesGrid.addfilter('selec', filtergroup);
          }
          this.statesGrid.applyfilters();
          this.dataSource.localdata = this.cambiosEstado;
          this.statesGrid.updatebounddata('data');
        }, 500);
      }
    }, 500);

    this.resizeColumns(this.statesGrid);
    this.countHistoySubject.next(this.statesGrid.getrows().length);
  }

  ngOnDestroy() {
    StatusHistoryComponent.searchControl.remove();
    StatusHistoryComponent.toolbarContainer.remove();
  }

  // Cierro el formulario y destruyo el componente
  onClose() {
    //se emite evento para que se muestre la ventana del grid de incidencias
    this.cerrar.emit(true);
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }

  }

  //eventos botones
  onFilter() {
    //extrae las fechas del selector de periodo
    this.header.searchInput['nativeElement'].value = '';
    this.fechaIni = new Date(this.header.periodoSelect.getFechaIni());
    this.fechaFin = new Date(this.header.periodoSelect.getFechaFin());
    this.refreshGrid();
  }

  onResetFilter(){
    this.header.periodoSelect.desde = new Date();
    this.header.periodoSelect.desde.setHours(0, 0, 0);
    this.header.periodoSelect.hasta = new Date();
    this.header.periodoSelect.hasta.setHours(23, 59, 59);
    PeriodoSelectComponent._this.dateForm.get('desde').setValue(new Date());
    PeriodoSelectComponent._this.dateForm.get('hasta').setValue(new Date());
    this.header.periodoSelect.cbPeriodo.selectedIndex(0);

    this.header.searchInput['nativeElement'].value = '';

    //extrae las fechas del selector de periodo
    this.fechaIni = new Date(this.header.periodoSelect.getFechaIni());
    this.fechaFin = new Date(this.header.periodoSelect.getFechaFin());
    //actualiza grid
    this.refreshGrid();
  }

  onExportar() {
    const json = JSON.parse(JSON.stringify(this.statesGrid.getrows()));
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Historico_estados') + '.xlsx');
  }

  onBindingComplete() {
    this.loaderComponent.close();
  }

  onRowSelect(event) {

  }
  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }




}
