import { Injectable, signal } from '@angular/core';
import { SsoService } from '../../sso/sso.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuditoriaService } from '../../auditoria/auditoria.service';
import { RangoModel } from '../models/rango.model';
import { MainComponent } from 'src/app/components/main/main.component';
import { TabRangosComponent } from 'src/app/components/tarjetas-ciudadanos/tab-rangos/tab-rangos.component';
import { BehaviorSubject, firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RangosService {
  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;
  private urlApi = this.ssoService.getTicket().UrlApi;
  private usuario = this.ssoService.getTicket().Usuario.Email;
  recordCount = signal<number>(0);


  constructor(
    private http: HttpClient,
    private ssoService: SsoService,
    private auditoriaService: AuditoriaService
  ) { }

  async getRangos(): Promise<RangoModel[]> {
    let result: RangoModel[] = [];

    try {
      await this.http.get<RangoModel[]>(
        `${this.urlApi}/api/tarjetas/rangos?enterprise=${this.empresaId}&include=all`
      ).toPromise().then(
        res => {
          let cargaAsociacion = new Promise<void>((resolve, reject) => {
            res.forEach(async (rango, index, array) => {
              rango.zonasNombre = '';
              if (rango.zonas) {
                rango.zonas.forEach(zona => {
                  if (zona.id === rango.zonas[rango.zonas.length - 1].id) {
                    rango.zonasNombre += `${zona.nombre}`
                  } else {
                    rango.zonasNombre += `${zona.nombre}, `
                  }
                });
              }

              rango.asociaciones = await this.getAsociacion(rango.id);
              if (index === array.length - 1) {
                resolve();
              }
            });
          });

          cargaAsociacion.then(() => {
            TabRangosComponent._this.rangos = res;
            TabRangosComponent._this.cargaGrid();
          });
        }, error => {
        }
      )
    } catch (e) {
      return e;
    }

    return result;
  }

  async getAsociacion(idRango: number): Promise<any[]> {
    try {
      const res = await firstValueFrom(
        this.http.get<any[]>(
          `${this.urlApi}/api/tarjetas/rangos/zonas/id/rango?idRango=${idRango}&enterprise=${this.empresaId}&include=all`
        )
      );

      return res.map((aso) => {
        delete aso.rango;
        delete aso.zona;
        return aso;
      });
    } catch (error) {
      console.error(`Error fetching asociaciones for rango ${idRango}:`, error);
      return [];
    }
  }

  async createRango(rango: RangoModel): Promise<RangoModel> {
    let result: RangoModel = null;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    rango.empresa = this.empresaId;

    try {
      await this.http.post<RangoModel>(
        `${this.urlApi}/api/tarjeta/rango`,
        JSON.stringify(rango), httpOptions
      ).toPromise().then(
        res => {
          result = res;
        }, error => {
          result = null;
          MainComponent.getInstance().showError(
            'ATENCION',
            error.error.message,
            2000,
            false
          );
        }
      )
    } catch (e) {
      result = null;
    }

    return result;
  }

  async asociateRangoZona(idRango: Number, zonas: any[]): Promise<Boolean> {
    let response: Boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let asociacion: any = {
      "empresa": this.ssoService.getTicket().Empresa.IdGestion,
      "idRango": idRango,
      "zonas": zonas,
    };

    try {
      await this.http.post<any>(
        `${this.urlApi}/api/tarjetas/rangos/zonas`,
        JSON.stringify(asociacion), httpOptions
      ).toPromise().then(
        res => {
          response = true;
        }
      )
    } catch (error) {
    }

    return response;
  }

  async deleteRango(idRango: Number): Promise<Boolean> {
    let response: Boolean = false;

    try {
      await this.http.delete(
        `${this.urlApi}/api/tarjeta/rango?enterprise=${this.empresaId}&id=${idRango}`
      ).toPromise().then(
        res => {
          response = true;
        }
      )
    } catch (e) {
    }

    return response;
  }

  recordCountRangosUpdate(count: number): void {
    this.recordCount.set(count);
  }
}
