<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>
<jqxWindow #formTotal [theme]="environment.tema" [width]="1110" [height]="567" [zIndex]="50"
    [position]="getFormPos(formTotal)" [autoOpen]="true" [zIndex]="90" [showCloseButton]="true"
    [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()">
    <div class="formHeader">
        {{translate('Volumetricos')}} {{translate('Total').toLowerCase()}}
    </div>
    <div class="form" style="float:left; overflow: hidden;">
        <div style="float: left; width: 100%;">
            {{translate('Total') + ' ' + translate('Elementos').toLowerCase()}}
            {{translate('Requieren_volumetrico').toLowerCase()}}: <b>{{totalElem}}</b>
        </div>
        <div style="float: left; margin-top: 4px; width: 360px; height: 240px;">
            <b>{{translate('Pendiente_instalar_volum')}}</b>
            <jqxGrid #grid1 [theme]="environment.tema" [width]="'100%'" [height]="'90%'" [source]="dataAdapter1"
                [appCellClick] [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true"
                [enablebrowserselection]="true" [columns]="columns1" [altrows]="true" [sortmode]="'single'"
                [enabletooltips]="true" [filterable]="false" [showstatusbar]="true" [statusbarheight]="20"
                [showaggregates]="true" [rowsheight]="20" [columnsheight]="20" [localization]="langGrid"
                (onRowclick)="onRowClick1($event)">
            </jqxGrid>
        </div>
        <div style="float: left; margin-left: 8px; margin-top: 4px; width: 360px; height: 240px;">
            <b>{{translate('Instalados_nunca_enviado')}}</b>
            <jqxGrid #grid2 [theme]="environment.tema" [width]="'100%'" [height]="'90%'" [source]="dataAdapter2"
                [appCellClick] [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true"
                [enablebrowserselection]="true" [columns]="columns2" [altrows]="true" [sortmode]="'single'"
                [enabletooltips]="true" [filterable]="false" [showstatusbar]="true" [statusbarheight]="20"
                [showaggregates]="true" [rowsheight]="20" [columnsheight]="20" [localization]="langGrid"
                (onRowclick)="onRowClick2($event)">
            </jqxGrid>
        </div>
        <div style="float: left; margin-left: 8px; margin-top: 4px; width: 360px; height: 240px;">
            <b>{{translate('Tienen_lecturas_sin_volum')}}</b>
            <jqxGrid #grid3 [theme]="environment.tema" [width]="'100%'" [height]="'90%'" [source]="dataAdapter3"
                [appCellClick] [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true"
                [enablebrowserselection]="true" [columns]="columns3" [altrows]="true" [sortmode]="'single'"
                [enabletooltips]="true" [filterable]="false" [showstatusbar]="true" [statusbarheight]="20"
                [showaggregates]="true" [rowsheight]="20" [columnsheight]="20" [localization]="langGrid"
                (onRowclick)="onRowClick3($event)">
            </jqxGrid>
        </div>
        <div style="float: left; width: 540px; height: 240px;">
            <b>{{translate('No_envian_mas_24_horas')}}</b>
            <jqxGrid #grid4 [theme]="environment.tema" [width]="'100%'" [height]="'90%'" [source]="dataAdapter4"
                [appCellClick] [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true"
                [enablebrowserselection]="true" [columns]="columns4" [altrows]="true" [sortmode]="'single'"
                [enabletooltips]="true" [filterable]="false" [showstatusbar]="true" [statusbarheight]="20"
                [showaggregates]="true" [rowsheight]="20" [columnsheight]="20" [localization]="langGrid"
                (onRowclick)="onRowClick4($event)">
            </jqxGrid>
        </div>
        <div style="float: left; margin-left: 8px; width: 548px; height: 240px;">
            <b>{{translate('Enviando_al_dia')}}</b>
            <jqxGrid #grid5 [theme]="environment.tema" [width]="'100%'" [height]="'90%'" [source]="dataAdapter5"
                [appCellClick] [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true"
                [enablebrowserselection]="true" [columns]="columns5" [altrows]="true" [sortmode]="'single'"
                [enabletooltips]="true" [filterable]="false" [showstatusbar]="true" [statusbarheight]="20"
                [showaggregates]="true" [rowsheight]="20" [columnsheight]="20" [localization]="langGrid"
                (onRowclick)="onRowClick5($event)">
            </jqxGrid>
        </div>
        <div style="float: left;">
            <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgSrc]='"/assets/images/center.png"' [value]="translate('Centrar')"
                (onClick)="onCentrar($event)">
            </jqxButton>
            <jqxButton style="float: left; margin-left: 6px" [imgPosition]="'left'"
                [textImageRelation]="'imageBeforeText'" [width]="100" [height]="26" [textPosition]="'left'"
                [value]="translate('CANCELAR')" (onClick)="formTotal.close()">
            </jqxButton>
        </div>
    </div>
</jqxWindow>