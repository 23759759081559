import { Component, Input, OnInit, QueryList, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';

import { AppComponent } from 'src/app/app.component';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';

import { InputNumberComponent } from 'src/app/components/input-number/input-number.component';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { environment } from 'src/environments/environment';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { CustomForms } from 'src/app/components/forms/custom-forms';

@Component({
  selector: 'app-elements-edit-detalle',
  templateUrl: './elements-edit-detalle.component.html',
  styleUrls: ['./elements-edit-detalle.component.css', '../elements-edit.component.css']
})
export class ElementsEditDetalleComponent extends CustomForms implements OnInit {
  @Input() elemEdit: ElementoModel;
  @Input() expanded: boolean = true;
  @Input() closed: boolean = false;

  @ViewChild('gridClasesAsociadas') gridClasesAsociadas: jqxGridComponent;
  @ViewChild('fracciones') fracciones: jqxWindowComponent;
  @ViewChildren(InputNumberComponent) inputNumbers: QueryList<InputNumberComponent>;

  public langGrid = JqWidgets.getLocalization('es');
  environment = environment;

  ejecutado: boolean = false;

  translate(text: string): string {
    return AppComponent.translate(text);
  }

  renderer() {
    if (!this.ejecutado) {
      setTimeout(() => {
        this.inputNumbers.forEach(inputNumber => {
          const value = inputNumber.value;
          inputNumber.rendererDecimal('', '', value);
        });
      }, 100);
      this.ejecutado = true;
    }
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initGridClasesAsociadas();
  }

  ngAfterViewInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'elemEdit': {
            this.elemEdit = changes[propName].currentValue;

            if(this.sourceClasesAsociadas) {
              this.sourceClasesAsociadas.localdata = this.elemEdit?.Equipamiento?.Clases;
              this.gridClasesAsociadas.updatebounddata();
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  columnsClasesAsociadas: any[] = [];
  sourceClasesAsociadas: any;
  dataAdapterClasesAsociadas: any;

  initGridClasesAsociadas() {
    this.columnsClasesAsociadas = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
      { text: this.translate('CODIGO'), columntype: 'textbox', filtertype: 'textbox', datafield: 'codigo' },
      { text: this.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', },
      { text: this.translate('Acronimo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'acronimo', },
      { text: this.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'descripcion', },
    ]

    this.sourceClasesAsociadas = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'Id' },
        { name: 'codigo', type: 'string', map: 'Codigo' },
        { name: 'nombre', type: 'string', map: 'Nombre' },
        { name: 'acronimo', type: 'string', map: 'Acronimo' },
        { name: 'descripcion', type: 'string', map: 'Descripcion' },
      ],
      localdata: this.elemEdit?.Equipamiento?.Clases ? this.elemEdit.Equipamiento.Clases : [],
    };

    this.dataAdapterClasesAsociadas = new jqx.dataAdapter(this.sourceClasesAsociadas);
  }

  dataAdapterFracciones: any;
  columnsFracciones: any[] = [];
  winFracTop: number = 0;
  winFracLeft: number = 0;

  openFracciones(event: any) {
    if (this.elemEdit) {
      this.fracciones.open();

      this.winFracTop = event.clientY;
      this.winFracLeft = event.clientX;

      this.columnsFracciones = [
        // tiene dos columnas, fracion 1 hasta el 5 y Porcentaje
        { text: 'select', datafield: 'select', hidden: true, },
        { text: 'frac', datafield: 'frac', hidden: true, },
        { text: 'Fracción', datafield: 'fraccion', cellsalign: 'left', width: 100 },
        { text: 'Porcentaje', datafield: 'porcentaje', cellsalign: 'right', width: 90 },
      ];

      let sourceFracciones = {
        datatype: 'json',
        datafields: [
          { name: 'fraccion', type: 'string' },
          { name: 'porcentaje', type: 'string' },
          { name: 'select', type: 'number', datafield: 'select' },
          { name: 'frac', type: 'number', datafield: 'frac' },
        ],
        localdata: [
          { fraccion: '1ª Fraccion', porcentaje: '0%', select: 1, frac: this.elemEdit.Equipamiento.NumeroFracciones },
          { fraccion: '2ª Fraccion', porcentaje: '25%', select: 2, frac: this.elemEdit.Equipamiento.NumeroFracciones },
          { fraccion: '3ª Fraccion', porcentaje: '50%', select: 3, frac: this.elemEdit.Equipamiento.NumeroFracciones },
          { fraccion: '4ª Fraccion', porcentaje: '75%', select: 4, frac: this.elemEdit.Equipamiento.NumeroFracciones },
          { fraccion: '5ª Fraccion', porcentaje: '100%', select: 5, frac: this.elemEdit.Equipamiento.NumeroFracciones },
        ],
        sortcolumn: 'fraccion',
        sortdirection: 'asc',
      };

      this.dataAdapterFracciones = new jqx.dataAdapter(sourceFracciones);
    }
  }

  clickCerrar($event) {
    this.fracciones.close();
  }

  /* Opciones dropdown ruedas */
  sourceRuedas: string[] =
  [
    'No',
    'Si'
  ];

  /* Opciones dropdown pedal */
  sourcePedal: string[] =
  [
    'No',
    'Si'
  ];
}
