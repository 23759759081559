import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { DateUtils } from 'src/app/utils/date-utils';
import { environment } from 'src/environments/environment';
import { MapsService } from '../maps/maps.service';
import { ResourcesService } from '../resources/resources.service';
import { SsoService } from '../sso/sso.service';
import { PosicionesInterface } from './interfaces/posiciones.interface';
import { PosicionModel } from './models/posicion.model';

@Injectable({
  providedIn: 'root'
})
export class PositionService {
  private ready = false;
  private loadTimer = null;

  private posiciones = new Map<number, PosicionModel>();

  // Disparadores para notificar eventos
  @Output() posicionesReadyEmiter: EventEmitter<void> = new EventEmitter();
  @Output() newPosicionEmiter: EventEmitter<PosicionModel> = new EventEmitter();
  @Output() changePosicionEmiter: EventEmitter<PosicionModel> = new EventEmitter();

  constructor(private http: HttpClient,
    private ssoService: SsoService,
    private resorcesService: ResourcesService,
    private mapsService: MapsService) {
    // Recupero las ùltimas posiciones cada x tiempo
    this.loadTimer = setTimeout(() => {
      this.loadLastPosition();
    }, environment.refresPosInterval);
  }

  // Recupera la última posición de todos lo móviles de la empresa actual
  private async loadLastPosition(): Promise<void> {
    try {
      const result = await this.http.get<PosicionModel[]>(this.ssoService.getTicket().UrlApi + '/api/posiciones/ultimas/' +
        this.ssoService.getTicket().Empresa.IdGestion).toPromise();
      if (result !== null) {
        result.forEach(posicion => {
          // Sólo almaceno posiciones de móviles que estén cargados
          const movil = this.resorcesService.getMovil(posicion.MovilId);
          if (movil !== null) {
            // Actualizo el estado de la conexión y la ignición en el móvil
            this.resorcesService.setMovilConexion(posicion.MovilId, posicion.Conexion, posicion.Ignicion);
            // Recupero la última posición almacenada del móvil
            const oldPos = this.posiciones.get(posicion.MovilId);
            posicion.Fecha = new Date(posicion.Fecha); // Convierto la fecha a un objeto Date
            // Compruebo si es una nueva posición o si se ha modificado la información, de lo contrario no hago nada
            if (oldPos === undefined) {
              this.setInfoGeo(posicion);
              this.posiciones.set(posicion.MovilId, posicion);
              this.newPosicionEmiter.emit(posicion);
            } else {
              if (!PosicionModel.compare(posicion, oldPos)) {
                this.setInfoGeo(posicion);
                this.posiciones.set(posicion.MovilId, posicion);
                this.changePosicionEmiter.emit(posicion);
              }
            }
          }
        });
        if (!this.ready) {
          this.ready = true;
          this.posicionesReadyEmiter.emit();
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      if (this.loadTimer !== null) {
        clearTimeout(this.loadTimer);
      }
      // Recupero las ùltimas posiciones cada x tiempo
      this.loadTimer = setTimeout(() => {
        this.loadLastPosition();
      }, environment.refresMovilInterval);
    }
  }

  // Devuelve las posiciones almacenadas en la tabla, si todavía no se han
  // recuperado todas lo hago en este momento
  public async getLastPositions(): Promise<PosicionModel[]> {
    const result: PosicionModel[] = [];
    if (!this.ready) {
      await this.loadLastPosition();
    }
    // Genero el array a partir de la tabla hash
    for (const [key, value] of this.posiciones) {
      result.push(value);
    }
    return result;
  }

  // Devuelve la última posición de un móvil
  public getLastPosition(idMovil: number): PosicionModel {
    const posicion = this.posiciones.get(idMovil);
    return posicion !== undefined ? posicion : null;
  }

  // Devuelve las posiciones de un móvil entre dos fechas
  async getPositions(movil: number, desde: Date, hasta: Date): Promise<PosicionModel[]> {
    let result: PosicionModel[] = null;
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      };
      const body = {
        movil: movil,
        desde: DateUtils.formatDateTime(desde, false),
        hasta: DateUtils.formatDateTime(hasta, false)
      }
      result = await this.http.post<PosicionModel[]>(this.ssoService.getTicket().UrlApi + '/api/posiciones',
        JSON.stringify(body), httpOptions).toPromise();
      result.forEach(pos => {
        pos.Fecha = new Date(pos.Fecha);
      });
    } catch {
    }
    return result;
  }

  // Permite subscribirse para saber cuando se han recuperado las posiciones de todos los móviles por primera vez
  public subscribeOnPositionReady(subscriber: PosicionesInterface): Subscription {
    return this.posicionesReadyEmiter.subscribe(() => {
      subscriber.onPosicionesReady();
    });
  }

  // Permite subscribirse para recibir las posiciones que se incorporan a las lista inicial
  public subscribeNewPosition(subscriber: PosicionesInterface): Subscription {
    return this.newPosicionEmiter.subscribe(posicion => {
      subscriber.onNewPosicion(posicion);
    });
  }

  // Permite subscribirse para recibir los móviles que cambian de contenido
  public subscribeChangePosition(subscriber: PosicionesInterface): Subscription {
    return this.changePosicionEmiter.subscribe(posicion => {
      subscriber.onChangePosicion(posicion);
    });
  }

  // Recupera información geográfica de la posición
  setInfoGeo(posicion: PosicionModel) {
    const t = setTimeout(async () => {
      try {
        const info = await this.mapsService.getInfogeoFromPoint(posicion.Lat, posicion.Lng);
        posicion.Localizacion = info;
        // Actualizo la última posición en el móvil
        this.resorcesService.setMovilPos(posicion.MovilId, posicion);
      } catch (e) {
        console.log(e);
      }
      clearTimeout(t);
    }, 0);
  }

}
