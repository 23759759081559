import { Permiso } from "src/app/services/menu/models/menu.opcion.model";

/**
 * @deprecated Don't inherit from this class, use services and avoid smart components.
 */
export class CustomForms {
    private static forms: any[] = [undefined, undefined, undefined, undefined, undefined,
        undefined, undefined, undefined, undefined, undefined];
    public maximizable: boolean = false;
    private static zIndexMin = 0;
    private observer = null;
    public static permisos = new Map<string, any>();
    public static menuActions = new Map<string, any>();
    public static lastActionMenu = '';
    public static lastOptionMenu: any = null;

    constructor() { }

    public addCustomForm(form: any, maximizable: boolean = true) {
        // Esto lo hago para contrarestar el incremento automático del zIndex que hace
        // internamente el componente JQXWindow
        const zIndex = form.host[0].style['zIndex'];
        if (zIndex > CustomForms.zIndexMin) {
            if (CustomForms.zIndexMin === 0) {
                CustomForms.zIndexMin = form.host[0].style['zIndex'];
            } else {
                form.host[0].style['zIndex']--;
            }
        }
        this.maximizable = maximizable;
        form.collapseAnimationDuration(0);
        // Si no es auto-open los eventos se capturan al abrir por primera vez
        if (!form.autoOpen()) {
            const onOpenSubscription = form.onOpen.subscribe((event) => {
                this.captureEvents(form);
                onOpenSubscription.unsubscribe();
            });
        } else {
            // Capturo los eventos de la ventana
            this.captureEvents(form);
        }
        this.applyPermissions(form);
    }

    public captureEvents(form: any) {
        form.collapseAnimationDuration(0);
        form.attrMaxWidth = 10000;
        form.attrMaxHeight = 10000;
        form.iniWidth = form.attrWidth;
        form.oriWidth = form.attrWidth;
        form.oriHeight = form.attrHeight;
        form.maximized = false;

        const onResizeSubscription = form.onResizing.subscribe((event) => {
            form.attrWidth = event.args.width;
            form.attrHeight = event.args.height;
            if (this.maximizable) {
                let div = form.host[0].querySelector('#btnMaximize');
                if (div) {
                    if (form.attrWidth > form.iniWidth) {
                        form.maximized = false;
                        form.iniWidth = form.attrWidth;
                        div.className = 'jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-right jqx-icon-arrow-right-material';
                    }
                }
            }
        });
        const onMovingSubscription = form.onMoving.subscribe((event) => {
            form.x = event.args.x;
            form.y = event.args.y;
            if (form.isCollapsed === true) {
                form.xCollapsed = event.args.x;
                form.yCollapsed = event.args.y;
            }
        });
        const onCollapseSubscription = form.onCollapse.subscribe(() => {
            const menuContainer = document.getElementById('map-container').getClientRects();
            form.oriX = form.x;
            form.oriY = form.y;
            if (form.xCollapsed === undefined) {
                form.column = 0;
                for (; form.column < CustomForms.forms.length && CustomForms.forms[form.column]; form.column++);
                CustomForms.forms[form.column] = form;
                form.x = menuContainer[0].left + 2 + (form.column * 156);
                form.y = menuContainer[0].bottom - 30;
            } else {
                form.x = form.xCollapsed;
                form.y = form.yCollapsed;
            }
            form.oriWidth = form.attrWidth;
            form.oriHeight = form.attrHeight;
            form.oriTitle = form.title();
            form.setTitle(form.title().substring(0, 11) + (form.title().length > 11 ? '...' : ''));
            form.attrWidth = 150;
            form.isCollapsed = true;
            // Pongo el icono flecha arriba
            let div = form.host[0].querySelector('.jqx-window-collapse-button');
            if (div) {
                div.className = 'jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-up jqx-icon-arrow-up-material';
            }
        });
        const onExpandSubscription = form.onExpand.subscribe(() => {
            form.x = form.oriX;
            form.y = form.oriY;
            form.attrWidth = form.oriWidth;
            form.isCollapsed = false;
            form.setTitle(form.oriTitle);
            CustomForms.forms[form.column] = undefined;
            // Pongo el icono flecha abajo
            let div = form.host[0].querySelector('.jqx-window-collapse-button');
            if (div) {
                div.className = 'jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-down jqx-icon-arrow-down-material';
            }
            // Reordeno el resto de formularios minimizados
            this.reorderForms();
        });
        const onCloseSubscription = form.onClose.subscribe(() => {
            if (form.column !== undefined) {
                CustomForms.forms[form.column] = undefined;
            }
            if (form.autoOpen()) {
                onMovingSubscription.unsubscribe();
                onCollapseSubscription.unsubscribe();
                onExpandSubscription.unsubscribe();
                onCloseSubscription.unsubscribe();
            }
            if (this.observer) {
                this.observer.disconnect();
            }
            this.observer = null;
            // Reordeno el resto de formularios minimizados
            this.reorderForms();
        });
        // Controlo el doble click sobre la barra de título del formulario
        let divHeader = form.host[0].querySelector('.jqx-window-header');
        if (divHeader) {
            divHeader.addEventListener("dblclick", () => {
                if (form.isCollapsed) {
                    form.expand();
                } else {
                    form.collapse();
                }
            }, false);
        }
        // Cambio el icono de minimizar
        let div = form.host[0].querySelector('.jqx-window-collapse-button');
        if (div) {
            div.className = 'jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-down jqx-icon-arrow-down-material';
        }
        if (this.maximizable) {
            // Añado botón de maximizar
            div = form.host[0].querySelector('.jqx-window-header');
            if (div) {
                const btnMaximize: any = document.createElement('div');
                btnMaximize.innerHTML = `
                    <div class="jqx-window-collapse-button-background jqx-window-collapse-button-background-material"
                        style="visibility: visible; width: 16px; height: 16px; margin-right: 4px; margin-left: 0px;
                        position: absolute; right: 32px;">
                            <div class="jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-right
                                jqx-icon-arrow-right-material" style="width: 100%; height: 100%; top: 0px;" id="btnMaximize">
                            </div>
                    </div>
                `;
                div.appendChild(btnMaximize);
                btnMaximize.addEventListener('click', (event: any) => {
                    // Si está minimizado se restaura
                    if (form.isCollapsed) {
                        form.expand();
                    } else {
                        if (form.maximized === true) {
                            form.maximized = false;
                            btnMaximize.innerHTML = `
                                <div class="jqx-window-collapse-button-background jqx-window-collapse-button-background-material"
                                style="visibility: visible; width: 16px; height: 16px; margin-right: 4px; margin-left: 0px;
                                position: absolute; right: 32px;">
                                    <div class="jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-right
                                        jqx-icon-arrow-right-material" style="width: 100%; height: 100%; top: 0px;" id="btnMaximize">
                                    </div>
                                </div>
                            `;
                            form.attrWidth = form.iniWidth;
                        } else {
                            if (form.attrWidth < window.innerWidth - form.x - 70) {
                                form.maximized = true;
                                form.iniWidth = form.attrWidth;
                                form.attrWidth = window.innerWidth - form.x - 70 + 'px';
                                btnMaximize.innerHTML = `
                                    <div class="jqx-window-collapse-button-background jqx-window-collapse-button-background-material"
                                    style="visibility: visible; width: 16px; height: 16px; margin-right: 4px; margin-left: 0px;
                                    position: absolute; right: 32px;">
                                        <div class="jqx-window-collapse-button jqx-window-collapse-button-material jqx-icon-arrow-left
                                            jqx-icon-arrow-left-material" style="width: 100%; height: 100%; top: 0px;" id="btnMaximize">
                                        </div>
                                    </div>
                                `;
                            }
                        }
                    }
                });
            }
        }
    }

    // Reordena los formulario cada vez que se maximiza o cierra un formulario
    private reorderForms() {
        const menuContainer = document.getElementById('map-container').getClientRects();
        let j = 0;
        for (let i = 0; i < CustomForms.forms.length; i++) {
            if (CustomForms.forms[i]) {
                CustomForms.forms[j] = CustomForms.forms[i];
                CustomForms.forms[j].column = j;
                j++;
            }
        }
        while (j < CustomForms.forms.length) {
            CustomForms.forms[j++] = undefined;
        }
        for (let i = 0; i < CustomForms.forms.length && CustomForms.forms[i]; i++) {
            CustomForms.forms[i].x = menuContainer[0].left + 2 + (i * 156);
        }
    }

    // Devuelve la posición del formulario al template
    /**
     * @deprecated Use `jqxWindowAutoResize` instead.
     */
    public getFormPos(form: any, top = 60): any {
        const mapContainer = document.getElementById('map-container').getClientRects();
        if (form.x === undefined || form.y === undefined) {
            const centerContainer = document.getElementById('center-container').getClientRects();
            form.x = centerContainer[0].left + 2;
            form.y = centerContainer[0].top + top;
        }
        if (form.isCollapsed || (form.y > mapContainer[0].bottom - 30)) {
            form.y = mapContainer[0].bottom - 30;
        }
        if (form.x < mapContainer[0].left + 2) {
            form.x = mapContainer[0].left + 2;
        }
        return {
            x: form.x,
            y: form.y
        };
    }

    // Oculta elementos de acuerdo a los permisos del rol del usuario
    public applyPermissions(form: any) {
        const _this = this;
        // Creo un observador para detectar cambios en la vista
        this.observer = new MutationObserver(function (mutations) {
            _this.processChildNodes(form.host[0], 0);
        });
        this.observer.observe(form.host[0], {
            attributes: true,
            subtree: true
        });
    }

    // Método que aplica los permisos de forma recursiva
    public processChildNodes(element: any, apartado: number) {
        if (element && element.style) {
            for (let i = 0; i < element.style.length; i++) {
                // Apartados
                if (element.style[i] === '--apartado') {
                    const permissions: Permiso[] = CustomForms.permisos.get(CustomForms.lastActionMenu);
                    apartado = 0;
                    if (permissions) {
                        const attr = getComputedStyle(element).getPropertyValue('--apartado');
                        // Compruebo si hay permisos por apartado
                        let hayPermisosApartado = false;
                        for (let i = 0; i < permissions.length; i++) {
                            if (permissions[i].IdApartado > 0) {
                                hayPermisosApartado = true;
                            }
                            if (permissions[i].Apartado === attr) {
                                apartado = permissions[i].IdApartado;
                                break;
                            }
                        }
                        if (hayPermisosApartado) {
                            let hidden = true;
                            for (let i = 0; i < permissions.length && !element.hidden; i++) {
                                if (permissions[i].Apartado === attr) {
                                    hidden = false;
                                }
                            }
                            if (!element.hidden && hidden) {
                                element.hidden = true;
                            }
                        }
                    }
                    break;
                }
                // Permisos
                if (element.style[i] === '--permisos') {
                    const permissions: Permiso[] = CustomForms.permisos.get(CustomForms.lastActionMenu);
                    if (permissions) {
                        const attr = getComputedStyle(element).getPropertyValue('--permisos');
                        // Compruebo si hay permisos por apartado
                        let hayPermisosGenerales = false;
                        for (let i = 0; i < permissions.length; i++) {
                            if (permissions[i].IdApartado < 1) {
                                hayPermisosGenerales = true;
                                break;
                            }
                        }
                        if (hayPermisosGenerales) {
                            let hidden = true;
                            for (let i = 0; i < permissions.length && !element.hidden && hidden; i++) {
                                if (apartado === permissions[i].IdApartado) {
                                    hidden = attr.indexOf(permissions[i].Acronimo) < 0;
                                }
                            }
                            if (!element.hidden && hidden) {
                                element.hidden = true;
                            }
                        }
                    }
                    break;
                }
            }
        }
        for (let i = 0; element.childNodes && i < element.childNodes.length; i++) {
            this.processChildNodes(element.childNodes[i], apartado);
        }
    }

    // Devuelve si un elemento está oculto o no en función de los permisos
    public static isHidden(permis: string): boolean {
        const permissions: Permiso[] = CustomForms.permisos.get(CustomForms.lastActionMenu);
        let hidden = true;
        for (let i = 0; i < permissions.length && hidden; i++) {
            hidden = permis.indexOf(permissions[i].Acronimo) < 0;
        }
        return hidden;
    }

    // Devuelve si un elemento es visible o no en función de los permisos
    public static isVisible(permis: string): boolean {
        const permissions: Permiso[] = CustomForms.permisos.get(CustomForms.lastActionMenu);
        let visible = false;
        for (let i = 0; i < permissions.length && !visible; i++) {
            visible = permis.indexOf(permissions[i].Acronimo) > -1;
        }
        return visible;
    }

    // Devuelve si el usuario puede ver una opción del menú
    public static hasMenuAction(action: string): boolean {
        if (!CustomForms.menuActions) {
            return false;
        }
        return CustomForms.menuActions.get(action);
    }

}
