import {
  Type,
  OnInit,
  Output,
  Component,
  OnDestroy,
  ViewChild,
  ComponentRef,
  EventEmitter,
  AfterViewInit,
  ViewContainerRef,
  ViewChildren,
  QueryList
} from '@angular/core';

import { switchMap, tap, timer } from 'rxjs';
import { Subscription } from 'rxjs';
import { GeoUtils } from 'src/app/utils/geo-utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { LocalStorage } from '../../utils/localStorage';
import { environment } from 'src/environments/environment';
import { ToastContainerDirective, ToastrService } from 'ngx-toastr';

import { MapSearch } from 'movisat-maps/lib/models/map.search';
import {
  MapBounds,
  MapCircle,
  MapLatLng,
  MapMarker,
  MapPolygon,
  MapInterface,
  MapComponent,
  MapPolygonPoint,
  MapPolylinePoint
} from 'movisat-maps';

import { ApiService } from '../../services/api/api.service';
import { SsoService } from '../../services/sso/sso.service';
import { KmlService } from 'src/app/services/kml/kml.service';
import { LangService } from 'src/app/services/lang/lang.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { PositionService } from 'src/app/services/positions/position.service';
import { ResourcesService } from '../../services/resources/resources.service';

import { KmlModel } from 'src/app/services/kml/models/kml.model';
import { CasaModel } from 'src/app/services/geographics/casa.model';
import { TicketModel } from '../../services/sso/models/ticket.model';
import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { PosicionModel } from 'src/app/services/positions/models/posicion.model';
import { MovilesInterface } from '../../services/resources/interfaces/moviles.interface';
import { AmbitoActividadModel } from 'src/app/services/geographics/ambito-actividad.model';
import { MarcoGeograficoModel } from 'src/app/services/geographics/marco-geografico.model';
import { PosicionesInterface } from 'src/app/services/positions/interfaces/posiciones.interface';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxRibbonComponent } from 'jqwidgets-ng/jqxribbon';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxSplitterComponent } from 'jqwidgets-ng/jqxsplitter';

import { PuComponent } from '../pu/pu.component';
import { MenuComponent } from './menu/menu.component';
import { ElementsComponent } from '../elements/elements.component';
import { CiudadanosComponent } from '../ciudadanos/ciudadanos.component';
import { ListadoAlarmaComponent } from '../reports/cerraduras/listado-alarma/listado-alarma.component';
import { MenuService } from 'src/app/services/menu/menu.service';
import {
  HistoricoEnviosDispIdentificadorComponent
} from '../reports/historico-envios-disp-identificador/historico-envios-disp-identificador.component';
import { ListadoIdentificacionComponent } from '../reports/cerraduras/listado-identificacion/listado-identificacion.component';
import { CustomForms } from '../forms/custom-forms';
import { MovilesComponent } from '../resources/moviles/moviles.component';
import { ElementsService } from 'src/app/services/elements/elements.service';
import { PuService } from 'src/app/services/pu/pu.service';

// Clase para gestionar mapas
export class Mapa {
  public label = 'Mapa';
  public map: MapComponent;
  public visible = false;
  public destroyed = true;
  public icon = 'assets/images/carto.png';
  public closeButton = true;

  public onMapReadyExternal?(map: MapComponent, mapIndex: number);

  // Cuando el mapa está listo para ser usuado
  public onMapReady(map: MapComponent, mapIndex: number) {
    this.map = map;
    if (this.onMapReadyExternal) {
      this.onMapReadyExternal(map, mapIndex);
    }
  }

  constructor(label: string, visible: boolean, destroyed: boolean) {
    this.label = label;
    this.visible = visible;
    this.destroyed = destroyed;
  }
}

// Clase para gestionar tabs de gestión
export class GestionTab {
  public label = 'Gestión';
  public visible = false;
  public destroyed = true;
  public icon = 'assets/images/gestion.png';
  public closeButton = true;

  public onClose?(tabIndex: number);

  constructor(label: string, visible: boolean, destroyed: boolean) {
    this.label = label;
    this.visible = visible;
    this.destroyed = destroyed;
  }
}

interface ComponentConfig {
  componentFactory: () => ComponentRef<any>; // Función que crea el componente
}

//Clase para gestionar tabs de info (test)

export class InfoTab {
  public id: string = null;
  public label: string = null;
  public visible = false;
  public destroyed = true;
  public activated = true;
  public componentRef: ComponentRef<any>;
  public component: any;

  constructor(label: string, visible: boolean) {
    this.label = label;
    this.visible = visible;
  }
}

enum App {
  ECOEVOLUTION = 3,
  ECOEVOLUTION_DEV = 5
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, AfterViewInit, OnDestroy, MapInterface, MovilesInterface, PosicionesInterface {
  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
  @ViewChild('rightContainer', { read: ViewContainerRef }) rightContainer: ViewContainerRef;
  @ViewChild('gridElemIaContainer', { read: ViewContainerRef }) gridElemIaContainer: ViewContainerRef;
  @ViewChild('dynamicContainer', { read: ViewContainerRef }) dynamicContainer: ViewContainerRef;
  @ViewChild('tabGestion') tabGestion: jqxRibbonComponent;
  @ViewChild('rightSplitter') rightSplitter: jqxSplitterComponent;
  @ViewChild('downSplitter') downSplitter: jqxSplitterComponent;
  @ViewChild('divGestion0', { read: ViewContainerRef }) divGestion0;
  @ViewChild('divGestion1', { read: ViewContainerRef }) divGestion1;
  @ViewChild('divGestion2', { read: ViewContainerRef }) divGestion2;
  @ViewChild('divGestion3', { read: ViewContainerRef }) divGestion3;
  @ViewChild('divGestion4', { read: ViewContainerRef }) divGestion4;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChildren('divTab') divTabs: QueryList<any>;

  // Eventos
  @Output() endLoadElementsEmiter: EventEmitter<ElementoModel[]> = new EventEmitter();

  // Versión de la web
  public webVersion = '250130.01';

  // Definiciones para los tabuladores del área de gestión
  public static MOVILES_TAB = 0;
  public static ELEMENTOS_TAB = 1;
  public static PU_TAB = 2;
  public static IA_TAB = 3;
  public static ITINERARIOS_TAB = 4;
  public static GESTION_TAB0 = 5;
  public static GESTION_TAB1 = 6;
  public static GESTION_TAB2 = 7;
  public static GESTION_TAB3 = 8;
  public static GESTION_TAB4 = 9;
  public static CIUDADANOS_TAB = 10;

  private static instance: MainComponent;
  private map: MapComponent = null;
  public mapProvider = 'Google';
  public cartoType = 'raster';
  public lang = 'es-ES'; // Idioma por defecto
  public searchCountry = 'ES'; // Pais por defecto (para las búsquedas en el mapa)
  public searchBounds = ''; // Marco por defecto (para las búsquedas en el mapa)
  public environment = environment;
  public ssoTicket: TicketModel;
  public zoom = 6;
  public center = { // Cieza
    lat: 38.2378331,
    lng: -1.42102,
    weight: 1
  };
  private moviles = new Map<number, MovilModel>();
  public movilesList: MovilModel[] = [];
  public elements = new Map<number, ElementoModel>();
  public elementsList: ElementoModel[] = [];
  private subcriptionNewMovil: Subscription = null;
  private subcriptionChangeMovil: Subscription = null;
  private posiciones: PosicionModel[] = [];
  private subcriptionNewPosicion: Subscription = null;
  private subcriptionChangePosicion: Subscription = null;
  private subcriptionMovileChangeFilter: Subscription = null;
  public maps: Mapa[] = [];
  public tabsInfo: InfoTab[] = [];
  public kmlObj = new Map<number, any>();
  public kml: KmlModel[] = [];
  public gestionTabs: GestionTab[] = [];
  public tabMapActive = 0; // Tab activo de mapas
  // Variable para saber si está visible la gestión IA
  public formIaVisible = false;
  // Variable para saber si está visible el tab de itinerarios
  public formItineraryVisible = false;
  // Variable para saber si se trata de una aplicación IA sin las cosas de EcoSAT
  public isEcoEvolution = false;
  // Variable para mostrar el tab de moviles
  public formMovVisible = true;
  // Variable para mostrar el tab de elementos
  public formElemVisible = true;
  // Variable para mostrar el tab de puntos de ubicación
  public formPuVisible = true;
  // Variable para mostrar el tab de ciudadanos
  public formCiudadanosVisible = false;
  // Variables para guardar el marco cartográfico y el ámbito de actividad
  public marcoGeografico: MarcoGeograficoModel;
  public ambitoActividad: AmbitoActividadModel;
  //constante para activar o desactivar marco
  public verMarcoAmbito = false;
  public marcoAmbitoPolygon: MapPolygon;
  private lastBounds: MapBounds;
  private lastZoom;
  private minZoom;
  public marcoCartoPolygon: MapPolygon;
  public casaMarker: MapMarker;
  public casaZoom: number;
  public arrPolygonsGeojson = [];
  public arrCircles = [];
  // Versión de la API
  public apiVersion = {
    apiVer: ''
  };

  public _this = MainComponent;
  private subscriptionCollapse: Subscription | null = null;
  showLoader = true;
  listComponentsMenu: any = [];

  currentComponent: Type<any> | null = null;

  constructor(
    public ssoService: SsoService,
    public apiService: ApiService,
    public langService: LangService,
    private toastrService: ToastrService,
    private resourcesService: ResourcesService,
    private positionService: PositionService,
    private kmlService: KmlService,
    private zonesService: ZonesService,
    private menuService: MenuService,
    private elementService: ElementsService,
    private puService: PuService,
    private configService: ConfigService) {
    MainComponent.instance = this;
  }

  public static getInstance(): MainComponent {
    return MainComponent.instance;
  }

  recordMovilesCountTotal = this.resourcesService.recordCountTotal;
  recordCountElementsTotal = this.elementService.recordCountTotal;
  recordCountPuTotal = this.puService.recordCountTotal;
  recordCountMovilesUpdate = this.resourcesService.recordCuntUpdate
  recordCountElementsUpdate = this.elementService.recordCountUpdate;
  recordCountPuUpdate = this.puService.recordCountUpdate;

  async ngOnInit(): Promise<void> {
    // Creo los mapas
    this.maps.push(new Mapa('Mapa-0', true, false)); // Mapa principal
    this.maps.push(new Mapa('Mapa-1', false, true));
    this.maps.push(new Mapa('Mapa-2', false, true));
    this.maps.push(new Mapa('Mapa-3', false, true));
    this.maps.push(new Mapa('Mapa-4', false, true));
    this.maps.push(new Mapa('Mapa-5', false, true));
    // Creo los tabs de gestión
    this.gestionTabs.push(new GestionTab('Gestion-1', false, true));
    this.gestionTabs.push(new GestionTab('Gestión-2', false, true));
    this.gestionTabs.push(new GestionTab('Gestión-3', false, true));
    this.gestionTabs.push(new GestionTab('Gestión-4', false, true));
    this.gestionTabs.push(new GestionTab('Gestión-5', false, true));

    // Creo los tabs de info
    // this.tabsInfo.push(new InfoTab('Historico_envios_dispositivo_identificador', false,));
    // this.tabsInfo.push(new InfoTab('Listado_alarmas_geo', false,));
    // this.tabsInfo.push(new InfoTab('Historicos_identificaciones', false,));

    // Recupero el ticket del SSO
    this.ssoTicket = this.ssoService.getTicket();
    // Obtengo la versión de la API
    const res = await this.apiService.getApiVersion();
    if (res) {
      this.apiVersion = res;
    }
    // Obtengo el proveedor y tipo de cartografía y el idioma
    this.mapProvider = this.ssoTicket.Empresa.Cartografia;
    this.cartoType = this.ssoTicket.Empresa.Raster ? 'raster' : 'vectorial';
    this.lang = this.ssoTicket.Usuario.Idioma.Codigo;
    if (this.ssoService.getTicket().Aplicacion.Id === App.ECOEVOLUTION ||
      this.ssoService.getTicket().Aplicacion.Id === App.ECOEVOLUTION_DEV) {
      this.isEcoEvolution = true;
    }
    // Asigno el idioma para los componentes DateTime JQWidgets
    JqWidgets.setCulture(window, this.ssoTicket.Usuario.Idioma.Codigo);
    // Recupero el centro del mapa y el zoom
    this.getLastCenterMap();
  }

  ngAfterViewInit(): void {
    this.showLoader = true;
    this.menuService.setViewContainerRef(this.dynamicContainer);
    // Asigno el contenedor para los mensajes de notificación
    this.toastrService.overlayContainer = this.toastContainer;
    this.rightSplitter.collapse();

    let t = setTimeout(() => {
      clearTimeout(t);
      window.addEventListener('resize', (event) => {
        t = setTimeout(() => {
          clearTimeout(t);
          MainComponent.getActiveMap().resize();
        }, 250);
      });
    }, 500);

    document.getElementById('tabElementos').click();
    let t2 = setTimeout(() => {
      clearTimeout(t2);
      document.getElementById('tabPU').click();
      t2 = setTimeout(() => {
        clearTimeout(t2);
        this.formPuVisible = false;
        this.setFormCiudadanoVisible(false);
        document.getElementById('tabMoviles').click();
        this.showLoader = false;
        t2 = setTimeout(() => {
          clearTimeout(t2);
          if (CustomForms.hasMenuAction('MOVILES')) {
            document.getElementById('tabMoviles').click();
            // desactivo la opcion del menu
            MenuComponent.getInstance().menu.disable(25345, true);
          } else {
            if (CustomForms.hasMenuAction('CATALOGO_ELEMENTOS')) {
              document.getElementById('tabElementos').click();
            }
          }
        }, 500);
      }, 500);
    }, 500);

    document.title = this.ssoService.getTicket().Aplicacion.Nombre + ' - ' + this.ssoService.getTicket().Empresa.Nombre;

    // Cuando se cambia el filtro de móviles
    // this.subcriptionMovileChangeFilter = this.resourcesService.changeFilterEmiter.subscribe(async () => {
    // this.createMarkerPos();
    // });

    //*******************************************************************************
    // MainComponent.createMap('Carto-IA', 'assets/images/ia.png', (map: MapComponent, mapIndex: number) => {
    //   map.addMarker({
    //     label: 'Hola mundo!',
    //     position: new MapLatLng(38.2391, -1.41877)
    //   })
    //   map.setZoom(15);
    //   map.setCenter(new MapLatLng(38.2391, -1.41877));

    //   setTimeout(() => {
    //     MainComponent.hideMap(mapIndex);
    //   }, 5000);
    //   setTimeout(() => {
    //     MainComponent.showMap(mapIndex);
    //   }, 10000);
    //   setTimeout(() => {
    //     MainComponent.removeMap(mapIndex);
    //   }, 15000);
    // });
    // //*******************************************************************************

    // MainComponent.onLoadElements((elementos: ElementoModel[]) => {
    //   alert('Se han descargado ' + elementos.length + ' elementos');
    // });

    this.subscriptionCollapse = MenuComponent.getInstance().obsListComponent.subscribe((component) => {
      if (component) {
        this.listComponentsMenu.push(component);

        // Ensure that component.instance exists before using Object.values
        if (component.instance) {
          let hasJqxWindowsComponent: any = Object.values(component.instance).find(this.isJqxWindowsComponent);

          if (hasJqxWindowsComponent) {

            hasJqxWindowsComponent.bringToFront();
          }
          this.subscribeCollapse();
        } else {
          return;
        }

      } else {
        if (this.listComponentsMenu.length > 0 && this.tabsInfo.length > 0) {

          let ventanasAbiertas = this.listComponentsMenu.filter(component => component !== null && component !== undefined);
          ventanasAbiertas.forEach((component) => {
            let hasJqxWindowsComponent: any = Object.values(component.instance).find(this.isJqxWindowsComponent);
            if (hasJqxWindowsComponent) {
              hasJqxWindowsComponent.collapse();
            }
          });
        } else {
          return;
        }
      }
    });
  }



  subscribeCollapse() {
    // Filtrar solo los componentes que no sean null o undefined
    this.listComponentsMenu = this.listComponentsMenu.filter(component => component !== null && component !== undefined);

    this.listComponentsMenu.forEach((component) => {
      // Asegurarse de que component.instance no sea undefined o null antes de aplicar Object.values()
      if (component.instance) {
        let hasJqxWindowsComponent: any = Object.values(component.instance).find(this.isJqxWindowsComponent);

        // Verificar si se encontró el componente antes de acceder a onCollapse
        if (hasJqxWindowsComponent && hasJqxWindowsComponent.onCollapse) {
          hasJqxWindowsComponent.onCollapse.subscribe((event) => {
            // Filtrar tabs que no sean null o undefined
            let tabActivos = this.tabsInfo.filter(tab => tab.id !== null && tab.componentRef !== null && tab.componentRef !== undefined);

            tabActivos.forEach(tab => {
              if (tab.componentRef.instance) {
                setTimeout(() => {
                  let hasJqxWindowsComponent: any = Object.values(tab.componentRef.instance).find(this.isJqxWindowsComponent);

                  // Verifico el componente antes de llamar a bringToFront
                  if (hasJqxWindowsComponent && hasJqxWindowsComponent.bringToFront) {
                    hasJqxWindowsComponent.bringToFront();
                  }
                }, 200);
              }
            });
          });
        }
      }
    });
  }

  minimizeWindows() {
    if (this.listComponentsMenu.length > 0) {
      let ventanasAbiertas = this.listComponentsMenu.filter(component => component !== null && component !== undefined);

      ventanasAbiertas.forEach((component) => {
        // Ensure that component.instance is not null or undefined
        if (component.instance) {
          let hasJqxWindowsComponent: any = Object.values(component.instance).find(this.isJqxWindowsComponent);

          if (hasJqxWindowsComponent) {
            hasJqxWindowsComponent.collapse();
          }
        }
      });
    } else {
      return;
    }
  }


  selectOpenTab() {
    timer(500).pipe(
      tap(() => {
        let tabClicked = false;

        MainComponent.instance.divTabs.forEach(tab => {
          if (tab && tab.nativeElement && !tab.nativeElement.hidden && !tabClicked) {
            tabClicked = true;
            document.getElementById(tab.nativeElement.id).click();
          }
        });

        if (!tabClicked) {
          this.downSplitter.collapse();
        }
      })
    ).subscribe();
  }

  // Cuando se destruye el componente borro las subscripciones
  ngOnDestroy() {
    if (this.subcriptionNewMovil !== null) {
      this.subcriptionNewMovil.unsubscribe();
    }
    if (this.subcriptionChangeMovil !== null) {
      this.subcriptionChangeMovil.unsubscribe();
    }
    if (this.subcriptionNewPosicion !== null) {
      this.subcriptionNewPosicion.unsubscribe();
    }
    if (this.subcriptionChangePosicion !== null) {
      this.subcriptionChangePosicion.unsubscribe();
    }
    if (this.subcriptionMovileChangeFilter !== null) {
      this.subcriptionMovileChangeFilter.unsubscribe();
    }
    if (this.subscriptionCollapse !== null) {
      this.subscriptionCollapse.unsubscribe();
    }
  }

  tongleMenu() {
    MenuComponent.getInstance().tongleMenu(true);
  }

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Estos métodos estáticos se usan para mostrar todas las notificaciones
  // de la aplicación desde módulos externos
  public static showSuccess(title: string, message: string, timeout: number) {
    MainComponent.getInstance().showSuccess(title, message, timeout);
  }

  public static showInfo(title: string, message: string, timeout: number) {
    MainComponent.getInstance().showInfo(title, message, timeout);
  }

  public static showWarning(title: string, message: string, timeout: number) {
    MainComponent.getInstance().showWarning(title, message, timeout);
  }

  public static showError(title: string, message: string, timeout: number) {
    MainComponent.getInstance().showError(title, message, timeout);
  }

  public static translate(text: string): string {
    return AppComponent.translate(text);
  }

  public static showLoader(show: boolean) {
    if (show) {
      MainComponent.getInstance().loader.open();
    } else {
      MainComponent.getInstance().loader.close();
    }
  }

  public static getSsoTicket(): TicketModel {
    return MainComponent.getInstance().ssoTicket;
  }

  // Crea un nuevo mapa (como máximo 5)
  public static createMap(nombre: string, icono: string, closeButton: boolean, onMapReady: any = null): number {
    return MainComponent.getInstance().createMap(nombre, icono, closeButton, onMapReady);
  }

  // Crea un nuevo mapa (como máximo 5)
  public static createTabInfo(nombre: string, visible: boolean) {
    return MainComponent.getInstance().createComponent(nombre, visible);
  }

  // Permite destruir un mapa
  public static removeMap(index: number) {
    return MainComponent.getInstance().removeMap(index);
  }

  // Muestra un mapa
  public static showMap(index: number) {
    return MainComponent.getInstance().showMap(index);
  }

  // Oculta un mapa
  public static hideMap(index: number) {
    return MainComponent.getInstance().hideMap(index);
  }

  // Devuelve el mapa indicado (0=Mapa principal)
  public static getMap(index: number = 0): MapComponent {
    return MainComponent.getInstance().getMap(index);
  }

  // Devuelve el mapa activo
  public static getActiveMap(): MapComponent {
    return MainComponent.getInstance().getActiveMap();
  }

  // Crea un nuevo tab para gestión (como máximo 5)
  public static createGestionTab(nombre: string, icono: string, closeButton: boolean, onClose: any = null): number {
    return MainComponent.getInstance().createGestionTab(nombre, icono, closeButton, onClose);
  }

  // Devuelve el tab de gestión indicado
  public static getGestionContainer(index: number): ViewContainerRef {
    return MainComponent.getInstance().getGestionContainer(index);
  }

  // Permite destruir un tab de gestión
  public static removeGestionTab(index: number) {
    return MainComponent.getInstance().removeGestionTab(index);
  }

  // Muestra un tab de gestión
  public static showGestionTab(index: number) {
    return MainComponent.getInstance().showGestionTab(index);
  }

  // Oculta un tab de gestión
  public static hideGestionTab(index: number) {
    return MainComponent.getInstance().hideGestionTab(index);
  }

  // Devuelve el manejador del splitter de la derecha
  public static getRightSplitter(): jqxSplitterComponent {
    return MainComponent.getInstance().rightSplitter;
  }

  // Devuelve el manejador del splitter de abajo
  public static getDownSplitter(): jqxSplitterComponent {
    return MainComponent.getInstance().downSplitter;
  }

  // Permite asignar una función para recibir los elementos cuando se cargan
  public static onLoadElements(fnCallback: (elementos: ElementoModel[]) => void): void {
    // Si en el momento de la subscripción ya se ha cargado los elementos
    // notifico en el acto
    const elements = MainComponent.getInstance().elementsList;
    if (!elements || elements.length === 0) {
      MainComponent.getInstance().endLoadElementsEmiter.subscribe((elementos: ElementoModel[]) => {
        fnCallback(elementos);
      });
    } else {
      fnCallback(elements);
    }
  }

  // Devuelve la lista de elementos
  public static getElements(): ElementoModel[] {
    return MainComponent.getInstance().elementsList;
  }

  // Devuelve la lista de móviles
  public static getMoviles(): MovilModel[] {
    return MainComponent.getInstance().movilesList;
  }

  // Estos métodos se usan para mostrar todas las notificaciones de la aplicación
  public showSuccess(title: string, message: string, timeout: number, info: string = '') {
    this.toastrService.success(AppComponent.translate(message) + info,
      AppComponent.translate(title), {
      timeOut: timeout,
      positionClass: 'toast-top-center',
      enableHtml: true
    });
  }

  public showInfo(title: string, message: string, timeout: number, info: string = '') {
    this.toastrService.info(AppComponent.translate(message) + info,
      AppComponent.translate(title), {
      //timeOut: timeout,
      positionClass: 'toast-top-center',
      enableHtml: true
    });
  }

  public showWarning(title: string, message: string, timeout: number, info: string = '') {
    this.toastrService.warning(AppComponent.translate(message) + info,
      AppComponent.translate(title), {
      timeOut: timeout,
      positionClass: 'toast-top-center',
      enableHtml: true
    });
  }

  public showError(title: string, message: string, timeout: number, translate = true, info: string = '') {
    if (translate) {
      this.toastrService.error(AppComponent.translate(message) + info,
        AppComponent.translate(title), {
        timeOut: timeout,
        positionClass: 'toast-top-center',
        enableHtml: true
      });
    } else {
      this.toastrService.error(message,
        AppComponent.translate(title), {
        timeOut: timeout,
        positionClass: 'toast-top-center',
        enableHtml: true
      });
    }
  }

  public setFormMovilesVisible() {
    this.formMovVisible = true;

    this.downSplitter.expand();
    const t = setTimeout(() => {
      clearTimeout(t);
      document.getElementById('tabMoviles').style.display = '';
      this.tabGestion.selectAt(MainComponent.MOVILES_TAB);
      MenuComponent.getInstance().menu.disable(25345, true);
    }, 500);
  }

  public setFormElementsVisible() {
    this.formElemVisible = true;

    this.downSplitter.expand();
    const t = setTimeout(() => {
      clearTimeout(t);
      document.getElementById('tabElementos').style.display = '';
      this.tabGestion.selectAt(MainComponent.ELEMENTOS_TAB);
    }, 500);
  }

  // Pongo visible la pestaña del grid de elementos IA
  public setFormIaVisible(visible: boolean) {
    this.formIaVisible = visible;
    if (visible) {
      this.downSplitter.expand();
      const t = setTimeout(() => {
        clearTimeout(t);
        document.getElementById('tabIA').style.display = '';
        this.tabGestion.selectAt(MainComponent.IA_TAB);
      }, 500);
    }
  }

  // Pongo visible la pestaña del grid de itinrarios
  public setFormItineraryVisible(visible: boolean) {
    this.formItineraryVisible = visible;
    if (visible) {
      this.downSplitter.expand();
      setTimeout(() => {
        document.getElementById('tabItinerary').style.display = '';
        document.getElementById('tabItinerary').click();
      }, 500);
    }
  }

  // Pongo visible la pestaña del grid de puntos de ubicación
  public setFormPuVisible(visible: boolean) {
    this.formPuVisible = visible;
    if (visible) {
      this.downSplitter.expand();
      let t = setTimeout(() => {
        clearTimeout(t);
        this.tabGestion.selectAt(MainComponent.PU_TAB);
        t = setTimeout(() => {
          clearTimeout(t);
          document.getElementById('tabPU').style.display = '';
          document.getElementById('tabPU').click();
          // Muestro los puntos de ubicación en la cartografía
          PuComponent.getInstance().showPU();
        }, 500);
      }, 500);
    }
  }

  public setFormCiudadanoVisible(visible: boolean) {
    this.formCiudadanosVisible = visible;
    if (!visible) {
      return;
    }
    CiudadanosComponent._this.initGrid();
    this.downSplitter.expand();

    timer(500).pipe(
      tap(() => this.tabGestion.selectAt(MainComponent.CIUDADANOS_TAB)),
      switchMap(() => timer(500)),
      tap(() => {
        document.getElementById('tabCiudadano').click();
        document.getElementById('tabCiudadano').style.display = '';
      })
    ).subscribe();
  }

  onClickTab(tab: number) {
    switch (tab) {
      case MainComponent.MOVILES_TAB:
        break;
      case MainComponent.ELEMENTOS_TAB:
        break;
      case MainComponent.PU_TAB:
        break;
      case MainComponent.CIUDADANOS_TAB:
        break;
    }
  }



  // Cada vez que se añade un móvil
  onNewMovil(movil: MovilModel) {
  }

  // Oculta el tab de moviles
  hideMovilesTab() {
    this.formMovVisible = false;
    this.selectOpenTab();
    MenuComponent.getInstance().menu.disable(25345, false);
  }

  // Oculta el tab de elementos
  hideElementsTab() {
    this.formElemVisible = false;
    this.selectOpenTab();
  }

  // Oculta el tab de puntos de ubicación
  hidePUTab() {
    this.formPuVisible = false;
    // Oculto los puntos de ubicación en la cartografía
    PuComponent.getInstance().hidePU();
    this.selectOpenTab();
  }

  // Oculta el tab de ciudadanos
  hideCiudadanosTab() {
    if (CiudadanosComponent._this.marker) {
      CiudadanosComponent._this.map.removeMarker(CiudadanosComponent._this.marker);
    }

    this.setFormCiudadanoVisible(false);
    this.selectOpenTab();
  }

  // Cada vez que se modifica un móvil
  onChangeMovil(movil: MovilModel) {
    // Si el móvil tiene un marcador vuelvo a generar el contenido por si ha cambiado algo que tenga
    // que ver con el móvil y también el icono por si ha cambiado
    if (movil.marker !== undefined && movil.ultimaPos !== undefined) {
      // Obtengo el nuevo título para el marcador y el contenido del mismo
      // De acuerdo a los datos del móvil y la última posición
      movil.marker.setTitle(this.getMarketTitle(movil.ultimaPos));
      movil.marker.setContent(this.getMarketContent(movil.ultimaPos));
      // Asigno de nuevo el icono por si ha cambiado
      movil.marker.setIcon(movil.ConjuntoVehiculo.Icono ? 'data:image/png;base64,' +
        movil.ConjuntoVehiculo.Icono : 'assets/images/car.png');
    }
  }

  // Cuando se han terminado de recuperar las posiciones de todos los móviles
  onPosicionesReady() {
  }

  // Cada vez que se añade una última posición de un móvil nuevo
  // que no estaba en la carga inicial
  onNewPosicion(posicion: PosicionModel) {
    // Creo un marcador con los datos de la posición
    const marker = this.map.addMarker({
      dataModel: posicion,
      title: this.getMarketTitle(posicion),
      content: this.getMarketContent(posicion),
      position: new MapLatLng(posicion.Lat, posicion.Lng),
      icon: this.resourcesService.getMovil(posicion.MovilId).ConjuntoVehiculo.Icono ? 'data:image/png;base64,' +
        this.resourcesService.getMovil(posicion.MovilId).ConjuntoVehiculo.Icono : 'assets/images/car.png',
      zIndex: 0,
      drag: false
    });
    const movil = this.moviles.get(posicion.MovilId);
    if (movil) {
      // Asigno el marcador al móvil y la última posición
      movil.marker = marker;
      movil.ultimaPos = posicion;
    }
  }

  // Cada vez que se modifica la última posición de un móvil
  onChangePosicion(posicion: PosicionModel) {
    // Recupero el móvil de la lista y modifico los datos de su marcador
    const movil = this.moviles.get(posicion.MovilId);
    if (movil) {
      // Esto es para actualizar la posición sin animación
      // movil.ultimaPos = posicion;
      // movil.marker.setPosition(new MapLatLng(posicion.Lat, posicion.Lng));
      // movil.marker.setContent(this.getMarketContent(posicion));
      this.animateMovilMarker(movil, posicion);
    }
  }

  animateMovilMarker(movil: MovilModel, posicion: PosicionModel) {
    const deltaLat = (posicion.Lat - movil.marker.position.lat) / 10;
    const deltaLng = (posicion.Lng - movil.marker.position.lng) / 10;
    if (deltaLat !== 0 || deltaLng !== 0) {
      let j = 0;
      const auxPos = { ...posicion };
      const timer = setInterval(() => {
        if (j < 10) {
          auxPos.Lat = movil.marker.position.lat + deltaLat;
          auxPos.Lng = movil.marker.position.lng + deltaLng;
          movil.marker.setPosition(new MapLatLng(auxPos.Lat, auxPos.Lng));
          j++;
        } else {
          movil.ultimaPos = posicion;
          movil.marker.setPosition(new MapLatLng(posicion.Lat, posicion.Lng));
          movil.marker.setContent(this.getMarketContent(posicion));
          clearInterval(timer);
        }
      }, 100);
    } else {
      movil.ultimaPos = posicion;
      movil.marker.setPosition(new MapLatLng(posicion.Lat, posicion.Lng));
      movil.marker.setContent(this.getMarketContent(posicion));
    }
  }

  // Recupera el último centro del mapa y el nivel de zoom para el usuario
  // actual, empresa y aplicación
  getLastCenterMap() {
    const center = JSON.parse(LocalStorage.getItem(this.ssoTicket, 'mapCenter'));
    const zoom = LocalStorage.getItem(this.ssoTicket, 'mapZoom');
    if (center !== null) {
      this.center = center;
    }
    if (zoom !== null) {
      this.zoom = Number.parseInt(zoom);
    }
  }

  //Cuando se selecciona un mapa
  onClickTabMap(event: any, index: number) {
    if (index == undefined) {
      this.tabMapActive = 0;
    } else {
      this.tabMapActive = index;
    }
    const tabcontent: any = document.getElementsByClassName('tabcontent');
    let tabsInfo = this.tabsInfo.find(tab => tab.id !== null && tab.id == event.id);

    this.listComponentsMenu.forEach((component) => {
      let hasJqxWindowsComponent: any = Object.values(component.instance || component).find(this.isJqxWindowsComponent);
      if (hasJqxWindowsComponent) {
        hasJqxWindowsComponent.collapse();
      }
    });

    if (tabsInfo) {
      tabsInfo.activated = true;
      // desactivo el resto de tabs
      this.tabsInfo = this.tabsInfo.filter(tab => tab.id !== null);
      this.tabsInfo.forEach(tab => {
        if (tab.id !== tabsInfo.id) {
          tab.activated = false;
          let hasJqxWindowsComponent: any = Object.values(tab?.componentRef?.instance).find(this.isJqxWindowsComponent);
          if (hasJqxWindowsComponent) {
            hasJqxWindowsComponent.hide();
          }
        }
      });
      let hasJqxWindowsComponent: any = Object.values(tabsInfo?.componentRef?.instance).find(this.isJqxWindowsComponent);
      if (hasJqxWindowsComponent) {
        hasJqxWindowsComponent.open();
      }
    } else {
      this.tabsInfo.forEach(tab => {
        tab.activated = false;
        let hasJqxWindowsComponent: any = Object.values(tab?.componentRef?.instance).find(this.isJqxWindowsComponent);
        if (hasJqxWindowsComponent) {
          hasJqxWindowsComponent.hide();
        }
      });
    }

    // Oculto todas los mapas
    if (tabcontent && index !== undefined) {
      for (let i = 0; i < tabcontent.length; i++) {
        if (tabcontent[i].localName === 'div') {
          tabcontent[i].style.display = 'none';
        }
      }
    }
    const tablinks = document.getElementsByClassName('tablinks');
    if (tablinks) {
      for (let i = 0; i < tablinks.length; i++) {
        tablinks[i].className = tablinks[i].className.replace(' active', '');
      }
    }
    // Activo el mapa actual o tab actual
    if (this.tabsInfo.filter(item => item.activated !== false) && index !== undefined) {
      document.getElementById('map' + index).style.display = 'block';
      event.currentTarget.className += ' active';
      // Esto es para que se refresquen los marcadores en el mapa principal
      if (index === 0) {
        const t = setTimeout(() => {
          clearTimeout(t);
          this.map.onBoundsChange(this.map.getBounds());
        }, 2000);
      }
    }
  }

  isJqxWindowsComponent(obj) {
    return obj instanceof jqxWindowComponent;
  }

  // Crea un nuevo mapa (máximo 5)
  createMap(label: string, icon: string, closeButton: boolean, onMapReady: any = null): number {
    if (this.maps) {
      for (let i = 1; i < this.maps.length; i++) {
        if (this.maps[i].destroyed) {
          this.maps[i].label = label ? label : this.translate('Mapa') + '-' + i;
          this.maps[i].icon = icon ? icon : 'assets/images/carto.png';
          this.maps[i].onMapReadyExternal = onMapReady;
          this.maps[i].visible = true;
          this.maps[i].destroyed = false;
          this.maps[i].closeButton = closeButton;
          const t = setTimeout(() => {
            clearTimeout(t);
            document.getElementById('tabMap' + i).click();
            this.maps[i].map.resize();
          }, 500);
          return i;
        }
      }
    }
    this.showError('ATENCION', 'No_mas_mapas', 2000);
    return -1;
  }

  createTabInfo(label: string, icon: string, closeButton: boolean, onMapReady: any = null): number {
    for (let i = 1; i < this.tabsInfo.length; i++) {
      const t = setTimeout(() => {
        clearTimeout(t);
        document.getElementById('tabInfo' + i).click();
      }, 500);
      return i;
    }

  }

  // Visualiza un mapa
  showMap(index: number) {
    if (!this.maps[index].destroyed) {
      this.maps[index].visible = true;
      const t = setTimeout(() => {
        clearTimeout(t);
        document.getElementById('tabMap' + index).click();
        this.maps[index].map.resize();
      }, 500);
    }
  }

  // Oculta un mapa
  hideMap(index: number) {
    if (this.maps[index].visible) {
      this.maps[index].visible = false;
      // Si se trata del mapa activo busco el siguiente a la derecha para activarlo
      let i = index;
      for (; i < this.maps.length; i++) {
        if (this.maps[i].visible) {
          this.showMap(i);
          this.tabMapActive = i;
          break;
        }
      }
      // Si no encuentro ninguno a la derecha busco hacia la izquierda
      if (i >= this.maps.length) {
        for (i = index - 1; i >= 0; i--) {
          if (this.maps[i].visible) {
            this.showMap(i);
            this.tabMapActive = i;
            break;
          }
        }
      }
    }
  }

  // Oculta un mapa y lo destruye
  removeMap(index: number) {
    this.hideMap(index);
    this.maps[index].destroyed = true;
  }

  // Devuelve el mapa indicado (0-5) 0=Principal)
  public getMap(index: number = 0): MapComponent {
    return (index < this.maps.length && !this.maps[index].destroyed) ? this.maps[index].map : null;
  }

  // Devuelve el mapa activo
  public getActiveMap(): MapComponent {
    return this.maps[this.tabMapActive].map;
  }

  // Visualiza un tab de gestión
  public showGestionTab(index: number) {
    if (!this.gestionTabs[index].destroyed) {
      this.downSplitter.expand();
      this.gestionTabs[index].visible = true;
      const t = setTimeout(() => {
        clearTimeout(t);
        document.getElementById('tabGestion' + index).click();
      }, 500);
    }
  }

  // Oculta un tab de gestión
  public hideGestionTab(index: number) {
    if (this.gestionTabs[index].visible) {
      this.selectOpenTab();
      this.gestionTabs[index].visible = false;
      // Si se trata de tab activo busco el siguiente a la derecha para activarlo
      let i = index;
      for (; i < this.gestionTabs.length; i++) {
        if (this.gestionTabs[i].visible) {
          this.showGestionTab(i);
          return;
        }
      }
      // Si no encuentro ninguno a la derecha busco hacia la izquierda
      if (i >= this.gestionTabs.length) {
        for (i = index - 1; i >= 0; i--) {
          if (this.gestionTabs[i].visible) {
            this.showGestionTab(i);
            return;
          }
        }
      }
      const t = setTimeout(() => {
        clearTimeout(t);
        document.getElementById('tabMoviles').click();
      }, 500);
    }
  }

  // Oculta un tag de gestión y lo libera
  public removeGestionTab(index: number) {
    this.hideGestionTab(index);
    this.gestionTabs[index].destroyed = true;
    if (this.gestionTabs[index].onClose) {
      const t = setTimeout(() => {
        clearTimeout(t);
        this.gestionTabs[index].onClose(index);
      }, 0);
    }
  }

  // Crea un nuevo tab para gestión (máximo 5)
  public createGestionTab(label: string, icon: string, closeButton: boolean, onClose: any = null): number {
    if (this.gestionTabs) {
      for (let i = 0; i < this.gestionTabs.length; i++) {
        if (this.gestionTabs[i].destroyed) {
          this.gestionTabs[i].label = label ? label : this.translate('Gestion') + '-' + i;
          this.gestionTabs[i].icon = icon ? icon : 'assets/images/gestion.png';
          this.gestionTabs[i].onClose = onClose;
          this.gestionTabs[i].visible = true;
          this.gestionTabs[i].destroyed = false;
          this.gestionTabs[i].closeButton = closeButton;
          const t = setTimeout(() => {
            clearTimeout(t);
            document.getElementById('tabGestion' + i).click();
          }, 1000);
          return i;
        }
      }
    }
    this.showError('ATENCION', 'No_mas_tab_gestion', 2000);
    return null;
  }

  manageActivatedTabs(activeTabId) {
    this.tabsInfo.forEach(tab => {
      tab.activated = (tab.id === activeTabId);
    });

    if (this.listComponentsMenu.length) {

      this.listComponentsMenu.forEach(component => {
        let hasJqxWindowsComponent: any = Object.values(component.instance).find(this.isJqxWindowsComponent);
        if (hasJqxWindowsComponent) {
          hasJqxWindowsComponent.collapse();
        }
      });
    }
  }

  // Método para crear y configurar un componente
  private createAndConfigureComponent(nombre: string, config: ComponentConfig, visible: boolean): ComponentRef<any> {
    // Creo el componente
    const componentRef = config.componentFactory();
    // Lo añado al array de componentes
    this.tabsInfo.push({
      id: nombre,
      component: componentRef.instance,
      componentRef: componentRef,
      visible: visible,
      activated: true,
      label: nombre,
      destroyed: false,
    });

    // desactivo el resto de tabs
    this.tabsInfo.forEach(tab => {
      if (tab.id !== nombre) {
        tab.activated = false;
      }
    });

    return componentRef;
  }

  public createComponent(nombre: string, visible: boolean): ComponentRef<any> {
    // Configuración para cada tipo de componente
    const componentConfigs: { [key: string]: ComponentConfig } = {
      'Historico_envios_dispositivo_identificador': {
        componentFactory: () => this.menuService.createComponent(HistoricoEnviosDispIdentificadorComponent),
      },
      'Listado_alarmas_geo': {
        componentFactory: () => this.menuService.createComponent(ListadoAlarmaComponent),
      },
      'Historicos_identificaciones': {
        componentFactory: () => this.menuService.createComponent(ListadoIdentificacionComponent),
      }
    };

    const tab = this.tabsInfo.find(tab => tab.label === nombre);
    if (!tab) {
      const config = componentConfigs[nombre];
      if (config) {
        return this.createAndConfigureComponent(nombre, config, visible);
      }
    }
  }

  public destroyComponent(item: any): void {
    if (item.componentRef) {
      item.componentRef.destroy();
      let hasJqxWindowsComponent: any = Object.values(item.componentRef?.instance).find(this.isJqxWindowsComponent);
      if (hasJqxWindowsComponent) {
        hasJqxWindowsComponent.destroy();
        item.visible = false;
        item.componentRef = null;
      }

      if (this.tabsInfo.filter(item => item.activated !== false) && this.tabMapActive !== undefined) {
        document.getElementById('map' + this.tabMapActive).style.display = 'block';
      }
    }

    // elimino de la lista de componentes
    this.tabsInfo = this.tabsInfo.filter(tab => tab.componentRef !== null);

  }

  // Devuelve el div de gestión
  public getGestionContainer(index: number): ViewContainerRef {
    let res: ViewContainerRef = null;
    switch (index) {
      case 0:
        res = this.divGestion0;
        break;
      case 1:
        res = this.divGestion1;
        break;
      case 2:
        res = this.divGestion2;
        break;
      case 3:
        res = this.divGestion3;
        break;
      default:
        res = this.divGestion4;
    }
    res.clear();
    return res;
  }

  // Cuando el mapa principal se ha cargado y está listo para ser usado
  async onMapReady(map: MapComponent) {
    this.map = map;
    this.maps[0].map = map;
    // Cargo la lista de móviles y me subscribo para recibir los cambios
    this.movilesList = await this.resourcesService.getMoviles();
    if (this.moviles) {
      this.movilesList.forEach(movil => {
        this.moviles.set(movil.Codigo, movil);
      });
    }
    this.subcriptionNewMovil = this.resourcesService.subscribeNewMoviles(this);
    this.subcriptionChangeMovil = this.resourcesService.subscribeChangeMoviles(this);
    // Cargo las últimas posiciones y me subscribo para recibir los cambios
    this.posiciones = await this.positionService.getLastPositions();
    this.subcriptionNewPosicion = this.positionService.subscribeNewPosition(this);
    this.subcriptionChangePosicion = this.positionService.subscribeChangePosition(this);
    // Cargo los marcadores con la última posición de cada móvil
    this.createMarkerPos();
    // Cargo los KML de la empresa
    this.loadKmls();
    // Cargo las zonas
    this.zonesService.loadZones();
    // Cargo el marco greográfico
    this.loadMarcoGeografico();
    // Muestro la casa
    if (await this.configService.getEmpApp('ver-casa', 'false') === 'true') {
      this.showCasa();
    }
    //Mostrar ambito-actividad
    if (await this.configService.getEmpApp('ver-ambito-actividad', 'false') === 'true') {
      this.showAmbito();
    }
  }

  async loadAmbitoActividad() {
    let valid = false;
    // Cargo el ámbito de actividad
    const ambActividad = await this.configService.getEmpApp('ambito-actividad', null);
    if (ambActividad) {
      const aa = JSON.parse(ambActividad);
      // El ámbito de actividad solo vale si se han definido zonas
      if (aa.zonas.length > 0) {
        const bound = new MapBounds(aa.marco.swCorner, aa.marco.neCorner);
        // El ambito de actividad siempre tiene que estar dentro del marco geográfico
        if (this.marcoGeografico.marco.contains(bound.swCorner) && this.marcoGeografico.marco.contains(bound.neCorner)) {
          valid = true;
          this.ambitoActividad = new AmbitoActividadModel(bound, aa.zonas);
        }
      }
    }
    if (!valid) {
      // Si no se ha definido pongo las coordenadas del marco geográfico
      this.ambitoActividad = new AmbitoActividadModel(this.marcoGeografico.marco, []);
      this.configService.setEmpApp('ambito-actividad', JSON.stringify(this.ambitoActividad));
    }
    // Creo el marco a efectos de búsquedas en el mapa con el encuadre del ambito de actividad
    this.searchBounds = this.ambitoActividad.marco.swCorner.lat + ',' + this.ambitoActividad.marco.swCorner.lng +
      ',' + this.ambitoActividad.marco.neCorner.lat + ',' + this.ambitoActividad.marco.neCorner.lng;
  }

  async loadMarcoGeografico() {
    // Cargo el marco cartografico
    const marcoGeo = await this.configService.getEmpApp('marco-geografico', null);
    if (marcoGeo) {
      const mc = JSON.parse(marcoGeo);
      const bound = new MapBounds(mc.marco.swCorner, mc.marco.neCorner);
      this.marcoGeografico = new MarcoGeograficoModel(bound);
    } else {
      // Si no se ha definido pongo un marco que engloba Canarias y la Península Ibérica
      this.marcoGeografico = new MarcoGeograficoModel(new MapBounds(new MapLatLng(26.063926, -19.225307),
        new MapLatLng(44.141537, 6.063661)));
      this.configService.setEmpApp('marco-geografico', JSON.stringify(this.marcoGeografico));
      this.configService.setEmpApp('ver-marco-geografico', 'false');
    }
    // Para que se aplique el marco cartográfico
    if (!this.marcoGeografico.marco.contains(this.map.center)) {
      this.map.onBoundsChange(this.map.getBounds());
    } else {
      this.minZoom = this.map.zoom;
      try {
        const t = setTimeout(() => {
          clearTimeout(t);
          this.lastBounds = this.map.getBounds();
        }, 500);
      } catch (error) {
      }
    }
    // Muestro el marco cartográfico
    if (await this.configService.getEmpApp('ver-marco-geografico', 'false') === 'true') {
      this.showMarcoGeografico();
    } else {
      if (this.marcoCartoPolygon) {
        this.map.removePolygon(this.marcoCartoPolygon);
        this.marcoCartoPolygon = null;
      }
    }
    // Cargo el ámbito de actividad
    this.loadAmbitoActividad();
  }

  public showMarcoGeografico(save: boolean = true) {
    if (this.marcoCartoPolygon) {
      this.map.removePolygon(this.marcoCartoPolygon);
      this.marcoCartoPolygon = null;
    }
    this.marcoCartoPolygon = this.map.addPolygon({
      strokeColor: '#ff0000',
      strokeOpacity: 0.3,
      strokeWeight: 4,
      fillColor: '#000000',
      fillOpacity: 0
    });
    this.map.addPolygonPoint(this.marcoCartoPolygon, {
      dataModel: this,
      content: this.translate('Marco_geografico'),
      position: this.marcoGeografico.marco.swCorner
    });
    this.map.addPolygonPoint(this.marcoCartoPolygon, {
      dataModel: this,
      content: this.translate('Marco_geografico'),
      position: new MapLatLng(this.marcoGeografico.marco.neCorner.lat, this.marcoGeografico.marco.swCorner.lng)
    });
    this.map.addPolygonPoint(this.marcoCartoPolygon, {
      dataModel: this,
      content: this.translate('Marco_geografico'),
      position: this.marcoGeografico.marco.neCorner
    });
    this.map.addPolygonPoint(this.marcoCartoPolygon, {
      dataModel: this,
      content: this.translate('Marco_geografico'),
      position: new MapLatLng(this.marcoGeografico.marco.swCorner.lat, this.marcoGeografico.marco.neCorner.lng)
    });
    if (save) {
      this.configService.setEmpApp('ver-marco-geografico', 'true');
    }
  }

  public hideMarcoCartografico(save: boolean = true) {
    if (this.marcoCartoPolygon) {
      this.map.removePolygon(this.marcoCartoPolygon);
      this.marcoCartoPolygon = null;
    }
    if (save) {
      this.configService.setEmpApp('ver-marco-geografico', 'false');
    }
  }

  public async showCasa() {
    const res = await this.configService.getEmpApp('casa', null);
    if (res) {
      const casa: CasaModel = JSON.parse(res);
      if (this.casaMarker) {
        this.map.removeMarker(this.casaMarker);
      }
      this.casaZoom = casa.zoom;
      this.casaMarker = this.map.addMarker({
        dataModel: this,
        title: this.translate('Casa'),
        content: this.translate('Casa'),
        position: casa.posicion,
        icon: '/assets/images/casa.png',
        zIndex: 0,
        drag: false,
        visible: true
      });
    }
  }

  public hideCasa(save: boolean = true) {
    if (save) {
      this.configService.setEmpApp('ver-casa', 'false');
    }
    if (this.casaMarker) {
      this.map.removeMarker(this.casaMarker);
      this.casaMarker = null;
    }
  }

  // Mostrar ambito
  public async showAmbito() {
    this.arrPolygonsGeojson = [];
    this.arrCircles = [];
    const visible = await this.configService.getEmpApp('ver-ambito-actividad', 'false');
    const res = await this.configService.getEmpApp('ambito-actividad', null);
    const zones = await this.zonesService.getZonas();
    if (res && zones && visible === 'true') {
      const ambito: AmbitoActividadModel = JSON.parse(res);
      this.showMarcoAmbito(ambito);
      // Filtrar zonas para poder dibujarlas
      const result = zones.filter(e => {
        return ambito.zonas.indexOf(e.Id) != -1;
      });
      result.forEach(zona => {
        if (zona.Geometria != null) {
          let polygonGeoJson = this.map.addPolygonsFromGeoJson(zona.Geometria, false, {
            dataModel: zona,
            content: zona.Nombre,
            strokeColor: '#227481',
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: '#F3FF00',
            fillOpacity: 0.3,
            zIndex: 100,
          })[0];
          this.arrPolygonsGeojson.push(polygonGeoJson);
        } else {
          let circle = this.map.addCircle({
            dataModel: zona,
            content: zona.Nombre,
            strokeColor: '#227481',
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: '#F3FF00',
            fillOpacity: 0.3,
            position: new MapLatLng(zona.Lat, zona.Lng),
            radius: zona.Radio,
            draggable: false,
            editable: false
          });
          this.arrCircles.push(circle);
        }
      });
    }
  }

  public hideAmbito(save: boolean = true) {
    if (save) {
      this.configService.setEmpApp('ver-ambito-actividad', 'false');
    }
    this.arrPolygonsGeojson.forEach(polygon => {
      this.map.removePolygon(polygon);
    });
    this.arrCircles.forEach(circle => {
      this.map.removeCircle(circle);
    });
    if (this.marcoAmbitoPolygon) {
      this.map.removePolygon(this.marcoAmbitoPolygon);
      this.marcoAmbitoPolygon = null;
    }

    this.arrCircles = [];
    this.arrPolygonsGeojson = [];
  }

  //dibujar polígono(rectangulo) que contiene el ambito de actividad
  public showMarcoAmbito(ambito: AmbitoActividadModel) {
    if (!this.verMarcoAmbito) {
      return;
    }
    if (this.marcoAmbitoPolygon) {
      this.map.removePolygon(this.marcoAmbitoPolygon);
      this.marcoAmbitoPolygon = null;
    }
    this.marcoAmbitoPolygon = this.map.addPolygon({
      strokeColor: '#2500bb',
      strokeOpacity: 0.3,
      strokeWeight: 4,
      fillColor: '#000000',
      fillOpacity: 0
    });
    this.map.addPolygonPoint(this.marcoAmbitoPolygon, {
      dataModel: this,
      content: this.translate('Ambito_actividad'),
      position: ambito.marco.swCorner
    });
    this.map.addPolygonPoint(this.marcoAmbitoPolygon, {
      dataModel: this,
      content: this.translate('Ambito_actividad'),
      position: new MapLatLng(ambito.marco.neCorner.lat, ambito.marco.swCorner.lng)
    });
    this.map.addPolygonPoint(this.marcoAmbitoPolygon, {
      dataModel: this,
      content: this.translate('Ambito_actividad'),
      position: ambito.marco.neCorner
    });
    this.map.addPolygonPoint(this.marcoAmbitoPolygon, {
      dataModel: this,
      content: this.translate('Ambito_actividad'),
      position: new MapLatLng(ambito.marco.swCorner.lat, ambito.marco.neCorner.lng)
    });
  }

  public async loadKmls() {
    // Borro todos los KML antes de nada
    if (this.kmlObj) {
      for (const [key, value] of this.kmlObj) {
        this.map.removeKml(value);
      }
    }
    // Recupero los KML de la empresa y los pinto en el mapa
    this.kml = await this.kmlService.getKmls();
    if (this.kml) {
      this.kml.forEach(async (kml) => {
        const obj = await this.map.loadKml(kml.Url);
        this.kmlObj.set(kml.Id, obj);
      });
    }
  }

  // Hace un zoom de encuadre del KML
  public centrarKml(id: number) {
    const kml = this.kmlObj.get(id);
    if (kml) {
      const b = new MapBounds(new MapLatLng(kml.defaultViewport.getSouthWest().lat(), kml.defaultViewport.getSouthWest().lng()),
        new MapLatLng(kml.defaultViewport.getNorthEast().lat(), kml.defaultViewport.getNorthEast().lng()));
      this.map.fitTo(b);
    }
  }

  // Crea el marcador con la última posición de cada móvil
  async createMarkerPos() {
    this.movilesList.forEach(movil => {
      if (movil.marker) {
        this.map.removeMarker(movil.marker);
      }
    });
    this.moviles.clear();
    this.movilesList = await this.resourcesService.getMoviles();
    this.movilesList.forEach(movil => {
      this.moviles.set(movil.Codigo, movil);
    });
    // Cargo los marcadores con la última posición de cada móvil
    this.posiciones.forEach(pos => {
      // Sólo asigno marcadores a los móviles cargados
      const movil = this.moviles.get(pos.MovilId);
      if (movil) {
        // Creo un marcador con los datos de la posición
        const marker = this.map.addMarker({
          dataModel: pos,
          title: this.getMarketTitle(pos),
          content: this.getMarketContent(pos),
          position: new MapLatLng(pos.Lat, pos.Lng),
          icon: this.resourcesService.getMovil(pos.MovilId).ConjuntoVehiculo.Icono ? 'data:image/png;base64,' +
            this.resourcesService.getMovil(pos.MovilId).ConjuntoVehiculo.Icono : 'assets/images/car.png',
          zIndex: 0,
          drag: false,
        });
        // Asigno el marcador al móvil y la última posición
        movil.marker = marker;
        movil.ultimaPos = pos;
      }
    });
  }

  // Devuelve el contenido que se mostrará al pinchar sobre un marcador de última posición
  getMarketContent(posicion: PosicionModel): string {
    return '<b>' + this.resourcesService.getMovil(posicion.MovilId).Nombre + '</b><hr>' +
      DateUtils.formatDateTimeShort(posicion.Fecha, true) + '<br>' +
      posicion.Velocidad + ' km/h<br>' + (posicion.Ignicion ? AppComponent.translate('Con_ignicion') :
        AppComponent.translate('Sin_ignicion'));
  }

  // Devuelve el título del marcador de última posición
  getMarketTitle(posicion: PosicionModel): string {
    return this.resourcesService.getMovil(posicion.MovilId).Nombre;
  }

  // Cuando se modifica el tamaño del spliter de abajo
  onResizeSpliter(event: any) {
    if (this.map) {
      this.map.resize();
      this.map.onBoundsChange(this.map.getBounds());
    }
  }

  // Cuando se modifica el tamaño del spliter de la derecha
  onResizeSpliterRight(event: any) {
    this.map.resize();
  }

  // getNumElements(): string {
  //   if (ElementsComponent.getInstance().elementList) {
  //     return ElementsComponent.getInstance().count();
  //   }
  // }

  // getNumPU(): number {
  //   if (PuComponent.getInstance().puListTotal) {
  //     return PuComponent.getInstance().puListTotal.length;
  //   }
  //   return 0;
  // }

  getNumCiudadanos(): string {
    if (CiudadanosComponent.getInstance().ciudadanos) {
      return CiudadanosComponent.getInstance().countCiudadanos();
    }
  }

  // Cuando se hace click sobre el mapa
  onMapClick(pos: MapLatLng) {
  }

  // Cuando se mueve el cursor sobre el mapa
  onMapMouseMove(pos: MapLatLng) {
  }

  // Cuando cambia el encuadre del mapa
  onBoundsChange(bounds: MapBounds) {
    if (this.marcoGeografico) {
      if (!this.lastBounds) {
        this.map.fitTo(this.marcoGeografico.marco);
        this.lastZoom = this.map.zoom;
        this.minZoom = this.map.zoom;
      } else {
        if (!this.lastBounds && (bounds.contains(this.marcoGeografico.marco.swCorner) ||
          bounds.contains(this.marcoGeografico.marco.neCorner))) {
          this.map.fitTo(this.marcoGeografico.marco);
          this.minZoom = this.map.zoom;
        } else {
          if (!this.marcoGeografico.marco.contains(this.map.center)) {
            if (this.lastBounds.contains(this.marcoGeografico.marco.swCorner) &&
              this.lastBounds.contains(this.marcoGeografico.marco.neCorner)) {
              this.map.setZoom(this.lastZoom + 1);
              this.map.fitTo(this.marcoGeografico.marco);
            } else {
              this.map.fitTo(this.lastBounds);
            }
            return;
          } else {
            if (bounds.contains(this.marcoGeografico.marco.swCorner) &&
              bounds.contains(this.marcoGeografico.marco.neCorner) && this.map.zoom < this.lastZoom) {
              this.map.setZoom(Math.max(this.lastZoom, this.minZoom));
              this.map.fitTo(this.marcoGeografico.marco);
              const center = {
                lat: this.marcoGeografico.marco.swCorner.lat +
                  (this.marcoGeografico.marco.neCorner.lat - this.marcoGeografico.marco.swCorner.lat) / 2,
                lng: this.marcoGeografico.marco.swCorner.lng +
                  (this.marcoGeografico.marco.neCorner.lng - this.marcoGeografico.marco.swCorner.lng) / 2
              };
              LocalStorage.setItem(this.ssoTicket, 'mapCenter', JSON.stringify(center));
              LocalStorage.setItem(this.ssoTicket, 'mapZoom', '' + this.map.zoom);
              return;
            }
          }
        }
      }
      this.lastBounds = bounds;
      this.lastZoom = this.map.zoom;
      const center = {
        lat: bounds.swCorner.lat + (bounds.neCorner.lat - bounds.swCorner.lat) / 2,
        lng: bounds.swCorner.lng + (bounds.neCorner.lng - bounds.swCorner.lng) / 2
      };
      LocalStorage.setItem(this.ssoTicket, 'mapCenter', JSON.stringify(center));
      LocalStorage.setItem(this.ssoTicket, 'mapZoom', '' + this.map.zoom);
    }
  }

  public controlAmbitoActividad(pos: MapLatLng): boolean {
    if (this.ambitoActividad && this.ambitoActividad.marco) {
      if (this.ambitoActividad.marco.contains(pos)) {
        if (this.ambitoActividad.zonas.length > 0) {
          for (let i = 0; i < this.ambitoActividad.zonas.length; i++) {
            const zona = this.zonesService.zonas.get(this.ambitoActividad.zonas[i]);
            if (zona) {
              switch (zona.TipoGeo) {
                case 0: // Círculo
                  return (GeoUtils.getDistance(pos.lat, pos.lng, zona.Lat, zona.Lng) <= zona.Radio);
                case 1: // Polígono
                  for (let j = 0; j < zona.Geometria.geometry.coordinates.length; j++) {
                    for (let k = 0; k < zona.Geometria.geometry.coordinates[j].length; k++) {
                      if (GeoUtils.pointIntoPolygon(pos, zona.Geometria.geometry.coordinates[j][k])) {
                        return true;
                      }
                    }
                  }
                  break;
              }
            }
          }
          return false;
        }
        return true;
      }
      return false;
    }
    if (this.marcoGeografico && this.marcoGeografico.marco) {
      return this.marcoGeografico.marco.contains(pos);
    }
    return true;
  }

  // Devuelve el entorno (dev, pre o prod)
  public getEntorno(): string {
    return AppComponent.getInstance().entorno;
  }

  // Cuando cambia el nivel de zoom del mapa
  onZoomChange(zoom: number) {
    LocalStorage.setItem(this.ssoTicket, 'mapZoom', '' + zoom);
    if (this.tabsInfo.length > 0) {
      let mapActive = document.getElementById('map0');
      if (mapActive) {
        mapActive.style.display = 'block';
        document.getElementById('tabMap0').click();
      }

      this.tabsInfo.forEach(component => {
        let hasJqxWindowsComponent: any = Object.values(component.componentRef.instance).find(this.isJqxWindowsComponent);
        if (hasJqxWindowsComponent) {
          hasJqxWindowsComponent.hide();
        }
      });
    }
  }

  public hasMenuAction(section: string) {
    return CustomForms.hasMenuAction(section);
  }

  onMarkerClick(marker: MapMarker) {
  }

  onMarkerDragEnd(marker: MapMarker) {
  }

  onMarkerMove(marker: MapMarker) {
  }

  onPolylineClick(point: MapPolylinePoint) {
  }

  onPolygonClick(point: MapPolygonPoint) {
  }

  onPolygonDragEnd(point: MapPolygonPoint) {
  }

  onCircleClick(circle: MapCircle) {
  }

  onCircleDragEnd(circle: MapCircle) {
  }

  onCircleRadiusChanged(circle: MapCircle) {
  }

  onMapSearch(search: MapSearch) {
  }

}
