import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { DateUtils } from 'src/app/utils/date-utils';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { AppComponent } from 'src/app/app.component';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { HeaderComponent } from '../../header/header.component';
import { RangoModel } from 'src/app/services/ciudadanos/models/rango.model';
import { RangosService } from 'src/app/services/ciudadanos/tarjetas/rangos.service';
import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { FormRangoComponent } from './form-rango/form-rango.component';
import { TarjetasCiudadanosComponent } from '../tarjetas-ciudadanos.component';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { MapBounds, MapCircle, MapComponent, MapLatLng, MapPolygon } from 'movisat-maps';
import { MainComponent } from '../../main/main.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NumberUtils } from 'src/app/utils/number-utils';
import { RendererUtils } from 'src/app/utils/RendererUtils';
import { UserLangService } from 'src/app/services/common/user-lang/user-lang.service';
import { GeoUtils } from 'src/app/utils/geo-utils';

@Component({
  selector: 'app-tab-rangos',
  templateUrl: './tab-rangos.component.html',
  styleUrls: ['./tab-rangos.component.css']
})
export class TabRangosComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('gridRangos') gridRangos: jqxGridComponent;

  showLoader: boolean = false;

  public static _this: TabRangosComponent;

  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  public checkTarjVirtual = AppComponent.translate('Todas');

  public zonaFilter: string = '';
  public tipoTarjetaFilter: string = '';
  public numeroTarjetaFilter: number = 0;

  // Centrar mapa y añadir marca
  public map: MapComponent;
  public circles: MapCircle[] = [];
  public polygons: MapPolygon[] = [];

  rangos: RangoModel[] = [];
  public zonas: ZonaModel[] = [];

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private modal: NzModalService,
    private zonesService: ZonesService,
    private rangosService: RangosService,
    private userLangService: UserLangService
  ) {
    super();
    TabRangosComponent._this = this;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.userLangService.getUserLangShort());
    this.map = MainComponent.getInstance().getMap();
    this.initGrid();
  }

  ngAfterViewInit(): void {
    this.getRangos();
    this.getZones();

    this.gridRangos.columnsheight(25);
    this.gridRangos.rowsheight(25);
  }

  public columnsRangos;

  public sourceRangos;
  public adapterRangos;

  initGrid() {
    this.columnsRangos = [
      { text: 'Id', columntype: 'textbox', datafield: 'id', hidden: true },
      {
        text: '',
        width: 130,
        columntype: 'text',
        sortable: false,
        editable: false,
        datafield: this.translate('Acciones'),
        groupable: false,
        menu: false,
        filterable: false,
        resizable: false,
        rendered: (columnHeaderElement) => {
          const buttonContainer1 = document.createElement('div');
          buttonContainer1.style.width = '100%';
          buttonContainer1.style.height = '100%';
          buttonContainer1.style.display = 'flex';
          buttonContainer1.style.justifyContent = 'center';
          buttonContainer1.id = `buttonContainerColumn_jqxButton`;
          columnHeaderElement[0].appendChild(buttonContainer1);
          const btnCrear = document.createElement('div');
          btnCrear.innerHTML =
            `<button class="button" style="height: 24px; width: 100%; padding: 0; margin: 0; cursor: pointer !important ; title="` +
            AppComponent.translate('Crear') + `"> <i style="font-size: 18px;" class="fa-solid fa-plus"></i></button>`;
          btnCrear.id = `buttonCrear_jqxButton`;
          buttonContainer1.appendChild(btnCrear);
          btnCrear.style.width = '50%';
          btnCrear.addEventListener('click', async (event: any) => {
            this.onCrearRango();
          });
          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        }
      },
      { text: this.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Descripcion'), width: 150, cellsrenderer: RendererUtils.rendexTextGeneric, },
      {
        text: this.translate('Num_Cerr'), columntype: 'textbox', filtertype: 'number', datafield: this.translate('Num_Cerr'), cellsalign: 'right', width: 50, cellsrenderer: RendererUtils.numberrenderer,
        tooltipMessage: AppComponent.translate('Numero_cerraduras'),
      },
      {
        text: this.translate('Tarj_Inicial'), columntype: 'textbox', filtertype: 'number', datafield: this.translate('Tarj_Inicial'), cellsalign: 'right', width: 80, cellsrenderer: RendererUtils.numberrenderer,
        tooltipMessage: AppComponent.translate('Tarjeta_inicial'),
      },
      {
        text: this.translate('Tarj_final'), columntype: 'textbox', filtertype: 'number', datafield: this.translate('Tarj_final'), cellsalign: 'right', width: 80, cellsrenderer: RendererUtils.numberrenderer,
        tooltipMessage: AppComponent.translate('Tarjeta_final'),
      },
      { text: this.translate('Total'), columntype: 'textbox', filtertype: 'number', datafield: this.translate('Total'), cellsalign: 'right', width: 80, cellsrenderer: RendererUtils.numberrenderer, },
      {
        text: this.translate('Blanca'), columntype: 'checkbox', datafield: this.translate('Blanca'), cellclassname: this.cellClassCheck, width: 50, cellsrenderer: RendererUtils.rendexTextGeneric,
        filtertype: 'checkedlist',
        filteritems: [
          AppComponent.translate('SI'),
          AppComponent.translate('NO')
        ],
      },
      {
        text: this.translate('Master'), columntype: 'checkbox', datafield: this.translate('Master'), cellclassname: this.cellClassCheck, width: 50, cellsrenderer: RendererUtils.rendexTextGeneric,
        filtertype: 'checkedlist',
        filteritems: [
          AppComponent.translate('SI'),
          AppComponent.translate('NO')
        ],
      },
      {
        text: this.translate('Negra'), columntype: 'checkbox', datafield: this.translate('Negra'), cellclassname: this.cellClassCheck, width: 50, cellsrenderer: RendererUtils.rendexTextGeneric,
        filtertype: 'checkedlist',
        filteritems: [
          AppComponent.translate('SI'),
          AppComponent.translate('NO')
        ],
      },
      {
        text: this.translate('Num_zonas'), columntype: 'textbox', filtertype: 'number', datafield: this.translate('Num_zonas'), cellsalign: 'right', width: 50, cellsrenderer: RendererUtils.numberrenderer,
        tooltipMessage: AppComponent.translate('Numero_zonas'),
      },
      { text: this.translate('Zonas'), columntype: 'textbox', filtertype: 'stringfilter', datafield: this.translate('Zonas'), width: 120, cellsrenderer: RendererUtils.rendexTextGeneric },
      { datafield: 'selec', hidden: true }
    ];

    this.sourceRangos = {
      groupsrenderer: this.groupsrenderer,
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'int', map: 'id' },
        { name: this.translate('Acciones'), type: 'string' },
        { name: this.translate('Descripcion'), type: 'string', map: 'nombre' },
        { name: this.translate('Num_Cerr'), type: 'int', map: 'cerraduras>length' },
        { name: this.translate('Tarj_Inicial'), type: 'int', map: 'idTarjetaInicial' },
        { name: this.translate('Tarj_final'), type: 'int', map: 'idTarjetaFinal' },
        { name: this.translate('Total'), type: 'int', map: 'cantidadTarjetas' },
        { name: this.translate('Blanca'), type: 'string', map: 'listaBlanca' },
        { name: this.translate('Master'), type: 'string', map: 'listaMaster' },
        { name: this.translate('Negra'), type: 'string', map: 'listaNegra' },
        { name: this.translate('Num_zonas'), type: 'int', map: 'zonas>length' },
        { name: this.translate('Zonas'), type: 'string', map: 'zonasNombre' },
        { name: 'selec', map: 'selec', type: 'string' }
      ],
      localdata: this.rangos,
    };
    this.adapterRangos = new jqx.dataAdapter(this.sourceRangos);

    this.columnsRangos.forEach(column => {
      if (column.datafield !== this.translate('Acciones')) {
        if(column.tooltipMessage) {
          column.rendered = (element) => { Utils.tooltiprenderer(element, column.tooltipMessage) };
        }else {
          column.rendered = (element) => { Utils.tooltiprenderer(element) };
        }
      }
    });
  }

  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    let showText: string;

    if (data.groupcolumn.datafield === AppComponent.translate('Blanca')) {
      showText = `
        <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
          <b>`+ AppComponent.translate('Blanca') + `: </b>
      `;

      if (group === true) {
        showText += AppComponent.translate('SI');
        if (data.subGroups.length == 0) {
          showText += ' (' + data?.subItems.length + ')';
        } else {
          showText += ' (' + data?.subGroups.length + ')';
        }
        return showText;
      } else if (group === false) {
        showText += AppComponent.translate('NO');
        if (data.subGroups.length == 0) {
          showText += ' (' + data?.subItems.length + ')';
        } else {
          showText += ' (' + data?.subGroups.length + ')';
        }
        return showText + `</div>`;
      }
    } else if (data.groupcolumn.datafield === AppComponent.translate('Master')) {
      showText = `
        <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
          <b>`+ AppComponent.translate('Master') + `: </b>
      `;

      if (group === true) {
        showText += AppComponent.translate('SI');
        if (data.subGroups.length == 0) {
          showText += ' (' + data?.subItems.length + ')';
        } else {
          showText += ' (' + data?.subGroups.length + ')';
        }
        return showText;
      } else if (group === false) {
        showText += AppComponent.translate('NO');
        if (data.subGroups.length == 0) {
          showText += ' (' + data?.subItems.length + ')';
        } else {
          showText += ' (' + data?.subGroups.length + ')';
        }
        return showText + `</div>`;
      }
    } else if (data.groupcolumn.datafield === AppComponent.translate('Negra')) {
      showText = `
        <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
          <b>`+ AppComponent.translate('Negra') + `: </b>
      `;

      if (group === true) {
        showText += AppComponent.translate('SI');
        if (data.subGroups.length == 0) {
          showText += ' (' + data?.subItems.length + ')';
        } else {
          showText += ' (' + data?.subGroups.length + ')';
        }
        return showText;
      } else if (group === false) {
        showText += AppComponent.translate('NO');
        if (data.subGroups.length == 0) {
          showText += ' (' + data?.subItems.length + ')';
        } else {
          showText += ' (' + data?.subGroups.length + ')';
        }
        return showText + `</div>`;
      }
    }
  }

  onCellClick(event: any) {
    if (event.args.column.datafield === AppComponent.translate('Num_Cerr')) {
      let rowdata = this.gridRangos.getrowdata(event.args.rowindex);

      let rango: RangoModel = this.rangos.find(rang => rang.id === rowdata.id);

      if (rango?.cerraduras?.length > 0) {
        this.openCerraduras(rango);
      }
    }
  }

  cellClassCheck = (row: number, columnfield: any, value: any): string => {
    if (value) {
      return 'checkGray';
    }
    return '';
  }

  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    let rowdata;
    if (isNaN(row)) {
      rowdata = row.bounddata;
    } else {
      rowdata = this.gridRangos.getrowdata(row);
    }

    let rango: RangoModel = this.rangos.find(rang => rang.id === rowdata.id);

    htmlElement.innerHTML = '';
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';
    btnContainer.style.justifyContent = 'start';

    const btnEditar = document.createElement('div');
    btnEditar.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; cursor: pointer; display: flex; align-items: center; justify-content: center;"
        title="`+ AppComponent.translate('Editar') + `">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;

    btnEditar.addEventListener('click', (event: any) => {
      this.onEditarRango(rango);
    });
    btnContainer.appendChild(btnEditar);

    if (rango && rango.zonas && rango?.zonas?.length !== 0) {
      let showButton: boolean = false;

      rango?.asociaciones?.forEach(asociacion => {
        if (asociacion.enviar === false) {
          showButton = true;
        }
      });

      if (showButton) {
        const btnSendRange = document.createElement('div');
        btnSendRange.innerHTML = `
          <button class="button" style="height: 23px; width: 25px; cursor: pointer; display: flex; align-items: center; justify-content: center;"
            title="`+ AppComponent.translate('Enviar_rango') + `">
            <img style="float: left; height: 15px; width: 15px;" src="../assets/images/asignar.png"/>
          </button>
        `;

        btnSendRange.addEventListener('click', (event: any) => {
          TabRangosComponent._this.showLoader = true;

          let zonasToAsociate: any[] = [];
          rango.zonas.forEach(zona => {
            zonasToAsociate.push({
              "idZona": zona.id,
              "enviar": true
            });
          });

          TabRangosComponent._this.rangosService.asociateRangoZona(rango.id, zonasToAsociate).then(
            (result) => {
              TabRangosComponent._this.getRangos();
              TabRangosComponent._this.showLoader = false;
            }, (error) => {
            }
          )
        });

        btnContainer.appendChild(btnSendRange);
      }
    }

    const btnUbicar = document.createElement('div');
    btnUbicar.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; cursor: pointer; display: flex; align-items: center; justify-content: center;"
        title="`+ AppComponent.translate('Centrar_mapa') + `">
        <i class="fa-solid fa-location-dot"></i>
      </button>
    `;

    btnUbicar.addEventListener('click', (event: any) => {
      this.onCentrar(rango);
    });
    btnContainer.appendChild(btnUbicar);

    const btnDelete = document.createElement('div');
    btnDelete.innerHTML = `
      <button class="button" style="height: 23px; width: 25px; cursor: pointer; display: flex; align-items: center; justify-content: center;"
        title="`+ AppComponent.translate('Borrar') + `">
        <i class="fa-solid fa-trash"></i>
      </button>
    `;

    btnDelete.addEventListener('click', (event: any) => {
      this.onBorrar(rango);
    });
    btnContainer.appendChild(btnDelete);

    htmlElement.appendChild(btnContainer);
  }

  onFilter(event) {
    // Itero a través de todos los filtros aplicados
    for (let filterObj of event.args.filters) {
      let column = filterObj.datafield;
      let filter = filterObj.filter.getfilters();

      if (column === this.translate('Blanca')) {
        let filter1;
        filter.forEach(filt => {
          let filtergroup = new jqx.filter();
          let filter_operator = filt.operator;
          let filtercondition = filt.condition;
          let filtervalue;

          if (filt.value === this.translate('NO') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = 0;
          } else if (filt.value === this.translate('SI') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = 1;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridRangos.addfilter(column, filtergroup);
            this.gridRangos.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', filt.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridRangos.addfilter(column, filtergroup);
          }
        });
      } else if (column === this.translate('Master')) {
        let filter1;
        filter.forEach(filt => {
          let filtergroup = new jqx.filter();
          let filter_operator = filt.operator;
          let filtercondition = filt.condition;
          let filtervalue;

          if (filt.value === this.translate('NO') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = 0;
          } else if (filt.value === this.translate('SI') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = 1;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridRangos.addfilter(column, filtergroup);
            this.gridRangos.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', filt.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridRangos.addfilter(column, filtergroup);
          }
        });
      } else if (column === this.translate('Negra')) {
        let filter1;
        filter.forEach(filt => {
          let filtergroup = new jqx.filter();
          let filter_operator = filt.operator;
          let filtercondition = filt.condition;
          let filtervalue;

          if (filt.value === this.translate('NO') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = 0;
          } else if (filt.value === this.translate('SI') && filtercondition !== 'NOT_EQUAL') {
            filtervalue = 1;
          }

          if (filtervalue != undefined) {
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridRangos.addfilter(column, filtergroup);
            this.gridRangos.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', filt.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridRangos.addfilter(column, filtergroup);
          }
        });
      }
    }
  }

  updatefilterconditions = (type: string, defaultconditions: any): string[] => {
    return Utils.updatefilterconditions(type, defaultconditions);
  };

  public filter(cellValue?: any, rowData?: any, dataField?: string, filterGroup?: any, defaultFilterResult?: boolean): any {
    let filterColumns = [
      AppComponent.translate('Descripcion'),
      AppComponent.translate('Total'),
      AppComponent.translate('Zonas'),
      AppComponent.translate('Num_Cerr'),
      AppComponent.translate('Tarj_Inicial'),
      AppComponent.translate('Tarj_final'),
      AppComponent.translate('Num_zonas')
    ]

    return Utils.filterRow(cellValue, dataField, filterGroup, defaultFilterResult, filterColumns);
  }

  async getRangos() {
    try {
      this.showLoader = true;
      const rangos = await this.rangosService.getRangos();
      if (rangos) {
        this.rangos = rangos.map(rango => ({
          ...rango,
          zonasNombre: rango.zonas.map(zona => zona.nombre).join(', ')
        }));
      }
      this.gridRangos.updatebounddata('data');
    } catch (error) {
      console.error(error);
    } finally {
      this.showLoader = false;
    }
  }

  rangosBindComplete() {
    this.rangosService.recordCountRangosUpdate(this.gridRangos.getrows().length);
  }

  cargaGrid() {
    this.rangos.sort((a, b) => a.id > b.id ? 1 : -1);

    this.sourceRangos.localdata = this.rangos;
    this.gridRangos.updatebounddata(this.rangos);
    this.showLoader = false;

    this.onBuscar();
  }

  eventFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.getRangos();
  }

  eventResetFilter() {
    this.header.searchInput['nativeElement'].value = '';
    this.header.dropTipoTarjeta.selectIndex(0);
    this.header.dropZonas.clearFilter();
    this.header.dropZonas.selectIndex(0);
    this.header.numeroTarjeta.val(0);
    this.numeroTarjetaFilter = 0;
    this.onBuscar();
  }

  async getZones() {
    this.zonas.push(new ZonaModel());
    await this.zonesService.getZonas().then(
      res => {
        this.zonas = this.zonas.concat(res);
      }
    );
  }

  onChangeZona(event: any) {
    this.zonaFilter = event;

    this.onBuscar();
  }

  onChangeNumeroTarjeta(event: any) {
    this.numeroTarjetaFilter = parseInt(event);

    this.onBuscar();
  }

  onChangetTipoTarjeta(event: any) {
    this.tipoTarjetaFilter = event;

    this.onBuscar();
  }

  onBuscar() {
    let filtervalue = '';

    if (this.header.searchInput['nativeElement'].value.length >= 3) {
      filtervalue = this.header.searchInput['nativeElement'].value;
    } else {
      filtervalue = '';
    }

    this.rangos?.forEach(rang => {
      if(
        (
          rang?.nombre.compareWith(filtervalue) ||
          (
            !NumberUtils.isNaN(parseInt(filtervalue)) && rang?.cerraduras ?
            rang?.cerraduras.length === parseInt(filtervalue) :
            false
          ) ||
          (
            !(NumberUtils.isNaN(parseInt(filtervalue))) ?
            parseInt(filtervalue) >= rang?.idTarjetaInicial && parseInt(filtervalue) <= rang?.idTarjetaFinal :
            false
          ) ||
          (
            !NumberUtils.isNaN(parseInt(filtervalue)) ?
            rang?.cantidadTarjetas === parseInt(filtervalue) :
            false
          ) ||
          (rang?.listaBlanca && filtervalue.compareWith(AppComponent.translate('Blanca'))) ||
          (rang?.listaMaster && filtervalue.compareWith(AppComponent.translate('Master'))) ||
          (rang?.listaNegra && filtervalue.compareWith(AppComponent.translate('Negra'))) ||
          (
            !NumberUtils.isNaN(parseInt(filtervalue)) && rang?.zonas ?
            rang?.zonas?.length === parseInt(filtervalue) :
            false
          ) ||
          rang?.zonasNombre.compareWith(filtervalue)
        ) &&
        (
          this.zonaFilter !== '' ?
          rang.zonas ?
          rang.zonas.findIndex(t => t.nombre === this.zonaFilter) !== -1
          : false
          : true
        ) &&
        (
          this.numeroTarjetaFilter !== 0 ?
          ((rang.idTarjetaInicial <= this.numeroTarjetaFilter && rang.idTarjetaFinal >= this.numeroTarjetaFilter)) : true
        ) &&
        (
          this.tipoTarjetaFilter === AppComponent.translate('Todas') ? true :
          this.tipoTarjetaFilter === AppComponent.translate('Blanca') && rang.listaBlanca ? true :
          this.tipoTarjetaFilter === AppComponent.translate('Master') && rang.listaMaster ? true :
          this.tipoTarjetaFilter === AppComponent.translate('Negra') && rang.listaNegra ? true :
          false
        )
      ) {
        rang['selec'] = 'selec';
      }else {
        rang['selec'] = '';
      }
    });

    // Compruebo si ya he creado el filtro "selec" anteriormente
    try {
      const filters = this.gridRangos.getfilterinformation();
      if (filters.find(s => s.datafield === 'selec') === undefined) {
        const filtergroup = new jqx.filter();
        filtergroup.operator = 'and';
        filtergroup.addfilter(0, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
        this.gridRangos.addfilter('selec', filtergroup);
      }
      this.gridRangos.applyfilters();
      this.gridRangos.updatebounddata('data');
    } catch (error) {
    }
  }

  public onCrearRango() {
    TarjetasCiudadanosComponent._this.form.collapse()

    TarjetasCiudadanosComponent._this.formRango = TarjetasCiudadanosComponent._this.editRangoComponent.createComponent(FormRangoComponent);
    TarjetasCiudadanosComponent._this.formRango.instance.init(TarjetasCiudadanosComponent._this.formRango);
  }

  public onEditarRango(rango: RangoModel) {
    TarjetasCiudadanosComponent._this.form.collapse()

    TarjetasCiudadanosComponent._this.formRango = TarjetasCiudadanosComponent._this.editRangoComponent.createComponent(FormRangoComponent);
    TarjetasCiudadanosComponent._this.formRango.instance.init(TarjetasCiudadanosComponent._this.formRango, rango);
  }

  public onCentrar(rango: RangoModel) {
    this.removeZonesFromMap();

    let globalSWPoint = new MapLatLng(180, 90);
    let globalNEPoint = new MapLatLng(-180, -90);

    rango.zonas.forEach(zona => {
      let zonaWithGeometria = this.zonas.find(z => z.Id === zona.id);

      let swPoint, nePoint;

      if (zonaWithGeometria.TipoGeo === 0) { // Si es un círculo
        this.circles.push(
          this.map.addCircle(
          {
            dataModel: zonaWithGeometria,
            content: '',
            strokeColor: '#ff0000',
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: '#0000ff',
            fillOpacity: 0.1,
            position: new MapLatLng(zonaWithGeometria.Lat, zonaWithGeometria.Lng),
            radius: Number(zonaWithGeometria.Radio),
            draggable: false,
            editable: false
          }
        ));

        let center = new MapLatLng(zonaWithGeometria.Lat, zonaWithGeometria.Lng);
        swPoint = GeoUtils.getNewLatLng(center, -zonaWithGeometria.Radio, -zonaWithGeometria.Radio);
        nePoint = GeoUtils.getNewLatLng(center, zonaWithGeometria.Radio, zonaWithGeometria.Radio);
      } else { // Si es un polígono
        let polygon = this.map.addPolygonsFromGeoJson(
          zonaWithGeometria.Geometria, false,
          {
            dataModel: zonaWithGeometria,
            content: '',
            strokeColor: '#ff0000',
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: '#0000ff',
            fillOpacity: 0.1,
            zIndex: 100,
          }
        );

        this.polygons.push(polygon[0]);

        let bounds = this.calculatePolygonBounds(zonaWithGeometria.Geometria); // Obtener límites
        swPoint = bounds.swPoint;
        nePoint = bounds.nePoint;
      }

      globalSWPoint.lat = Math.min(globalSWPoint.lat, swPoint.lat);
      globalSWPoint.lng = Math.min(globalSWPoint.lng, swPoint.lng);
      globalNEPoint.lat = Math.max(globalNEPoint.lat, nePoint.lat);
      globalNEPoint.lng = Math.max(globalNEPoint.lng, nePoint.lng);
    });
    this.map.fitTo(new MapBounds(globalSWPoint, globalNEPoint));

    TarjetasCiudadanosComponent._this.form.collapse();
  }

  removeZonesFromMap() {
    this.circles.forEach(circle => {
      this.map.removeCircle(circle)
    });
    this.circles = [];

    this.polygons.forEach(polygon => {
      this.map.removePolygon(polygon);
    });
    this.polygons = [];
  }

  calculatePolygonBounds(geoJson) {
    let arrLat = [];
    let arrLng = [];
    let arrayCoordenadas = geoJson.geometry.coordinates;
    arrayCoordenadas.forEach(polygon => {
      polygon[0].forEach(coord => {
        arrLat.push(coord[1]);
        arrLng.push(coord[0]);
      });
    });
    let swPoint = new MapLatLng(Math.min(...arrLat), Math.min(...arrLng));
    let nePoint = new MapLatLng(Math.max(...arrLat), Math.max(...arrLng));
    return { swPoint, nePoint };
  }

  public onBorrar(rango: RangoModel) {
    this.modal.confirm({
      nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
      nzContent: AppComponent.translate('Quiere_borrar_rango') + ' (' + rango.nombre + ')',
      nzCentered: true,
      nzCancelText: AppComponent.translate('CANCELAR'),
      nzOkText: AppComponent.translate('ACEPTAR'),
      nzOnOk: async () => {
        this.rangosService.deleteRango(rango.id).then(
          (response) => {
            MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
            this.getRangos();
          }, (error) => {
            MainComponent.getInstance().showError(
              'ATENCION',
              'Ha_ocurrido_un_error',
              2000
            )
          }
        )
      }
    });
  }

  onExportar() {
    const json = JSON.parse(JSON.stringify(this.gridRangos.getrows()));
    json.forEach(element => {
      delete element.id;
      delete element.uid;
      delete element.uniqueid;
      delete element.visibleindex;
      delete element.boundindex;
      delete element.dataindex;
      delete element.selec;

      element.Blanca = element.Blanca ? AppComponent.translate('SI') : AppComponent.translate('NO');
      element.Máster = element.Máster ? AppComponent.translate('SI') : AppComponent.translate('NO');
      element.Negra = element.Negra ? AppComponent.translate('SI') : AppComponent.translate('NO');
    });
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    Utils.generateAutofilterHeader(ws);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + AppComponent.translate('Rangos') + '.xlsx');
  }

  /* VENTANA CERRADURAS */
  @ViewChild('winCerraduras') winCerraduras: jqxWindowComponent;

  dataAdapterCerraduras: any;
  columnsCerraduras: any[] = [];

  openCerraduras(rango: RangoModel) {
    this.winCerraduras.open();

    this.winCerraduras.title(rango.nombre);

    this.columnsCerraduras = [
      { text: AppComponent.translate('Ns_movisat'), datafield: 'nsMovisat', width: 300, align: 'center', cellsalign: 'left' },
    ];

    let sourceCerraduras = {
      datatype: 'json',
      datafields: [
        { name: 'nsMovisat', type: 'string' },
      ],
      localdata: rango.cerraduras
    };

    this.dataAdapterCerraduras = new jqx.dataAdapter(sourceCerraduras);
  }

  cerrarCerraduras() {
    this.winCerraduras.close();
  }
}
